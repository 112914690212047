import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { Text, Button, theme, Input, Select } from 'verak-ui'
import styled from 'styled-components'
import { useFetch } from '../../../api/apihook'
import { ToastContainer, toast } from 'react-toastify'

const amruta_offline = { name: 'Amruta Supekar', email: 'amruta@verak.in' }

const parseAgentNumber = email => {
	const regex = /\d+/g
	const matches = email.match(regex)
	return matches?.[0]
}

export default function ReassignmentContainer({
	selectedRfqs,
	deactivateReassignmentMode,
	agentToHide = '',
}) {
	const { user } = useAuth0()

	const [agentList, setAgentList] = useState([])
	const [selectedAgent, setSelectedAgent] = useState({})
	const [agentNumber, setAgentNumber] = useState('')
	const [reassignmentReason, setReassignmentReason] = useState('')

	const [teamType, setTeamType] = useState('')
	const { getAgentList, raiseReassignmentRequest } = useFetch()

	const fetchAgents = async teamType => {
		const result = await getAgentList(teamType)
		if (result?.agents) {
			let agents = result?.agents?.filter(agent => agent.email !== user.email) // filter logged in user,

			if (agentToHide) {
				agents = agents.filter(agent => agent.email !== agentToHide.email) // filter rfq owner in case of reactivation flow
			}
			setAgentList(teamType === 'Offline' ? [...agents, amruta_offline] : agents)
		}
	}

	const reassignRfqsToAgent = async () => {
		const result = await raiseReassignmentRequest(
			selectedAgent.email,
			selectedRfqs,
			reassignmentReason
		)
		if (result) {
			deactivateReassignmentMode()
			toast.info('Reassignment request raised')
		}
	}

	const handleTeamChange = teamType => {
		setAgentList([])
		setSelectedAgent({})
		setTeamType(teamType)
		fetchAgents(teamType)
		setReassignmentReason('')
		setAgentNumber('')
	}

	const handleAgentNumberChange = input => {
		if (isNaN(input)) {
			return
		}
		setAgentNumber(input)
		const targetAgent = agentList.find(agent => parseAgentNumber(agent.email) === input)
		targetAgent ? setSelectedAgent(targetAgent) : setSelectedAgent({})
	}

	const isAgentPresent = Boolean(selectedAgent?.email)
	let agentSelector, agentNotFoundLabel

	if (teamType === 'Offline') {
		agentSelector = (
			<Select
				label="Select Agent"
				value={selectedAgent?.name}
				onChange={val => {
					const agent = agentList.find(agent => agent.name === val)
					setSelectedAgent(agent)
				}}
				placeholder="Select Agent"
				className="w-100"
				style={{ height: '55px' }}
			>
				<option value="" selected disabled>
					Select Agent
				</option>
				{agentList.map(agent => (
					<option key={agent.name} value={agent.name}>
						{agent.name}
					</option>
				))}
			</Select>
		)
	} else if (teamType === 'Online' || teamType === 'FOS') {
		agentNotFoundLabel =
			Boolean(agentNumber) && !isAgentPresent ? (
				<Text className="mt-2" color={theme.colors.red} fontSize="14px">
					Error: Invalid agent number, try another value
				</Text>
			) : null

		agentSelector = (
			<div className="mb-1 d-flex flex-column">
				<div className="d-flex align-items-center">
					<Text className="me-2" fontSize="18px" fontWeight="600">
						Agent
					</Text>
					<Input
						value={agentNumber}
						onChange={e => handleAgentNumberChange(e.target.value)}
						placeholder="Enter number"
						style={{ height: '48px' }}
						className="w-100"
					/>
				</div>
				{agentNotFoundLabel}
			</div>
		)
	}

	const disableReassignButton = !isAgentPresent || !reassignmentReason

	const hasSelectedAgent = Object.keys(selectedAgent).length > 0

	return (
		<>
			<div className="d-flex flex-column w-100 px-3">
				<Text fontSize="18px" color="#333" fontWeight="600">
					Enter the Agent Number to whom you want to assign the RFQ :
				</Text>
				<Select
					value={teamType}
					onChange={val => handleTeamChange(val)}
					className="w-100 my-3"
					style={{ height: '55px' }}
					placeholder="Select Team"
				>
					<option value="" disabled selected>
						Select Team
					</option>
					<option value="Online">Tele Sales</option>
					<option value="Offline">Offline</option>
					<option value="FOS">FOS</option>
				</Select>

				{agentSelector}

				{Boolean(teamType) && (
					<Select
						value={reassignmentReason}
						onChange={val => setReassignmentReason(val)}
						className="w-100 my-3"
						style={{ height: '55px' }}
						placeholder="Select Reason"
					>
						<option value="" disabled selected>
							Select Reason
						</option>
						<option value="TL_DECISION">Team leader's decision</option>
						<option value="SOLD_BY_OTHER_REP">Other rep closed the sale</option>
						<option value="REP_LEFT_COMPANY">Rep has left the company</option>
					</Select>
				)}

				{hasSelectedAgent && (
					<SelectionBanner className="mt-2 d-flex flex-column">
						<Text color="#0D1E54" fontSize="14px" fontWeight="500">
							All the selected RFQs will be reassigned to:
						</Text>
						<Text color="#0D1E54" fontSize="14px" fontWeight="700">
							{selectedAgent?.name}
						</Text>
					</SelectionBanner>
				)}
			</div>
			<Button
				disabled={disableReassignButton}
				className="w-100 mt-4"
				label="Raise request to reassign"
				style={{ height: '64px' }}
				onClick={() => reassignRfqsToAgent(selectedAgent?.email)}
			/>
			<ToastContainer />
		</>
	)
}

const SelectionBanner = styled.div`
	padding: 14px 15px;
	background: #e9effe;
	border-radius: 4px;
`
