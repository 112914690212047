const getSumInsuredFromRiskLocation = RiskLocation => {
	let SumInsured = []

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		// check is property multiple --
		if (RiskLocation?.property?.levels?.length > 0) {
			RiskLocation.property.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (
			RiskLocation?.property?.SumInsured &&
			RiskLocation?.property?.SumInsured.length > 0
		) {
			// for single or whole property
			SumInsured = [...RiskLocation?.property?.SumInsured]
		}
		// check is basement multiple --
		if (RiskLocation?.basement?.levels?.length > 0) {
			RiskLocation.basement.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (
			RiskLocation?.basement?.SumInsured &&
			RiskLocation?.basement?.SumInsured.length > 0
		) {
			// for single or whole basement
			SumInsured = [...SumInsured, ...(RiskLocation?.basement?.SumInsured || [])]
		}
	} else {
		// for multiple property or basement
		if (RiskLocation?.levels?.length > 0) {
			RiskLocation.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (RiskLocation?.SumInsured && RiskLocation?.SumInsured.length > 0) {
			// for single or whole property or basement
			SumInsured = [...(RiskLocation?.SumInsured || [])]
		}
	}

	return SumInsured
}

export const generateSumInsuredBreakdown = RiskLocations => {
	let allSumInsured = []
	let sumInsuredValues = {}

	RiskLocations.forEach(el => {
		const sumList = getSumInsuredFromRiskLocation(el)
		allSumInsured = [...allSumInsured, ...sumList]
	})

	allSumInsured.forEach(({ Name, Amount }) => {
		if (Name !== 'MaxValSingleItem') {
			// max value of an item shouldn't be included in calculations
			if (!sumInsuredValues[Name]) {
				sumInsuredValues[Name] = { Name, Amount }
			} else {
				sumInsuredValues[Name].Amount = parseInt(sumInsuredValues[Name].Amount) + parseInt(Amount)
			}
		}
	})

	const result = Object.keys(sumInsuredValues).map(sumInsuredName => {
		return sumInsuredValues[sumInsuredName]
	})

	return result
}

export const totalSumInsuredForAllRiskLocations = sumInsuredBreakdown => {
	let totalValue = 0
	if (sumInsuredBreakdown?.length) {
		totalValue = sumInsuredBreakdown
			?.map(el => el.Amount)
			?.reduce((prev, curr) => parseInt(prev || 0) + parseInt(curr || 0), 0)
	}

	return totalValue
}
