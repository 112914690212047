const insurerOps = [
	{ name: 'Acko General Insurance', value: 'Acko General Insurance' },
	{ name: 'Bajaj Allianz General Insurance', value: 'Bajaj Allianz General Insurance' },
	{ name: 'Bharti AXA General Insurance', value: 'Bharti AXA General Insurance' },
	{ name: 'Cholamandalam MS General Insurance', value: 'Cholamandalam MS General Insurance' },
	{ name: 'Navi General Insurance', value: 'Navi General Insurance' },
	{ name: 'Edelweiss General Insurance', value: 'Edelweiss General Insurance' },
	{ name: 'Future Generali India Insurance', value: 'Future Generali India Insurance' },
	{ name: 'Go Digit General Insurance', value: 'Go Digit General Insurance' },
	{ name: 'HDFC ERGO General Insurance', value: 'HDFC ERGO General Insurance' },
	{ name: 'ICICI LOMBARD General Insurance', value: 'ICICI LOMBARD General Insurance' },
	{ name: 'IFFCO TOKIO General Insurance', value: 'IFFCO TOKIO General Insurance' },
	{ name: 'Kotak Mahindra General Insurance', value: 'Kotak Mahindra General Insurance' },
	{ name: 'Liberty General Insurance', value: 'Liberty General Insurance' },
	{ name: 'Magma HDI General Insurance', value: 'Magma HDI General Insurance' },
	{ name: 'National Insurance', value: 'National Insurance' },
	{ name: 'Raheja QBE General Insurance', value: 'Raheja QBE General Insurance' },
	{ name: 'Reliance General Insurance', value: 'Reliance General Insurance' },
	{ name: 'Royal Sundaram General Insurance', value: 'Royal Sundaram General Insurance' },
	{ name: 'SBI General Insurance', value: 'SBI General Insurance' },
	{ name: 'Shriram General Insurance', value: 'Shriram General Insurance' },
	{ name: 'Tata AIG General Insurance', value: 'Tata AIG General Insurance' },
	{ name: 'The New India Assurance', value: 'The New India Assurance' },
	{ name: 'The Oriental Insurance', value: 'The Oriental Insurance' },
	{ name: 'United India Insurance', value: 'United India Insurance' },
	{ name: 'Universal Sompo General Insurance', value: 'Universal Sompo General Insurance' },
]

const claimsHistoryYears = [2021, 2020, 2019, 2018, 2017]

const claimReasons = ['STFI', 'RSMD', 'FLEXA', 'Earthquake', 'Terrorism']

const monthDropdownData = [
	{name: "January", value: 1}, 
	{name: "February", value: 2}, 
	{name: "March", value: 3}, 
	{name: "April", value: 4}, 
	{name: "May", value: 5}, 
	{name: "June", value: 6}, 
	{name: "July", value: 7}, 
	{name: "August", value: 8}, 
	{name: "September", value: 9}, 
	{name: "October", value: 10}, 
	{name: "November", value: 11}, 
	{name: "December", value: 12}, 
]

const yearDropdownData = [
	{name: "2021", value: 2021},
	{name: "2020", value: 2020},
	{name: "2019", value: 2019},
	{name: "2018", value: 2018},
	{name: "2017", value: 2017},
]

export { insurerOps, claimsHistoryYears, claimReasons, monthDropdownData, yearDropdownData }
