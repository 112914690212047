import { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/Provider'

import {
	MainContainer,
	theme,
	Tabbar,
	SecondaryNavbar,
	Text,
	Divider,
	Tooltip,
	Button,
} from 'verak-ui'

import {
	// utils and components
	getNumString,
	infoContainerStyle,
	footerStyle,
} from '../RiskLocationReview/reviewScreenUtils'

import ReviewComponent from './ReviewComponent'

import {
	BreadCrumbComponent,
	breadcrumbStyles,
	InnerTabWrapper,
	mainContainerHeaderStyles,
	mainContainerHeaderTextStyle,
	mainContainerStyles,
	MainStickyContainer,
	reviewComponentStyles,
	TabWrapper,
} from './finalReviewUtils'
import { useHistory, useLocation } from 'react-router'
import useScrollDirectionDetect from './useScrollDirectionDetect'
import { useFetch } from '../../api/apihook'
import { isRfqEqual, editRfqAllowedStatuses } from '../../helpers/editRfqUtils'

const getTabTitle = (item, index) => {
	if (item?.Type === 'PROPERTYBASEMENT') {
		return `Location (${index + 1}) (Pin - ${item?.property?.PIN || ''})`
	} else {
		return `Location (${index + 1}) (Pin - ${item?.PIN || ''})`
	}
}

export default function FinalReviewScreen({ onBack, onProceed }) {
	const { scrollUp } = useScrollDirectionDetect()
	const [state, dispatch] = useContext(Context)
	// const { setExtraContainer } = useContext(ComponentContext)
	const history = useHistory()
	const location = useLocation()
	const fetch = useFetch()

	const rfqViewOnlyMode = state?.rfq?.rfqViewOnlyMode || false
	const draftRFQReview = state?.rfq?.draftRFQReview || false

	const flowCompletionRoutes = state?.sqs?.flowCompletionRoutes || {}
	const { finalRoute, redirectRoute } = flowCompletionRoutes

	const [floorLevelActiveTab, setFloorLevelActiveTab] = useState(() => {
		const firstRL = state?.quote?.RiskLocations?.[0] || {}
		let floorString = 'floor'

		if (firstRL?.Type === 'BASEMENT') {
			floorString = 'level'
		}

		if (firstRL?.Type === 'PROPERTYBASEMENT') {
			// if prop + basement, select the first tab of a 'property' item
			const firstRLProperty = firstRL?.property || {}
			if (firstRLProperty?.levels?.length) {
				floorString += firstRLProperty?.levels?.[0]?.Level || ''
			}
			// assign the subtype if whole or single type
			else {
				floorString += firstRLProperty?.SubType || ''
			}
		} else if (firstRL?.levels?.length) {
			// convert level to absolute value to match the tab ids
			if (firstRL?.levels?.[0]?.Level) {
				floorString += Math.abs(firstRL?.levels?.[0]?.Level || '')
			}
		} else {
			// assign the subtype if whole or single type
			floorString += firstRL?.SubType || ''
		}

		return floorString
	})

	// detect browser back button press on component unmount
	useEffect(() => {
		return () => {
			dispatch({
				type: 'RESET_DRAFT_RFQ_REVIEW_MODE',
			})
		}
	}, [])

	const generateInnerTab = (tabList, key, noOfRiskLoc) => {
		let topOffset = ''
		if (noOfRiskLoc === 1) {
			topOffset = '188px'
		}

		return (
			<InnerTabWrapper scrollUp={scrollUp} top={topOffset}>
				<Tabbar
					key={key}
					activeTabId={floorLevelActiveTab}
					tabList={tabList}
					flat
					iconName="locationOutline"
					tabId="RoundedSecondary-Tab"
					className=""
					onChange={selected => setFloorLevelActiveTab(selected)}
				/>
			</InnerTabWrapper>
		)
	}

	const getTabsForRiskLocation = (locationItem, index) => {
		let Type = locationItem?.Type

		const noOfRiskLoc = state?.quote?.RiskLocations?.length || 0

		let quoteRelatedData = {
			Cover: state?.quote?.Cover,
			StockDisclosureMethod: state?.quote?.StockDisclosureMethod,
			DeclarationQ: state?.quote?.DeclarationQ,
		}

		let commonData = {
			...locationItem,
			...quoteRelatedData,
		}

		const isPropertyBasement = Type === 'PROPERTYBASEMENT'
		if (!isPropertyBasement) {
			// multiple property or basement
			if (locationItem?.levels?.length > 0) {
				const innerTab = locationItem.levels.map((innerLevelItem, levelIndex) => {
					// abs level is required to pre-select the tab on the screen if this is the 1st level [for multiple basement lvls]
					const actualLevel = innerLevelItem?.Level
					const absLevel = Math.abs(actualLevel)

					// const level = Math.abs()
					const { ID, Type: type, ...trimmedInnerLevelItem } = innerLevelItem
					return {
						id: Type === 'BASEMENT' ? `level${absLevel}` : `floor${absLevel}`,
						title: Type === 'BASEMENT' ? `Level ${absLevel}` : `Floor ${getNumString(absLevel)}`,
						disabled: false,
						content: (
							<ReviewComponent
								style={reviewComponentStyles}
								key={`${Type}-${absLevel}`}
								data={{ ...commonData, ...trimmedInnerLevelItem }}
								floorOrLevel={actualLevel}
								returnPath="/journey/risk-location-selection"
								propertyType={Type}
								levelIndex={levelIndex}
							/>
						),
					}
				})

				return generateInnerTab(innerTab, `${Type}-${index}`, noOfRiskLoc)
			} else {
				// single property or basement
				let floorString = ''

				if (locationItem.SubType === 'Whole') {
					let title = ''
					let noOfLevels = Math.abs(locationItem?.Level || 0)
					let levelsSuffix = 'levels'

					if (noOfLevels === 1) {
						levelsSuffix = 'level'
					}
					if (Type === 'BASEMENT') {
						title = 'Whole Basement'
					} else {
						title = 'Whole Building'
					}

					floorString = `${title} - (${noOfLevels} ${levelsSuffix})`
				}

				return (
					<ReviewComponent
						style={reviewComponentStyles}
						data={commonData}
						floorString={floorString}
						key={index}
						floorOrLevel={locationItem?.Level}
						returnPath="/journey/risk-location-selection"
						propertyType={Type}
						levelIndex={-1}
					/>
				)
			}
		} else {
			// first property
			let innerTab = []
			if (locationItem.property.levels) {
				innerTab = locationItem.property.levels.map((innerLevelItem, levelIndex) => {
					const level = innerLevelItem?.Level
					const { ID, Type: type, ...trimmedInnerLevelItem } = innerLevelItem
					return {
						id: `floor${level}`,
						title: `Floor ${getNumString(level)}`,
						disabled: false,
						content: (
							<ReviewComponent
								style={reviewComponentStyles}
								key={`property-${Type}-${level}`}
								data={{ ...commonData, ...trimmedInnerLevelItem }}
								floorOrLevel={level}
								returnPath="/journey/risk-location-selection"
								propertyType={'PROPERTY'}
								levelIndex={levelIndex}
							/>
						),
					}
				})
			} else {
				// no levels in property
				let title = ''
				let floorString = '' // only filled for 'whole' type

				const level = locationItem?.property?.Level || 0
				const subType = locationItem?.property?.SubType || ''

				if (subType === 'Whole') {
					let noOfLevels = Math.abs(level)
					let levelsSuffix = 'levels'
					if (noOfLevels === 1) {
						levelsSuffix = 'level'
					}
					title = 'Whole Building'
					floorString = `${title} - (${noOfLevels} ${levelsSuffix})`
				} else {
					title = `Level ${level}`
				}

				const { ID, Type: type, ...trimmedPropertyItem } = locationItem.property

				innerTab.push({
					id: `floor${subType}`,
					title: title,
					disabled: false,
					content: (
						<ReviewComponent
							style={reviewComponentStyles}
							data={{ ...commonData, ...trimmedPropertyItem }}
							floorString={floorString}
							key={`property-${Type}-${index}`}
							floorOrLevel={level}
							returnPath="/journey/risk-location-selection"
							propertyType={'PROPERTY'}
							levelIndex={-1}
						/>
					),
				})
			}

			// P+B multiple basement levels
			if (locationItem.basement.levels) {
				const basementLevels = locationItem.basement.levels.map((innerLevelItem, levelIndex) => {
					const level = innerLevelItem?.Level
					const { ID, Type: type, ...trimmedInnerLevelItem } = innerLevelItem
					return {
						id: `level${level}`,
						title: `Level ${level}`,
						disabled: false,
						content: (
							<ReviewComponent
								style={reviewComponentStyles}
								key={`basement-${Type}-${level}`}
								data={{ ...commonData, ...trimmedInnerLevelItem }}
								floorOrLevel={level}
								returnPath="/journey/risk-location-selection"
								propertyType={'BASEMENT'}
								levelIndex={levelIndex}
							/>
						),
					}
				})
				innerTab = innerTab.concat(basementLevels)
			} else {
				let title = ''
				let floorString = '' // only filled for 'whole' type

				const level = locationItem?.basement?.Level || 0
				const subType = locationItem?.basement?.SubType || ''

				if (subType === 'Whole') {
					let noOfLevels = Math.abs(level)
					let levelsSuffix = 'levels'
					if (noOfLevels === 1) {
						levelsSuffix = 'level'
					}
					title = 'Whole Basement'
					floorString = `${title} - (${noOfLevels} ${levelsSuffix})`
				} else {
					title = `Level ${level}`
				}

				const { ID, Type: type, ...trimmedBasementItem } = locationItem.basement

				innerTab.push({
					id: `level${subType}`,
					title: title,
					disabled: false,
					content: (
						<ReviewComponent
							style={reviewComponentStyles}
							data={{ ...commonData, ...trimmedBasementItem }}
							floorString={floorString}
							key={`basement-${Type}-${index}`}
							floorOrLevel={level}
							returnPath="/journey/risk-location-selection"
							propertyType={'BASEMENT'}
							levelIndex={-1}
						/>
					),
				})
			}
			return generateInnerTab(innerTab, `${Type}-${index}`, noOfRiskLoc)
		}
	}

	let riskLocationTabList = []
	if (state?.quote?.RiskLocations.length > 0) {
		riskLocationTabList = state?.quote?.RiskLocations?.map((item, index) => {
			return {
				id: item.ID,
				title: getTabTitle(item, index),
				disabled: false,
				content: getTabsForRiskLocation(item, index),
				key: item.ID,
			}
		})
	}

	const [riskLocationActiveTab, setRiskLocationActiveTab] = useState(() => {
		return riskLocationTabList[0]?.id
	})

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	let nextScreen = async () => {
		let nextRoute = '/sum-insured-breakdown'

		// the sqs reducer has '/final-review' set as the finalRoute for the current flow,
		// navigate to the redirectRoute set in the sqs reducer
		if (finalRoute === window.location.pathname) {
			nextRoute = redirectRoute
			// check if the current flow is edit flow
			if (state?.rfq?.editRfqMode === true) {
				// call the update rfq API
				// sqs reducer will be cleared on the update success screen

				const validRFQStatus = editRfqAllowedStatuses.includes(rfqStatus)
				if (validRFQStatus && state?.rfq?.editRfqMode && !isRfqEqual(state?.quote, state?.oldRfq)) {
					await fetch.resubmitRfqOnUpdate(state?.quote?.quoteId, state?.oldRfq)
				}
				await new Promise(resolve => {
					dispatch({ type: 'CLEAR_OLD_RFQ' })
					dispatch({
						type: 'SET_EDIT_RFQ',
						data: { editRfqMode: false },
					})
					resolve()
				})
			}
		}
		history.push(nextRoute)
	}

	if (typeof onProceed === 'function') {
		nextScreen = onProceed
	}

	let prevScreen = () => {
		if (location?.state?.from === 'rfq-progress-screen') {
			history.goBack()
		} else {
			history.push('/summary')
		}
	}

	if (rfqViewOnlyMode) {
		prevScreen = () => {
			history.goBack()
		}
	}

	if (typeof onBack === 'function') {
		prevScreen = onBack
	}

	const setActiveTabs = async selected => {
		await new Promise((resolve, reject) => {
			setRiskLocationActiveTab(selected)
			resolve()
		})
		const selectedRL = state?.quote?.RiskLocations.find(el => el.ID === selected)
		let levelId = ''
		if (!selectedRL) {
			return
		}
		if (selectedRL.Type === 'PROPERTYBASEMENT') {
			if (selectedRL.property.levels) {
				const levelItem = selectedRL.property.levels[0].Level
				levelId = `floor${levelItem}`
			} else {
				levelId = `floor${selectedRL.property.SubType}`
			}
			setFloorLevelActiveTab(levelId)
		} else {
			if (selectedRL.levels) {
				const levelItem = selectedRL.levels[0].Level
				levelId = selectedRL.Type === 'BASEMENT' ? `level${levelItem}` : `floor${levelItem}`
				setFloorLevelActiveTab(levelId)
				// get first level and set inner level
			} else {
				// whole or single
				// no need to set inner tab id
			}
		}
	}
	let locationTab
	if (riskLocationTabList.length > 1) {
		locationTab = (
			<TabWrapper scrollUp={scrollUp}>
				<Tabbar
					activeTabId={riskLocationActiveTab}
					tabList={riskLocationTabList}
					withIcon
					iconName="locationOutline"
					tabId="RoundedSecondary-Tab"
					className=""
					onChange={selected => setActiveTabs(selected)}
					// style={{position: 'sticky', top: '185px'}}
				/>
			</TabWrapper>
		)
	} else {
		locationTab = riskLocationTabList[0].content
	}

	// init styles
	let breadcrumbContainerStyles = {
		...breadcrumbStyles,
		margin: '0',
		transform: 'translateY(-500px)',
	}
	let mainContainerHeaderSectionStyles = {
		...mainContainerHeaderStyles,
		transform: 'translateY(-500px)',
	}
	if (scrollUp) {
		breadcrumbContainerStyles = breadcrumbStyles
		mainContainerHeaderSectionStyles = mainContainerHeaderStyles
	}

	const mainContainerFooterStyles = {
		...footerStyle,
		margin: 'auto',
		width: 'min(1070px, 100%)',
		borderRadius: '8px',
	}

	const businessName = state?.rfq?.EntityName || ''
	const customerName = state?.rfq?.ProposerName || ''

	const customerInfo = (
		<div className="p-2">
			{customerName && (
				<>
					<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
						Customer Name:
					</Text>
					<Text type="body1" color="white" fontWeight="700" fontSize="18px">
						{customerName}
					</Text>
				</>
			)}
			{businessName && (
				<>
					<Divider top="0.5" bottom="0.5" style={{ color: '#fff' }} />
					<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
						Business Name:
					</Text>
					<Text type="body1" color="white" fontWeight="700" fontSize="18px">
						{businessName}
					</Text>
				</>
			)}
		</div>
	)

	let showEditModeNavbar = null
	if (rfqViewOnlyMode) {
		showEditModeNavbar = (
			<SecondaryNavbar
				showGoBack={true}
				onGoBack={prevScreen}
				headerBg="#F3BDA1"
				leftContent={!draftRFQReview ? 'Back to comparison screen' : 'Back'}
				rightContent={
					!draftRFQReview ? (
						<Tooltip trigger="click" position="bottom" html={customerInfo}>
							<Button
								bgColor="transparent"
								textColor="#4C4C4C"
								label="View Customer Info"
								icon="customerInfoIcon"
								style={{ fontSize: '16px' }}
								iconStyles={{ marginRight: '0.5rem' }}
							/>
						</Tooltip>
					) : null
				}
			/>
		)
	}

	return (
		<MainStickyContainer>
			{showEditModeNavbar}
			<BreadCrumbComponent
				location="Final Review"
				showNextButton={!rfqViewOnlyMode}
				nextLabel="Next"
				nextOnClick={nextScreen}
				containerStyles={breadcrumbContainerStyles}
			/>

			<MainContainer
				containerStyles={mainContainerStyles}
				headerBg={theme.colors.secondary4}
				headerStyle={mainContainerHeaderSectionStyles}
				headerTextStyle={mainContainerHeaderTextStyle}
				header="Please review the details for each risk location"
				rightLabel="Next"
				rightOnClick={nextScreen}
				leftOnClick={prevScreen}
				rightDisabled={rfqViewOnlyMode && draftRFQReview ? false : rfqViewOnlyMode}
				infoContainerStyle={infoContainerStyle}
				footerStyle={mainContainerFooterStyles}
				footerContainerStyle={{ padding: '0 1rem' }}
				divider={false}
			>
				{locationTab}
			</MainContainer>
		</MainStickyContainer>
	)
}
