export const initialRiskLocationData = {
	Type: 'PROPERTY',
	SubType: 'Single',
	Level: '',
	AddressLine1: '',
	PIN: '',
	PrimaryOccupancy: {
		Category: '',
		SubCategory: '',
	},
	FireRisk: {
		Measures: [],
		AMC: '',
	},
	MaterialRisk: {
		Ceiling: {
			Type: '',
			Description: '',
		},
		Walls: {
			Type: '',
			Description: '',
		},
		Floor: {
			Type: '',
			Description: '',
		},
	},
	TheftRisk: {
		SecurityArrangements: [],
	},
	SumInsured: [
		{ Name: 'BasementValue', Amount: '' },
		{ Name: 'BuildingValue', Amount: '' },
		{ Name: 'BoundaryWalls', Amount: '' },
		{ Name: 'PlantMachinery', Amount: '' },
		{ Name: 'FurnitureFixture', Amount: '' },
		{ Name: 'Electronics', Amount: '' },
		{ Name: 'OfficeEquipment', Amount: '' },
		{ Name: 'ValuableContents', Amount: '' },
		{ Name: 'Other', Amount: '' },
		{ Name: 'MaxValSingleItem', Amount: '' },
	],
	Stock: {
		Present: false,
		Details: [
			{
				AmountClosed: '',
				Type: '',
				AmountOpen: 0,
				OpenPer: 0,
				ClosedPer: 0,
			},
		],
	},
	ConstructionAge: { Low: 1, High: 5 },
}

export const initialState = {
	Contact: {
		Name: '',
		Phone: '',
		WaPhone: '',
		WaConsent: '',
		Address: '',
		PIN: '',
	},
	Account: {
		Name: '',
		UID: '',
		LeadSource: '',
		Type: '',
	},
	Data: {
		PolicyKind: '',
		RiskLocations: [
			// add a initial location
			{ ...initialRiskLocationData },
		],
	},
}
