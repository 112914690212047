import { useHistory } from 'react-router'
import Summary from '../../../FinalReview/Summary'

const QuotesPendingSummaryScreen = () => {
	const history = useHistory()

	const onBack = () => {
		history.push('/rfq-status/quotes-pending')
	}
	const onProceed = () => {
		history.push('/rfq-status/quotes-pending/final-review')
	}
	return <Summary onBack={onBack} onProceed={onProceed} />
}

export default QuotesPendingSummaryScreen
