import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import CICLogo from '../../../../../assets/training/CICLogo.svg'
import CISLogo from '../../../../../assets/training/CISLogo.svg'
import CITLogo from '../../../../../assets/training/CITLogo.svg'
import DailyRecord from '../../../../../assets/training/DailyRecord.svg'
import SafeRequired from '../../../../../assets/training/SafeRequired.svg'
import WINC_1 from '../../../../../assets/training/WINC_1.svg'
import WINC_2 from '../../../../../assets/training/WINC_2.svg'
import WINC_3 from '../../../../../assets/training/WINC_3.svg'
import WINC_4 from '../../../../../assets/training/WINC_4.svg'
import WINC_5 from '../../../../../assets/training/WINC_5.svg'

export default function CheatSheet_Convincing() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<InfoText fontWeight="700" fontSize="16px" className="text-center" spaced>
					MONEY CAN BE INSURED IF IT IS
				</InfoText>
				<div className="d-flex justify-content-between align-items-center pt-2">
					<InfoBox icon={CISLogo} iconSize="">
						<InfoText fontWeight="700" fontSize="16px">
							CASH IN SAFE
						</InfoText>
					</InfoBox>
					<VerticalDivider />
					<InfoBox icon={CICLogo} iconSize="">
						<InfoText fontWeight="700" fontSize="16px">
							CASH IN COUNTER
						</InfoText>
					</InfoBox>
					<VerticalDivider />
					<InfoBox icon={CITLogo} iconSize="">
						<InfoText fontWeight="700" fontSize="16px">
							CASH IN TRANSIT
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />
			</div>
			<HorizontalDivider />
			<div className="row w-100 d-flex justify-content-between">
				<InfoText fontWeight="700" fontSize="16px" className="mb-3 text-center" spaced>
					PLEASE CLARIFY TO CUSTOMER
				</InfoText>

				<div className="row w-100 d-flex justify-content-between">
					<div className="col">
						<InfoBox className="col" icon={DailyRecord}>
							<InfoText fontWeight="700">
								Customer ko daily copy me written hisab rakhna padega, kitna cash hai counter/safe
								me
							</InfoText>
						</InfoBox>
						<ul className="mt-1">
							<li>Kisko kitna cash diya ha uska bhi written hisab rakhna hoga</li>
						</ul>
					</div>

					<VerticalDivider />

					<InfoBox className="col" icon={SafeRequired}>
						<InfoText fontWeight="700">
							Customer ke paas kijori (Safe hona chahiye), jahan business close hone pe safely paisa
							rakhte h
						</InfoText>
					</InfoBox>
				</div>
			</div>

			<HorizontalDivider />
			<div className="row w-100 d-flex justify-content-between">
				<InfoText fontWeight="700" fontSize="16px" className="mb-3 text-center" spaced>
					WHAT IS NOT COVERED
				</InfoText>

				<div className="row w-100 d-flex justify-content-between">
					<div className="col">
						<InfoBox className="col" icon={WINC_1}>
							<InfoText fontWeight="700">Agar khud ke employee ne chori krri ho</InfoText>
						</InfoBox>
					</div>

					<VerticalDivider />

					<InfoBox className="col" icon={WINC_4}>
						<InfoText fontWeight="700">
							Agar chor ko kisi tarah aapki chabi mil jaye (Eg customer ne chabi kha di ho)
						</InfoText>
					</InfoBox>
				</div>

				<HorizontalDivider />

				<div className="row w-100 d-flex justify-content-between">
					<div className="col">
						<InfoBox className="col" icon={WINC_2}>
							<InfoText fontWeight="700">Dango ke karan dukaan me chori ho jaye</InfoText>
						</InfoBox>
						<ul className="mt-1">
							<li>
								Vyapar Suraksha dango se cover krti h, magar money ka insurance included nahi hota
							</li>
						</ul>
					</div>

					<VerticalDivider />

					<InfoBox className="col" icon={WINC_5}>
						<InfoText fontWeight="700">
							Paise counter pe bhool gye aur vahan se chori hoti h to
						</InfoText>
					</InfoBox>
				</div>

				<HorizontalDivider />

				<div className="row w-100 d-flex justify-content-between">
					<div className="col-md-6">
						<InfoBox className="col" icon={WINC_3}>
							<InfoText fontWeight="700">
								Kisi 3rd party ko aap paise de rahe ho to (Sirf employee aur owner covered hai)
							</InfoText>
						</InfoBox>
					</div>
				</div>
			</div>
		</div>
	)
}
