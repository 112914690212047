import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import { Text, Icon } from 'verak-ui'

const CardItem = ({ title, self, benchmark, unit = '', children }) => {
	let tag

	let status = self > benchmark ? 'good' : 'bad'

	if (status == 'good') {
		tag = (
			<GoodTag className="d-flex align-items-center">
				<Icon name="like" className="me-2" />
				<Text fontSize={'10px'} fontWeight={'700'} color={'#275534'}>
					GOOD
				</Text>
			</GoodTag>
		)
	} else {
		tag = (
			<BadTag className="d-flex align-items-center">
				<Icon name="disLike" className="me-2" />
				<Text fontSize={'10px'} fontWeight={'700'} color={'#C32A1F'}>
					NEEDS IMPROVEMENT
				</Text>
			</BadTag>
		)
	}

	let agentMetricValue = ''
	if (self) {
		agentMetricValue = `${self} ${unit}`
	} else {
		agentMetricValue = 'NA'
	}

	return (
		<Col className="my-3" xs={6}>
			<CardUI className="d-flex flex-column">
				<Header className="p-3 d-flex align-items-end justify-content-between">
					<Text fontSize={'18px'} fontWeight={'600'}>
						{title}
					</Text>
					{tag}
				</Header>
				<Content className="px-3 pt-3 flex-fill d-flex">
					<div className="flex-fill">{children}</div>
				</Content>
				<div className="d-flex px-3 pb-3 align-items-end">
					<MiniValue className="me-3">
						<Text color={'white'} fontWeight={'600'}>
							Minumum threshold: {benchmark} {unit}
						</Text>
					</MiniValue>
					<SelfValue>
						<Text color={'white'} fontWeight={'600'}>
							You: {agentMetricValue}
						</Text>
					</SelfValue>
				</div>
			</CardUI>
		</Col>
	)
}

const MiniValue = styled.div`
	background-color: #dd5853;
	border-radius: 8px;
	padding: 8px 14px 8px 14px;
`
const SelfValue = styled.div`
	background-color: #02475e;
	border-radius: 8px;
	padding: 8px 14px 8px 14px;
`
const CardUI = styled.div`
	/* height: 400px; */
	border: 0.6px solid transparent;
	background-color: white;
	border-radius: 8px;
`

const Header = styled.div`
	height: 59px;
	border-bottom: solid 1px;
`
const Content = styled.div``

const GoodTag = styled.div`
	background-color: #f4fff9;
	border: solid 1px #7deba3;
	border-radius: 60px;
	padding: 6px 20px 6px 20px;
`

const BadTag = styled.div`
	background-color: #e29896;
	border: solid 1px #e29896;
	border-radius: 60px;
	padding: 6px 20px 6px 20px;
`

export default CardItem
