import { useLocation, useHistory } from 'react-router-dom'
import { useState } from 'react'
import styled from 'styled-components'

import {
	Button,
	theme,
	Text,
	Icon,
	Modal,
	Space,
	Input,
	FileUploader,
	FileUploaded,
	HiddenFileInput,
} from 'verak-ui'
import { useFetch } from '../../../../api/apihook'
import { parseLinkFromSignedUrl } from '../../../../utilities/imageUploadUtilities'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import { convertAmountToWords } from '../../../RiskLocationReview/reviewScreenUtils'

export default function CompleteInsurerRequirements() {
	const { state: locationState } = useLocation()
	const history = useHistory()

	const fetch = useFetch()

	const [showSuccessModal, setShowSuccessModal] = useState(false)

	const [insurerQuestions, setInsurerQuestions] = useState(() => {
		if (locationState?.FurtherRequirements?.length > 0) {
			return locationState.FurtherRequirements.map(item => {
				return { ...item, Answer: '' }
			})
		} else {
			return []
		}
	})

	const handleChange = (questionId, answer) => {
		let insurerQuestionsCopy = [...insurerQuestions]
		const targetQuestion = insurerQuestionsCopy.find(item => item._id === questionId)
		targetQuestion.Answer = answer
		setInsurerQuestions(insurerQuestionsCopy)
	}

	const closeModal = () => {
		setShowSuccessModal(false)
		history.push('/quotes-in-progress')
	}

	const submitAnswers = async () => {
		const answers = {}
		insurerQuestions.forEach(questionItem => {
			answers[questionItem._id] = questionItem.Answer
		})

		const result = await fetch.answerInsurerRequirements(locationState?.quoteId, {
			requirements: answers,
		})
		if (result?.message === 'ok') {
			setShowSuccessModal(true)
		}
	}

	// const submitDisabled = false
	const submitDisabled = insurerQuestions?.some(item => item.Answer === '')

	const sumInsured = !!locationState?.totalSumInsured
		? convertAmountToWords(`₹ ${locationState?.totalSumInsured}`)
		: ''

	const navLeftContent = (
		<Text type="body1" color={theme.colors.white} fontWeight="700">
			<div className="d-flex align-items-center">
				<span style={{ opacity: '.6' }}>{locationState?.Insurer}</span> &nbsp;
				<Icon name="rightArrow" color={theme.colors.white} />
				Missing Data, {locationState?.uid} - {locationState?.ClientName}
			</div>
		</Text>
	)

	const navRightContent = (
		<div className="d-flex align-items-center">
			<Text style={{ opacity: '.6' }} type="body1" color={theme.colors.white} fontWeight="700">
				{!!sumInsured && `Sum Insured: ${sumInsured}`}
			</Text>
			<Button
				disabled={submitDisabled}
				onClick={submitAnswers}
				className="mx-2"
				label="Submit updated details"
				icon="checkboxImg"
				iconAlign="left"
				iconStyles={{ marginLeft: '10px' }}
				textColor={theme.colors.white}
				iconColor={theme.colors.white}
				bgColor={theme.colors.primary}
			/>
		</div>
	)

	const clickInputElement = inputId => {
		const input = document.getElementById(inputId)
		input?.click()
	}

	const handleMediaUpload = async (files, questionId, mimeType) => {
		const file = files[0]

		const uploadUrl = await fetch.getSignedUrl(mimeType, locationState?.uid)
		console.log(uploadUrl)
		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

		handleChange(questionId, s3Link)
	}

	const getAnswerElement = questionItem => {
		switch (questionItem.AnswerType) {
			case 'TEXT':
				let question = insurerQuestions.find(item => item._id === questionItem._id)
				return (
					<Input
						value={question?.Answer}
						onChange={event => handleChange(questionItem._id, event.target.value)}
						placeholder="Enter answer"
					/>
				)

			case 'IMAGE':
				let imageInputId = `${questionItem._id}_IMAGE`
				let isImageAvailable = insurerQuestions.find(item => item._id === questionItem._id)
				if (!isImageAvailable?.Answer) {
					return (
						<>
							<HiddenFileInput
								onChange={e => handleMediaUpload(e.target.files, questionItem._id, 'image/jpeg')}
								accept="image/jpeg"
								id={imageInputId}
							/>
							<FileUploader onClick={() => clickInputElement(imageInputId)} />
						</>
					)
				} else {
					return (
						<FileUploaded
							onDelete={() => handleChange(questionItem._id, '')}
							imageUrl={isImageAvailable.Answer}
						/>
					)
				}
			case 'VIDEO':
				let videoInputId = `${questionItem._id}_VIDEO`
				let isVideoAvailable = insurerQuestions.find(item => item._id === questionItem._id)
				if (!isVideoAvailable?.Answer) {
					return (
						<>
							<HiddenFileInput
								onChange={e => handleMediaUpload(e.target.files, questionItem._id, 'video/mp4')}
								accept="video/mp4"
								id={videoInputId}
							/>
							<FileUploader onClick={() => clickInputElement(videoInputId)} />
						</>
					)
				} else {
					return <FileUploaded onDelete={() => handleChange(questionItem._id, '')} imageUrl={''} />
				}
			default:
				return
		}
	}

	let insurerQuestionsMap = []
	if (insurerQuestions?.length > 0) {
		insurerQuestions.forEach(questionItem => {
			let answerElement = getAnswerElement(questionItem)

			insurerQuestionsMap.push(
				<QuestionCard key={questionItem._id} className="flex flex-col items-center">
					<Text fontSize="18px" fontWeight="700" color={theme.colors.gray7} className="mb-2">
						{questionItem.Question}
					</Text>
					{answerElement}
				</QuestionCard>
			)
		})
	}

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			{/* main navbar */}
			<SecondaryNavbar
				headerBg={theme.colors.red}
				navbarNumber={1}
				leftContent={navLeftContent}
				rightContent={navRightContent}
			/>
			<div className="p-4" style={{ width: '95%' }}>
				<Text
					className="d-flex align-items-start align-self-start"
					fontSize="24px"
					fontWeight="700"
					color={theme.colors.gray7}
				>
					Insurance company has requested for more data
				</Text>

				<div
					style={{
						boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
						borderRadius: '8px',
					}}
					className="container-fluid bg-white w-100 p-3 my-4 d-flex justify-content-start align-items-start flex-column"
				>
					{insurerQuestionsMap}
				</div>

				<Button
					disabled={submitDisabled}
					onClick={submitAnswers}
					style={{ height: '48px' }}
					className="w-100"
					label="Submit updated details"
					icon="checkboxImg"
					iconAlign="left"
					iconStyles={{ marginRight: '10px' }}
					iconColor={theme.colors.white}
				/>
			</div>
			<SuccessModal
				insurerName={locationState?.Insurer}
				show={showSuccessModal}
				handleClose={closeModal}
			/>
		</PageContentAndNavbarWrapper>
	)
}

const SuccessModal = ({ show, handleClose, insurerName }) => {
	return (
		<Modal
			title={
				<Text type="h5" fontWeight="bold" color={theme.colors.red}>
					Notice
				</Text>
			}
			show={show}
			handleClose={handleClose}
			footer={<></>}
		>
			<div className="text-center">
				<div className="d-flex justify-content-center">
					<Icon name="tickCircleFilled" color={theme.colors.primary} />
				</div>

				<Space y={1} />
				<Text fontSize="24px" fontWeight="700" color={theme.colors.primary}>
					The updated details have been successfully sent to {insurerName}. Quote status will be
					reflected soon
				</Text>

				<Button
					label="Back to home"
					onClick={handleClose}
					className="mt-3"
					style={{
						width: '100%',
						height: '48px',
						fontSize: '18px',
					}}
				/>
			</div>
		</Modal>
	)
}

const QuestionCard = styled.div`
	background: #fff;
	padding: 24px;
	display: flex;
	flex-direction: column;
	width: 100%;
`
