import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'

import { stockMethodCheck } from '../../utilities/stockMethodCheck'
import { ModalButton } from './Modals'
import { Icon, Space, Text, theme, Modal, MainContainer } from 'verak-ui'
import { useFetch } from '../../api/apihook'

const StockSelect = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)

	const triggerDispatch = async disclosureMethod => {
		await fetch.trackRfqEvent(`Selected 'Stock Disclosure Method': ${disclosureMethod}`)

		if (disclosureMethod == 'DECLARED_VALUE') {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_STOCK_DISCLOSURE_METHOD',
					data: {
						StockDisclosureMethod: disclosureMethod,
					},
				})

				// reset values
				dispatch({
					type: 'SET_STOCK_REPORTING_METHOD',
					data: {
						ReportingMethod: '',
					},
				})
				dispatch({
					type: 'SET_STOCK_VALUE',
					data: {
						MaxValueAllLoc: '',
						MaxValueOneLoc: '',
					},
				})

				resolve()
			})

			await fetch.saveQuote()
			if (state.quote?.PolicyName === 'SFSP') {
				history.push('/sfsp/sum-insured-methods')
			} else {
				history.push('/journey/risk-location-selection')
			}
		} else {
			history.push({
				pathname: '/stock-disclosure-choice/stock-disclosure-methods',
				state: {
					disclosureMethod: disclosureMethod,
				},
			})
		}
	}

	const data = {
		StockRailSiding: state?.quote?.StockRailSiding,
		StockInProcess: state?.quote?.StockInProcess,
		Duration: state?.quote?.Cover?.Duration,
	}

	const isDeclarationAllowed = stockMethodCheck(
		data.StockRailSiding,
		data.StockInProcess,
		data.Duration
	)

	const methods = [
		{
			icon: 'floater',
			name: 'Floater',
			disclosureMethod: 'FLOATER',
		},
		{
			icon: 'declarationBasis',
			name: 'Declaration Basis',
			disclosureMethod: 'DECLARATION_BASIS',
			disabled: !isDeclarationAllowed,
		},
		{
			icon: 'floaterDeclaration',
			name: 'Floater Declaration',
			disclosureMethod: 'FLOATER_DECLARATION',
			disabled: !isDeclarationAllowed,
			space: 0.5,
		},
		{
			icon: 'declaredValue',
			name: 'Declared Value',
			disclosureMethod: 'DECLARED_VALUE',
		},
	]
	let history = useHistory()

	const [modalState, setModalState] = useState(false)
	const [modalContent, setModalContent] = useState()

	const clickHandler = (name, disclosureMethod) => {
		if (name !== 'Floater') {
			if (name === 'Declared Value') {
				setModalContent(
					<>
						<Text type="primary" color={theme.colors.primary}>
							Under the Declared Value basis, you simply need to disclose the value of Stock for
							each location at the start of the policy.
						</Text>
						<Space y={1} />
						<Text type="primary" color={theme.colors.primary}>
							No further disclosure is required.
						</Text>
						<Space y={1.5} />
						<ModalButton proceed={() => triggerDispatch(disclosureMethod)} />
					</>
				)
			} else if (name === 'Floater Declaration' || name === 'Declaration Basis') {
				setModalContent(
					<>
						<Text type="primary" color={theme.colors.primary}>
							The client will be required to disclose the Stock Value for each location at the start
							of every Calendar month.
						</Text>
						<Space y={1.5} />
						<Text style={{ fontSize: '18px' }} color={theme.colors.primary}>
							If you are unsure about this - please ask the Sales Manager for help
						</Text>
						<Space y={1.5} />
						<Text style={{ fontSize: '18px' }} color={theme.colors.primary}>
							Insure First Insurance Brokers LLP will provide you reminders to meet this disclosure
							requirement - including the template for submission to insurers & guidance.
						</Text>
						<Space y={1.5} />
						<ModalButton proceed={() => triggerDispatch(disclosureMethod)} />
					</>
				)
			}
			setModalState(true)
		} else {
			triggerDispatch(disclosureMethod)
		}
	}

	return (
		<MainContainer header="Stock details" top={4}>
			<Space y={2.75} />
			<Text type="primary" color={theme.colors.gray7}>
				Please select a disclosure method for Stock value:
			</Text>
			<Space y={1.5} />
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				{methods.map(el => (
					<StyledButton
						key={el.name}
						disabled={el.disabled}
						onClick={() => clickHandler(el.name, el.disclosureMethod)}
					>
						<Icon name={el.icon} />
						<Space y={el.space || 1.5} />
						<Text style={{ fontSize: '22px', fontWeight: '700' }} color={theme.colors.primary}>
							{el.name}
						</Text>
					</StyledButton>
				))}
			</div>
			<Space y={1.5} />
			{/* Couldn't modify the alert component to create this */}
			<div className="flex-container">
				<Icon name="infoIconOctagon" color={theme.colors.secondary} />
				<Space x={0.5} />
				{/* Learn more needs to be added */}
				<Text style={{ fontSize: '14px', fontWeight: '700' }} color={theme.colors.secondary}>
					Understand the difference between these declaration methods
				</Text>
			</div>
			<Modal
				show={modalState}
				handleClose={() => setModalState(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Notice
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				{modalContent}
			</Modal>
		</MainContainer>
	)
}

export default StockSelect

const StyledButton = styled.button`
	border: none;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	width: 183.5px;
	height: 210px;
	background: ${theme.colors.secondary3};
	opacity: ${({ disabled }) => (disabled ? '0.5' : 'initial')};
	border-radius: 10px;
	text-align: left;
	padding: 1.5rem;
`
