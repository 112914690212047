import styled from 'styled-components'
import {
	Divider,
	MainContainer,
	Tooltip,
	FileUploader,
	FileUploaded,
	HiddenFileInput,
	Icon,
	Input,
	Radio,
	Text,
	Tabbar,
	theme,
} from 'verak-ui'

import { Row, Col } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext, useEffect, useRef } from 'react'
import { Context } from '../../../context/Provider'
import { useFetch } from '../../../api/apihook'
import {
	parseLinkFromSignedUrl,
	imageDeleteConfirmOptions,
} from '../../../utilities/imageUploadUtilities'
import Swal from 'sweetalert2'
import { useLoadingOverlay } from '../../../components/LoadingOverlay'

const defaultSchema = {
	Type: '',
	Description: '',
}

function prefillMaterialDetails(propertyType, currentTab, globalState) {
	const isProperty = propertyType === 'PROPERTY'
	const isBasement = propertyType === 'BASEMENT'

	if (isProperty || isBasement) {
		return globalState.Materials || ''
	} else {
		if (currentTab === 'property') {
			return globalState.property.Materials || ''
		}
		return globalState.basement.Materials || ''
	}
}

const MaterialsDetails = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const [activeTab, setActiveTab] = useState('property')
	const [isDisabled, setIsDisabled] = useState(true)
	const [propertyData, setPropertyData] = useState(state.location?.property?.Materials || {})
	const [basementData, setBasementData] = useState(state.location?.basement?.Materials || {})

	const history = useHistory()
	const location = useLocation()
	const Type = state?.location?.Type
	const returnPath = location.state?.returnPath || ''

	let propertyEle = (
		<PropertyUI
			globalState={state.location || {}}
			Type={Type}
			data={propertyData}
			setPropertyData={setPropertyData}
			activeTab={activeTab}
		/>
	)
	let basementEle = (
		<BasementUI
			globalState={state.location || {}}
			Type={Type}
			data={basementData}
			setBasementData={setBasementData}
			activeTab={activeTab}
		/>
	)

	const tabList = [
		{
			id: 'property',
			title: 'Building',
			content: propertyEle,
			// disabled: activeTab !== 'property' && isDisabled,
		},
		{
			id: 'basement',
			title: 'Basement',
			content: basementEle,
			// disabled: activeTab !== 'basement' && isDisabled,
		},
	]

	const isCompleteData = data => {
		const allFieldsPresent = Object.keys(data).length > 2

		let materialDescForKutcha = true

		Object.values(data).forEach(dataItem => {
			if (dataItem.Type === 'Kutcha' && !dataItem.Description) {
				materialDescForKutcha = false
			}
		})

		return allFieldsPresent && materialDescForKutcha
	}

	// handles all disabling/enabling of the ****"Save and Continue"**** button
	useEffect(() => {
		// logic is divided into 2 parts, either PROPERTYBASEMENT and rest
		if (Type === 'PROPERTYBASEMENT') {
			// this if-else-if structure handles the case of shifing of tabs for disabling/enabling the button
			if (activeTab === 'property') {
				if (isCompleteData(propertyData)) {
					setIsDisabled(false)
				} else {
					setIsDisabled(true)
				}
			} else if (activeTab === 'basement') {
				if (isCompleteData(basementData)) {
					setIsDisabled(false)
				} else {
					setIsDisabled(true)
				}
			}
		} else {
			if (
				(Type === 'PROPERTY' && isCompleteData(propertyData)) ||
				(Type === 'BASEMENT' && isCompleteData(basementData))
			) {
				setIsDisabled(false)
			} else {
				setIsDisabled(true)
			}
		}
	}, [isDisabled, propertyData, basementData, activeTab]) // eslint-disable-line

	let dispatchPropertyData = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					Materials: propertyData,
				},
			})
			resolve()
		})
	}

	let dispatchBasementData = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'basement',
				levelIndex: -1,
				data: {
					Materials: basementData,
				},
			})
			resolve()
		})
	}

	const saveAndProceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})
		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else {
			history.push('/property-risk/fire-system')
		}
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/theft-risk')
		}
	}

	const proceed = async () => {
		if (Type === 'PROPERTYBASEMENT') {
			if (propertyData) {
				await dispatchPropertyData()
			}
			if (basementData) {
				await dispatchBasementData()
			}
			// the final history.push always takes place from the basements tab
			if (activeTab === 'property') {
				setActiveTab('basement')
			} else if (activeTab === 'basement') {
				if (isCompleteData(propertyData)) {
					saveAndProceed()
				} else {
					setActiveTab('property')
				}
			}
		} else {
			if (Type === 'PROPERTY') {
				await dispatchPropertyData()
			} else if (Type === 'BASEMENT') {
				await dispatchBasementData()
			}
			saveAndProceed()
		}
	}

	const changeTab = () => {
		if (activeTab === 'property') {
			dispatchPropertyData()
			setActiveTab('basement')
		} else {
			dispatchBasementData()
			setActiveTab('property')
		}
	}

	let content = null
	if (Type === 'PROPERTYBASEMENT') {
		content = (
			<Tabbar className="mt-3" tabList={tabList} activeTabId={activeTab} onChange={changeTab} />
		)
	} else if (Type === 'PROPERTY') {
		content = propertyEle
	} else if (Type === 'BASEMENT') {
		content = basementEle
	}

	return (
		<MainContainer
			header="Construction details"
			rightLabel="Save & Continue"
			rightOnClick={proceed}
			leftOnClick={goBack}
			rightDisabled={isDisabled}
		>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<TitleText>Please enter some further details:</TitleText>
			{content}
		</MainContainer>
	)
}

export default MaterialsDetails

const PropertyUI = ({ id = 'property', setPropertyData, Type, globalState, activeTab }) => {
	const prefillData = prefillMaterialDetails(Type, 'property', globalState)
	const { Roof, Walls, Floors, Images } = prefillData

	const { setIsLoading, setLoadingText } = useLoadingOverlay()
	const [propertyRoof, setPropertyRoof] = useState({ ...defaultSchema, ...Roof })
	const [propertyWalls, setPropertyWalls] = useState({ ...defaultSchema, ...Walls })
	const [propertyFloor, setPropertyFloor] = useState({ ...defaultSchema, ...Floors })
	const [propertyImages, setPropertyImages] = useState([...(Images || [])])

	const propertyFileInput = useRef()
	const fetch = useFetch()

	const clearSelection = () => {
		setPropertyRoof(defaultSchema)
		setPropertyWalls(defaultSchema)
		setPropertyFloor(defaultSchema)
		if (propertyImages.length) {
			setPropertyData({ Images: propertyImages })
		} else {
			setPropertyData({})
		}
	}

	useEffect(() => {
		let allPropertyFilled = propertyRoof.Type && propertyWalls.Type && propertyFloor.Type
		if (allPropertyFilled) {
			setPropertyData({
				Roof: propertyRoof,
				Walls: propertyWalls,
				Floors: propertyFloor,
				Images: propertyImages,
			})
		}
	}, [propertyRoof, propertyWalls, propertyFloor, propertyImages]) // eslint-disable-line

	const triggerFileUpload = () => propertyFileInput?.current?.click()

	const handleFileUpload = async files => {
		const file = files[0]
		setIsLoading(true)
		setLoadingText('Uploading...')
		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setPropertyImages([...propertyImages, s3Link])
	}

	const removeImage = img => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setPropertyImages([...propertyImages.filter(el => el !== img)])
				Swal.fire('Your file has been deleted')
			}
		})
	}

	const propertyUploadedImagesMap =
		propertyImages?.length > 0 &&
		propertyImages?.map((img, key) => (
			<FileUploaded key={key} imageUrl={img} onDelete={() => removeImage(img)} />
		))

	return (
		<>
			<Question
				icon="ceiling"
				name="Roof"
				radioKey="roof"
				value1={propertyRoof.Type === 'Kutcha'}
				value2={propertyRoof.Type === 'Pucca'}
				radio1OnChange={() => setPropertyRoof({ ...propertyRoof, Type: 'Kutcha' })}
				radio2OnChange={() => setPropertyRoof({ ...propertyRoof, Type: 'Pucca' })}
				inputValue={propertyRoof.Description || ''}
				inputOnChange={e => setPropertyRoof({ ...propertyRoof, Description: e.target.value })}
				compulsoryMaterialDesc={propertyRoof.Type === 'Kutcha'}
			/>
			<Question
				icon="wall"
				name="Walls"
				radioKey={`${id}-walls`}
				value1={propertyWalls.Type === 'Kutcha'}
				value2={propertyWalls.Type === 'Pucca'}
				radio1OnChange={() => setPropertyWalls({ ...propertyWalls, Type: 'Kutcha' })}
				radio2OnChange={() => setPropertyWalls({ ...propertyWalls, Type: 'Pucca' })}
				inputValue={propertyWalls.Description || ''}
				inputOnChange={e => setPropertyWalls({ ...propertyWalls, Description: e.target.value })}
				compulsoryMaterialDesc={propertyWalls.Type === 'Kutcha'}
			/>
			<Question
				icon="floor"
				name="Floor"
				radioKey={`${id}-floor`}
				value1={propertyFloor.Type === 'Kutcha'}
				value2={propertyFloor.Type === 'Pucca'}
				radio1OnChange={() => setPropertyFloor({ ...propertyFloor, Type: 'Kutcha' })}
				radio2OnChange={() => setPropertyFloor({ ...propertyFloor, Type: 'Pucca' })}
				inputValue={propertyFloor.Description || ''}
				inputOnChange={e => setPropertyFloor({ ...propertyFloor, Description: e.target.value })}
				compulsoryMaterialDesc={propertyFloor.Type === 'Kutcha'}
			/>
			<Row>
				<Col
					onClick={clearSelection}
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
				>
					<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
					<Text>Clear selection</Text>
				</Col>
			</Row>
			<Divider />
			<Text type="body1">Upload photos of each of the 4 corners of the building</Text>
			<>
				<div className="d-flex mt-4 mb-4">
					<HiddenFileInput
						ref={propertyFileInput}
						onChange={e => handleFileUpload(e.target.files)}
					/>
					<FileUploader onClick={triggerFileUpload} />
					{propertyUploadedImagesMap}
				</div>
			</>
		</>
	)
}

const BasementUI = ({ id = 'basement', setBasementData, Type, globalState, activeTab }) => {
	const prefillData = prefillMaterialDetails(Type, 'basement', globalState)
	const { Ceiling, Walls, Floors, Images } = prefillData

	const { setIsLoading, setLoadingText } = useLoadingOverlay()
	const [basementCeiling, setBasementCeiling] = useState({ ...defaultSchema, ...Ceiling })
	const [basementWalls, setBasementWalls] = useState({ ...defaultSchema, ...Walls })
	const [basementFloor, setBasementFloor] = useState({ ...defaultSchema, ...Floors })
	const [basementImages, setBasementImages] = useState([...(Images || [])])

	const fetch = useFetch()
	const basementFileInput = useRef()

	const clearSelection = () => {
		setBasementCeiling(defaultSchema)
		setBasementWalls(defaultSchema)
		setBasementFloor(defaultSchema)

		if (basementImages.length) {
			setBasementData({ Images: basementImages })
		} else {
			setBasementData({})
		}
	}

	useEffect(() => {
		let allBasementFilled = basementCeiling.Type && basementWalls.Type && basementFloor.Type
		if (allBasementFilled) {
			setBasementData({
				Ceiling: basementCeiling,
				Walls: basementWalls,
				Floors: basementFloor,
				Images: basementImages,
			})
		}
	}, [basementCeiling, basementWalls, basementFloor, basementImages]) // eslint-disable-line

	const triggerFileUpload = () => basementFileInput?.current?.click()

	const handleFileUpload = async files => {
		const file = files[0]
		setLoadingText('Uploading...')
		setIsLoading(true)
		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setBasementImages([...basementImages, s3Link])
	}

	const removeImage = img => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setBasementImages([...basementImages.filter(el => el !== img)])
				Swal.fire('Your file has been deleted')
			}
		})
	}
	const basementUploadedImagesMap =
		basementImages?.length > 0 &&
		basementImages?.map((img, key) => (
			<FileUploaded key={key} imageUrl={img} onDelete={() => removeImage(img)} />
		))

	return (
		<>
			<Question
				icon="ceiling"
				name="Ceiling"
				tooltip="Ceiling here referred to the roof of the basement"
				radioKey={`${id}-ceiling`}
				value1={basementCeiling.Type === 'Kutcha'}
				value2={basementCeiling.Type === 'Pucca'}
				radio1OnChange={() => setBasementCeiling({ ...basementCeiling, Type: 'Kutcha' })}
				radio2OnChange={() => setBasementCeiling({ ...basementCeiling, Type: 'Pucca' })}
				inputValue={basementCeiling.Description || ''}
				inputOnChange={e => setBasementCeiling({ ...basementCeiling, Description: e.target.value })}
				compulsoryMaterialDesc={basementCeiling.Type === 'Kutcha'}
			/>
			<Question
				icon="wall"
				name="Walls"
				radioKey={`${id}-walls`}
				value1={basementWalls.Type === 'Kutcha'}
				value2={basementWalls.Type === 'Pucca'}
				radio1OnChange={() => setBasementWalls({ ...basementWalls, Type: 'Kutcha' })}
				radio2OnChange={() => setBasementWalls({ ...basementWalls, Type: 'Pucca' })}
				inputValue={basementWalls.Description || ''}
				inputOnChange={e => setBasementWalls({ ...basementWalls, Description: e.target.value })}
				compulsoryMaterialDesc={basementWalls.Type === 'Kutcha'}
			/>
			<Question
				icon="floor"
				name="Floor"
				radioKey={`${id}-floor`}
				value1={basementFloor.Type === 'Kutcha'}
				value2={basementFloor.Type === 'Pucca'}
				radio1OnChange={() => setBasementFloor({ ...basementFloor, Type: 'Kutcha' })}
				radio2OnChange={() => setBasementFloor({ ...basementFloor, Type: 'Pucca' })}
				inputValue={basementFloor.Description || ''}
				inputOnChange={e => setBasementFloor({ ...basementFloor, Description: e.target.value })}
				compulsoryMaterialDesc={basementFloor.Type === 'Kutcha'}
			/>
			<Row>
				<Col
					onClick={clearSelection}
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
				>
					<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
					<Text>Clear selection</Text>
				</Col>
			</Row>
			<Divider />
			<Text type={'body1'}>Upload photos of each of the 4 corners of the basement</Text>
			<>
				<div className="d-flex mt-4 mb-4">
					<HiddenFileInput
						ref={basementFileInput}
						onChange={e => handleFileUpload(e.target.files)}
					/>
					<FileUploader onClick={triggerFileUpload} />
					{basementUploadedImagesMap}
				</div>
			</>
		</>
	)
}

const Question = ({
	icon,
	name,
	tooltip,
	radioKey,
	value1,
	value2,
	radio1OnChange,
	radio2OnChange,
	inputValue,
	inputOnChange,
	compulsoryMaterialDesc = false,
}) => {
	const displayTooltip = tooltip ? <Tooltip text={tooltip} icon="knowMore"></Tooltip> : null

	const displayInput =
		value1 || value2 ? (
			<Row className="mb-4">
				<Col>
					<Text className="mb-2">
						Please specify the material used
						{compulsoryMaterialDesc ? <span style={{ color: theme.colors.red }}>*</span> : null}
					</Text>
					<Input
						placeholder="Enter here"
						value={inputValue}
						onChange={inputOnChange}
						required={compulsoryMaterialDesc}
					/>
				</Col>
			</Row>
		) : null

	return (
		<>
			<Row className="mb-4 mt-4">
				<Col className="d-flex align-items-center">
					<Icon name={icon} className="me-3" />
					<Text type="body1" className="me-3">
						{name}
						<span style={{ color: theme.colors.red }}>*</span>
					</Text>
					{displayTooltip}
				</Col>
				<Col className="d-flex justify-content-around">
					<Radio
						text="Kutcha"
						name={`${radioKey}-kutcha`}
						value={value1}
						secondary
						onChange={radio1OnChange}
					/>
					<Radio
						text="Pucca"
						name={`${radioKey}-pucca`}
						value={value2}
						secondary
						onChange={radio2OnChange}
					/>
				</Col>
			</Row>
			{displayInput}
		</>
	)
}

const TitleText = styled(Text)`
	font-size: 24px;
	font-weight: 700;
	color: ${theme.colors.gray7};
`
