import { useState, useRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap'
import {
	MainContainer,
	Divider,
	theme,
	Button,
	Input,
	Text,
	Switch,
	Checkbox,
	PhoneNoInput,
	Space,
	Icon,
} from 'verak-ui'

import confirmationLottie from '../../assets/confirmationLottie.svg'
import { useFetch } from '../../api/apihook'
import { validateEmail } from '../../utilities/validateEmail'
import { useHistory } from 'react-router-dom'
import { useContextApi } from '../../context/Provider'
import WhatsappIcon from '../../assets/icons/whatsappIcon.svg'
import SMSIcon from '../../assets/icons/smsIcon.svg'

const rfqSubmitStatusEnum = {
	IDLE: null,
	SENDING: 'sending',
	SUCCESS: 'success',
	ERROR: 'error',
}

const breakdownEmail = email => {
	const emailParts = email.split('@')

	let emailExtension = ''
	if (emailParts[1].includes('.co.in')) {
		emailExtension = 'co.in'
	} else {
		emailExtension = emailParts[1]?.split('.')?.[1] || ''
	}

	return {
		name: emailParts?.[0] || '',
		domain: emailParts[1]?.split('.')?.[0] || '',
		extension: emailExtension ? `.${emailExtension}` : '',
	}
}

const SendRfqEmail = ({ changeToScreen2 }) => {
	const { sendRfqEmailPilot, getCustomerDetails } = useFetch()

	const [state, dispatch] = useContextApi()

	const history = useHistory()

	const rfqId = state?.quote?.quoteId || ''
	const isDraftRFQ = state?.rfq?.draftRFQ?._id
	const draftRFQ = state?.rfq?.draftRFQ

	const [email, setEmail] = useState('')
	const [noEmailAvailable, setNoEmailAvailable] = useState(false)
	const [emailValid, setEmailValid] = useState(false)

	const [rfqSubmitStatus, setRfqSubmitStatus] = useState(rfqSubmitStatusEnum.IDLE)
	const [whatsappConsent, setWhatsappConsent] = useState(false)
	const [altWhatsappNumber, setAltWhatsappNum] = useState('')

	const [emailExists, setEmailExists] = useState(false)
	const [waConsentExists, setWaConsentExists] = useState(false)
	const [altWaNumberExists, setAltWaNumberExists] = useState(false)

	const isValidAltNumber = altWhatsappNumber === '' || altWhatsappNumber?.length === 10

	const isSubmittingRfq = rfqSubmitStatus === rfqSubmitStatusEnum.SENDING
	const isRfqSubmitted = rfqSubmitStatus === rfqSubmitStatusEnum.SUCCESS

	const buttonLabel = isSubmittingRfq ? 'Submitting RFQ...' : 'Save and continue'

	let emailSubmitDisabled = false
	let altPhoneNumSubmitDisabled = false

	if (noEmailAvailable) {
		emailSubmitDisabled = false
	} else {
		emailSubmitDisabled = !emailValid
	}

	if (whatsappConsent) {
		altPhoneNumSubmitDisabled = !isValidAltNumber
	} else {
		altPhoneNumSubmitDisabled = false
	}

	const goBack = () => history.goBack()

	const emailAndWhatsappNotAvailable = noEmailAvailable && !whatsappConsent

	const submitDisabled =
		emailAndWhatsappNotAvailable ||
		emailSubmitDisabled ||
		altPhoneNumSubmitDisabled ||
		isSubmittingRfq ||
		isRfqSubmitted

	useEffect(() => {
		const checkClientDetails = async () => {
			const { ContactInfo } = await getCustomerDetails(rfqId)
			const { Email, WaPhone, WaConsent } = ContactInfo
			if (Email) {
				setEmail(Email)
				setEmailExists(true)
			} else if (isDraftRFQ && draftRFQ?.Contact?.Email) {
				setEmail(draftRFQ?.Contact?.Email)
				setEmailExists(true)
			}

			if (WaPhone) {
				let validWaPhone = WaPhone.replace('+91', '')
				setAltWhatsappNum(validWaPhone)
				setAltWaNumberExists(true)
			} else if (isDraftRFQ && draftRFQ?.Contact?.WaPhone) {
				let validWaPhone = draftRFQ?.Contact?.WaPhone.replace('+91', '')
				setAltWhatsappNum(validWaPhone)
				setAltWaNumberExists(true)
			}

			if (WaConsent) {
				setWhatsappConsent(WaConsent)
				setWaConsentExists(true)
			} else if (isDraftRFQ) {
				setWhatsappConsent(draftRFQ?.Contact?.WaConsent)
				setWaConsentExists(true)
			}
		}

		if (rfqId) {
			checkClientDetails()
		}
	}, [])

	const submitForm = async () => {
		setRfqSubmitStatus(rfqSubmitStatusEnum.SENDING)

		let clientEmail = email
		if (noEmailAvailable) {
			clientEmail = ''
		}
		let waNumber = ''
		if (whatsappConsent && altWhatsappNumber) {
			waNumber = `+91${altWhatsappNumber}`
		}

		const payload = {
			// email: clientEmail,
			waConsent: whatsappConsent,
			waPhone: waNumber,
		}
		if (clientEmail) {
			payload.email = clientEmail
		}

		const result = await sendRfqEmailPilot(payload)
		if (result?.message === 'ok') {
			setRfqSubmitStatus(rfqSubmitStatusEnum.SUCCESS)
			changeToScreen2()
		} else {
			setRfqSubmitStatus(rfqSubmitStatusEnum.ERROR)
		}
	}

	const handleNoEmailSwitch = () => setNoEmailAvailable(!noEmailAvailable)

	const handleWhatsappConsent = () => setWhatsappConsent(!whatsappConsent)

	const handleAltWhatsappNumberChange = e => {
		const value = e.target.value
		if (value.length <= 10) {
			setAltWhatsappNum(value)
		}
	}

	const setEmailValidity = v => setEmailValid(v)

	const goToHome = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'CLEAR_DATA',
			})
			resolve()
		})
		history.push('/')
	}

	return (
		<MainContainer
			headerBg={theme.colors.secondary}
			icon={'yes'}
			header={'Some more info'}
			divider={false}
			hasLeftBtn={false}
		>
			<Row>
				<Col className="d-flex flex-column align-items-center mb-5 mt-5">
					<FormContainer>
						<Text className="mb-3" fontSize="18px" fontWeight="700" color={'#333333'}>
							Please enter the customer email ID to send the RFQ details
						</Text>

						{!noEmailAvailable && (
							<EmailBreakdown
								emailExists={emailExists}
								email={email}
								setEmail={setEmail}
								noEmailAvailable={noEmailAvailable}
								setEmailValid={setEmailValidity}
							/>
						)}

						{!emailExists && (
							<EmailSwitchContainer className="d-flex justify-content-between p-2" style={{}}>
								<Text fontSize="14px" fontWeight="400" color="#999999">
									Client doesn’t have an Email ID
								</Text>
								<Switch value={noEmailAvailable} onChange={handleNoEmailSwitch} />
							</EmailSwitchContainer>
						)}

						<CustomDivider />

						<Text fontSize="14px" fontWeight="700" color={theme.colors.secondary}>
							Send updates to the clients on
							<span className="mx-1" style={{ color: theme.colors.primary }}>
								<img className="me-1" src={SMSIcon} alt="sms-available" />
								SMS
							</span>
							and:
						</Text>
						<Checkbox
							className="my-3"
							value={whatsappConsent}
							onChange={handleWhatsappConsent}
							disabled={waConsentExists}
							text={
								<Text className="d-flex" color={theme.colors.gray2} fontWeight="700">
									<img className="me-2" src={WhatsappIcon} alt="whatsapp-available" />
									Whatsapp
								</Text>
							}
						/>
						{whatsappConsent && (
							<>
								<Text className="mb-1" fontSize="14px" fontWeight="400" color={theme.colors.gray2}>
									Alternate phone number for <b>Whatsapp</b> (Optional)
								</Text>
								<PhoneNoInput
									placeholder="Enter"
									value={altWhatsappNumber}
									error={!isValidAltNumber}
									onChange={handleAltWhatsappNumberChange}
									showAlert={!isValidAltNumber}
									alertText="Incorrect phone number"
									disabled={altWaNumberExists}
								/>
							</>
						)}

						<div className="pt-3 d-flex align-items-center">
							<Button
								onClick={goBack}
								label="Back"
								style={{ width: '15%', height: '48px', padding: '12px 20px' }}
								secondary
								icon="leftArrow"
								iconAlign="left"
							/>
							<Button
								disabled={submitDisabled}
								style={{ width: '85%', padding: '12px 20px', height: '48px', marginLeft: '.8rem' }}
								label={buttonLabel}
								onClick={submitForm}
							/>
						</div>
					</FormContainer>
				</Col>
			</Row>
		</MainContainer>
	)
}

// exported to be used in quotes-pending > update flow
export const RfqEmailSent = ({ title, onProceed, extraInfo }) => {
	const [state, dispatch] = useContextApi()
	const history = useHistory()

	const goToHome = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'CLEAR_DATA',
			})
			dispatch({
				type: 'SET_CLIENT_EXISTS',
				data: { clientExists: false },
			})
			resolve()
		})

		// use the custom proceed function if passed
		if (typeof onProceed === 'function') {
			onProceed()
		} else {
			history.push('/')
		}
	}

	let updatedTitle = (
		<>
			RFQ details have been{' '}
			<span style={{ color: theme.colors.green }}>collected successfully</span> and sent to the
			Underwriter for review
		</>
	)
	if (title) {
		updatedTitle = title
	}

	let submissionImage = <ConfirmationSuccessImage className="my-3" src={confirmationLottie} />
	let rfqSubmissionMessage = (
		<>Once confirmed, the RFQ will be sent to the Insurer for Quote generation.</>
	)

	return (
		<MainContainer
			headerBg={theme.colors.secondary}
			icon={'yes'}
			header={'Progress!'}
			divider={false}
			hasLeftBtn={false}
		>
			<Row>
				<Col className="d-flex flex-column align-items-center mb-5">
					<Text
						className="mb-3 text-center"
						fontSize="24px"
						fontWeight="700"
						color={theme.colors.primary}
					>
						{updatedTitle}
					</Text>
					{submissionImage}
					<AnimatedDiv className="w-100 d-flex flex-column align-items-center">
						<Text
							className="mb-5 text-center"
							fontSize="18px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							{rfqSubmissionMessage}
						</Text>
						{extraInfo}
						{/* to display any extra info */}
						<Divider />
						<Button
							icon="rightArrow"
							iconAlign="right"
							label="Continue to home"
							onClick={goToHome}
						/>
					</AnimatedDiv>
				</Col>
			</Row>
		</MainContainer>
	)
}

export default function ReviewRFQ() {
	const [activeScreen, setActiveScreen] = useState('send-rfq-email')

	const changeToScreen2 = () => {
		setActiveScreen('rfq-email-sent')
	}

	const renderScreen1 =
		activeScreen === 'send-rfq-email' ? <SendRfqEmail changeToScreen2={changeToScreen2} /> : null

	const renderScren2 = activeScreen === 'rfq-email-sent' ? <RfqEmailSent /> : null

	return (
		<Container>
			{renderScreen1}
			{renderScren2}
		</Container>
	)
}

const EmailBreakdown = ({ email, setEmail, emailExists, setEmailValid, noEmailAvailable }) => {
	const emailNameRef = useRef(null)
	const domainRef = useRef(null)
	const extensionRef = useRef(null)

	const [emailName, setEmailName] = useState('')
	const [domain, setDomain] = useState('')
	const [extension, setExtension] = useState('')
	const [emailError, setEmailError] = useState('')

	const [state, dispatch] = useContextApi()

	const isExistingClient = state?.user?.clientExists

	const composedEmail = `${emailName}${emailName && domain ? '@' : ''}${domain}${extension}`

	useEffect(() => {
		setEmail(composedEmail)
	}, [composedEmail]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (emailExists) {
			const emailBreakdown = breakdownEmail(email)
			setEmailName(emailBreakdown.name)
			setDomain(emailBreakdown.domain)
			setExtension(emailBreakdown.extension)
		}
	}, [emailExists])

	const emailRules = {
		gmail: ['.com'],
		yahoo: ['.com', '.co.in', '.in'],
		hotmail: ['.com'],
		outlook: ['.com'],
		rediffmail: ['.com'],
	}

	const validateEmailRules = (emailName, domain, extension) => {
		if (emailName === '' || domain === '' || extension === '') {
			return false
		}

		// is a custom domain
		if (!Object.keys(emailRules).includes(domain)) {
			return true
		}

		const domainRules = emailRules[domain]
		if (!domainRules) {
			return false
		}

		const isValidExtension = domainRules.includes(extension)
		return isValidExtension
	}

	const checkEmailValidity = (emailName, domain, extension, email) => {
		if (!email) {
			if (!!emailError) {
				setEmailError('') // reset error
			}
			return false
		}

		if (!emailName || !domain || !extension) {
			if (!emailError) {
				setEmailError('Invalid email address')
			}
			return false
		} else if (!validateEmailRules(emailName, domain, extension)) {
			if (!emailError) {
				setEmailError(`'${domain}' cannot have '${extension}' as extension`)
			}
			return false
		} else if (!validateEmail(composedEmail)) {
			if (!emailError) {
				setEmailError('Invalid email address')
			}
			return false
		}

		if (!!emailError) {
			setEmailError('') // reset email error
		}
		return true
	}

	const isEmailValid = checkEmailValidity(emailName, domain, extension, composedEmail)

	useEffect(() => {
		setEmailValid(isEmailValid)
	}, [isEmailValid]) // eslint-disable-line react-hooks/exhaustive-deps

	const autocompleteExtensionByDomain = domain => {
		if (!Object.keys(emailRules).includes(domain)) {
			return '.'
		}

		const domainRules = emailRules[domain]
		if (!domainRules) {
			return '.'
		}

		return domainRules[0] // return most common extension for that domain
	}

	const handleEmailChange = (fieldName, event) => {
		const value = event.target.value
		if (fieldName === 'emailName') {
			if (value.length && value.indexOf('@') === value.length - 1) {
				domainRef.current.focus()
			}
			setEmailName(value.replace('@', ''))
		} else if (fieldName === 'domain') {
			if (value.length && value.indexOf('.') === value.length - 1) {
				extensionRef.current.focus()
			}
			setDomain(value.replace('.', ''))
			if (value.length) {
				let autocompleteSuggestion = autocompleteExtensionByDomain(value)
				setExtension(autocompleteSuggestion)
			}
		} else if (fieldName === 'extension') {
			setExtension(value)
		}
	}

	const handleSuggestion = (suggestionName, event) => {
		if (suggestionName === 'domain' && event.target.textContent.length <= 10) {
			setDomain(event.target.textContent)
			let autocompleteSuggestion = autocompleteExtensionByDomain(event.target.textContent)
			setExtension(autocompleteSuggestion)
		} else if (event.target.textContent.length <= 6) {
			setExtension(event.target.textContent)
		}
	}

	let composedEmailPreview
	if (!noEmailAvailable && composedEmail) {
		composedEmailPreview = (
			<div
				className="d-flex flex-column mt-2 p-3 rounded w-100"
				style={{
					background: isEmailValid ? '#D8F1C0' : theme.colors.secondaryLightRed,
					border: `.5px solid ${isEmailValid ? theme.colors.green : theme.colors.red}`,
				}}
			>
				<div className="d-flex align-items-center justify-content-between">
					<Text
						color={isEmailValid ? theme.colors.green : theme.colors.red}
						fontSize="18px"
						fontWeight="700"
					>
						{composedEmail}
					</Text>
					{isEmailValid && <Icon name="tickCircle" />}
				</div>
				<Text
					className={emailError ? 'mt-2' : ''}
					color={theme.colors.red}
					fontSize="13px"
					fontWeight="700"
				>
					{emailError}
				</Text>
			</div>
		)
	}

	const domains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'rediffmail']
	const extensions = ['.com', '.co.in', '.in']

	let domainSuggestions, extensionSuggestions
	if (!noEmailAvailable) {
		const domainSuggestionsList = domains.map(domain => (
			<SuggestionBadge type="domain" label={domain} key={domain} />
		))

		const extensionSuggestionsList = extensions.map(extension => (
			<SuggestionBadge type="extension" label={extension} key={extension} />
		))

		domainSuggestions = (
			<div
				onClick={event => {
					if (emailExists && !isExistingClient) {
						handleSuggestion('domain', event)
					}
				}}
				className="mt-2"
				style={{ opacity: isExistingClient && emailExists ? 0.5 : 1 }}
			>
				{domainSuggestionsList}
			</div>
		)

		extensionSuggestions = (
			<div
				onClick={event => {
					if (emailExists && !isExistingClient) {
						handleSuggestion('extension', event)
					}
				}}
				className="mt-2"
				extension={extension}
				style={{ opacity: isExistingClient && emailExists ? 0.5 : 1 }}
			>
				{extensionSuggestionsList}
			</div>
		)
	}

	return (
		<div className="w-100 d-flex flex-column p-3 mb-3 rounded" style={{ background: '#F2F2F2' }}>
			<div className="d-flex align-items-start">
				<div>
					<Input
						ref={emailNameRef}
						value={emailName}
						onChange={e => handleEmailChange('emailName', e)}
						style={{ width: '100%' }}
						placeholder="Enter"
						label={
							<>
								Email ID <span style={{ color: '#eb5757' }}>*</span>
							</>
						}
						disabled={isExistingClient && (noEmailAvailable || emailExists)}
					/>
				</div>
				<div className="mt-4 pt-2 px-2">
					<Text fontSize="24px" fontWeight="700">
						@
					</Text>
				</div>
				<div className="d-flex flex-column">
					<Input
						style={{ width: '100%' }}
						placeholder="Enter"
						label={<span className="invisible">*</span>}
						ref={domainRef}
						disabled={isExistingClient && (noEmailAvailable || emailExists)}
						value={domain}
						onChange={e => handleEmailChange('domain', e)}
					/>
					{domainSuggestions}
				</div>
				<Space x={1} />
				<div className="d-flex flex-column">
					<Input
						style={{ width: '100%' }}
						placeholder="Enter"
						label={<span className="invisible">*</span>}
						ref={extensionRef}
						disabled={isExistingClient && (noEmailAvailable || emailExists)}
						value={extension}
						onChange={e => handleEmailChange('extension', e)}
					/>
					{extensionSuggestions}
				</div>
			</div>

			{composedEmailPreview}
		</div>
	)
}

const SuggestionBadge = ({ type, label }) => {
	const bgColor = {
		domain: '#d3defd',
		extension: '#F9CDE2',
	}
	return (
		<SuggestionBadgeContainer className="badge" bgColor={bgColor[type]}>
			{label}
		</SuggestionBadgeContainer>
	)
}

const FormContainer = styled.div`
	width: min(700px, 95%);
	padding: 16px;
	border: 1px solid #f78670;
	border-radius: 8px;
`

const EmailSwitchContainer = styled.div`
	background: ${theme.colors.secondary3};
	border-radius: 6px;
`

const CustomDivider = styled(Divider)`
	margin: 1rem auto;
	width: 75%;
	border-width: 0px;
	@media (max-width: 768px) {
		width: 100%;
	}
`

const lottieAnimation = keyframes`
from{
  transform: scale(1);
}
to{
  transform: scale(.5);
}
`

const fadeIn = keyframes`
from{
opacity: 0.3;
}
to{
  opacity: 1;
}
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const AnimatedDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`

// suggestion badge
const SuggestionBadgeContainer = styled.div`
	background: ${({ bgColor }) => bgColor};
	color: ${theme.colors.primary};
	box-shadow: 0px 1px 1px #000000;
	border: 0.6px solid transparent;
	font-size: 14px;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 6px;
	cursor: pointer;
	&:hover {
		border: 0.6px solid ${theme.colors.primary};
	}
`
