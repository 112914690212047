/**
 *
 * @param {QuoteTypeDef} quote
 * @returns {{ Insurer: number, Verak: number, Total: number }}
 * @todo: Handle multiple units of hardware
 */
const getQuotePriceComponents = quote => {
	const { PremiumBreakdown, Addons = [], IntegratedCosts = [], Fees = [] } = quote
	// Addon premium not to be added if PremiumBreakdown is NOT available or is REJECTED
	// Ref - https://www.notion.so/How-are-we-doing-summation-of-quote-amounts-Super-confusing-245e075684784b5ebe6e8bf18102975a
	const insurerPremium = getInsurerPremium(PremiumBreakdown, Addons)

	const otherCosts = Math.round(sum(IntegratedCosts) + sum(Fees))

	return {
		Insurer: insurerPremium,
		Verak: otherCosts,
		Total: otherCosts + insurerPremium,
	}
}

/**
 * @typedef {{
 * 	Addons: { Name: string, Amount: number }[],
 * 	PremiumBreakdown: { TotalPremium: number, Available: boolean },
 * 	IntegratedCosts: { Amount: number }[],
 * 	Fees: { Amount: number }[]
 * }} QuoteTypeDef
 */

/**
 *
 * @param {QuoteTypeDef} quote
 * @returns {number}
 */
const calculateTotalPremium = quote => {
	const { Total } = getQuotePriceComponents(quote)
	return Total
}

const getInsurerPremium = (PremiumBreakdown, Addons) => {
	return Math.round(
		PremiumBreakdown?.TotalPremium +
			(PremiumBreakdown?.Available ? sum(Addons.filter(a => !a?.Rejected)) : 0)
	)
}

const sum = arr => arr.reduce((prev, curr) => prev + curr.Amount || 0, 0)

export default calculateTotalPremium
