import styled, { css } from 'styled-components'
import { theme, Button, Text, Icon } from 'verak-ui'

const BreadCrumbComponent = ({
	location,
	nextLabel,
	nextOnClick,
	showNextButton = true,
	containerStyles = {},
}) => {
	return (
		<BreadCrumbWrapper
			className="d-flex justify-content-between align-items-center"
			style={containerStyles}
		>
			<div className="d-flex align-items-center">
				<CustomText style={{ opacity: '.6' }}>Vyapar Suraksha policy</CustomText>
				&nbsp; <Icon name="rightArrow" color={theme.colors.primary} /> &nbsp;
				<CustomText>{location}</CustomText>
			</div>

			{showNextButton && (
				<Button
					icon="rightArrow"
					iconAlign="right"
					iconColor="#fff"
					label={nextLabel}
					onClick={nextOnClick}
				/>
			)}
		</BreadCrumbWrapper>
	)
}

// styles
const MainStickyContainer = styled.div`
	position: relative;
	width: 100%;
`

const CustomText = styled(Text)`
	font-size: 18px;
	font-weight: 700;
	color: ${theme.colors.gray7};
`

const BreadCrumbWrapper = styled.div`
	padding: 16px 24px;
	/* margin-top: 60px; */
	z-index: 2;
	/* margin-bottom:30px; */
	width: 100%;
	background: ${theme.colors.secondary2};
	transition: all 0.3s ease;
`

const tabWrapperStickyCss = top => css`
	position: sticky;
	top: ${top || '188px'};
	transform: translateY(0px);
	transition: all 0.3s ease;
`
const tabWrapperHideCss = css`
	transform: translateY(-500px);
`

const TabWrapper = styled.div`
	position: relative;
	> * {
		&:first-child {
			z-index: 10;
			${p => (p.scrollUp ? tabWrapperStickyCss(p.top) : tabWrapperHideCss)}
		}
	}
`

const innerTabWrapperStickyCss = top => css`
	position: sticky;
	top: ${top || '236px'};
	transform: translateY(0px);
	transition: all 0.3s ease;
`
const InnerTabWrapper = styled.div`
	position: relative;
	> * {
		&:first-child {
			z-index: 5;
			transform: translateY(0px);
			${p => (p.scrollUp ? innerTabWrapperStickyCss(p.top) : tabWrapperHideCss)};
		}
	}
`

const mainContainerStyles = {
	margin: '0',
	width: '100%',
	position: 'relative',
}

const breadcrumbStyles = {
	margin: '0',
	position: 'sticky',
	top: '60px',
}

const mainContainerHeaderStyles = {
	margin: '0',
	position: 'sticky',
	top: '132px',
	zIndex: '2',
	borderRadius: '0',
	padding: '1rem 1.5rem',
	transform: 'translateY(0px)',
}

const mainContainerHeaderTextStyle = {
	color: theme.colors.primary,
	fontSize: '24px',
	fontWeight: '700',
}

const reviewComponentStyles = {
	width: 'min(1100px, 100%)',
	margin: 'auto',
	padding: '0 1rem',
}

function totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) {
	let totalValue = 0
	if (sumInsuredBreakdown.length) {
		totalValue = sumInsuredBreakdown
			.map(el => el.Amount)
			.reduce((prev, curr) => parseInt(prev || 0) + parseInt(curr || 0))
	}

	return parseInt(totalValue)
}

function getSumInsuredFromRiskLocation(RiskLocation) {
	let SumInsured = []

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		// check is property multiple --
		if (RiskLocation?.property?.levels?.length > 0) {
			RiskLocation.property.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (
			RiskLocation?.property?.SumInsured &&
			RiskLocation?.property?.SumInsured.length > 0
		) {
			// for single or whole property
			SumInsured = [...RiskLocation?.property?.SumInsured]
		}
		// check is basement multiple --
		if (RiskLocation?.basement?.levels?.length > 0) {
			RiskLocation.basement.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (
			RiskLocation?.basement?.SumInsured &&
			RiskLocation?.basement?.SumInsured.length > 0
		) {
			// for single or whole basement
			SumInsured = [...SumInsured, ...(RiskLocation?.basement?.SumInsured || [])]
		}
	} else {
		// for multiple property or basement
		if (RiskLocation?.levels?.length > 0) {
			RiskLocation.levels.forEach(el => {
				SumInsured = [...SumInsured, ...el.SumInsured]
			})
		} else if (RiskLocation?.SumInsured && RiskLocation?.SumInsured.length > 0) {
			// for single or whole property or basement
			SumInsured = [...(RiskLocation?.SumInsured || [])]
		}
	}

	return SumInsured
}

function totalSumInsuredForSingleRiskLocation(RiskLocation) {
	let totalSumSingleRiskLocation = 0
	const sumInsuredForAllRLs = getSumInsuredFromRiskLocation(RiskLocation) || []

	sumInsuredForAllRLs.forEach(el => {
		if (el.Amount && el.Name !== 'MaxValSingleItem') {
			totalSumSingleRiskLocation += parseInt(el?.Amount || 0)
		}
	})

	return totalSumSingleRiskLocation
}

const calcSIforOneLoc = (RiskLocation, propertyType, levelIndex) => {
	// all data calculated here is for a "single" level or incase of "whole property/basement"
	let sumInsured = 0
	const calcSI = loc => {
		if (levelIndex !== -1) {
			// has levels
			const SIData = loc?.levels[levelIndex]?.SumInsured
			if (SIData) {
				for (const item in SIData) {
					if (SIData[item]?.Name !== 'MaxValSingleItem') {
						sumInsured = sumInsured + parseInt(SIData[item]?.Amount || 0)
					}
				}
			}
		} else if (loc?.SumInsured) {
			for (const item in loc?.SumInsured) {
				if (loc?.SumInsured[item]?.Name !== 'MaxValSingleItem') {
					sumInsured = sumInsured + parseInt(loc?.SumInsured[item]?.Amount || 0)
				}
			}
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType.toLowerCase() === 'property') {
			calcSI(RiskLocation?.property)
		} else {
			calcSI(RiskLocation?.basement)
		}
	} else {
		calcSI(RiskLocation)
	}

	return sumInsured
}

const calcStockSIForOneLoc = (RiskLocation, propertyType, levelIndex) => {
	let stockSumInsured = 0

	const calcStockForOneLoc = detailsList => {
		for (const item in detailsList) {
			stockSumInsured =
				stockSumInsured +
				parseInt(detailsList[item]?.AmountClosed || 0) +
				parseInt(detailsList[item]?.AmountOpen || 0)
		}
	}

	const calcStockSI = loc => {
		if (levelIndex !== -1) {
			// has levels
			const stockData = loc?.levels[levelIndex]?.Stock
			if (stockData?.Present) {
				calcStockForOneLoc(stockData?.Details)
			}
		} else if (loc?.Stock?.Present) {
			calcStockForOneLoc(loc?.Stock?.Details)
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType.toLowerCase() === 'property') {
			calcStockSI(RiskLocation?.property)
		} else {
			calcStockSI(RiskLocation?.basement)
		}
	} else {
		calcStockSI(RiskLocation)
	}

	return stockSumInsured
}

const calcGoodsInTrustSIForOneLoc = (RiskLocation, propertyType, levelIndex) => {
	let goodsInTrustSumInsured = 0

	const calcGoodsInTrustPropertyStock = goodsInTrustData => {
		const goodsProperytData = goodsInTrustData?.Property
		if (goodsProperytData) {
			for (const item in goodsProperytData) {
				if (goodsProperytData[item]?.Name !== 'MaxValSingleItem') {
					goodsInTrustSumInsured =
						parseInt(goodsInTrustSumInsured || 0) + parseInt(goodsProperytData[item]?.Amount || 0)
				}
			}
		}
		// stock section
		const goodsStockData = goodsInTrustData?.Stock
		if (goodsStockData) {
			for (const item in goodsStockData) {
				const amountClosed = parseInt(goodsStockData?.[item]?.AmountClosed || 0)
				const amountOpen = parseInt(goodsStockData?.[item]?.AmountOpen || 0)
				goodsInTrustSumInsured = goodsInTrustSumInsured + amountClosed + amountOpen
			}
		}
	}

	const calcGoodsInTrustSI = loc => {
		if (levelIndex !== -1) {
			// has levels
			const goodsInTrustData = loc?.levels[levelIndex]?.GoodsInTrust
			if (goodsInTrustData?.Present) {
				calcGoodsInTrustPropertyStock(goodsInTrustData)
			}
		} else if (loc?.GoodsInTrust?.Present) {
			calcGoodsInTrustPropertyStock(loc?.GoodsInTrust)
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType.toLowerCase() === 'property') {
			calcGoodsInTrustSI(RiskLocation?.property)
		} else {
			calcGoodsInTrustSI(RiskLocation?.basement)
		}
	} else {
		calcGoodsInTrustSI(RiskLocation)
	}

	return goodsInTrustSumInsured
}

const goodsInTrustSIForAllLoc = (RiskLocation, propertyType, levelIndex) => {
	// all data calculated here is for a "single" level or incase of "whole property/basement"
	let sumInsured = 0
	const calcSI = loc => {
		if (levelIndex !== -1) {
			// has levels
			loc?.levels?.forEach(locItem => {
				const SIData = locItem?.GoodsInTrust?.Property
				if (SIData) {
					for (const item in SIData) {
						if (SIData[item]?.Name !== 'MaxValSingleItem') {
							sumInsured = sumInsured + parseInt(SIData[item]?.Amount || 0)
						}
					}
				}
			})
		} else if (loc?.GoodsInTrust?.Property) {
			for (const item in loc?.GoodsInTrust?.Property) {
				if (loc?.GoodsInTrust?.Property[item]?.Name !== 'MaxValSingleItem') {
					sumInsured = sumInsured + parseInt(loc?.GoodsInTrust?.Property[item]?.Amount || 0)
				}
			}
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType.toLowerCase() === 'property') {
			calcSI(RiskLocation?.property)
		} else {
			calcSI(RiskLocation?.basement)
		}
	} else {
		calcSI(RiskLocation)
	}

	return sumInsured
}

const goodsInTrustStocksForAllLoc = RiskLocation => {
	// all data calculated here is for a "single" level or incase of "whole property/basement"
	let allStockItems = []
	const getStocks = loc => {
		if (loc?.levels?.length) {
			// has levels
			loc?.levels?.forEach(locItem => {
				const stock = locItem?.GoodsInTrust?.Stock
				if (stock?.length) {
					stock.forEach(el => {
						allStockItems.push(el)
					})
				}
			})
		} else if (loc?.GoodsInTrust?.Stock) {
			loc?.GoodsInTrust?.Stock?.forEach(el => {
				allStockItems.push(el)
			})
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		// populate all stocks available, first for property and then for basement
		getStocks(RiskLocation?.property)
		getStocks(RiskLocation?.basement)
	} else {
		getStocks(RiskLocation)
	}

	return allStockItems
}

const isGoodsInTrustPresentForSomeLoc = (RiskLocation, propertyType, levelIndex) => {
	// all data calculated here is for a "single" level or incase of "whole property/basement"
	let present = false
	const checkGoodsInTrust = loc => {
		if (levelIndex !== -1) {
			// has levels
			present = loc?.levels?.some(locItem => {
				const isPresentForLvl = locItem?.GoodsInTrust?.Present
				return Boolean(isPresentForLvl)
			})
		} else if (loc?.GoodsInTrust?.Present) {
			present = true
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType.toLowerCase() === 'property') {
			checkGoodsInTrust(RiskLocation?.property)
		} else {
			checkGoodsInTrust(RiskLocation?.basement)
		}
	} else {
		checkGoodsInTrust(RiskLocation)
	}

	return present
}

function generateSumInsuredBreakdown(RiskLocations) {
	let allSumInsured = []
	let sumInsuredValues = {}

	RiskLocations.forEach(el => {
		const sumList = getSumInsuredFromRiskLocation(el)
		allSumInsured = [...allSumInsured, ...sumList]
	})

	allSumInsured.forEach(({ Name, Amount }) => {
		if (Name !== 'MaxValSingleItem') {
			// max value of an item shouldn't be included in calculations
			if (!sumInsuredValues[Name]) {
				sumInsuredValues[Name] = { Name, Amount }
			} else {
				sumInsuredValues[Name].Amount =
					parseInt(sumInsuredValues[Name].Amount || 0) + parseInt(Amount || 0)
			}
		}
	})

	const result = Object.keys(sumInsuredValues).map(sumInsuredName => {
		return sumInsuredValues[sumInsuredName]
	})

	return result
}

const getStockSumForSingleLocation = RiskLocation => {
	if (RiskLocation?.Stock?.Present && RiskLocation?.Stock?.Details?.length > 0) {
		const singleRiskLocationStockSum = RiskLocation.Stock?.Details.map(st =>
			parseInt(parseInt(st?.AmountClosed) + parseInt(st?.AmountOpen))
		).reduce((prev, curr) => parseInt(prev) + parseInt(curr))

		return singleRiskLocationStockSum
	}
	return 0
}

const getStockSumForAllRiskLocations = RiskLocations => {
	if (RiskLocations?.length > 0) {
		const allRiskLocationStockSum = RiskLocations.map(rl =>
			getStockSumForSingleLocation(rl)
		).reduce((prev, curr) => parseInt(prev) + parseInt(curr))

		return allRiskLocationStockSum
	}
	return 0
}

const isManufCategory = (OccupancyItem, cachedSubCategories) => {
	const MANUFACTURING_CATEGORY = 'Manufacturing or other Industrial Property'

	if (!!cachedSubCategories) {
		const isManufacturing = cachedSubCategories?.find(
			item => item.Description === OccupancyItem.SubCategory
		)

		return isManufacturing?.Type === MANUFACTURING_CATEGORY
	}
	return false
}

const checkForPrimarySecondaryOcc = (data, cachedSubCategories) => {
	let primaryOccupancyCheck = false,
		secondaryOccupancyCheck = false

	if (data?.levels) {
		secondaryOccupancyCheck = data?.levels?.some(el =>
			el?.SecondaryOccupancy?.some(item => isManufCategory(item, cachedSubCategories))
		)
		primaryOccupancyCheck = data?.levels?.some(el =>
			isManufCategory(el?.PrimaryOccupancy || {}, cachedSubCategories)
		)
	} else {
		secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item =>
			isManufCategory(item, cachedSubCategories)
		)
		primaryOccupancyCheck = isManufCategory(data?.PrimaryOccupancy || {}, cachedSubCategories)
	}
	return secondaryOccupancyCheck || primaryOccupancyCheck
}

const checkIndustrialOrManufacturing = (data, cachedSubCategories) => {
	const { Type, property, basement } = data || {}

	const isPropertyBasement = Type === 'PROPERTYBASEMENT'
	let isIndustrialOrManuf = false

	if (isPropertyBasement) {
		isIndustrialOrManuf = checkForPrimarySecondaryOcc(property, cachedSubCategories)
		isIndustrialOrManuf =
			isIndustrialOrManuf || checkForPrimarySecondaryOcc(basement, cachedSubCategories)
	} else {
		isIndustrialOrManuf = checkForPrimarySecondaryOcc(data, cachedSubCategories)
	}
	return isIndustrialOrManuf
}

// category is the isDwellings callback
const checkForPrimaryAndSecondaryOcc = (data, checkCategory, cachedSubCategories) => {
	let secondaryOccupancyCheck = false,
		primaryOccupancyCheck = false
	if (data?.levels) {
		secondaryOccupancyCheck = data?.levels?.some(el =>
			el?.SecondaryOccupancy?.some(item => checkCategory(item, cachedSubCategories))
		)
		primaryOccupancyCheck = data?.levels?.some(el =>
			checkCategory(el?.PrimaryOccupancy || {}, cachedSubCategories)
		)
	} else {
		secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item =>
			checkCategory(item, cachedSubCategories)
		)
		primaryOccupancyCheck = checkCategory(data?.PrimaryOccupancy || {}, cachedSubCategories)
	}
	return secondaryOccupancyCheck || primaryOccupancyCheck
}

const isDwellingsCategory = (OccupancyItem, cachedSubCategories) => {
	const DWELLINGS_CATEGORY = 'Dwellings, Office, Shop or Hotel'

	if (!!cachedSubCategories) {
		const isManufacturing = cachedSubCategories?.find(
			item => item.Description === OccupancyItem.SubCategory
		)

		return isManufacturing?.Type === DWELLINGS_CATEGORY
	}
	return false
}

// Check for dwellings -> code should technically be dynamic enough to handle even other primary occupancy checks
const checkForTypeOfOccupancy = (data, cachedSubCategories) => {
	const { Type, property, basement } = data || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let isDwellings = false

	if (isPropertyBasement) {
		isDwellings = checkForPrimaryAndSecondaryOcc(property, isDwellingsCategory, cachedSubCategories)
		isDwellings =
			isDwellings ||
			checkForPrimaryAndSecondaryOcc(basement, isDwellingsCategory, cachedSubCategories)
	} else {
		isDwellings = checkForPrimaryAndSecondaryOcc(data, isDwellingsCategory, cachedSubCategories)
	}
	return isDwellings
}

const goodsInTrustTotalSIForSingleRiskLoc = RiskLocation => {
	let totalSumInsured = 0

	const calcSIForSingleLoc = locItem => {
		// property section
		const goodsProperytData = locItem?.GoodsInTrust?.Property
		if (goodsProperytData) {
			for (const item in goodsProperytData) {
				if (goodsProperytData[item]?.Name !== 'MaxValSingleItem') {
					totalSumInsured = totalSumInsured + parseInt(goodsProperytData[item]?.Amount || 0)
				}
			}
		}
		// stock section
		const goodsStockData = locItem?.GoodsInTrust?.Stock
		if (goodsStockData) {
			for (const item in goodsStockData) {
				const amountClosed = parseInt(goodsStockData?.[item]?.AmountClosed || 0)
				const amountOpen = parseInt(goodsStockData?.[item]?.AmountOpen || 0)
				totalSumInsured = totalSumInsured + amountClosed + amountOpen
			}
		}
	}

	const calcSI = loc => {
		// has levels
		if (loc?.levels?.length) {
			loc?.levels?.forEach(calcSIForSingleLoc)
		} else {
			calcSIForSingleLoc(loc)
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		calcSI(RiskLocation?.property)
		calcSI(RiskLocation?.basement)
	} else {
		calcSI(RiskLocation)
	}

	return totalSumInsured
}

const goodsInTrustTotalSIForAllRiskLocations = RiskLocations => {
	let totalSIAmount = 0

	RiskLocations?.forEach(riskLocation => {
		totalSIAmount = totalSIAmount + goodsInTrustTotalSIForSingleRiskLoc(riskLocation)
	})

	return totalSIAmount
}

const getClaimsHistoryStatusString = status => {
	switch (status) {
		case 'PENDING':
			return 'Pending'
		case 'SETTLED':
			return 'Settled'
		case 'ARBITRATION':
			return 'In Arbitration'
		default:
			return ''
	}
}

const getClaimsHistoryDamageString = damageType => {
	switch (damageType) {
		case 'BUILDING_ONLY':
			return 'Building only'
		case 'STOCK_CONTENTS':
			return 'Stock & contents only'
		case 'BUILDING_STOCK_CONTENTS':
			return 'Building, stock & contents'
		default:
			return ''
	}
}

const getMonthNameFromNumber = monthNum => {
	const monthList = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]

	const monthString = monthList?.[monthNum - 1] || ''

	return monthString
}

export {
	mainContainerStyles,
	mainContainerHeaderStyles,
	mainContainerHeaderTextStyle,
	breadcrumbStyles,
	reviewComponentStyles,
	MainStickyContainer,
	TabWrapper,
	InnerTabWrapper,
	BreadCrumbComponent,
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
	totalSumInsuredForSingleRiskLocation,
	getStockSumForAllRiskLocations,
	calcSIforOneLoc,
	goodsInTrustSIForAllLoc,
	goodsInTrustStocksForAllLoc,
	goodsInTrustTotalSIForSingleRiskLoc,
	goodsInTrustTotalSIForAllRiskLocations,
	isGoodsInTrustPresentForSomeLoc,
	checkIndustrialOrManufacturing,
	getMonthNameFromNumber,
	getClaimsHistoryDamageString,
	getClaimsHistoryStatusString,
	checkForTypeOfOccupancy,
	calcStockSIForOneLoc,
	calcGoodsInTrustSIForOneLoc,
}
