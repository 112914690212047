import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../components/SecondaryNavbar'
import TimelineView from '../../../components/Timeline'
import { useHistory, useLocation } from 'react-router'
import { useState, useEffect } from 'react'
import { useFetch } from '../../../api/apihook'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../../FinalReview/finalReviewUtils'
import { calculateTotalStockVal } from '../../../utilities/calculateTotalStockVal'
import TickCircleFilled from '../../../assets/icons/greenTickCircleFilled.svg'
import TimelineWarningIcon from '../../../assets/icons/timelineWarningIcon.svg'

export default function PolicyConfirmationPending() {
	const history = useHistory()

	const fetch = useFetch()
	const { state } = useLocation()

	const [quotes, setQuotes] = useState([])
	const [totalSumInsured, setTotalSumInsured] = useState('')

	const fetchRFQandQuotes = async rfqId => {
		let rfqDetails = await fetch.getQuote(rfqId)

		const toMerge = {
			...rfqDetails,
			quoteId: rfqId,
		}
		const mergedData = fetch.mergeRiskLocations(toMerge)
		const sumInsuredBreakdown = generateSumInsuredBreakdown(mergedData.RiskLocations)
		const stockValue = calculateTotalStockVal(mergedData)

		const totalSumInsured =
			totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) + (stockValue || 0)

		const policyCover = totalSumInsured
		const policyDuration = mergedData.Cover.Duration

		const result = await fetch.fetchQuotes(rfqId)
		if (result?.quotes?.length > 0) {
			const modifiedQuotes = result.quotes.map(quote => {
				return {
					...quote,
					Cover: policyCover,
					Duration: policyDuration,
				}
			})
			setQuotes(modifiedQuotes)
			setTotalSumInsured(totalSumInsured)
		}
	}

	useEffect(() => {
		fetchRFQandQuotes(state?.uid)
	}, []) // eslint-disable-line

	let furtherRequirementsExist = false
	if (quotes?.length > 0) {
		for (let quote of quotes) {
			if (
				quote?.FurtherRequirements.length > 0 &&
				quote?.FurtherRequirements?.every(item => !item?.Answer)
			) {
				furtherRequirementsExist = true
				break
			}
		}
	}

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Overview, {state?.uid} - {state?.ClientName} - {state?.PolicyType}
		</Text>
	)

	const viewQuotes = () => {
		if (!!quotes) {
			history.push(`/rfq-status/quotes-ready/compare-quotes`, { ...state, quotes, totalSumInsured })
		}
	}

	const backToQuotesInProgress = () => {
		history.push(`/quotes-in-progress`)
	}

	const timelineItems = [
		{
			icon: <img src={TickCircleFilled} alt="tick-circle-filled" height="40px" width="40px" />,
			id: 'client-verification',
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Client Verification
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Complete
							</Text>
						</div>
					</div>
				</div>
			),
		},
		{
			icon: <img src={TickCircleFilled} alt="tick-circle-filled" height="40px" width="40px" />,
			id: 'rfq-submitted',
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							RFQ submitted
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Complete
							</Text>
						</div>
					</div>
				</div>
			),
		},
		{
			icon: (
				<img
					src={furtherRequirementsExist ? TimelineWarningIcon : TickCircleFilled}
					alt="tick-circle-filled"
					height="40px"
					width="40px"
				/>
			),
			id: 'quotes-generated',
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Quotes generated
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								{furtherRequirementsExist ? 'Missing Information' : 'Quotes available'}
							</Text>
						</div>
					</div>

					<Button
						disabled={quotes?.length === 0}
						style={{ height: '56px' }}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
						onClick={viewQuotes}
					/>
				</div>
			),
		},
		{
			disabled: false,
			id: 'purchase',
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2 align-items-center">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Client payment received. Insurer confirmation pending!
						</Text>
					</div>
				</div>
			),
		},
	]

	const timelineView = (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
				onGoBack={backToQuotesInProgress}
			/>

			<div
				style={{
					width: '95%',
				}}
				className="container h-100 p-3 mt-2 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.primary}>
					Client payment received. Insurer confirmation pending!
				</Text>

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)

	return timelineView
}
