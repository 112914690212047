import { useEffect, useState } from 'react'
import { PageContentAndNavbarWrapper, SecondaryNavbar, Text, theme } from 'verak-ui'
import { useFetch } from '../../api/apihook'
import CardItem from './CardItem'

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Container, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router'
import FilterButton from '../SQS/QuoteInProgressComponents/FilterButton'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const getChart = (id, self, benchmark, leastValue, maxValue, yAxisFunc) => {
	if (!leastValue) {
		leastValue = self > benchmark ? benchmark : self
	}
	if (!maxValue) {
		maxValue = self < benchmark ? benchmark : self
	}

	let toSetLeastValue = Math.round(leastValue) - 10

	if (!yAxisFunc) {
		yAxisFunc = function (value, index, values) {
			return value
		}
	}

	const LineChart = (
		<Line
			datasetIdKey={id}
			data={{
				labels: ['', '', ''],
				datasets: [
					{
						label: 'Minimum threshold',
						data: [benchmark, benchmark, benchmark],
						borderWidth: 2,
						backgroundColor: '#C32A1F',
						borderColor: '#C32A1F',
						borderDash: [10, 5],
					},
					{
						label: 'You',
						data: [self, self, self],
						backgroundColor: '#02475E',
						borderColor: '#02475E',
						borderWidth: 2,
					},
				],
			}}
			options={{
				scales: {
					y: {
						min: toSetLeastValue > 0 ? toSetLeastValue : 0,
						max: Math.round(maxValue) + 10,
						grid: {
							display: false,
						},
						ticks: {
							// Customize your label here
							callback: yAxisFunc,
						},
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
				},
			}}
		/>
	)
	return LineChart
}

const AgentAnalytics = () => {
	const history = useHistory()
	const goBack = () => {
		history.goBack()
	}
	const { getPerformanceMetric } = useFetch()
	const [analyticData, setAnalyticData] = useState({ self: {}, benchmark: {} })
	const [activeFilter, setActiveFilter] = useState('This Month')

	useEffect(() => {
		let backendFilter
		switch (activeFilter) {
			case 'This Month':
				backendFilter = 'CURRENT_MONTH'
				break
			case 'Last Month':
				backendFilter = 'LAST_MONTH'
				break
			case 'Life Time':
				backendFilter = 'LIFETIME'
				break
		}

		getPerformanceMetric(backendFilter).then(result => {
			setAnalyticData(result)
		})
	}, [activeFilter])

	let leadRFQ, rfqSale, leadSale, gwpCapture, nopCount, gwpTally

	if (analyticData?.self) {
		leadRFQ = getChart(
			'leadRFQ',
			analyticData?.self['Lead::RFQ %'],
			analyticData?.benchmark['Lead::RFQ %']
		)

		rfqSale = getChart(
			'rfqSale',
			analyticData?.self['RFQ::Sale %'],
			analyticData?.benchmark['RFQ::Sale %']
		)

		leadSale = getChart(
			'leadSale',
			analyticData?.self['Lead::Sale %'],
			analyticData?.benchmark['Lead::Sale %']
		)

		gwpCapture = getChart(
			'gwpCapture',
			analyticData?.self['GWP Capture %'],
			analyticData?.benchmark['GWP Capture %']
		)

		nopCount = getChart(
			'nopCount',
			analyticData?.self['NOP Count'],
			analyticData?.benchmark['NOP Count']
		)

		let minTally, maxTally

		if (analyticData?.self['GWP Tally'] > analyticData?.benchmark['GWP Tally']) {
			minTally = analyticData?.benchmark['GWP Tally'] - 20000
			maxTally = analyticData?.self['GWP Tally'] + 20000
		} else {
			minTally = analyticData?.self['GWP Tally'] - 20000
			maxTally = analyticData?.benchmark['GWP Tally'] + 20000
		}

		const lakhFormatter = (value, index, values) => {
			let lakhValue = value / 100000
			return '₹ ' + lakhValue.toFixed(2) + 'L'
		}
		gwpTally = getChart(
			'gwpTally',
			analyticData?.self['GWP Tally'],
			analyticData?.benchmark['GWP Tally'],
			minTally,
			maxTally,
			lakhFormatter
		)
	}

	const sortMenuItems = [
		{ text: 'Last Month', value: 'Last Month' },
		{ text: 'This Month', value: 'This Month' },
		{ text: 'Life Time', value: 'Life Time' },
	]

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={
					<div className="d-flex align-items-center">
						<Text type="body1" color={theme.colors.gray7} fontWeight="700" className="me-3">
							My Performance
						</Text>
						<FilterButton
							filterText="Filter by"
							disableLeftMargin
							selectedOption={activeFilter}
							setFilterValue={setActiveFilter}
							menuItems={sortMenuItems}
						/>
					</div>
				}
				rightContent=""
				onGoBack={goBack}
			/>
			<Container>
				<Row>
					<CardItem
						title={'Lead :: RFQ%'}
						self={analyticData?.self['Lead::RFQ %']}
						benchmark={analyticData?.benchmark['Lead::RFQ %']}
						unit={'%'}
					>
						{leadRFQ}
					</CardItem>

					<CardItem
						title={'RFQ :: Sale'}
						self={analyticData?.self['RFQ::Sale %']}
						benchmark={analyticData?.benchmark['RFQ::Sale %']}
						unit={'%'}
					>
						{rfqSale}
					</CardItem>
				</Row>
				<Row>
					<CardItem
						title={'Lead :: Sale'}
						self={analyticData?.self['Lead::Sale %']}
						benchmark={analyticData?.benchmark['Lead::Sale %']}
						unit={'%'}
					>
						{leadSale}
					</CardItem>
					<CardItem
						title={'GWP Capture %'}
						self={analyticData?.self['GWP Capture %']}
						benchmark={analyticData?.benchmark['GWP Capture %']}
						unit={'%'}
					>
						{gwpCapture}
					</CardItem>
				</Row>
				<Row>
					<CardItem
						title={'NOP Count'}
						self={analyticData?.self['NOP Count']}
						benchmark={analyticData?.benchmark['NOP Count']}
						unit={'polices'}
					>
						{nopCount}
					</CardItem>
					<CardItem
						title={'GWP tally'}
						self={analyticData?.self['GWP Tally']}
						benchmark={analyticData?.benchmark['GWP Tally']}
					>
						{gwpTally}
					</CardItem>
				</Row>
			</Container>
		</PageContentAndNavbarWrapper>
	)
}

export default AgentAnalytics
