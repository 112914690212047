import { useContext } from 'react'
import { Context } from '../../context/Provider'

import { Space, Text, theme, Button, Icon } from 'verak-ui'
import { useHistory } from 'react-router'

// should happen on the "initial" screen
export const ZeroToOne1 = ({ handleProceedForZeroToOne }) => {
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText>
				Since the client has now disclosed Stock value, this quotation will adjust to a Property +
				Stock quotation
			</CustomText>
			<ModalButton proceed={handleProceedForZeroToOne} />
		</>
	)
}

// should happen on the "initial" screen
export const ZeroToOne2 = ({ propertyType, levelIndex }) => {
	const [state, dispatch] = useContext(Context)
	const history = useHistory()

	const returnPath = state?.sqs?.returnPath || '/property-risk/sum-insured'

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 1,
					StockDisclosureMethod: 'DECLARED_VALUE',
				},
			})

			resolve()
		})

		history.replace({
			pathname: returnPath,
			state: {
				propertyType: propertyType,
				levelIndex: levelIndex,
			},
		})
	}
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText>
				Since only 1 risk location has stock - Declared Value method for disclosing stock value must
				be used.
			</CustomText>
			<ModalButton proceed={proceed} />
		</>
	)
}

export const OneToMany = ({ propertyType, levelIndex, currentLocationId }) => {
	let history = useHistory()
	const proceed = () => {
		history.push({
			pathname: '/edge-case-1/stock-in-process',
			state: {
				propertyType: propertyType,
				levelIndex: levelIndex,
				currentLocationId: currentLocationId,
			},
		})
	}

	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText>
				Since the number of risk locations <br />
				with Stock is MORE THAN ONE,
			</CustomText>
			<Space y={1} />
			<CustomText>
				The client now has to choose a <br />
				Valuation method for Stock
			</CustomText>
			<ModalButton proceed={proceed} />
		</>
	)
}

export const FloaterDeclarationError = ({ propertyType, levelIndex, currentLocationId }) => {
	let history = useHistory()

	const proceed = () => {
		history.push({
			pathname: '/edge-case-1/stock-select',
			state: {
				propertyType: propertyType,
				levelIndex: levelIndex,
				currentLocationId: currentLocationId,
			},
		})
	}
	return (
		<>
			<Text type="primary" color={theme.colors.primary}>
				As per the Guidelines, a Floater Declaration policy for stock must: <br />
				1. Have a minimum Sum Insured of ₹2 crores <br />
				2. Have at least one location with a Sum Insured &gt; ₹25 Lakhs
			</Text>
			<Space y={1.5} />
			<Text type="primary" color={theme.colors.secondary}>
				Since the client’s stock does NOT meet this criteria - you need to pick a new Stock
				disclosure method
			</Text>
			<ModalButton proceed={proceed} />
		</>
	)
}

export const ModalButton = ({ proceed }) => {
	return (
		<>
			<Space y={1.5} />
			<Button
				label="Continue"
				icon="rightArrow"
				iconAlign="right"
				style={buttonStyle}
				onClick={proceed}
			/>
		</>
	)
}

const CustomText = ({ children, color = theme.colors.red }) => {
	return (
		<Text color={color} style={{ fontWeight: 700, fontSize: '18px' }}>
			{children}
		</Text>
	)
}

const buttonStyle = {
	width: '100%',
	height: '48px',
	fontSize: '18px',
}
