import styled from 'styled-components'
import { theme, Icon } from 'verak-ui'
import PropTypes from 'prop-types'

const rightArrowStyles = {
	position: 'absolute',
	margin: 'auto',
	right: '1rem',
	top: '0%',
	bottom: '0%',
}

const TableComponent = ({
	tableHeaderItems,
	tableDataItems,
	hideOnMobileIndex = 1,
	headerRowStyles = {},
	tableRowStyles = {},
	tableDataRowCls = '',
}) => {
	// construct table header
	let displayTableHeaders
	let tableHeadersKeyList = []

	if (tableHeaderItems.length) {
		let tableHeadersContentList = []

		tableHeaderItems.forEach(headerItem => {
			const headerItemContent = (
				<TableHeaderItem
					hideOnMobileIndex={hideOnMobileIndex}
					width={headerItem?.width}
					key={headerItem?.key}
				>
					{headerItem?.content || '--'}
				</TableHeaderItem>
			)
			const headerItemKey = headerItem?.key

			tableHeadersContentList.push(headerItemContent)
			tableHeadersKeyList.push(headerItemKey)
		})

		displayTableHeaders = (
			<TableHeaderRow style={headerRowStyles}>{tableHeadersContentList}</TableHeaderRow>
		)
	}

	// use the table header keys to construct table data
	let displayTableData
	if (tableDataItems.length && tableHeadersKeyList.length) {
		displayTableData = []

		tableDataItems.forEach((rowItem, rowIndex) => {
			const rowData = []
			// loop over the header keys in an orderly manner and push the data items
			tableHeadersKeyList.forEach((headerKey, headerIndex) => {
				// show the right arrow only for the last column
				const rowDataItem = (
					<TableDataItem
						hideOnMobileIndex={hideOnMobileIndex}
						key={`table-row-item-${headerKey}-${rowIndex}`}
					>
						{rowItem?.[headerKey] || '--'}
					</TableDataItem>
				)

				rowData.push(rowDataItem)
			})

			// assign the row click-handling function
			let handleRowClick = () => {}
			if (typeof rowItem?.handleRowClick === 'function') {
				handleRowClick = rowItem?.handleRowClick
			}
			displayTableData.push(
				<TableDataRow
					onClick={handleRowClick}
					key={rowItem?.key || `table-row-${rowIndex}`}
					className={tableDataRowCls}
					style={rowItem?.tableRowStyles || tableRowStyles}
				>
					{rowData}
				</TableDataRow>
			)
		})
	}

	return (
		<div className="table-responsive-sm">
			<TableComp className="table table-sm">
				<thead>{displayTableHeaders}</thead>
				<tbody style={{ borderTop: 'none' }}>{displayTableData}</tbody>
			</TableComp>
		</div>
	)
}

const TableComp = styled.table`
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
`

const TableHeaderRow = styled.tr`
	background: #e5eaec;
`
const TableHeaderItem = styled.th`
	color: ${theme.colors.gray3};
	text-transform: uppercase;
	padding: 1rem !important;
	font-size: 12px;
	font-weight: 700;
	line-height: 15.12px;
	letter-spacing: 0.05rem;
	border-bottom: none;
	width: ${({ width }) => (width ? width : 'auto')};
	@media (max-width: 600px) {
		/* only show 1st and specified column from last in mobile view */
		&:not(:first-child)&:not(:nth-last-child(${({ hideOnMobileIndex }) => hideOnMobileIndex})) {
			display: none;
		}
	}
`

const TableDataRow = styled.tr`
	background: #fff;
	cursor: pointer;
	position: relative;
	&:hover {
		background: #f6f6f6;
	}
`
const TableDataItem = styled.td`
	color: ${theme.colors.gray7};
	padding: 1rem !important;
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
	@media (max-width: 600px) {
		/* only show 1st and specified column from last in mobile view */
		&:not(:first-child)&:not(:nth-last-child(${({ hideOnMobileIndex }) => hideOnMobileIndex})) {
			display: none;
		}
	}
`

export default TableComponent

TableComponent.propTypes = {
	tableHeaderItems: PropTypes.array,
	tableDataItems: PropTypes.array,
	hideOnMobileIndex: PropTypes.number,
	headerRowStyles: PropTypes.object,
	tableRowStyles: PropTypes.object,
}
