const coverageValues = [
	{ value: 'D15', name: '15 days', months: 0.5 },
	{ value: 'M1', name: '1 month', months: 1 },
	{ value: 'M2', name: '2 months', months: 2 },
	{ value: 'M3', name: '3 months', months: 3 },
	{ value: 'M4', name: '4 months', months: 4 },
	{ value: 'M5', name: '5 months', months: 5 },
	{ value: 'M6', name: '6 months', months: 6 },
	{ value: 'M7', name: '7 months', months: 7 },
	{ value: 'M8', name: '8 months', months: 8 },
	{ value: 'M9', name: '9 months', months: 9 },
	{ value: 'M10', name: '10 months', months: 10 },
	{ value: 'M11', name: '11 months', months: 11 },
	{ value: 'M12', name: '12 months', months: 12 },
	// { value: 'Y2', name: '2 years', months: 24 },
	// { value: 'Y3', name: '3 years', months: 26 },
	// { value: 'Y4', name: '4 years', months: 48 },
	// { value: 'Y5', name: '5 years', months: 60 },
	// { value: 'Y6', name: '6 years', months: 72 },
	// { value: 'Y7', name: '7 years', months: 84 },
	// { value: 'Y8', name: '8 years', months: 96 },
	// { value: 'Y9', name: '9 years', months: 108 },
	// { value: 'Y10', name: '10 years', months: 120 },
]

const calculateDateFromCode = (code, startDate) => {
	let date = new Date(startDate.valueOf())

	if (code === 'D15') {
		date.setDate(date.getDate() + 15)
	} else if (code.includes('M')) {
		let months = parseInt(code.replace('M', ''))
		date.setMonth(date.getMonth() + months)
	} else if (code.includes('Y')) {
		let months = parseInt(code.replace('Y', '')) * 12
		date.setMonth(date.getMonth() + months)
	}
	date.setDate(date.getDate() - 1)

	return date
}

export { coverageValues, calculateDateFromCode }
