import { useHistory } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import { Context } from '../../../context/Provider'
import { convertToNumber } from '../../../utilities/convertToNumber'

import {
	getFloaterPreFilled,
	updateFloaterValues,
} from '../../../utilities/convertingDeclaredToFloater'
import { Divider, Alert, MainContainer, Space, theme, Input, Text } from 'verak-ui'
import { SelectedDisclosureMethod, FloaterValuesError } from '../../../components/SharedComponents'

const Floater = ({ propertyType, levelIndex, currentLocationId }) => {
	const [state, dispatch] = useContext(Context)
	const [MaxValueAllLoc, setMaxValueAllLoc] = useState('')
	const [MaxValueOneLoc, setMaxValueOneLoc] = useState('')
	const [error, setError] = useState(false)

	let history = useHistory()

	const returnPath = state?.sqs?.returnPath || '/property-risk/sum-insured'

	const RiskLocations = state.quote?.RiskLocations

	const checkForValues = () => {
		if (MaxValueAllLoc) {
			return false
		} else {
			return true
		}
	}
	const disableProceed = checkForValues()

	// handles dynamic prefilling of values
	useEffect(() => {
		const values = getFloaterPreFilled(RiskLocations)
		setMaxValueAllLoc(values.total)
		setMaxValueOneLoc(values.maxOneLoc)
	}, [])

	const proceed = async () => {
		const changeFloaterValues = (ID, propertyType, levelIndex, Stock) => {
			let toReturn = []
			toReturn.push(
				new Promise((resolve, reject) => {
					dispatch({
						type: 'MOVE_TO_LOCATION',
						data: {
							locationId: ID,
						},
					})
					resolve()
				})
			)

			toReturn.push(
				new Promise(resolve => {
					dispatch({
						type: 'UPDATE_RISK_LOCATION_DETAILS',
						propertyType: propertyType,
						levelIndex: levelIndex,
						data: {
							Stock,
						},
					})
					resolve()
				})
			)

			toReturn.push(
				new Promise(resolve => {
					dispatch({
						type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
					})
					resolve()
				})
			)

			return Promise.all(toReturn)
		}

		if (parseFloat(MaxValueOneLoc) > parseFloat(MaxValueAllLoc)) {
			setError(true)
		} else {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_STOCK_VALUE',
					data: {
						MaxValueAllLoc: MaxValueAllLoc || '',
						MaxValueOneLoc: MaxValueOneLoc || '',
					},
				})
				resolve()
			})

			await new Promise(resolve => {
				dispatch({
					type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
					data: {
						NumStock: 2,
						StockDisclosureMethod: 'FLOATER',
					},
				})
				resolve()
			})

			// dispatching values of open & covered in %
			const data = updateFloaterValues(RiskLocations)
			const promiseList = data.map(el =>
				changeFloaterValues(el.ID, el.propertyType, el.levelIndex, el.Stock)
			)
			await Promise.all(promiseList)

			// resets state.location to the previous value it arrived from
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'MOVE_TO_LOCATION',
					data: {
						locationId: currentLocationId,
					},
				})
				resolve()
			})

			history.replace({
				pathname: returnPath,
				state: {
					propertyType: propertyType,
					levelIndex: levelIndex,
				},
			})
		}
	}

	const handleChangeInMaxValueAllLoc = e => {
		const numValue = convertToNumber(e.target.value)
		setMaxValueAllLoc(numValue)
	}
	const handleChangeInMaxValueOneLoc = e => {
		const numValue = convertToNumber(e.target.value)
		setMaxValueOneLoc(numValue)
	}

	let renderStocSumInsAlert = null

	if (parseInt(MaxValueAllLoc) > 500000000) {
		renderStocSumInsAlert = (
			<>
				<Space y={1} />
				<Alert
					bgColor={theme.colors.secondaryLightRed}
					iconName="warningTriangleSm"
					infoIconColor={theme.colors.red}
					titleColor={theme.colors.red}
					title="Important Notice"
				>
					<Space y={0.5} />
					<Text type="body2" style={{ marginLeft: '2rem' }}>
						The Sum Insured has exceeded ₹50 crore - you may continue with the quotation. However,
						you will not get an immediate quote - the Sales Manager will need to help you.
					</Text>
				</Alert>
			</>
		)
	}

	return (
		<MainContainer
			header="Stock details"
			top={2.5}
			rightOnClick={proceed}
			rightDisabled={disableProceed}
		>
			<SelectedDisclosureMethod method="Floater Basis" />
			<Input
				label="Total value of stock:"
				labelStyle={textStyle}
				compulsory
				placeholder="Enter value here"
				value={MaxValueAllLoc}
				onChange={e => handleChangeInMaxValueAllLoc(e)}
				compulsory
				autoComma
				rupee
			/>
			<Space y={2.5} />
			<Alert
				variant="secondary"
				infoIconColor={theme.colors.secondary}
				titleColor={theme.colors.secondary}
				title="Please enter the maximum value for your stock (from the previous year) for each stock
				location and add it together"
			/>
			<Divider top={2.5} bottom={2.5} />
			<Input
				label="Maximum stock value at any one location:"
				labelStyle={textStyle}
				placeholder="Enter value here"
				value={MaxValueOneLoc}
				onChange={e => handleChangeInMaxValueOneLoc(e)}
				autoComma
				rupee
			/>

			{error ? (
				<>
					<Space y={1.5} />
					<FloaterValuesError />
				</>
			) : null}
			{renderStocSumInsAlert}
		</MainContainer>
	)
}

export default Floater

const textStyle = {
	fontSize: '24px',
	fontWeight: 700,
	color: `${theme.colors.gray7}`,
	marginBottom: '1rem',
}
