import { theme, Text, Button, Checkbox, Icon } from 'verak-ui'
import { Row, Col } from 'react-bootstrap'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import styled from 'styled-components'
// import { useHistory } from 'react-router'
// import { useState } from 'react'

export default function SelectedJourneys() {
	const selectedJourneys = [
		{ name: 'Fire & Allied Perils', type: 'online' },
		{ name: 'Burglary & Theft', type: 'offline' },
		{ name: 'Electronic Equipment Insurance', type: 'offline' },
	]

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Fire All Risk Package
		</Text>
	)

	const clearSelection = (
		<Row>
			<Col style={{ cursor: 'pointer' }} className="d-flex align-items-center">
				<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
				<Text>Remove policies</Text>
			</Col>
		</Row>
	)

	const selectedJourneysMap = selectedJourneys.map((journeyItem, index) => {
		return (
			<JourneyItem title={journeyItem.name} type={journeyItem.type} key={`journey-item-${index}`} />
		)
	})

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar headerBg="white" navbarNumber={1} leftContent={navContent} rightContent="" />

			<div className="container p-3 mb-5">
				<div className="d-flex justify-content-between align-items-center">
					<Text fontSize="24px" fontWeight="bold" color={theme.colors.primary} className="px-2">
						Complete RFQ details for all selected policies
					</Text>

					{clearSelection}
				</div>

				<div className="d-flex flex-column">{selectedJourneysMap}</div>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

const JourneyItem = ({ title, type }) => {
	const subText =
		type === 'online'
			? 'Online & seamless process >>>'
			: 'Offline - Complete Fire & Allied Perils RFQ to start this'

	const subTextProps = {
		color: type === 'online' ? theme.colors.secondary : theme.colors.gray4,
		fontSize: type === 'online' ? '12px' : '10px',
		fontWeight: type === 'online' ? '700' : 'bold',
		marginTop: '1rem',
	}

	const btnProps = {
		style: {
			background: type === 'offline' ? 'transparent' : theme.colors.primary,
			color: type === 'offline' ? theme.colors.primary : 'white',
			opacity: type === 'offline' && '.5',
			padding: '1rem',
			fontSize: '18px',
			height: '56px',
		},
	}

	return (
		<JourneyItemWrapper className="d-flex justify-content-between">
			<div className="d-flex flex-column justify-content-between">
				<Text color={theme.colors.primary} fontSize="18px" fontWeight="bold">
					{title}
				</Text>
				<Text {...subTextProps}>{subText}</Text>
			</div>

			<Button
				{...btnProps}
				disabled={type === 'offline'}
				label="Start RFQ"
				icon="rightArrow"
				iconAlign="right"
			/>
		</JourneyItemWrapper>
	)
}

const JourneyItemWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 16px;
	margin-top: 1rem;
	margin-bottom: 1rem;
`
