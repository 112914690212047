const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const prependZero = num => (num < 10 ? `0${num}` : num)

const getMeridian = hours => (hours >= 12 ? 'pm' : 'am')

// returns date string like
// 15 Aug 2021, 5:22 pm
const getDateString = ISOString => {
	const date = new Date(ISOString)
	const currHours = date.getHours()
	const hours = currHours > 12 ? currHours - 12 : currHours
	const meridian = getMeridian(currHours)

	return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${prependZero(
		hours
	)}:${prependZero(date.getMinutes())} ${meridian}`
}

// returns date string like
// 15 Aug 2022 at 5:22 pm OR Today at 5:22 pm OR Yesterday at 5:22 pm
const getDateWordString = ISOString => {
	const date = new Date(ISOString)
	const currHours = date.getHours()
	const hours = currHours > 12 ? currHours - 12 : currHours
	const meridian = getMeridian(currHours)

	let dateToDisplay = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`

	const [todayDate, todayMonth, todayYear] = [
		new Date().getDate(),
		new Date().getMonth(),
		new Date().getFullYear(),
	]
	const [enteredDate, enteredMonth, enteredYear] = [
		date.getDate(),
		date.getMonth(),
		date.getFullYear(),
	]

	const isMonthYearSame = enteredMonth === todayMonth && enteredYear === todayYear

	const isToday = enteredDate === todayDate && isMonthYearSame
	const isYesterday = enteredDate === todayDate - 1 && isMonthYearSame

	if (isToday) dateToDisplay = 'Today'
	if (isYesterday) dateToDisplay = 'Yesterday'
	return `${dateToDisplay} at ${prependZero(hours)}:${prependZero(date.getMinutes())} ${meridian}`
}

const channelPartner = {
	CP_1: 'India Advocacy',
	CP_2: 'Prasanna',
	CP_3: 'Lakshya Financial',
	CP_4: 'SuperK',
	CP_5: 'Bharat Seva',
	CP_6: 'Prodo Tech',
	CP_7: 'Ezo',
	CP_8: 'Pick My Work',
	CP_9: 'TaskMo',
	CP_10: 'Mixsquads',
}

export { months, prependZero, getDateString, getDateWordString, channelPartner }
