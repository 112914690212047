import styled from 'styled-components'
import { useState } from 'react'
import { theme } from 'verak-ui'

import StockContent from '../../../../../assets/training/StockContent.svg'
import MachineContent from '../../../../../assets/training/MachineContent.svg'
import StructureContent from '../../../../../assets/training/StructureContent.svg'

const tabs = {
	STOCK: { value: 'STOCK', image: StockContent },
	MACHINE: { value: 'MACHINE', image: MachineContent },
	STRUCTURE: { value: 'STRUCTURE', image: StructureContent },
}

export default function Training_ValuationDoubts() {
	const [activeTab, setActiveTab] = useState(tabs.STOCK.value)

	const isTabActive = tab => activeTab === tab

	return (
		<div className="d-flex flex-column p-2 align-items-center">
			<div className="row w-100">
				<Tab
					selected={isTabActive(tabs.STOCK.value)}
					onClick={() => setActiveTab(tabs.STOCK.value)}
					className="col"
				>
					STOCK
				</Tab>
				<Tab
					selected={isTabActive(tabs.MACHINE.value)}
					onClick={() => setActiveTab(tabs.MACHINE.value)}
					className="col"
				>
					MACHINE
				</Tab>
				<Tab
					selected={isTabActive(tabs.STRUCTURE.value)}
					onClick={() => setActiveTab(tabs.STRUCTURE.value)}
					className="col"
				>
					STRUCTURE
				</Tab>
			</div>
			<img className="mt-3" src={tabs[activeTab].image} alt={tabs[activeTab].value} />
		</div>
	)
}

const Tab = styled.div`
	padding: 10px 2px 0px;
	background: ${props =>
		props.selected
			? 'linear-gradient(180deg, #02475e -251.47%, rgba(255, 255, 255, 0.7) 107.05%)'
			: 'white'};
	border: ${props => (props.selected ? `2px solid ${theme.colors.primary}` : '')};
	border-bottom: ${props =>
		props.selected ? `2px solid ${theme.colors.white}` : `2px solid ${theme.colors.primary}`};
	border-radius: 8px 8px 0px 0px;
	text-align: center;
	font-weight: 700;
	font-size: 12px;
	cursor: pointer;
`
