import { theme } from 'verak-ui'
import { useState } from 'react'
import styled from 'styled-components'

import NavigationSidebar from './components/NavigationSidebar'
import ContentViewer from './components/ContentViewer'

import { sections } from './components/sections'
const firstTopics = {
	CHEATSHEET: Object.keys(sections.CHEATSHEET.contentIndex)[0],
	TRAINING: Object.keys(sections.TRAINING.contentIndex)[1], // first element is divider
}

export default function ReferenceMaterial({ companyName, showShortcuts = true }) {
	const [activeSection, setActiveSection] = useState(sections.TRAINING.value)
	const [selectedTopic, setSelectedTopic] = useState(firstTopics['TRAINING'])

	const onChangeActiveSection = section => {
		setActiveSection(section)
		setSelectedTopic(firstTopics[section])
	}
	const onChangeSelectedTopic = topic => setSelectedTopic(topic)

	return (
		<ReferenceMaterialWrapper className="reference-material-container d-flex">
			<NavigationSidebar
				activeSection={activeSection}
				onChangeActiveSection={onChangeActiveSection}
				selectedTopic={selectedTopic}
				onChangeSelectedTopic={onChangeSelectedTopic}
				showShortcuts={showShortcuts}
				companyName={companyName}
			/>
			<ContentViewer activeSection={activeSection} selectedTopic={selectedTopic} />
		</ReferenceMaterialWrapper>
	)
}

const ReferenceMaterialWrapper = styled.div`
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	height: calc(100vh - 350px);
	background: ${theme.colors.white};
	font-family: Inter;
`
