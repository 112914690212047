export const infoStrings = {
  amcForce: 'AMC (Annual Maintenance contract) - This is an agreement with a service provider for repair and maintenance of a property used by the company.',
  glassFlooding: 'Gas Flooding System provides a blanket of heavy gas that reduces the oxygen content of the atmosphere to a point where combustion is not sustainable.',
  fireAlarm: 'A fire alarm system warns people when smoke, fire, carbon monoxide or other fire-related emergencies are detected.',
  foamSystem: 'Foam is a mixture of water, foam agent and air. These systems extinguish fire by separating the fuel from the oxygen.',
  sprinkler: 'A fire sprinkler system that discharges water when the effects of a fire have been detected, such as when a predetermined temperature has been exceeded.',
  smallBore: 'Small bore hose reel systems consist of pumps, pipes, water supply and hose reels located strategically in a building, ensuring proper coverage of water to combat a fire',
  hydrant: 'A fire hydrant is a connection point by which firefighters can tap into a water supply.',
  fireWaterSpray: 'Water Spray System is a special fixed pipe system connected to a reliable source of pressurised water supply and equipped with water spray nozzles for application on area / equipment to be protected.',
  smokeDetector: 'A smoke detector is a device that senses smoke, typically as an indicator of fire.',
  uploadSurroundings: 'Upload photos of surrounding roads leading up to the risk location. Also upload photos of adjacent buildings, main roads etc.',
  anyOtherInitiatives: 'For example – Fire drills are conducted every month.',
  handheldExtinguisher: 'Easy to use portable fire extinguisher'
}