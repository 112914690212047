/*** STANDARD FIRE AND SPECIAL PERILS (SFSP) ***/
import SumInsuredMethods from '../screens/SFSP/StockDisclosureChoice/SumInsuredMethods'
import ShopRisk from '../screens/SFSP/PropertyRiskData/ShopRisk/ShopRisk'
import AddOns from '../screens/SFSP/AddOns'
import EscalationClause from '../screens/SFSP/EscalationClause'
import SFSPRiskLocationSelection from '../screens/SFSP/SFSPEdgeCase/RiskLocationSelection'

import MaterialsHandler from '../screens/PropertyRiskData/Materials/MaterialsHandler'
import SumInsured from '../screens/PropertyRiskData/SumInsured/SumInsured'

const sfspCombinedRoutes = [
	{
		path: '/sfsp-edgecase/materials',
		component: MaterialsHandler,
		exact: true,
	},
	{
		path: '/property-risk/shop-risk',
		component: ShopRisk,
		exact: true,
	},
	{
		path: '/sfsp-edgecase/shop-risk',
		component: ShopRisk,
		exact: true,
	},
	{
		path: '/sfsp/sum-insured-methods',
		component: SumInsuredMethods,
		exact: true,
	},
	{
		path: '/sfsp-edgecase/sum-insured-methods',
		component: SumInsuredMethods,
		exact: true,
	},
	{
		path: '/sfsp/add-ons',
		component: AddOns,
		exact: true,
	},
	{
		path: '/sfsp/escalation-clause',
		component: EscalationClause,
		exact: true,
	},
	{
		path: '/sfsp-edgecase/risk-location-selection',
		component: SFSPRiskLocationSelection,
		exact: true,
	},
	{
		// SFSP
		path: '/sfsp-edgecase/sum-insured',
		component: SumInsured,
		exact: true,
	},
]

export default sfspCombinedRoutes
