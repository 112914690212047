/*** FIRE RFQ JOURNEY ***/

// JOURNEY START
import PolicyType from '../screens/JourneyStart/PolicyType'
import ClientDetails from '../screens/JourneyStart/ClientDetails'
import CoverageDetails from '../screens/JourneyStart/CoverageDetails'
import RiskLocation from '../screens/JourneyStart/RiskLocation'

import RiskLocationSelection from '../screens/JourneyStart/RiskLocationSelection'

// STOCK DISCLOSURE (ST - 0 to 3)
import NumberStockLocations from '../screens/StockDisclosureChoice/NumberStockLocations'
import StockInProcessCheck from '../screens/StockDisclosureChoice/StockInProcessCheck'
import RailwaySidingsCheck from '../screens/StockDisclosureChoice/RailwaySidingsCheck'
import StockSelect from '../screens/StockDisclosureChoice/StockSelect'
import DisclousureMethods from '../screens/StockDisclosureChoice/DisclousureMethods'

// PROPERTY CHECK (RL - 0)
import PropertyCheck from '../screens/PropertyRiskDataCollection/PropertyCheck'

//PROPERTY RISK DATA COLLECTION  (RL - 1)
import BasementLevelOccupancy from '../screens/PropertyRiskDataCollection/BasementLevelOccupancy'
import PropertyLevelOccupancy from '../screens/PropertyRiskDataCollection/PropertyLevelOccupancy'
import PropertyBasementLevelOccupancy from '../screens/PropertyRiskDataCollection/PropertyBasementLevelOccupancy'

// LOCATION DETAILS (RL - 2)
import LocationDetails from '../screens/PropertyRiskData/LocationDetails/LocationDetails'

// THEFT RISK (RL - 3)
import TheftRiskHandler from '../screens/PropertyRiskData/TheftRisk/TheftRiskHandler'
import ManufacturingRisk from '../screens/PropertyRiskData/TheftRisk/ManufacturingRisk'

// MATERIAL RISK (RL - 4)
import MaterialsHandler from '../screens/PropertyRiskData/Materials/MaterialsHandler'

// FIRE RISK (RL - 5)
import FireRiskHandler from '../screens/PropertyRiskData/FireSystem/FireRiskHandler'

// HISTORIC INFO (RL - 6)
import HistoricInfo from '../screens/PropertyRiskData/HistoricInfo/HistoricInfo'

// GOODS HELD IN TRUST (RL - 7)
import GoodsInTrust from '../screens/PropertyRiskData/GoodsInTrust/GoodsInTrust'
import GoodsInTrustData from '../screens/PropertyRiskData/GoodsInTrust/GoodsInTrustData'

// SUM INSURED (RL - 8)
import SumInsured from '../screens/PropertyRiskData/SumInsured/SumInsured'

// STOCK DETAILS COLLECTION
import StockHandler from '../screens/StockDetailsCollection/StockHandler'

// STOCK EDGE CASE MANAGEMENT - 1
import StockSelectEdgeCase1 from '../screens/EdgeCase1/StockSelect'
import DisclousureMethodsEdgeCase1 from '../screens/EdgeCase1/DisclousureMethods'
import StockInProcessCheckEdgeCase1 from '../screens/EdgeCase1/StockInProcessCheck'
import RailwaySidingsCheckEdgeCase1 from '../screens/EdgeCase1/RailwaySidingsCheck'

// STOCK EDGE CASE MANAGEMENT - 2
import OneLocationWarning from '../screens/EdgeCase2/OneLocationWarning'
import DeclarationBasisWarning from '../screens/EdgeCase2/DeclarationBasisWarning'

// RISK LOCATION REVIEW
import ConfirmScreen from '../screens/RiskLocationReview/ConfirmScreen'
import RiskLocationReview from '../screens/RiskLocationReview/ReviewScreen'

// Money Insurance (Addons)
import CIS from '../screens/MoneyInsurance/CIS'
import CIT from '../screens/MoneyInsurance/CIT'
import CIC from '../screens/MoneyInsurance/CIC'

// FINAL REVIEW
import Summary from '../screens/FinalReview/Summary'
import FinalReviewScreen from '../screens/FinalReview/FinalReview'
import SumInsuredBreakdown from '../screens/FinalReview/SumInsuredBreakdown'

// CONFIRMATION
import ConfirmationEmail from '../screens/ConfirmationScreens/ConfirmationEmail'

import NoteForInsurer from '../screens/ConfirmationScreens/NoteForInsurer'
import ReviewRFQ from '../screens/ConfirmationScreens/ReviewRFQ'
import QuoteTypeSelection from '../screens/JourneyStart/QuoteTypeSelection'

// FIRE RFQ ROUTES

// JOURNEY START
const journeyStartRoutes = [
	{
		path: '/journey/policy-type',
		component: PolicyType,
		exact: true,
	},
	{
		path: '/journey/client-details',
		component: ClientDetails,
		exact: true,
	},
	{
		path: '/journey/coverage-details',
		component: CoverageDetails,
		exact: true,
	},
	{
		path: '/journey/risk-location',
		component: RiskLocation,
		exact: true,
	},
	{
		path: '/journey/quote-type-selection',
		component: QuoteTypeSelection,
		exact: true,
	},
	{
		path: '/journey/risk-location-selection',
		component: RiskLocationSelection,
		exact: true,
	},
	{
		path: '/property-check',
		component: PropertyCheck,
		exact: true,
	},
]

const stockDisclosureRoutes = [
	// Stock Disclosure Choice Screens
	{
		path: '/stock-disclosure-choice/stock-location',
		component: NumberStockLocations,
		exact: true,
	},
	{
		path: '/stock-disclosure-choice/stock-in-process',
		component: StockInProcessCheck,
		exact: true,
	},
	{
		path: '/stock-disclosure-choice/railway-sidings',
		component: RailwaySidingsCheck,
		exact: true,
	},
	{
		path: '/stock-disclosure-choice/stock-select',
		component: StockSelect,
		exact: true,
	},
	{
		path: '/stock-disclosure-choice/stock-disclosure-methods',
		component: DisclousureMethods,
		exact: true,
	},
]

const propertyRiskDataCollectionRoutes = [
	{
		path: '/risk-location-details/basement',
		component: BasementLevelOccupancy,
		exact: true,
	},
	{
		path: '/risk-location-details/property',
		component: PropertyLevelOccupancy,
		exact: true,
	},
	{
		path: '/risk-location-details/property-and-basement',
		component: PropertyBasementLevelOccupancy,
		exact: true,
	},
]

const propertyAssesmentRoutes = [
	{
		path: '/property-risk/location-details',
		component: LocationDetails,
		exact: true,
	},
	{
		path: '/property-risk/theft-risk',
		component: TheftRiskHandler,
		exact: true,
	},
	{
		path: '/property-risk/manufacturing-risk',
		component: ManufacturingRisk,
		exact: true,
	},
	{
		path: '/property-risk/materials',
		component: MaterialsHandler,
		exact: true,
	},
	{
		path: '/property-risk/fire-system',
		component: FireRiskHandler,
		exact: true,
	},
]

const propertyHistoricAndSumInsuredRoutes = [
	{
		path: '/property-risk/historic-info',
		component: HistoricInfo,
		exact: true,
	},
	// {
	// 	path: '/property-risk/goods-in-trust',
	// 	component: GoodsInTrust,
	// 	exact: true,
	// },
	// {
	// 	path: '/property-risk/goods-in-trust-data',
	// 	component: GoodsInTrustData,
	// 	exact: true,
	// },
	{
		path: '/property-risk/sum-insured',
		component: SumInsured,
		exact: true,
	},
	// Stock Details Collection Screens
	{
		path: '/stock-details-collection',
		component: StockHandler,
		exact: true,
	},
]

const stockEdgeCase1Routes = [
	// Edge case management - 1
	{
		path: '/edge-case-1/stock-select',
		component: StockSelectEdgeCase1,
		exact: true,
	},
	{
		path: '/edge-case-1/stock-disclosure-methods',
		component: DisclousureMethodsEdgeCase1,
		exact: true,
	},
	{
		path: '/edge-case-1/stock-in-process',
		component: StockInProcessCheckEdgeCase1,
		exact: true,
	},
	{
		path: '/edge-case-1/railway-sidings',
		component: RailwaySidingsCheckEdgeCase1,
		exact: true,
	},
]

const stockEdgeCase2Routes = [
	// Edge case management - 2
	{
		path: '/edge-case-2/one-location-warning',
		component: OneLocationWarning,
		exact: true,
	},
	{
		path: '/edge-case-2/declared-value-details-collection',
		component: StockHandler,
		exact: true,
	},
	{
		path: '/edge-case-2/declaration-basis-warning',
		component: DeclarationBasisWarning,
		exact: true,
	},
]

const riskLocationReviewAndFinalReviewRoutes = [
	{
		path: '/risk-location-confirm/:id',
		component: ConfirmScreen,
		exact: true,
	},
	{
		path: '/risk-location-review',
		component: RiskLocationReview,
		exact: true,
	},
	{
		path: '/summary',
		component: Summary,
		exact: true,
	},
	{
		path: '/final-review',
		component: FinalReviewScreen,
		exact: true,
	},
	{
		path: '/sum-insured-breakdown',
		component: SumInsuredBreakdown,
		exact: true,
	},
]

const moneyInsuranceRoutes = [
	{
		path: '/cic',
		component: CIC,
		exact: true,
	},
	{
		path: '/cis',
		component: CIS,
		exact: true,
	},
	{
		path: '/cit',
		component: CIT,
		exact: true,
	},
]

// final confirmation + adding notes for the insurer
const finalConfirmationRoutes = [
	// disabled for pilot flow
	{
		path: '/confirmation-email',
		component: ConfirmationEmail,
		exact: true,
	},
	// used for pilot flow
	{
		path: '/rfq-email',
		component: ReviewRFQ,
		exact: true,
	},

	{
		path: '/note-for-insurer',
		component: NoteForInsurer,
		exact: true,
	},
]

const rfqCombinedRoutes = [
	...journeyStartRoutes,
	...propertyRiskDataCollectionRoutes,
	...stockDisclosureRoutes,
	...propertyAssesmentRoutes,
	...propertyHistoricAndSumInsuredRoutes,
	...stockEdgeCase1Routes,
	...stockEdgeCase2Routes,
	...riskLocationReviewAndFinalReviewRoutes,
	...moneyInsuranceRoutes,
	...finalConfirmationRoutes,
]

export default rfqCombinedRoutes
