import { useHistory } from 'react-router'
import styled from 'styled-components'
import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'

const PolicyCard = styled.div`
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	margin-top: 1rem;
`

const UWRFireAllRisk = () => {
	const history = useHistory()
	const navText = `Overview, {UID} - {Client Name} - Fire All Risk package`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	const handleCompleteRequirements = () => {
		history.push('/rfq-status/underwriter-requirements/fill-missing-data')
	}

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					borderRadius: '8px',
					width: 'min(1200px, 100%)',
				}}
				className="container h-100 p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.red}>
					Complete RFQ details for all selected policies
				</Text>

				<PolicyCard>
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Fire & Allied Perils
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								More requirments needed by the underwriter
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="Complete requirments"
						icon="rightArrow"
						iconAlign="right"
						onClick={handleCompleteRequirements}
					/>
				</PolicyCard>
				<PolicyCard>
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Plate glass
						</Text>
						<div className="d-flex mt-2">
							<Text className="mr-2" color={theme.colors.yellow} fontSize="12px" fontWeight="400">
								Self verification pending
							</Text>
						</div>
					</div>

					<Button
						bgColor="#fff"
						textColor={theme.colors.primary}
						iconColor={theme.colors.primary}
						style={{ height: '56px' }}
						label="See RFQ details"
						icon="rightArrow"
						iconAlign="right"
					/>
				</PolicyCard>
				<PolicyCard>
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Burglary & Theft
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Offline
							</Text>
						</div>
					</div>

					<Button
						bgColor="#fff"
						textColor={theme.colors.primary}
						iconColor={theme.colors.primary}
						style={{ height: '56px' }}
						label="Start RFQ"
						icon="rightArrow"
						iconAlign="right"
					/>
				</PolicyCard>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

export default UWRFireAllRisk
