import { Text, Icon, Modal, Button, theme, MainContainer } from 'verak-ui'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { Nto0, Nto1, Nto1_Modal2, DeclarationBasisError } from '../EdgeCase2/Modals'
import { determineEdgeCase2 } from '../../utilities/determineEdgeCase2'
import { declarationMethodCheck } from '../../utilities/declarationMethodCheck'
import { LocationReview } from '../../components/Tags'
import { getLocationStatus, areAllLocationsComplete } from '../../utilities/locationStatus'
import Swal from 'sweetalert2'
import { imageDeleteConfirmOptions } from '../../utilities/imageUploadUtilities'
import { calculateNumStock } from '../../utilities/calculateNumStock'
import { generateSumInsuredBreakdown } from '../FinalReview/finalReviewUtils'

import {
	NoticeForSFSPEdgeCase,
	InfoAboutNonSFSPQuote,
	determineWhichNonSFSPQuote,
} from '../SFSP/SFSPEdgeCase/Modals'
import {
	findLocations,
	doesShopRiskExist,
	doesBuildingHeightExist,
	doesFloorsExist,
	doesWarrantiesExist,
	findPlinthFoundation,
	deleteBuildingHeight,
	deleteShopRisk,
	deleteWarranties,
	deletePlinthFoundation,
	deleteFloors,
} from './riskLocationSelectionUtils'
import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import { goodsInTrustTotalSIForAllRiskLocations } from '../../utilities/goodsInTrustTotalSIForAllRiskLocations'
import { totalSumInsuredForAllRiskLocations } from '../../utilities/generateSumInsuredBreakdown'

export const LocationCard = styled(Col)`
	background-color: ${theme.colors.secondary3};
	border-radius: 10px;
	cursor: pointer;
	border: 1px solid ${theme.colors.secondary};
`

const RiskLocationSelection = () => {
	const fetch = useFetch()
	let history = useHistory()

	const [state, dispatch] = useContext(Context)
	const { NumStock, StockDisclosureMethod, RiskLocations } = state.quote
	let PolicyAddons = state?.quote?.PolicyAddons

	useEffect(() => {
		dispatch({
			type: 'DONE_WITH_LOCATION_CHANGES',
		})
	}, [])

	const [modalState, setModalState] = useState(false)
	const [modalContent, setModalContent] = useState(null)
	const openModal = () => {
		setModalState(true)
	}
	const closeModal = () => {
		setModalState(false)
	}
	const handleProceedForNto1 = () => {
		setModalContent(<Nto1_Modal2 />)
	}

	// sfsp modals
	const [modalStateSFSP, setModalStateSFSP] = useState(false)
	const [modalContentSFSP, setModalContentSFSP] = useState(null)

	// fn which deletes extra questions of SFSP
	// remove ValuationMethodMap, Shop Risk, Building Height, Floors, Warranties, PlinthFoundation
	const deleteSFSPValues = async () => {
		// ValuationMethodMap
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_VALUATION_METHOD',
				data: {
					ValuationMethodMap: {},
				},
			})
			resolve()
		})

		// Shop Risk
		let riskLocationIDS = findLocations(RiskLocations, doesShopRiskExist)
		let shopRiskDeletionPromises = riskLocationIDS.map(ID => {
			return deleteShopRisk(ID, dispatch)
		})
		await Promise.all(shopRiskDeletionPromises)

		// Warranties
		riskLocationIDS = findLocations(RiskLocations, doesWarrantiesExist)
		let warrantiesDeletionPromises = riskLocationIDS.map(ID => {
			const locationData = RiskLocations.find(location => location.ID === ID)
			let prevData = null
			if (locationData?.Type === 'PROPERTYBASEMENT') {
				prevData = locationData?.property?.HistoricInfo
			} else {
				prevData = locationData?.HistoricInfo
			}
			// need to send previous data to prevent overwriting everything to null
			return deleteWarranties(ID, prevData, dispatch)
		})
		await Promise.all(warrantiesDeletionPromises)

		// Building Height
		riskLocationIDS = findLocations(RiskLocations, doesBuildingHeightExist)
		let buildingDeletionPromises = riskLocationIDS.map(ID => {
			return deleteBuildingHeight(ID, dispatch)
		})
		await Promise.all(buildingDeletionPromises)

		// Floors
		riskLocationIDS = findLocations(RiskLocations, doesFloorsExist)
		let floorsDeletionPromises = riskLocationIDS.map(ID => {
			return deleteFloors(ID, dispatch)
		})
		await Promise.all(floorsDeletionPromises)

		// Plinth & Foundation
		riskLocationIDS = findPlinthFoundation(RiskLocations)
		// riskLocationIDS = [{ ID: '', propertyType: '', levelIndex: '' }, { ID: '', propertyType: '', levelIndex: '' }]

		let plinthFoundationDeletionPromises = riskLocationIDS.map(location => {
			const ID = location?.ID
			const propertyType = location?.propertyType
			const levelIndex = location?.levelIndex

			const locationData = RiskLocations.find(location => location.ID === ID)

			// need to get previous data to prevent overwriting everything to null
			let prevData = null
			if (locationData?.Type === 'PROPERTYBASEMENT') {
				if (levelIndex === -1) {
					// no levels

					// Note: for some reason only putting "propertyType" instead of writing these checks wasn't working
					if (propertyType === 'property') {
						prevData = locationData?.property?.SumInsured
					} else {
						prevData = locationData?.basement?.SumInsured
					}
				} else {
					if (propertyType === 'property') {
						prevData = locationData?.property?.levels[levelIndex]?.SumInsured
					} else {
						prevData = locationData?.basement?.levels[levelIndex]?.SumInsured
					}
				}
			} else {
				if (levelIndex === -1) {
					// no levels
					prevData = locationData?.SumInsured
				} else {
					prevData = locationData?.levels[levelIndex]?.SumInsured
				}
			}
			const SIwithoutPlinth = prevData?.filter(el => el.Name !== 'PlinthFoundation')

			// deletion
			return deletePlinthFoundation(ID, propertyType, levelIndex, SIwithoutPlinth, dispatch)
		})
		await Promise.all(plinthFoundationDeletionPromises)
	}

	// final path
	const getNextPath = () => {
		// triggers Money Insurance add ons or summary screen
		if (PolicyAddons?.CIC?.Present) return '/cic'
		if (PolicyAddons?.CIS?.Present) return '/cis'
		if (PolicyAddons?.CIT?.Present) return '/cit'
		return '/summary'
	}

	// proceed fns for SFSP modals
	const handleProceedForSFSPNotice = sfspEdgeCase => {
		if (sfspEdgeCase === 'BSUStoSFSP' || sfspEdgeCase === 'BLUStoSFSP') {
			history.push({
				pathname: '/sfsp-edgecase/sum-insured-methods',
				state: {
					isSFSPEdgeCase: true,
				},
			})
		} else {
			const convertSFSPToOther = async () => {
				const { policyName } = determineWhichNonSFSPQuote(sfspEdgeCase)
				await new Promise((resolve, reject) => {
					dispatch({
						type: 'SET_POLICY_TYPE',
						data: {
							PolicyName: policyName,
						},
					})
					resolve()
				})
				await deleteSFSPValues()

				await fetch.saveQuote()
				history.push(getNextPath())
			}
			setModalContentSFSP(
				<InfoAboutNonSFSPQuote sfspEdgeCase={sfspEdgeCase} proceed={convertSFSPToOther} />
			)
		}
	}

	const draftRFQ = state?.rfq?.draftRFQ

	const allLocationsFilled = areAllLocationsComplete(RiskLocations)
	const proceed = item => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: item.ID,
			},
		})

		if (!item.SubType) {
			if (draftRFQ) {
				//
				if (item.Type == 'BASEMENT') {
					history.push('/risk-location-details/basement')
				} else {
					history.push('/risk-location-details/property')
				}
			} else {
				history.push(`/property-check`)
			}
		} else {
			history.push('/property-risk/location-details')
		}
	}

	const addNewRiskLocation = () => {
		console.log('addNewRiskLocation')
		dispatch({
			type: 'ADD_NEW_RISK_LOCATION',
		})
	}

	const setCorrectPolicyKind = async () => {
		// no of stock locations
		const updatedNumStock = calculateNumStock(RiskLocations)

		// check if sum insured present
		let policyKind = ''

		let isBuilding = true
		const sumInsuredForAllRLs = generateSumInsuredBreakdown(state?.quote?.RiskLocations || [])

		if (sumInsuredForAllRLs.length === 0) {
			isBuilding = false
		}

		// check if stock present
		let isStock = true

		if (updatedNumStock === 0) {
			isStock = false
		}

		if (isBuilding && isStock) {
			policyKind = 'BUILDING_AND_STOCK'
		} else if (isBuilding) {
			policyKind = 'BUILDING_ONLY'
		} else if (isStock) {
			policyKind = 'STOCK_ONLY'
		}

		if (policyKind) {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_POLICY_KIND',
					data: {
						PolicyKind: policyKind,
					},
				})
				resolve()
			})
		}
	}

	const getUpdatedPolicyType = total => {
		let oldPolicyName = state?.quote?.PolicyName
		let updatedPolicyName = oldPolicyName

		if (total > 500000000) {
			updatedPolicyName = 'SFSP'
		} else if (total >= 50000000 && total <= 500000000) {
			updatedPolicyName = 'BLUS'
		} else {
			updatedPolicyName = 'BSUS'
		}

		const toReturn = {
			oldPolicyName,
			updatedPolicyName,
		}

		return toReturn
	}

	const getSFSPEdgeCase = (total, oldPolicyName, updatedPolicyName) => {
		let sfspEdgeCase = ''
		if (oldPolicyName === 'SFSP') {
			if (updatedPolicyName === 'BLUS') {
				sfspEdgeCase = 'SFSPtoBLUS'
			} else if (updatedPolicyName === 'BSUS') {
				sfspEdgeCase = 'SFSPtoBSUS'
			}
		} else if (total > 500000000) {
			sfspEdgeCase = `${oldPolicyName}toSFSP`
		}

		return sfspEdgeCase
	}

	const setCorrectPolicyType = async updatedPolicyName => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_TYPE',
				data: {
					PolicyName: updatedPolicyName,
				},
			})
			resolve()
		})
	}

	// NOTE: the setting of updated numStock value was removed because triggering the edge case 2
	// requires the older numStock value; setting of updatedNumStock has been moved to summary screen
	const handleSaveAndContinue = async () => {
		// total SI calculation
		const totalStockSI = calculateTotalStockVal(state?.quote)
		const SI = generateSumInsuredBreakdown(state?.quote?.RiskLocations)
		const goodsSI = goodsInTrustTotalSIForAllRiskLocations(state?.quote?.RiskLocations)
		const total = totalStockSI + totalSumInsuredForAllRiskLocations(SI) + goodsSI

		await setCorrectPolicyKind()

		// get the new updated policy type
		const { oldPolicyName, updatedPolicyName } = getUpdatedPolicyType(total)
		const sfspEdgeCase = getSFSPEdgeCase(total, oldPolicyName, updatedPolicyName)
		let isSFSP = updatedPolicyName === 'SFSP'
		// set the new policy type
		await setCorrectPolicyType(updatedPolicyName)

		// determining edge case - 2
		const edgeCase = determineEdgeCase2(NumStock, RiskLocations)
		if (edgeCase != '') {
			await fetch.trackRfqEvent('Triggered edge case 2')
		}

		// sets the policyTag to "FIRE_ALL_RISK" and adds the "BURGLARY" add on based on certain conditions
		const autoConvertValues = ['PlantMachinery', 'FurnitureFixture', 'ValuableContents']
		const isFireAllRisk = Object.keys(PolicyAddons)?.length > 0
		const filteredAutoConvertedSIValues = SI?.filter(object =>
			autoConvertValues?.includes(object.Name)
		)
		const needAutoPolicyTypeUpdate =
			!isFireAllRisk && (totalStockSI > 0 || filteredAutoConvertedSIValues?.length > 0)

		const policyTagManualUpdate = oldPolicyName !== updatedPolicyName

		if (policyTagManualUpdate || needAutoPolicyTypeUpdate) {
			if (policyTagManualUpdate) {
				await fetch.trackRfqEvent(
					`Changed PolicyType from ${oldPolicyName} to ${updatedPolicyName}`
				)
			}
			if (needAutoPolicyTypeUpdate) {
				PolicyAddons = {
					...PolicyAddons,
					BURGLARY_THEFT: { Present: true },
				}

				await new Promise((resolve, reject) => {
					dispatch({
						type: 'SET_POLICY_ADD_ONS',
						data: {
							PolicyAddons: PolicyAddons,
						},
					})
					resolve()
				})

				let AutoConversion = []
				if (totalStockSI > 0) {
					AutoConversion.push('Stock')
				}
				if (filteredAutoConvertedSIValues?.length > 0) {
					filteredAutoConvertedSIValues.forEach(object => AutoConversion.push(object.Name))
				}

				await new Promise((resolve, reject) => {
					dispatch({
						type: 'SET_AUTO_CONVERSION',
						data: {
							AutoConversion: AutoConversion,
						},
					})
					resolve()
				})
			}
			await fetch.saveQuote(policyTagManualUpdate ? updatedPolicyName : '')
		}

		if (edgeCase === 'nto0') {
			openModal()
			setModalContent(<Nto0 NumStock={NumStock} closeModal={closeModal} />)
		} else if (edgeCase === 'nto1') {
			openModal()
			setModalContent(<Nto1 proceed={handleProceedForNto1} closeModal={closeModal} />)
		} else if (NumStock > 1 && StockDisclosureMethod === 'DECLARATION_BASIS') {
			const doesNotSatisfy = declarationMethodCheck(RiskLocations)
			if (doesNotSatisfy) {
				openModal()
				setModalContent(<DeclarationBasisError />)
			} else {
				// need this because it might enter the 'if' but does satisfy so it won't go to the last 'else'

				// trigger SFSP edge case
				if (sfspEdgeCase !== '') {
					setModalContentSFSP(
						<NoticeForSFSPEdgeCase
							sfspEdgeCase={sfspEdgeCase}
							proceed={() => handleProceedForSFSPNotice(sfspEdgeCase)}
						/>
					)
					setModalStateSFSP(true)
				} else {
					if (isSFSP) {
						history.push('/sfsp/add-ons')
					} else {
						history.push(getNextPath())
					}
				}
			}
		} else {
			// trigger SFSP edge case
			if (sfspEdgeCase !== '') {
				setModalContentSFSP(
					<NoticeForSFSPEdgeCase
						sfspEdgeCase={sfspEdgeCase}
						proceed={() => handleProceedForSFSPNotice(sfspEdgeCase)}
					/>
				)
				setModalStateSFSP(true)
			} else {
				if (isSFSP) {
					history.push('/sfsp/add-ons')
				} else {
					history.push(getNextPath())
				}
			}
		}
	}

	const removeLocationWithWarning = async (event, locationId) => {
		event.preventDefault()
		event.stopPropagation()

		const optionsConfig = {
			...imageDeleteConfirmOptions,
			title: 'Do you want to delete this risk location?',
		}

		const result = await Swal.fire(optionsConfig)
		if (result.isConfirmed) {
			dispatch({
				type: 'DELETE_RISK_LOCATION',
				data: {
					locationId: locationId,
				},
			})
			await fetch.saveQuote()
			Swal.fire('Risk location has been deleted')
		}
	}

	const navigateToReview = locationId => {
		// set and navigate
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: locationId,
			},
		})

		history.push('/risk-location-review')
	}

	const navigateToLocationScreen = (locationItem, status) => {
		if (status == 'Complete') {
			navigateToReview(locationItem.ID)
		} else {
			proceed(locationItem)
		}
	}

	const locationCount = state.quote?.RiskLocations?.length
	let locationList = []
	let leftCTA = null

	state.quote?.RiskLocations?.forEach((item, index) => {
		let locStatus = getLocationStatus(item)
		if (locStatus === 'Complete' && leftCTA === null) {
			leftCTA = (
				<Button
					label="Add a risk location"
					icon="addBg"
					bgColor="#fff"
					textColor={theme.colors.primary}
					iconStyles={{ marginRight: '0.5rem', width: '32px', height: '32px' }}
					style={{ fontSize: '24px', fontWeight: 700, padding: 0 }}
					onClick={addNewRiskLocation}
				/>
			)
		}

		let PIN = item.PIN
		if (item.Type === 'PROPERTYBASEMENT') {
			PIN = item?.property?.PIN
		}
		locationList.push(
			<Row key={item.ID}>
				<LocationCard
					onClick={() => navigateToLocationScreen(item, locStatus)}
					className={` ${
						index == locationCount ? 'mb-5' : 'mb-3'
					} p-3 d-flex align-items-center justify-content-between`}
				>
					<LocationReview
						label={item.label}
						type={item.Type}
						status={locStatus}
						pinCode={PIN}
						navigateToReview={event => {
							navigateToReview(event, item.ID)
						}}
						remove={event => {
							removeLocationWithWarning(event, item.ID)
						}}
					/>
					<Icon name="rightArrow" color={theme.colors.gray2} />
				</LocationCard>
			</Row>
		)
	})

	return (
		<Container>
			<MainContainer
				header="Let’s understand the client requirements"
				rightOnClick={handleSaveAndContinue}
				rightLabel={'Continue'}
				rightDisabled={!allLocationsFilled}
				leftCTA={leftCTA}
				top={1.5}
			>
				<Row>
					<Col className="mb-3">
						<Text type={'primary'}>Click on a risk location to continue:</Text>
					</Col>
				</Row>

				{locationList}

				<Modal
					show={modalState}
					handleClose={closeModal}
					closeButton={true}
					title={
						<Text type="primary" color={theme.colors.red}>
							Notice
						</Text>
					}
					footer={<></>}
					style={{ textAlign: 'center' }}
				>
					{modalContent}
				</Modal>

				{/* For SFSP */}
				<Modal
					show={modalStateSFSP}
					handleClose={() => setModalStateSFSP(false)}
					closeButton={true}
					title={
						<Text type="primary" color={theme.colors.red}>
							Notice
						</Text>
					}
					footer={<></>}
					style={{ textAlign: 'center' }}
				>
					{modalContentSFSP}
				</Modal>
			</MainContainer>
		</Container>
	)
}

export default RiskLocationSelection
