import { Space, theme, Text, Button } from 'verak-ui'

const buttonStyle = {
	height: '64px',
	fontSize: '18px',
}

const StockDetailsSavedModalContent = ({ onContinue }) => {
	return (
		<>
			<Space y={1} />
			<Text type="h5" fontWeight="700" color={theme.colors.primary} style={{ textAlign: 'center' }}>
				Stock details have been saved!
			</Text>
			<Space y={1} />
			<Text
				type="h5"
				fontWeight="700"
				color={theme.colors.secondary}
				style={{ textAlign: 'center' }}
			>
				If the client has structure or contents to insure, please enter the values.
			</Text>
			<Space y={1} />
			<Text
				type="h5"
				fontWeight="700"
				color={theme.colors.secondary}
				style={{ textAlign: 'center' }}
			>
				Otherwise, you can hit Save & Continue on the next screen and proceed to the next step.
			</Text>
			<Space y={1.5} />
			<Button size="stretch" primary label="Continue" style={buttonStyle} onClick={onContinue} />
		</>
	)
}

export default StockDetailsSavedModalContent
