export const calculateStockForOneRiskLocation = (riskLocation, stockDisclosureMethod) => {
    // console.log('$$', quote)
	let total = 0

	const calcStockForOneLoc = detailsList => {
		for (const item in detailsList) {
			total = total + detailsList[item]?.AmountClosed + detailsList[item]?.AmountOpen
		}
	}

	const calcStock = obj => {
		if (obj?.levels) {
			for (const level in obj?.levels) {
				let levelStockData = obj?.levels[level]?.Stock
				if (levelStockData?.Present) {
					calcStockForOneLoc(levelStockData?.Details)
				}
			}
		} else if (obj?.Stock?.Present) {
			calcStockForOneLoc(obj?.Stock?.Details)
		}
	}

	if (stockDisclosureMethod === 'DECLARED_VALUE' || stockDisclosureMethod === 'DECLARATION_BASIS') {
        if (riskLocation.Type === 'PROPERTYBASEMENT') {
            calcStock(riskLocation.property)
            calcStock(riskLocation.basement)
        } else {
            calcStock(riskLocation)
        }
		return total
	} else {
        return 0
		// return quote?.DeclarationQ?.MaxValueAllLoc
	}
}
