import { Text } from 'verak-ui'
import styled from 'styled-components'

const InfoBox = ({ icon, iconSize = '76px', className, children }) => {
	return (
		<div className={`d-flex align-items-start ${className}`}>
			<img height={iconSize} width={iconSize} src={icon} alt="reference-img" className="me-2" />
			{children}
		</div>
	)
}

const ImageWithText = ({ image, text }) => {
	return (
		<div className="d-flex flex-column align-items-center">
			<img src={image} alt="img" />
			<InfoText className="mt-2" fontWeight="700">
				{text}
			</InfoText>
		</div>
	)
}

const InfoText = styled(Text)`
	font-size: ${props => props.fontSize || '14px'};
	color: ${props => props.color || 'black'};
	letter-spacing: ${props => (props.spaced ? '0.14em' : '0')};
`

const VerticalDivider = styled.div`
	background: #b3b3b3;
	width: 1px !important;
	padding: 0px;
	margin: 0 1rem;
`

const HorizontalDivider = styled.div`
	background: #b3b3b3;
	height: 1px !important;
	padding: 0px;
	margin: 1rem 0;
`

export { InfoBox, ImageWithText, InfoText, VerticalDivider, HorizontalDivider }
