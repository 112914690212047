// Function doesn't support "PROPERTYBASEMENT + levels" -> returns '' for this case
export const getAddress = (rlData, handleUpdate) => {
	const { Type, levels } = rlData || {}
	let addressData = {}

	if (Type === 'PROPERTY' || Type === 'BASEMENT') {
		if (levels && levels[0]?.AddressLine1) {
			addressData = levels[0]
		} else {
			addressData = rlData
		}
	} else {
		if (rlData?.property?.levels || rlData?.basement?.levels) {
			handleUpdate('', 'Address')
			return
		} else {
			addressData = rlData?.property || rlData?.basement || {}
		}
	}

	const { AddressLine1, AddressLine2, PIN, City, State } = addressData
	handleUpdate([AddressLine1, AddressLine2, PIN, City, State].filter(Boolean).join(', '), 'Address')
}
