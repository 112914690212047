import { useContext, Fragment, useEffect, useState } from 'react'
import { Context } from '../../context/Provider'
import { Row, Col } from 'react-bootstrap'
import { Divider, FileUploaded, Text, theme } from 'verak-ui'
import { useHistory } from 'react-router'

import { useFetch } from '../../api/apihook'

import {
	// utils and components
	getFormattedDate,
	getConstructionYearsString,
	getNumString,
	InfoSection,
	InfoRow,
	noStockAlert,
	getPropertyString,
	getStockDisclosureMethodString,
	convertAmountToWords,
	getOccupancyValue,
	getCustomerDetails,
	// styles below
	StockType,
	boldText,
	getOwnership,
	constructionDesc,
	getReportingStatement,
	secondaryTitle,
	ClaimsHistoryTable,
	ClaimsHistoryTableHeader,
	ClaimsHistoryTableData,
	ClaimsHistoryTableRow,
} from '../RiskLocationReview/reviewScreenUtils'

import {
	calcGoodsInTrustSIForOneLoc,
	calcSIforOneLoc,
	calcStockSIForOneLoc,
	checkIndustrialOrManufacturing,
	getClaimsHistoryDamageString,
	getClaimsHistoryStatusString,
	getMonthNameFromNumber,
	goodsInTrustSIForAllLoc,
	goodsInTrustStocksForAllLoc,
	isGoodsInTrustPresentForSomeLoc,
	checkForTypeOfOccupancy,
} from './finalReviewUtils'
import { isBuildingHeightInFloors } from '../../utilities/isBuildingHeightInFloors'
import EditClientDetailsModal from './EditClientDetailsModal'

const ReviewComponent = ({
	floorOrLevel = '',
	floorString = '',
	style = {},
	data,
	returnPath,
	propertyType,
	levelIndex,
}) => {
	const history = useHistory()
	const [state, dispatch] = useContext(Context)
	const fetch = useFetch()

	const [showEditClientDetailsModal, setShowEditClientDetailsModal] = useState(false)

	useEffect(() => {
		if (!cachedData) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	if (!data) {
		return null
	}
	const cachedData = fetch?.cachedData?.SubCategories?.required

	const fetchSubCategories = async () => {
		if (!cachedData) {
			await fetch.getSubCategoryByRiskType()
		}
	}

	const { Type, property, basement, levels } = data
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'
	const isBasement = Type === 'BASEMENT'
	const isSFSP = state?.quote?.PolicyName === 'SFSP'

	const rfqEditAllowed = state?.rfq?.editRfqMode
	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	const rfqViewOnlyMode = state?.rfq?.rfqViewOnlyMode || false

	let AddressLine1, AddressLine2, City, State, PIN
	if (isPropertyBasement) {
		AddressLine1 = data.property.AddressLine1
		AddressLine2 = data?.property?.AddressLine2 || ''
		City = data.property.City
		State = data.property.State
		PIN = data.property.PIN
	} else {
		AddressLine1 = data.AddressLine1
		AddressLine2 = data?.AddressLine2 || ''
		City = data.City
		State = data.State
		PIN = data.PIN
	}
	let AddressString = `${AddressLine1} ${AddressLine2}, ${City}, ${State}, ${PIN}`

	let floorFieldLabel = 'Floor number'

	// let SumInsured = {}
	let specificData = {}
	let StockDataMap = []
	let otherData = {}

	// handle case for PROPERTYBASEMENT

	if (data?.Type === 'PROPERTYBASEMENT') {
		if (parseInt(floorOrLevel) >= 0) {
			otherData = { ...otherData, ...data?.property }

			// for multiple property
			if (data?.property?.levels?.length > 0) {
				specificData = data?.property?.levels?.find(({ Level }) => Level === floorOrLevel)
			} else {
				// for single or whole property
				specificData = data?.property
			}
		} else {
			otherData = { ...otherData, ...data?.basement }

			// for multiple basement
			if (data?.basement?.levels?.length > 0) {
				specificData = data?.basement?.levels?.find(({ Level }) => Level === floorOrLevel)
			} else {
				// for single or whole basement
				specificData = data?.basement
			}
		}
	} else {
		otherData = { ...otherData, ...data }
		// only property or only basement
		if (data?.levels) {
			// multiple property or basement
			specificData = data?.levels?.find(({ Level }) => Level === floorOrLevel)
		} else {
			// single property or basement
			specificData = data
		}
	}

	if (!!specificData?.Stock?.Present) {
		StockDataMap = specificData?.Stock?.Details
	}

	if (isBasement) {
		if (otherData?.SubType === 'Whole') {
			floorFieldLabel = 'No of levels in the basement'
		} else {
			floorFieldLabel = 'Level number'
		}
	} else {
		if (otherData?.SubType === 'Whole') {
			floorFieldLabel = 'No of floors in the building'
		} else {
			floorFieldLabel = 'Floor number'
		}
	}

	const generateTabData = () => {
		const tabData = {}

		if (isPropertyBasement) {
			if (floorOrLevel >= 0) {
				tabData.propertyType = 'Property'
				if (property?.levels?.length) {
					tabData.levelNum = floorOrLevel
				}
			} else {
				tabData.propertyType = 'Basement'
				if (basement?.levels?.length) {
					tabData.levelNum = floorOrLevel
				}
			}
		} else {
			if (floorOrLevel >= 0) {
				if (levels?.length) {
					tabData.levelNum = floorOrLevel
				}
			} else {
				if (levels?.length) {
					tabData.levelNum = floorOrLevel
				}
			}
		}
		return tabData
	}

	const moveToLocation = ID => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
	}

	const checkIfSQSUpdateAllowed = () => {
		switch (rfqStatus) {
			case 'OPS:QUOTES:PENDING':
			case 'AGENT:QUOTES:PENDING':
			case 'CLIENT:QUOTES:AVAILABLE':
			case 'AGENT:ALL_QUOTES:PENDING':
			case 'OPS:RFQ_RESUBMISSION:PENDING':
				return true
			default:
				return false
		}
	}

	const setSQSUpdateFlow = async () => {
		// trigger sqs update flow for only these statuses
		// statuses picked up from Tags.js
		const sqsUpdateAllowedFlow = checkIfSQSUpdateAllowed()
		// set update flow to true if it wasn't set already
		if (sqsUpdateAllowedFlow && !rfqEditAllowed) {
			console.log('setting the update flow to true...')
			// set update flow as true
			await new Promise(resolve => {
				dispatch({
					type: 'SET_EDIT_RFQ',
					data: { editRfqMode: true },
				})
				dispatch({
					type: 'SET_OLD_RFQ',
					data: JSON.parse(JSON.stringify(state?.quote)),
				})
				resolve()
			})

			// change the final flow completion routes
			const flowCompletionRoutes = {
				finalRoute: '/note-for-insurer',
				redirectRoute: '/rfq-status/update-rfq-success',
			}
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_FLOW_COMPLETION_ROUTES',
					data: { flowCompletionRoutes },
				})
				resolve()
			})
		}
	}

	const editRLType = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const tabData = generateTabData()
		const state = {
			returnPath: returnPath,
			edit: true,
			tabData,
		}
		history.push({ pathname: '/property-check', state })
	}

	const editSumInsured = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const tabData = generateTabData()
		const state = {
			returnPath: returnPath,
			edit: true,
			tabData,
		}
		history.push({ pathname: '/property-risk/sum-insured', state })
	}

	const editLocationDetails = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const tabData = generateTabData()
		const state = {
			returnPath: returnPath,
			edit: true,
			tabData,
		}
		history.push({ pathname: '/property-risk/location-details', state })
	}

	const editTheftRisk = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const state = {
			returnPath: returnPath,
			edit: true,
		}
		history.push({ pathname: '/property-risk/theft-risk', state })
	}

	const editShopRisk = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const state = {
			returnPath: returnPath,
			edit: true,
		}
		history.push({ pathname: '/property-risk/shop-risk', state })
	}

	const editConstruction = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const state = {
			returnPath: returnPath,
			edit: true,
		}
		history.push({ pathname: '/property-risk/materials', state })
	}

	const editFireRisk = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const state = {
			returnPath: returnPath,
			edit: true,
		}
		history.push({ pathname: '/property-risk/fire-system', state })
	}

	const editHistorticInfo = () => {
		setSQSUpdateFlow()
		moveToLocation(data?.ID || '')
		const state = {
			returnPath: returnPath,
			edit: true,
		}
		history.push({ pathname: '/property-risk/historic-info', state })
	}

	const editStockTypes = async () => {
		setSQSUpdateFlow()
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_RETURN_PATH',
				data: {
					returnPath: '/journey/risk-location-selection',
				},
			})
			resolve()
		})

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'MOVE_TO_LOCATION',
				data: {
					locationId: data.ID || '',
				},
			})
			resolve()
		})

		history.push({
			pathname: '/stock-details-collection',
			state: {
				propertyType,
				levelIndex,
				currentLocationId: state?.location?.ID,
				returnPath: returnPath,
				edit: true,
			},
		})
	}

	const editGoodsinTrust = () => {
		// moveToLocation(data?.ID || '')
		// const tabData = generateTabData()
		// const state = {
		// 	returnPath: returnPath,
		// 	edit: true,
		// 	tabData,
		// }
		// history.push({
		// 	pathname: '/property-risk/goods-in-trust-data',
		// 	state,
		// })
	}

	// maps;

	let displayCoOwners = []

	if (specificData?.CoOwners?.length > 0) {
		specificData?.CoOwners?.forEach((cown, index) => {
			if (index !== 0) {
				displayCoOwners.push(
					<InfoRow key={`co-owner-${index}-${propertyType}-${levelIndex}`} subText={cown} />
				)
			}
		})
	}

	const isThirdPartyPresent = specificData?.ThirdPartyUsage?.Present
	const isThirdPartyFilled = typeof isThirdPartyPresent === 'boolean'
	let thirdPartyPresenceText = '-'

	if (isThirdPartyFilled) {
		if (isThirdPartyPresent) {
			thirdPartyPresenceText = 'Yes'
		} else {
			thirdPartyPresenceText = 'No'
		}
	}

	let displayThirdPartyUsage = []

	if (specificData?.ThirdPartyUsage?.Details?.length > 0) {
		specificData?.ThirdPartyUsage?.Details?.forEach((tItem, index) => {
			if (index !== 0) {
				displayThirdPartyUsage.push(
					<InfoRow
						key={`third-party-${index}-${propertyType}-${levelIndex}`}
						subText={tItem?.SubCategory || tItem?.Category || '-'}
					/>
				)
			}
		})
	}

	let displaySecondaryOccupancy = []

	if (specificData?.SecondaryOccupancy?.length > 0) {
		specificData?.SecondaryOccupancy?.forEach((sItem, index) => {
			if (index !== 0) {
				displaySecondaryOccupancy.push(
					<InfoRow
						key={`sec-occupancy-${index}-${propertyType}-${levelIndex}`}
						subText={sItem?.SubCategory || sItem?.Category || '-'}
					/>
				)
			}
		})
	}

	let displayHypothecation = []

	if (specificData?.Hypothecation?.length > 1) {
		specificData.Hypothecation?.forEach((hypo, index) => {
			if (index !== 0) {
				displayHypothecation.push(
					<InfoRow
						key={`hypothecation-${index}-${propertyType}-${levelIndex}`}
						leftText=" "
						rightText={`${hypo?.Name || ''}, ${hypo?.Type || ''}`}
					/>
				)
			}
		})
	}

	// industrial or manuf check
	const isIndustrialOrManuf = checkIndustrialOrManufacturing(otherData, cachedData)

	// is dwellings check
	const isDwellings = checkForTypeOfOccupancy(otherData, cachedData)

	const historicInfoClaims = otherData?.HistoricInfo?.Claims || []

	const historicClaimsPresent =
		historicInfoClaims && historicInfoClaims !== 'NA' && historicInfoClaims?.length > 0
	let displayClaimsHistoryTables = []

	if (historicClaimsPresent) {
		displayClaimsHistoryTables = historicInfoClaims?.map((claimItem, key) => {
			let claimsAmount = claimItem?.Amount || ''
			let claimsAmountText = ''
			if (claimsAmount) {
				claimsAmountText = convertAmountToWords(`₹ ${claimsAmount}`)
			}

			return (
				<ClaimsHistoryTable
					className="mb-3"
					key={`claims-history-table-${key}-${propertyType}-${levelIndex}`}
				>
					<thead>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableHeader>CLAIM #{key + 1}</ClaimsHistoryTableHeader>
							<ClaimsHistoryTableHeader></ClaimsHistoryTableHeader>
						</ClaimsHistoryTableRow>
					</thead>
					<tbody>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>
								What was damaged/affected during the event?
							</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>
								{getClaimsHistoryDamageString(claimItem?.Damaged || '') || '-'}
							</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>
								The month during which the event occured
							</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>
								{getMonthNameFromNumber(claimItem?.Month || '') || '-'}
							</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>
								The year during which the event occured
							</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>{claimItem?.Year || '-'}</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>Claims amount</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>{claimsAmountText || '-'}</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>What is the claim status?</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>
								{getClaimsHistoryStatusString(claimItem?.Status || '') || '-'}
							</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>
								What were the reason(s) for the claim?
							</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>
								{claimItem?.Reasons?.join(', ') || '-'}
							</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
						<ClaimsHistoryTableRow>
							<ClaimsHistoryTableData>
								Please specify any post-loss risk mitigation measures that have been introduced
							</ClaimsHistoryTableData>
							<ClaimsHistoryTableData>{claimItem?.RiskMitigation || '-'}</ClaimsHistoryTableData>
						</ClaimsHistoryTableRow>
					</tbody>
				</ClaimsHistoryTable>
			)
		})
	}

	// stock valuation method
	// used for floater declaration
	const floaterDeclTotalStockVal = data?.DeclarationQ?.MaxValueAllLoc || ''
	let floaterDeclTotalStockAmountString = ''
	if (floaterDeclTotalStockVal) {
		floaterDeclTotalStockAmountString = convertAmountToWords(`₹ ${floaterDeclTotalStockVal}`)
	}

	const floaterDeclMaxStockVal = data?.DeclarationQ?.MaxValueOneLoc || ''
	let floaterDeclMaxStockAmountString = ''
	if (floaterDeclMaxStockVal) {
		floaterDeclMaxStockAmountString = convertAmountToWords(`₹ ${floaterDeclMaxStockVal}`)
	}

	// stock types
	const isStockThirdPartyStoragePresent = specificData?.Stock?.ThirdPartyUsage?.Present
	const isStockThirdPartyStorageFilled = typeof isStockThirdPartyStoragePresent === 'boolean'

	let stockThirdPartyStorageText = '-'
	if (isStockThirdPartyStorageFilled) {
		if (isStockThirdPartyStoragePresent) {
			stockThirdPartyStorageText = 'Yes'
		} else {
			stockThirdPartyStorageText = 'No'
		}
	}
	// check for stock disclosure method and display stocks
	const isDeclaredOrDeclarationMethod =
		data?.StockDisclosureMethod === 'DECLARED_VALUE' ||
		data?.StockDisclosureMethod === 'DECLARATION_BASIS'

	let stockDetailsText = 'Percentage value'
	if (isDeclaredOrDeclarationMethod) {
		stockDetailsText = 'Value'
	}

	const goodsHeldInTrustTag = (
		<div
			style={{ background: '#02475E', borderRadius: '8px', padding: '0.5rem', marginLeft: '1rem' }}
		>
			<Text
				style={{ fontSize: '14px', letterSpacing: '0.05em', fontWeight: 700 }}
				color={theme.colors.white}
			>
				GOODS HELD IN TRUST
			</Text>
		</div>
	)

	const StockDetailsMap =
		!!specificData?.Stock?.Present &&
		StockDataMap?.map((item, key) => {
			let coveredStock = `${item?.ClosedPer || 0}%`
			let openStock = `${item?.OpenPer || 0}%`
			if (isDeclaredOrDeclarationMethod) {
				coveredStock = convertAmountToWords(`₹ ${item?.AmountClosed || 0}`)
				openStock = convertAmountToWords(`₹ ${item?.AmountOpen || 0}`)
			}
			let goodsHeldInTrust = null
			if (item?.GoodsHeldInTrust) {
				goodsHeldInTrust = goodsHeldInTrustTag
			}

			return (
				<StockType className="mb-3" key={`nature-of-stock-${key}-${propertyType}-${levelIndex}`}>
					<InfoSection
						sectionTitle={`STOCK TYPE - ${getNumString(key + 1)}`}
						edit={false}
						containerStyles={{ background: 'transparent', boxShadow: 'none' }}
						goodsHeldInTrust={goodsHeldInTrust}
					>
						<InfoRow leftText="Nature of stock" rightText={item?.Type} />
						<InfoRow
							leftText={`${stockDetailsText} of stock kept covered`}
							rightText={coveredStock}
						/>
						<InfoRow leftText={`${stockDetailsText} of stock kept in open`} rightText={openStock} />
					</InfoSection>
				</StockType>
			)
		})

	const BlockPlanImagesMap =
		otherData?.TheftRisk?.BlockPlan?.length > 0 &&
		otherData?.TheftRisk?.BlockPlan?.map((el, index) => (
			<FileUploaded key={`blockplan-imgs-${index}-${propertyType}-${levelIndex}`} imageUrl={el} />
		))

	const MaterialImagesMap =
		otherData?.Materials?.Images &&
		otherData?.Materials?.Images?.map((el, index) => (
			<FileUploaded key={`materials-imgs-${index}-${propertyType}-${levelIndex}`} imageUrl={el} />
		))

	const FireFightingImagesMap =
		otherData?.FireRisk?.Images?.FireFightingEquipments &&
		otherData?.FireRisk?.Images?.FireFightingEquipments?.map((el, key) => (
			<FileUploaded
				key={`firefighting-equipmts-${key}-${propertyType}-${levelIndex}`}
				imageUrl={el}
			/>
		))

	let WaterBodyDistance = '-'
	let FireStationDistance = '-'
	let IsRailwayCrossing = '-'

	if (!!otherData?.FireRisk?.DistWaterBody) {
		WaterBodyDistance = `${otherData?.FireRisk?.DistWaterBody} ${
			otherData?.FireRisk?.DistWaterBody > 1 ? 'kms' : 'km'
		}`
	}

	if (!!otherData?.FireRisk?.DistFireStation) {
		FireStationDistance = `${otherData?.FireRisk?.DistFireStation} ${
			otherData?.FireRisk?.DistFireStation > 1 ? 'kms' : 'km'
		}`
	}

	if (!!otherData?.FireRisk?.RailwayCrossing) {
		IsRailwayCrossing = Boolean(otherData?.FireRisk?.RailwayCrossing) ? 'Yes' : 'No'
	}

	// const totalSIValue = convertAmountToWords(`₹ ${calcSIforOneLoc(data, propertyType, levelIndex)}`)

	let totalSIAmount = '-'
	const totalSI = calcSIforOneLoc(data, propertyType, levelIndex) || 0
	const totalStock = calcStockSIForOneLoc(data, propertyType, levelIndex) || 0
	const totalGoodsInTrust = calcGoodsInTrustSIForOneLoc(data, propertyType, levelIndex) || 0

	if (totalSI || totalStock || totalGoodsInTrust) {
		totalSIAmount = convertAmountToWords(`₹ ${totalSI + totalStock + totalGoodsInTrust}`)
	}

	let displayStockHypothecation = []
	if (specificData?.Stock?.Hypothecation?.length > 1) {
		specificData?.Stock?.Hypothecation?.forEach((hypo, index) => {
			if (index !== 0) {
				displayStockHypothecation.push(
					<InfoRow
						key={`stock-hypothecation-${index}-${propertyType}-${levelIndex}`}
						leftText=" "
						rightText={`${hypo?.Name || ''}, ${hypo?.Type || ''}`}
					/>
				)
			}
		})
	}

	let displayThirdPartyStockStorage = []
	if (specificData?.Stock?.ThirdPartyUsage?.Details?.length > 0) {
		let allTypes = ''
		specificData?.Stock?.ThirdPartyUsage?.Details.forEach((tItem, idx) => {
			if (idx === 0) {
				allTypes = allTypes + tItem
			} else {
				allTypes = allTypes + ', ' + tItem
			}
		})
		displayThirdPartyStockStorage.push(<InfoRow subText={allTypes} key={'third-party-usage'} />)
	}

	// display products manufactured at the location
	let displayManufacturedProducts = []
	if (otherData?.Products?.length > 0) {
		let allProducts = ''
		otherData?.Products?.forEach((prod, idx) => {
			if (idx === 0) {
				allProducts = allProducts + prod
			} else {
				allProducts = allProducts + ', ' + prod
			}
		})
		displayManufacturedProducts.push(<InfoRow subText={allProducts} key={'all-products'} />)
	}
	if (displayManufacturedProducts.length === 0) {
		displayManufacturedProducts.push(<InfoRow leftText="-" key={'-'} />)
	}

	// hisotric info
	const isInsuredElsewhere = otherData?.HistoricInfo?.InsuredElsewhere
	const isInsuredElsewhereFilled = typeof isInsuredElsewhere === 'boolean'
	let historicInfoInsuredElsewhereText = '-'

	if (isInsuredElsewhereFilled) {
		if (isInsuredElsewhere) {
			historicInfoInsuredElsewhereText = 'Yes'
		} else {
			historicInfoInsuredElsewhereText = 'No'
		}
	}

	let historicInfoOtherCompanySubTextItems = []

	if (isInsuredElsewhere) {
		if (otherData?.HistoricInfo?.OtherInsurer) {
			historicInfoOtherCompanySubTextItems.push(otherData?.HistoricInfo?.OtherInsurer)
		}
		if (otherData?.HistoricInfo?.StartDate) {
			historicInfoOtherCompanySubTextItems.push(
				getFormattedDate(otherData?.HistoricInfo?.StartDate)
			)
		}
		if (otherData?.HistoricInfo?.PolicyPDF?.url) {
			const policyPDFLink = (
				<a
					href={otherData?.HistoricInfo?.PolicyPDF?.url}
					download
					target="_blank"
					rel="noopener noreferrer"
				>
					Download Policy PDF
				</a>
			)
			historicInfoOtherCompanySubTextItems.push(policyPDFLink)
		}
	}

	const displayHistoricInfoOtherCompanySubText = historicInfoOtherCompanySubTextItems.map(
		(el, idx) => {
			let separator = ''
			if (idx > 0) {
				separator = ' - '
			}
			return (
				<Fragment key={`historic-info-othercomp-${idx}-${propertyType}-${levelIndex}`}>
					{separator}
					{el}
				</Fragment>
			)
		}
	)

	const isCoverDenied = otherData?.HistoricInfo?.CoverDenied
	const isCoverDeniedFilled = typeof isCoverDenied === 'boolean'
	let historicInfoCoverDeniedText = '-'

	if (isCoverDeniedFilled) {
		if (isCoverDenied) {
			historicInfoCoverDeniedText = 'Yes'
		} else {
			historicInfoCoverDeniedText = 'No'
		}
	}

	let historicInfoCoverDenialSubTextItems = []

	if (isCoverDenied) {
		if (otherData?.HistoricInfo?.DenialInsurer) {
			historicInfoCoverDenialSubTextItems.push(otherData?.HistoricInfo?.DenialInsurer)
		}
		if (otherData?.HistoricInfo?.DenialReason) {
			historicInfoCoverDenialSubTextItems.push(otherData?.HistoricInfo?.DenialReason)
		}
	}

	const displayHistoricInfoCoverDenialSubtext = historicInfoCoverDenialSubTextItems.join(' - ')

	// goods in trust
	const isGoodsInTrustPresent = isGoodsInTrustPresentForSomeLoc(data, propertyType, levelIndex)

	let goodsInTrustTitle = (
		<>
			Goods held in trust -
			<i style={{ color: '#8d8d8d' }}>
				For {data?.label} ({PIN})
			</i>
		</>
	)

	const totalGoodsInTrustAmt = goodsInTrustSIForAllLoc(data, propertyType, levelIndex) || ''

	const GoodsInTrustProperty = totalGoodsInTrustAmt && (
		<Row>
			<Col>
				<Text className="mb-3" style={secondaryTitle}>
					PROPERTY
				</Text>
				<InfoRow
					leftText="Total Sum Insured"
					rightText={convertAmountToWords(`₹ ${totalGoodsInTrustAmt}`)}
				/>
			</Col>
		</Row>
	)

	let allGoodsInTrustStock = goodsInTrustStocksForAllLoc(data)
	let GoodsInTrustStockList, GoodsInTrustStock

	if (allGoodsInTrustStock) {
		GoodsInTrustStockList = allGoodsInTrustStock.map((item, key) => {
			return (
				<StockType
					className="mb-3"
					key={`goods-in-trust-stock-${key}-${propertyType}-${levelIndex}`}
				>
					<InfoSection
						sectionTitle={`STOCK TYPE - ${getNumString(key + 1)}`}
						edit={false}
						containerStyles={{ background: 'transparent', boxShadow: 'none' }}
					>
						<InfoRow leftText="Nature of stock" rightText={item?.Type} />
						<InfoRow
							leftText="Value of stock kept covered"
							rightText={convertAmountToWords(`₹ ${item?.AmountClosed}`)}
						/>
						<InfoRow
							leftText="Value of stock kept in open"
							rightText={convertAmountToWords(`₹ ${item?.AmountOpen}`)}
						/>
					</InfoSection>
				</StockType>
			)
		})
	}

	if (GoodsInTrustStockList && GoodsInTrustStockList.length > 0) {
		GoodsInTrustStock = (
			<>
				<Divider style={{ border: `1px solid #02475E` }} top="0.5" bottom="1" />
				<Text className="mb-3" style={secondaryTitle}>
					STOCK
				</Text>
				{GoodsInTrustStockList}
			</>
		)
	}

	const getRadioBtnValue = value => {
		if (value === true) {
			return 'Yes'
		} else if (value === false) {
			return 'No'
		} else {
			return '-'
		}
	}

	let desiredSumInsuredStatement = ''
	let desiredSumInsuredContents = []

	if (totalSI !== 0) {
		desiredSumInsuredContents.push('Property & Contents Sum Insured')
	}
	if (totalStock !== 0) {
		desiredSumInsuredContents.push('Stock Sum Insured')
	}
	// // goods in trust is temporarily hidden
	// if (isGoodsInTrustPresent) {
	// 	desiredSumInsuredContents.push('Goods Held in Trust Sum Insured')
	// }

	// statement for sum insured
	if (desiredSumInsuredContents.length > 0) {
		desiredSumInsuredStatement = 'CONTAINS '
		desiredSumInsuredStatement += desiredSumInsuredContents.join(' + ')
	}

	// building height logic
	let leftTextForBuildingHeight, rightTextForBuildingHeight
	if (isSFSP && otherData?.Type === 'PROPERTY') {
		if (isBuildingHeightInFloors(otherData?.Type, otherData)) {
			leftTextForBuildingHeight = 'Building height in floors'
			rightTextForBuildingHeight = `${otherData?.Floors} floors`
		} else if (otherData?.BuildingHeight) {
			leftTextForBuildingHeight = 'Building height in meters'
			rightTextForBuildingHeight = `${otherData?.BuildingHeight} meters`
		}
	}

	// customer details
	let customerDetails = getCustomerDetails(state?.rfq)
	if (Object.keys(customerDetails)?.length === 0) {
		// state.rfq is empty while rfq creation
		const { CompanyName, ProposerName, Phone } = state?.quote?.ClientDetails
		customerDetails = {
			EntityName: CompanyName,
			ProposerName,
			Phone,
		}
	}

	const currentLocation = { ...generateTabData(), locationId: data?.ID }

	const handleClientDetailsEdit = () => {
		setShowEditClientDetailsModal(true)
	}

	let toDisplayFloor
	if (floorString) {
		toDisplayFloor = floorString
	} else if (typeof floorOrLevel == 'number') {
		toDisplayFloor = `${floorOrLevel}`
	}
	return (
		<div style={style}>
			{/* SECTION: PROPERTY TYPE */}

			{/* SECTION: PROPERTY TYPE */}
			<InfoSection
				sectionTitle="RISK LOCATION TYPE"
				onEditClick={editRLType}
				edit={!rfqViewOnlyMode && !isPropertyBasement}
			>
				<Text style={boldText}>{getPropertyString(data?.Type)}</Text>
				{!specificData?.Stock?.Present && noStockAlert}
			</InfoSection>

			{/* SECTION: DESIRED SUM INSURED FOR THIS RISK LOCATION */}
			<InfoSection
				sectionTitle="Desired Sum Insured"
				sectionSubTitle={<span style={{ color: '#8d8d8d' }}>{desiredSumInsuredStatement}</span>}
				onEditClick={editSumInsured}
				edit={!rfqViewOnlyMode}
			>
				<Text style={boldText}>{totalSIAmount}</Text>
			</InfoSection>

			{/* SECTION: CLIENT DETAILS */}
			<InfoSection
				sectionTitle="Client Details"
				onEditClick={handleClientDetailsEdit}
				edit={!rfqViewOnlyMode}
			>
				<InfoRow leftText="Proposer Name" rightText={customerDetails?.ProposerName} />
				<InfoRow leftText="Legal Entity Name" rightText={customerDetails?.EntityName} />
				<InfoRow leftText="Email" rightText={customerDetails?.Email} />
				<InfoRow leftText="Contact Number" rightText={customerDetails?.Phone || '-'} />
				<InfoRow leftText="Whatsapp Number" rightText={customerDetails?.WaPhone || '-'} />
				<InfoRow
					leftText="Type of Facility"
					rightText={getOccupancyValue(specificData?.PrimaryOccupancy?.SubCategory, 'Type')}
				/>
				<InfoRow
					leftText="Nature of Business"
					rightText={
						getOccupancyValue(specificData?.PrimaryOccupancy?.SubCategory, 'Nature') || '-'
					}
				/>
			</InfoSection>

			{/* SECTION: LOCATION DETAILS */}
			<InfoSection
				sectionTitle="Location details "
				onEditClick={editLocationDetails}
				edit={!rfqViewOnlyMode}
			>
				<InfoRow leftText={floorFieldLabel} rightText={toDisplayFloor} />
				<InfoRow leftText="Address" />

				{/* Street address, City, State, Floor no, PIN code */}
				<Text style={boldText}>{AddressString}</Text>

				<InfoRow
					leftText="Age of construction"
					rightText={
						getConstructionYearsString(
							otherData?.ConstructionAge?.High,
							otherData?.ConstructionAge?.Low
						) || '-'
					}
				/>

				<InfoRow
					leftText="Ownership status"
					rightText={getOwnership(specificData?.Ownership)}
					subText={specificData?.CoOwners?.[0] || ''}
				/>

				{displayCoOwners}

				<InfoRow
					leftText="3rd party usage"
					rightText={thirdPartyPresenceText}
					subText={
						specificData?.ThirdPartyUsage?.Details?.[0]?.SubCategory ||
						specificData?.ThirdPartyUsage?.Details?.[0]?.Category ||
						''
					}
				/>

				{displayThirdPartyUsage}

				<InfoRow
					leftText="Other occupancies at the risk location"
					rightText={specificData?.SecondaryOccupancy?.length > 0 ? 'Yes' : 'No'}
					subText={
						specificData?.SecondaryOccupancy?.[0]?.SubCategory ||
						specificData?.SecondaryOccupancy?.[0]?.Category ||
						''
					}
				/>

				{displaySecondaryOccupancy}

				{/* only first insurance interest party displayed below */}
				<InfoRow
					leftText="Parties that have insurable interest"
					rightText={
						!!specificData?.Hypothecation?.length > 0
							? `${specificData?.Hypothecation?.[0]?.Name || ''}, ${
									specificData?.Hypothecation?.[0]?.Type || ''
							  }`
							: '-'
					}
				/>

				{/* displaying other insurable interest parties */}
				{displayHypothecation}
			</InfoSection>

			{/* SECTION: THEFT RISK ASSESMENT */}
			<InfoSection
				sectionTitle="Theft risk assesment"
				showDivider
				onEditClick={editTheftRisk}
				edit={!rfqViewOnlyMode}
			>
				{/* only show for property */}
				{(otherData?.Type !== 'BASEMENT' || parseInt(floorOrLevel) >= 0) && (
					<>
						<InfoRow
							leftText="Is there a boundary wall?"
							rightText={otherData?.TheftRisk?.BoundaryWall ? 'Yes' : 'No'}
						/>

						{isIndustrialOrManuf && (
							<InfoRow
								leftText="What kind of factory is this?"
								rightText={otherData?.TheftRisk?.FactoryType || '-'}
							/>
						)}
					</>
				)}

				<InfoRow leftText="Security arrangements present on risk location premises" />
				{/* Anti-Theft locks, CCTV, 24 hour security etc. */}
				<Text style={boldText}>
					{otherData?.TheftRisk?.SecurityArrangements?.length > 0
						? otherData?.TheftRisk?.SecurityArrangements?.join(', ')
						: '-'}
				</Text>

				{otherData?.TheftRisk?.BlockPlan?.length > 0 && (
					<InfoRow leftText="Detailed block plan of manufacturing unit" />
				)}
				<div className="d-flex">{BlockPlanImagesMap}</div>

				{/* only show for property */}
				{/* TO check: shouldn't the condition for checking if it's industrial/manufacturing be present??
				    even for silent factory */}
				{(otherData?.Type !== 'BASEMENT' || parseInt(floorOrLevel) >= 0) && isIndustrialOrManuf && (
					<>
						<InfoRow leftText="List of products manufactured at the risk location" />
						{displayManufacturedProducts}
					</>
				)}
			</InfoSection>

			{/* SECTION: SHOP RISK ASSESMENT */}
			{isSFSP && isDwellings ? (
				<InfoSection
					sectionTitle="Shop risk assesment"
					showDivider
					onEditClick={editShopRisk}
					edit={!rfqViewOnlyMode}
				>
					<InfoRow leftText="List of high fire risk items stored at this location" />
					<Text style={boldText}>
						{otherData?.ShopRisk?.Goods?.length > 0 ? otherData?.ShopRisk?.Goods?.join(', ') : '-'}
					</Text>

					{otherData?.ShopRisk?.Goods?.length > 0 ? (
						<InfoRow
							leftText="Do these goods exceed 5% value of the risk location"
							rightText={getRadioBtnValue(otherData?.ShopRisk?.ExceedsLimit)}
						/>
					) : null}
				</InfoSection>
			) : null}

			{/* SECTION: CONSTRUCTION ASSESMENT */}
			<InfoSection
				sectionTitle="Construction assesment"
				showDivider
				onEditClick={editConstruction}
				edit={!rfqViewOnlyMode}
			>
				{otherData?.Type === 'BASEMENT' ? (
					// for basement
					<InfoRow
						leftText="Ceiling"
						rightText={`${otherData?.Materials?.Ceiling?.Type || '-'} ${constructionDesc(
							otherData?.Materials?.Ceiling?.Description || ''
						)}`}
					/>
				) : (
					// for property
					<InfoRow
						leftText="Roof"
						rightText={`${otherData?.Materials?.Roof?.Type || '-'} ${constructionDesc(
							otherData?.Materials?.Roof?.Description || ''
						)}`}
					/>
				)}

				<InfoRow
					leftText="Walls"
					rightText={`${otherData?.Materials?.Walls?.Type || '-'} ${constructionDesc(
						otherData?.Materials?.Walls?.Description || ''
					)}`}
				/>

				<InfoRow
					leftText="Floor"
					rightText={`${otherData?.Materials?.Floors?.Type || '-'} ${constructionDesc(
						otherData?.Materials?.Floors?.Description || ''
					)}`}
				/>

				{isSFSP && otherData?.Type === 'PROPERTY' ? (
					<InfoRow leftText={leftTextForBuildingHeight} rightText={rightTextForBuildingHeight} />
				) : null}

				{otherData?.Materials?.Images?.length > 0 && (
					<InfoRow leftText="Photos of each of the 4 corners of the building" />
				)}
				<div className="d-flex">{MaterialImagesMap}</div>
			</InfoSection>

			{/* SECTION: FIRE RISK ASSESMENT */}
			<InfoSection
				sectionTitle="Fire risk assesment"
				showDivider
				onEditClick={editFireRisk}
				edit={!rfqViewOnlyMode}
			>
				<InfoRow leftText="Fire protection mechanisms" />

				<Text style={boldText}>
					{otherData?.FireRisk?.Measures?.length > 0
						? otherData?.FireRisk?.Measures.join(', ')
						: '-'}
				</Text>

				<InfoRow
					leftText="Additional details provided"
					rightText={otherData?.FireRisk?.OtherPreventativeMeasures || '-'}
				/>

				<InfoRow
					leftText="What is the distance from this risk location to the nearest fire station?"
					rightText={FireStationDistance}
				/>

				<InfoRow
					leftText="Is there a railway crossing in between the public fire station and the risk location?"
					rightText={IsRailwayCrossing}
				/>

				<InfoRow
					leftText="What is the distance from this risk location to the nearest open water body?"
					rightText={WaterBodyDistance}
				/>

				{otherData?.FireRisk?.Images?.FireFightingEquipments?.length > 0 && (
					<InfoRow leftText="Photos of firefighting equipment" />
				)}

				<div className="d-flex">{FireFightingImagesMap}</div>
			</InfoSection>

			{/* SECTION: HISTORIC INFORMATION */}
			<InfoSection
				sectionTitle="Historic information"
				showDivider
				onEditClick={editHistorticInfo}
				edit={!rfqViewOnlyMode}
			>
				<InfoRow
					leftText="Is this property insured with another company for Fire & Allied Perils?"
					rightText={historicInfoInsuredElsewhereText}
					subText={displayHistoricInfoOtherCompanySubText}
				/>
				<InfoRow
					leftText="Was cover denied in the last 3 years?"
					rightText={historicInfoCoverDeniedText}
					subText={displayHistoricInfoCoverDenialSubtext}
				/>

				{isSFSP ? (
					<InfoRow
						leftText="Any warranties added by the previous insurer?"
						rightText={getRadioBtnValue(otherData?.HistoricInfo?.AddedWarranties?.Present)}
						subText={otherData?.HistoricInfo?.AddedWarranties?.Description}
					/>
				) : null}
			</InfoSection>

			{/* SECTION: CLAIMS HISTORY */}
			<InfoSection
				sectionTitle={
					historicClaimsPresent ? 'Claims History' : 'Claims History (No History Added)'
				}
				showDivider={historicInfoClaims?.length > 0}
				onEditClick={editHistorticInfo}
				edit={!rfqViewOnlyMode}
			>
				{/* TODO: [after claims page redesign] add item to display selected choice of whether there was any claims over the past 5 years */}
				{displayClaimsHistoryTables}
			</InfoSection>

			{/* SECTION: STOCK VALUATION METHOD */}
			{specificData?.Stock?.Present && (
				<InfoSection sectionTitle="Stock valuation method" showDivider edit={false}>
					<Row>
						<Col>
							<Text style={boldText}>
								{getStockDisclosureMethodString(data?.StockDisclosureMethod)}
							</Text>

							<InfoRow subText={getReportingStatement(data?.DeclarationQ?.ReportingMethod)} />

							<InfoRow subText="Market value disclosed" />
						</Col>
					</Row>
					{(data?.StockDisclosureMethod === 'FLOATER' ||
						data?.StockDisclosureMethod === 'FLOATER_DECLARATION') && (
						<Row>
							<Col>
								<InfoRow leftText="Total value of stock" />
								<Text style={boldText}>{floaterDeclTotalStockAmountString}</Text>
							</Col>
							<Col>
								<InfoRow leftText="Maximum value of stock at any one location" />
								<Text style={boldText}>{floaterDeclMaxStockAmountString}</Text>
							</Col>
						</Row>
					)}
				</InfoSection>
			)}

			{/* SECTION: STOCK TYPE(S) */}
			<InfoSection
				sectionTitle={
					specificData?.Stock?.Present
						? 'Stock details & type(s)'
						: 'Stock details & type(s) (No stock added)'
				}
				showDivider
				onEditClick={editStockTypes}
				edit={!rfqViewOnlyMode}
			>
				{StockDetailsMap}

				<div style={{ margin: '0px 8px' }}>
					{specificData?.Stock?.Present ? (
						<>
							{/* Stock Hypothecation */}
							<Col>
								<InfoRow
									leftText="Stock Hypothecation"
									rightText={
										specificData?.Stock?.Hypothecation?.length > 0
											? `${specificData?.Stock?.Hypothecation?.[0]?.Name || ''}, ${
													specificData?.Stock?.Hypothecation?.[0]?.Type || ''
											  }`
											: '-'
									}
								/>
							</Col>
							{displayStockHypothecation} {/* displays remaining hypothecations */}
							{/* 3rd party storage */}
							{/* TODO after other PR is merged (prefilling): Case of not filled needs to be handled for rightText - Also if No is present wipe out details */}
							<InfoRow leftText="3rd party storage" rightText={stockThirdPartyStorageText} />
							{displayThirdPartyStockStorage}
						</>
					) : null}
				</div>
			</InfoSection>

			{/* SECTION: GOODS IN TRUST */}
			{isGoodsInTrustPresent && (
				<InfoSection
					showDivider
					sectionTitle={goodsInTrustTitle}
					onEditClick={editGoodsinTrust}
					edit={!rfqViewOnlyMode}
				>
					{GoodsInTrustProperty}

					{GoodsInTrustStock}
				</InfoSection>
			)}

			{showEditClientDetailsModal && (
				<EditClientDetailsModal
					show={true}
					handleClose={() => setShowEditClientDetailsModal(false)}
					locationData={{
						...currentLocation,
						Occupancy: specificData?.PrimaryOccupancy?.SubCategory,
					}}
					setSQSUpdateFlow={setSQSUpdateFlow}
				/>
			)}
		</div>
	)
}

export default ReviewComponent
