import {
	Text,
	Input,
	InputGroup,
	Icon,
	Button,
	theme,
	MainContainer,
	PhoneNoInput,
	Alert,
	Checkbox,
} from 'verak-ui'
import { Col, Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import { Context } from '../../context/Provider'
import { useLoadingOverlay } from '../../components/LoadingOverlay'
import { isFireAllRiskPolicy } from '../../utilities/isFireAllRiskPolicy'
import AccountSelectionModal from './AccountSelectionModal'
import { useFetch } from '../../api/apihook'

const parsePhoneNumber = phone => {
	if (phone?.startsWith('+91') && phone?.length === 13) {
		return phone?.replace('+91', '')
	} else if (phone?.startsWith('0') && phone?.length === 11) {
		return phone?.replace('0', '')
	} else if (phone?.startsWith('+910') && phone?.length === 14) {
		return phone?.replace('+910', '')
	} else if (phone?.startsWith('91') && phone?.length === 12) {
		return phone?.replace('91', '')
	} else {
		return phone
	}
}

const EditField = ({ onEditClick, showEditButton = false, children }) => {
	if (showEditButton) {
		return (
			<div className="w-100 position-relative">
				{children}
				<Button
					className="position-absolute bottom-0 end-0 me-2 mb-1 p-0"
					onClick={onEditClick}
					icon="edit"
					bgColor="rgba(0,0,0,0.0)"
					style={{ zIndex: '1000' }}
				/>
			</div>
		)
	}
	return children
}

const ClientDetails = () => {
	let history = useHistory()
	const [state, dispatch] = useContext(Context)

	const { setIsLoading, setLoadingText } = useLoadingOverlay()

	const setLoadingState = ({ status, message }) => {
		setIsLoading(status)
		setLoadingText(message)
	}

	const policyName = state?.quote?.PolicyName || ''

	const { ClientDetails } = state?.quote // prefilling states

	const [ContactName, setContactName] = useState(ClientDetails?.ProposerName || '')
	const [AccountName, setAccountName] = useState(() => ClientDetails?.CompanyName || '')
	const [Phone, setPhone] = useState(parsePhoneNumber(ClientDetails?.Phone) || '')
	const [errorState, setErrorState] = useState('')
	const [newlyCreatedAccountNotSelected, setNewlyCreatedAccountNotSelected] = useState(false)
	const [isAccountSelected, setIsAccountSelected] = useState(
		Boolean(ClientDetails?.CompanyName || '')
	)
	const [accountId, setAccountId] = useState('')
	const [contactId, setContactId] = useState('')
	const [accounts, setAccounts] = useState([])

	const [selectedAccount, setSelectedAccount] = useState(null)
	const [existingClient, setExistingClient] = useState(false)
	const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(false)
	const [useProposerName, setUseProposerName] = useState(false)

	const [screen, setScreen] = useState('proposer-number-screen')

	const fetch = useFetch()
	const { state: locationState } = useLocation()

	let validPhoneNumber = true
	// At length 0 - it is valid; it checks for validity only if a number is entered
	// This is done so that the default state is not also an error state
	// and to prevent the continue button the length of the number being 10 is also added as a check
	if (Phone.length > 0) {
		validPhoneNumber = !isNaN(Phone) && Phone.length === 10
	}

	let proceedDisabled = false
	if (screen === 'proposer-number-screen') {
		proceedDisabled = !validPhoneNumber || !Phone
	} else {
		proceedDisabled = !ContactName || !AccountName
	}

	// disable all the inputs if details are already present (coming up from the CRM)
	// this is in case of an assigned CRM lead
	let clientDetailsFromCrm = Boolean(
		ClientDetails?.ProposerName && ClientDetails?.Phone && ClientDetails?.CompanyName
	)

	let skipClientCreation = Boolean(
		state.app.clientCreated && ClientDetails?.ProposerName && ClientDetails?.Phone
	)

	const createAccount = () => {
		const requestObject = {
			ProposerName: ContactName,
			EntityName: AccountName,
			Phone: `+91${Phone}`,
			CrmAccountId: locationState?.accountId,
			LeadSource: locationState?.leadSource,
			Type: 'CORPORATE',
		}

		setLoadingState({ status: true, message: 'Creating new client' })

		const response = fetch.createAccount(requestObject)
		return response
	}

	const proceedToCoverage = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_CLIENT_DETAILS',
				data: {
					ProposerName: ContactName,
					CompanyName: AccountName,
					Phone: `+91${Phone}`,
					Type: 'CORPORATE',
				},
			})
			resolve()
		})
		await fetch.trackRfqEvent(`Selected ${state?.quote?.PolicyName} - New Quote`)
		await fetch.saveQuote()

		history.push(`/journey/coverage-details`)
	}

	const handleStartRfq = async (accountId, contactId) => {
		setLoadingState({ status: true, message: 'Starting RFQ' })

		let toSend = {
			accountId: accountId,
			contactId: contactId,
			policyName: policyName,
			policyTag: isFireAllRiskPolicy(state?.quote?.PolicyAddons),
		}

		if (state?.rfq?.draftRFQ?._id) {
			toSend.draftRfqId = state?.rfq?.draftRFQ?._id
		}
		const response = await fetch.createRfq(toSend)

		if (response.error) {
			setErrorState(response.error)
		} else {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_QUOTE_ID',
					data: {
						id: response.id,
					},
				})
				resolve()
			})
			await proceedToCoverage()
		}
	}

	const fetchAccounts = async existingContactId => {
		let associatedAccounts = await fetch.getAssociatedAccounts(existingContactId)
		setAccounts(associatedAccounts)
		return associatedAccounts
	}

	const proceed = async () => {
		if (screen === 'proposer-number-screen') {
			const { contactId: existingContactId, name } = await fetch.checkContactExists(Phone)
			if (existingContactId && name) {
				setExistingClient(true)
				setContactId(existingContactId)
				setContactName(name)

				dispatch({
					type: 'SET_CLIENT_EXISTS',
					data: { clientExists: true },
				})

				// fetch only if no accounts in list OR if the user comes back and changes the contact number
				if (!accounts?.length || existingContactId !== contactId) {
					let accountList = await fetchAccounts(existingContactId)
					if (clientDetailsFromCrm) {
						// check if company name already presnet in accountList
						const index = accountList.findIndex(item => {
							return item.Name == AccountName
						})

						if (index > -1) {
							const accountItem = accountList[index]
							if (accountItem.id) {
								setAccountId(accountItem.id)
							}
							setSelectedAccount({
								Name: AccountName,
								id: accountItem.id,
							})
						} else {
							// add it and select it in accountselection popup
							setSelectedAccount({
								Name: AccountName,
								id: 'clientAddition',
							})
						}
					}
				}
				// if existing client AND client details for CRM assigned lead, go through normal account selection flow
				if (clientDetailsFromCrm) {
					setIsAccountSelected(false)
				} else {
					setShowAccountSelectionModal(true)
				}
			} else if (!clientDetailsFromCrm) {
				setExistingClient(false)
				setContactId('')
				setAccounts([])
				if (!ClientDetails?.ProposerName) {
					setContactName('')
				}

				dispatch({
					type: 'SET_CLIENT_EXISTS',
					data: { clientExists: false },
				})
			}
			setScreen('other-details')
		} else {
			// save data on the backend
			// if inputs are disabled - Client is already created. Proceed to next
			if (skipClientCreation) {
				history.push(`/journey/coverage-details`)
			} else if (existingClient && accountId && contactId) {
				dispatch({
					type: 'CLIENT_CREATED',
				})
				handleStartRfq(accountId, contactId)
			} else {
				const clientResult = await createAccount()
				dispatch({
					type: 'CLIENT_CREATED',
				})
				setAccountId(clientResult.account)
				setContactId(clientResult.contact)
				handleStartRfq(clientResult.account, clientResult.contact)
			}
		}
	}

	const back = () => {
		if (screen === 'other-details') {
			setScreen('proposer-number-screen')
		} else {
			history.goBack()
		}
	}

	const handlePhoneChange = e => {
		const phoneNumber = e.target.value
		if ((Number(phoneNumber) || phoneNumber === '') && phoneNumber.length <= 10) {
			setPhone(phoneNumber)
		}
	}

	const selectAccount = (account, isNewlyCreatedNotSelected) => {
		const { Name, id } = account
		setSelectedAccount(account)
		setIsAccountSelected(true)
		setAccountName(Name)
		setNewlyCreatedAccountNotSelected(isNewlyCreatedNotSelected)
		// if the id of the selected account is not one of these, it is the account id of the existing client
		if (existingClient && !['proposerNameSelection', 'clientAddition'].includes(id)) {
			setAccountId(id)
		} else {
			setAccountId('')
		}
		setShowAccountSelectionModal(false)
	}

	const onAccountEdit = () => {
		setShowAccountSelectionModal(true)
	}

	const closeAccountSelectionModal = () => {
		setShowAccountSelectionModal(false)
	}

	let content = (
		<div className="d-flex align-items-center" style={{ height: '400px' }}>
			<Col xs={7.5}>
				<InputGroup
					inputId="mobile"
					label="Mobile Number of the proposer"
					isRequired={true}
					className="mb-1"
				/>
				<PhoneNoInput
					disabled={clientDetailsFromCrm}
					placeholder="Enter"
					value={Phone}
					error={!validPhoneNumber}
					onChange={handlePhoneChange}
					showAlert={!validPhoneNumber}
					alertText="Incorrect phone number"
					className="mb-2"
					style={{ width: '100%' }}
				/>
				{/* TODO: verify this 'OTP' copy */}
				<Alert iconName="warning" className="mt-3">
					<Text fontSize="14px" fontWeight={700} color={theme.colors.secondary}>
						Only enter the number of the proposer as it will be verified by OTP
					</Text>
				</Alert>
			</Col>
		</div>
	)

	if (screen === 'other-details') {
		let policyNameField
		if (existingClient) {
			policyNameField = (
				<InputGroup
					inputId="name"
					label={'Name on policy'}
					isRequired={true}
					className="my-3"
					style={{ width: '360px' }}
				>
					{/* also hide the edit button in case of CRM lead */}
					{/* EXCEPT: in case of existing client which is a CRM lead too */}
					<EditField showEditButton={true} onEditClick={onAccountEdit}>
						<Input
							type="text"
							placeholder="Enter"
							value={AccountName}
							onChange={e => setAccountName(e.target.value)}
							disabled
						/>
					</EditField>
				</InputGroup>
			)
		} else {
			const handleProposerNameSelection = value => {
				setUseProposerName(value)
				if (value) {
					setAccountName(ContactName)
				} else {
					setAccountName('')
				}
			}
			policyNameField = (
				<>
					<InputGroup inputId="name" label="Name on policy" isRequired={true} className="mt-4 mb-3">
						<Input
							type="text"
							placeholder="Enter"
							value={AccountName}
							disabled={useProposerName}
							onChange={e => setAccountName(e.target.value)}
						/>
					</InputGroup>
					{ContactName && (
						<Checkbox
							text={`Same as proposer name`}
							value={useProposerName}
							textStyle={{ color: '#6D6D6D', fontSize: '14px', paddingLeft: '2rem' }}
							onChange={handleProposerNameSelection}
						/>
					)}
				</>
			)
		}
		content = (
			<div className="d-flex align-items-center" style={{ minHeight: '300px' }}>
				<Col xs={7.5}>
					<InputGroup inputId="name" label="Proposer name" isRequired={true} className="mt-3">
						<Input
							type="text"
							placeholder="Enter"
							value={ContactName}
							onChange={e => setContactName(e.target.value)}
							disabled={
								clientDetailsFromCrm ||
								existingClient ||
								ClientDetails?.ProposerName === ContactName
							}
						/>
					</InputGroup>
					{policyNameField}

					{newlyCreatedAccountNotSelected && (
						<div className="d-flex align-items-center pt-2">
							<Icon name="warningTriangleSm" color={theme.colors.red} />
							<Text className="px-2" color={theme.colors.red} fontSize="14px" fontWeight="700">
								Newly added legal entity name has not been selected and therefore deleted from the
								system.
							</Text>
						</div>
					)}
				</Col>
				{showAccountSelectionModal && (
					<AccountSelectionModal
						show={true}
						handleClose={closeAccountSelectionModal}
						contactId={contactId}
						selectAccount={selectAccount}
						existingClient={existingClient}
						isAccountSelected={isAccountSelected}
						proposerName={ContactName}
						account={selectedAccount}
						associatedAccounts={accounts}
					/>
				)}
			</div>
		)
	}

	let alertMessage = 'This information cannot be edited. Please use the CRM in order to do so.'
	return (
		<Container>
			<MainContainer
				header="Client details"
				divider={true}
				rightLabel={screen === 'proposer-number-screen' ? 'Start' : 'Continue'}
				rightOnClick={proceed}
				rightDisabled={proceedDisabled}
				leftOnClick={back}
				top={3}
			>
				<Text type={'primary'}>Enter basic client details</Text>
				<Text className="my-2" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
					Note: * marked questions are mandatory
				</Text>

				{clientDetailsFromCrm && (
					<div className="d-flex align-items-center pt-2">
						<Icon name="infoIcon" color={theme.colors.red} />
						<Text className="px-2" color={theme.colors.red} fontSize="14px" fontWeight="700">
							{alertMessage}
						</Text>
					</div>
				)}

				{content}
			</MainContainer>
		</Container>
	)
}

export default ClientDetails
