import { calculateNumStock } from './calculateNumStock'

export const determineEdgeCase2 = (numStock, RiskLocations) => {
    let actualNumStock = calculateNumStock(RiskLocations)

    if (numStock === 1) {
        if (actualNumStock === 0) {
            return 'nto0'
        }
    } else if (numStock > 1) {
        if (actualNumStock === 0) {
            return 'nto0'
        } else if (actualNumStock === 1) {
            return 'nto1'
        }
    }
    return ''
}