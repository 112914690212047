import { Text, Select, DatePicker, theme, MainContainer, Divider } from 'verak-ui'
import { Row, Col, Container } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'
import { coverageValues } from '../../utilities/coverageDateUtils'
import { getCorrectDateFormat } from '../../utilities/getCorrectDateFormat'

const CoverageDetails = () => {
	const { trackRfqEvent } = useFetch()
	const prefillDuration = Duration => {
		if (Duration) {
			return `${Duration},${Duration?.substring(1)}` // returns M12,12
		} else {
			return ''
		}
	}

	const stringToStartDate = dateString => {
		// dd/mm/yyyy to JS object (Mon Sep 25 2021 10:55am)
		if (dateString) {
			let dateParts = dateString.split('/')

			// link: https://stackoverflow.com/questions/33299687/how-to-convert-dd-mm-yyyy-string-into-javascript-date-object
			// month is 0-based, that's why we need dataParts[1] - 1
			let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) // not sure what '+' means
			return date
		} else {
			return new Date()
		}
	}

	const stringToEndDate = (quoteStartDate, quoteDuration) => {
		// dd/mm/yyyy to JS object (Mon Sep 25 2021 10:55am)
		if (quoteStartDate && quoteDuration) {
			let months = 0
			if (quoteDuration.substring(0, 1) === 'M') {
				// checking if it's "D" or "M"
				months = quoteDuration.substring(1)
			}

			let dateParts = quoteStartDate.split('/')
			let date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

			if (months >= 1) {
				date.setDate(date.getDate() - 1)
				date.setMonth(date.getMonth() + parseInt(months))
			} else {
				// for 0.5 value - D15 - 15 days
				date.setDate(date.getDate() + 15)
			}

			return date
		} else {
			return null
		}
	}

	let history = useHistory()
	const fetch = useFetch()
	const historyLoc = useLocation()

	const { edit, returnPath } = historyLoc?.state || {}

	const [state, dispatch] = useContext(Context)
	let quoteDuration = state.quote?.Cover?.Duration
	let quoteStartDate = state.quote?.Cover?.StartDate

	const [DurationCode, setDurationCode] = useState(quoteDuration || '') // M12
	const [StartDate, setStartDate] = useState(stringToStartDate(quoteStartDate)) // JS date format or new Date()
	const [EndDate, setEndDate] = useState(stringToEndDate(quoteStartDate, quoteDuration)) // JS date format or null
	const [durationValue, setDurationValue] = useState(prefillDuration(quoteDuration)) // M12,12

	// todo improve this, this will be recalculated on every render
	const startDateLimit = new Date()
	startDateLimit.setFullYear(startDateLimit.getFullYear() + 1)

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_COVERAGE_DETAILS',
				data: {
					Duration: DurationCode, // D15, M1
					StartDate: getCorrectDateFormat(StartDate),
					EndDate: getCorrectDateFormat(EndDate), // dd/mm/yyyy -> Mon Sep 25 2021 10:25 am
				},
			})
			resolve()
		})

		let path = `/journey/quote-type-selection`
		if (edit && returnPath) {
			path = returnPath
		}
		await fetch.saveQuote()
		history.push(path)
	}

	const back = () => {
		history.goBack()
	}

	const getEndDateByDuration = (months, startDate) => {
		var date = new Date(startDate.valueOf())

		if (months >= 1) {
			date.setDate(startDate.getDate() - 1)
			date.setMonth(date.getMonth() + parseInt(months)) // sets the months
		} else {
			// for 0.5 value - D15 - 15 days
			date.setDate(date.getDate() + 15)
		}
		return date
	}

	const onDurationSelect = async selected => {
		const [code, months] = selected.split(',')
		const endDate = getEndDateByDuration(months, StartDate)
		setEndDate(endDate)
		setDurationValue(selected)
		setDurationCode(code)
		await trackRfqEvent("Selected 'Duration of Cover'")
	}

	const onStartDateSelect = value => {
		setStartDate(value)

		if (durationValue) {
			let months = durationValue.split(',')[1]
			let endDate = getEndDateByDuration(months, value)
			setEndDate(endDate)
		}
	}

	const validInput = DurationCode !== '' && !!StartDate

	const coverageValueMap = coverageValues.map(({ name, value, months }) => {
		return (
			<option value={`${value},${months}`} key={name}>
				{name}
			</option>
		)
	})

	return (
		<Container>
			<MainContainer
				header="Coverage details"
				divider={true}
				rightLabel="Save & Continue"
				rightOnClick={proceed}
				rightDisabled={!validInput}
				leftOnClick={back}
				top={4}
			>
				<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
					Note: * marked questions are mandatory
				</Text>
				<Row>
					<Col className="mb-3">
						<Text fontWeight={'700'} type={'body1'}>
							Select the duration of cover <span style={{ color: theme.colors.red }}>*</span>
						</Text>
					</Col>
				</Row>
				<Row>
					<Col className="">
						<Select
							height="3rem"
							placeholder="Select duration"
							value={durationValue}
							onChange={onDurationSelect}
						>
							{coverageValueMap}
						</Select>
					</Col>
				</Row>
				<Divider />
				<Row className="mb-3">
					<Col>
						<Text fontWeight={'700'} type={'body1'} className="mb-2">
							Select start date <span style={{ color: theme.colors.red }}>*</span>
						</Text>
						<DatePicker
							height="3rem"
							className="mb-1"
							showMonthDropdown
							selected={StartDate}
							maxDate={startDateLimit}
							minDate={new Date()}
							onChange={onStartDateSelect}
						/>
						<Text style={timeStyle} color={theme.colors.gray3}>
							00:00 Hrs (12:00 AM)
						</Text>
					</Col>
					<Col>
						<Text fontWeight={'700'} type={'body1'} className="mb-2">
							End date
						</Text>
						<DatePicker selected={EndDate} height="3rem" className="mb-1" disabled />
						<Text style={timeStyle} color={theme.colors.gray3}>
							23:59 Hrs (11:59 PM)
						</Text>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col>
						<Text color={theme.colors.secondary2}>
							Actual start day may change, depending on insurer's response time.
						</Text>
					</Col>
				</Row>
			</MainContainer>
		</Container>
	)
}

export default CoverageDetails

const timeStyle = {
	fontWeight: 500,
	fontSize: '14px',
	lineHeight: '24px',
}
