import { useState } from 'react'
import styled from 'styled-components'
import { Accordion, Checkbox, Radio, Select, Space, Text, theme, Button, Alert } from 'verak-ui'
import { claimReasons, monthDropdownData, yearDropdownData } from './options'
import { convertToNumber } from '../../../../utilities/convertToNumber'
import { claimReasonInfoStrings } from './claimReasonInfoStrings'

import {
	CustomInput,
	GridContainer,
	RadioGroup,
	SearchableSelectLabel,
	SelectLabel,
	SelectWrapper,
	SharedInputStyles,
	SharedRadioStyles,
} from './SharedStyles'

const AccordionWrapper = styled.div`
	.accordion-item,
	.accordion-header > button {
		background-color: ${theme.colors.secondary3};
		border: none;
	}
`

const ClaimsHistorySubHeader = styled.div`
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	color: ${theme.colors.primary};
`
const ClaimsHistorySubHeaderNote = styled(SearchableSelectLabel)`
	font-weight: 500;
`

const ClaimsHistoryAccordionHeader = ({ claimId, showRemoveBtn, onRemoveClaimsItem }) => {
	let removeBtn = null
	if (showRemoveBtn) {
		const handleRemoveClick = e => {
			e.stopPropagation() // stop from toggling the dropdown state
			onRemoveClaimsItem(claimId)
		}
		removeBtn = (
			<Button
				icon="remove"
				label="Remove"
				iconStyles={{ marginRight: '0.5rem' }}
				onClick={handleRemoveClick}
				style={{ background: '#FCEBE3' }}
				textColor={{ color: `${theme.colors.primary}` }}
			/>
		)
	}

	return (
		<div className="d-flex justify-content-between">
			<Text
				type="h5"
				fontWeight={700}
				style={{ lineHeight: '30px' }}
				color={theme.colors.secondary}
			>
				{claimId}
			</Text>
			{removeBtn}
		</div>
	)
}

const ClaimsHistoryAccordionBody = ({ claimId, claimsData, updateHistoricInfoData }) => {
	// NOTE: Same names used w/o caps for local state vars
	const { Damaged, Year, Month, Amount, Status, Reasons, RiskMitigation } = claimsData

	let reasons = []
	const filteredReasons = Reasons?.filter(rItem => claimReasons.includes(rItem))
	reasons = filteredReasons || []

	const [riskMitigation, setRiskMitigation] = useState(RiskMitigation || '')

	const [otherReason, setOtherReason] = useState(() => {
		const otherReasonItem = Reasons?.find(rItem => !claimReasons.includes(rItem))
		return otherReasonItem || ''
	})
	// checkbox state for other reason input field
	const [otherChecked, setOtherChecked] = useState(() =>
		Boolean(Reasons?.find(el => !claimReasons.includes(el)) || '')
	)

	const [amount, setAmount] = useState(() => {
		if (Amount || Amount === 0) {
			return Amount
		}
		return ''
	})

	// state change handlers
	const triggerSave = (key, value) => {
		updateHistoricInfoData(claimId, key, value)
	}

	const handleSettingAmount = e => {
		const numValue = convertToNumber(e.target.value)
		setAmount(numValue)
	}

	const addReasons = (reasonName, reasonValue) => {
		let updatedReasons = []
		if (reasonValue) {
			updatedReasons = [...reasons, reasonName]
		} else {
			updatedReasons = reasons.filter(el => el !== reasonName)
		}
		if (otherChecked && otherReason) {
			updatedReasons.push(otherReason)
		}
		triggerSave('Reasons', updatedReasons)
	}

	const handleDamageTypeSelection = val => {
		triggerSave('Damaged', val)
	}

	const handleStatusChange = val => {
		triggerSave('Status', val)
	}

	const handleMonthSelect = val => {
		const numericalVal = parseInt(val)
		if (numericalVal) {
			triggerSave('Month', numericalVal)
		}
	}

	const handleYearSelect = val => {
		const numericalVal = parseInt(val)
		if (numericalVal) {
			triggerSave('Year', numericalVal)
		}
	}

	const handleOtherChecked = val => {
		let updatedReasons = []
		if (val) {
			updatedReasons = [...reasons, otherReason]
		} else if (val === false) {
			updatedReasons = [...reasons]
		}
		setOtherChecked(val)
		triggerSave('Reasons', updatedReasons)
	}

	const handleOtherFieldChange = () => {
		let updatedReasons = [...reasons]

		if (otherChecked) {
			updatedReasons = [...reasons, otherReason]
		}
		triggerSave('Reasons', updatedReasons)
	}

	const handleRiskMitigation = () => {
		triggerSave('RiskMitigation', riskMitigation)
	}

	const checkForReason = name => {
		const isPresent = reasons?.findIndex(el => el === name) !== -1
		return isPresent
	}

	const monthOptions = monthDropdownData.map(({ name, value }) => {
		return (
			<option value={value} key={name}>
				{name}
			</option>
		)
	})

	const yearOptions = yearDropdownData.map(({ name, value }) => {
		return (
			<option value={value} key={name}>
				{name}
			</option>
		)
	})

	return (
		<>
			<ClaimsHistorySubHeader>
				What was damaged/affected during the event?<span style={{ color: '#eb5757' }}>*</span>
			</ClaimsHistorySubHeader>
			<RadioGroup>
				<Radio
					labelStyle={SharedRadioStyles}
					value={Damaged === 'BUILDING_ONLY'}
					onChange={() => handleDamageTypeSelection('BUILDING_ONLY')}
					secondary
					text="Building only"
					id={`claims-damage-building-only-${claimId}`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={Damaged === 'STOCK_CONTENTS'}
					onChange={() => handleDamageTypeSelection('STOCK_CONTENTS')}
					secondary
					text="Stock & contents only"
					id={`claims-damage-stock-contents-${claimId}`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={Damaged === 'BUILDING_STOCK_CONTENTS'}
					onChange={() => handleDamageTypeSelection('BUILDING_STOCK_CONTENTS')}
					secondary
					text="Building, stock & contents"
					id={`claims-damage-building-stock-contents-${claimId}`}
				/>
			</RadioGroup>
			<Space y={0.5} />
			<GridContainer>
				<SelectWrapper>
					<SelectLabel>Enter the month during which the event occured</SelectLabel>
					<div className="bg-white">
						<Select
							height="3rem"
							placeholder="Month"
							style={SharedInputStyles}
							value={Month || ''}
							onChange={handleMonthSelect}
						>
							{monthOptions}
						</Select>
					</div>
				</SelectWrapper>

				<SelectWrapper>
					<SelectLabel>Enter the year during which the event occured</SelectLabel>
					<div className="bg-white">
						<Select
							height="3rem"
							placeholder="Year"
							style={SharedInputStyles}
							value={Year || ''}
							onChange={handleYearSelect}
						>
							{yearOptions}
						</Select>
					</div>
				</SelectWrapper>

				<CustomInput
					value={amount}
					onChange={e => handleSettingAmount(e)}
					onBlur={() => triggerSave('Amount', amount)}
					placeholder="Enter value here"
					label="Enter the claims amount"
					autoComma
					rupee
					compulsory
				/>
			</GridContainer>
			<Space y={2.5} />
			<ClaimsHistorySubHeader>
				What is the claims status?<span style={{ color: '#eb5757' }}>*</span>
			</ClaimsHistorySubHeader>
			<RadioGroup>
				<Radio
					labelStyle={SharedRadioStyles}
					value={Status === 'PENDING'}
					onChange={() => handleStatusChange('PENDING')}
					secondary
					text="Pending"
					id={`claims-status-pending-${claimId}`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={Status === 'SETTLED'}
					onChange={() => handleStatusChange('SETTLED')}
					secondary
					text="Settled"
					id={`claims-status-settled-${claimId}`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={Status === 'ARBITRATION'}
					onChange={() => handleStatusChange('ARBITRATION')}
					secondary
					text="In Arbitration"
					id={`claims-status-in-arbitration-${claimId}`}
				/>
			</RadioGroup>
			<Space y={1.5} />
			<ClaimsHistorySubHeader className="mb-2">
				What was the reason(s) for the claim?<span style={{ color: '#eb5757' }}>*</span>
			</ClaimsHistorySubHeader>
			<ClaimsHistorySubHeaderNote className="mb-4" optional>
				Select all that apply
			</ClaimsHistorySubHeaderNote>
			<GridContainer>
				<Checkbox
					text="STFI"
					className="mb-2"
					onChange={v => addReasons('STFI', v)}
					value={checkForReason('STFI')}
					info={claimReasonInfoStrings['stfi']}
				/>
				<Checkbox
					text="RSMD"
					className="mb-2"
					onChange={v => addReasons('RSMD', v)}
					value={checkForReason('RSMD')}
					info={claimReasonInfoStrings['rsmd']}
				/>
				<Checkbox
					text="FLEXA"
					className="mb-2"
					onChange={v => addReasons('FLEXA', v)}
					value={checkForReason('FLEXA')}
					info={claimReasonInfoStrings['flexa']}
				/>
				<Checkbox
					text="Earthquake"
					className="mb-2"
					onChange={v => addReasons('Earthquake', v)}
					value={checkForReason('Earthquake')}
					info={claimReasonInfoStrings['earthquake']}
				/>
				<Checkbox
					text="Terrorism"
					className="mb-2"
					onChange={v => addReasons('Terrorism', v)}
					value={checkForReason('Terrorism')}
					info={claimReasonInfoStrings['terrorism']}
				/>
				<Checkbox
					text="Other (Please specify)"
					value={otherChecked}
					onChange={v => handleOtherChecked(v)}
					className="mb-2"
				/>
				<Space mobile={false} />
				<CustomInput
					type="text"
					value={otherReason}
					disabled={!otherChecked}
					onChange={e => setOtherReason(e.target.value)}
					onBlur={handleOtherFieldChange}
					placeholder="Enter details here"
					label=""
				/>
			</GridContainer>
			<Space y={1.5} />
			<ClaimsHistorySubHeader className="mb-2">
				Please specify any post-loss risk mitigation measures that have been introduced:
			</ClaimsHistorySubHeader>
			<CustomInput
				type="text"
				value={riskMitigation}
				onChange={e => setRiskMitigation(e.target.value)}
				onBlur={handleRiskMitigation}
				placeholder="Enter details here"
				label=""
			/>
		</>
	)
}

export default function ClaimsHistory({
	HistoricInfoData,
	hasPastClaims,
	updateHistoricInfoData,
	onNewClaimsAddition,
	onClaimsRemoval,
	onAllClaimsRemoval,
	propertyType,
}) {
	const [recentClaim, setRecentClaim] = useState(() => {
		if (hasPastClaims !== 'NA') {
			return hasPastClaims === 'YES' ? true : false
		}
		return 'NA'
	})

	const handleRecentClaimTrue = () => {
		if (HistoricInfoData?.length === 0) {
			setRecentClaim(true)
			onNewClaimsAddition()
		} else {
			setRecentClaim(true)
		}
	}

	const handleRecentClaimFalse = () => {
		setRecentClaim(false)
		onAllClaimsRemoval()
	}

	let accordionItems = []
	if (hasPastClaims !== 'NA') {
		accordionItems = HistoricInfoData.map(claimItem => {
			const claimId = claimItem.claimId || `Claim #1`
			const showRemoveBtn = HistoricInfoData?.length > 1
			return {
				header: (
					<ClaimsHistoryAccordionHeader
						showRemoveBtn={showRemoveBtn}
						claimId={claimId}
						onRemoveClaimsItem={onClaimsRemoval}
					/>
				),
				id: claimItem.claimId,
				content: (
					<ClaimsHistoryAccordionBody
						claimId={claimId}
						claimsData={claimItem}
						updateHistoricInfoData={updateHistoricInfoData}
					/>
				),
			}
		})
	}

	let displayClaimsSection = null
	if (recentClaim === true) {
		displayClaimsSection = (
			<>
				<Alert
					bgColor={theme.colors.secondaryLightRed}
					iconName="infoIconOctagon"
					infoIconColor={theme.colors.red}
				>
					<Text color={theme.colors.red}>
						<strong>Instructions for filling in claims history:</strong>
						<br />
						IF a loss has occured & the claim is still pending settlement, please enter the claims
						amount as 0. Please specify what part of the risk location (building, contents or stock)
						was damaged in the claim.
					</Text>
				</Alert>
				<Text className="mt-3" type="body1" fontWeight={500} style={{ lineHeight: '22.68px' }}>
					Please specify the details below:
				</Text>
				<AccordionWrapper>
					<Accordion
						key={accordionItems.length}
						accordionList={accordionItems}
						defaultActiveKey="claims-history"
					/>
				</AccordionWrapper>
				<Button
					className="my-3"
					bgColor={theme.colors.white}
					icon="add"
					iconStyles={{
						marginRight: '0.5rem',
					}}
					label="Add another claims event"
					onClick={onNewClaimsAddition}
					style={{
						padding: '0 0.5rem',
					}}
					textColor={theme.colors.primary}
				/>
			</>
		)
	}

	return (
		<>
			<Text className="mt-3" type="body1" fontWeight={700} style={{ lineHeight: '22.68px' }}>
				Has there been any claim in the last 5 years at this risk location (including stock and at
				the {propertyType})?
			</Text>
			<RadioGroup className="my-2">
				<Radio
					labelStyle={SharedRadioStyles}
					value={recentClaim === true}
					onChange={handleRecentClaimTrue}
					secondary
					text="Yes"
					name="recent-claim"
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={recentClaim === false}
					onChange={handleRecentClaimFalse}
					secondary
					text="No"
					name="recent-claim"
				/>
			</RadioGroup>
			{displayClaimsSection}
		</>
	)
}
