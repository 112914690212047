import { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Context } from '../../context/Provider'
import { MainContainer } from 'verak-ui'

import PropertyTypeSelector from './property/PropertyTypeSelector'
import { useFetch } from '../../api/apihook'

function initData(state) {
	const { Type, SubType, PrimaryOccupancy, levels, Level } = state

	if (SubType === 'Whole' && Level) {
		return {
			propertyType: SubType,
			levels: [{ Level, PrimaryOccupancy }],
		}
	} else if ((SubType === 'Single' || SubType === 'Multiple') && levels?.length) {
		return {
			propertyType: SubType,
			levels: levels,
		}
	}
	return null
}

export default function PropertyLevelOccupancy() {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)

	const [isInputComplete, setInputCompleteStatus] = useState(false)
	const [data, setData] = useState(initData(state))

	let history = useHistory()

	const goBack = () => {
		history.replace('/property-check')
	}

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_PROPERTY_USAGE_AND_SUBTYPE',
				data: {
					SubType: data.propertyType,
					levels: data.levels,
				},
			})

			resolve()
		})

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push('/property-risk/location-details')
	}

	const saveData = val => {
		setData(val)
	}

	return (
		<Container>
			<MainContainer
				header="Risk location occupancy"
				divider={false}
				leftOnClick={goBack}
				rightOnClick={proceed}
				rightDisabled={!isInputComplete}
			>
				<PropertyTypeSelector setInputCompleteStatus={setInputCompleteStatus} saveData={saveData} />
			</MainContainer>
		</Container>
	)
}
