import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import CompareEcommerce from '../../../../../assets/training/CompareEcommerce.svg'
import Instant3Quotes from '../../../../../assets/training/Instant3Quotes.svg'
import VyapariClients from '../../../../../assets/training/VyapariClients.svg'
import IRDAICertificate from '../../../../../assets/training/IRDAICertificate.svg'
import Just1Quote from '../../../../../assets/training/Just1Quote.svg'
import PhoneRecorded from '../../../../../assets/training/PhoneRecorded.svg'
import RazorpayTrusted from '../../../../../assets/training/RazorpayTrusted.svg'
import RecordedLine from '../../../../../assets/training/RecordedLine.svg'
import RemoveBurglary from '../../../../../assets/training/RemoveBurglary.svg'
import Settled70Claims from '../../../../../assets/training/Settled70Claims.svg'
import WhatsappGreenTick from '../../../../../assets/training/WhatsappGreenTick.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function Training_CantTrustOnline() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				EXPLAIN WHY TO TRUST OUR PROCESS
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={RecordedLine}>
						<InfoText>
							<b>Recorded Line</b> <br />
							Conversation is submitted to IRDAI (Govt) after purchase
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={WhatsappGreenTick}>
						<InfoText>
							<b>WhatsApp Green Tick</b> <br /> Shows we are verified & reputed business, so we can
							be trusted
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={IRDAICertificate}>
						<InfoText>
							<b>IRDAI Certificate</b> <br />
							On website's footer section to prove our legal existence
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={RazorpayTrusted}>
						<InfoText>
							<b>Razorpay Trusted Business Badge</b> for payments to show safe, trusted and recorded
							payments
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={VyapariClients}>
						<InfoText>
							<b>5000+ customers this years</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={Settled70Claims}>
						<InfoText>
							<b>Settled {claimsCount} of their claims</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3 mb-4" spaced fontWeight="700">
				WHY OFFLINE PURCHASE IS BAD
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={Just1Quote}>
						<InfoText>
							<b>With only 1 Quote customer cannot compare different quotes</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={Instant3Quotes}>
						<InfoText>
							Verak gives <b>3 Quotes instantly</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={PhoneRecorded}>
						<InfoText>
							In Offline,<b> No conversation is recorded, so customer can be cheated</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={RemoveBurglary}>
						<InfoText>
							<b>They remove Burglary & Theft cover & call it discount,</b> this is bad for
							customers
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3 mb-4" spaced fontWeight="700">
				COMPARE WITH E-COMMERCE
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CompareEcommerce}>
						<InfoText>
							<b>You buy Clothes & Office Supplies online.</b> What is the fear with insurance
							policy?
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column"></div>
			</div>
		</div>
	)
}
