import { nanoid } from 'nanoid'

const propertyOptions = [
	{
		icon: 'plantAndMachinery',
		title: 'Plant & Machinery',
		inputName: 'PlantMachinery',
	},
	{
		icon: 'furniture',
		title: 'Furniture & Fixture',
		inputName: 'FurnitureFixture',
	},
	{
		icon: 'electronicInstallations',
		title: 'Electronic Installations',
		inputName: 'Electronics',
	},
	{
		icon: 'officeEquipment',
		title: 'Office Equipment (including IT systems)',
		inputName: 'OfficeEquipment',
	},
	{
		icon: 'valuables',
		title: 'Valuable contents',
		inputName: 'ValuableContents',
	},
	{
		icon: 'otherValuables',
		title: 'Other contents',
		inputName: 'Other',
	},
]

const singleItemOps = {
	icon: 'singleItem',
	title: 'Maximum value of a single content item?',
	inputName: 'MaxValSingleItem',
}

const singleItemDefaults = {
	Name: 'MaxValSingleItem',
	Amount: '',
}

const otherItem = {
	icon: 'otherItems',
	title: 'Other items (Please specify)',
	Name: 'Other',
	Amount: '',
}

const propertyItemsDefaults = propertyOptions.map(el => ({
	Name: el.inputName,
	Amount: '',
}))

export { propertyOptions, otherItem, propertyItemsDefaults, singleItemOps, singleItemDefaults }
