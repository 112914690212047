import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import TimelineView from '../../../../components/Timeline'
import { useHistory, useLocation } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../../../context/Provider'
import { useFetch } from '../../../../api/apihook'

export default function ChangesInRFQ() {
	const history = useHistory()

	const { state } = useLocation()

	const navText = `Overview, ${state?.uid} - ${state?.ClientName} - ${state?.PolicyType}`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	const navigateToViewChanges = () => {
		let rfqId = state?.uid
		history.push(`/rfq-status/rfq-changes-list/${rfqId}`, state)
	}

	const timelineItems = [
		{
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							RFQ completion
						</Text>
						<div className="d-flex mt-2">
							<Text className="mr-2" color={theme.colors.primary} fontSize="12px" fontWeight="400">
								Sent: 14 July 2021, 5:11pm
							</Text>
							<Text className="mx-1" color="#C4C4C4">
								|
							</Text>
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Underwriter review pending, you will be notified if any changes are required.
							</Text>
						</div>
					</div>

					<Button
						onClick={navigateToViewChanges}
						style={{ height: '56px' }}
						label="View changes"
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Client completion
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Awaiting RFQ completion
						</Text>
					</div>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Quotes generation
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Awaiting update
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Pending
						</Text>
					</div>
				</div>
			),
		},
	]

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			// style={{ height: '100%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					borderRadius: '8px',
					width: '95%',
				}}
				className="container h-100 bg-white p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.red}>
					Changes have been made by the underwriter, please verify the same
				</Text>

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)
}
