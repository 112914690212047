import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button, Input, Switch, Text, theme } from 'verak-ui'
import { filterArrayObjects } from '../../../../utilities/filterArrayObjects'

const GoodsInTrustCheckContainer = styled.div`
	border: 1px solid ${theme.colors.secondary2};
	border-radius: 8px;
`

const GoodsOwnershipContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	place-items: center start;
	@media (max-width: 768px) {
		grid-template-columns: 1fr 0.1fr;
	}
`

const GoodsInTrustCheck = ({ value, handleChange }) => {
	const [checked, setChecked] = useState(value)

	useEffect(() => {
		const triggerSave = () => {
			handleChange({
				Present: Boolean(checked),
			})
		}
		triggerSave()
	}, [checked]) // eslint-disable-line

	return (
		<GoodsInTrustCheckContainer className="d-flex justify-content-between p-3 my-3">
			<Text
				type="body1"
				fontWeight={700}
				color={theme.colors.gray7}
				style={{ lineHeight: '22.68px' }}
			>
				Are Goods held in trust present at this location?
			</Text>
			<Switch value={checked} onChange={() => setChecked(prev => !prev)} />
		</GoodsInTrustCheckContainer>
	)
}

const GoodsOwnerDataItem = ({
	value,
	handleChange,
	itemKey,
	removebtn = false,
	removeOwnerDataItem,
}) => {
	const renderRemoveBtn = removebtn ? (
		<Button
			bgColor={theme.colors.white}
			icon="remove"
			label=""
			onClick={removeOwnerDataItem}
			className="p-0 h-25 ms-2"
			textColor={theme.colors.primary}
		/>
	) : null

	return (
		<GoodsOwnershipContainer className="mb-3">
			<Input
				className="w-100"
				type="text"
				value={value}
				onChange={e => handleChange(itemKey, e.target.value)}
				placeholder="Name of owner"
			/>
			{renderRemoveBtn}
		</GoodsOwnershipContainer>
	)
}

// type = "property" OR "stock"
// since this section is used within both the sections
const GoodsOwnership = ({ onSave, OwnershipList }) => {
	const [ownerDetails, setOwnerDetails] = useState([
		...OwnershipList.map(el => ({ data: el, itemKey: nanoid() })),
		{ data: '', itemKey: nanoid() },
	])

	const addOwnerDataItem = () => {
		const itemKey = nanoid()
		setOwnerDetails(prev => [...prev, { data: '', itemKey }])
	}

	const removeOwnerDataItem = key => {
		const filteredOwnerDetails = ownerDetails.filter(el => el.itemKey !== key)
		setOwnerDetails(filteredOwnerDetails)
	}

	const setOwnerData = (key, val) => {
		const alteredItems = ownerDetails.map(el => {
			return el.itemKey === key ? { ...el, data: val } : el
		})
		setOwnerDetails(alteredItems)
	}

	useEffect(() => {
		const triggerSave = () => {
			let filteredOwnership = ownerDetails.filter(el => el.data)
			filteredOwnership = filterArrayObjects(JSON.parse(JSON.stringify(filteredOwnership)), [
				'itemKey',
			])
			onSave({
				Ownership: filteredOwnership.map(el => el.data),
			})
		}
		triggerSave()
	}, [ownerDetails]) // eslint-disable-line

	const renderOwnerDetails = ownerDetails.map(el => (
		<GoodsOwnerDataItem
			key={el.itemKey}
			removebtn={ownerDetails.length > 1}
			removeOwnerDataItem={() => removeOwnerDataItem(el.itemKey)}
			value={el.data}
			handleChange={setOwnerData}
			itemKey={el.itemKey}
		/>
	))

	return (
		<>
			<Text className="mb-3" type="body1" fontWeight={500} style={{ lineHeight: '22.68px' }}>
				Please provide details on the ownership of these goods
			</Text>
			{renderOwnerDetails}
			<Button
				bgColor={theme.colors.white}
				icon="add"
				iconStyles={{
					marginRight: '0.5rem',
				}}
				label="Add another item"
				onClick={addOwnerDataItem}
				className="p-2 mb-3"
				textColor={theme.colors.primary}
			/>
		</>
	)
}

export { GoodsInTrustCheck, GoodsOwnership }
