// Note: These functions work only if there is a SumInsured Array - either [] or with values

const checkForSumInsured = dataObj => {
	let currentStatus = 'Complete'

	if (dataObj?.levels) {
		for (const level in dataObj?.levels) {
			if (
				currentStatus !== 'Incomplete' &&
				dataObj?.levels[level]?.SumInsured?.length === 0 &&
				!dataObj?.levels[level]?.Stock?.Present
			) {
				currentStatus = 'Incomplete'
			}
		}
	} else {
		if (dataObj?.SumInsured?.length === 0 && !dataObj?.Stock?.Present) {
			currentStatus = 'Incomplete'
		}
	}

	return currentStatus
}

// individual location
export const getLocationStatus = location => {
	let isPresent = ''
	if (location.Type === 'PROPERTYBASEMENT') {
		isPresent = checkForSumInsured(location?.property)
		if (isPresent === 'Complete') {
			isPresent = checkForSumInsured(location?.basement)
		}
	} else {
		isPresent = checkForSumInsured(location)
	}

	return isPresent
}

// all locations together
export const areAllLocationsComplete = RiskLocations => {
	let areAllLocationsFilled = true

	for (const location in RiskLocations) {
		let singleLocCheck = 'Complete'

		if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
			singleLocCheck = checkForSumInsured(RiskLocations[location].property)
			if (singleLocCheck === 'Complete') {
				singleLocCheck = checkForSumInsured(RiskLocations[location].basement)
			}
		} else {
			singleLocCheck = checkForSumInsured(RiskLocations[location])
		}

		if (singleLocCheck === 'Incomplete') {
			areAllLocationsFilled = false
		}
	}

	return areAllLocationsFilled
}
