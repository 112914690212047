import { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { Row, Container } from 'react-bootstrap'
import { useFetch } from '../../api/apihook'

import { MainContainer, theme, Text, Modal, Button, Space } from 'verak-ui'

import {
	// styles below
	infoContainerStyle,
	footerStyle,
	convertAmountToWords,
} from '../RiskLocationReview/reviewScreenUtils'
import { Context } from '../../context/Provider'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
	getStockSumForAllRiskLocations,
	BreadCrumbComponent,
	mainContainerHeaderStyles,
	mainContainerHeaderTextStyle,
	mainContainerStyles,
	MainStickyContainer,
	breadcrumbStyles,
	goodsInTrustTotalSIForAllRiskLocations,
} from './finalReviewUtils'
import { useHistory } from 'react-router-dom'

import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import useScrollDirectionDetect from './useScrollDirectionDetect'

import EditNoticeModal from '../../components/EditNoticeModal'

import autoConvert from '../../assets/icons/autoConvert.svg'
import { isRfqEqual, editRfqAllowedStatuses } from '../../helpers/editRfqUtils'

const getStockValue = quote => {
	const disclosureMethod = quote?.StockDisclosureMethod

	// check for declared and declaration basis
	if (disclosureMethod === 'DECLARATION_BASIS' || disclosureMethod === 'DECLARED_VALUE') {
		return getStockSumForAllRiskLocations(quote?.RiskLocations)
	} else if (disclosureMethod === 'FLOATER_DECLARATION' || disclosureMethod === 'FLOATER_BASIS') {
		// show the total value disclosed at the outset
		return quote?.DeclarationQ?.MaxValueAllLoc
	}
}

export default function SumInsuredBreakdown({ onBack, onProceed, proceedBtnText }) {
	const { scrollUp } = useScrollDirectionDetect()
	const [state, dispatch] = useContext(Context)
	let fetch = useFetch()

	const [showEditNoticeModal, setShowEditNoticeModal] = useState(false)

	// auto conversion modal
	const [modalState, setModalState] = useState(false)
	let AutoConversion = state?.quote?.AutoConversion
	let isAutoConverted = AutoConversion?.length > 0

	const history = useHistory()

	const flowCompletionRoutes = state?.sqs?.flowCompletionRoutes || {}
	const { finalRoute, redirectRoute } = flowCompletionRoutes

	const valuationMethods = state?.quote?.ValuationMethodMap || {}
	const escalationList = state?.quote?.EscalationClause

	const isEscalationPresent = value => {
		if (escalationList?.includes(value)) {
			return 'Escalation clause added'
		} else {
			return ''
		}
	}
	const getValuationMethod = value => {
		// all valuation methods will be present for SFSP and none for other policy types
		let method = valuationMethods[value] || 'Reinstatement'
		return `${method} value opted`
	}

	const sumInsuredBreakdown = generateSumInsuredBreakdown(state.quote?.RiskLocations)
	const stockValue = calculateTotalStockVal(state?.quote)
	const totalGoodsInTrust = goodsInTrustTotalSIForAllRiskLocations(state.quote?.RiskLocations || [])

	let sumInsuredBreakdownMap = sumInsuredBreakdown.map(el => {
		return {
			title: el.Name,
			amount: el.Amount,
			primaryText: getValuationMethod(el.Name), // market value only for stock, else reinstatement value
			secondaryText: isEscalationPresent(el.Name), // Escalation clause added
		}
	})

	// adding stock row
	if (stockValue && stockValue !== undefined) {
		sumInsuredBreakdownMap.push({
			title: 'Stock',
			amount: stockValue,
			primaryText: 'Market value', // market value only for stock, else reinstatement value
			secondaryText: '', // Escalation clause added
		})
	}

	// adding goods in trust row
	if (totalGoodsInTrust) {
		sumInsuredBreakdownMap.push({
			title: 'Goods Held in Trust',
			amount: totalGoodsInTrust,
			primaryText: '', // market value only for stock, else reinstatement value
			secondaryText: '', // Escalation clause added
		})
	}

	const totalSumInsuredValue = convertAmountToWords(
		`₹ ${
			totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) +
			(stockValue || 0) +
			(totalGoodsInTrust || 0)
		}`
	)

	const headerText = `Please confirm the breakdown of Total Sum Insured: ${totalSumInsuredValue}/-`

	const getName = title => {
		switch (title) {
			case 'BuildingValue':
				return 'Building'
			case 'BasementValue':
				return 'Basement'
			case 'PlinthFoundation':
				return 'Plinth & Foundation'
			case 'BoundaryWalls':
				return 'Boundary Walls, etc.'
			case 'PlantMachinery':
				return 'Plant & Machinery'
			case 'FurnitureFixture':
				return 'Furniture & Fixture'
			case 'Electronics':
				return 'Electronic Installations'
			case 'OfficeEquipment':
				return 'Office Equipment (including IT systems)'
			case 'ValuableContents':
				return 'Valuable contents'
			case 'Other':
				return 'Other contents'
			case 'MaxValSingleItem':
				return 'Maximum value of a single content item'
			case 'Stock':
				return 'Stock'
			default:
				return title
		}
	}

	const displaySumInsuredBreakdown = sumInsuredBreakdownMap.map((item, key) => {
		const { title, amount, primaryText, secondaryText } = item

		return (
			<DataRow
				key={key}
				title={getName(title)}
				amount={convertAmountToWords(`₹ ${amount}`)}
				primaryText={primaryText}
				secondaryText={secondaryText}
				bottomborder={key !== sumInsuredBreakdownMap.length - 1} // do not show bottom border for last row
			/>
		)
	})

	let nextPage = () => {
		// only show edit notice modal if sum-insured-breakdown is the finalRoute
		if (state?.rfq?.rfqEditMode && finalRoute !== '/note-for-insurer') {
			setShowEditNoticeModal(true)
		} else {
			proceedNext()
		}
	}

	const handleModalClose = () => {
		setShowEditNoticeModal(false)
		proceedNext()
	}

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	let proceedNext = async () => {
		// history.push('/confirmation-email')
		// only for pilot flow
		// history.push('/rfq-email')
		let nextRoute = '/note-for-insurer'

		if (isAutoConverted) {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_AUTO_CONVERSION',
					data: {
						AutoConversion: [],
					},
				})
				resolve()
			})
			await fetch.saveQuote()
		}

		// the sqs reducer has '/sum-insured-breakdown' set as the finalRoute for the current flow,
		// navigate to the redirectRoute set in the sqs reducer
		if (finalRoute === window.location.pathname) {
			nextRoute = redirectRoute
			// check if the current flow is edit flow
			if (state?.rfq?.editRfqMode === true) {
				// call the update rfq API
				// sqs reducer will be cleared on the update success screen
				const validRFQStatus = editRfqAllowedStatuses.includes(rfqStatus)
				if (validRFQStatus && state?.rfq?.editRfqMode && !isRfqEqual(state?.quote, state?.oldRfq)) {
					await fetch.resubmitRfqOnUpdate(state?.quote?.quoteId, state?.oldRfq)
				}
				await new Promise(resolve => {
					dispatch({ type: 'CLEAR_OLD_RFQ' })
					dispatch({
						type: 'SET_EDIT_RFQ',
						data: { editRfqMode: false },
					})
					resolve()
				})
			}
		}
		history.push(nextRoute)
	}

	if (typeof onProceed === 'function') {
		nextPage = onProceed
	}

	let goback = () => {
		history.push('/final-review')
	}
	if (typeof onBack === 'function') {
		goback = onBack
	}

	let proceedBtnLabel = 'Proceed to quote generation'
	if (proceedBtnText) {
		proceedBtnLabel = proceedBtnText
	}

	// init styles
	let breadcrumbContainerStyles = {
		...breadcrumbStyles,
		margin: '0',
		transform: 'translateY(-500px)',
	}
	let mainContainerHeaderSectionStyles = {
		...mainContainerHeaderStyles,
		transform: 'translateY(-500px)',
	}
	if (scrollUp) {
		breadcrumbContainerStyles = breadcrumbStyles
		mainContainerHeaderSectionStyles = mainContainerHeaderStyles
	}

	const mainContainerFooterStyles = {
		...footerStyle,
		margin: '0 auto',
		width: 'min(900px, 100%)',
		borderRadius: '0 0 8px 8px',
		borderTop: '1px solid #02475e',
	}

	const sumInsContainerStyles = {
		width: 'min(900px, 100%)',
		background: '#fff',
		borderRadius: '8px 8px 0 0',
	}

	const handleProceed = () => {
		if (isAutoConverted) {
			setModalState(true)
		} else {
			nextPage()
		}
	}

	const getAutoConvertText = () => {
		if (isAutoConverted) {
			return AutoConversion.map(item => getName(item)).join(' and ')
		} else {
			return ''
		}
	}

	return (
		<MainStickyContainer>
			<EditNoticeModal show={showEditNoticeModal} onButtonClick={handleModalClose} />

			<BreadCrumbComponent
				location="Final Review"
				nextLabel={proceedBtnLabel}
				nextOnClick={handleProceed}
				containerStyles={breadcrumbContainerStyles}
			/>

			<MainContainer
				containerStyles={mainContainerStyles}
				headerBg={theme.colors.secondary4}
				headerStyle={mainContainerHeaderSectionStyles}
				headerTextStyle={mainContainerHeaderTextStyle}
				header={headerText}
				rightLabel={proceedBtnLabel}
				leftOnClick={goback}
				rightOnClick={handleProceed}
				infoContainerStyle={infoContainerStyle}
				footerStyle={mainContainerFooterStyles}
				footerContainerStyle={{ padding: '0 1rem' }}
				divider={false}
			>
				<Container className="px-4 py-4 m-auto mt-3" style={sumInsContainerStyles}>
					{displaySumInsuredBreakdown}
				</Container>

				{/* <Divider bottom="0" style={{ width: '100%' }} /> */}
				<Modal
					id="auto-conversion-notice"
					title={
						<Text type="primary" color={theme.colors.red}>
							Policy Converted
						</Text>
					}
					closeButton={false}
					footer={<></>}
					show={modalState}
					handleClose={() => setModalState(false)}
					style={{ textAlign: 'center' }}
				>
					<Space y={1} />
					<img src={autoConvert} alt="auto-convert" />
					<Space y={1.5} />
					<Text type="h5" fontWeight="700" color={theme.colors.primary}>
						<span style={{ color: `${theme.colors.secondary}` }}>Please note:</span>&nbsp; This Fire
						Only RFQ is automatically converted into a Fire + Burglary RFQ because it contains{' '}
						{getAutoConvertText()} to be covered. This is because NO insurer will give standalone
						Fire coverage for {getAutoConvertText()}.
					</Text>
					<Space y={1.5} />
					<Button size="stretch" primary label="Continue" style={buttonStyle} onClick={nextPage} />
				</Modal>
			</MainContainer>
		</MainStickyContainer>
	)
}

function DataRow({ title, primaryText, secondaryText, amount, bottomborder }) {
	return (
		<DataRowWrapper $bottomborder={bottomborder}>
			<div className="mt-2 d-flex pb-2">
				<TitleText>{title}</TitleText>

				<AmountText>{amount}/-</AmountText>
			</div>

			<div className="d-flex pb-2 align-items-center">
				<PrimaryText>{primaryText}</PrimaryText>
				{secondaryText && (
					<SecondaryText>
						<div className="dot"></div>
						{secondaryText}
					</SecondaryText>
				)}
			</div>
		</DataRowWrapper>
	)
}

const DataRowWrapper = styled(Row)`
	border-bottom: ${({ $bottomborder }) => ($bottomborder ? '1px solid rgba(0,0,0, .3);' : 'none')};
`

const TitleText = styled(Text)`
	color: ${theme.colors.gray2};
	font-size: 1.25rem;
	font-weight: 500;
	min-width: 530px;
`

const AmountText = styled(Text)`
	color: ${theme.colors.primary};
	font-size: 1.25rem;
	font-weight: bold;
`

const PrimaryText = styled(Text)`
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 0.875rem;
`

const SecondaryText = styled(Text)`
	background-color: ${theme.colors.secondary3};
	border-radius: 20px;
	padding: 0.25rem 1.5rem;
	margin-left: 1rem;
	display: flex;
	align-items: center;
	color: ${theme.colors.secondary};
	font-weight: 700;
	font-size: 0.875rem;

	.dot {
		margin-right: 0.5rem;
		width: 7px;
		height: 7px;
		background-color: ${theme.colors.secondary};
		border-radius: 50%;
	}
`

const buttonStyle = {
	height: '64px',
	fontSize: '18px',
}
