import { createContext, useState, useContext } from 'react'

const initialState = {
	isSuccessAPI: false,
	setSuccess: () => {},
	cachedData: null,
	setCachedData: () => {},
}

export const APIStateContext = createContext(initialState)

export const useAPIStateContext = () => {
	const context = useContext(APIStateContext)
	if (!context) {
		throw new Error('useAPIStateContext must be used within APIStateProvider')
	}
	return context
}

export const APIStateProvider = ({ children, ...props }) => {
	const storedCache = null
	const [isSuccessAPI, setSuccess] = useState(false)

	const [cachedData, setCachedData] = useState(() => (storedCache ? JSON.parse(storedCache) : null))

	const setCache = data => {
		setCachedData(prev => {
			const newCache = { ...prev, ...data }
			return newCache
		})
	}

	const value = {
		isSuccessAPI,
		setSuccess,
		cachedData,
		setCache,
	}

	return <APIStateContext.Provider value={value}>{children}</APIStateContext.Provider>
}
