import { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router'

export const ComponentContext = createContext()

export function ComponentProvider({ children, ...props }) {
	const initValue = { status: false, component: <></> }

	// routes that have the top extraContainer
	const allowedRoutes = ['/final-review', '/sum-insured-breakdown']

	const [extraContainer, setContainer] = useState(initValue)

	const [renderSidePanel, setRenderSidePanelValue] = useState(false)

	const setRenderSidePanel = value => {
		if (value !== renderSidePanel) {
			setRenderSidePanelValue(value)
		}
	}

	const { pathname } = useLocation()

	// to prevent infinite render
	const setExtraContainer = ({ status, component }) => {
		if (extraContainer.status === false) {
			setContainer({ status, component })
		}
	}

	useEffect(() => {
		// check if extracontainer is rendered in allowed route; if false then reset the container
		if (extraContainer?.status && !allowedRoutes.includes(pathname)) {
			setContainer(initValue)
		}
	}, [pathname, extraContainer]) // eslint-disable-line

	const values = {
		extraContainer,
		setExtraContainer,
		renderSidePanel,
		setRenderSidePanel,
	}

	return (
		<ComponentContext.Provider {...props} value={values}>
			{children}
		</ComponentContext.Provider>
	)
}
