import { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import DraftRFQForm from './DraftRFQForm'
import { Button, Text, theme } from 'verak-ui'
import styled from 'styled-components'
import { Col } from 'react-bootstrap'
import Pagination from './components/Pagination'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../FinalReview/finalReviewUtils'
import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import { initialRiskLocationData } from './defaultData'
import { useFetch } from '../../api/apihook'

const DraftRFQFormHandler = forwardRef(
	(
		{
			accountData,
			contactData,
			riskLocations,
			policyInfo,
			notes,
			draftId,
			setTotalSI,
			setAccountData,
			setContactData,
			miAddons,
			CICData,
			CISData,
			CITData,
			setMIAddons,
			setCICData,
			setCISData,
			setCITData,
			safetyToggle,
			cctvUnits,
			setCCTVUnits,
			setRiskLocations,
			setPolicyInfo,
			setSelectedState,
			pincodeDataFetched,
			setPincodeDataFetched,
			onPolicyKindChange,
			saveDraft,
			onAccountEdit,
			existingClient,
			sumInsuredContainerRef,
		},
		ref
	) => {
		const { getLocationsWithPIN, triggerWAAddressPing, getDraftRFQ } = useFetch()

		const [activeRiskLocation, setActiveRiskLocation] = useState(0)

		const updateAccountData = data => setAccountData(data)
		const updateContactData = data => setContactData(data)
		const updateMIAddons = data => setMIAddons(data)
		const updateCICData = data => setCICData(data)
		const updateCISData = data => setCISData(data)
		const updateCITData = data => setCITData(data)
		const updateCCTVUnits = data => setCCTVUnits(data)

		//https://stackoverflow.com/a/27875018/5149570
		useImperativeHandle(
			ref,
			() => ({
				getData: () => {
					let riskLocationsCopy = [...riskLocations]
					riskLocationsCopy.forEach(rl => {
						if (rl.Type === 'BASEMENT') {
							delete rl.Materials.Roof
						} else if (rl.Type === 'PROPERTY') {
							delete rl.Materials.Ceiling
						}
					})
					return { riskLocations: riskLocationsCopy, policyInfo }
				},
			}),
			[riskLocations, policyInfo]
		)

		useEffect(() => {
			const SIBreakdown = generateSumInsuredBreakdown(riskLocations)
			const totalSI = totalSumInsuredForAllRiskLocations(SIBreakdown) || 0
			const totalStock =
				calculateTotalStockVal({
					RiskLocations: riskLocations,
					StockDisclosureMethod: 'DECLARED_VALUE',
				}) || 0
			setTotalSI(totalSI + totalStock)
		}, [riskLocations, policyInfo?.PolicyKind]) // eslint-disable-line react-hooks/exhaustive-deps

		const updateRiskLocation = values => {
			let rList = [...riskLocations]
			rList[activeRiskLocation] = { ...values }
			setRiskLocations(rList)
		}

		const fetchCityAndState = async PIN => {
			if (PIN) {
				setPincodeDataFetched(false)
				const response = await getLocationsWithPIN(PIN)

				const results = response?.[0] || []
				const locations = results?.PostOffice || []

				if (locations?.length) {
					const { District: City, State } = locations?.[0] || {}
					setSelectedState({ City, State, PIN })
					setPincodeDataFetched(true)
					return { City, State }
				}
				return null
			}
		}

		const onAddRiskLocation = () => {
			if (riskLocations.length === 3) return // cannot add more than 3 locations
			const dummyValues = JSON.parse(JSON.stringify(initialRiskLocationData))
			dummyValues.SumInsured.forEach(item => {
				item.Amount = ''
			})

			dummyValues.Stock.Details = [
				{
					AmountClosed: '',
					Type: '',
					AmountOpen: 0,
					OpenPer: 0,
					ClosedPer: 0,
				},
			]

			const newRiskLocation = { ...dummyValues }

			let rList = [...riskLocations, newRiskLocation]
			setActiveRiskLocation(rList.length - 1)
			setRiskLocations(rList)
		}

		const onRemoveRiskLocation = index => {
			if (riskLocations.length === 1) return
			let rList = [...riskLocations]
			rList.splice(index, 1)
			setRiskLocations(rList)
			setActiveRiskLocation(index > 0 ? index - 1 : 0)
			//update activeRiskLocation here
		}

		const navigateToRiskLocation = toNavigate => {
			setActiveRiskLocation(toNavigate)
		}

		let stockAvailable = riskLocations.findIndex(rItem => {
			return rItem.Stock?.Details.some(item => {
				return Boolean(item?.Type && item?.AmountClosed)
			})
		})

		const onAddressRequest = async id => {
			const result = await triggerWAAddressPing(id, 'DraftRfq')
			return result
		}

		const onAddressFetch = async () => {
			const result = await getDraftRFQ(draftId)
			const updatedList = [...result.Data.RiskLocations]
			setRiskLocations(updatedList)
		}

		const locationMoreThan1 = riskLocations.length > 1

		return (
			<MainContainer xs={5} className="pe-0 pb-1">
				<Section>
					<FormHeader className="d-flex justify-content-between mb-1">
						<Text color={theme.colors.blue} className="ms-3 mt-2" fontSize="14px" fontWeight="700">
							Enter & verify customer's details:
						</Text>
						<div className="d-flex">
							<Button
								label="Location"
								icon="addBg"
								iconStyles={{ height: '22px', width: '22px', marginRight: '4px' }}
								size="sm"
								bgColor="white"
								onClick={onAddRiskLocation}
								textColor={'#5153A0'}
								iconColor={'#5153A0'}
								className="me-3"
							/>
							{locationMoreThan1 && (
								<Button
									label="Remove"
									icon="remove"
									bgColor="white"
									textColor={theme.colors.red}
									iconStyles={{
										marginRight: '4px',
										marginLeft: '-4px',
										height: '22px',
										width: '22px',
									}}
									size="sm"
									onClick={() => onRemoveRiskLocation(activeRiskLocation)}
								/>
							)}
						</div>
					</FormHeader>

					<DraftRFQForm
						riskLocation={riskLocations[activeRiskLocation]}
						activeRiskLocation={activeRiskLocation}
						onChange={updateRiskLocation}
						fetchCityAndState={fetchCityAndState}
						contactInfo={contactData}
						accountInfo={accountData}
						updateAccountInfo={updateAccountData}
						updateContactInfo={updateContactData}
						miAddons={miAddons}
						CICData={CICData}
						CISData={CISData}
						CITData={CITData}
						updateMIAddons={updateMIAddons}
						updateCICData={updateCICData}
						updateCISData={updateCISData}
						updateCITData={updateCITData}
						safetyToggle={safetyToggle}
						cctvUnits={cctvUnits}
						updateCCTVUnits={updateCCTVUnits}
						policyInfo={policyInfo}
						setPolicyInfo={setPolicyInfo}
						onPolicyKindChange={onPolicyKindChange}
						showAsterisk={stockAvailable > -1 ? stockAvailable == activeRiskLocation : true}
						saveDraft={saveDraft}
						draftId={draftId}
						onAddressRequest={onAddressRequest}
						onAddressFetch={onAddressFetch}
						onAccountEdit={onAccountEdit}
						existingClient={existingClient}
						sumInsuredContainerRef={sumInsuredContainerRef}
					/>
					{locationMoreThan1 && (
						<FormFooter className="d-flex justify-content-center pt-2">
							<Pagination
								activePage={activeRiskLocation}
								totalPages={riskLocations.length}
								pageNumberClicked={navigateToRiskLocation}
							/>
						</FormFooter>
					)}
				</Section>
			</MainContainer>
		)
	}
)

export default DraftRFQFormHandler

const FormHeader = styled.div`
	height: 40px;
`

const FormFooter = styled.div`
	height: 60px;
`

const Section = styled(Col)`
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 10px;
	height: 100%;
`

const MainContainer = styled(Col)`
	height: 100%;
	@media (min-width: 816px) {
		margin-right: 0.5rem;
	}
	@media (max-width: 816px) {
		width: 100%;
		order: 2;
	}
`
