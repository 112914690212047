import { useHistory, useLocation } from 'react-router'
import { useState, useContext } from 'react'

import { useFetch } from '../../../../api/apihook'
import { Context } from '../../../../context/Provider'

import { headerStyle, compulsoryStyle } from '../../../../components/SharedComponents'
import { Space, Text, theme, Radio, Checkbox, MainContainer, Divider } from 'verak-ui'

const ShopRisk = () => {
	let history = useHistory()
	let location = useLocation()
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const Type = state?.location?.Type

	const isSFSPEdgeCase = location?.state?.isSFSPEdgeCase

	const returnPath = state?.location?.returnPath || ''

	let defaultData = null
	if (Type === 'PROPERTYBASEMENT') {
		// can take values from either 'property' or 'basement'
		defaultData = state?.location?.property?.ShopRisk || { ExceedsLimit: '', Goods: [] }
	} else {
		defaultData = state?.location?.ShopRisk || { ExceedsLimit: '', Goods: [] }
	}

	const [doesExceed, setExceed] = useState(defaultData.ExceedsLimit)
	const [goodsList, setGoodsList] = useState(defaultData.Goods)
	const [isNoneChecked, setIsNoneChecked] = useState(false)

	const doGoodsExist = goodsList?.length > 0

	const items = [
		'Celluloid goods',
		'Coir Loose',
		'Crackers & Fireworks',
		'Explosives of any kind',
		'Hay/Straw',
		'Hemp',
		'Jute loose',
		'Matches',
		'Methylated spirit',
		'Nitro-cellulose plastics',
		'Oils/Ether/Industrial solvent & other inflammable liquids having flashing point below 32º C ',
		'Paints with inflammble base having flashing point below 32º C - other than in sealed tins or drums',
		'Disinfectant liquids and liquid insectables - other than in sealed tins or drums',
		'Vegetable fibres of any kind including Rayon fibre',
	]

	const handleRadioChange = value => {
		setExceed(value)
	}

	const handleCheckBoxChange = value => {
		if (goodsList.includes(value)) {
			let alteredList = goodsList.filter(el => el !== value)
			setGoodsList(alteredList)
		} else {
			setGoodsList([...goodsList, value])
		}
	}

	const handleIsNoneChecked = value => {
		setIsNoneChecked(value => !value)
		setGoodsList([])
	}

	const isChecked = value => {
		return goodsList.includes(value)
	}

	// if goods list is empty then u can skip
	// if goods list has values and "does exceed" is also not answered u cannot skip
	const rightDisabled = doGoodsExist && doesExceed === ''

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_LOCATION_SHOP_RISK',
				data: {
					Goods: goodsList,
					ExceedsLimit: doGoodsExist ? doesExceed : '',
				},
			})
			resolve()
		})

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else if (isSFSPEdgeCase) {
			history.push({
				pathname: '/sfsp-edgecase/materials',
				state: {
					isSFSPEdgeCase: true,
				},
			})
		} else {
			history.push('/property-risk/theft-risk')
		}
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/location-details')
		}
	}

	return (
		<MainContainer
			header="Shop risk assessment"
			rightOnClick={proceed}
			rightDisabled={rightDisabled}
			top={1.5}
			leftOnClick={goBack}
		>
			<Text color={theme.colors.gray7} style={headerStyle}>
				Do you have any of the below mentioned goods at this risk location?
			</Text>
			<Space y={1.5} />
			<Text color={theme.colors.gray3} type={'body1'}>
				Select all that apply
			</Text>
			<Space y={1.5} />

			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				{items.map(el => (
					<Checkbox
						value={isNoneChecked ? false : isChecked(el)}
						onChange={() => handleCheckBoxChange(el)}
						text={el}
						disabled={isNoneChecked}
						containerStyle={{ width: '375px', marginBottom: '2rem' }}
					/>
				))}
				<Checkbox
					value={isNoneChecked}
					onChange={() => handleIsNoneChecked()}
					text={'None of the above'}
					containerStyle={{ width: '375px', marginBottom: '2rem' }}
				/>
			</div>

			{doGoodsExist ? (
				<>
					<Divider top={1.5} bottom={1.5} />
					<Text color={theme.colors.gray7} style={headerStyle}>
						Does the goods total value exceed 5% of the risk location value?
						<span style={compulsoryStyle}>*</span>
					</Text>
					<Space y={1} />
					<div className="flex-container">
						<Radio
							text="Yes"
							name="exceeds"
							value={doesExceed === true}
							onChange={() => handleRadioChange(true)}
						/>
						<Space x={3} />
						<Radio
							text="No"
							name="doesntexceed"
							value={doesExceed === false}
							onChange={() => handleRadioChange(false)}
						/>
					</div>
				</>
			) : null}
		</MainContainer>
	)
}

export default ShopRisk
