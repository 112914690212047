import { useState } from 'react'
import { Text, FileUploaded } from 'verak-ui'
import { Row, Col } from 'react-bootstrap'
import { SectionCard, InfoItem } from './commonStyle'

const parseNotes = (notes = '') => {
	if (!notes) return {}

	notes = notes?.split('\n') || []

	const parsedData = {}
	notes.forEach(item => {
		if (item) {
			const [question, answer] = item.split(' - ')
			switch (question) {
				case 'What facility do you have?':
					parsedData['facility'] = answer
					break
				case 'What business do you do?':
					parsedData['TypeOfBusiness'] = answer
					break
				case 'What do you want to insure?':
					parsedData['WantsToCover'] = answer
					break
				case 'What value of assets do you want to insure?':
					parsedData['ValueOfCover'] = answer
					break
				default:
			}
		}
	})

	return parsedData
}

const CustomerProvidedInfo = ({ data }) => {
	const { Lead = {}, Notes = '' } = data

	const NatureOfStock = Lead?.NatureOfStock || ''
	const FacilityType = Lead?.FacilityType || ''
	const BusinessType = Lead?.BusinessType || ''
	const FinishedGoods = Lead?.FinishedGoods || ''
	const RawMaterial = Lead?.RawMaterial || ''
	const FacilityOwnership = Lead?.FacilityOwnership || ''
	const FacilityFloor = Lead?.FacilityFloor || ''
	const WallMaterial = Lead?.WallMaterial || ''
	const RoofMaterial = Lead?.RoofMaterial || ''
	const FacilityImage = Lead?.FacilityImage || ''

	const [parsedValue, setParsedValue] = useState(() => parseNotes(Notes)) // eslint-disable-line

	const OwnershipValue =
		FacilityOwnership === 'SOLE' ? 'Owned' : FacilityOwnership === 'RENTED' ? 'Rented' : ''

	const FacilityImageViewer = FacilityImage ? (
		<FileUploaded className="mt-1" imageUrl={FacilityImage} />
	) : null

	const materialInfo = (WallMaterial || RoofMaterial) && (
		<Text fontWeight="500" fontSize="14px" color="black">
			{RoofMaterial ? `Roof - ${RoofMaterial}` : ''}
			<br />
			{WallMaterial ? `Wall - ${WallMaterial}` : ''}
		</Text>
	)

	return (
		<SectionCard className="px-1 py-3">
			<div className="d-flex">
				<Text className="mb-2" color={'#6467B0'} fontWeight="700">
					Customer provided information
				</Text>
			</div>

			<Row>
				<InfoItem label="Facility" value={FacilityType || parsedValue?.facility || '-'} withCol />
				<InfoItem label="Facility Ownership" value={OwnershipValue} withCol />
				<InfoItem label="Floor of customer's facility" value={FacilityFloor} withCol />
				<InfoItem label="Material Wall/Roof made of" value={materialInfo} withCol />
				<InfoItem label="Nature of Business" value={BusinessType} withCol />
				<InfoItem label="Type of stock" value={NatureOfStock} withCol renderIfAvailable />
				<InfoItem label="Finished Goods" value={FinishedGoods} withCol renderIfAvailable />
				<InfoItem label="Raw Material" value={RawMaterial} withCol renderIfAvailable />
			</Row>

			{FacilityImage && (
				<Row className="mt-3">
					<Col md="3">
						<InfoItem label="Picture of Facility" value={FacilityImageViewer} />
					</Col>
				</Row>
			)}
		</SectionCard>
	)
}

export default CustomerProvidedInfo
