export const rfqReducer = (state, action) => {
	switch (action.type) {
		case 'SET_RFQ_STATUS':
			return {
				...state,
				rfqStatus: action.data.rfqStatus,
			}
		case 'SET_EDIT_RFQ':
			return {
				...state,
				editRfqMode: action.data.editRfqMode,
			}
		case 'SET_RFQ_VIEW_ONLY_MODE':
			return {
				...state,
				rfqViewOnlyMode: action.data.rfqViewOnlyMode,
			}
		case 'SET_DRAFT_RFQ_VIEW_ONLY_MODE':
			return {
				...state,
				rfqViewOnlyMode: true,
				draftRFQReview: true,
				instantRFQData: {
					...action.data,
				},
			}
		case 'RESET_INSTANT_RFQ_DATA': {
			return {
				...state,
				instantRFQData: null,
			}
		}
		case 'RESET_DRAFT_RFQ_REVIEW_MODE':
			return {
				...state,
				rfqViewOnlyMode: false,
				draftRFQReview: false,
			}
		case 'SET_RFQ_DATA':
			return {
				...state,
				...action.data,
			}
		case 'SET_DRAFT_RFQ':
			return {
				...state,
				draftRFQ: { ...action.data },
			}
		case 'CLEAR_DRAFT_RFQ':
			return {
				...state,
				draftRFQ: null,
			}
		default:
			return state
	}
}
