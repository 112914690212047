import { useWindowDimensions } from '../hooks/useWindowDimensions'

const ViewOnLayout = ({ allow, children }) => {
	const { width } = useWindowDimensions()
	const isMobile = width < 768
	const isDesktop = width > 768
	if ((allow === 'desktop' && isDesktop) || (allow === 'mobile' && isMobile)) {
		return children
	} else {
		return null
	}
}

export default ViewOnLayout
