import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import BulkDiscount from '../../../../../assets/training/BulkDiscount.svg'
import InformationTransparency from '../../../../../assets/training/InformationTransparency.svg'
import NoFalsePromise from '../../../../../assets/training/NoFalsePromise.svg'
import VerakBroker from '../../../../../assets/training/VerakBroker.svg'
import VerakFeatures from '../../../../../assets/training/VerakFeatures.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function Training_StockValueVerify() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				GIVE CLARITY OF WHO WE ARE & WHAT WE OFFER
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={VerakBroker}>
						<InfoText>
							<b>Verak is IRDAI registered broker</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>We helped in settling {claimsCount} claims this year, we will help you too</li>
						<li>
							Our tie-up with insurance company gets us low price and help with claim settlement
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={VerakFeatures}>
						<InfoText>
							<b>With Verak, the customer gets </b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>Choice of Quotes/ Insurance Co.</li>
						<li>24x7 WhatsApp support</li>
						<li>Best Price Guaranteed</li>
					</ul>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				RECORDED CONVERSATION & INFORMATION
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={NoFalsePromise}>
						<InfoText>
							<b>
								No false promise as everything is recorded& this don't happen with offline direct
								purchase
							</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={InformationTransparency}>
						<InfoText>
							<b>
								Information we collect is stored and shared with you for clarity. We send email +
								WhatsApp
							</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={BulkDiscount}>
						<InfoText>
							<b>Customer understands the concept of bulk discount</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							Humare pass 5000 ke bhi jyada customers hai, to insurance company hume bhi bulk me
							discount deti hai
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column"></div>
			</div>
		</div>
	)
}
