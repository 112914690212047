export const checkIfRenewalsFlow = () => {
	return window.location.pathname.includes('/renewals')
}

export const getRedirectLink = (isRenewalsFlow, defaultLink) => {
	if (isRenewalsFlow) {
		return `/renewals${defaultLink}`
	}
	return defaultLink
}
