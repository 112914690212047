const goodsInTrustTotalSIForSingleRiskLoc = RiskLocation => {
	let totalSumInsured = 0

	const calcSIForSingleLoc = locItem => {
		// property section
		const goodsProperytData = locItem?.GoodsInTrust?.Property
		if (goodsProperytData) {
			for (const item in goodsProperytData) {
				if (goodsProperytData[item]?.Name !== 'MaxValSingleItem') {
					totalSumInsured = totalSumInsured + goodsProperytData[item]?.Amount
				}
			}
		}
		// stock section
		const goodsStockData = locItem?.GoodsInTrust?.Stock
		if (goodsStockData) {
			for (const item in goodsStockData) {
				const amountClosed = goodsStockData?.[item]?.AmountClosed || 0
				const amountOpen = goodsStockData?.[item]?.AmountOpen || 0
				totalSumInsured = totalSumInsured + amountClosed + amountOpen
			}
		}
	}

	const calcSI = loc => {
		// has levels
		if (loc?.levels?.length) {
			loc?.levels?.forEach(calcSIForSingleLoc)
		} else {
			calcSIForSingleLoc(loc)
		}
	}

	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		calcSI(RiskLocation?.property)
		calcSI(RiskLocation?.basement)
	} else {
		calcSI(RiskLocation)
	}

	return totalSumInsured
}

export const goodsInTrustTotalSIForAllRiskLocations = RiskLocations => {
	let totalSIAmount = 0

	RiskLocations?.forEach(riskLocation => {
		totalSIAmount = totalSIAmount + goodsInTrustTotalSIForSingleRiskLoc(riskLocation)
	})

	return totalSIAmount
}
