import { theme, Text, Button, Icon } from 'verak-ui'
import { useHistory, useLocation } from 'react-router'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import TimelineView from '../../../../components/Timeline'
import FinalReviewScreen from '../../../FinalReview/FinalReview'

import { useFetch } from '../../../../api/apihook'
import { useContextApi } from '../../../../context/Provider'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../../../FinalReview/finalReviewUtils'
import { calculateTotalStockVal } from '../../../../utilities/calculateTotalStockVal'

import TickCircleFilled from '../../../../assets/icons/greenTickCircleFilled.svg'
import TimelineWarningIcon from '../../../../assets/icons/timelineWarningIcon.svg'

export default function QuotesReady() {
	const history = useHistory()

	const fetch = useFetch()
	const [state, dispatch] = useContextApi()
	const { state: locationState } = useLocation()

	const [rfqData, setRfqData] = useState({})
	const [quotes, setQuotes] = useState([])
	const [sumInsuredBreakdown, setSumInsuredBreakdown] = useState({})
	const [totalSumInsured, setTotalSumInsured] = useState('')
	const [paymentDetails, setPaymentDetails] = useState({})

	// fetches rfq, quotes customer and payment details
	const fetchData = async () => {
		const rfqId = locationState?.uid
		const customerInfoUnavailable = !state.rfq?.ProposerName || !state.rfq?.Email

		const fetchHardwareProducts = () => {
			if (!state?.app?.hardwareProducts) {
				return fetch.getHardwareProducts()
			} else {
				return state?.app?.hardwareProducts
			}
		}

		const [rfqDetails, rfqQuotes, customerInfo, paymentInfo, hardwareProducts] = await Promise.all([
			fetch.getQuote(rfqId),
			fetch.fetchQuotes(rfqId),
			customerInfoUnavailable && fetch.getCustomerDetails(rfqId),
			fetch.getPaymentInfo(rfqId),
			fetchHardwareProducts(),
		])

		if (!state?.app?.hardwareProducts) {
			dispatch({
				type: 'SET_HARDWARE_PRODUCTS',
				data: hardwareProducts,
			})
		}

		// set payment details
		setPaymentDetails(paymentInfo)

		// prepare rfq data
		const mergedRfqData = fetch.mergeRiskLocations({
			...rfqDetails,
			quoteId: rfqId,
		})
		setRfqData(mergedRfqData)

		// prepare sum insured data
		const sumInsuredBreakdown = generateSumInsuredBreakdown(mergedRfqData.RiskLocations)
		const stockValue = calculateTotalStockVal(mergedRfqData)
		const totalSumInsured =
			totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) + (stockValue || 0)

		// prepare quotes data
		const policyCover = totalSumInsured
		const policyDuration = mergedRfqData.Cover.Duration

		const quotes = rfqQuotes.quotes
		if (quotes.length > 0) {
			const modifiedQuotes = quotes.map(quote => {
				const modifiedQuote = {
					...quote,
					Cover: policyCover,
					Duration: policyDuration,
				}

				if (quote?.HardwareProducts.length > 0) {
					modifiedQuote.Products = quote?.HardwareProducts.map(product => {
						const { Manufacturer, Model, Image, Features, Key } = hardwareProducts.find(
							hw => hw.Key === product.Key
						)
						return {
							Key,
							Model,
							Manufacturer,
							Image,
							Features,
							Units: product.Units,
						}
					})
				}
				return modifiedQuote
			})
			setQuotes(modifiedQuotes)
			setSumInsuredBreakdown(rfqQuotes?.SumInsuredBreakdown)
			setTotalSumInsured(totalSumInsured)
		}

		// set customer data
		const { ContactInfo, PersonalInfo } = customerInfo
		const { Phone, Email, WaPhone, WaConsent } = ContactInfo
		const { Name: ProposerName, LegalEntityName: EntityName } = PersonalInfo
		const customerInfoPayload = {
			WaPhone,
			Phone,
			Email,
			EntityName,
			WaConsent,
			ProposerName,
		}
		dispatch({
			type: 'SET_RFQ_DATA',
			data: customerInfoPayload,
		})
	}

	useEffect(() => {
		fetchData()
	}, [locationState]) // eslint-disable-line

	// Screens:
	// 1. timeline-view
	// 2. final-review
	const [activeScreen, setActiveScreen] = useState('timeline-view')

	let furtherRequirementsExist = false
	if (quotes?.length > 0) {
		for (let quote of quotes) {
			if (
				quote?.FurtherRequirements.length > 0 &&
				quote?.FurtherRequirements?.every(item => !item?.Answer)
			) {
				furtherRequirementsExist = true
				break
			}
		}
	}

	const navContent = (
		<Text type="body1" mobileFontSize="14px" color={theme.colors.gray7} fontWeight="700">
			Overview, {locationState?.uid} - {locationState?.ClientName} - {locationState?.PolicyType}
		</Text>
	)

	const viewQuotes = () => {
		if (!!quotes) {
			const { State, City } = rfqData?.RiskLocations?.[0]

			history.push(`/rfq-status/quotes-ready/compare-quotes`, {
				...locationState,
				selectedState: State || '',
				selectedCity: City || '',
				quotes,
				totalSumInsured,
				SumInsuredBreakdown: sumInsuredBreakdown,
				paymentDetails,
				selectedQuote: quotes?.find(q => q?.Selected),
				RfqTag: rfqData?.Tag,
			})
		}
	}

	const viewRFQDetails = async () => {
		const uid = locationState?.uid || ''
		if (uid) {
			let rfqDetails = await fetch.getQuote(uid)

			const toMerge = {
				...rfqDetails,
				quoteId: uid,
			}

			const rfqData = fetch.mergeRiskLocations(toMerge)

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE',
					data: rfqData,
				})
				resolve()
			})

			await new Promise(resolve => {
				dispatch({
					type: 'SET_OLD_RFQ',
					data: JSON.parse(JSON.stringify({ ...rfqData })),
				})
				resolve()
			})
		}

		// the final review route should take the user to this route after the updates are done
		// instead of continuing the normal flow
		const flowCompletionRoutes = {
			finalRoute: '/final-review',
			redirectRoute: '/quotes-in-progress',
		}
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_FLOW_COMPLETION_ROUTES',
				data: { flowCompletionRoutes },
			})
			resolve()
		})

		// set update flow as false initially
		await new Promise(resolve => {
			dispatch({
				type: 'SET_EDIT_RFQ',
				data: { editRfqMode: false },
			})
			resolve()
		})

		// navigate to final review screen
		history.push('/final-review', {
			from: 'rfq-progress-screen',
		})

		// setActiveScreen('final-review')
	}

	const backToQuotesInProgress = () => {
		history.push(`/quotes-in-progress`)
	}

	const timelineItems = [
		{
			icon: <img src={TickCircleFilled} alt="tick-circle-filled" height="40px" width="40px" />,
			id: 'client-verification',
			content: (
				<TimelineItem>
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} className="me-2" fontSize="18px" fontWeight="700">
							Client Verification
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text color={theme.colors.secondary} fontSize="12px" fontWeight="400">
								Complete
							</Text>
						</div>
					</div>

					<Text
						style={{ cursor: 'pointer' }}
						onClick={viewRFQDetails}
						className="d-flex justify-content-center align-items-center"
						color={theme.colors.primary}
						fontSize="18px"
						fontWeight="24px"
					>
						See RFQ details &nbsp; <Icon name="rightArrow" color={theme.colors.primary} />
					</Text>
				</TimelineItem>
			),
		},
		{
			icon: <img src={TickCircleFilled} alt="tick-circle-filled" height="40px" width="40px" />,
			id: 'rfq-submitted',
			content: (
				<TimelineItem>
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} className="me-2" fontSize="18px" fontWeight="700">
							RFQ submitted
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text color={theme.colors.secondary} fontSize="12px" fontWeight="400">
								Complete
							</Text>
						</div>
					</div>
				</TimelineItem>
			),
		},
		{
			icon: (
				<img
					src={furtherRequirementsExist ? TimelineWarningIcon : TickCircleFilled}
					alt="tick-circle-filled"
					height="40px"
					width="40px"
				/>
			),
			id: 'quotes-generated',
			content: (
				<TimelineItem>
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} className="me-2" fontSize="18px" fontWeight="700">
							Quotes generated
						</Text>
						<div className="d-flex mt-2 align-items-center">
							<Text color={theme.colors.secondary} fontSize="12px" fontWeight="400">
								{furtherRequirementsExist ? 'Missing Information' : 'Quotes available'}
							</Text>
						</div>
					</div>

					<ViewQuotesButton
						disabled={quotes?.length === 0}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
						onClick={viewQuotes}
					/>
				</TimelineItem>
			),
		},
		{
			disabled: true,
			id: 'purchase',
			content: (
				<TimelineItem>
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2 align-items-center">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Pending
						</Text>
					</div>
				</TimelineItem>
			),
		},
	]

	const timelineView = (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
				onGoBack={backToQuotesInProgress}
			/>

			<div
				style={{
					width: '95%',
				}}
				className="container h-100 p-3 mt-2 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" mobileFontSize="16px" color={theme.colors.primary}>
					Quotes available now!
				</Text>

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)

	const backToTimelineView = () => {
		dispatch({
			type: 'CLEAR_DATA',
		})
		setActiveScreen('timeline-view')
	}

	const finalReview = (
		<FinalReviewScreen onBack={backToTimelineView} onProceed={backToTimelineView} />
	)

	return activeScreen === 'timeline-view' ? timelineView : finalReview
}

// styles

const TimelineItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 816px) {
		flex-direction: column;
		align-items: flex-start;
	}
`

const ViewQuotesButton = styled(Button)`
	height: 56px;
	@media (max-width: 816px) {
		margin-top: 0.5rem;
		height: 38px;
	}
`
