import { Button, Icon, Modal, Text, theme } from 'verak-ui'

const SendWebsiteLinkModal = ({ showModal = true, uid, closeModal = () => {} }) => {
	return (
		<Modal
			show={showModal}
			handleClose={closeModal}
			closeButton={false}
			title={
				<Text type="primary" color={theme.colors.red}>
					Notice
				</Text>
			}
			footer={<></>}
			style={{ textAlign: 'center' }}
		>
			<Icon name="web" className="d-flex justify-content-center" />
			<Text fontSize="20px" fontWeight="700" color={theme.colors.primary} className="mt-3">
				Website access details has been sent! UID is {uid}
			</Text>

			<Button
				primary
				onClick={closeModal}
				className="mt-3"
				style={{
					width: '100%',
					height: '50px',
					fontSize: '18px',
				}}
				label="Close"
			/>
		</Modal>
	)
}

export default SendWebsiteLinkModal
