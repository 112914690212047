import { Container, Row, Col } from 'react-bootstrap'
import { useContext, useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import {
	Alert,
	FileUploader,
	HiddenFileInput,
	FileUploaded,
	MainContainer,
	Checkbox,
	Icon,
	Input,
	Radio,
	Text,
	Tooltip,
	theme,
	Divider,
} from 'verak-ui'
import { Context } from '../../../context/Provider'
import { defaultSecurityOptions, prefillTheftRisk } from './Shared'
import { useFetch } from '../../../api/apihook'
import {
	parseLinkFromSignedUrl,
	imageDeleteConfirmOptions,
} from '../../../utilities/imageUploadUtilities'
import Swal from 'sweetalert2'
import { useLoadingOverlay } from '../../../components/LoadingOverlay'
import { isManufacturingOccupancy } from '../../../utilities/checkManufacturing'

const TitleText = styled(Text)`
	font-weight: 700;
	font-size: 24px;
	color: ${theme.colors.gray7};
`

const isFreeTextResponse = (Occupancy, cachedSubCategories) => {
	if (cachedSubCategories && Occupancy) {
		// check if occupancy is present in the cached data
		const occupancyPresent = cachedSubCategories?.find(item => item.Description === Occupancy)
		return !occupancyPresent
	}
	return false
}

const isManufCategory = (OccupancyItem, cachedSubCategories) => {
	const MANUFACTURING_CATEGORY = 'Manufacturing or other Industrial Property'
	let isManuf = false
	let isFreeText = isFreeTextResponse(OccupancyItem.SubCategory, cachedSubCategories)

	// check if occupancy entered is free text response
	if (isFreeText) {
		const isManufacturing = isManufacturingOccupancy(OccupancyItem.SubCategory)
		isManuf = isManufacturing
	} else {
		const isManufacturing = cachedSubCategories?.find(
			item => item.Description === OccupancyItem.SubCategory
		)
		isManuf = isManufacturing?.Type === MANUFACTURING_CATEGORY
	}
	return isManuf
}

const checkForPrimarySecondaryOcc = (data, cachedSubCategories) => {
	let primaryOccupancyCheck = false,
		secondaryOccupancyCheck = false

	if (data?.levels) {
		secondaryOccupancyCheck = data?.levels?.some(el =>
			el?.SecondaryOccupancy?.some(item => isManufCategory(item, cachedSubCategories))
		)
		primaryOccupancyCheck = data?.levels?.some(el =>
			isManufCategory(el?.PrimaryOccupancy || {}, cachedSubCategories)
		)
	} else {
		secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item =>
			isManufCategory(item, cachedSubCategories)
		)
		primaryOccupancyCheck = isManufCategory(data?.PrimaryOccupancy || {}, cachedSubCategories)
	}
	return secondaryOccupancyCheck || primaryOccupancyCheck
}

const isOnlySecondaryManufacturing = (data, cachedSubCategories) => {
	let primaryOccupancyCheck = false,
		secondaryOccupancyCheck = false

	if (data?.levels) {
		secondaryOccupancyCheck = data?.levels?.some(el =>
			el?.SecondaryOccupancy?.some(item => isManufCategory(item, cachedSubCategories))
		)
		primaryOccupancyCheck = data?.levels?.some(el =>
			isManufCategory(el?.PrimaryOccupancy || {}, cachedSubCategories)
		)
	} else {
		secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item =>
			isManufCategory(item, cachedSubCategories)
		)
		primaryOccupancyCheck = isManufCategory(data?.PrimaryOccupancy || {}, cachedSubCategories)
	}

	if (!primaryOccupancyCheck) {
		return secondaryOccupancyCheck
	} else {
		return false
	}
}

const checkSecondaryManufacturing = (state, cachedSubCategories) => {
	const { Type, property, basement } = state?.location || {}

	let result = false

	if (Type === 'PROPERTYBASEMENT') {
		result =
			isOnlySecondaryManufacturing(property, cachedSubCategories) ||
			isOnlySecondaryManufacturing(basement, cachedSubCategories)
	} else {
		result = isOnlySecondaryManufacturing(state?.location, cachedSubCategories)
	}
	return result
}

function checkIndustrialOrManufacturing(state, cachedSubCategories) {
	const { Type, property, basement } = state?.location || {}

	const isPropertyBasement = Type === 'PROPERTYBASEMENT'
	let isIndustrialOrManuf = false

	if (isPropertyBasement) {
		isIndustrialOrManuf = checkForPrimarySecondaryOcc(property, cachedSubCategories)
		isIndustrialOrManuf =
			isIndustrialOrManuf || checkForPrimarySecondaryOcc(basement, cachedSubCategories)
	} else {
		isIndustrialOrManuf = checkForPrimarySecondaryOcc(state?.location, cachedSubCategories)
	}
	return isIndustrialOrManuf
}

const TheftRiskPropertyBasement = () => {
	const fetch = useFetch()
	const location = useLocation()

	const returnPath = location?.state?.returnPath || ''

	const cachedData = fetch?.cachedData?.SubCategories?.required

	const fetchSubCategories = async () => {
		if (!cachedData) {
			await fetch.getSubCategoryByRiskType()
		}
	}

	useEffect(() => {
		if (!cachedData) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	const { setIsLoading, setLoadingText } = useLoadingOverlay()
	const [state, dispatch] = useContext(Context)
	const prefillData = prefillTheftRisk(state.location || {})

	const fileInput = useRef()

	const [SecurityArrangements, setSecurityArrangements] = useState(
		prefillData.SecurityArrangements?.filter(el => defaultSecurityOptions.includes(el)) || []
	)
	const [otherDisabled, setOtherDisabled] = useState(!Boolean(prefillData.otherOptionData))

	const [BoundaryWall, setBoundaryWall] = useState(prefillData.BoundaryWall || '')
	const [FactoryType, setFactoryType] = useState(prefillData.FactoryType || '')

	// add images prefill
	const [AwsLinks, setAwsLinks] = useState(prefillData?.BlockPlan || [])

	// pending - get this value from global state
	// (either from - RL 1 [primary usage] or RL 2 [other usage])
	const isIndustrialOrManufacturing = checkIndustrialOrManufacturing(state, cachedData)
	const isSecondaryManufacturing = checkSecondaryManufacturing(state, cachedData)

	const [otherOption, setOtherOption] = useState(prefillData.otherOptionData || '')
	const history = useHistory()

	const isDwellingsCategory = OccupancyItem => {
		const DWELLINGS_CATEGORY = 'Dwellings, Office, Shop or Hotel'

		if (!!cachedData) {
			const isDwellings = cachedData?.find(item => item.Description === OccupancyItem.SubCategory)

			return isDwellings?.Type === DWELLINGS_CATEGORY
		}
		return false
	}

	const checkForPrimarySecondaryOcc = data => {
		let secondaryOccupancyCheck = false,
			primaryOccupancyCheck = false
		if (data?.levels) {
			secondaryOccupancyCheck = data?.levels?.some(el =>
				el?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			)
			primaryOccupancyCheck = data?.levels?.some(el =>
				isDwellingsCategory(el?.PrimaryOccupancy || {})
			)
		} else {
			secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			primaryOccupancyCheck = isDwellingsCategory(data?.PrimaryOccupancy || {})
		}
		return secondaryOccupancyCheck || primaryOccupancyCheck
	}

	const checkDwellings = () => {
		const { Type, property, basement } = state?.location || {}
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'

		let isDwellings = false

		if (isPropertyBasement) {
			isDwellings = checkForPrimarySecondaryOcc(property)
			isDwellings = isDwellings || checkForPrimarySecondaryOcc(basement)
		} else {
			isDwellings = checkForPrimarySecondaryOcc(state?.location)
		}
		return isDwellings
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else if (state.quote?.PolicyName === 'SFSP') {
			if (checkDwellings()) {
				history.replace('/property-risk/shop-risk')
			} else {
				history.replace('/property-risk/location-details')
			}
		} else {
			history.replace('/property-risk/location-details')
		}
	}

	const proceed = async () => {
		let SecurityValues = [...SecurityArrangements]

		if (!otherDisabled && otherOption) {
			SecurityValues = [...SecurityArrangements, otherOption]
		}

		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'basement',
				levelIndex: -1,
				data: {
					TheftRisk: {
						SecurityArrangements: SecurityValues, // from state
					},
				},
			})

			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					TheftRisk: {
						SecurityArrangements: SecurityValues, // from state
						BoundaryWall: Boolean(BoundaryWall), // True or false
						FactoryType: FactoryType, //Working or Silent
						BlockPlan: AwsLinks, // array of image links
					},
				},
			})

			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else {
			history.push(`/property-risk/materials`)
		}
	}

	const handleChange = (key, state) => {
		let arrCopy = [...SecurityArrangements]

		if (state && !arrCopy.includes(key) && key !== 'Other') {
			arrCopy = [...arrCopy, key]
		} else {
			if (arrCopy.includes(key)) {
				arrCopy = [...arrCopy.filter(el => el !== key)]
			}
		}
		setSecurityArrangements([...arrCopy])
	}

	const clearValues = () => {
		setBoundaryWall('')
		setFactoryType('')
	}

	const isChecked = value => SecurityArrangements.includes(value)

	let proceedDisabled = false
	let allQuestionsOptional = true
	if (isIndustrialOrManufacturing) {
		proceedDisabled = !FactoryType || (!otherDisabled && !otherOption)
		allQuestionsOptional = false
	} else {
		proceedDisabled = !otherDisabled && !otherOption
	}

	// file upload code

	const triggerFileUpload = () => {
		fileInput?.current?.click()
	}

	const handleFileUpload = async files => {
		const file = files[0]
		setIsLoading(true)
		setLoadingText('Uploading...')
		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setAwsLinks([...AwsLinks, s3Link])
	}

	const removeImage = toRemove => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setAwsLinks([...AwsLinks.filter(link => link !== toRemove)])
				Swal.fire('Your file has been deleted')
			}
		})
	}

	const fileUploaded =
		AwsLinks?.length > 0 &&
		AwsLinks.map((el, index) => {
			return <FileUploaded imageUrl={el} key={index} onDelete={() => removeImage(el)} />
		})

	const clearSelection = (
		<Row>
			<Col
				onClick={clearValues}
				style={{ cursor: 'pointer' }}
				className="d-flex align-items-center"
			>
				<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
				<Text>Clear selection</Text>
			</Col>
		</Row>
	)

	let optionalTag = null
	if (!allQuestionsOptional) {
		optionalTag = (
			<span className="ms-1" style={{ color: theme.colors.secondary }}>
				[Optional]
			</span>
		)
	}

	const arrangementOnPremise = (
		<>
			<TitleText className="mb-2">
				What security arrangements exist on the premises?
				{optionalTag}
			</TitleText>
			<Text className="mb-3" color={theme.colors.gray3} type={'body1'}>
				Select all that apply
			</Text>
			<Row className="mb-4">
				<Col className="d-flex">
					<Checkbox
						value={isChecked('Anti-theft locks')}
						onChange={state => handleChange('Anti-theft locks', state)}
						text="Locks"
						icon="antiTheftLock"
					/>
				</Col>
				<Col className="d-flex">
					<Checkbox
						value={isChecked('CCTV')}
						onChange={state => handleChange('CCTV', state)}
						text="CCTV"
						icon="cctv"
					/>
				</Col>
			</Row>
			<Row className="mb-4">
				<Col className="d-flex">
					<Checkbox
						value={isChecked('24 hour security')}
						onChange={state => handleChange('24 hour security', state)}
						text="24 hour security"
						icon="hrSecurity"
					/>
				</Col>
				<Col className="d-flex">
					<Checkbox
						value={isChecked('Alarm system')}
						onChange={state => handleChange('Alarm system', state)}
						text="Alarm system"
						icon="alarm"
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col className="d-flex">
					<Checkbox
						value={isChecked('Night watchman')}
						onChange={state => handleChange('Night watchman', state)}
						text="Night watchman"
						icon="watchman"
					/>
				</Col>
				<Col className="d-flex flex-column">
					<div className="d-flex">
						<Checkbox
							value={!otherDisabled}
							onChange={state => setOtherDisabled(!state)}
							text="Other (Please specify)"
							icon="otherSecurity"
						/>
					</div>
				</Col>
			</Row>
			<Row className="mb-5">
				<Col></Col>
				<Col>
					<Input
						disabled={otherDisabled}
						value={otherOption}
						onChange={e => setOtherOption(e.target.value)}
						placeholder="Enter here"
					/>
				</Col>
			</Row>
		</>
	)

	const radioButtonStyle = { width: '254px' }

	const details = (
		<>
			{isIndustrialOrManufacturing && (
				<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
					Note: * marked questions are mandatory
				</Text>
			)}
			<TitleText className="mb-2">Enter building details:</TitleText>
			<Row>
				<Col className="d-flex align-items-center mb-2">
					<Icon name="wall" className="me-3" />
					<Text type="body1">Is there a boundary wall?</Text>
					<div className="d-flex ms-auto" style={{ width: '450px' }}>
						<Radio
							value={BoundaryWall === true}
							onClick={() => setBoundaryWall(true)}
							name="boundaryWall"
							text="Yes"
							style={radioButtonStyle}
						/>
						<Radio
							value={BoundaryWall === false}
							onClick={() => setBoundaryWall(false)}
							name="boundaryWall"
							text="No"
							style={radioButtonStyle}
						/>
					</div>
				</Col>
			</Row>
			{isIndustrialOrManufacturing && (
				<Row>
					<Col className="d-flex align-items-center mb-2">
						<Icon name="factory" className="me-3" />
						<Text type="body1">
							What kind of factory?
							<span style={{ color: theme.colors.red }}>*</span>
						</Text>
						<div className="d-flex align-items-center ms-auto" style={{ width: '450px' }}>
							<Radio
								value={FactoryType === 'Working'}
								onClick={() => setFactoryType('Working')}
								name="factoryType"
								text={
									<>
										<Text fontSize="18px">Operational</Text>
										<Text color={theme.colors.gray4}>(Working)</Text>
									</>
								}
								style={radioButtonStyle}
							/>
							<Tooltip
								icon="knowMore"
								text="A working risk denotes Industrial/Manufacturing risks and Utilities located inside the compounds of industrial/manufacturing risks where manufacturing/storage activities are carried out continuously for 30 days or more."
								style={{ margin: '0 2rem 0 -2rem' }}
							/>

							<Radio
								value={FactoryType === 'Silent'}
								onClick={() => setFactoryType('Silent')}
								name="factoryType"
								text={
									<>
										<Text fontSize="18px">Non-Operational</Text>
										<Text color={theme.colors.gray4}>(Silent)</Text>
									</>
								}
								style={radioButtonStyle}
							/>
							<Tooltip
								icon="knowMore"
								text="A silent risk denotes Industrial/Manufacturing risks and Utilities located outside the compounds of industrial/manufacturing risks where no manufacturing/storage activities are carried out continuously for 30 days or more."
							/>
						</div>
					</Col>
					{isSecondaryManufacturing && (
						<Alert
							className="mb-3"
							bgColor={theme.colors.secondary3}
							iconName="infoIcon"
							infoIconColor={theme.colors.secondary}
						>
							<Text
								color={theme.colors.secondary}
								style={{
									fontWeight: 'bold',
								}}
							>
								Please answer the above question since the client has disclosed secondary use of the
								facility for manufacturing / production purposes.
							</Text>
						</Alert>
					)}
				</Row>
			)}
			{clearSelection}
			<Divider />
		</>
	)

	const blockPlanSection = (
		<>
			<Divider />
			<Text type="body1" className="mb-4">
				Please attach a detailed block plan showing facility details (a hand made sketch is
				sufficient)
				{optionalTag}
			</Text>

			<Container>
				<div className="d-flex mt-4 mb-4">
					<>
						<HiddenFileInput ref={fileInput} onChange={e => handleFileUpload(e.target.files)} />
						<FileUploader onClick={triggerFileUpload} />
					</>

					{fileUploaded}
				</div>
			</Container>

			<Alert
				className="mb-4"
				title="Warning"
				variant="warning"
				infoIconColor={theme.colors.gray7}
				titleColor={theme.colors.gray7}
				bgColor={theme.colors.secondaryLightRed}
			>
				<Text>1. These documents are NOT required to generate the quotation</Text>
				<Text>
					2. BUT, these documents are required to be submitted to the insurance company to ensure
					that there are NO issues during claims.
				</Text>
			</Alert>
		</>
	)

	let mainHeaderText = 'Theft risk assessment'
	if (allQuestionsOptional) {
		mainHeaderText = (
			<>
				Theft risk assessment <span className="ms-2">[OPTIONAL]</span>
			</>
		)
	}
	return (
		<Container>
			<MainContainer
				header={mainHeaderText}
				rightLabel="Save & Continue"
				rightOnClick={proceed}
				rightDisabled={proceedDisabled}
				leftOnClick={goBack}
			>
				{details}
				{arrangementOnPremise}
				{isIndustrialOrManufacturing && blockPlanSection}
			</MainContainer>
		</Container>
	)
}

export default TheftRiskPropertyBasement
