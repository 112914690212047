/*** SALES QUOTE SOFTWARE (SQS) ***/

// NEW QUOTE FLOW
import SelectClientType from '../screens/SQS/SelectClientType'
import SelectLeadType from '../screens/SQS/SelectLeadType'
import SelectPolicyType from '../screens/SQS/SelectPolicyType'
import SelectSumInsuredRange from '../screens/SQS/SelectSumInsuredRange'
import SelectAddons from '../screens/SQS/SelectAddons'
import SelectedJourneys from '../screens/SQS/SelectedJourneys'

// RETURN TO SAVED QUOTE (RESUME QUOTE)
import QuotesInProgress from '../screens/SQS/QuotesInProgress'

// AGENT ANALYTICS
import AgentAnalytics from '../screens/AgentAnalytics/AgentAnalytics'

// DRAFT RFQ (SALES AMMUNITION)
import DraftRFQScreen from '../screens/DraftRFQ/DraftRFQScreen'
import DraftRFQList from '../screens/DraftRFQ/DraftRFQList'
import StartDraft from '../screens/DraftRFQ/StartDraft'
import ViewSavedDrafts from '../screens/DraftRFQ/ViewSavedDrafts'

// RFQ REASSIGNMENT
import ReassignmentRequests from '../screens/RFQReassignment/ReassignmentRequests'

// -- RFQ STATUS --

// STATUS - IN PROGRESS
import InProgress from '../screens/SQS/RFQStatus/InProgress'

// STATUS - QUOTES PENDING
import QuotesPending from '../screens/SQS/RFQStatus/QuotesPending/QuotesPending'

// STATUS - RFQ ABANDONED
import RFQAbandoned from '../screens/SQS/RFQStatus/RFQAbandoned'
import RFQPurchase from '../screens/SQS/RFQStatus/RFQPurchase'

// STATUS - QUOTE ABANDONED
import QuoteAbandoned from '../screens/SQS/RFQStatus/QuoteAbandoned'

// STATUS - UNDERWRITER REVIEW PENDING
import UnderwriterReviewPending from '../screens/SQS/RFQStatus/UnderwriterReviewPending'

// STATUS - CHANGES IN RFQ
import ChangesInRFQ from '../screens/SQS/RFQStatus/ChangesInRFQ/ChangesInRFQ'
import RFQChangeList from '../screens/SQS/RFQStatus/ChangesInRFQ/ViewChangeList'

// STATUS - UNDERWRITER REQUIREMENTS
import UnderwriterRequirements from '../screens/SQS/RFQStatus/UnderwriterRequirements/UnderwriterRequirements'
import UWRFireAllRisk from '../screens/SQS/RFQStatus/UnderwriterRequirements/UWRFireAllRisk'
import FillMissingData from '../screens/SQS/RFQStatus/UnderwriterRequirements/FillMissingData'

// STATUS - CLIENT VERIFICATION PENDING
import ClientVerificationPending from '../screens/SQS/RFQStatus/ClientVerificationPending/ClientVerificationPending'
import ClientVerificationFinalReview from '../screens/SQS/RFQStatus/ClientVerificationPending/ClientVerificationFinalReview'
import ClientVerificationSumInsuredBreakdown from '../screens/SQS/RFQStatus/ClientVerificationPending/ClientVerificationSumInsuredBreakdown'

// STATUS - QUOTES PENDING
import QuotesPendingSummaryScreen from '../screens/SQS/RFQStatus/QuotesPending/QuotesPendingSummaryScreen'
import QuotesPendingFinalReview from '../screens/SQS/RFQStatus/QuotesPending/QuotesPendingFinalReview'
import QuotesPendingSumInsuredBreakdown from '../screens/SQS/RFQStatus/QuotesPending/QuotesPendingSumInsured'
import RFQUpdateSuccess from '../screens/SQS/RFQStatus/RFQUpdateSuccess'

// STATUS - QUOTES READY
import QuotesReady from '../screens/SQS/RFQStatus/QuotesReady/QuotesReady'
import QuotesComparisonScreen from '../screens/SQS/RFQStatus/QuotesReady/ComparisonComponents/QuotesComparisonScreen'
import CompleteInsurerRequirements from '../screens/SQS/RFQStatus/QuotesReady/CompleteInsurerRequirements'
import ChooseInsurer from '../screens/Renewals/ChooseInsurer'
import SpecificQuoteRequests from '../screens/Renewals/SpecificRequests'

// routes
const newQuoteRoutes = [
	{
		path: '/new-quote',
		component: SelectClientType,
		exact: true,
	},
	{
		path: '/new-quote/select-lead-type',
		component: SelectLeadType,
		exact: true,
		blockOnMobile: true,
	},
	// {
	// 	path: '/new-quote/self-sourced-lead',
	// 	component: SelfSourcedLead,
	// 	exact: true,
	// },
	{
		path: '/new-quote/select-policy-type',
		component: SelectPolicyType,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/new-quote/select-sum-insured',
		component: SelectSumInsuredRange,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/new-quote/select-addons',
		component: SelectAddons,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/new-quote/selected-journeys',
		component: SelectedJourneys,
		exact: true,
		blockOnMobile: true,
	},
]

const returnToSavedQuoteRoutes = [
	{
		path: '/quotes-in-progress',
		component: QuotesInProgress,
		exact: true,
	},
]

const rfqStatusRoutes = [
	{
		path: '/rfq-status/in-progress',
		component: InProgress,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-pending',
		component: QuotesPending,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/rfq-abandoned',
		component: RFQAbandoned,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/purchase',
		component: RFQPurchase,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quote-abandoned',
		component: QuoteAbandoned,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/underwriter-review-pending',
		component: UnderwriterReviewPending,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/changes-in-rfq',
		component: ChangesInRFQ,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/rfq-changes-list/:rfqId',
		component: RFQChangeList,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/underwriter-requirements',
		component: UnderwriterRequirements,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/underwriter-requirements/fill-missing-data',
		component: FillMissingData,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/underwriter-requirements/fire-all-risk',
		component: UWRFireAllRisk,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/client-verification-pending',
		component: ClientVerificationPending,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/client-verification-pending/final-review',
		component: ClientVerificationFinalReview,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/client-verification-pending/sum-insured-breakdown',
		component: ClientVerificationSumInsuredBreakdown,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-pending/summary',
		component: QuotesPendingSummaryScreen,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-pending/final-review',
		component: QuotesPendingFinalReview,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-pending/sum-insured-breakdown',
		component: QuotesPendingSumInsuredBreakdown,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/update-rfq-success',
		component: RFQUpdateSuccess,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-ready',
		component: QuotesReady,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-ready/compare-quotes',
		component: QuotesComparisonScreen,
		exact: true,
		blockOnMobile: true,
	},
	{
		path: '/rfq-status/quotes-ready/complete-insurer-requirements',
		component: CompleteInsurerRequirements,
		exact: true,
		blockOnMobile: true,
	},
]

const draftRFQRoutes = [
	{
		path: '/new-draft',
		component: DraftRFQScreen,
		exact: true,
	},
	{
		path: '/draft-rfqs',
		component: DraftRFQList,
		exact: true,
	},
	{
		path: '/start-draft',
		component: StartDraft,
		exact: true,
	},
	{
		path: '/saved-drafts',
		component: ViewSavedDrafts,
		exact: true,
	},
]

const renewalsDraftRFQRoutes = [
	{
		path: '/renewals/new-draft',
		component: DraftRFQScreen,
		exact: true,
	},
	{
		path: '/renewals/draft-rfqs',
		component: DraftRFQList,
		exact: true,
	},
	{
		path: '/renewals/start-draft',
		component: StartDraft,
		exact: true,
	},
	{
		path: '/renewals/saved-drafts',
		component: ViewSavedDrafts,
		exact: true,
	},
	{
		path: '/renewals/choose-insurer',
		component: ChooseInsurer,
		exact: true,
	},
	{
		path: '/renewals/specific-requests',
		component: SpecificQuoteRequests,
		exact: true,
	},
]

const reassignmentRoutes = [
	{
		path: '/reassignment-requests',
		component: ReassignmentRequests,
		exact: true,
	},
]

const analyticsRoutes = [
	{
		path: '/analytics',
		component: AgentAnalytics,
		exact: true,
	},
]

const sqsCombinedRoutes = [
	...newQuoteRoutes,
	...returnToSavedQuoteRoutes,
	...rfqStatusRoutes,
	...draftRFQRoutes,
	...renewalsDraftRFQRoutes,
	...reassignmentRoutes,
	...analyticsRoutes,
]

export default sqsCombinedRoutes
