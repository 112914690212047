import { useContext } from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { convertToNumber } from '../../../../utilities/convertToNumber'

import {
	Icon,
	Input,
	Modal,
	Space,
	Switch,
	Text,
	theme,
	Tooltip,
	Button,
	Alert,
	Divider,
} from 'verak-ui'
import { Context } from '../../../../context/Provider'
import remDuplicatesArrObj from '../../../../utilities/remDuplicatesArrObj'
import {
	predefinedSumInsuredFields,
	singleItemDefaults,
	singleItemOps,
	sumInsuredDefaults,
	sumInsuredOptions,
} from './options'
import {
	ReinstatementModalContent,
	ReinstatementModalFooter,
	ReinstatementModalTitle,
} from './ReinstatementModalData'
import AddCustomSIFieldModal from '../../../DraftRFQ/components/AddCustomSIFieldModal'

function filterItems(list, propertyType, isSFSP, isSFSPEdgeCase) {
	let basementExclusion = []
	let propertyExclusion = []
	let common = [
		'BoundaryWalls',
		'PlantMachinery',
		'FurnitureFixture',
		'Electronics',
		'OfficeEquipment',
		'ValuableContents',
		'Other',
	]

	if (isSFSPEdgeCase) {
		basementExclusion = ['BuildingValue', ...common]
		propertyExclusion = ['BasementValue', ...common]
	} else if (isSFSP) {
		basementExclusion = ['BuildingValue', 'BoundaryWalls']
		propertyExclusion = ['BasementValue']
	} else {
		basementExclusion = ['BuildingValue', 'BoundaryWalls', 'PlinthFoundation']
		propertyExclusion = ['BasementValue', 'PlinthFoundation']
	}

	if (propertyType === 'property') {
		return list.filter(el => !propertyExclusion.includes(el.inputName || el.Name))
	}
	return list.filter(el => !basementExclusion.includes(el.inputName || el.Name))
}

function prefillSumInsured(filteredList, selectedType, selectedLevelIdx, globalState) {
	const { Type, property, basement, levels, SumInsured } = globalState?.location || {}

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'

	let SumInsData = {
		sumInsItemsData: filteredList,
		singleItemData: singleItemDefaults,
	}

	if (isProperty || isBasement) {
		if (levels) {
			const currentLvlData = levels[selectedLevelIdx] || {}
			const sumInsuredData = currentLvlData?.SumInsured || []
			if (sumInsuredData?.length > 0) {
				SumInsData.sumInsItemsData = sumInsuredData
			}
		}
		if (SumInsured?.length > 0) {
			SumInsData.sumInsItemsData = SumInsured
		}
	} else {
		if (selectedType === 'property' && property) {
			if (property?.levels) {
				const currentLvlData = property?.levels[selectedLevelIdx] || {}
				const sumInsuredData = currentLvlData?.SumInsured || []
				if (sumInsuredData?.length > 0) {
					SumInsData.sumInsItemsData = sumInsuredData
				}
			}
			if (property?.SumInsured?.length > 0) {
				SumInsData.sumInsItemsData = property.SumInsured
			}
		}
		if (selectedType === 'basement' && basement) {
			if (basement?.levels) {
				const currentLvlData = basement?.levels[selectedLevelIdx] || {}
				const sumInsuredData = currentLvlData?.SumInsured || []
				if (sumInsuredData?.length > 0) {
					SumInsData.sumInsItemsData = sumInsuredData
				}
			}
			if (basement?.SumInsured?.length > 0) {
				SumInsData.sumInsItemsData = basement.SumInsured
			}
		}
	}

	// extract singleItem data
	const checkSingleItemAmt = SumInsData.sumInsItemsData.find(
		el => el.Name === 'MaxValSingleItem'
	)?.Amount
	if (checkSingleItemAmt) {
		SumInsData.singleItemData = { ...singleItemDefaults, Amount: checkSingleItemAmt }
	}

	// add missing key value pairs w/ their defaults
	// and remove duplicates
	SumInsData.sumInsItemsData = remDuplicatesArrObj(
		[...filteredList, ...SumInsData.sumInsItemsData],
		'Name'
	).filter(el => el.Name !== 'MaxValSingleItem')

	return SumInsData
}

function checkIfRented(selectedType, selectedLevelIdx, globalState) {
	const { Type, property, basement, levels, Ownership } = globalState?.location || {}

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'

	let allowed = true

	if (isProperty || isBasement) {
		if (levels) {
			const currentLvlData = levels[selectedLevelIdx] || {}
			allowed = currentLvlData?.Ownership !== 'RENTED'
		} else {
			allowed = Ownership !== 'RENTED'
		}
	} else {
		if (selectedType === 'property' && property) {
			if (property?.levels) {
				const currentLvlData = property?.levels[selectedLevelIdx] || {}
				allowed = currentLvlData?.Ownership !== 'RENTED'
			} else {
				allowed = property?.Ownership !== 'RENTED' // fixes the error of "RENTED" not hiding the value in property+basement
			}
		}
		if (selectedType === 'basement' && basement) {
			if (basement?.levels) {
				const currentLvlData = basement?.levels[selectedLevelIdx] || {}
				allowed = currentLvlData?.Ownership !== 'RENTED'
			} else {
				allowed = basement?.Ownership !== 'RENTED' // fixes the error of "RENTED" not hiding the value in property+basement
			}
		}
	}
	return allowed
}

const SumInsuredItem = ({
	icon,
	label,
	inputName,
	labelExplanation,
	setDataItem,
	value,
	disabled = false,
	error = false,
	ownershipValueAllowed = true,
	isSFSP,
}) => {
	// Building value, Basement value and Plinth & Foundation ===> isOwnershipValue
	let isOwnershipValue = null
	if (isSFSP) {
		isOwnershipValue =
			inputName === 'BuildingValue' ||
			inputName === 'BasementValue' ||
			inputName === 'PlinthFoundation'
	} else {
		isOwnershipValue = inputName === 'BuildingValue' || inputName === 'BasementValue'
	}
	const disableOwnershipField = isOwnershipValue && !ownershipValueAllowed
	const generatePlaceholder = disableOwnershipField ? 'Not Allowed' : 'Enter value here'
	const disableField = disabled || disableOwnershipField

	const renderLabelExplanation = labelExplanation ? (
		<Tooltip icon="knowMore" text={labelExplanation} className="ms-2" />
	) : null

	const handleNumberInput = (inputName, e) => {
		const numValue = convertToNumber(e.target.value)
		setDataItem(inputName, numValue)
	}

	const AdditionalBuildingInfo =
		!isSFSP && label === 'Building' ? '  (incl plinth & foundation)' : null

	return (
		<PropertyItemWrapper className="d-flex align-items-center">
			<Icon name={icon} className="me-3" />
			<PropertyItem className="py-3 w-100">
				<div className="d-flex align-items-center">
					<Text type="body1" style={{ letterSpacing: '0' }} mobileFontSize="14px">
						{label} {AdditionalBuildingInfo}
					</Text>
					{renderLabelExplanation}
				</div>
				<Space mobileY={0.5} />
				<CustomInput
					placeholder={generatePlaceholder}
					name={inputName}
					value={value}
					onChange={e => handleNumberInput(inputName, e)}
					disabled={disableField}
					error={error ?? ''}
					autoComma
					rupee
				/>
			</PropertyItem>
		</PropertyItemWrapper>
	)
}

const SumInsuredItems = ({
	onSave,
	isActiveTab,
	disableProceed,
	propertyType,
	levelIndex,
	onStockPresentToggle,
	isSFSPEdgeCase,
}) => {
	const [state] = useContext(Context)
	const { Type } = state.location || {}
	const isPropertybasement = Type === 'PROPERTYBASEMENT'

	const isSFSP =
		state.quote?.PolicyName === 'SFSP' || window.location.href.includes('sfsp-edgecase')
	const valuationMethods = state.quote?.ValuationMethodMap

	const { sumInsItemsData, singleItemData } = prefillSumInsured(
		filterItems(sumInsuredDefaults, propertyType, isSFSP),
		propertyType,
		levelIndex,
		state
	)

	const [sumInsuredItems, setSumInsuredItems] = useState(sumInsItemsData)
	const [singleItem, setSingleItem] = useState(singleItemData)

	const [showReinstatementModal, setShowReinstatementModal] = useState(false)

	const [isStockPresent, setIsStockPresent] = useState(() => {
		let checked = false
		if (isPropertybasement) {
			if (levelIndex === -1) {
				// no individual levels
				checked = state.location[propertyType]?.Stock?.Present
			} else {
				checked = state.location[propertyType]?.levels[levelIndex]?.Stock?.Present
			}
		} else {
			// only property || only basement
			if (levelIndex === -1) {
				// no individual levels
				checked = state.location?.Stock?.Present
			} else {
				checked = state.location?.levels[levelIndex]?.Stock?.Present
			}
		}
		return Boolean(checked)
	})

	// proceed disabling calculation
	const someInputsFilled = sumInsuredItems.some(el => el.Amount)

	const ValuebleContentsAmt =
		parseFloat(sumInsuredItems.find(el => el.Name === 'ValuableContents').Amount) || 0
	const OtherContentsAmt = parseFloat(sumInsuredItems.find(el => el.Name === 'Other').Amount) || 0
	const MaxValSingleItemAmt = parseFloat(singleItem.Amount) || 0

	const singleItemMandatory = MaxValSingleItemAmt || ValuebleContentsAmt || OtherContentsAmt

	const isMaxValSingleItemLess =
		ValuebleContentsAmt >= MaxValSingleItemAmt || OtherContentsAmt >= MaxValSingleItemAmt

	const maxSingleItemError = Boolean(
		singleItemMandatory && (!MaxValSingleItemAmt || !isMaxValSingleItemLess)
	)

	const proceedDisabled = Boolean(maxSingleItemError || (!isStockPresent && !someInputsFilled))

	const ownershipValueAllowed = checkIfRented(propertyType, levelIndex, state)

	useEffect(() => {
		const triggerSave = () => {
			const filteredPropertyItems = sumInsuredItems.filter(el => el.Amount)
			if (singleItem.Amount && filteredPropertyItems.length) {
				filteredPropertyItems.push(singleItem)
			}
			onSave({
				SumInsured: filteredPropertyItems,
			})
			if (isActiveTab) {
				disableProceed(proceedDisabled)
			}
		}
		triggerSave()
	}, [sumInsuredItems, singleItem, proceedDisabled, isActiveTab]) // eslint-disable-line

	const closeModal = () => setShowReinstatementModal(false)
	const toggleModal = () => setShowReinstatementModal(true)

	const setSingleItemAmt = (name, val) => {
		let updatedAmt = ''
		if (val || val === 0) {
			updatedAmt = parseFloat(val)
		}
		setSingleItem({ Name: name, Amount: updatedAmt })
	}

	const setSumInsuredItem = (name, amt) => {
		let updatedSumInsItems = []
		sumInsuredItems.forEach(el => {
			if (el.Name === name) {
				let updatedAmt = ''
				if (amt || amt === 0) {
					updatedAmt = parseFloat(amt)
				}
				updatedSumInsItems.push({
					Name: name,
					Amount: updatedAmt,
				})
			} else {
				const { Name, Amount } = el
				updatedSumInsItems.push({
					Name: Name,
					Amount: Amount,
				})
			}
		})
		setSumInsuredItems(updatedSumInsItems)
	}

	const getSumInsAmount = fieldName =>
		sumInsuredItems?.find(e => e.Name === fieldName)?.Amount || ''

	const alertSection = (
		<Alert className="mb-3">
			<Text color={theme.colors.secondary} fontWeight={700} mobileFontSize="14px">
				Please enter Reinstatment values for the below fields
				<span
					className="ms-3"
					style={{ borderBottom: `2px solid ${theme.colors.secondary2}`, cursor: 'pointer' }}
					onClick={toggleModal}
				>
					Learn more
				</span>
			</Text>
		</Alert>
	)

	const [showCustomSIFieldModal, setShowCustomSIFieldModal] = useState(false)
	const [customSIFields, setCustomSiFields] = useState(() => {
		const customFields =
			sumInsuredItems.filter(field => !predefinedSumInsuredFields.includes(field.Name)) || []
		return customFields
	})

	const handleAddCustomSIField = fieldName => {
		setShowCustomSIFieldModal(false)
		setCustomSiFields([...customSIFields, { Name: fieldName, Amount: '' }])
	}

	const renderSumInsuredItems = filterItems(
		sumInsuredOptions,
		propertyType,
		isSFSP,
		isSFSPEdgeCase
	).map(el => (
		<SumInsuredItem
			setDataItem={setSumInsuredItem}
			value={getSumInsAmount(el.inputName)}
			key={el.inputName}
			{...el}
			labelExplanation={isPropertybasement ? el.labelExplanation || '' : ''}
			ownershipValueAllowed={ownershipValueAllowed}
			isSFSP={isSFSP}
		/>
	))

	const renderCustomSIItems = customSIFields?.map(siField => {
		return (
			<SumInsuredItem
				icon="otherValuables"
				label={siField.Name}
				inputName={siField.Name}
				value={getSumInsAmount(siField.Name)}
				setDataItem={setSumInsuredItem}
				key={siField.Name}
				isSFSP={isSFSP}
			/>
		)
	})

	const maxValItem = isSFSPEdgeCase ? null : (
		<SumInsuredItem
			setDataItem={setSingleItemAmt}
			value={singleItem.Amount}
			key={singleItem.key}
			disabled={!singleItemMandatory}
			error={maxSingleItemError ?? ''}
			{...singleItemOps}
			isSFSP={isSFSP}
		/>
	)

	const onSwithToggle = val => {
		setIsStockPresent(val)
		onStockPresentToggle(val)
	}

	// edit stock details button
	let editStockBtn = isStockPresent ? (
		<>
			<Space y={1} />
			<Button
				label="Edit stock details"
				icon="editPencilSquare"
				iconColor={theme.colors.white}
				iconStyles={{ marginRight: '0.5rem' }}
				onClick={() => onStockPresentToggle(true)} // Sending true always since we are editing
			/>
		</>
	) : null

	const stockPresenceCheck = isSFSPEdgeCase ? null : (
		<StockPresenceCheckContainer className="d-flex justify-content-between align-items-center p-3 my-3">
			<div>
				<TitleText>Is there stock present at this risk location?</TitleText>
				<Space y={1} />
				<Text color={theme.colors.gray4}>
					This stock information is for the stock present at this building
				</Text>
				{editStockBtn}
			</div>
			<Switch onChange={onSwithToggle} value={isStockPresent} />
		</StockPresenceCheckContainer>
	)

	const maxValueAlert =
		!!MaxValSingleItemAmt && !!!ValuebleContentsAmt && !!!OtherContentsAmt ? (
			<Alert
				style={{ color: theme.colors.red }}
				title="'Valuable contents' or 'Other contents' cannot be blank"
			/>
		) : singleItemMandatory && !isMaxValSingleItemLess ? (
			<Alert
				style={{ color: theme.colors.red }}
				title="'Maximum value of a single item' cannot exceed the amount entered for 'Valuable Contents' or 'Other Content'"
			/>
		) : null

	return (
		<>
			<Divider top={0.5} bottom={1.5} />
			{stockPresenceCheck}
			{isSFSP ? null : alertSection}
			<Text
				style={{ fontSize: '18px', letterSpacing: '0.05em', fontWeight: 700, padding: '1rem 0' }}
				color={theme.colors.secondary}
			>
				STRUCTURE & CONTENTS
			</Text>
			{renderSumInsuredItems}
			{maxValItem}
			{maxValueAlert}
			{renderCustomSIItems}

			<Button
				icon="add"
				className="p-0 mt-1 mb-2"
				iconStyles={{ marginRight: '.5rem' }}
				label="Add Other/Valuable Content"
				bgColor="transparent"
				textColor={theme.colors.primary}
				onClick={() => setShowCustomSIFieldModal(true)}
			/>

			<Modal
				show={showReinstatementModal}
				handleClose={closeModal}
				title={ReinstatementModalTitle}
				footer={<ReinstatementModalFooter closeModal={closeModal} />}
			>
				<ReinstatementModalContent />
			</Modal>

			{showCustomSIFieldModal && (
				<AddCustomSIFieldModal
					handleClose={() => setShowCustomSIFieldModal(false)}
					onAddSIField={handleAddCustomSIField}
				/>
			)}
		</>
	)
}

const PropertyItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
	}
`
const PropertyItemWrapper = styled.div`
	&:not(:last-of-type) {
		border-bottom: 0.5px solid ${theme.colors.gray6};
	}
`

const CustomInput = styled(Input)`
	width: 300px;
	@media (max-width: 920px) {
		width: 150px;
	}
	@media (max-width: 768px) {
		width: 100%;
		flex: 1;
	}
`
const StockPresenceCheckContainer = styled.div`
	border: 1px solid ${theme.colors.secondary2};
	border-radius: 8px;
`

const TitleText = styled(Text)`
	font-weight: 700;
	font-size: 24px;
	color: ${theme.colors.gray7};
	line-height: 30.24px;
`

export default SumInsuredItems
