import { useState } from 'react'
import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	theme,
	Text,
	Button,
	Icon,
	Input,
	Modal,
} from 'verak-ui'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import TableComponent from '../../components/Table'
import { HeaderContent } from '../SQS/QuoteInProgressComponents/quoteInProgressUtils'
import { getDateString } from '../../utilities/sqsDateUtils'
import { getRedirectLink, checkIfRenewalsFlow } from '../../utilities/renewalsUtils'

const tableHeaderItems = [
	{
		key: 'contactName',
		content: <HeaderContent icon="userIcon" text="Client Name" />,
		width: '200px',
	},
	{
		key: 'accountName',
		content: <HeaderContent icon="buildingSm" text="Legal Entity Name" />,
		width: '250px',
	},
	{ key: 'uid', content: <HeaderContent icon="" text="UID" /> },
	{ key: 'phone', content: <HeaderContent icon="phoneSm" text="Phone" /> },
	{ key: 'savedAt', content: <HeaderContent icon="lastUpdatedSm" text="Last Saved" /> },
	{
		key: 'actionItems',
		content: <HeaderContent icon="" text={<div style={{ width: '80' }}></div>} />,
	},
]

const MODAL_MODES = {
	OPEN: 'OPEN',
	DELETE: 'DELETE',
}

export default function ViewSavedDrafts() {
	const isRenewalsFlow = checkIfRenewalsFlow()
	const [searchQuery, setSearchQuery] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [modalMode, setModalMode] = useState('')
	const [selectedDraftKey, setSelectedDraftKey] = useState('')

	const history = useHistory()

	const navLeftContent = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Saved Draft RFQs
		</Text>
	)

	const handleSearch = e => {
		setSearchQuery(e.target.value)
	}

	const getDraftItem = draftKey => {
		let draft = JSON.parse(localStorage.getItem(draftKey))
		draft = { ...draft, draftKey }
		return draft
	}

	const openModal = (mode, draftKey) => {
		setModalMode(mode)
		setSelectedDraftKey(draftKey)
		setShowModal(true)
	}

	const getTableItem = draftData => {
		const { Account, Contact, timestamp, draftKey } = draftData
		return {
			key: draftKey,
			contactName: Contact?.Name,
			accountName: Account?.Name,
			phone: Contact?.Phone,
			uid: Contact?.UID,
			savedAt: timestamp ? getDateString(timestamp) : '',
			actionItems: (
				<div className="d-flex align-items-center">
					<Icon
						color={theme.colors.red}
						name="deleteTrash"
						style={{ cursor: 'pointer' }}
						onClick={() => openModal(MODAL_MODES.DELETE, draftKey)}
					/>
					<Button
						label="Open Draft"
						bgColor={theme.colors.white}
						textColor={theme.colors.secondary}
						iconColor={theme.colors.secondary}
						icon="rightArrow"
						iconAlign="right"
						onClick={() => openModal(MODAL_MODES.OPEN, draftKey)}
					/>
				</div>
			),
		}
	}

	const draftSessionKeyPrefix = isRenewalsFlow ? 'session_renewals_' : 'session_'
	const savedDraftsKeys = Object.keys(localStorage).filter(key =>
		key.includes(draftSessionKeyPrefix)
	)
	const savedDraftItems = savedDraftsKeys
		?.map(draftKey => getDraftItem(draftKey))
		.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp))

	let savedDraftTableItems
	if (searchQuery !== '') {
		savedDraftTableItems = savedDraftItems.filter(draft => {
			const { Account, Contact } = draft
			const { Name: accountName } = Account
			const { Name: contactName, UID: contactUID, Phone } = Contact
			return (
				accountName.toLowerCase().includes(searchQuery.toLowerCase()) ||
				contactName.toLowerCase().includes(searchQuery.toLowerCase()) ||
				contactUID.toString().includes(searchQuery) ||
				Phone.toString().includes(searchQuery)
			)
		})
	} else {
		savedDraftTableItems = savedDraftItems
	}

	let savedDraftsContainer
	if (savedDraftTableItems.length > 0) {
		savedDraftTableItems = savedDraftTableItems.map(draft => getTableItem(draft))
		savedDraftsContainer = (
			<TableComponent
				hideOnMobileIndex={2}
				tableHeaderItems={tableHeaderItems}
				tableDataItems={savedDraftTableItems || []}
				tableDataRowCls="draft-rfq-row-item"
			/>
		)
	} else {
		savedDraftsContainer = (
			<Text color={theme.colors.secondary} fontSize="16px" fontWeight="600">
				No saved drafts
			</Text>
		)
	}

	const openSavedDraft = () => {
		const draft = getDraftItem(selectedDraftKey)
		history.push(getRedirectLink(isRenewalsFlow, '/new-draft'), {
			...draft,
			_id: draft?._id || '',
		})
		setShowModal(false)
	}

	const deleteSavedDraft = () => {
		localStorage.removeItem(selectedDraftKey)
		setShowModal(false)
	}

	// action modal
	let modalTitle, modalContent
	if (showModal) {
		if (modalMode === MODAL_MODES.OPEN) {
			modalTitle = 'Open Draft'
			modalContent = (
				<div className="d-flex flex-column align-items-center">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Are you sure you want to open this draft?
					</Text>
					<Button
						className="mt-3 w-100"
						label="Open Draft"
						bgColor={theme.colors.primary}
						textColor={theme.colors.white}
						icon="rightArrow"
						iconColor={theme.colors.white}
						iconAlign="right"
						style={{ height: '48px' }}
						onClick={openSavedDraft}
					/>
				</div>
			)
		} else if (modalMode === MODAL_MODES.DELETE) {
			modalTitle = 'Delete Draft'
			modalContent = (
				<div className="d-flex flex-column align-items-center">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Are you sure you want to delete this draft? <br />
						This action cannot be undone.
					</Text>
					<Button
						className="mt-3 w-100"
						label="DELETE"
						bgColor={theme.colors.red}
						textColor={theme.colors.white}
						style={{ height: '48px' }}
						icon="deleteTrash"
						iconColor={theme.colors.white}
						iconAlign="left"
						iconSize="18px"
						iconStyles={{ marginRight: '10px' }}
						onClick={deleteSavedDraft}
					/>
				</div>
			)
		}
	}

	return (
		<DPageContentAndNavbarWrapper>
			<SecondaryNavbar headerBg="white" navbarNumber={1} leftContent={navLeftContent} />

			<div className="container p-3 mb-5">
				<Section className="d-flex justify-content-start align-items-center my-1 mb-3">
					<FullWidth>
						<CustomInput
							value={searchQuery}
							onChange={handleSearch}
							placeholder="Search by Proposer name, Legal entity name, UID or Phone"
						/>
					</FullWidth>
				</Section>

				{savedDraftsContainer}
			</div>

			<Modal
				show={showModal}
				handleClose={() => {
					setShowModal(false)
					setModalMode('')
					setSelectedDraftKey('')
				}}
				title={
					<Text color={theme.colors.primary} fontSize="16px" fontWeight="700">
						{modalTitle}
					</Text>
				}
				footer={<></>}
			>
				{modalContent}
			</Modal>
		</DPageContentAndNavbarWrapper>
	)
}

const FullWidth = styled.div`
	width: 100% !important;
`

const CustomInput = styled(Input)`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: none;
	width: 100%;
	&:focus {
		border: none;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	}
`

const Section = styled.div`
	@media (max-width: 768px) {
		flex-direction: column !important;
		align-items: flex-start !important;
		margin-top: 0.1rem !important;
		margin-bottom: 0.5rem !important;
	}
`

const DPageContentAndNavbarWrapper = styled(PageContentAndNavbarWrapper)`
	tr.draft-rfq-row-item {
		cursor: default;
		&:hover {
			background: #fff;
		}
	}
`
