import styled from 'styled-components'
import { Select, theme, Radio } from 'verak-ui'

const primaryPropertyTypes = [
	'Manufacturing or other Industrial Property',
	'Dwellings, Office, Shop or Hotel',
	'Utility located outside of manufacturing unit',
	'Storage facility outside of manufacturing unit',
	'Tank or gas storage facility outside of manufacturing unit',
]

const subPropertyTypes = [
	{ name: 'Abrasive Manufacturing', value: 'Abrasive Manufacturing' },
	{ name: 'Agarbatti manufacturing', value: 'Agarbatti manufacturing' },
	{ name: 'Aircraft Hangers', value: 'Aircraft Hangers' },
	{ name: 'Aerated Water Factories', value: 'Aerated Water Factories' },
]

const CustomFlexHolder = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const CustomSelect = styled(Select)`
	width: 390px;
	height: 48px;

	@media (max-width: 768px) {
		width: 100%;
		background: white !important;
	}
`

const CustomSelectSingle = styled(Select)`
	width: 250px;
	height: 48px;

	@media (max-width: 768px) {
		width: 100%;
		background: white !important;
	}
`

const singleSelectStyle = {
	width: '440px',
	height: '48px',
	'@media(max-width: 768px)': {
		width: '100% !important',
		backgroundColor: 'white',
	},
}

const RadioHolder = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const CustomRadio = styled(Radio)`
	.radio__label {
		color: ${theme.colors.secondary};
		font-weight: bold;
		cursor: pointer;
	}
	@media (max-width: 768px) {
		.radio__label {
			font-size: 14px;
		}
		.radio-before {
			margin: 0.8rem 0;
		}
	}
`

export {
	primaryPropertyTypes,
	subPropertyTypes,
	CustomFlexHolder,
	CustomSelectSingle,
	CustomSelect,
	singleSelectStyle,
	RadioHolder,
	CustomRadio,
}
