import { useContext, useEffect } from 'react'
import { useState } from 'react'

import { Checkbox, Space } from 'verak-ui'
import { Context } from '../../../../context/Provider'
import { filterArrObj } from '../../../../helpers/arrayOps'
import { FinancialInsTypeOps, hypothecationPartyDefaults, capitalize } from './options'
import { RenderRemoveButton, SpecifyMoreDetails } from './SharedComponents'
import { CustomInput, CustomSelect, GridContainer, SharedInputStyles } from './SharedStyles'

function initHypothecationData(state, levelIdx, propertyType) {
	const { Type, levels, Hypothecation } = state.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let HypothecationData = Hypothecation,
		levelsData = levels

	if (isPropertyBasement) {
		HypothecationData = state?.location?.[propertyType]?.Hypothecation || []
		levelsData = state?.location?.[propertyType]?.levels || []
	}
	const isMultiple = levelsData?.length
	if (isMultiple) {
		HypothecationData = levelsData[levelIdx]?.Hypothecation || []
	}
	return HypothecationData?.length ? [...HypothecationData] : []
}

function getFirstLevelHypothecation(state) {
	const { Type, levels, Hypothecation } = state?.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let HypothecationData = Hypothecation,
		levelsData = levels

	if (isPropertyBasement) {
		HypothecationData = state?.location?.property?.Hypothecation || []
		levelsData = state?.location?.property?.levels || []
	}
	const isMultiple = levelsData?.length
	if (isMultiple) {
		HypothecationData = levelsData[0]?.Hypothecation || []
	}
	return HypothecationData?.length ? HypothecationData : []
}

function hypoSameAsFirstLevel(firstLvlData, currentLvlData) {
	if (firstLvlData.length === 0 && currentLvlData.length === 0) {
		return false
	}
	const sameAsFirstLevel = currentLvlData.every(current => {
		const sameItemFoundIndex = firstLvlData.findIndex(first => {
			const isNameMatching = first.Name === current.Name
			const isFinMatching = first.Type === current.Type

			return isNameMatching && isFinMatching
		})
		return sameItemFoundIndex > -1
	})

	if (sameAsFirstLevel || currentLvlData.length === 0) {
		return true
	}
	return false
}

function getSameAsLevelLabel(state, propertyType) {
	const { Type, property, levels } = state.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let level = ''
	let sameAsType = propertyType

	if (isPropertyBasement) {
		sameAsType = 'Building'
		if (property?.levels?.length) {
			level = property?.levels?.[0]?.Level || ''
		} else {
			level = property?.Level || ''
		}
	} else {
		if (Type === 'PROPERTY') {
			sameAsType = 'Building'
		}
		if (levels?.length) {
			level = levels?.[0]?.Level || ''
		} else {
			level = property?.Level || ''
		}
	}

	return level ? `Same as ${sameAsType} Level ${level}` : `Same as ${sameAsType}`
}

const Hypothecation = ({ prefillHypothecation, onSave, levelIdx, propertyType }) => {
	const [state] = useContext(Context)
	const { Type } = state?.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	const firstLevelHypothecation = getFirstLevelHypothecation(state)
	const currentLevelHypothecation = initHypothecationData(state, levelIdx, propertyType)

	let showCheckbox = !!prefillHypothecation
	if (showCheckbox) {
		if (firstLevelHypothecation.length > 0) {
			showCheckbox = true
		} else {
			showCheckbox = false
		}
	}

	const isHypoSameAsFirstLvl = hypoSameAsFirstLevel(
		firstLevelHypothecation,
		currentLevelHypothecation
	)

	const [sameAsFirstLvl, setSameAsFirstLvl] = useState(() => showCheckbox && isHypoSameAsFirstLvl)

	const [hypothecation, setHypothecation] = useState(() => {
		let hypothecationData = []
		if (showCheckbox && isHypoSameAsFirstLvl) {
			hypothecationData = firstLevelHypothecation
		} else {
			hypothecationData = currentLevelHypothecation
		}
		// to show an extra input field w/ default empty values
		return [...hypothecationData, { ...hypothecationPartyDefaults }]
	})

	useEffect(() => {
		setSameAsFirstLvl(showCheckbox && isHypoSameAsFirstLvl)
	}, [showCheckbox, isHypoSameAsFirstLvl])

	useEffect(() => {
		if (showCheckbox && sameAsFirstLvl) {
			triggerSave(firstLevelHypothecation)
		}
	}, [])

	const addNewParty = () => {
		const list = [...hypothecation, { ...hypothecationPartyDefaults }]
		setHypothecation(list)
		triggerSave(list)
	}

	const removeParty = index => {
		let list = [...hypothecation]
		list.splice(index, 1)
		setHypothecation(list)
		triggerSave(list)
	}

	const setHypothecationItem = (index, name, value) => {
		let alteredItems = [...hypothecation]
		alteredItems[index][name] = value
		setHypothecation(alteredItems)
		triggerSave(alteredItems)
	}

	const checkInputs = list => {
		let isValid = Boolean(list?.length)

		list?.forEach(item => {
			if (isValid) {
				isValid = !!item.Name && !!item.Type
			}
		})
		return isValid
	}

	const triggerSave = list => {
		const isValid = checkInputs(list)
		if (isValid) {
			onSave({
				Hypothecation: filterArrObj(list, ['id']),
			})
		}
	}

	const FinancialInstitutesMap = FinancialInsTypeOps.map(el => {
		return (
			<option key={el} value={el?.toUpperCase()}>
				{el}
			</option>
		)
	})

	const renderPartySections = hypothecation.map((el, index) => (
		<GridContainer key={index} removeButtonCol={hypothecation.length > 1} className="mt-3">
			<CustomInput
				type="text"
				label=""
				placeholder="Enter party name here"
				value={el.Name}
				onChange={e => setHypothecationItem(index, 'Name', e.target.value)}
			/>
			<div>
				<CustomSelect
					style={SharedInputStyles}
					// selected={el.Type}
					value={el.Type}
					onChange={val => setHypothecationItem(index, 'Type', val?.toUpperCase())}
					placeholder="Financial Institution  / Other"
				>
					{FinancialInstitutesMap}
				</CustomSelect>
			</div>
			<RenderRemoveButton
				onClick={() => removeParty(index)}
				renderWhen={hypothecation.length > 1}
			/>
		</GridContainer>
	))

	const copyValuesFromFirstLevel = () => {
		// get values from first level and save
		let toSave
		if (!sameAsFirstLvl) {
			const firstLevelHypothecation = getFirstLevelHypothecation(state)
			toSave = firstLevelHypothecation
			triggerSave([...toSave])
		} else {
			// reset to default
			toSave = [{ ...hypothecationPartyDefaults }]
			onSave({
				Hypothecation: [],
			})
		}
		setHypothecation([...toSave])
		setSameAsFirstLvl(val => !val)
	}

	const renderCheckBox = showCheckbox ? (
		<Checkbox
			value={sameAsFirstLvl}
			onChange={copyValuesFromFirstLevel}
			text={getSameAsLevelLabel(state, propertyType)}
		/>
	) : null

	const renderHypothecationInput = !sameAsFirstLvl ? (
		<SpecifyMoreDetails
			title={`List any other parties that have an insurable interest in this ${propertyType}`}
			renderDetailItems={renderPartySections}
			addNewDetailItem={addNewParty}
			addItemButtonLabel="Add another party"
			divider={false}
		/>
	) : null

	return (
		<>
			{renderCheckBox}
			<Space y={1} />
			{renderHypothecationInput}
		</>
	)
}
export default Hypothecation
