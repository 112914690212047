import { useAuth0 } from '@auth0/auth0-react'
import { Appbar, Text, Tooltip, theme, Tabbar } from 'verak-ui'
import { useContextApi } from '../context/Provider'
import styled, { keyframes } from 'styled-components'
import { useEffect, useMemo, useState } from 'react'
import { useFetch } from '../api/apihook'
import { useHistory } from 'react-router'
import EditLostWarningModal from './EditLostWarningModal'
import { getDateString } from '../utilities/sqsDateUtils'
import { isRfqEqual, editRfqAllowedStatuses } from '../helpers/editRfqUtils'

const cheatSheetBannerAnim = keyframes`
	0% {
		content: '';
		max-width: 0;
	}
	40% {
		content: 'CHEAT SHEET DEKHO';
		max-width: 50%;
	}
	90% {
		content: 'CHEAT SHEET DEKHO AUR SALE CLOSE KARO';
		max-width: 100%;
	}
`

const BannerContainer = styled.div`
	background: #feeaa6e5;
	padding: 0.25rem 0.5rem;
	border: 1px solid #e1b566;
	border-radius: 0.5rem;
	@media (min-width: 816px) {
		span::before {
			content: '';
			max-width: 0;
			animation: ${cheatSheetBannerAnim} 5s ease-in-out infinite;
			animation-delay: 400ms;
			transition: max-width 0.5s ease-in-out;
		}
	}
`

const cheatSheetBanner = (
	<BannerContainer>
		<Text fontSize="14px" fontWeight="700" color="#9B4800">
			🤑 <span></span>
		</Text>
	</BannerContainer>
)

const NotificationPanelWrapper = styled.div`
	max-height: 600px;
	width: 337px;
	overflow-y: auto;
	text-align: start;
`

const NotificationItemDiv = styled.div`
	border-bottom: solid 1px ${theme.colors.gray3};
	background: ${props => (!props.$seen ? '#DFEDFF' : '')};
	cursor: pointer;
`

const NewNotificationDot = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background: #4f76e8;
	flex-shrink: 0;
`

const NotificationCounter = styled.span`
	text-align: center;
	min-width: 34px;
	height: auto;
	padding: 0.25rem 0.5rem;
	color: ${theme.colors.white};
	background-color: ${p => (p.count ? theme.colors.secondary : '#B3B3B3')};
	border: 1px solid ${p => (p.count ? '#AC2519' : '#999999')};
	border-radius: 0.5rem;
	font-weight: 700;
`

const TabNotificationWrapper = styled.div`
	.nav-link {
		font-weight: 700;
		color: #8d8d8d;
	}
	.nav-link.active {
		border-color: #ffffff #ffffff #4f76e8;
		color: #4f76e8;
	}
	.nav-link:hover {
		border-color: #ffffff #ffffff #4f76e8;
	}
`

const NotificationItem = ({
	eventId,
	nItem,
	navigateToRfq,
	navigateToDraftRfq,
	markNotificationAsRead,
}) => {
	const { ClientName, Phone, RfqId, Budget } = nItem.data
	const { seen, createdAt } = nItem

	const timeElapsedStr = () => {
		const minutesElapsed = Math.floor((new Date() - new Date(createdAt)) / (1000 * 60))
		let hoursElapsed = ''
		let daysElapsed = ''
		let date = ''
		if (minutesElapsed > 59) {
			hoursElapsed = Math.floor(minutesElapsed / 60)
		}
		if (hoursElapsed > 23) {
			daysElapsed = Math.floor(hoursElapsed / 24)
		}
		if (daysElapsed > 2) {
			date = getDateString(createdAt)?.split(',')?.[0] || ''
		}

		if (date) {
			return date
		} else if (daysElapsed) {
			return `${daysElapsed} ${daysElapsed > 1 ? 'days' : 'day'} ago`
		} else if (hoursElapsed) {
			return `${hoursElapsed} ${hoursElapsed > 1 ? 'hours' : 'hour'} ago`
		}

		return `${minutesElapsed} ${minutesElapsed > 1 ? 'mins' : 'min'} ago`
	}

	let headerText, bottomContent
	if (nItem.name == 'TARGET_PREMIUM_RECEIVED') {
		headerText = (
			<Text fontWeight={600} color={theme.colors.secondary} fontSize="14px" className="">
				Budget/Target premium request has been received
				<Text color={theme.colors.gray7} className="ms-1" style={{ display: 'inline' }}>
					from the customer, please call them ☎
				</Text>
			</Text>
		)
		bottomContent = (
			<>
				<div className="d-flex mb-3">
					<div className="flex-fill">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Client Name :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{ClientName}
						</Text>
					</div>
					<div className="flex-fill d-flex flex-column align-items-end">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Contact No :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{Phone}
						</Text>
					</div>
				</div>

				<div className="mb-3">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						RFQ ID:
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{RfqId}
					</Text>
				</div>
				<div className="mb-3">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						Customer’s Budget :
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{Budget}
					</Text>
				</div>
			</>
		)
	} else if (nItem.name == 'TARGET_PREMIUM_REQUEST') {
		headerText = (
			<Text fontWeight={600} color={theme.colors.gray7} fontSize="14px" className="">
				Client has been
				<Text color={'#6A11DE'} className="ms-1" style={{ display: 'inline' }}>
					notified to avail Budget/Target Premium reduction
				</Text>
			</Text>
		)
		bottomContent = (
			<>
				<div className="d-flex mb-3">
					<div className="flex-fill">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Client Name :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{ClientName}
						</Text>
					</div>
					<div className="flex-fill d-flex flex-column align-items-end">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Contact No :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{Phone}
						</Text>
					</div>
				</div>

				<div className="mb-3">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						RFQ ID:
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{RfqId}
					</Text>
				</div>
			</>
		)
	} else if (nItem.name == 'INSTALLMENTS_AVAILABLE') {
		headerText = (
			<Text fontWeight={600} color={theme.colors.gray7} fontSize="14px" className="">
				RFQ has
				<Text color={'#EA4892'} className="ms-1" style={{ display: 'inline' }}>
					Instalments available
				</Text>
			</Text>
		)
		bottomContent = (
			<>
				<div className="d-flex mb-3">
					<div className="flex-fill">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Client Name :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{ClientName}
						</Text>
					</div>
					<div className="flex-fill d-flex flex-column align-items-end">
						<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
							Contact No :{' '}
						</Text>
						<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
							{Phone}
						</Text>
					</div>
				</div>

				<div className="mb-3">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						RFQ ID:
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{RfqId}
					</Text>
				</div>
			</>
		)
	} else if (nItem.name == 'WA_ADDRESS_RECEIVED') {
		headerText = (
			<Text fontWeight={600} color={theme.colors.gray7} fontSize="14px" className="">
				Customer has
				<Text color={'#57BA70'} className="ms-1" style={{ display: 'inline' }}>
					entered the risk location address
				</Text>
			</Text>
		)
		bottomContent = (
			<div className="d-flex mb-3">
				<div className="flex-fill">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						Client Name :{' '}
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{ClientName}
					</Text>
				</div>
				<div className="flex-fill d-flex flex-column align-items-end">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						Contact No :{' '}
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{Phone}
					</Text>
				</div>
			</div>
		)
	} else if (nItem.name == 'DRAFTRFQ_DETAILS_RECEIVED') {
		headerText = (
			<Text fontWeight={600} color={theme.colors.gray7} fontSize="14px" className="">
				<Text color={'#9A5A41'} className="me-1" style={{ display: 'inline' }}>
					Client has sent their details
				</Text>
				on Whatsapp
			</Text>
		)
		bottomContent = (
			<div className="d-flex mb-3">
				<div className="flex-fill">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						Client Name :{' '}
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{ClientName}
					</Text>
				</div>
				<div className="flex-fill d-flex flex-column align-items-end">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						Contact No :{' '}
					</Text>
					<Text fontWeight={700} color={theme.colors.gray7} fontSize="14px">
						{Phone}
					</Text>
				</div>
			</div>
		)
	}

	const clickHandler = () => {
		if (nItem?.data?.type == 'DraftRfq' || nItem?.name == 'DRAFTRFQ_DETAILS_RECEIVED') {
			if (nItem.data.id) {
				navigateToDraftRfq(eventId, nItem.data.id, seen, nItem.data.url)
			}
		} else {
			navigateToRfq(eventId, RfqId, ClientName, seen)
		}
	}

	const handleMarkAsReadClick = e => {
		e.stopPropagation()
		markNotificationAsRead(eventId)
	}

	return (
		<NotificationItemDiv className="px-3 py-3" $seen={seen} onClick={clickHandler}>
			<div className="d-flex align-items-center">
				<div className="flex-fill">{headerText}</div>
				{!seen && (
					<div className="d-flex flex-column align-items-end">
						<Text
							fontWeight={700}
							color="#4F76E8"
							fontSize="10px"
							className="mb-2"
							style={{ width: '55px' }}
							onClick={handleMarkAsReadClick}
						>
							Mark Read
						</Text>
						<NewNotificationDot className="me-1" />
					</div>
				)}
			</div>
			<div className="d-flex align-items-center">
				<Text fontWeight={700} color={theme.colors.gray3} fontSize="10px" className="mb-3">
					{timeElapsedStr()}
				</Text>
			</div>
			{bottomContent}
		</NotificationItemDiv>
	)
}

const AppbarWrapper = () => {
	const [state, dispatch] = useContextApi()
	const [events, setEvents] = useState([])
	const [newEvents, setNewEvents] = useState(false)
	const {
		getNotifications,
		registerEventListener,
		markNotificationAsSeen,
		getDraftRFQ,
		markAllNotificationsAsSeen,
	} = useFetch()
	const { user, logout, isAuthenticated } = useAuth0()
	const [activeTab, setActiveTab] = useState('all') // unread

	const [openNotifTray, setOpenNotifTray] = useState(false)
	const [showEditWarningModal, setShowWarningModal] = useState(false)

	const history = useHistory()

	const closeWarningModal = () => {
		setShowWarningModal(false)
	}

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	const handleHomeClick = () => {
		const validRFQStatus = editRfqAllowedStatuses.includes(rfqStatus)
		if (validRFQStatus && state?.rfq?.editRfqMode && !isRfqEqual(state?.quote, state?.oldRfq)) {
			setShowWarningModal(true)
		} else {
			clearGlobalStateAndRedirect()
		}
	}

	const clearGlobalStateAndRedirect = () => {
		dispatch({ type: 'CLEAR_DATA' })
		history.push('/')
	}

	const triggerLogout = async () => {
		await new Promise((resolve, reject) => {
			clearGlobalStateAndRedirect()
			resolve()
		})
		logout({
			returnTo: `${window.location.origin}/logout`,
		})
	}

	useEffect(() => {
		getNotifications().then(nList => {
			// filter out duplicate entry
			const eventList = [...nList.events]
			// .filter((i) => {
			// 	return i.name !== "TARGET_PREMIUM_RECEIVED"
			// })
			let notificationEnabled = false
			let uniqueEvents = []
			eventList.forEach(event => {
				const index = uniqueEvents.findIndex(e => e.data.RfqId == event.data.RfqId)
				if (index == -1) {
					uniqueEvents.push(event)
					if (!notificationEnabled && !event.seen) {
						notificationEnabled = true
					}
				}
			})

			setEvents(uniqueEvents)
			setNewEvents(notificationEnabled)
		})
	}, [])

	useEffect(() => {
		const onMessageReceived = (result, agentId) => {
			if (result.data !== 'connected') {
				const parsedData = JSON.parse(result.data)
				if (parsedData.agent == agentId) {
					setNewEvents(true)
					let eList = [...events]
					// find duplicate, move it to top
					const index = eList.findIndex(e => {
						return e.data.RfqId == parsedData.data.RfqId
					})
					if (index > -1) {
						eList.splice(index, 1)
					}

					setEvents(e => {
						return [{ ...parsedData, seen: false }, ...eList]
					})
				}
			}
		}

		const onMessageError = err => {
			// console.log("$$$$$ Error :: ",err)
		}
		let eventListener
		registerEventListener(onMessageReceived, onMessageError).then(ref => {
			eventListener = ref
		})

		return () => {
			if (eventListener) {
				eventListener.close()
			}
		}
	}, [events])

	// hide the notification tray when the user scrolls
	useEffect(() => {
		if (openNotifTray) {
			window.addEventListener('scroll', () => setOpenNotifTray(false))
		}

		return () => {
			window.removeEventListener('scroll', () => setOpenNotifTray(false))
		}
	}, [openNotifTray])

	const markNotificationAsSeenLocally = (eventId, rfqId) => {
		const eventList = [...events]
		const eIndex = eventList.findIndex(i => i._id == eventId)

		if (eIndex > -1) {
			eventList[eIndex].seen = true
			setEvents(eventList)
		}
	}

	const unseenNotifs = useMemo(() => {
		return events.filter(e => !e.seen)
	}, [events])

	const showCheatSheetBanner =
		isAuthenticated && window.location.pathname === '/rfq-status/quotes-ready/compare-quotes'

	const navigateToRfq = async (eventId, rfqId, ClientName, seen) => {
		// clear old rfq and reset rfq edit mode on notification click
		dispatch({ type: 'CLEAR_OLD_RFQ' })
		dispatch({
			type: 'SET_EDIT_RFQ',
			data: { editRfqMode: false },
		})

		dispatch({
			type: 'SET_RFQ_DATA',
			data: {
				id: rfqId || '',
			},
		})

		if (!seen) {
			// mark it as seen
			markNotificationAsSeenLocally(eventId)
			await markNotificationAsSeen(eventId)
		}

		history.push('/rfq-status/quotes-ready', {
			uid: rfqId,
			ClientName: ClientName,
			PolicyType: 'Vyapar Suraksha',
		})
	}

	const navigateToDraftRfq = async (eventId, draftRfqId, seen, chatbotUrl) => {
		if (!seen) {
			// mark it as seen
			markNotificationAsSeenLocally(eventId)
			await markNotificationAsSeen(eventId)
		}

		// navigate to draft rfq screen
		const result = await getDraftRFQ(draftRfqId)
		history.push('/new-draft', {
			...result,
			_id: draftRfqId,
			chatbotUrl: chatbotUrl ? chatbotUrl : '',
		})
	}

	const marNotificationAsRead = async eventId => {
		markNotificationAsSeenLocally(eventId)
		await markNotificationAsSeen(eventId, false)
	}

	const markAllNotificationsAsRead = async e => {
		e.stopPropagation()
		// mark all notifs as read locally
		const updatedEvents = events.map(event => ({ ...event, seen: true }))
		setEvents(updatedEvents)

		// API call to mark all events as seen
		await markAllNotificationsAsSeen()
	}

	const notificationPanel = type => {
		let filteredEvents = events

		if (type == 'unread') {
			filteredEvents = events.filter(i => {
				return !i.seen
			})
		}

		if (filteredEvents.length == 0) {
			return (
				<NotificationPanelWrapper className="p-3">
					<Text fontWeight={700} color={theme.colors.gray3} fontSize="14px">
						All clear
					</Text>
				</NotificationPanelWrapper>
			)
		}
		return (
			<NotificationPanelWrapper className="">
				{filteredEvents.map(nItem => (
					<NotificationItem
						key={nItem._id}
						eventId={nItem._id}
						nItem={nItem}
						navigateToRfq={navigateToRfq}
						navigateToDraftRfq={navigateToDraftRfq}
						markNotificationAsRead={marNotificationAsRead}
					/>
				))}
			</NotificationPanelWrapper>
		)
	}

	let unReadCount = events.filter(i => {
		return !i.seen
	}).length

	const tabList = [
		{
			id: 'all',
			title: 'All',
			content: notificationPanel('all'),
		},
		{
			id: 'unread',
			title: (
				<div className="d-flex" style={{ paddingBottom: '2px' }}>
					<Text fontWeight={700} style={{ color: 'inherit' }}>
						Unread
					</Text>
					<Text
						className="px-2 ms-2"
						style={{ background: theme.colors.red }}
						color={theme.colors.white}
						fontWeight="700"
					>
						{unReadCount}
					</Text>
				</div>
			),
			content: notificationPanel('unread'),
		},
	]

	const changeTab = (id, event) => {
		setActiveTab(id)
		event.preventDefault()
		event.stopPropagation()
	}

	const tabNotification = (
		<TabNotificationWrapper>
			{unReadCount ? (
				<Text
					role="button"
					fontWeight={700}
					color="#4F76E8"
					fontSize="10px"
					className="py-2 mt-1 me-4 position-absolute end-0 pe-auto"
					onClick={markAllNotificationsAsRead}
				>
					Mark All Read
				</Text>
			) : null}
			<Tabbar
				rounded={false}
				className=""
				tabList={tabList}
				activeTabId={activeTab}
				onChange={changeTab}
				style={{ border: 'none' }}
			></Tabbar>
		</TabNotificationWrapper>
	)

	const notificationIcon = (
		<Tooltip
			contentStyle={{ padding: '0' }}
			text=""
			html={tabNotification}
			position="bottom"
			theme="light"
			trigger="click"
			open={openNotifTray}
			onHidden={() => {
				setNewEvents(false)
			}}
			onRequestClose={() => setOpenNotifTray(false)}
		>
			<div className="d-flex position-relative" onClick={() => setOpenNotifTray(true)}>
				<NotificationCounter count={unseenNotifs?.length}>
					{unseenNotifs?.length}
				</NotificationCounter>
			</div>
		</Tooltip>
	)

	const navigateToAnalytics = () => {
		history.push('/analytics')
	}

	const appbarMenuItems = [
		{
			icon: 'logout',
			text: 'Logout',
			id: 'LOGOUT',
			onClick: triggerLogout,
		},
		{
			icon: 'analyticsLine',
			text: 'My Performance',
			id: 'analyticsLine',
			onClick: navigateToAnalytics,
		},
	]

	return (
		<>
			<Appbar
				onHomeRedirect={handleHomeClick}
				loggedIn={isAuthenticated}
				triggerLogout={triggerLogout}
				user={user}
				banner={showCheatSheetBanner && cheatSheetBanner}
				renderNotificationPanel={notificationIcon}
				appbarMenuItems={appbarMenuItems}
			/>
			<EditLostWarningModal showModal={showEditWarningModal} onCloseClick={closeWarningModal} />
		</>
	)
}

export default AppbarWrapper
