import cheatsheetMaterialIndex from '../CheatSheet/materialIndex'
import trainingMaterialIndex from '../Training/materialIndex'

const sections = {
	CHEATSHEET: {
		value: 'CHEATSHEET',
		contentIndex: cheatsheetMaterialIndex,
	},
	TRAINING: {
		value: 'TRAINING',
		contentIndex: trainingMaterialIndex,
	},
}

export { sections }
