import {
	ImageWithText,
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import Plus15Risk from '../../../../../assets/training/Plus15Risk.svg'
import LowPremium from '../../../../../assets/training/LowPremium.svg'
import Chori from '../../../../../assets/training/Chori.svg'
import Aag from '../../../../../assets/training/Aag.svg'
import Danga from '../../../../../assets/training/Danga.svg'
import Dakaiti from '../../../../../assets/training/Dakaiti.svg'
import Baadh from '../../../../../assets/training/Baadh.svg'
import Aatank from '../../../../../assets/training/Aatank.svg'

export default function CheatSheet_VyaparSuraksha() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={Plus15Risk}>
					<InfoText>
						<b>15+ durghatna ke nuksan se</b> suraksha. <b>Bada Risk aur Chota premium</b>
					</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={LowPremium}>
					<InfoText fontWeight="700">
						Low premium: <b>₹250 per ₹1 Lac. Give Daily rate using calculator</b>
					</InfoText>
				</InfoBox>
			</div>

			<HorizontalDivider />

			<div className="d-flex flex-column justify-content-center">
				<InfoText fontWeight="700" fontSize="16px" className="text-center" spaced>
					COVERS
				</InfoText>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Chori} text="चोरी" />
					<ImageWithText image={Aag} text="आग" />
					<ImageWithText image={Danga} text="दंगा" />
				</div>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Dakaiti} text="डकैती" />
					<ImageWithText image={Baadh} text="बाढ़" />
					<ImageWithText image={Aatank} text="आतंक" />
				</div>
			</div>
		</div>
	)
}
