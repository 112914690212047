import { Col, Row } from 'react-bootstrap'
import { Button, Text, Tooltip } from 'verak-ui'
import styled from 'styled-components'

import { InfoItem, SectionCard } from './commonStyle'
import {
	InsurerImagesMap,
	QuoteImg,
} from '../../SQS/RFQStatus/QuotesReady/ComparisonComponents/quoteComparisonUtils'

import { getFormattedAmount } from '../../../utilities/getFormattedAmount'

import { ReactComponent as InfoIcon } from '../../../assets/icons/infoIconSquare.svg'
import PreviousPolicyIcon from '../../../assets/icons/previousPolicy.svg'

function extractPolicyDetails(policyDetails) {
	const { Quote, CoverStartDate } = policyDetails || {}

	let endDate = ''
	if (CoverStartDate) {
		const [startDate, startMonth, startYear] = CoverStartDate.split('/')
		endDate = [startDate, startMonth, Number(startYear) + 1].join('/')
	}

	return {
		startDate: CoverStartDate || '',
		endDate: endDate || '',
		addons: Quote?.Addons?.join(', '),
		premium: getFormattedAmount(Quote?.TotalPremium || ''),
		wasDiscounted: Quote?.isPremiumDiscounted,
		insCompany: Quote?.Insurer,
		isCompanySupported: Quote?.isCalculatorSupported,
	}
}

function DiscountedPremium({ background = '#EBFEED', style = {} }) {
	return (
		<Text
			fontSize="13px"
			color="#4AA161"
			style={{ background, borderRadius: '6px', padding: '8px', ...style }}
		>
			This quote was discounted last time, please request for a new discount if the customer asks
			for it
		</Text>
	)
}

function CompanyNotSupported({ background = '#FEEEEB', color = '#AC2519' }) {
	return (
		<InsNotSupportedContainer background={background}>
			<InfoIcon style={{ color }} />
			<Text fontSize="13px" color={color} style={{ fontStyle: 'italic' }}>
				Our calculators no longer support this insurance company
			</Text>
		</InsNotSupportedContainer>
	)
}

function InfoRow({ title, data }) {
	return (
		<div className="mb-1 py-2" style={{ borderBottom: '1px solid #4C4C4C' }}>
			<Text fontSize="14px" fontWeight="700" className="mb-2" color="#B3B3B3">
				{title}
			</Text>
			<Text fontSize="16px" fontWeight="700" color="#fff">
				{data}
			</Text>
		</div>
	)
}

export function PreviousPolicyDetailsTooltip({ policyDetails }) {
	const { startDate, endDate, addons, premium, wasDiscounted, insCompany, isCompanySupported } =
		extractPolicyDetails(policyDetails || {})

	const tooltipContent = (
		<div style={{ textAlign: 'left' }}>
			<InfoRow title="Policy Period:" data={`${startDate} - ${endDate}`} />
			<InfoRow
				title="Premium Paid Last Time:"
				data={
					<>
						{`₹${premium}`}
						{wasDiscounted && (
							<DiscountedPremium style={{ padding: '0' }} background="transparent" />
						)}
					</>
				}
			/>
			<InfoRow title="Add Ons:" data={addons || '-'} />
			<InfoRow
				title="Insurance Company"
				data={
					<>
						<QuoteImg
							style={{ width: 'auto' }}
							src={InsurerImagesMap[insCompany]}
							alt={insCompany}
						/>
						<br />
						{insCompany}
						{isCompanySupported && <CompanyNotSupported background="transparent" color="#E29896" />}
					</>
				}
			/>
		</div>
	)

	return (
		<Tooltip
			style={{ width: '30px' }}
			theme="dark"
			trigger="click"
			position="bottom"
			html={tooltipContent}
		>
			<Button
				label={
					<>
						<img src={PreviousPolicyIcon} alt="previous policy" style={{ marginRight: '0.5rem' }} />
						Previous Policy Details
					</>
				}
				style={{ background: '#fff', color: '#4C4C4C', fontSize: '16px', width: 'max-content' }}
			/>
		</Tooltip>
	)
}

export function PreviousPolicyDetails({ policyDetails }) {
	const { startDate, endDate, addons, premium, wasDiscounted, insCompany, isCompanySupported } =
		extractPolicyDetails(policyDetails || {})

	return (
		<SectionCard className="mb-3">
			<div className="p-3">
				<Text className="mb-2" color={'#6467B0'} fontWeight="700">
					Previous Policy Details
				</Text>

				<Row className="mb-3">
					<Col md="3">
						<Row>
							<InfoItem label="Policy Period" value={`${startDate} - ${endDate}`} />
						</Row>
						<Row>
							<InfoItem label="Addons" value={addons || '-'} />
						</Row>
					</Col>
					<Col md="3">
						<QuoteImg
							style={{ width: 'auto' }}
							src={InsurerImagesMap[insCompany]}
							alt={insCompany}
						/>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md="3">
						<InfoItem
							label="Premium Paid Last time"
							value={premium ? `₹${getFormattedAmount(premium)}` : '-'}
						/>
					</Col>
					<Col md="3">
						<InfoItem label="Insurance Company" value={insCompany} />
					</Col>
				</Row>
				<Row className="mb-3">
					{wasDiscounted ? (
						<Col md="3">
							<DiscountedPremium />
						</Col>
					) : null}
					{isCompanySupported ? (
						<Col md="3">
							<CompanyNotSupported />
						</Col>
					) : null}
				</Row>
			</div>
		</SectionCard>
	)
}

const InsNotSupportedContainer = styled.div`
	display: flex;
	align-items: start;
	gap: 12px;
	background: ${p => p.background || '#fff'};
	border-radius: 6px;
	padding: 8px;
`
