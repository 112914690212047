import { theme, Text, Icon, Button, Modal } from 'verak-ui'
import styled from 'styled-components'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import { useHistory, useLocation } from 'react-router'
import { useContext, useState } from 'react'
import { Context } from '../../../../context/Provider'
import { useFetch } from '../../../../api/apihook'

const footerSectionStyle = {
	background: 'white',
	padding: '12px 24px',
	width: '100%',
	borderTop: '1.5px solid #02475E',
}

// MOCK DATA
// TODO: replace this with API call
const changesInRfq = [
	{
		id: 'F5jkNg4Gcu5EJDqENwd3oi',
		title: 'Occupancy change',
		category: 'Property details',
		beforeChange: 'Occupancy was Manufacturing',
		afterChange: 'Occupancy changed to Dwellings',
		confirmed: false,
	},
	{
		id: 'F5jkNg4GcuWEJD4ENwd3oi',
		title: 'Something change',
		category: 'Property details',
		beforeChange: 'Sum Insured was Rs.1,00,000',
		afterChange: 'Sum Insured changed to Rs.2,40,000',
		confirmed: false,
	},
	{
		id: 'F5jkNg4GcuWE6DqENwd3oi',
		title: 'Data change',
		category: 'Property details',
		beforeChange: 'Address was ABC street',
		afterChange: 'Address changed to XYZ street',
		confirmed: false,
	},
]

export default function ViewChangeList() {
	const history = useHistory()

	const { state } = useLocation()

	const [rfqChangeList, setRFQChangeList] = useState(changesInRfq)
	const [currentChangeItem, setCurrentChangeItem] = useState(null)

	const [showConfirmationModal, setShowConfirmationModal] = useState(false)

	const navText = `Overview, ${state?.uid} - ${state?.ClientName} - ${state?.PolicyType}`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	const navigateToOngoingRFQs = () => {
		history.push('/quotes-in-progress')
	}

	const changeListItems = rfqChangeList.map((changeItem, index) => {
		const isLast = index === rfqChangeList?.length - 1
		return (
			<ListItem
				key={`list-item-${index}`}
				title={changeItem.title}
				category={changeItem.category}
				onClickViewChange={() => {
					setCurrentChangeItem(changeItem)
				}}
				isLast={isLast}
				confirmed={changeItem.confirmed}
			/>
		)
	})

	const handleConfirm = id => {
		const changesCopy = [...rfqChangeList]

		const itemToChange = changesCopy.find(item => item.id === id)
		if (!!itemToChange) {
			itemToChange.confirmed = true
		}

		setRFQChangeList(changesCopy)
		setCurrentChangeItem(null)
	}

	let allChangesConfirmed = changesInRfq?.every(item => item.confirmed === true)

	let footerContent

	if (allChangesConfirmed) {
		footerContent = (
			<div
				className="d-flex justify-content-end align-items-center mt-2"
				style={footerSectionStyle}
			>
				<Button
					style={{ width: '240px', height: '48px' }}
					label="Confirm changes"
					icon="rightArrow"
					onClick={() => setShowConfirmationModal(true)}
					iconAlign="right"
				/>
			</div>
		)
	}

	// changelist
	const changeListSection = (
		<div
			style={{
				boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
				borderRadius: '8px',
				width: '95%',
			}}
			className="container h-100 bg-white p-3 mt-4 mx-2 d-flex flex-column"
		>
			<Text fontWeight="700" fontSize="24px" color={theme.colors.primary}>
				Our in-house Underwriter has corrected some issues in the RFQ. Please verify the changes
				made to proceed with the quote generation
			</Text>

			<Text className="mt-3" fontWeight="700" fontSize="18px" color={theme.colors.secondary}>
				If there are any issues in the changes made, click on the call icon to contact the
				Underwriter
			</Text>

			<div className="mt-3 d-flex flex-column">
				<Text color="#333333" fontSize="18px" fontWeight="400">
					List of changes made
				</Text>
				<CustomHR className="mb-3" />

				{changeListItems}
			</div>

			<CustomHR className="my-2 mb-3" />

			<Text className="pb-5" color="#333" fontSize="18px" fontWeight="700">
				Please verify all changes which had been made and confirm them on the next screen
			</Text>

			{footerContent}
		</div>
	)

	const activeSection =
		currentChangeItem === null ? (
			changeListSection
		) : (
			<ViewSingleChange
				changeItem={currentChangeItem}
				confirmChange={() => handleConfirm(currentChangeItem.id)}
			/>
		)

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<ConfirmationModal
				show={showConfirmationModal}
				handleClose={() => setShowConfirmationModal(false)}
				navigateToOngoingRFQs={navigateToOngoingRFQs}
			/>

			{activeSection}
		</PageContentAndNavbarWrapper>
	)
}

const ConfirmationModal = ({ show, handleClose, navigateToOngoingRFQs }) => {
	const titleElement = (
		<Text color={theme.colors.secondary} fontSize="14px" fontWeight="600">
			NOTICE
		</Text>
	)

	const footerElement = (
		<div className="p-3 pt-0 w-100">
			<Button onClick={navigateToOngoingRFQs} className="w-100 p-4" label="Continue" />
		</div>
	)
	return (
		<Modal
			closeButton={false}
			show={show}
			handleClose={handleClose}
			title={titleElement}
			footer={footerElement}
		>
			<div className="d-flex align-items-center flex-column">
				<Icon
					style={{ height: '100px', width: '100px' }}
					name="tickCircle"
					color={theme.colors.primary}
				/>

				<Text
					className="my-3"
					color={theme.colors.primary}
					fontSize="24px"
					fontWeight="700"
					style={{ textAlign: 'center' }}
				>
					The RFQ has been sent to the client for verification. You will be notified if the RFQ
					status changes.
				</Text>
			</div>
		</Modal>
	)
}

const ListItem = ({ title, category, onClickViewChange, confirmed, isLast }) => {
	const actionLinkProps = {
		color: confirmed ? theme.colors.green : theme.colors.primary,
		fontSize: '14px',
		fontWeight: '700',
	}

	let viewChangeContent
	if (confirmed) {
		viewChangeContent = (
			<>
				<Icon name="tickCircle" />
				<div className="mx-1"></div>
				<ViewChange {...actionLinkProps} style={{ textDecoration: 'none' }} onClick={() => {}}>
					Changes confirmed
				</ViewChange>
			</>
		)
	} else {
		viewChangeContent = (
			<>
				<ViewChange style={{ cursor: 'pointer' }} onClick={onClickViewChange} {...actionLinkProps}>
					View changes
				</ViewChange>
				<div className="mx-1"></div>
				<Icon name="arrowUpLink" />
			</>
		)
	}

	return (
		<ListItemWrapper isLast={isLast} className="d-flex align-items-center justify-content-between">
			<Text color="#333333" fontSize="18px" fontWeight="400">
				{title} - {category}
			</Text>

			<div className="d-flex align-items-center">{viewChangeContent}</div>
		</ListItemWrapper>
	)
}

const ChangeItem = ({ heading, content }) => {
	return (
		<div className="p-3 w-50">
			<Text
				style={{ paddingBottom: '16px', borderBottom: '1.5px solid rgba(2,71,94, .2)' }}
				color={heading === 'BEFORE' ? theme.colors.gray7 : theme.colors.secondary}
				fontSize="14px"
				fontWeight="700"
			>
				{heading}
			</Text>

			<Text
				style={{ paddingTop: '16px' }}
				color={heading === 'BEFORE' ? theme.colors.gray7 : theme.colors.secondary}
				fontSize="14px"
				fontWeight="400"
			>
				{content}
			</Text>
		</div>
	)
}

function ViewSingleChange({ changeItem, confirmChange }) {
	return (
		<div
			style={{
				boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
				borderRadius: '8px',
				width: '95%',
			}}
			className="container h-100 bg-white p-3 mt-4 mx-2 d-flex flex-column"
		>
			<Text fontWeight="700" fontSize="24px" color={theme.colors.primary}>
				Change made to: {changeItem?.title}
			</Text>

			<div className="d-flex mt-3 mb-5">
				<ChangeItem heading="BEFORE" content={changeItem?.beforeChange} />
				<ChangeItem heading="AFTER" content={changeItem?.afterChange} />
			</div>

			<div
				className="d-flex justify-content-end align-items-center mt-5"
				style={footerSectionStyle}
			>
				<Button
					style={{ width: '240px', height: '48px' }}
					label="Confirm change"
					icon="rightArrow"
					onClick={confirmChange}
					iconAlign="right"
				/>
			</div>
		</div>
	)
}

// styles

const ViewChange = styled(Text)`
	&:hover {
		text-decoration: underline;
	}
`

const ListItemWrapper = styled.div`
	padding: 10px 0px;
	border-bottom: ${({ isLast }) => !isLast && `1px solid rgba(136, 136, 136, .5)`};
`

const CustomHR = styled.div`
	height: 1.5px;
	width: 100%;
	background: ${theme.colors.primary};
	opacity: 0.2;
	margin-top: 0.5rem;
`
