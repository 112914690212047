export const appReducer = (state, action) => {
	switch (action.type) {
		case 'CLIENT_CREATED':
			return {
				...state,
				clientCreated: true,
			}
		case 'RESET_CLIENT':
			return {
				...state,
				clientCreated: false,
			}
		case 'SET_FOS_USER': {
			return {
				...state,
				isFosUser: true,
			}
		}
		case 'SET_INSTANT_QUOTE_OCCUPANCIES': {
			return {
				...state,
				instantQuoteOccupancies: action.data,
			}
		}
		case 'SET_HARDWARE_PRODUCTS': {
			return {
				...state,
				hardwareProducts: action.data,
			}
		}
		default:
			return state
	}
}
