import { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import { Icon, Tabbar, Text, theme, MainContainer } from 'verak-ui'
import { Context } from '../../../context/Provider'
import SumInsuredItems from './components/SumInsuredItems'
import { useFetch } from '../../../api/apihook'

const TitleText = styled(Text)`
	font-weight: 700;
	font-size: 24px;
	color: ${theme.colors.gray7};
	line-height: 30.24px;
`

const TabbarWrapper = styled.div`
	.nav-link {
		font-weight: 700;
	}
`

// KB* figure out how to check this
function genActiveId(levels, levelIndex, propertyType, tabData) {
	// handle dynamic active tab
	let tab = null
	let index = 0
	// from review edit details
	const { propertyType: tabDataPropertyType, levelNum } = tabData || {}

	if (propertyType) {
		if (propertyType === 'basement') {
			tab = 'Basement'
		} else if (propertyType === 'property') {
			tab = 'Property'
		}
		index = levelIndex
	}

	if (tabDataPropertyType) {
		return tabDataPropertyType
	} else if (levelNum) {
		return `level${levelNum}`
	} else if (levels) {
		return `level${levels[index].Level}`
	} else {
		return tab ? tab : 'Property'
	}
}

function initSumInsuredData(isBasement, isProperty, state, levels) {
	if (isBasement || isProperty) {
		if (levels) {
			return {
				levels: levels,
			}
		} else {
			// get location/other info from state.location
			return {}
		}
	} else {
		let basementData = {},
			propertyData = {}
		if (state.location?.basement?.levels) {
			basementData = {
				levels: state.location?.basement?.levels,
			}
		} else {
			// get location/other info from state.location.basement
			basementData = {}
		}

		if (state.location?.property?.levels) {
			propertyData = {
				levels: state.location?.property?.levels,
			}
		} else {
			// get location/other info from state.location.property
			propertyData = {}
		}
		return {
			basement: {
				...basementData,
			},
			property: {
				...propertyData,
			},
		}
	}
}

function initInnerBasementLevel(sumInsuredData, levelIndex, propertyType, tabData) {
	const { propertyType: tabDataPropertyType, levelNum } = tabData || {}

	if (tabDataPropertyType === 'Basement' && levelNum) {
		return `level${levelNum}`
	}

	// dynamically moving to the correct level while coming back to the screen
	let index = 0
	if (propertyType === 'basement') {
		index = levelIndex
	}

	let basementLevel = ''
	if (
		(sumInsuredData.basement &&
			sumInsuredData.basement?.levels &&
			sumInsuredData.basement?.levels[index].Level) ||
		sumInsuredData?.levels?.[index].Level
	) {
		basementLevel =
			sumInsuredData?.basement?.levels[index]?.Level || sumInsuredData?.levels?.[index].Level
	}

	return `level${basementLevel}`
}

function initInnerPropertyLevel(sumInsuredData, levelIndex, propertyType, tabData) {
	const { propertyType: tabDataPropertyType, levelNum } = tabData || {}

	if (tabDataPropertyType === 'Property' && levelNum) {
		return `level${levelNum}`
	}

	// dynamically moving to the correct level while coming back to the screen
	let index = 0
	if (propertyType === 'property') {
		index = levelIndex
	}

	let propertyLevel = ''
	if (
		(sumInsuredData.property &&
			sumInsuredData.property?.levels &&
			sumInsuredData.property?.levels[index].Level) ||
		sumInsuredData?.levels?.[index].Level
	) {
		propertyLevel =
			sumInsuredData?.property?.levels?.[index]?.Level || sumInsuredData?.levels?.[index].Level
	}

	return `level${propertyLevel}`
}

export default function SumInsured() {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const location = useLocation()
	const history = useHistory()
	let { Type, levels } = state.location || {}

	const { edit, tabData, returnPath } = location?.state || {}
	// console.log(tabData)

	const propertyType = location?.state?.propertyType
	const levelIndex = location?.state?.levelIndex

	const isSFSPEdgeCase = location?.state?.isSFSPEdgeCase

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	const [disableProceed, setDisableProceed] = useState(false)

	const [activeId, setActiveId] = useState(() =>
		genActiveId(levels, levelIndex, propertyType, tabData)
	)

	const [sumInsuredData, setsumInsuredData] = useState(() =>
		initSumInsuredData(isProperty, isBasement, state, levels)
	)

	const [innerBasementLevel, setInnerBasementLevel] = useState(() =>
		initInnerBasementLevel(sumInsuredData, levelIndex, propertyType, tabData)
	)

	const [innerPropertyLevel, setInnerPropertyLevel] = useState(() =>
		initInnerPropertyLevel(sumInsuredData, levelIndex, propertyType, tabData)
	)

	const onTabSwitch = tabId => {
		// save operation
		saveLevelData()

		setActiveId(tabId)
	}

	const setBasementLevelHandler = levelId => {
		// save operation here
		saveLevelData()

		setInnerBasementLevel(levelId)
	}

	const setPropertytLevelHandler = levelId => {
		// save operation here
		saveLevelData()

		setInnerPropertyLevel(levelId)
	}

	const saveLevelData = async () => {
		let toSend = {
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			data: '',
			propertyType: '',
			levelIndex: -1,
		}

		if (isProperty || isBasement) {
			// check if contains levels
			toSend.propertyType = isProperty ? 'property' : 'basement'
			const levelNo = activeId.substring(5, activeId.length)
			if (levels && levels.length > 0) {
				// find level Index
				const index = levels.findIndex(item => {
					return item.Level === levelNo
				})
				toSend.levelIndex = index

				toSend.data = { SumInsured: sumInsuredData.levels[index].SumInsured }
			} else {
				toSend.data = {
					SumInsured: sumInsuredData.SumInsured,
				}
			}

			// Extra check to not send wrong SI values if Type of Property changes
			const oldSIValues = toSend?.data?.SumInsured
			if (isProperty) {
				toSend.data = {
					SumInsured: oldSIValues?.filter(x => x?.Name !== 'BasementValue'),
				}
			} else {
				toSend.data = {
					SumInsured: oldSIValues?.filter(
						x => x?.Name !== 'BuildingValue' && x?.Name !== 'BoundaryWalls'
					),
				}
			}
		} else {
			// property+basement
			toSend.propertyType = activeId === 'Property' ? 'property' : 'basement'
			if (activeId === 'Property') {
				if (
					sumInsuredData.property &&
					sumInsuredData.property.levels &&
					sumInsuredData.property.length > 0
				) {
					const levelNo = innerPropertyLevel.substring(5, innerPropertyLevel.length)
					const index = sumInsuredData.property.levels.findIndex(item => {
						return item.Level === levelNo
					})
					toSend.levelIndex = index

					toSend.data = {
						SumInsured: sumInsuredData.property.levels[index].SumInsured,
					}
				} else {
					toSend.levelIndex = -1
					toSend.data = {
						SumInsured: sumInsuredData.property.SumInsured,
					}
				}
			} else {
				if (
					sumInsuredData.basement &&
					sumInsuredData.basement.levels &&
					sumInsuredData.basement.levels.length > 0
				) {
					const levelNo = innerBasementLevel.substring(5, innerBasementLevel.length)
					const index = sumInsuredData.basement.levels.findIndex(item => {
						return item.Level === levelNo
					})
					toSend.levelIndex = index

					toSend.data = {
						SumInsured: sumInsuredData.basement.levels[index].SumInsured,
					}
				} else {
					toSend.levelIndex = -1
					toSend.data = {
						SumInsured: sumInsuredData.basement.SumInsured,
					}
				}
			}
		}
		await new Promise(resolve => {
			dispatch(toSend)
			resolve()
		})
	}

	const saveSumInsuredData = (data, propertyType, levelIndex) => {
		setsumInsuredData(prev => {
			let oldValue = isBasement || isProperty ? prev : prev[propertyType]

			if (levelIndex > -1) {
				const oldLvlData = oldValue.levels[levelIndex]
				oldValue.levels[levelIndex] = { ...oldLvlData, ...data }
			} else {
				oldValue = {
					...oldValue,
					...data,
				}
			}

			const newSumInsuredData =
				isBasement || isProperty
					? { ...prev, ...oldValue }
					: {
							...prev,
							[propertyType]: {
								...oldValue,
							},
					  }
			return newSumInsuredData
		})
	}

	const checkActiveTab = (propertyType, levelIndex) => {
		// either property only or basement only
		const onlyBasementOrProperty = isBasement || isProperty
		let currentTabData = {}
		let currentLevel = ''

		if (onlyBasementOrProperty) {
			currentTabData = sumInsuredData
			currentLevel = activeId
		} else {
			currentTabData = { ...sumInsuredData[propertyType] }

			if (activeId === 'Property') {
				currentLevel = innerPropertyLevel
			} else {
				currentLevel = innerBasementLevel
			}
		}

		if (levelIndex > -1) {
			const tabLvlNo = currentTabData.levels[levelIndex]?.Level
			const currentLvlNo = currentLevel?.replace(/level/, '') || ''
			if (currentLvlNo === tabLvlNo) {
				return true
			}
		} else if (onlyBasementOrProperty || propertyType === activeId?.toLowerCase() || '') {
			return true
		}

		return false
	}

	const dispatchData = async () => {
		await saveLevelData()
		await new Promise(resolve => {
			dispatch({ type: 'SAVE_LOCATION_CHANGES' })
			resolve()
		})
		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else if (isSFSPEdgeCase) {
			history.push('/sfsp-edgecase/risk-location-selection')
		} else {
			history.push(`/risk-location-confirm/${state?.location?.ID}`)
		}
	}

	const proceed = () => {
		if (returnPath) {
			dispatchData()
		} else if (sumInsuredData.levels) {
			const currentLevelNo = activeId.substring(5, activeId.length)
			const index = sumInsuredData.levels.findIndex(item => {
				return item.Level === currentLevelNo
			})

			if (index < sumInsuredData.levels.length - 1) {
				const nextLevel = sumInsuredData.levels[index + 1].Level
				onTabSwitch(`level${nextLevel}`)
			} else {
				// save and proceed
				dispatchData()
			}
		} else {
			// check if property+basement
			if (isPropertyBasement) {
				if (activeId === 'Property') {
					// check if it contains levels
					if (sumInsuredData.property.levels) {
						const currentLevelNo = innerPropertyLevel.substring(5, activeId.length)
						const index = sumInsuredData.property.levels.findIndex(item => {
							return item.Level === currentLevelNo
						})
						if (index < sumInsuredData.property.levels.length - 1) {
							const nextLevel = sumInsuredData.property.levels[index + 1].Level
							setPropertytLevelHandler(`level${nextLevel}`)
						} else {
							//navigate to basement
							onTabSwitch('Basement')
						}
					} else {
						// navigate To basement
						onTabSwitch('Basement')
					}
				} else {
					if (sumInsuredData.basement.levels) {
						const currentLevelNo = innerBasementLevel.substring(5, activeId.length)
						const index = sumInsuredData.basement.levels.findIndex(item => {
							return item.Level === currentLevelNo
						})

						if (index < sumInsuredData.basement.levels.length - 1) {
							const nextLevel = sumInsuredData.basement.levels[index + 1].Level
							setBasementLevelHandler(`level${nextLevel}`)
						} else {
							// save and proceed
							dispatchData()
						}
					} else {
						// save and proceed
						dispatchData()
					}
				}
			} else {
				// proceed
				dispatchData()
			}
		}
	}

	const noteSection = (
		<div className="d-flex align-items-center mb-4">
			<Icon name="warningTriangleSm" className="me-1" />
			<Text
				type="body2"
				fontWeight={700}
				color={theme.colors.red}
				style={{ lineHeight: '17.64px' }}
			>
				Provide at least one value
			</Text>
		</div>
	)

	const header = (
		<>
			<TitleText className="mb-3">Enter the Sum Insured below:</TitleText>
			{noteSection}
		</>
	)

	const onStockPresentToggle = (value, propertyType, levelIndex) => {
		if (value) {
			saveLevelData()

			history.push({
				pathname: '/stock-details-collection',
				state: {
					propertyType,
					levelIndex,
					currentLocationId: state.location.ID,
				},
			})
		} else {
			let toSend = {
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType,
				levelIndex,
				data: {
					Stock: { Present: false },
				},
			}
			dispatch(toSend)
		}
	}

	const genSumInsuredItems = props => (
		<SumInsuredItems
			onSave={data => saveSumInsuredData(data, props.propertyType, props.levelIndex)}
			onStockPresentToggle={value =>
				onStockPresentToggle(value, props.propertyType, props.levelIndex)
			}
			isActiveTab={checkActiveTab(props.propertyType, props.levelIndex)}
			disableProceed={val => setDisableProceed(val)}
			isSFSPEdgeCase={isSFSPEdgeCase}
			{...props}
		/>
	)

	let tabs

	if (isProperty || isBasement) {
		// check for levels
		if (sumInsuredData.levels) {
			const levelsList = state.location.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `${isProperty ? 'Floor' : 'Level'} ${item.Level}`,
					content: genSumInsuredItems({
						propertyType: Type.toLowerCase(),
						levelIndex: index,
					}),
				}
			})
			tabs = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab"
						activeTabId={activeId}
						onChange={onTabSwitch}
						tabList={levelTabsItem}
						secondary={false}
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			// render
			tabs = genSumInsuredItems({
				propertyType: Type.toLowerCase(),
				levelIndex: -1,
			})
		}
	} else {
		let basementContent, propertyContent

		if (sumInsuredData.basement.levels) {
			const levelsList = sumInsuredData.basement.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `Level ${item.Level}`,
					content: genSumInsuredItems({
						propertyType: 'basement',
						levelIndex: index,
					}),
				}
			})
			basementContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-basement"
						activeTabId={innerBasementLevel}
						onChange={setBasementLevelHandler}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			basementContent = genSumInsuredItems({
				propertyType: 'basement',
				levelIndex: -1,
			})
		}

		if (sumInsuredData.property.levels) {
			const levelsList = sumInsuredData.property.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `Floor ${item.Level}`,
					content: genSumInsuredItems({
						propertyType: 'property',
						levelIndex: index,
					}),
				}
			})
			propertyContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-property"
						activeTabId={innerPropertyLevel}
						onChange={setPropertytLevelHandler}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			propertyContent = genSumInsuredItems({
				propertyType: 'property',
				levelIndex: -1,
			})
		}

		const parentTabbarItems = [
			{ id: 'Property', title: 'Building', disabled: false, content: propertyContent },
			{ id: 'Basement', title: 'Basement', disabled: false, content: basementContent },
		]

		tabs = (
			<TabbarWrapper>
				<Tabbar
					className="mt-3"
					tabId="outer-tab"
					activeTabId={activeId}
					onChange={onTabSwitch}
					tabList={parentTabbarItems}
					secondary={false}
				/>
			</TabbarWrapper>
		)
	}

	const goBack = () => {
		// check for goods in trust presence here first
		let isGoodsInTrustPresent = true
		if (isPropertyBasement) {
			const property = state?.location?.property

			// check only for one level should suffice as all the levels will have the same 'Present' value
			if (property?.levels?.length) {
				isGoodsInTrustPresent = Boolean(property?.levels?.[0]?.GoodsInTrust?.Present)
			} else {
				isGoodsInTrustPresent = Boolean(property?.GoodsInTrust?.Present)
			}
		} else {
			if (levels?.length) {
				isGoodsInTrustPresent = Boolean(levels?.[0]?.GoodsInTrust?.Present)
			} else {
				isGoodsInTrustPresent = Boolean(state?.location?.GoodsInTrust?.Present)
			}
		}
		if (returnPath) {
			history.replace(returnPath)
		} else if (isGoodsInTrustPresent) {
			// history.replace('/property-risk/goods-in-trust-data')
			history.replace('/property-risk/historic-info')
		} else {
			// history.replace('/property-risk/goods-in-trust')
			history.replace('/property-risk/historic-info')
		}
	}

	return (
		<MainContainer
			header="Sum Insured"
			rightLabel="Save & Continue"
			rightOnClick={proceed}
			rightDisabled={disableProceed}
			leftOnClick={goBack}
		>
			{header}
			{tabs}
		</MainContainer>
	)
}
