import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Button, PageContentAndNavbarWrapper, Text, theme } from 'verak-ui'
import { useFetch } from '../../../../../api/apihook'
import calculateTotalPremium from '../../../../../utilities/calculateTotalPremium'
import { coverageValues } from '../../../../../utilities/coverageDateUtils'
import { convertAmountToWords } from '../../../../RiskLocationReview/reviewScreenUtils'
import { InsurerImagesMap } from './quoteComparisonUtils'

export default function PaymentDetails({
	selectedQuote,
	rfqId,
	totalSumInsured,
	paymentDetails,
	onBackClick,
}) {
	const [paymentLink, setPaymentLink] = useState(paymentDetails?.Link || '')
	const { getPaymentLink, sendNotificationToClient } = useFetch()

	const fetchPaymentLink = async () => {
		const result = await getPaymentLink(rfqId)
		setPaymentLink(result?.paymentLink)
	}

	const sendToClient = async () => {
		const result = await sendNotificationToClient(rfqId, 'PAYMENT_LINK')
		if (!result?.error) {
			toast.info('Payment link sent to client')
		}
	}

	const insurerImage = InsurerImagesMap[selectedQuote?.Insurer]
	const totalPremium = convertAmountToWords(`₹ ${calculateTotalPremium(selectedQuote)}`)
	let durationValue = coverageValues.find(item => item.value === selectedQuote?.Duration)
	let durationString = durationValue?.name || ''
	if (durationString === '12 months') {
		durationString = '1 Year'
	}

	const copyLinkToClipboard = () => {
		if (paymentLink) {
			navigator.clipboard.writeText(paymentLink)
			toast.info('Payment link copied to clipboard')
		}
	}

	return (
		<PageContentAndNavbarWrapper
			className="container m-5 p-3 rounded bg-white"
			style={{ boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.15)' }}
		>
			<div className="d-flex flex-column w-100 align-items-center">
				<div className="w-100 d-flex justify-content-between align-items-center">
					<Button
						label="Back"
						icon="leftArrow"
						iconAlign="left"
						iconColor={theme.colors.primary}
						textColor={theme.colors.primary}
						bgColor="transparent"
						onClick={onBackClick}
					/>

					<Text
						className="align-self-center"
						fontSize="14px"
						fontWeight="700"
						color={theme.colors.gray2}
						style={{ marginLeft: '-6rem' }}
					>
						SELECTED POLICY
					</Text>

					<span>&nbsp;</span>
				</div>

				<div className="d-flex flex-column" style={{ maxWidth: '280px' }}>
					<img height="120px" width="230px" src={insurerImage} alt="insurer" />

					<div className="d-flex my-2 justify-content-between">
						<Text color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Premium
						</Text>
						<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
							{totalPremium}
						</Text>
					</div>

					<div className="d-flex my-2 justify-content-between">
						<Text color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Cover
						</Text>
						<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
							{convertAmountToWords(`₹ ${totalSumInsured}`)}
						</Text>
					</div>

					<div className="d-flex my-2 justify-content-between">
						<Text color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Period
						</Text>
						<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
							{durationString}
						</Text>
					</div>

					<Text className="mt-3 align-self-start" fontSize="14px" fontWeight="700" color="#999999">
						PAYMENT LINK
					</Text>

					{paymentLink && (
						<div className="d-flex align-items-center mt-2 justify-content-between">
							<Text fontSize="12px" fontWeight="700" color="#2854E7">
								{paymentLink}
							</Text>

							<Button
								className="p-1"
								label="Copy"
								textColor="#333"
								bgColor="#F2F2F2"
								style={{ height: '28px' }}
								icon="copyToClipboard"
								iconSize="18px"
								iconStyles={{ marginRight: '4px' }}
								iconAlign="left"
								iconColor="#333"
								onClick={copyLinkToClipboard}
							/>
						</div>
					)}

					<Button
						className="mt-3 w-100 mb-2"
						primary
						label={paymentLink ? 'Send to client' : 'Generate Payment Link'}
						icon="whatsapp"
						iconAlign="left"
						iconStyles={{ marginRight: '4px' }}
						iconSize="16px"
						style={{ height: '36px' }}
						onClick={paymentLink ? sendToClient : fetchPaymentLink}
					/>
				</div>
			</div>
			<ToastContainer />
		</PageContentAndNavbarWrapper>
	)
}
