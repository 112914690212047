import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import WhatType from '../../../../../assets/training/WhatType.svg'
import WhatStock from '../../../../../assets/training/WhatStock.svg'
import WhatFloor from '../../../../../assets/training/WhatFloor.svg'
import NonSingleCallSale from '../../../../../assets/training/NonSingleCallSale.svg'
import NoDocuments from '../../../../../assets/training/NoDocuments.svg'

export default function CheatSheet_Paperless() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WhatFloor}>
						<InfoText fontWeight="700">What FLOOR is the business located on?</InfoText>
					</InfoBox>
					<ul>
						<li>1st Floor, ground floor or basement</li>
						<li>Customer should provide address on official WhatsApp number</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={WhatType}>
						<InfoText fontWeight="700">What TYPE of business do they run?</InfoText>
					</InfoBox>
					<ol>
						<li>Facility Type - Shop, Godown, Factory, Service centre, Restaurant, Cafe</li>
						<li>Business Type - Selling What? Making What? Storing What?</li>
						<li>Be Careful - Shop or godown? Kutcha or Pucca?</li>
					</ol>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WhatStock}>
						<InfoText fontWeight="700">What is their STOCK?</InfoText>
					</InfoBox>
					<ul>
						<li>Treat finished goods and raw materials as 2 separate values</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={NoDocuments}>
						<InfoText fontWeight="700">
							NO Documents for purchase - soft copy documents for claims (Remind Customer)
						</InfoText>
					</InfoBox>
					<ul>
						<li>They will need to sign the claim form</li>
					</ul>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={NonSingleCallSale}>
						<InfoText fontWeight="700">If you cannot do 1 call sale</InfoText>
					</InfoBox>
					<ul>
						<li>Push customers to verify details in the RFQ PDF which is sent to them.</li>
					</ul>
				</div>
				<VerticalDivider />
				<div className="col"></div>
			</div>
		</div>
	)
}
