import { Checkbox, Space, Text, theme, Button, SearchableSelect, Select } from 'verak-ui'
import { ageOfConstructionOps, stateOps } from './options'
import {
	CustomInput,
	CustomSelect,
	GridContainer,
	SearchableSelectLabel,
	SearchableSelectWrapper,
	CustomSelectLabel,
} from './SharedStyles'
import { useContext, useState, useEffect, useRef } from 'react'
// import objValToNum from '../../../../helpers/objValToNum'
import { Context } from '../../../../context/Provider'
import { useFetch } from '../../../../api/apihook'
import { useLocation } from 'react-router'
import { renderLevels } from '../../../PropertyRiskDataCollection/commonUtils/commonExports'
import styled from 'styled-components'

const StyledLabel = styled.label`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: ${theme.type.weights.medium};
	margin-bottom: 0.5rem;
`

function initGenLocDetails(state, levelIdx, propertyType) {
	const { Type, SubType, levels } = state.location || {}

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'

	if (isProperty || isBasement) {
		if (levels && levels[levelIdx]?.AddressLine1) {
			return {
				AddressLine1: levels[levelIdx]?.AddressLine1 || '',
				AddressLine2: levels[levelIdx]?.AddressLine2 || '',
				PIN: levels[levelIdx]?.PIN || '',
				City: levels[levelIdx]?.City || '',
				State: levels[levelIdx]?.State || '',
				ConstructionAge: levels[levelIdx]?.ConstructionAge || { Low: '', High: '' },
			}
		}
		const { AddressLine1, AddressLine2, PIN, City, State, ConstructionAge } = state.location
		return {
			AddressLine1,
			AddressLine2,
			PIN,
			City,
			State,
			ConstructionAge: ConstructionAge || { Low: '', High: '' },
		}
	} else {
		const { AddressLine1, AddressLine2, PIN, City, State, ConstructionAge } =
			state.location?.[propertyType] || {}
		return {
			AddressLine1,
			AddressLine2,
			PIN,
			City,
			State,
			ConstructionAge: ConstructionAge || { Low: '', High: '' },
		}
	}
}

function generateLevelField(state, levelIdx, propertyType) {
	const { Type, SubType, levels, Level } = state.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	const levelLabelString =
		Type === 'PROPERTY' || (isPropertyBasement && propertyType === 'property')
			? 'Floor No.'
			: 'Level No.'

	let levelsData = levels,
		levelData = Level,
		isWhole = SubType === 'Whole',
		isSingle = SubType === 'Single'

	if (isPropertyBasement) {
		levelsData = state.location?.[propertyType]?.levels || []
		levelData = state.location?.[propertyType]?.Level || ''
		isWhole = state.location?.[propertyType]?.SubType === 'Whole'
		isSingle = state.location?.[propertyType]?.SubType === 'Single'
	}

	if (isWhole) {
		return {
			levelLabel:
				Type === 'PROPERTY' || (isPropertyBasement && propertyType === 'property')
					? 'Number of Floors'
					: 'Number of Levels',
			levelFieldValue: levelData,
		}
	} else if (isSingle) {
		return {
			levelLabel: levelLabelString,
			levelFieldValue: levelData,
		}
	} else {
		return { levelLabel: levelLabelString, levelFieldValue: levelsData?.[levelIdx]?.Level }
	}

	// return { levelLabel: '', levelFieldValue: '' }
}

function getSameAsLevelLabel(state, propertyType) {
	const { Type, property, levels } = state.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let level = ''
	let sameAsType = propertyType

	if (isPropertyBasement) {
		sameAsType = 'Building'
		if (property?.levels?.length) {
			level = property?.levels?.[0]?.Level || ''
		} else {
			level = property?.Level || ''
		}
	} else {
		if (Type === 'PROPERTY') {
			sameAsType = 'Building'
		}
		if (levels?.length) {
			level = levels?.[0]?.Level || ''
		} else {
			level = property?.Level || ''
		}
	}

	return level ? `Same as ${sameAsType} Level ${level}` : `Same as ${sameAsType}`
}

const EditField = ({ onEditClick, showEditButton = false, children }) => {
	if (showEditButton) {
		return (
			<div className="w-100 position-relative">
				{children}
				<Button
					className="position-absolute bottom-0 end-0 me-2 mb-1 p-0"
					onClick={onEditClick}
					icon="edit"
					bgColor="rgba(0,0,0,0.0)"
					style={{ zIndex: '1000' }}
				/>
			</div>
		)
	}
	return children
}

const GeneralLocationDetails = ({
	onSave,
	isActiveTab,
	disableProceed,
	disabled,
	levelIdx,
	propertyType,
	activeId,
	innerPropertyLevel,
	innerBasementLevel,
}) => {
	const fetch = useFetch()
	const historyLoc = useLocation()
	const anyInputChangedRef = useRef(false)
	const [state] = useContext(Context)
	const { levelLabel, levelFieldValue } = generateLevelField(state, levelIdx, propertyType)
	const [levelValue, setLevelValue] = useState(levelFieldValue)

	const [locationDetails, setLocationDetails] = useState(() =>
		initGenLocDetails(state, levelIdx, propertyType)
	)

	const [cityPrefilledByAPI, setCityPrefilledByAPI] = useState(false)
	const [statePrefilledByAPI, setStatePrefilledByAPI] = useState(false)

	const { Type } = state?.location || {}
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'
	const { AddressLine1, AddressLine2, PIN, City, State, ConstructionAge } = locationDetails

	const fieldErrors = {
		AddressLine1: !Boolean(AddressLine1),
		PIN: !Boolean(PIN && String(PIN).match(/^[1-9]{1}\d{5}$/)),
		City: !Boolean(City),
		State: !Boolean(State),
		ConstructionAge: !Boolean(ConstructionAge?.High && ConstructionAge?.Low),
		// Level: !Boolean(Level && Number(Level) > 1),
	}

	const initConstructionAgeLvl = () => {
		const { Low, High } = ConstructionAge || {}

		if (Low && High) {
			return ageOfConstructionOps.find(el => el.val.Low === Low && el.val.High === High)?.op || ''
		}
		return ''
	}

	const inputComplete = !(
		fieldErrors.AddressLine1 ||
		fieldErrors.PIN ||
		fieldErrors.City ||
		fieldErrors.State
	)

	const setField = (name, value) => {
		if (name === 'ConstructionAge') {
			value = ageOfConstructionOps.find(el => el.op === value)?.val || { Low: '', High: '' }
		} else if (name === 'PIN') {
			value = value.substring(0, 6)
		} else if (name === 'City') {
			// accept alphabets, space and hyphen
			value = value.replace(/[^a-zA-Z- ]/gi, '')
		}
		anyInputChangedRef.current = true
		setLocationDetails(prev => ({
			...prev,
			[name]: value,
		}))
	}

	useEffect(() => {
		if (inputComplete) {
			onSave({ ...locationDetails, Level: parseInt(levelValue) }, true, true)
		}
		if (isActiveTab) {
			disableProceed(!inputComplete)
		}
	}, [locationDetails, isActiveTab, inputComplete, levelValue]) // eslint-disable-line

	// required to re-init the state w/ updated reducer data on tab change
	useEffect(() => {
		setLocationDetails(initGenLocDetails(state, levelIdx, propertyType))
	}, [activeId, innerPropertyLevel, innerBasementLevel]) // eslint-disable-line

	// runs when PIN field goes out of focus
	const fetchCityAndState = async () => {
		const cityOrStateEmpty = !City || !State
		const pinValid = !fieldErrors.PIN

		// make an API call only if the city or state is empty and if the PIN is valid
		if (pinValid && cityOrStateEmpty) {
			const response = await fetch.getLocationsWithPIN(PIN)

			const results = response?.[0] || []
			const locations = results?.PostOffice || []

			if (locations?.length) {
				// set the 'District' field as the city
				const { District: City, State } = locations?.[0] || {}
				if (City) {
					setField('City', City)
					setCityPrefilledByAPI(true)
				}
				if (State) {
					setField('State', State)
					setStatePrefilledByAPI(true)
				}
			}
		}
	}

	// set prefilled state to false when the user edits the city or state
	const handleCityChange = e => {
		setCityPrefilledByAPI(false)
		setField('City', e.target.value)
	}

	const handleStateChange = value => {
		setStatePrefilledByAPI(false)
		setField('State', value)
	}

	// const levelLabel = 'OK'
	// const levelsFieldValue = 'OK'

	const disableAddress = disabled || (isPropertyBasement && propertyType === 'basement')

	let completeAddressFields = [AddressLine1, AddressLine2, City, State, PIN]
	let validAddressFields = completeAddressFields.filter(addrField => addrField)

	let someAddressFieldsPresent = AddressLine1 || AddressLine2 || City || State || PIN

	const displayPrefilledAddress = (
		<>
			{someAddressFieldsPresent ? (
				<div>
					<Text type="body2" color={theme.colors.gray2}>
						Address
					</Text>
					<Text type="body1" color={theme.colors.primary} fontWeight="600">
						{validAddressFields.join(', ')}
					</Text>
				</div>
			) : (
				<Space />
			)}
			<Space />
			<Checkbox text={getSameAsLevelLabel(state, propertyType)} value={true} disabled={true} />
			<Space />
		</>
	)

	const prefilledMessage = (
		<Text type="body2" color={theme.colors.green}>
			Pre filled from PIN code
		</Text>
	)

	let displayStatePrefilledMessage = null
	if (statePrefilledByAPI) {
		displayStatePrefilledMessage = prefilledMessage
	}

	let displayCityPrefilledMessage = null
	if (cityPrefilledByAPI) {
		displayCityPrefilledMessage = prefilledMessage
	}

	const displayAddressFields = (
		<>
			<CustomInput
				type="text"
				label="Address Line 1"
				placeholder="Enter here"
				compulsory={true}
				disabled={disableAddress}
				value={AddressLine1 || ''}
				onChange={e => setField('AddressLine1', e.target.value)}
				error={anyInputChangedRef.current && fieldErrors.AddressLine1}
			/>
			<CustomInput
				type="text"
				label="Address Line 2"
				disabled={disableAddress}
				placeholder="Enter here"
				value={AddressLine2 || ''}
				onChange={e => setField('AddressLine2', e.target.value)}
			/>
			<CustomInput
				type="number"
				label="PIN Code"
				placeholder="Enter here"
				disabled={disableAddress}
				compulsory={true}
				value={PIN || ''}
				onChange={e => setField('PIN', e.target.value)}
				error={anyInputChangedRef.current && fieldErrors.PIN}
				onBlur={fetchCityAndState}
			/>
			<div className="w-100">
				<EditField
					showEditButton={cityPrefilledByAPI}
					onEditClick={() => setCityPrefilledByAPI(false)}
				>
					<CustomInput
						type="text"
						label="City"
						disabled={disableAddress || cityPrefilledByAPI}
						placeholder="Enter your city"
						compulsory={true}
						value={City || ''}
						onChange={handleCityChange}
						error={anyInputChangedRef.current && fieldErrors.City}
					/>
				</EditField>
				{displayCityPrefilledMessage}
			</div>
			<SearchableSelectWrapper>
				<SearchableSelectLabel>State </SearchableSelectLabel>
				<EditField
					showEditButton={statePrefilledByAPI}
					onEditClick={() => setStatePrefilledByAPI(false)}
				>
					<SearchableSelect
						options={stateOps}
						search
						placeholder="Select your state"
						value={State}
						disabled={disableAddress || statePrefilledByAPI}
						onChange={handleStateChange}
						error={anyInputChangedRef.current && fieldErrors.State}
					/>
				</EditField>

				{displayStatePrefilledMessage}
			</SearchableSelectWrapper>
			<div>
				<CustomSelectLabel>Age of construction </CustomSelectLabel>
				<CustomSelect
					placeholder="Enter here"
					value={initConstructionAgeLvl()}
					onChange={val => setField('ConstructionAge', val)}
					error={anyInputChangedRef.current && fieldErrors.ConstructionAge}
					disabled={disableAddress}
				>
					{ageOfConstructionOps.map(el => (
						<option key={el.op}>{el.op}</option>
					))}
				</CustomSelect>
			</div>
		</>
	)

	const editPostSubmission = historyLoc?.state?.edit
	return (
		<GridContainer>
			{disableAddress ? displayPrefilledAddress : displayAddressFields}

			<div className="d-flex flex-column">
				<StyledLabel>
					{levelLabel} <span style={{ color: '#eb5757' }}>*</span>
				</StyledLabel>
				<Select
					value={levelValue}
					disabled={!editPostSubmission}
					onChange={selected => setLevelValue(selected)}
					placeholder={propertyType == 'basement' ? '-1 to -20' : '0 to 100'}
				>
					{renderLevels(propertyType?.toUpperCase())}
				</Select>
			</div>
		</GridContainer>
	)
}

export default GeneralLocationDetails
