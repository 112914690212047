export function parsePhoneNumber(phone) {
	if (phone?.startsWith('+91') && phone?.length === 13) {
		return phone?.replace('+91', '')
	} else if (phone?.startsWith('0') && phone?.length === 11) {
		return phone?.replace('0', '')
	} else if (phone?.startsWith('+910') && phone?.length === 14) {
		return phone?.replace('+910', '')
	} else if (phone?.startsWith('91') && phone?.length === 12) {
		return phone?.replace('91', '')
	} else {
		return phone
	}
}
