import styled from 'styled-components'
import { theme, Input, Text } from 'verak-ui'
import { getAmountInWords } from '../utilities/getAmountInWords'
import { replaceAll } from '../utilities/replaceAll'

const FormInput = ({ ...props }) => {
	if (props?.autoComma) {
		let amountValue = props?.value?.toString() || ''
		let convertedAmount = ''

		if (amountValue.includes(',')) amountValue = replaceAll(amountValue, ',', '')
		if (!isNaN(parseFloat(amountValue))) {
			convertedAmount = getAmountInWords(amountValue)
		}
		return (
			<FullWidthFlex className="flex-column">
				<FullWidthInput {...props} />
				{convertedAmount && (
					<Text className="ms-3 mt-1" fontSize="14px" color={theme.colors.gray2}>
						{convertedAmount}
					</Text>
				)}
			</FullWidthFlex>
		)
	}
	return <FullWidthInput {...props} />
}

export default FormInput

const FullWidthFlex = styled.div`
	width: 100%;
	display: flex;
	div {
		width: 100%;
	}
`

const FullWidthInput = styled(Input)`
	width: 100%;
`
