export default function remDuplicatesArrObj(arr, key) {
	const newArr = []
	const uniqueObject = {}

	for (let i in arr) {
		const filterKey = arr[i][key]
		uniqueObject[filterKey] = arr[i]
	}

	for (let i in uniqueObject) {
		newArr.push(uniqueObject[i])
	}

	return newArr
}
