import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../components/SecondaryNavbar'
import { useHistory, useLocation } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../../context/Provider'
import CloudInProgress from '../../../assets/icons/cloudInProgress.svg'
import { useFetch } from '../../../api/apihook'
import { ComponentContext } from '../../../context/ComponentProvider'

import 'react-toastify/dist/ReactToastify.css'

export default function InProgress() {
	const history = useHistory()

	const { state } = useLocation()

	const { getQuote, mergeRiskLocations, getDraftRFQ } = useFetch()
	const [, dispatch] = useContext(Context)

	const { setRenderSidePanel } = useContext(ComponentContext)

	const continueQuote = async () => {
		if (!state?.uid) {
			return
		}

		// get rfq data by uid
		let rfqData
		try {
			rfqData = await getQuote(state.uid)
		} catch (err) {
			console.log('ERR: failed to load RFQ')
		}

		const toMerge = {
			...rfqData,
			quoteId: state.uid,
		}
		const mergedData = mergeRiskLocations(toMerge)

		// set locationState context
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'RESUME_QUOTE',
				data: mergedData,
			})
			resolve()
		})

		if (state.DraftRfqId) {
			const response = await getDraftRFQ(state.DraftRfqId)
			dispatch({
				type: 'SET_DRAFT_RFQ',
				data: {
					...response,
					_id: state.DraftRfqId,
				},
			})
			setRenderSidePanel(true)
		}

		if (mergedData.RiskLocations.length < mergedData.NumRiskLoc) {
			// add initial locations
			const count = mergedData.NumRiskLoc - mergedData.RiskLocations.length
			for (let index = 0; index < count; index++) {
				dispatch({
					type: 'ADD_NEW_RISK_LOCATION',
				})
			}
		}

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_QUOTE_ID',
				data: {
					id: state?.uid,
				},
			})
			resolve()
		})

		if (state?.locationID !== '') {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE_LOCATION',
					data: {
						locationId: state?.locationID,
					},
				})
				resolve()
			})
		}

		// navigate to last active path
		if (state?.LastPath === '/journey/coverage-details') {
			// set ClientName, Legal entity name and Phone number in the context
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_CLIENT_DETAILS',
					data: {
						ProposerName: state?.ProposerName,
						CompanyName: state?.ClientName,
						Phone: state?.Phone,
					},
				})
				resolve()
			})

			// redirect to /journey/coverage-details
			history.push('/journey/coverage-details')
		} else {
			history.push(state?.LastPath)
		}
	}

	// TODO: to be changed
	const navText = `${state?.PolicyType} > Resume, ${state?.uid} - ${state?.ClientName}`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			style={{ height: '80%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					borderRadius: '8px',
					width: '95%',
				}}
				className="container h-100 bg-white p-3 mt-4 mx-2 d-flex justify-content-between align-items-center flex-column"
			>
				<div className="d-flex justify-content-between align-items-center flex-column">
					<img src={CloudInProgress} alt="resume quote" />

					<Text color={theme.colors.primary} fontSize="24px" fontWeight="700">
						Let’s pickup from where you left off
					</Text>
				</div>

				<div
					className="d-flex justify-content-end align-items-center mt-2"
					style={{
						background: 'white',
						padding: '12px 24px',
						width: '100%',
						borderTop: '1.5px solid #02475E',
					}}
				>
					<Button label="Continue" icon="rightArrow" onClick={continueQuote} iconAlign="right" />
				</div>
			</div>
		</PageContentAndNavbarWrapper>
	)
}
