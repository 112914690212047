import styled from 'styled-components'
import { theme, Text, Icon } from 'verak-ui'

import { getFormattedAmount } from '../../../utilities/getFormattedAmount'
import { getAmountInWords } from '../../../utilities/getAmountInWords'
import { replaceAll } from '../../../utilities/replaceAll'

export const convertToAmount = amountString => {
	let parsedAmount = amountString
	if (typeof parsedAmount === 'number') return parsedAmount
	if (typeof parsedAmount === 'string' && parsedAmount?.includes(',')) {
		parsedAmount = replaceAll(parsedAmount, ',', '')
	}
	return parseInt(parsedAmount)
}

const calculateAddonPremium = (type, amount) => {
	amount = convertToAmount(amount)
	if (['CIS', 'CIC'].includes(type)) {
		amount = (amount / 1000) * 2
	} else {
		amount = amount * 0.4 * 0.18
	}
	return parseInt(amount)
}

const PremiumCalculatorCard = ({
	totalSI,
	addonSI,
	provisionalQuoteData,
	sumInsuredContainerRef,
	safetyPlanData,
}) => {
	const { provisionalSI, provisionalPremium } = provisionalQuoteData
	const isProvisionalDataPresent = Boolean(provisionalSI && provisionalPremium)
	const { safetyToggle, cctvUnits } = safetyPlanData
	const isSafetyPlan = safetyToggle?.value

	const { CIC, CIS, CIT } = addonSI

	// total si = rfq si + money addons si
	let siPreviewAmount = [totalSI, CIC, CIS, CIT].reduce(
		(acc, curr) => acc + convertToAmount(curr || 0),
		0
	)

	let siPreview = getFormattedAmount(siPreviewAmount)
	let premium = 0

	if (isProvisionalDataPresent) {
		const roundedProvisionalPremium = Math.round(provisionalPremium)
		if (!totalSI) {
			premium = roundedProvisionalPremium
			siPreview = getFormattedAmount(provisionalSI)
		} else {
			premium = Math.round((roundedProvisionalPremium * totalSI) / Number(provisionalSI))
		}
	} else {
		premium = Math.round(totalSI * (250 / 100000))
	}

	// add addon premiums
	premium += ['CIC', 'CIS', 'CIT'].reduce((acc, curr) => {
		return acc + calculateAddonPremium(curr, addonSI?.[curr] || 0)
	}, 0)

	if (premium < 473) {
		premium = 473
	}

	const insurerPremium = premium // a copy is created to display that separately

	// Safety plan pricing logic (takes only 'totalSI' into consideration)
	if (isSafetyPlan) {
		const CCTV_UNIT_COST = 1300,
			MEMORY_CARD_UNIT_COST = 307,
			MEMORY_CARD_MARGIN = 90,
			COURIER_COST = 150

		let margin = MEMORY_CARD_MARGIN * cctvUnits
		const hardwareCost = (CCTV_UNIT_COST + MEMORY_CARD_UNIT_COST + COURIER_COST) * cctvUnits

		if (totalSI < 600000) {
			margin += 250
		} else if (totalSI >= 600000 && totalSI < 1500000) {
			margin += 500
		} else if (totalSI >= 1500000 && totalSI < 2600000) {
			margin += 750
		} else if (totalSI >= 2600000 && totalSI < 4100000) {
			margin += 1000
		} else if (totalSI >= 4100000 && totalSI < 5100000) {
			margin += 1250
		} else if (totalSI >= 5100000) {
			margin += 1500
		}
		premium += margin + hardwareCost
	}

	const perDay = getFormattedAmount(Math.round(premium / 365))
	const perYear = getFormattedAmount(premium)

	let siAmountInWords = '-'
	if (totalSI) {
		siAmountInWords = getAmountInWords(totalSI)
	} else if (provisionalSI) {
		siAmountInWords = getAmountInWords(provisionalSI)
	}

	const handleEditClick = () => {
		// get the sum insured container component, which is a child component
		const childComp = sumInsuredContainerRef.current.firstChild
		// scrollIntoView doesn't support scroll offset, so ref was set on a parent div, with some top padding
		sumInsuredContainerRef.current.scrollIntoView({ behavior: 'smooth' })

		childComp.classList.add('highlight-container')
		setTimeout(() => childComp.classList.remove('highlight-container'), 2000)
	}

	return isSafetyPlan ? (
		<Container className="flex-fill d-flex flex-column">
			<div className="mt-2 mb-3 d-flex justify-content-around">
				<div className="d-flex flex-column align-items-center w-100">
					<Text fontSize="12px" fontWeight="400">
						Total Sum Insured
					</Text>
					<Text fontSize="14px" fontWeight="700">
						{siPreview}
					</Text>
				</div>
				<div className="d-flex flex-column align-items-center w-100">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Estimated Insurance Premium
					</Text>
					<div className="d-flex align-items-center">
						<Text color={theme.colors.black} fontSize="14px" fontWeight="700">
							₹ {insurerPremium}
						</Text>
						<Text className="ms-1" color="#999999" fontSize="12px" fontWeight="500">
							(including add on)
						</Text>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column align-items-center w-100">
				<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
					Amount to Quote
				</Text>
				<div className="d-flex align-items-center">
					<Text color={theme.colors.blue} fontSize="14px" fontWeight="700">
						₹{premium}/Yearly&nbsp;&nbsp;&nbsp; ⊛ &nbsp;&nbsp;&nbsp;₹{perDay}/Daily
					</Text>
				</div>
			</div>
		</Container>
	) : (
		<Container className="flex-fill d-flex flex-column">
			<div className="mt-2 mb-3 d-flex justify-content-around">
				<div className="d-flex flex-column align-items-center w-100">
					<Text color={theme.colors.blue} fontSize="12px" fontWeight="400">
						Per Day ⭐
					</Text>
					<Text color={theme.colors.blue} fontSize="14px" fontWeight="700">
						₹ {perDay}
					</Text>
				</div>
				<div className="d-flex flex-column align-items-center w-100">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Per Year
					</Text>
					<div className="d-flex align-items-center">
						<Text color={theme.colors.black} fontSize="14px" fontWeight="700">
							₹ {perYear}
						</Text>
						<Text className="ms-1" color="#999999" fontSize="12px" fontWeight="500">
							(including add on)
						</Text>
					</div>
				</div>
			</div>
			<TotalSISection className="d-flex justify-content-between align-items-center w-100">
				<div className="d-flex flex-column">
					<Text color={theme.colors.gray2} fontWeight="400" fontSize="12px">
						For Total Sum Insured
					</Text>
					<Text
						color={theme.colors.primary}
						fontWeight="600"
						fontSize="14px"
						className="d-flex gap-2"
					>
						₹ {siPreview}
						{!totalSI && isProvisionalDataPresent ? (
							<Icon
								name="editPencilSquare"
								size="20px"
								style={{ cursor: 'pointer' }}
								color={theme.colors.primary}
								onClick={handleEditClick}
							/>
						) : null}
					</Text>
				</div>
				<Text
					color={theme.colors.primary}
					className="w-50"
					fontWeight="600"
					fontSize={'11px'}
					style={{ wordWrap: 'break-word' }}
				>
					{siAmountInWords}
				</Text>
			</TotalSISection>
		</Container>
	)
}

export default PremiumCalculatorCard

const Container = styled.div`
	padding: 8px 10px;
	background: transparent;
`

const TotalSISection = styled.div`
	border: 0.5px solid #9fa2e2;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
	border-radius: 9px;
	padding: 8px 12px;
`
