import { useContext, useState } from 'react'
import { Row } from 'react-bootstrap'
import { RadioHolder, CustomRadio } from '../commonUtils/commonExports'

import { Text, Space, theme, Divider } from 'verak-ui'

import WholeBasement from './WholeBasement'
import SingleBasement from './SingleBasement'
import MultipleBasement from './MultipleBasement'
import { Context } from '../../../context/Provider'

function initSubType(state) {
	const { Type } = state.location || {}
	if (Type === 'PROPERTYBASEMENT') {
		return state.location?.basement?.SubType || ''
	}
	return state.location?.SubType || ''
}

const BasementTypeSelector = ({ setInputCompleteStatus, saveData }) => {
	const [state] = useContext(Context)
	const [basementType, setBasementType] = useState(initSubType(state))

	const onTypeSelect = val => {
		setBasementType(val)
	}

	const setBasementLevelToSingle = () => {
		setBasementType('Single')
	}

	const setData = data => {
		saveData({
			basementType: basementType,
			levels: data.levels,
		})
	}

	let selectedOption
	if (basementType === 'Single') {
		selectedOption = (
			<SingleBasement
				onInputComplete={value => setInputCompleteStatus(value)}
				onDataComplete={setData}
			/>
		)
	} else if (basementType === 'Multiple') {
		selectedOption = (
			<MultipleBasement
				onInputComplete={value => setInputCompleteStatus(value)}
				onBasementLevelSingle={setBasementLevelToSingle}
				onDataComplete={setData}
			/>
		)
	} else if (basementType === 'Whole') {
		selectedOption = (
			<WholeBasement
				onInputComplete={value => setInputCompleteStatus(value)}
				onDataComplete={setData}
			/>
		)
	}

	const isStockOnly = state?.quote?.PolicyKind === 'STOCK_ONLY'

	let titleText = 'How many basement levels does the client want insured?'

	if (isStockOnly) {
		titleText = 'How many levels contain the stock to be insured?'
	}

	return (
		<>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<Row className="d-flex align-items-center">
				<Text style={{ fontWeight: 700, fontSize: '24px' }}>
					{titleText}
					<span style={{ color: theme.colors.red }}>*</span>
				</Text>
			</Row>
			<Row>
				<RadioHolder className="d-flex">
					<form onSubmit={e => e.preventDefault()}></form>
					<CustomRadio
						value={basementType === 'Single'}
						onChange={() => onTypeSelect('Single')}
						secondary
						text="SINGLE"
						name="basementLevel"
						id="SINGLE_BASEMENT_OPTION"
					/>
					<Space x={2.5} mobile={false} />
					<CustomRadio
						value={basementType === 'Multiple'}
						onChange={() => onTypeSelect('Multiple')}
						secondary
						text="MULTIPLE"
						name="basementLevel"
						id="MULTIPLE_BASEMENT_OPTION"
					/>
					<Space x={2.5} mobile={false} />
					<CustomRadio
						value={basementType === 'Whole'}
						onChange={() => onTypeSelect('Whole')}
						secondary
						text="WHOLE BASEMENT"
						name="basementLevel"
						id="WHOLE_BASEMENT_OPTION"
					/>
				</RadioHolder>
			</Row>
			<Row>{selectedOption}</Row>
			<Divider />
		</>
	)
}
export default BasementTypeSelector
