import { Text, Space, Modal, Button, theme, Icon, Divider, Radio, Input, Checkbox } from 'verak-ui'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

const AccountSelectionModal = ({
	show,
	handleClose,
	contactId,
	selectAccount,
	existingClient,
	isAccountSelected,
	proposerName,
	account = null,
	associatedAccounts = [],
}) => {
	const accountNotSelectedNewClient = !existingClient && !isAccountSelected
	//  VIEWS -
	//  account-list-view (account list)
	//  add-view (add account)
	//  edit-view (edit account)
	const [activeScreen, setActiveScreen] = useState(
		accountNotSelectedNewClient ? 'add-view' : 'account-list-view'
	)
	const [accounts, setAccounts] = useState(account ? [account] : [])
	const [selectedAccount, setSelectedAccount] = useState(account)
	// prefill the account name in the add/edit account view
	const [newAccountName, setNewAccountName] = useState(
		account?.id === 'clientAddition' ? account.Name : ''
	)
	const [newAccountId, setNewAccountId] = useState(
		account?.id === 'clientAddition' ? account.id : ''
	)
	const [useProposerName, setUseProposerName] = useState(account?.Name === proposerName)

	const filterAndSetAccounts = async () => {
		const newAccounts = account ? [...associatedAccounts, account] : associatedAccounts
		const uniqueAccounts = newAccounts
			.map(account => account.id)
			.filter((id, index, self) => self.indexOf(id) === index)
			.map(id => newAccounts.find(account => account.id === id))
		setAccounts(uniqueAccounts)
	}

	useEffect(() => {
		if (contactId) {
			filterAndSetAccounts()
		}
	}, [])

	const addAccount = (newAccount = null) => {
		const accountsCopy = [...accounts]
		let toAdd = {
			id: newAccountId,
			Name: newAccountName,
		}
		if (newAccount) {
			toAdd = newAccount
		}
		accountsCopy.push(toAdd)
		setAccounts(accountsCopy)
		setSelectedAccount(toAdd)
		setActiveScreen('account-list-view')
	}

	const editAccount = () => {
		const accountsCopy = [...accounts]
		const itemIndex = accountsCopy.findIndex(item => item.id === 'clientAddition')
		accountsCopy[itemIndex].Name = newAccountName
		setAccounts(accountsCopy)
		setActiveScreen('account-list-view')
	}

	const proceedWithAccount = () => {
		const itemIndex = accounts.findIndex(item => item.id === 'clientAddition')
		if (itemIndex == -1) {
			selectAccount(selectedAccount, false)
		} else {
			const newlyAdded = accounts[itemIndex]
			const isNewlyCreatedNotSelected = newlyAdded.id !== selectedAccount.id
			selectAccount(selectedAccount, isNewlyCreatedNotSelected)
		}
	}

	const handleProposerNameSelection = () => {
		if (!useProposerName) {
			addAccount({
				id: 'proposerNameSelection',
				Name: proposerName,
			})
		} else {
			const accountsCopy = accounts.filter(
				entities => entities.id !== 'proposerNameSelection' && entities.Name !== proposerName
			)
			setAccounts(accountsCopy)
			setSelectedAccount(null)
		}
		setUseProposerName(!useProposerName)
	}

	const handleSelectProposerForAddEditFlow = () => {
		if (!useProposerName) {
			setNewAccountName(proposerName)
			setNewAccountId('proposerNameSelection')
		} else {
			setNewAccountName('')
			setNewAccountId('clientAddition')
		}
		setUseProposerName(!useProposerName)
	}

	const handleNewAccountNameChange = e => {
		const { value } = e.target
		if (value) {
			setNewAccountId('clientAddition')
		} else {
			setNewAccountId('')
		}
		setNewAccountName(e.target.value)
	}

	let activeView, activeTitle

	const showProposerNameCheckbox = !accounts.some(
		account => account.Name === proposerName && account.id !== 'proposerNameSelection'
	)

	const disableAccountAddition = showProposerNameCheckbox && useProposerName

	switch (activeScreen) {
		case 'account-list-view': {
			const accountList = accounts.map(account => {
				return (
					<div className="d-flex align-items-center justify-content-between" key={account.id}>
						<div className="d-flex align-items-center">
							<Radio
								value={selectedAccount?.id === account.id}
								onChange={() => setSelectedAccount(account)}
							/>
							<Space x={0.5} />
							<Text color={theme.colors.gray2} fontSize="18px">
								{account.Name}
							</Text>
						</div>
						{account.id === 'clientAddition' && (
							<Icon onClick={() => setActiveScreen('edit-view')} name="edit" />
						)}
					</div>
				)
			})
			const hasAlreadyAdded = accounts.find(account => account.id === 'clientAddition')

			activeTitle = (
				<Text type="primary" color={theme.colors.red}>
					Name on policy
				</Text>
			)
			activeView = (
				<>
					<div className="d-flex flex-column align-items-start w-100">
						{showProposerNameCheckbox && (
							<>
								<Checkbox
									text={`Use "${proposerName}"`}
									value={useProposerName}
									textStyle={{ color: '#6D6D6D' }}
									onChange={handleProposerNameSelection}
								/>
								<div className="d-flex align-items-center w-100">
									<Divider />
									<span className="mx-3" style={{ color: '#333333', fontSize: '14px' }}>
										OR
									</span>
									<Divider />
								</div>
							</>
						)}
						<AddAccountContainer className="w-100" disabled={disableAccountAddition}>
							{disableAccountAddition && <AddAccountBlocker />}
							<ColoredBox className="w-100 d-flex flex-column">
								<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
									SELECT/ADD A NAME BELOW:
								</Text>
								<Text className="mt-2" color={theme.colors.secondary} fontWeight={700}>
									NOTE - THIS WILL BE USED AS THE PRIMARY NAME FOR POLICY
								</Text>
								{accountList.length ? <Divider top="1" bottom="0" /> : null}
								{accountList}
							</ColoredBox>
							{!hasAlreadyAdded && (
								<Button
									className="mt-3"
									bgColor={theme.colors.white}
									icon="add"
									iconStyles={{
										marginRight: '0.5rem',
									}}
									label={`Add a new name`}
									onClick={() => {
										setActiveScreen('add-view')
									}}
									style={{
										padding: '0 0.5rem',
									}}
									textColor={theme.colors.primary}
								/>
							)}
						</AddAccountContainer>
						<Button
							label="Proceed"
							primary
							disabled={!selectedAccount}
							onClick={proceedWithAccount}
							style={{ height: '48px', alignSelf: 'flex-end', width: '180px' }}
							className="mt-3"
						/>
					</div>
				</>
			)
			break
		}
		case 'add-view':
		case 'edit-view': {
			const isAddMode = activeScreen === 'add-view'
			let accountExists
			if (isAddMode) {
				accountExists = accounts.find(account => account.Name === newAccountName)
			} else {
				accountExists = accounts
					.filter(account => account.id !== 'clientAddition')
					.find(account => account.Name === newAccountName)
			}
			activeTitle = (
				<div className="d-flex align-items-center">
					<Icon
						style={{ cursor: 'pointer' }}
						name="leftArrow"
						onClick={() => {
							setActiveScreen('account-list-view')
							setNewAccountName('') // reset input
						}}
					/>
					<Space x={0.3} />
					<Text color={theme.colors.red} fontSize="24px" fontWeight="700">
						{isAddMode ? `Adding a New Name on policy` : 'Edit Name'}
					</Text>
				</div>
			)
			activeView = (
				<div className="d-flex flex-column align-items-center w-100">
					{showProposerNameCheckbox && isAddMode && (
						<FullWidth className="d-flex flex-column mt-3">
							<Checkbox
								text={`Use "${proposerName}"`}
								value={useProposerName}
								textStyle={{ color: '#6D6D6D' }}
								onChange={handleSelectProposerForAddEditFlow}
							/>
							<div className="d-flex align-items-center w-100">
								<Divider />
								<span className="mx-3" style={{ color: '#333333', fontSize: '14px' }}>
									OR
								</span>
								<Divider />
							</div>
						</FullWidth>
					)}
					<AddAccountContainer
						className="d-flex flex-column align-items-center w-100"
						disabled={disableAccountAddition}
					>
						{disableAccountAddition && <AddAccountBlocker />}
						<FullWidth disabled={disableAccountAddition}>
							<Input
								value={newAccountName}
								onChange={handleNewAccountNameChange}
								label={'Name on policy'}
								compulsory
								placeholder="Enter"
								errorLabel={accountExists ? `Name already exists` : ''}
							/>
						</FullWidth>
					</AddAccountContainer>
					<Space y={1.5} />
					<Button
						disabled={!newAccountName || !!accountExists}
						onClick={isAddMode ? () => addAccount() : editAccount}
						style={{ width: '200px', height: '48px' }}
						label={isAddMode ? 'Add' : 'Edit'}
					/>
				</div>
			)
			break
		}
		default:
			activeView = null
			break
	}

	return (
		<Modal show={show} handleClose={handleClose} footer={<></>} title={activeTitle}>
			{activeView}
		</Modal>
	)
}

export default AccountSelectionModal

const ColoredBox = styled.div`
	background: #e9eff1;
	padding: 16px;
	border-radius: 10px;
`

const AddAccountContainer = styled.div`
	position: relative;
	opacity: ${props => (props.disabled ? 0.5 : 1)};
`

const AddAccountBlocker = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10000;
	cursor: not-allowed;
`

const FullWidth = styled.div`
	width: 90% !important;
	input {
		width: 100% !important;
	}
`
