import { nanoid } from 'nanoid'

// searchable select example options
const cityOp = [
	{ name: 'Mumbai', value: 'Mumbai' },
	{ name: 'Bengaluru', value: 'Bengaluru' },
	{ name: 'Delhi', value: 'Delhi' },
	{ name: 'Pune', value: 'Pune' },
]

const ageOfConstructionOps = [
	{ op: '1 to 5 years', val: { Low: 1, High: 5 } },
	{ op: '5 to 10 years', val: { Low: 5, High: 10 } },
	{ op: '10 to 15 years', val: { Low: 10, High: 15 } },
	{ op: '15 to 20 years', val: { Low: 15, High: 20 } },
	{ op: '20+ years', val: { Low: 20, High: 100 } },
]

const FinancialInsTypeOps = ['Bank', 'NBFC', 'Other']

const capitalize = str => `${str[0].toUpperCase()}${str.substring(1, str.length).toLowerCase()}`

// defaults for different sections
// used some for third party
const otherUsageDefaults = {
	Category: '',
	SubCategory: '',
	id: 'other-usage-default',
}

const coOwnwerDefaults = {
	name: '',
	id: 'co-owner-default',
}

const hypothecationPartyDefaults = {
	Name: '',
	Type: '',
	id: 'hypothecation-party-default',
}

const primaryPropertyTypes = [
	'Manufacturing or other Industrial Property',
	'Dwellings, Office, Shop or Hotel',
	'Utility located outside of manufacturing unit',
	'Storage facility outside of manufacturing unit',
	'Tank or gas storage facility outside of manufacturing unit',
]

const stateOps = [
	{
		name: 'Andaman and Nicobar Islands',
		value: 'Andaman and Nicobar Islands',
	},
	{
		name: 'Andhra Pradesh',
		value: 'Andhra Pradesh',
	},
	{
		name: 'Arunachal Pradesh',
		value: 'Arunachal Pradesh',
	},
	{
		name: 'Assam',
		value: 'Assam',
	},
	{
		name: 'Bihar',
		value: 'Bihar',
	},
	{
		name: 'Chandigarh',
		value: 'Chandigarh',
	},
	{
		name: 'Chhattisgarh',
		value: 'Chhattisgarh',
	},
	{
		name: 'Dadra and Nagar Haveli',
		value: 'Dadra and Nagar Haveli',
	},
	{
		name: 'Daman and Diu',
		value: 'Daman and Diu',
	},
	{
		name: 'Delhi',
		value: 'Delhi',
	},
	{
		name: 'Goa',
		value: 'Goa',
	},
	{
		name: 'Gujarat',
		value: 'Gujarat',
	},
	{
		name: 'Haryana',
		value: 'Haryana',
	},
	{
		name: 'Himachal Pradesh',
		value: 'Himachal Pradesh',
	},
	{
		name: 'Jammu and Kashmir',
		value: 'Jammu and Kashmir',
	},
	{
		name: 'Jharkhand',
		value: 'Jharkhand',
	},
	{
		name: 'Karnataka',
		value: 'Karnataka',
	},
	{
		name: 'Kerala',
		value: 'Kerala',
	},
	{
		name: 'Ladakh',
		value: 'Ladakh',
	},
	{
		name: 'Lakshadweep',
		value: 'Lakshadweep',
	},
	{
		name: 'Madhya Pradesh',
		value: 'Madhya Pradesh',
	},
	{
		name: 'Maharashtra',
		value: 'Maharashtra',
	},
	{
		name: 'Manipur',
		value: 'Manipur',
	},
	{
		name: 'Meghalaya',
		value: 'Meghalaya',
	},
	{
		name: 'Mizoram',
		value: 'Mizoram',
	},
	{
		name: 'Nagaland',
		value: 'Nagaland',
	},
	{
		name: 'Odisha',
		value: 'Odisha',
	},
	{
		name: 'Puducherry',
		value: 'Puducherry',
	},
	{
		name: 'Punjab',
		value: 'Punjab',
	},
	{
		name: 'Rajasthan',
		value: 'Rajasthan',
	},
	{
		name: 'Sikkim',
		value: 'Sikkim',
	},
	{
		name: 'Tamil Nadu',
		value: 'Tamil Nadu',
	},
	{
		name: 'Telangana',
		value: 'Telangana',
	},
	{
		name: 'Tripura',
		value: 'Tripura',
	},
	{
		name: 'Uttar Pradesh',
		value: 'Uttar Pradesh',
	},
	{
		name: 'Uttarakhand',
		value: 'Uttarakhand',
	},
	{
		name: 'West Bengal',
		value: 'West Bengal',
	},
]

export {
	cityOp,
	stateOps,
	capitalize,
	ageOfConstructionOps,
	FinancialInsTypeOps,
	otherUsageDefaults,
	coOwnwerDefaults,
	hypothecationPartyDefaults,
	primaryPropertyTypes,
}
