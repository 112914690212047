import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext } from 'react'
import { hypothecationDefault, stockDefault } from './defaults'
import { Context } from '../../../context/Provider'

import { Text, theme, Modal, Space, MainContainer, Button } from 'verak-ui'
import { determineEdgeCase1 } from '../../../utilities/determineEdgeCase1'
import {
	prefillHypothecation,
	prefillStockType,
	prefillThirdParty,
} from '../../../utilities/prefillStockData'
import { ZeroToOne1, ZeroToOne2, OneToMany } from '../../EdgeCase1/Modals'

import Address from './Address'
import ThirdPartyStorage from './ThirdPartyStorage'
import StockType from './StockType'
import { useFetch } from '../../../api/apihook'
import StockDetailsSavedModalContent from './StockDetailsSavedModalContent'
import { useAPIStateContext } from '../../../context/APIStateProvider'

const DetailsCollectionTemplate = ({
	secondaryContainerStyle,
	primaryContainerStyle,
	propertyType,
	levelIndex,
	currentLocationId,
	isInEdgeCase2Journey,
	children,
	type,
}) => {
	const { isSuccessAPI } = useAPIStateContext()
	const fetch = useFetch()
	let history = useHistory()
	const location = useLocation()
	const [state, dispatch] = useContext(Context)

	// Edge case handling
	const [modalState, setModalState] = useState(false)
	const [modalContent, setModalContent] = useState(null)
	const [modalTitle, setModalTitle] = useState('')
	const numStock = state.quote?.NumStock
	const openModal = () => {
		setModalState(true)
	}
	const handleProceedForZeroToOne = () => {
		setModalContent(<ZeroToOne2 propertyType={propertyType} levelIndex={levelIndex} />)
	}

	// returning from an edit flow
	const returnPath = location?.state?.returnPath || ''

	// getting the data to be prefilled in Address.js
	const Type = state.location?.Type
	let addressData = null
	if (Type === 'PROPERTYBASEMENT') {
		addressData = state.location?.property
	} else {
		addressData = state.location
	}

	// Address.js
	const [hypothecationList, setHypothecationList] = useState(
		prefillHypothecation(state.location, propertyType, levelIndex)
	)
	const incrementHypothecation = () => {
		setHypothecationList([...hypothecationList, { ...hypothecationDefault }])
	}
	const removeHypothecationFromList = idx => {
		const updatedHypothecation = [...hypothecationList]
		updatedHypothecation.splice(idx, 1)
		setHypothecationList(updatedHypothecation)
	}
	const onHypothecationFieldChange = (idx, value, hypothecationAttribute) => {
		const updatedList = hypothecationList.map((el, elIdx) => {
			if (elIdx === idx) {
				return { ...el, [hypothecationAttribute]: value }
			}
			return el
		})
		setHypothecationList(updatedList)
	}

	// StockType.js
	const [detailsList, setDetailsList] = useState(
		prefillStockType(state.location, propertyType, levelIndex)
	)
	const incrementStockType = () => {
		setDetailsList([...detailsList, { ...stockDefault }])
	}
	const decrementStockType = idx => {
		const updatedStockType = [...detailsList]
		updatedStockType.splice(idx, 1)
		setDetailsList(updatedStockType)
	}

	const stockWarningModalContent = (
		<div class="d-flex flex-column">
			<Text color={theme.colors.red} fontSize="20px" fontWeight="bold">
				Nature of stock should not exceed 300 characters
			</Text>
			<Button
				style={{ marginTop: '1rem', height: '48px' }}
				onClick={() => {
					setModalContent(null)
					setModalTitle('')
					setModalState(false) // close modal
				}}
				label="Close"
			/>
		</div>
	)

	const onStockFieldChange = (idx, value, stockAttribute) => {
		const updatedList = detailsList.map((el, elIdx) => {
			let computedValue = value
			if (stockAttribute !== 'Type' && stockAttribute !== 'GoodsHeldInTrust') {
				if (value || value === 0) {
					computedValue = Number(value)
				} else {
					computedValue = ''
				}
			}
			if (elIdx === idx) {
				if (stockAttribute === 'Type' && computedValue.length > 300) {
					// show warning modal
					openModal()
					setModalTitle('Warning')
					setModalContent(stockWarningModalContent)
				} else {
					return { ...el, [stockAttribute]: computedValue }
				}
			}
			return el
		})
		setDetailsList(updatedList)
	}
	const autoCalculate = (idx, value, destination) => {
		// Function to handle "%" changes in Floater stock types
		let numberVal = 0
		if (value && !isNaN(value)) {
			numberVal = Number(value)
		}

		let detailsListCopy = [...detailsList]

		if (detailsListCopy?.[idx]) {
			detailsListCopy[idx][destination] = 100 - numberVal
		}

		setDetailsList(detailsListCopy)
	}

	// ThirdPartyStorage.js
	const [hasThirdPartyStock, setThirdPartyStock] = useState(
		prefillThirdParty(state.location, propertyType, levelIndex)?.Present
	)
	const [thirdPartyList, setThirdPartyList] = useState(
		prefillThirdParty(state.location, propertyType, levelIndex)?.Details
	)
	const thirdPartyStockPresent = () => {
		// used to toggle "Yes"
		setThirdPartyStock(true)
	}
	const thirdPartyStockAbsent = () => {
		// used to toggle "No"
		setThirdPartyStock(false)
	}
	const incrementNature = () => {
		// handling addition of "select" tags
		setThirdPartyList([...thirdPartyList, ''])
	}
	const decrementNature = index => {
		// handling deletion of "select" tags
		const updateList = [...thirdPartyList]
		updateList.splice(index, 1)
		setThirdPartyList(updateList)
	}
	const setThirdPartyValueAtIndex = (index, value) => {
		const updateList = [...thirdPartyList]
		updateList[index] = value
		setThirdPartyList(updateList)
	}
	const getValidHypothecationList = () => {
		let validList = []
		hypothecationList.forEach(item => {
			if (item.Name && item.Type) {
				validList.push(item)
			}
		})
		return validList
	}

	// proceed button state toggling
	const disableProceed = disclosureMethod => {
		if (type === 'Floater') {
			const inCompleteValueIndex = detailsList.findIndex(el => {
				return !el.Type
			})
			return inCompleteValueIndex > -1
		} else {
			const incompleteDeclarationValueIndex = detailsList.findIndex(el => {
				const isValidAmountClosed = el.AmountClosed || el.AmountClosed === 0
				const isValidAmountOpen = el.AmountOpen || el.AmountOpen === 0

				return !(el.Type && isValidAmountClosed && isValidAmountOpen)
			})

			return incompleteDeclarationValueIndex > -1
		}
	}
	const disableProceedState = disableProceed(state.quote?.StockDisclosureMethod)

	// edge case handling
	const edgeCase1Trigger = async () => {
		await fetch.trackRfqEvent('Triggered edge case 1')

		const edgeCase = determineEdgeCase1(numStock, state.quote.RiskLocations)
		if (edgeCase === '0to1') {
			openModal()
			setModalTitle('Notice')
			setModalContent(<ZeroToOne1 handleProceedForZeroToOne={handleProceedForZeroToOne} />)
		} else if (edgeCase === '1to2') {
			openModal()
			setModalTitle('Notice')
			setModalContent(
				<OneToMany
					propertyType={propertyType}
					levelIndex={levelIndex}
					currentLocationId={currentLocationId}
				/>
			)
		} else if (returnPath) {
			history.push(returnPath)
		} else {
			history.replace({
				pathname: '/property-risk/sum-insured',
				state: {
					propertyType: propertyType,
					levelIndex: levelIndex,
				},
			})
		}
	}

	const onStockDetailsSave = () => {
		// if the API request was successful, show the popup
		// else directly trigger the edge case flow -> [An error popup can come in here]
		if (isSuccessAPI) {
			openModal()
			setModalTitle('Success')
			setModalContent(<StockDetailsSavedModalContent onContinue={edgeCase1Trigger} />)
		} else {
			edgeCase1Trigger()
		}
	}

	const proceedForEdgeCase2 = async Stock => {
		// replacing old stock data with new - for the correct location

		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: propertyType,
				levelIndex: levelIndex,
				data: {
					Stock,
				},
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 1,
					StockDisclosureMethod: 'DECLARED_VALUE',
				},
			})

			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_STOCK_VALUE',
				data: {
					MaxValueAllLoc: 0,
					MaxValueOneLoc: 0,
				},
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: '',
				},
			})
			resolve()
		})

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push('/journey/risk-location-selection')
	}

	const proceed = async () => {
		// ideal case: as soon as he fills 1 the save & continue should be disabled till he fills the 2nd
		const updatedHypothecationList = getValidHypothecationList()

		const Stock = {
			Present: true,
			Hypothecation: updatedHypothecationList,
			Details: detailsList,
			ThirdPartyUsage: {
				Present: hasThirdPartyStock,
				Details: thirdPartyList,
			},
		}

		if (isInEdgeCase2Journey) {
			proceedForEdgeCase2(Stock)
		} else {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: propertyType,
					levelIndex: levelIndex,
					data: {
						Stock: Stock,
					},
				})
				resolve()
			})

			await new Promise(resolve => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES',
				})
				resolve()
			})

			await fetch.saveQuote()

			onStockDetailsSave()
		}
	}

	const goBack = () => {
		if (returnPath) {
			history.push(returnPath)
		} else if (isInEdgeCase2Journey) {
			window.history.back()
		} else {
			history.replace({
				pathname: '/property-risk/sum-insured',
				state: {
					propertyType: propertyType,
					levelIndex: levelIndex,
				},
			})
		}
	}

	// get the current level and display the appropriate level text
	let currentLevelNumber = ''
	let currentRL = {}
	let levelPrefix = ''
	let levelsText = ''

	const currentPropertyType = propertyType?.toLowerCase()

	if (currentPropertyType === 'property') {
		levelPrefix = 'Floor'
	} else if (currentPropertyType === 'basement') {
		levelPrefix = 'Level'
	}

	if (state?.location?.Type === 'PROPERTYBASEMENT') {
		if (currentPropertyType === 'property') {
			currentRL = state?.location?.property
		} else {
			currentRL = state?.location?.basement
		}
	} else {
		currentRL = state?.location
	}

	// if levels are present consider the level at levelIndex as the current risk location
	if (levelIndex !== -1) {
		currentRL = currentRL?.levels?.[levelIndex]
	}
	currentLevelNumber = currentRL?.Level || ''

	// generate different levels text for 'whole' risk locations
	if (currentRL?.SubType === 'Whole') {
		currentLevelNumber = Math.abs(currentLevelNumber)
		levelsText = `Whole ${currentPropertyType} (${currentLevelNumber} ${levelPrefix}s)`
	} else {
		levelsText = `${levelPrefix} ${currentLevelNumber} `
	}

	let PIN = state?.location?.PIN || ''
	if (state?.location?.Type === 'PROPERTYBASEMENT') {
		PIN = state?.location?.property?.PIN || ''
	}

	const locationLabelAndPIN = `${state?.location?.label} - PIN (${PIN})`

	const renderLevelData = (
		<Text fontSize="24px" fontWeight="700" color={theme.colors.secondary}>
			{locationLabelAndPIN}
			<LevelSpan>{levelsText}</LevelSpan>
		</Text>
	)

	return (
		<MainContainer
			header="Stock details"
			top={1.5}
			rightOnClick={proceed}
			leftOnClick={goBack}
			rightDisabled={disableProceedState}
		>
			{renderLevelData}
			<Space y={1} />
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<Space y={1} />
			<Text type="primary" color={theme.colors.gray7}>
				Please enter the stock information:
			</Text>
			<Address
				data={addressData}
				hypothecationList={hypothecationList}
				incrementHypothecation={incrementHypothecation}
				decrementHypothecation={removeHypothecationFromList}
				onHypothecationFieldChange={onHypothecationFieldChange}
				secondaryContainerStyle={secondaryContainerStyle}
			/>
			{children}
			<StockType
				detailsList={detailsList}
				incrementStockType={incrementStockType}
				decrementStockType={decrementStockType}
				autoCalculate={autoCalculate}
				onStockFieldChange={onStockFieldChange}
				secondaryContainerStyle={secondaryContainerStyle}
				type={type}
			/>
			<ThirdPartyStorage
				details={thirdPartyList}
				setValueOnIndex={setThirdPartyValueAtIndex}
				decrementNature={decrementNature}
				thirdPartyStock={hasThirdPartyStock}
				incrementNature={incrementNature}
				thirdPartyStockPresent={thirdPartyStockPresent}
				thirdPartyStockAbsent={thirdPartyStockAbsent}
				primaryContainerStyle={primaryContainerStyle}
			/>
			<Modal
				show={modalState}
				// NOTE: this has been done to disable the close button on the edgecase modals
				// handleClose={() => setModalState(false)}
				closeButton={false}
				title={
					<Text type="primary" color={theme.colors.red}>
						{modalTitle}
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				{modalContent}
			</Modal>
		</MainContainer>
	)
}

export default DetailsCollectionTemplate

export const CompulsoryStar = () => (
	<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
)

const LevelSpan = styled.span`
	color: #fff;
	background-color: ${theme.colors.secondary};
	padding: 0.5rem 1.5rem;
	border-radius: 62px;
	font-size: 18px;
	margin-left: 1rem;
`

export const Container = styled.div`
	padding: 1rem;
	border-radius: 8px;
	margin-top: 1.5rem;
	width: 100%;
`
export const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`
export const StyledLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: ${theme.type.weights.medium};
	margin-bottom: 0.5rem;
`
export const selectStyle = {
	border: '0.5px solid #C9C9C9',
	width: '360px',
	height: '48px',
}
export const secondarySelectStyle = {
	border: `0.5px solid ${theme.colors.primary}`,
	backgroundColor: `${theme.colors.white}`,
}
export const textStyle = {
	fontSize: '18px',
	fontWeight: 700,
}
export const buttonStyle = {
	padding: 0,
	background: 'transparent',
}
