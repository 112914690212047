import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import PriceMatch from '../../../../../assets/training/PriceMatch.svg'
import PremiumDiscount from '../../../../../assets/training/PremiumDiscount.svg'
import MarutiSuzuki from '../../../../../assets/training/MarutiSuzuki.svg'
import LICBima from '../../../../../assets/training/LICBima.svg'

export default function CheatSheet_Convincing() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={LICBima}>
						<InfoText fontWeight="700">LIC Jeevan Bima Example</InfoText>
					</InfoBox>
					<ul className="text-italic">
						<li>Death pe Life Insurance se paise hai family ko, but business insurance ka kya?</li>
						<li>Bike/Car ke liye Motor Insurance, but business ke liye kya?</li>
						<li>Business loss, what about family?</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={MarutiSuzuki}>
						<InfoText fontWeight="700">Maruti Suzuki car example</InfoText>
					</InfoBox>
					<ul>
						<li>₹5 Lac car = ₹8,000 premium</li>
						<li>₹5 Lac stock = ₹1,250 premium</li>
						<li>If customer insures car or bike - why not insure business?</li>
					</ul>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={PremiumDiscount}>
						<InfoText fontWeight="700">Premium Discount</InfoText>
					</InfoBox>
					<ul>
						<li>What theft protection measures?</li>
						<li>What fire protection measures?</li>
						<li>GST number use krke 18% savings paiye</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={PriceMatch}>
						<InfoText fontWeight="700">Price Match (Best Price Guaranteed)</InfoText>
					</InfoBox>
					<ul>
						<li>Will give customer best price</li>
						<li>
							Kahin se kam premium par yeh policy mil rahi hai, toh aap hume batao aur hum aapke
							liye ussi premium mein options la sakte hien.
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
