import { useContext, useEffect, useRef, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { Text, theme, Icon, Modal, Input, Button } from 'verak-ui'
import { useHistory, useLocation } from 'react-router'
import { nanoid } from 'nanoid'
import md5 from 'js-md5'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { DraftTopBar } from './components'
import ExistingClientSelectionFlow from './components/ExistingClientFlow'
import DraftRFQFormHandler from './DraftRFQFormHandler'
import FinalReviewScreen from '../FinalReview/FinalReview'

import { ComponentContext } from '../../context/ComponentProvider'
import { useContextApi } from '../../context/Provider'
import { useLoadingOverlay } from '../../components/LoadingOverlay'
import { useFetch } from '../../api/apihook'

import { initialRiskLocationData } from './defaultData'
import { getCorrectDateFormat } from '../../utilities/getCorrectDateFormat'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../FinalReview/finalReviewUtils'
import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import { isFireAllRiskPolicy } from '../../utilities/isFireAllRiskPolicy'
import { isManufacturingOccupancy } from '../../utilities/checkManufacturing'
import { calculateNumStock } from '../../utilities/calculateNumStock'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { autosaveDraft } from '../../utilities/autosaveDraft'
import { getMaterialType } from '../../utilities/getMaterialType'

import ClaimsDoc from '../../assets/ClaimsDoc.png'

import { ReferenceMaterial, MultiNudgeContainer, NotesContainer } from '../SalesAmmunition'
import { hideNOBforBusinessTypes } from './components/PrimaryOccupancySelector'
import { parsePhoneNumber } from '../../utilities/parsePhoneNo'
import { checkIfRenewalsFlow, getRedirectLink } from '../../utilities/renewalsUtils'

const responsiveBreakpoint = '816px'

const BottomSectionDiv = styled.div`
	display: flex;
	height: calc(100% - 80px - 0.5rem);
	padding: 0 12px;
	@media (max-width: ${responsiveBreakpoint}) {
		flex-direction: column;
	}
`

const CityToast = ({ city }) => (
	<>
		CITY <b>({city})</b> is captured for this user, <b>please use local case study.</b>
	</>
)

export const ClaimsImageModal = ({ show, handleClose }) => {
	return (
		<Modal title={<></>} show={show} handleClose={handleClose} footer={<></>} size="lg">
			<img src={ClaimsDoc} style={{ width: '100%' }} alt="claims doc" />
		</Modal>
	)
}

export const NearestCustomerModal = ({ show, phone = '', handleClose, onSendDetails }) => {
	const [phoneInput, setPhoneInput] = useState(phone)
	return (
		<Modal
			title={
				<Text fontSize={'18px'} color={theme.colors.primary} fontWeight="700">
					Send Customer’s Details
				</Text>
			}
			show={show}
			handleClose={handleClose}
			footer={<></>}
		>
			<Input
				label="WhatsApp Number"
				className="w-100"
				value={phoneInput}
				onChange={event => setPhoneInput(event.target.value)}
				compulsory
			/>
			<Button
				label="Send Details"
				className="mt-3 w-100"
				disabled={phoneInput.length !== 10}
				onClick={() => onSendDetails(phoneInput)}
			/>
		</Modal>
	)
}

const AddressReceived = ({ show, handleClose, readClientResponse }) => {
	return (
		<Modal title={'Wohooo!'} show={show} handleClose={handleClose} footer={<></>} size="sm">
			<div className="d-flex flex-column align-items-center text-center">
				<div
					className="d-flex align-items-center justify-content-center mb-3"
					style={{ width: '85px', height: '85px', backgroundColor: '#E8FFF2' }}
				>
					<Icon name={'locationPerson'} fill={theme.colors.white} />
				</div>
				<Text fontSize={'16px'} color={theme.colors.primary} fontWeight={'700'} className="mb-3">
					Customer has entered the risk location address
				</Text>
				<Button
					className="me-3"
					textColor={theme.colors.white}
					bgColor={theme.colors.primary}
					label={'Read Client’s Response'}
					icon="whatsapp"
					iconStyles={{ marginRight: '0.5rem' }}
					onClick={readClientResponse}
				/>
			</div>
		</Modal>
	)
}

const DraftRFQScreen = () => {
	const location = useLocation()
	const form = useRef()
	const noteRef = useRef()
	const history = useHistory()
	const [state, dispatch] = useContextApi()
	const {
		createDraftRFQ,
		updateDraftRFQ,
		mergeRiskLocations,
		createRfq,
		createAccount,
		saveQuote,
		sendRfqEmailPilot,
		getCaseStudies,
		getNearestCustomerInfo,
		sendNearestCustInfo,
		registerEventListener,
		getDraftRFQ,
		fetchQuotes,
		getLeadsByPhone,
		getIncidentStats,
	} = useFetch()

	const isRenewalsFlow = checkIfRenewalsFlow()
	const { width } = useWindowDimensions()
	const isMobile = width < 768

	const { setRenderSidePanel } = useContext(ComponentContext)

	const {
		Account,
		Contact,
		Notes,
		Data,
		Tag = '',
		_id,
		Lead,
		chatbotUrl,
		PreviousPolicyDetails,
	} = location.state

	let AntiTheftDefaultValue
	if (Account?.AntiTheft) {
		if (Account?.AntiTheft === 'true') {
			AntiTheftDefaultValue = true
		}
	}
	const [totalSI, setTotalSI] = useState(0)
	const [draftId, setDraftId] = useState(_id)
	const [alreadyExist, setAlreadyExist] = useState(false)
	const [viewState, setViewState] = useState('draft') //draft & review
	const [caseStudies, setCaseStudies] = useState([])
	const [incidentStats, setIncidentStats] = useState({})
	const [nearestCustomers, setNearestCustomers] = useState([])
	const [showClaimsModal, setShowClaimsModal] = useState(false)
	const [showNearestCustomerPopup, setShowNearestCustomerPopup] = useState(null)
	const [showAddressReceivedModal, setShowAddressReceivedModal] = useState(false)
	const [addressChatlog, setAddressChatlog] = useState(() => (chatbotUrl ? chatbotUrl : ''))
	const [safetyToggle, setSafetyToggle] = useState(() => {
		if (Tag === 'SAFETY_PLAN') {
			return { disabled: false, value: true }
		}
		return { disabled: false, value: false }
	})

	const sumInsuredContainerRef = useRef(null)

	useEffect(() => {
		const onMessageReceived = (result, agentId) => {
			if (result.data !== 'connected') {
				const parsedData = JSON.parse(result.data)
				if (parsedData.agent === agentId) {
					if (parsedData.name === 'WA_ADDRESS_RECEIVED') {
						if (parsedData?.data?.type === 'DraftRfq' && parsedData?.data?.id === draftId) {
							// check if rfq id is also same then display popup
							setShowAddressReceivedModal(true)
							if (parsedData?.data?.url) {
								setAddressChatlog(parsedData.data.url)
							}
						}
					} else if (parsedData.name === 'DRAFTRFQ_DETAILS_RECEIVED') {
						if (parsedData?.data?.id === draftId) {
							if (parsedData?.data?.url) {
								setAddressChatlog(parsedData.data.url)
							}
						}
					}
				}
			}
		}

		const onMessageError = err => {
			// console.log("$$$$$ Error :: ",err)
		}
		let eventListener
		registerEventListener(onMessageReceived, onMessageError).then(ref => {
			eventListener = ref
		})

		return () => {
			if (eventListener) {
				eventListener.close()
			}
		}
	}, [])

	const LeadSource = Account?.lead_source ? Account?.lead_source : Account.LeadSource
	const [account, setAccount] = useState({
		...Account,
		LeadSource: LeadSource,
		createdAt: Lead?.createdAt,
		City: Lead?.City,
		State: Lead?.State,
		Platform: Lead?.Platform,
	})

	const [contact, setContact] = useState({
		...Contact,
		WaPhone: Contact?.WaPhone?.replace('+91', '') || Contact?.Phone?.replace('+91', '') || '',
		WaConsent: typeof Contact?.WaConsent === 'boolean' ? Contact?.WaConsent : true,
	})
	const [pincodeDataFetched, setPincodeDataFetched] = useState(false)
	const [cityToastTriggered, setCityToastTriggered] = useState(false)
	const [gstToastTriggered, setGSTToastTriggered] = useState(false)
	const [selectedState, setSelectedState] = useState(null)

	// Safety plan details
	const [cctvUnits, setCCTVUnits] = useState(
		Data?.Hardware?.find(i => i?.Key === 'CCTV_01')?.Units || 1
	)

	// Money Insurance Data
	const moneyInsurance = ['CIS', 'CIC', 'CIT']
	const PolicyAddons = Data?.PolicyAddons || {}
	const getMIAddons = () => {
		return Object.keys(PolicyAddons)?.filter(
			x => PolicyAddons?.[x]?.Present === true && moneyInsurance.includes(x)
		)
	}
	const [miAddons, setMIAddons] = useState(getMIAddons())
	const [CICData, setCICData] = useState(Data?.CIC)
	const [CISData, setCISData] = useState(Data?.CIS)
	const [CITData, setCITData] = useState(Data?.CIT)

	const addonRefs = {
		CIS: CISData,
		CIT: CITData,
		CIC: CICData,
	}

	const getAddonAmount = addon => {
		if (miAddons.includes(addon)) {
			return addonRefs[addon]?.Amount
		}
		return 0
	}

	const addonSI = {
		CIC: getAddonAmount('CIC'),
		CIS: getAddonAmount('CIS'),
		CIT: getAddonAmount('CIT'),
	}

	// existing client flow
	const [accountId, setAccountId] = useState('')
	const [contactId, setContactId] = useState('')
	const [existingClient, setExistingClient] = useState(false)
	const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(false)

	const onAccountEdit = () => {
		setShowAccountSelectionModal(true)
	}

	const closeAccountSelectionModal = () => {
		setShowAccountSelectionModal(false)
	}

	const { RiskLocations, ...genericData } = Data || {}

	const hasValidOccupancy = occupancyString => {
		// check if occupancy string contains valid Type of Facility and Nature of Business
		// stored as {Type}:-{Nature}
		const occupancy = occupancyString.split(':-')
		// if for eg. Motor showroom, etc is selected as facility type, skip nature of business check
		if (hideNOBforBusinessTypes.includes(occupancy?.[0])) {
			return Boolean(occupancy[0])
		}
		return Boolean(occupancy[0]) && Boolean(occupancy[1])
	}

	const [riskLocations, setRiskLocations] = useState(() => {
		const facilityType = Lead?.FacilityType || ''
		const businessType = Lead?.BusinessType || ''
		const pincode = Lead?.PIN || ''
		const address = Account?.Address || ''
		const natureOfStock = Lead?.NatureOfStock || ''
		const rawMaterial = Lead?.RawMaterial || ''
		const finishedGoods = Lead?.FinishedGoods || ''

		const stockSIBreakdown = Lead?.SumInsuredBreakdown?.Stock || ''

		const stockItem = {
			AmountClosed: '',
			Type: '',
			AmountOpen: 0,
			OpenPer: 0,
			ClosedPer: 0,
		}
		const getStockList = () => {
			let stockList = []
			// stock si to be only mapped to finished goods in cases where both raw material and finished goods are present
			const bothSIPresent = rawMaterial && finishedGoods
			if (rawMaterial) {
				stockList.push({
					...stockItem,
					Type: `Raw Material:${rawMaterial}`,
					AmountClosed: bothSIPresent ? '' : stockSIBreakdown,
				})
			}
			if (finishedGoods) {
				stockList.push({
					...stockItem,
					Type: `Finished goods:${finishedGoods}`,
					AmountClosed: stockSIBreakdown,
				})
			}
			if (natureOfStock) {
				stockList.push({
					...stockItem,
					Type: `Finished goods:${natureOfStock}`,
					AmountClosed: stockSIBreakdown,
				})
			}
			return stockList
		}

		const getOccupancy = (facilityType, businessType) => {
			// business type is empty for facilities like: motor vehicle showroom
			if (!businessType) {
				return facilityType
			}
			return `${facilityType}:-${businessType}`
		}

		if (RiskLocations && RiskLocations.length > 0) {
			// prefill pre-prospected data if occupancy is not set
			let firstRL = RiskLocations[0]
			if (!firstRL?.PrimaryOccupancy?.SubCategory && (facilityType || businessType)) {
				firstRL.PrimaryOccupancy.SubCategory = getOccupancy(facilityType, businessType)
			}
			if (!firstRL?.PIN && pincode) {
				firstRL.PIN = pincode
			}
			if (!firstRL?.AddressLine1 && address) {
				firstRL.AddressLine1 = address
			}
			if (!firstRL?.Stock?.Present && !firstRL?.Stock?.Details?.length) {
				const stockList = getStockList()
				if (stockList.length) {
					firstRL.Stock = {
						Present: true,
						Details: stockList,
					}
				}
			}

			return [...RiskLocations]
		}
		const dummyValues = JSON.parse(JSON.stringify(initialRiskLocationData))

		const Ownership = Lead?.FacilityOwnership || ''
		const SIBreakdown = Lead?.SumInsuredBreakdown || {}
		const WallMaterial = Lead?.WallMaterial || ''
		const RoofMaterial = Lead?.RoofMaterial || ''
		const Floor = Lead?.FacilityFloor || ''

		if (Floor) {
			if (Floor === 'Whole Building') {
				dummyValues.Type = 'PROPERTY'
			} else if (!isNaN(Floor)) {
				if (Floor > -1) {
					dummyValues.Type = 'PROPERTY'
					dummyValues.Level = Floor
				} else {
					dummyValues.Type = 'BASEMENT'
					dummyValues.Level = Floor
				}
			}
		}

		if (Ownership) {
			dummyValues.Ownership = Ownership
		}

		dummyValues.SumInsured.forEach(item => {
			if (SIBreakdown?.[item.Name]) {
				item.Amount = SIBreakdown[item.Name]
			} else if (item.Name === 'BuildingValue' && SIBreakdown?.Property) {
				item.Amount = SIBreakdown['Property']
			} else {
				item.Amount = ''
			}
		})

		if (facilityType === 'Factory/Manufacturer') {
			dummyValues.TheftRisk.FactoryType = 'Working'
		}

		if (AntiTheftDefaultValue) {
			dummyValues.TheftRisk.SecurityArrangements = ['Anti-theft locks']
		}

		if (facilityType || businessType) {
			dummyValues.PrimaryOccupancy.SubCategory = getOccupancy(facilityType, businessType)
		}
		if (pincode) {
			dummyValues.PIN = pincode
		}
		if (address) {
			dummyValues.AddressLine1 = address
		}
		const stockList = getStockList()
		if (stockList.length) {
			dummyValues.Stock = {
				Present: true,
				Details: stockList,
			}
		} else {
			dummyValues.Stock.Details = [stockItem]
		}

		if (!dummyValues['Materials']) dummyValues['Materials'] = {}

		if (WallMaterial || RoofMaterial) {
			const wallMaterialType = getMaterialType(WallMaterial)
			const roofMaterialType = getMaterialType(RoofMaterial)

			dummyValues.Materials.Walls = {
				Type: wallMaterialType || roofMaterialType,
				Description: WallMaterial,
			}
			dummyValues.Materials.Roof = {
				Type: roofMaterialType || wallMaterialType,
				Description: RoofMaterial,
			}
			dummyValues.Materials.Ceiling = {
				Type: roofMaterialType || wallMaterialType,
				Description: RoofMaterial,
			}

			// if either wall or roof is kutcha, floor is set to kutcha
			const isWallOrRoofKutcha = wallMaterialType === 'Kutcha' || roofMaterialType === 'Kutcha'

			let floorType = isWallOrRoofKutcha ? 'Kutcha' : 'Pucca'

			let floorMaterial = WallMaterial
			if (isWallOrRoofKutcha && roofMaterialType === 'Kutcha') {
				floorMaterial = RoofMaterial
			}

			dummyValues.Materials.Floors = {
				Type: floorType,
				Description: floorMaterial,
			}
		}

		return [dummyValues]
	})

	const [policyInfo, setPolicyInfo] = useState(() => {
		const natureOfStock = Lead?.NatureOfStock || ''
		const rawMaterial = Lead?.RawMaterial || ''
		const finishedGoods = Lead?.FinishedGoods || ''

		let policyData = genericData ? genericData : {}
		if (natureOfStock || rawMaterial || finishedGoods) {
			policyData = {
				...policyData,
				PolicyKind: 'BUILDING_AND_STOCK',
			}
		}
		return policyData
	})

	const [provisionalQuoteData, setProvisionalQuoteData] = useState(() => {
		return {
			provisionalSI: Lead?.SumInsured || '',
			provisionalPremium: Lead?.ProvisionalQuote || '',
		}
	})

	const fetchProvisionalQuoteData = async (
		parsedPhoneNo,
		showLoader = true,
		handleTimeout = false,
		hideErrorPopup = true
	) => {
		const result = await getLeadsByPhone(
			parsedPhoneNo,
			'phone',
			showLoader,
			handleTimeout,
			hideErrorPopup
		)
		const provisionalPremium = result?.Data?.ProvisionalQuote
		const provisionalSI = result?.Data?.AssetValue
		if (provisionalPremium && provisionalSI) {
			setProvisionalQuoteData({
				provisionalSI,
				provisionalPremium,
			})
		}
	}

	useEffect(() => {
		const parsedPhoneNo = parsePhoneNumber(contact?.Phone)
		const { provisionalPremium, provisionalSI } = provisionalQuoteData
		if (parsedPhoneNo.length === 10 && !provisionalPremium && !provisionalSI) {
			fetchProvisionalQuoteData(parsedPhoneNo)
		}
	}, [contact?.Phone])

	const { setIsLoading, setLoadingText, setErrorState } = useLoadingOverlay()

	const setLoadingState = ({ status, message }) => {
		setIsLoading(status)
		setLoadingText(message)
	}

	const fetchCaseStudies = async () => {
		if (!selectedState?.City && !selectedState?.State) {
			setCaseStudies([])
			return
		}
		const result = await getCaseStudies(selectedState?.City, selectedState?.State)
		setCaseStudies(result)
	}

	const fetchIncidentStats = async () => {
		if (!selectedState?.City && !selectedState?.State) {
			setIncidentStats({})
			return
		}

		let cityValue = selectedState?.City?.toUpperCase()
		let stateValue = selectedState?.State?.toUpperCase()

		switch (true) {
			case cityValue?.includes('DELHI') || stateValue?.includes('DELHI'): {
				cityValue = 'DELHI'
				break
			}
			default:
		}

		const result = await getIncidentStats(cityValue, stateValue)
		setIncidentStats(result)
	}

	const fetchNearestCustomer = async () => {
		if (selectedState?.City || selectedState?.PIN) {
			const result = await getNearestCustomerInfo(selectedState?.City, selectedState?.PIN)
			if (result && result.cases) {
				setNearestCustomers(result.cases)
			}
		}
	}

	const fetchAllNudgesData = () => {
		fetchCaseStudies()
		fetchNearestCustomer()
		fetchIncidentStats()
	}

	useEffect(() => {
		if (Account?.PIN) {
			// if pincode is present, wait until pin code api is resolved
			if (pincodeDataFetched) {
				fetchAllNudgesData()
			}
		} else {
			fetchAllNudgesData()
		}
	}, [selectedState?.City, selectedState?.State, pincodeDataFetched]) // eslint-disable-line

	useEffect(() => {
		setRenderSidePanel(false)

		if (account?.City) {
			setSelectedState({
				City: account?.City,
				State: account?.State,
			})
			setCityToastTriggered(true)

			if (!cityToastTriggered && !isMobile) {
				toast.error(<CityToast city={account?.City} />, {
					autoClose: false,
				})
			}
		} else if (pincodeDataFetched) {
			setCityToastTriggered(true)

			if (!cityToastTriggered && !isMobile) {
				toast.error(<CityToast city={selectedState?.City} />, {
					autoClose: false,
				})
			}
		}
	}, [pincodeDataFetched, selectedState?.City]) // eslint-disable-line

	useEffect(() => {
		if (Lead?.GSTIN && !gstToastTriggered && !isMobile) {
			const gstToast = (
				<>
					<b>Customer has provided GST Number</b>, its a good lead and the customer is serious
				</>
			)
			toast.info(gstToast, { autoClose: false })
			setGSTToastTriggered(true)
		}
	}, [])

	const prepareData = () => {
		const { riskLocations, policyInfo } = form?.current?.getData()
		const accountData = account
		const contactData = contact

		riskLocations.forEach(rItem => {
			let validSI = []
			rItem.SumInsured.forEach(sItem => {
				if (sItem.Amount) {
					validSI.push({ ...sItem })
				}
			})
			rItem.SumInsured = validSI
		})

		const policyStartDate = new Date()
		policyStartDate.setDate(policyStartDate.getDate() + 2)

		let noteValue = Notes
		if (!isMobile) {
			noteValue = noteRef?.current?.value
		}

		const WANo = contactData.WaPhone
			? contactData.WaPhone.startsWith('+91')
				? contactData.WaPhone
				: `+91${contactData.WaPhone}`
			: ''

		let assignedLeadsNo = ''
		if (contactData?.Phone) {
			assignedLeadsNo = contactData?.Phone?.startsWith('+91')
				? contactData.Phone
				: `+91${contactData.Phone}`
		}

		// Safety plan
		let safetyPlanData = []
		if (safetyToggle?.value) {
			// Note: This doesn't work for the autosave draft RFQ call
			const products = [
				{
					Key: 'CCTV_01',
					Units: parseInt(cctvUnits),
				},
				{
					Key: 'MEMORY_01',
					Units: parseInt(cctvUnits),
				},
			]
			safetyPlanData = [...products]
		}

		// Money insurance
		let moneyInsuranceData = {}
		if (miAddons?.includes('CIC')) {
			moneyInsuranceData['CIC'] = CICData
		}
		if (miAddons?.includes('CIS')) {
			moneyInsuranceData['CIS'] = CISData
		}
		if (miAddons?.includes('CIT')) {
			moneyInsuranceData['CIT'] = CITData
		}
		let PolicyAddons = { BURGLARY_THEFT: { Present: true } }
		miAddons?.forEach(addOn => {
			PolicyAddons[addOn] = { Present: true }
		})

		const data = {
			Contact: {
				Name: contactData.Name,
				Phone: assignedLeadsNo,
				UID: contactData.UID,
				Email: contactData.Email?.trim() || '',
				WaPhone: WANo,
				WaConsent: contactData.WaConsent || false,
				AlternateProductInterest: contactData.AlternateProductInterest || '',
			},
			Account: {
				Name: accountData.Name,
				Type: 'CORPORATE',
				UID: accountData.UID,
				LeadSource: accountData.LeadSource,
				Platform: accountData.Platform,
			},
			Data: {
				...policyInfo,
				RiskLocations: riskLocations,
				NumRiskLoc: riskLocations.length,
				Cover: {
					Duration: 'M12',
					StartDate: getCorrectDateFormat(policyStartDate),
				},
				...moneyInsuranceData,
				Hardware: safetyPlanData,
				PolicyAddons: PolicyAddons,
			},
			Notes: noteValue,
			Tag: safetyToggle.value ? 'SAFETY_PLAN' : isFireAllRiskPolicy(PolicyAddons),
			Type: isRenewalsFlow ? 'RENEWAL' : 'NEW',
			_id: draftId ? draftId : '',
		}

		return data
	}

	useEffect(() => {
		let timer = null
		if (viewState === 'draft') {
			timer = setInterval(() => {
				if (viewState !== 'draft') return // form is unmounted
				const draftData = prepareData()
				if (draftData) {
					autosaveDraft(draftData)
				}
			}, 3000)
		}
		return () => {
			if (timer) {
				clearInterval(timer)
			}
		}
	}, [viewState]) // eslint-disable-line

	const getRFQData = () => {
		const draftRFQ = prepareData()

		draftRFQ.Data.StockDisclosureMethod = 'DECLARED_VALUE'

		draftRFQ.Data.RfqType = 'New'
		if (isRenewalsFlow) {
			draftRFQ.Data.Type = 'RENEWAL'
		}

		let PolicyAddons = { BURGLARY_THEFT: { Present: true } }
		miAddons?.forEach(addOn => {
			PolicyAddons[addOn] = { Present: true }
		})
		draftRFQ.Data.PolicyAddons = PolicyAddons
		const riskLocations = draftRFQ?.Data?.RiskLocations || []
		const numStock = calculateNumStock(riskLocations)

		if (numStock) {
			draftRFQ.Data.NumStock = numStock
		}

		const SIBreakdown = generateSumInsuredBreakdown(riskLocations)
		const totalSI = totalSumInsuredForAllRiskLocations(SIBreakdown) || 0
		const totalStock =
			calculateTotalStockVal({
				RiskLocations: riskLocations,
				StockDisclosureMethod: 'DECLARED_VALUE',
			}) || 0
		let value = totalSI + totalStock

		let toSetPolicyName = ''
		if (value < 50000000) {
			toSetPolicyName = 'BSUS'
		} else if (value => 50000000 && value < 500000000) {
			toSetPolicyName = 'BLUS'
		} else if (value >= 500000000) {
			toSetPolicyName = 'SFSP'
		}

		draftRFQ.Data.PolicyName = toSetPolicyName //BLUS,BSUS,SFSP

		draftRFQ.Data.RiskLocations.forEach(rItem => {
			rItem.ID = nanoid()
			rItem.GroupHash = md5(`${rItem.AddressLine1},${rItem.PIN}`)
		})

		return { draftRFQ, totalSI }
	}

	const displayReviewScreen = async () => {
		// save draft first
		await triggerSave()
		const { draftRFQ, totalSI } = getRFQData()

		const location = draftRFQ.Data.RiskLocations[0]
		if (!location?.SubType) {
			location.SubType = 'Single'
		}

		const mergedData = mergeRiskLocations(draftRFQ.Data)

		dispatch({
			type: 'RESUME_QUOTE',
			data: {
				...mergedData,
			},
		})
		dispatch({
			type: 'SET_DRAFT_RFQ_VIEW_ONLY_MODE',
			data: {
				draftRFQ,
				totalSI,
			},
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_CLIENT_DETAILS',
				data: {
					ProposerName: draftRFQ.Contact.Name,
					CompanyName: draftRFQ.Account.Name,
					Phone: draftRFQ.Contact.Phone,
					Email: draftRFQ.Contact.Email,
					WaPhone: draftRFQ.Contact.WaPhone,
				},
			})
			resolve()
		})
		if (isRenewalsFlow) {
			await new Promise(resolve => {
				dispatch({
					type: 'SET_PREV_POLICY_DATA',
					data: { ...PreviousPolicyDetails },
				})
				resolve()
			})
		}
		setViewState('review')
	}

	const triggerInstantRFQ = async () => {
		setViewState('draft')

		const { draftRFQ, totalSI } = state?.rfq?.instantRFQData

		let toSend = {
			policyName: draftRFQ.Data.PolicyName,
			policyTag: safetyToggle.value
				? 'SAFETY_PLAN'
				: isFireAllRiskPolicy(draftRFQ.Data.PolicyAddons),
			draftRfqId: draftId,
			type: isRenewalsFlow ? 'RENEWAL' : 'NEW',
		}

		if (isRenewalsFlow || (existingClient && accountId && contactId)) {
			// skip account creation
			toSend.accountId = accountId
			toSend.contactId = contactId
		} else {
			let clientPhone = ''
			if (draftRFQ.Contact.Phone) {
				clientPhone = draftRFQ.Contact.Phone
			} else {
				return
			}

			clientPhone = clientPhone.replace('+91', '')
			const requestObject = {
				ProposerName: draftRFQ.Contact.Name,
				EntityName: draftRFQ.Account.Name,
				Phone: `+91${clientPhone}`,
				CrmAccountId: draftRFQ.Account.UID,
				LeadSource: draftRFQ.Account.LeadSource,
				Type: 'CORPORATE',
			}
			if (draftRFQ.Contact.WaPhone) {
				requestObject.WaPhone = draftRFQ.Contact.WaPhone?.startsWith('+91')
					? draftRFQ.Contact.WaPhone
					: `+91${draftRFQ.Contact.WaPhone}`
			}
			setLoadingState({ status: true, message: 'Creating new client' })
			const clientResult = await createAccount(requestObject)

			toSend.accountId = clientResult.account
			toSend.contactId = clientResult.contact
		}

		const response = await createRfq(toSend)

		const toMerge = {
			...draftRFQ.Data,
			quoteId: response.id,
		}

		const mergedData = mergeRiskLocations(toMerge)
		dispatch({
			type: 'RESUME_QUOTE',
			data: mergedData,
		})

		await saveQuote('', safetyToggle.value)

		dispatch({
			type: 'RESET_INSTANT_RFQ_DATA',
		})

		const payload = {
			waConsent: true,
			waPhone: draftRFQ.Contact.WaPhone,
			instantRfq: true,
		}
		if (draftRFQ?.Contact?.Email) {
			payload.email = draftRFQ.Contact.Email
		}

		await sendRfqEmailPilot(payload)

		if (isRenewalsFlow) {
			history.push('/renewals/choose-insurer')
			return
		}

		const result = await fetchQuotes(toMerge.quoteId)
		if (result?.quotes?.length > 0) {
			const policyDuration = mergedData.Cover.Duration

			const modifiedQuotes = result.quotes.map(quote => {
				return {
					...quote,
					Cover: totalSI,
					Duration: policyDuration,
				}
			})

			let SumInsuredBreakdown = result?.SumInsuredBreakdown

			history.replace('/rfq-status/quotes-ready/compare-quotes', {
				modifiedQuotes,
				SumInsuredBreakdown: SumInsuredBreakdown,
			})
		} else {
			history.replace('/quotes-in-progress')
		}
	}

	const triggerSave = async () => {
		const data = prepareData()

		if (draftId) {
			await updateDraftRFQ(draftId, data)
			return draftId
		} else {
			const response = await createDraftRFQ(data)
			if (response.id) {
				setDraftId(response.id)
				return response.id
			}
		}
	}

	const onSaveClick = async () => {
		const res = await triggerSave()
		history.push(getRedirectLink(isRenewalsFlow, '/draft-rfqs'))
		return res
	}

	const proceedToStartRfq = async () => {
		// save draft first
		await triggerSave()

		const data = prepareData()

		let PolicyAddons = { BURGLARY_THEFT: { Present: true } }
		miAddons?.forEach(addOn => {
			PolicyAddons[addOn] = { Present: true }
		})
		dispatch({
			type: 'SET_POLICY_ADD_ONS',
			data: {
				PolicyAddons: PolicyAddons,
			},
		})

		dispatch({
			type: 'SET_DRAFT_RFQ',
			data: data,
		})

		const toMerge = {
			...data.Data,
			quoteId: '',
			Type: isRenewalsFlow ? 'RENEWAL' : 'NEW',
		}

		toMerge.RiskLocations.forEach((item, index) => {
			item.SubType = 'Single'
			item.ID = nanoid()
			item.GroupHash = index
		})
		const mergedData = mergeRiskLocations(toMerge)
		mergedData.RiskLocations.forEach(item => {
			item.SubType = ''
		})

		dispatch({
			type: 'RESUME_QUOTE',
			data: mergedData,
		})

		// set contact data in context
		dispatch({
			type: 'SET_CLIENT_DETAILS',
			data: {
				ProposerName: data?.Contact.Name,
				CompanyName: data?.Account.Name,
				Phone: data?.Contact?.Phone,
			},
		})

		if (isRenewalsFlow) {
			dispatch({
				type: 'SET_PREV_POLICY_DATA',
				data: { ...PreviousPolicyDetails },
			})
		}

		const locationState = {
			accountId: data?.Account.UID,
			contactId: data?.Contact.UID,
			leadSource: data.Account?.LeadSource,
			draftRFQ: data,
		}

		history.push('/new-quote/select-policy-type', locationState)
	}

	const addr = riskLocations?.[0]?.AddressLine1 || ''

	const disableSaveDraft = useMemo(() => {
		if (alreadyExist) {
			return true
		}
		const validWaPhone = contact.WaPhone?.length === 10
		const validEmail = contact?.Email ? /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(contact.Email) : true
		const hasLocationType = riskLocations?.every(location => location.Type)
		const hasOwnershipType = riskLocations?.every(location => location.Ownership)
		const checkMaterial = material => {
			const { Type, Description } = material || {}
			let materialCheck = ''
			if (Type === 'Kutcha') {
				materialCheck = Type && Description
			} else {
				materialCheck = Type
			}
			return materialCheck
		}

		const hasValidPrimaryOccupancy = riskLocations?.every(location => {
			return !!hasValidOccupancy(location.PrimaryOccupancy.SubCategory)
		})

		const hasValidAddress = riskLocations?.every(location => {
			return (
				!!location.AddressLine1 &&
				!!location.City &&
				!!location.State &&
				location.PIN &&
				location.PIN.length === 6
			)
		})

		const hasMaterialTypes = riskLocations?.every(location => {
			const isBasement = location.Type === 'BASEMENT'
			const materials = location?.Materials || {}
			let roofMaterial = {}
			if (isBasement) {
				roofMaterial = materials?.Ceiling || {}
			} else {
				roofMaterial = materials?.Roof || {}
			}

			const roofCheck = checkMaterial(roofMaterial)
			const floorCheck = checkMaterial(materials?.Floors)
			const wallCheck = checkMaterial(materials?.Walls)

			return Boolean(roofCheck && floorCheck && wallCheck)
		})

		let hasSIBreakup = false
		// console.log(policyInfo)
		if (policyInfo?.PolicyKind === 'STOCK_ONLY') {
			hasSIBreakup = true
		} else {
			hasSIBreakup = riskLocations?.every(location => {
				// stock SI was entered, make this non-mandatory
				if (location?.Stock?.Details?.[0]?.AmountClosed) {
					return true
				}

				const sumInsuredItems = location?.SumInsured || []

				const someInputsFilled = sumInsuredItems.some(el => parseInt(el.Amount))

				const ValuebleContentsAmt =
					parseFloat(sumInsuredItems.find(el => el.Name === 'ValuableContents')?.Amount) || 0
				const OtherContentsAmt =
					parseFloat(sumInsuredItems.find(el => el.Name === 'Other')?.Amount) || 0
				const MaxValSingleItemAmt =
					parseFloat(sumInsuredItems.find(el => el.Name === 'MaxValSingleItem')?.Amount) || 0

				const singleItemMandatory = Boolean(
					MaxValSingleItemAmt || ValuebleContentsAmt || OtherContentsAmt
				)

				const isMaxValSingleItemLess =
					ValuebleContentsAmt >= MaxValSingleItemAmt || OtherContentsAmt >= MaxValSingleItemAmt

				const maxSingleItemError = Boolean(
					singleItemMandatory && (!MaxValSingleItemAmt || !isMaxValSingleItemLess)
				)

				return Boolean(someInputsFilled && !maxSingleItemError)
			})
		}

		let validStockValue
		if (policyInfo?.PolicyKind === 'BUILDING_ONLY') {
			validStockValue = true
		} else {
			validStockValue = riskLocations?.some(location => {
				const isPresent = location?.Stock?.Present

				if (isPresent) {
					const toReturn = location?.Stock?.Details.every(sItem => {
						const tList = sItem?.Type ? sItem?.Type?.split(':') : []
						return (
							tList.length === 2 &&
							tList[0].length > 0 &&
							tList[1].length > 0 &&
							!!sItem?.AmountClosed
						)
					})
					return toReturn
				} else {
					return isPresent
				}
			})
		}

		let validFactory = riskLocations.every(lItem => {
			const isManufacturing = isManufacturingOccupancy(lItem.PrimaryOccupancy?.SubCategory)

			if (isManufacturing) {
				return !!lItem.TheftRisk?.FactoryType
			} else {
				return true
			}
		})

		// check for valid level no
		let validLevel = riskLocations.every(lItem => {
			return lItem.Level !== ''
		})

		// check for money insurance add ons
		let validMIInfo = true
		if (miAddons.length) {
			if (validMIInfo && miAddons.includes('CIS')) {
				if (
					!(
						CISData?.Amount &&
						typeof CISData?.IsSafeLocked === 'boolean' &&
						CISData?.SafeDetails &&
						typeof CISData?.IsCCTVPresent === 'boolean' &&
						CISData?.Address
					)
				)
					validMIInfo = false
			}
			if (validMIInfo && miAddons.includes('CIT')) {
				if (
					!(
						CITData?.DistTransit &&
						CITData?.Amount &&
						CITData?.EstAmountPerTransit &&
						CITData?.Source &&
						CITData?.Destination &&
						CITData?.CarriageMode &&
						CITData?.CarriedBy &&
						CITData?.Address
					)
				)
					validMIInfo = false
			}
			if (validMIInfo && miAddons.includes('CIC')) {
				if (
					!(
						CICData?.Amount &&
						typeof CICData?.IsCounterLocked === 'boolean' &&
						typeof CICData?.IsCCTVPresent === 'boolean' &&
						CICData?.Address
					)
				)
					validMIInfo = false
			}
		}

		// check for safety plan data
		let validSafetyPlanDetails = true
		if (safetyToggle?.value) {
			if (!cctvUnits) {
				validSafetyPlanDetails = false
			}
		}

		return !(
			account.Name &&
			contact.Name &&
			validWaPhone &&
			hasValidAddress &&
			validEmail &&
			policyInfo.PolicyKind &&
			hasLocationType &&
			hasValidPrimaryOccupancy &&
			validFactory &&
			hasOwnershipType &&
			hasMaterialTypes &&
			hasSIBreakup &&
			validStockValue &&
			validLevel &&
			validMIInfo &&
			validSafetyPlanDetails
		)
	}, [
		alreadyExist,
		account,
		contact,
		riskLocations,
		policyInfo,
		miAddons,
		CICData,
		CISData,
		CITData,
		cctvUnits,
	])

	const onPolicyKindChange = value => {
		if (value === 'BUILDING_ONLY') {
			// traverse all locations and remove stocks
			const toUpdate = [...riskLocations]
			toUpdate.forEach(item => {
				item.Stock = {
					...item.Stock,
					Present: false,
					Details: [],
				}
			})
		} else {
			const toUpdate = [...riskLocations]
			toUpdate.forEach(item => {
				// update only if stock details is empty
				if (!item?.Stock?.Details?.length) {
					item.Stock = {
						...item.Stock,
						Present: false,
						Details: [
							{
								AmountClosed: '',
								Type: '',
								AmountOpen: 0,
								OpenPer: 0,
								ClosedPer: 0,
							},
						],
					}
				}
			})
		}
	}

	if (viewState === 'review') {
		const backToDraft = () => {
			setViewState('draft')
		}
		return <FinalReviewScreen onBack={backToDraft} onProceed={triggerInstantRFQ} />
	}
	const openNearestCustomerPopup = value => {
		setShowNearestCustomerPopup(value)
	}

	const triggerSendingCustInfo = async contactNo => {
		await sendNearestCustInfo({
			CompanyName: showNearestCustomerPopup.LegalEntityName,
			CustomerName: showNearestCustomerPopup.CustomerName,
			Address: showNearestCustomerPopup.Address,
			ProposerPhone: contactNo,
			ProposerName: contact.Name,
		})
		setShowNearestCustomerPopup(null)
	}

	const readClientResponse = async () => {
		const result = await getDraftRFQ(draftId)
		const updatedList = [...result.Data.RiskLocations]
		setRiskLocations(updatedList)
		setShowAddressReceivedModal(false)
	}

	return (
		<Container fluid style={{ height: '100%', background: '#F2F2F2', padding: '0' }}>
			<DraftTopBar
				account={{ ...account, address: addr }}
				contact={{ ...contact }}
				triggerSave={onSaveClick}
				displayReviewScreen={displayReviewScreen}
				proceedToStartRfq={proceedToStartRfq}
				disableMake={!draftId}
				disableSaveDraft={disableSaveDraft}
				addressChatlog={addressChatlog}
				safetyToggle={safetyToggle}
				setSafetyToggle={setSafetyToggle}
				previousPolicyDetails={PreviousPolicyDetails}
			/>
			<BottomSectionDiv id="draft-screen">
				<DraftRFQFormHandler
					accountData={account}
					contactData={contact}
					riskLocations={riskLocations}
					policyInfo={policyInfo}
					draftId={draftId}
					ref={form}
					setTotalSI={setTotalSI}
					setAccountData={setAccount}
					setContactData={setContact}
					miAddons={miAddons}
					CICData={CICData}
					CISData={CISData}
					CITData={CITData}
					setMIAddons={setMIAddons}
					setCICData={setCICData}
					setCISData={setCISData}
					setCITData={setCITData}
					safetyToggle={safetyToggle}
					cctvUnits={cctvUnits}
					setCCTVUnits={setCCTVUnits}
					setRiskLocations={setRiskLocations}
					setPolicyInfo={setPolicyInfo}
					setSelectedState={setSelectedState}
					pincodeDataFetched={pincodeDataFetched}
					setPincodeDataFetched={setPincodeDataFetched}
					onPolicyKindChange={onPolicyKindChange}
					saveDraft={triggerSave}
					onAccountEdit={onAccountEdit}
					existingClient={existingClient}
					sumInsuredContainerRef={sumInsuredContainerRef}
				/>

				{!isMobile && (
					<SalesAmmunitionSection className="d-flex flex-column">
						<ReferenceMaterial companyName={account?.Name} />
						<div className="mt-2 d-flex align-items-center">
							<MultiNudgeContainer
								caseStudyProps={{
									selectedState: selectedState?.State,
									selectedCity: selectedState?.City,
									caseStudies,
								}}
								nearestCustomerProps={{
									nearestCustomerList: nearestCustomers,
									sendCustomerDetails: openNearestCustomerPopup,
								}}
								premiumCalculatorProps={{
									totalSI,
									addonSI,
									provisionalQuoteData,
									sumInsuredContainerRef,
									safetyPlanData: { safetyToggle, cctvUnits },
								}}
								incidentStatsProps={{
									selectedState: selectedState?.State,
									selectedCity: selectedState?.City,
									incidentStats,
								}}
							/>
							<NotesContainer ref={noteRef} notes={Notes} />
						</div>
					</SalesAmmunitionSection>
				)}
			</BottomSectionDiv>

			<ClaimsImageModal
				show={showClaimsModal}
				handleClose={() => {
					setShowClaimsModal(false)
				}}
			/>
			{showNearestCustomerPopup ? (
				<NearestCustomerModal
					show={showNearestCustomerPopup}
					phone={contact.WaPhone}
					handleClose={() => {
						setShowNearestCustomerPopup(null)
					}}
					onSendDetails={triggerSendingCustInfo}
				/>
			) : null}
			<AddressReceived
				show={showAddressReceivedModal}
				readClientResponse={readClientResponse}
				handleClose={() => {
					setShowAddressReceivedModal(false)
				}}
			/>
			<ExistingClientSelectionFlow
				currentAccountData={Account} // selected account data from location state
				setAccount={setAccount}
				contact={contact}
				setContact={setContact}
				existingClient={existingClient}
				setExistingClient={setExistingClient}
				setAccountId={setAccountId}
				contactId={contactId}
				setContactId={setContactId}
				showAccountSelectionModal={showAccountSelectionModal}
				closeAccountSelectionModal={closeAccountSelectionModal}
			/>
		</Container>
	)
}

export default DraftRFQScreen

// DRAFT NEW LAYOUT

const SalesAmmunitionSection = styled.div`
	height: 100%;
	width: 100%;
`

// test
