export const calculateNumStock = (RiskLocations) => {
    let total = 0
    const calObjStock = (obj) => {
        if (obj?.levels) {
            for (const level in obj?.levels) {
                if (obj?.levels[level]?.Stock?.Present) {
                    total = total + 1
                }
            }
        } else if (obj?.Stock?.Present) {
            total = total + 1
        }
    }

    for (const location in RiskLocations) { // RiskLocations is the array of RiskLocations
        if (RiskLocations[location].Type === "PROPERTYBASEMENT") {
            calObjStock(RiskLocations[location].property)
            calObjStock(RiskLocations[location].basement)
        } else {
            calObjStock(RiskLocations[location])
        }
    }
    return total
}
