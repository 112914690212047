import {
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	Button,
	Select,
	Text,
	Input,
	theme,
	SearchableSelect,
} from 'verak-ui'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'

import TableComponent from '../../components/Table'
import { HeaderContent } from '../SQS/QuoteInProgressComponents/quoteInProgressUtils'
import { useFetch } from '../../api/apihook'
import { getDateString } from '../../utilities/sqsDateUtils'
import { QuoteStatusTag } from '../../components/Tags'
import { useHistory } from 'react-router'
import { useRef } from 'react'

const states = {
	PENDING: 'Pending',
	PAST: 'Past',
}

const sectionWiseHeaderItems = {
	Pending: [
		{ key: 'status', content: <HeaderContent icon="statusSm" text="Status" /> },
		{ key: 'reason', content: <HeaderContent icon="bellIcon" text="Reason" /> },
		{ key: 'reassigningTo', content: <HeaderContent icon="userIcon" text="Reassigning To" /> },
	],
	Past: [
		{ key: 'reassigningTo', content: <HeaderContent icon="userIcon" text="Reassigning To" /> },
		{ key: 'authorizedBy', content: <HeaderContent icon="userIcon" text="Authorized By" /> },
		{ key: 'reason', content: <HeaderContent icon="bellIcon" text="Reason" /> },
	],
}

const getHeaderItems = activeSection => {
	return [
		{
			key: 'agentName',
			content: <HeaderContent icon="userIcon" text="Requested By" />,
		},
		{ key: 'clientName', content: <HeaderContent icon="buildingSm" text="Client Name" /> },
		{ key: 'requestDate', content: <HeaderContent icon="lastUpdatedSm" text="Request Date" /> },
		...sectionWiseHeaderItems[activeSection],
		{ key: 'actions', content: <HeaderContent icon="bellIcon" text="Actions" /> },
	]
}

const parseAgentNumber = email => {
	const regex = /\d+/g
	const matches = email.match(regex)
	return matches?.[0]
}

const reasonStrings = {
	TL_DECISION: "Team leader's decision",
	SOLD_BY_OTHER_REP: 'Other rep closed the sale',
	REP_LEFT_COMPANY: 'Rep has left the company',
}

const amruta_offline = { name: 'Amruta Supekar', email: 'amruta@verak.in' }

export default function ReassignmentRequests() {
	const history = useHistory()

	const { getPendingReassignmentRequests, getAgentList, handleReassignmentRequest } = useFetch()

	const [hasMore, setHasMore] = useState(false)
	const [cursor, setCursor] = useState('')
	const [loadingMore, setLoadingMore] = useState(false)

	const [activeSection, setActiveSection] = useState(states.PENDING) // valid states - PENDING, PAST
	const [pendingRequests, setPendingRequests] = useState([])
	const [pastRequests, setPastRequests] = useState([])
	const [searchQuery, setSearchQuery] = useState('')

	const [telesalesAgents, setTelesalesAgents] = useState([])
	const [offlineAgents, setOfflineAgents] = useState([])
	const [fosAgents, setFosAgents] = useState([])

	const refetchPastRequests = useRef(false)
	const table = useRef()

	// fetch all agent lists
	const fetchAgents = async type => {
		const response = await getAgentList(type, false)
		return response?.agents
	}

	const fetchAllAgents = async () => {
		const [telesales, offline, fos] = await Promise.all([
			fetchAgents('Online'),
			fetchAgents('Offline'),
			fetchAgents('FOS'),
		])
		setTelesalesAgents(telesales)
		setOfflineAgents(offline)
		setFosAgents(fos)
	}

	useEffect(() => {
		fetchAllAgents()
	}, [])

	// fetch reassignment requests

	const fetchPendingRequests = async (type, cursor) => {
		const prevRequests = type === states.PENDING ? pendingRequests : pastRequests
		if (prevRequests?.length) {
			setLoadingMore(true)
		}

		const result = await getPendingReassignmentRequests(type, cursor)

		type === states.PENDING
			? setPendingRequests(result?.requests)
			: setPastRequests(result?.requests)
		setCursor(result?.cursor)
		setHasMore(result?.hasMore)
	}

	useEffect(() => {
		const hasPendingrequests = pendingRequests.length > 0
		const hasPastRequests = pastRequests.length > 0

		if (!hasPendingrequests || !hasPastRequests || refetchPastRequests.current) {
			fetchPendingRequests(activeSection)

			if (refetchPastRequests.current) refetchPastRequests.current = false
		}
	}, [activeSection]) // eslint-disable-line

	// handle infinite scroll
	const handleScroll = () => {
		if (
			window.scrollY > 0 &&
			window.innerHeight + window.scrollY > table?.current?.offsetHeight &&
			!loadingMore &&
			hasMore
		) {
			fetchPendingRequests(activeSection, cursor)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [pendingRequests.length, pastRequests.length]) // eslint-disable-line

	const startRfq = () => {
		history.push('/new-quote')
	}

	const leftNavContent = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Reassignment Requests
		</Text>
	)

	const rightNavContent = (
		<Button
			bgColor={theme.colors.secondary}
			icon="plusIcon"
			iconColor={'white'}
			label={'Start an RFQ'}
			style={{ borderRadius: '5rem' }}
			iconStyles={{ marginRight: '0.5rem' }}
			onClick={startRfq}
		/>
	)

	const handleDataChange = (request, key, value) => {
		const requestsCopy = [...pendingRequests]
		const targetRequest = requestsCopy.find(r => r._id === request._id)

		const assignTo = targetRequest?.To

		if (key === 'vendor') {
			assignTo.vendor = value
		} else if (key === 'email') {
			assignTo.email = value
		}

		setPendingRequests(requestsCopy)
	}

	const getAgentSelector = request => {
		const isTelesalesTeam = ['CDI_Mahape', 'Spire_Mahape', 'Online'].includes(request?.To?.vendor) // Online / Telesales
		const isFOSTeam = request?.To?.vendor === 'FOS'

		let optionList
		const agentList = isTelesalesTeam
			? telesalesAgents
			: isFOSTeam
			? fosAgents
			: [...offlineAgents, amruta_offline]

		optionList = agentList
			.filter(agent => agent.email !== request?.From?.email)
			.map(agent => {
				let agentName = agent.name
				if ((isTelesalesTeam || isFOSTeam) && !isNaN(parseAgentNumber(agent.email))) {
					agentName = `Agent ${parseAgentNumber(agent.email)}`
				}
				return {
					name: agentName,
					value: agent?.email,
				}
			})

		const agentSelector = (
			<SearchableSelectWrapper>
				<SearchableSelect
					style={{ maxWidth: '320px' }}
					options={optionList}
					value={request?.To?.email}
					onChange={val => handleDataChange(request, 'email', val)}
					search
					placeholder="Select"
				/>
			</SearchableSelectWrapper>
		)

		return (
			<div className="d-flex flex-column">
				<Select
					style={{ height: '48px' }}
					className="mb-2"
					value={isTelesalesTeam ? 'Online' : request?.To?.vendor}
					onChange={val => handleDataChange(request, 'vendor', val)}
					placeholder="Select Team"
				>
					<option selected disabled>
						Select Team
					</option>
					<option value="Online">Tele-Sales</option>
					<option value="Offline">Offline</option>
					<option value="FOS">FOS</option>
				</Select>
				{agentSelector}
			</div>
		)
	}

	const handleRequestApproval = async (request, status) => {
		const result = await handleReassignmentRequest(request._id, request?.To?.email, status)
		if (!result.error) {
			toast.success(`Reassignment request ${status.toLowerCase()}`)

			// remove request from pending requests
			const requestsCopy = [...pendingRequests]
			const targetRequest = requestsCopy.find(r => r._id === request._id)
			const index = requestsCopy.indexOf(targetRequest)
			requestsCopy.splice(index, 1)
			setPendingRequests(requestsCopy)

			refetchPastRequests.current = true
		} else {
			toast.error('Error: ' + result.error)
		}
	}

	const getActionItems = request => {
		return (
			<div>
				<Button
					onClick={() => handleRequestApproval(request, 'APPROVED')}
					style={{ width: '120px', height: '40px' }}
					textColor="#4AA161"
					bgColor="#DFF9E6"
					label="APPROVE"
				/>
				<Button
					className="mt-2"
					onClick={() => handleRequestApproval(request, 'REJECTED')}
					style={{ width: '120px', height: '40px' }}
					textColor={theme.colors.red}
					bgColor="#F5DBDB"
					label="REJECT"
				/>
			</div>
		)
	}

	const getTableRow = request => {
		const { Rfq, From, To, Status, createdAt, Approver, Reason } = request

		let rowData = {
			agentName: <Text fontWeight="700">{From.name}</Text>,
			clientName: Rfq?.Account?.Name,
			requestDate: getDateString(createdAt),
			status: <QuoteStatusTag status={Rfq?.Status} metaStatus={{}} />,
			reassigningTo: getAgentSelector(request),
			actions: getActionItems(request),
			reason: reasonStrings[Reason],
		}

		if (activeSection === states.PAST) {
			return {
				...rowData,
				authorizedBy: Approver?.name,
				reassigningTo: To?.email,
				actions: (
					<Text fontWeight="700" color={Status === 'APPROVED' ? '#4AA161' : theme.colors.red}>
						{Status}
					</Text>
				),
			}
		}
		return rowData
	}

	const activeRequests = activeSection === states.PENDING ? pendingRequests : pastRequests
	let requestItems = []
	if (searchQuery?.length > 0) {
		requestItems = activeRequests
			.filter(
				request =>
					request?.From?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
					request?.Rfq?.Account?.Name?.toLowerCase().includes(searchQuery?.toLowerCase())
			)
			.map(request => getTableRow(request))
	} else {
		requestItems = activeRequests?.map(request => getTableRow(request))
	}

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				leftContent={leftNavContent}
				rightContent={rightNavContent}
				headerBg="white"
			/>
			<SwiwelSection className="mt-3 mb-4 ms-3 d-flex align-items-center">
				<SwiwelButton
					isSelected={activeSection === states.PENDING}
					onClick={() => setActiveSection(states.PENDING)}
					label="Pending Requests"
				/>
				<SwiwelButton
					isSelected={activeSection === states.PAST}
					onClick={() => setActiveSection(states.PAST)}
					label="Past Requests"
				/>
			</SwiwelSection>

			<StretchWidth className="mb-3">
				<SearchInput
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
					className="ms-3 w-100"
					placeholder="Search by Client name, Sales rep's name"
				/>
			</StretchWidth>

			<div ref={table} className="w-100">
				<TableComponent
					tableHeaderItems={getHeaderItems(activeSection)}
					tableDataItems={requestItems || []}
				/>
			</div>
			<ToastContainer />
		</PageContentAndNavbarWrapper>
	)
}

const SwiwelButton = ({ isSelected, onClick, label }) => {
	return (
		<SwiwelButtonWrapper isSelected={isSelected} onClick={onClick}>
			{label}
		</SwiwelButtonWrapper>
	)
}

const SwiwelSection = styled.div`
	background: ${theme.colors.secondary4};
	width: auto;
	border-radius: 60px;
	width: 520px;
	transition: all 0.1s ease;
	&:hover {
		background: #7ca2b0;
		color: ${theme.colors.primary};
	}
`

const SwiwelButtonWrapper = styled.div`
	width: 270px;
	border-radius: 60px;
	background: ${props => (props.isSelected ? theme.colors.primary : theme.colors.secondary4)};
	color: ${props => (props.isSelected ? 'white' : theme.colors.primary)};
	padding: 10px 30px;
	text-align: center;
	cursor: pointer;
	font-weight: 700;
	transition: all 0.1s ease;
	user-select: none;
	&:hover {
		background: ${props => (!props.isSelected ? '#7ca2b0' : theme.colors.primary)};
		color: ${props => (!props.isSelected ? theme.colors.primary : 'white')};
	}
`

const StretchWidth = styled.div`
	width: 75%;
`

const SearchInput = styled(Input)`
	border: none;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
	&:focus {
		border: none !important;
	}
`

const SearchableSelectWrapper = styled.div`
	max-width: 320px;
`
