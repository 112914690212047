export const getCorrectDateFormat = date => {
	if (!(date instanceof Date)) {
		return date
	}
	let year = date.getFullYear()
	let day = date.getDate()
	let month = date.getMonth() + 1

	if (day.toString().length === 1) {
		day = '0' + day
	}
	if (month.toString().length === 1) {
		month = '0' + month
	}

	return `${day}/${month}/${year}`
}

// this func parses string - 29/04/2021 -> Date Object
export const parseFormattedDate = (dateString = '') => {
	if (dateString instanceof Date) {
		return dateString
	}
	if (dateString !== '' && typeof dateString === 'string') {
		let resultDate = new Date()
		let values = dateString.split('/')

		if (values[0]) {
			resultDate.setDate(values[0])
		}
		if (values[1]) {
			resultDate.setMonth(parseInt(values[1]) - 1)
		}
		if (values[2]) {
			resultDate.setFullYear(values[2])
		}

		return resultDate
	}
	return new Date()
}

export const getDiffYears = date => {
	var ageDifMs = new Date(new Date().getFullYear(), 0, 1) - new Date(date, 0, 1)
	var ageDate = new Date(ageDifMs) // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970)
	return 3
}
