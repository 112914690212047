import styled from 'styled-components'
import { theme } from 'verak-ui'
import { CardStyle } from './commonStyle'

const Container = styled(CardStyle)`
	background: ${theme.colors.white};
`
const NotesCard = ({ value, setValue }) => {
	return (
		<TextArea
			className="flex-fill h-100"
			placeholder="Write Notes here"
			value={value}
			onChange={e => setValue(e.target.value)}
		></TextArea>
	)
}

export default NotesCard

const TextArea = styled.textarea`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	border: none;
	outline: none;
	padding: 0.9rem;
`
