import { Button, Text, Space, MainContainer } from 'verak-ui'

import { useHistory, useParams } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

const ConfirmScreen = () => {
	const history = useHistory()
	const fetch = useFetch()

	const { id } = useParams() // location id

	const [state, dispatch] = useContext(Context) // eslint-disable-line

	const onReview = () => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: id,
			},
		})
		history.push('/risk-location-review')
	}

	const onContinue = async () => {
		dispatch({ type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE' })
		await fetch.saveQuote()
		history.push('/journey/risk-location-selection')
	}
	return (
		<MainContainer header="Details confirmation" top={6}>
			<Text type="primary" className="mb-5">
				You have filled in all details for this risk location!
			</Text>
			<Button
				label="Review details"
				icon="magnifyingGlass"
				onClick={onReview}
				iconStyles={{ marginRight: '0.5rem' }}
				style={buttonStyle}
				className="d-flex justify-content-start"
				secondary
			/>
			<Space y={1.5} />
			<Button
				label="Continue"
				icon="save"
				onClick={onContinue}
				className="d-flex justify-content-start"
				iconStyles={{ marginRight: '0.5rem' }}
				style={buttonStyle}
			/>
		</MainContainer>
	)
}

export default ConfirmScreen

const buttonStyle = {
	width: '100%',
	height: '80px',
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: 700,
}
