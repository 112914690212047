import { theme, Text, Radio, Button, Input, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../components/SecondaryNavbar'
import { useHistory, useLocation } from 'react-router'
import styled, { keyframes } from 'styled-components'
import { useState } from 'react'
import confirmationLottie from '../../../assets/confirmationLottie.svg'

export default function RFQAbandoned() {
	const history = useHistory()

	const { state } = useLocation()

	const [selectedOption, setSelectedOption] = useState('')
	const [selectionComplete, setSelectionComplete] = useState(false)

	const [ReasonText, setReasonText] = useState('')

	const setOption = v => setSelectedOption(v)
	const setReason = v => setReasonText(v)

	const proceedDisabled = selectedOption.length === 0

	const confirmAction = () => {
		// API call: Abandon RFQ or Re-active RFQ

		setSelectionComplete(true)
	}

	const returnToQuotesInProgress = () => history.push('/quotes-in-progress')

	let navText = `Progress! , ${state?.uid} - ${state?.ClientName} - ${state?.PolicyType}`

	if (!selectionComplete) {
		navText = (
			<div className="d-flex align-items-center">
				{state?.PolicyType} ({state?.PolicyNumber})&nbsp;
				<Icon name="rightArrow" color={theme.colors.primary} /> Abandon RFQ, {state?.uid} -&nbsp;
				{state?.ClientName}
			</div>
		)
	}

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	let InnerContent
	if (!selectionComplete) {
		InnerContent = (
			<div
				style={{
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					borderRadius: '8px',
					width: '95%',
				}}
				className="container bg-white p-3 mt-4 mx-2 d-flex justify-content-between flex-column"
			>
				<div>
					<Text color={theme.colors.red} fontSize="24px" fontWeight="700" className="my-3">
						This RFQ has been abandoned
					</Text>

					<Text color={theme.colors.gray} fontSize="14px" fontWeight="400" className="my-3">
						This RFQ has been marked as abandoned as the customer failed to proceed further with
						their self-verification within 72hrs of RFQ generation. <br />
						You can close the RFQ by selecting Option 1 or re-activate the RFQ by selecting Option
						2.
					</Text>

					<Text color={theme.colors.gray} fontSize="14px" fontWeight="400" className="my-3">
						Select an option to proceed further
					</Text>
				</div>

				<div className="d-flex flex-column">
					<OptionButton
						ReasonText={ReasonText}
						setReason={setReason}
						selectedOption={selectedOption}
						setOption={setOption}
						topLabel="OPTION 1"
						mainLabel="Close this RFQ"
						onClick={() => {}}
					/>

					<OptionButton
						ReasonText={ReasonText}
						setReason={setReason}
						selectedOption={selectedOption}
						setOption={setOption}
						topLabel="OPTION 2"
						mainLabel="Re-activate this RFQ"
						onClick={() => {}}
					/>
				</div>

				<div
					className="d-flex justify-content-end align-items-center mt-2"
					style={{
						background: 'white',
						padding: '12px 24px',
						width: '100%',
						borderTop: '1.5px solid #02475E',
					}}
				>
					<Button
						disabled={proceedDisabled}
						label="Save and Continue"
						icon="rightArrow"
						onClick={confirmAction}
						iconAlign="right"
					/>
				</div>
			</div>
		)
	} else {
		InnerContent = (
			<>
				<div
					style={{
						boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
						borderRadius: '8px',
						width: '95%',
					}}
					className="container h-100 bg-white p-3 mt-4 mx-2 d-flex justify-content-between align-items-center flex-column"
				>
					<div className="d-flex justify-content-between align-items-center flex-column">
						<Text color={theme.colors.gray7} fontSize="24px" fontWeight="700">
							Thank you for the feedback
						</Text>

						<CustomDiv>
							<ConfirmationSuccessImage className="mb-4" src={confirmationLottie} />
						</CustomDiv>

						<Text color={theme.colors.primary} fontSize="18px" fontWeight="bold" className="mb-4">
							This helps us improve our {state?.RFQType} journeys!
						</Text>
					</div>

					<div
						className="d-flex justify-content-end align-items-center mt-2"
						style={{
							background: 'white',
							padding: '12px 24px',
							width: '100%',
							borderTop: '1.5px solid #02475E',
						}}
					>
						<Button
							label="Continue"
							icon="rightArrow"
							onClick={returnToQuotesInProgress}
							iconAlign="right"
						/>
					</div>
				</div>
			</>
		)
	}

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			style={{ height: selectionComplete && '80%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			{InnerContent}
		</PageContentAndNavbarWrapper>
	)
}

const OptionButton = ({
	topLabel,
	selectedOption,
	setOption,
	mainLabel,
	ReasonText,
	setReason,
	...props
}) => {
	let currentOption = topLabel.replace(' ', '')

	const noticeText =
		topLabel === 'OPTION 1' ? (
			<>
				Please give the reason for closing this RFQ<span style={{ color: '#eb5757' }}>*</span>
			</>
		) : (
			<>
				Please give the reason for re-activating this RFQ<span style={{ color: '#eb5757' }}>*</span>
			</>
		)

	const additionalNotice = topLabel === 'OPTION 1' && (
		<Text className="my-1" fontWeight="bold" color={theme.colors.secondary} fontSize="14px">
			Using this option will remove the RFQ from the system!
		</Text>
	)

	let ReasonSection
	if (selectedOption === currentOption) {
		// if option is selected
		ReasonSection = (
			<div className="d-flex flex-column">
				<Text className="my-1" color={theme.colors.gray2} fontSize="14px">
					{noticeText}
				</Text>
				<Input
					value={ReasonText}
					onChange={e => setReason(e.target.value)}
					className="my-1"
					placeholder="Free text response"
				/>
				{additionalNotice}
			</div>
		)
	}

	return (
		<OptionButtonWrapper {...props} className="mt-2 mb-2">
			<div className="d-flex align-items-center">
				<Radio
					name="options"
					value={selectedOption === currentOption}
					onChange={() => {
						setReason('') // clear out reason field
						setOption(currentOption)
					}}
				/>

				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} style={{ opacity: '0.4' }} fontSize="14px">
						{topLabel}
					</Text>
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="bold">
						{mainLabel}
					</Text>
				</div>
			</div>
			{ReasonSection}
		</OptionButtonWrapper>
	)
}

const OptionButtonWrapper = styled.div`
	border: 1px solid #c8d6db;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
`
const lottieAnimation = keyframes`
from{
  transform: scale(1);
}
to{
  transform: scale(.5);
}
`

const fadeIn = keyframes`
from{
opacity: 0.3;
}
to{
  opacity: 1;
}
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const CustomDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`
