import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'

import { Accordion, Tabbar, Text, theme, MainContainer } from 'verak-ui'

import GeneralLocationDetails from './components/GeneralLocationDetails'
import OtherUsage from './components/OtherUsage'
import ThirdPartyUsage from './components/ThirdPartyUsage'
import OwnershipStatus from './components/OwnershipStatus'
import Hypothecation from './components/Hypothecation'

import { FreeTextSwitchModal } from '../../PropertyRiskDataCollection/commonUtils/commonExports'

import { Context } from '../../../context/Provider'
import { useContext, useState, useEffect } from 'react'
import { useFetch } from '../../../api/apihook'
// import { flattenArrObj } from '../../../helpers/arrayOps'

const TabbarWrapper = styled.div`
	.nav-link {
		font-weight: 700;
	}
`

function getHeaderTitle(type) {
	const isProperty = type.includes('PROPERTY')
	const isBasement = type.includes('BASEMENT')
	const isProprtyBasement = isProperty && isBasement

	if (isProprtyBasement) {
		return 'Building + Basement details'
	}
	if (isBasement) {
		return 'Basement details'
	}
	if (isProperty) {
		return 'Building details'
	}
	return ''
}

function getSectionTitle(type) {
	const isProperty = type.includes('PROPERTY')
	const isBasement = type.includes('BASEMENT')
	const isProprtyBasement = isProperty && isBasement

	if (isProprtyBasement) {
		return 'Building & Basement'
	}
	if (isBasement) {
		return 'Basement'
	}
	if (isProperty) {
		return 'Building'
	}
	return ''
}

const LocationDetails = () => {
	const fetch = useFetch()

	const cachedData = fetch?.cachedData?.SubCategories?.required

	const fetchSubCategories = async () => {
		if (!cachedData) {
			await fetch.getSubCategoryByRiskType()
		}
	}

	useEffect(() => {
		if (!cachedData) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	const history = useHistory()
	const historyLoc = useLocation()
	const [state, dispatch] = useContext(Context)
	const { Type, SubType, levels } = state.location || {}

	// data for edit details flow
	const { edit, tabData, returnPath } = historyLoc?.state || {}

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	const headerTitle = getHeaderTitle(Type || '')
	const sectionTitle = getSectionTitle(Type || '')
	let proceedCTAText = 'Save and Continue'

	// Freeflowing text notice modal
	const [showModal, setShowModal] = useState(false)
	const [modalShown, setModalShown] = useState(false)

	const toggleNoticeModal = () => {
		// modal is shown only once for both OtherUsage and Thirdparty usage
		if (!modalShown) {
			setShowModal(true)
			setModalShown(true)
		}
	}

	const [activeId, setActiveId] = useState(() => {
		const { propertyType, levelNum } = tabData || {}
		if (propertyType) {
			return propertyType
		} else if (levelNum) {
			return `level${levelNum}`
		} else if (levels) {
			return `level${levels[0].Level}`
		} else {
			return 'Property'
		}
	})

	const [locationData, setLocationData] = useState(() => {
		if (isBasement || isProperty) {
			if (levels) {
				return {
					levels: levels,
				}
			} else {
				// get location/other info from state.location
				return state.location || {}
			}
		} else {
			let basementData = {},
				propertyData = {}
			if (state.location?.basement?.levels) {
				basementData = {
					levels: state.location?.basement?.levels,
				}
			} else {
				// get location/other info from state.location.basement
				basementData = state.location?.basement || {}
			}

			if (state.location?.property?.levels) {
				propertyData = {
					levels: state.location?.property?.levels,
				}
			} else {
				// get location/other info from state.location.property
				propertyData = state.location?.property || {}
			}

			return {
				basement: {
					...basementData,
				},
				property: {
					...propertyData,
				},
			}
		}
	})
	const [innerBasementLevel, setInnerBasementLevel] = useState(() => {
		const { propertyType, levelNum } = tabData || {}
		if (propertyType === 'Basement' && levelNum) {
			return `level${levelNum}`
		}

		let basementLevel = ''
		if (
			locationData.basement &&
			locationData.basement.levels &&
			locationData.basement.levels[0].Level
		) {
			basementLevel = locationData?.basement?.levels[0]?.Level
		}

		return `level${basementLevel}`
	})

	const [innerPropertyLevel, setInnerPropertyLevel] = useState(() => {
		const { propertyType, levelNum } = tabData || {}
		if (propertyType === 'Property' && levelNum) {
			return `level${levelNum}`
		}

		let propertyLevel = ''
		if (
			locationData.property &&
			locationData.property.levels &&
			locationData.property.levels[0].Level
		) {
			propertyLevel = locationData.property?.levels[0]?.Level
		}

		return `level${propertyLevel}`
	})

	const [addressIncomplete, setaddressIncomplete] = useState(true)
	const [otherUsageIncomplete, setOtherUsageIncomplete] = useState(true)
	const [thirdPartyIncomplete, setThirdPartyIncomplete] = useState(false)

	const proceedDisabled = addressIncomplete || otherUsageIncomplete || thirdPartyIncomplete

	const disableProceed = (field, value) => {
		switch (field) {
			case 'address':
				setaddressIncomplete(value)
				break
			case 'otherUse':
				setOtherUsageIncomplete(value)
				break
			case 'thirdParty':
				setThirdPartyIncomplete(value)
				break
			default:
				break
		}
	}

	const isDwellingsCategory = OccupancyItem => {
		const DWELLINGS_CATEGORY = 'Dwellings, Office, Shop or Hotel'

		if (!!cachedData) {
			const isDwellings = cachedData?.find(item => item.Description === OccupancyItem.SubCategory)

			return isDwellings?.Type === DWELLINGS_CATEGORY
		}
		return false
	}

	const isManufCategory = OccupancyItem => {
		const MANUF_CATEGORY = 'Manufacturing or other Industrial Property'

		if (!!cachedData) {
			const isManufacturing = cachedData?.find(item =>
				item.Description.includes(OccupancyItem.SubCategory)
			)

			return isManufacturing?.Type === MANUF_CATEGORY
		}
		return false
	}

	const checkForPrimarySecondaryOccManufacturing = data => {
		let secondaryOccupancyCheck = false,
			primaryOccupancyCheck = false
		if (data?.levels) {
			secondaryOccupancyCheck = data?.levels?.some(el =>
				el?.SecondaryOccupancy?.some(item => isManufCategory(item))
			)
			primaryOccupancyCheck = data?.levels?.some(el => isManufCategory(el?.PrimaryOccupancy || {}))
		} else {
			secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item => isManufCategory(item))
			primaryOccupancyCheck = isManufCategory(data?.PrimaryOccupancy || {})
		}
		return secondaryOccupancyCheck || primaryOccupancyCheck
	}

	const checkForPrimarySecondaryOcc = data => {
		let secondaryOccupancyCheck = false,
			primaryOccupancyCheck = false
		if (data?.levels) {
			secondaryOccupancyCheck = data?.levels?.some(el =>
				el?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			)
			primaryOccupancyCheck = data?.levels?.some(el =>
				isDwellingsCategory(el?.PrimaryOccupancy || {})
			)
		} else {
			secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			primaryOccupancyCheck = isDwellingsCategory(data?.PrimaryOccupancy || {})
		}
		return secondaryOccupancyCheck || primaryOccupancyCheck
	}

	const checkDwellings = () => {
		const { Type, property, basement } = state?.location || {}
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'

		let isDwellings = false

		if (isPropertyBasement) {
			isDwellings = checkForPrimarySecondaryOcc(property)
			isDwellings = isDwellings || checkForPrimarySecondaryOcc(basement)
		} else {
			isDwellings = checkForPrimarySecondaryOcc(state?.location)
		}
		return isDwellings
	}

	const checkIndustrialOrManufacturing = () => {
		const { Type, property, basement } = state?.location || {}

		const isPropertyBasement = Type === 'PROPERTYBASEMENT'
		let isIndustrialOrManuf = false

		if (isPropertyBasement) {
			isIndustrialOrManuf = checkForPrimarySecondaryOccManufacturing(property)
			isIndustrialOrManuf =
				isIndustrialOrManuf || checkForPrimarySecondaryOccManufacturing(basement)
		} else {
			isIndustrialOrManuf = checkForPrimarySecondaryOccManufacturing(state?.location)
		}
		return isIndustrialOrManuf
	}

	const saveAndProceed = async () => {
		let productsPresent = state?.location?.Products?.length > 0
		let shopRiskPresent = state?.location?.ShopRisk?.Goods?.length > 0

		if (isPropertyBasement) {
			const { property, basement } = state?.location || {}

			// manufacturing products only present for property
			productsPresent = property?.Products?.length > 0

			shopRiskPresent =
				property?.ShopRisk?.Goods?.length > 0 || basement?.ShopRisk?.Goods?.length > 0
		}

		let nextRouteState = {}
		let nextRoute = '/property-risk/theft-risk'

		// this flow has been isolated as it is common for SFSP and BLUS/BSUS
		const industrialManufacturingFlow = async () => {
			// if edit details flow
			if (returnPath) {
				nextRoute = returnPath
			} else {
				nextRoute = '/property-risk/theft-risk'
			}
		}

		// for SFSP, first check for dwellings and then check for industrial or manufacturing too
		if (state.quote?.PolicyName === 'SFSP') {
			if (checkDwellings()) {
				// if edit details flow
				if (returnPath) {
					if (!shopRiskPresent) {
						nextRoute = '/property-risk/dwellings-risk'
						nextRouteState = historyLoc?.state || {}
					} else {
						nextRoute = returnPath
					}
				} else {
					nextRoute = '/property-risk/shop-risk'
					nextRouteState = historyLoc?.state || {}
				}
			} else {
				// clear the dwellings here...
				if (shopRiskPresent) {
					await new Promise((resolve, reject) => {
						dispatch({
							type: 'SET_LOCATION_SHOP_RISK',
							data: {
								Goods: [],
								ExceedsLimit: '',
							},
						})
						resolve()
					})
				}
				industrialManufacturingFlow()
			}
		} else {
			industrialManufacturingFlow()
		}

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push({ pathname: nextRoute, state: nextRouteState })
	}

	const goBack = () => {
		let goBackPath = ''
		if (returnPath) {
			goBackPath = returnPath
		} else if (isPropertyBasement) {
			goBackPath = '/risk-location-details/property-and-basement'
		} else if (isBasement) {
			goBackPath = '/risk-location-details/basement'
		} else if (isProperty) {
			goBackPath = '/risk-location-details/property'
		}

		history.replace(goBackPath)
	}

	const proceed = () => {
		if (returnPath) {
			saveAndProceed()
		} else {
			// switch tab here
			if (locationData.levels) {
				const currentLevelNo = activeId.substring(5, activeId.length)
				const index = locationData.levels?.findIndex(item => {
					return item.Level === currentLevelNo
				})

				if (index < locationData.levels.length - 1) {
					const nextLevel = locationData.levels?.[index + 1]?.Level
					setActiveId(`level${nextLevel}`)
				} else {
					// save and proceed
					saveAndProceed()
				}
			} else {
				// check if property+basement
				if (isPropertyBasement) {
					if (activeId === 'Property') {
						// check if it contains levels
						if (locationData.property.levels) {
							const currentLevelNo = innerPropertyLevel.substring(5, activeId.length)
							const index = locationData.property?.levels?.findIndex(item => {
								return item.Level === currentLevelNo
							})

							if (index < locationData.property.levels.length - 1) {
								const nextLevel = locationData.property?.levels?.[index + 1]?.Level
								setInnerPropertyLevel(`level${nextLevel}`)
							} else {
								//navigate to basement
								setActiveId('Basement')
							}
						} else {
							// navigate To basement
							setActiveId('Basement')
						}
					} else {
						if (locationData.basement.levels) {
							const currentLevelNo = innerBasementLevel.substring(5, activeId.length)
							const index = locationData.basement.levels.findIndex(item => {
								return item.Level === currentLevelNo
							})

							if (index < locationData.basement.levels.length - 1) {
								const nextLevel = locationData.basement.levels[index + 1].Level
								setInnerBasementLevel(`level${nextLevel}`)
							} else {
								// save and proceed
								saveAndProceed()
							}
						} else {
							// save and proceed
							saveAndProceed()
						}
					}
				} else {
					// proceed
					saveAndProceed()
				}
			}
		}
	}

	const saveLocationData = (
		data,
		propertyType,
		levelIndex,
		addressField = false,
		globalAddress = true
	) => {
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'
		let oldValue =
			Type === 'PROPERTYBASEMENT' && !addressField
				? { ...locationData[propertyType] }
				: { ...locationData }

		if (addressField) {
			if (isPropertyBasement) {
				oldValue = {
					...oldValue,
					property: { ...oldValue.property, ...data },
					basement: { ...oldValue.basement, ...data },
				}
			} else if (globalAddress) {
				oldValue = {
					...oldValue,
					...data,
				}
			} else {
				oldValue.levels[levelIndex] = { ...oldValue?.levels?.[levelIndex], ...data }
			}
		} else {
			if (levelIndex > -1) {
				oldValue.levels[levelIndex] = { ...oldValue?.levels?.[levelIndex], ...data }
			} else {
				oldValue = {
					...oldValue,
					...data,
				}
			}
		}

		const alteredLocationData =
			Type === 'PROPERTYBASEMENT' && !addressField
				? {
						...locationData,
						[propertyType]: {
							...oldValue,
						},
				  }
				: { ...locationData, ...oldValue }

		setLocationData(alteredLocationData)

		dispatch({
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			levelIndex: levelIndex,
			addressField,
			globalAddress,
			propertyType: propertyType,
			data: data,
		})
	}

	const checkActiveTab = (propertyType, levelIndex) => {
		// either property only or basement only
		const onlyBasementOrProperty = isBasement || isProperty
		let currentTabData = {}
		let currentLevel = ''

		if (onlyBasementOrProperty) {
			currentTabData = locationData
			currentLevel = activeId
		} else {
			currentTabData = { ...locationData[propertyType] }

			if (activeId === 'Property') {
				currentLevel = innerPropertyLevel
			} else {
				currentLevel = innerBasementLevel
			}
		}

		if (levelIndex > -1) {
			const tabLvlNo = currentTabData.levels[levelIndex]?.Level
			const currentLvlNo = currentLevel?.replace(/level/, '') || ''
			if (currentLvlNo === tabLvlNo) {
				return true
			}
		} else if (onlyBasementOrProperty || propertyType === activeId?.toLowerCase() || '') {
			return true
		}

		return false
	}

	const updateOwnership = (data, propertyType, levelIndex) => {
		let toSaveData = { ...data }
		const { Type } = state?.location || {}
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'

		if (toSaveData.Ownership == 'RENTED') {
			if (isPropertyBasement) {
				if (levelIndex > -1) {
					if (state.location[propertyType]?.levels[levelIndex]?.Hypothecation?.length > 0) {
						toSaveData.Hypothecation = []
					}
				} else {
					if (state.location[propertyType]?.Hypothecation?.length > 0) {
						toSaveData.Hypothecation = []
					}
				}
			} else {
				if (levelIndex > -1) {
					if (state.location.levels[levelIndex]?.Hypothecation?.length > 0) {
						toSaveData.Hypothecation = []
					}
				} else {
					if (state.location?.Hypothecation?.length > 0) {
						toSaveData.Hypothecation = []
					}
				}
			}
			if (levelIndex > -1) {
				if (state.location[propertyType]?.levels[levelIndex]?.Hypothecation?.length > 0) {
					toSaveData.Hypothecation = []
				}
			} else {
				if (state.location[propertyType]?.Hypothecation?.length > 0) {
					toSaveData.Hypothecation = []
				}
			}
		}
		saveLocationData(toSaveData, propertyType, levelIndex)
	}

	const accordionList = props => {
		const toReturn = [
			{
				header: 'Location details',
				headerIcon: 'location',
				id: 'location-details',
				content: (
					<GeneralLocationDetails
						disabled={!!props.addressDisabled}
						levelIdx={props.levelIndex}
						propertyType={props.propertyType}
						activeId={activeId}
						innerBasementLevel={innerBasementLevel}
						innerPropertyLevel={innerPropertyLevel}
						isActiveTab={checkActiveTab(props.propertyType, props.levelIndex)}
						disableProceed={val => disableProceed('address', val)}
						onSave={(data, addressField = true, globalAddress = true) =>
							saveLocationData(
								data,
								props.propertyType,
								props.levelIndex,
								addressField,
								globalAddress
							)
						}
					/>
				),
			},
			{
				header: 'Other usage of this location',
				headerIcon: 'propertyUsage',
				id: 'other-usage',
				content: (
					<OtherUsage
						levelIdx={props.levelIndex}
						propertyType={props.propertyType}
						isActiveTab={checkActiveTab(props.propertyType, props.levelIndex)}
						disableProceed={val => disableProceed('otherUse', val)}
						onSave={data => saveLocationData(data, props.propertyType, props.levelIndex)}
						toggleNoticeModal={toggleNoticeModal}
					/>
				),
			},
			{
				header: '3rd party usage of location',
				headerIcon: 'propertyUsage',
				id: 'third-party-usage',
				content: (
					<ThirdPartyUsage
						levelIdx={props.levelIndex}
						propertyType={props.propertyType}
						isActiveTab={checkActiveTab(props.propertyType, props.levelIndex)}
						disableProceed={val => disableProceed('thirdParty', val)}
						onSave={data => saveLocationData(data, props.propertyType, props.levelIndex)}
						toggleNoticeModal={toggleNoticeModal}
					/>
				),
			},
			{
				header: 'Ownership status',
				headerIcon: 'propertyOwnership',
				id: 'ownership',
				content: (
					<OwnershipStatus
						levelIdx={props.levelIndex}
						propertyType={props.propertyType}
						onSave={data => updateOwnership(data, props.propertyType, props.levelIndex)}
					/>
				),
			},
		]

		let displayHypothecation = false,
			ownership
		if (isPropertyBasement) {
			if (locationData?.[props.propertyType]?.levels) {
				ownership = locationData[props.propertyType]?.levels?.[props.levelIndex]?.Ownership || ''
			} else {
				ownership = locationData[props.propertyType]?.Ownership || ''
			}
		} else {
			if (locationData?.levels) {
				ownership = locationData?.levels?.[props.levelIndex]?.Ownership || ''
			} else {
				ownership = locationData.Ownership || ''
			}
		}

		displayHypothecation = ownership === 'SOLE' || ownership === 'CO-OWNED'

		if (displayHypothecation) {
			toReturn.push({
				header: 'Hypothecation',
				headerIcon: 'hypothecation',
				id: 'hypothecation',
				content: (
					<Hypothecation
						prefillHypothecation={props.prefillHypothecation}
						levelIdx={props.levelIndex}
						propertyType={props.propertyType}
						activeId={activeId}
						innerBasementLevel={innerBasementLevel}
						innerPropertyLevel={innerPropertyLevel}
						onSave={data => saveLocationData(data, props.propertyType, props.levelIndex)}
					/>
				),
			})
		}

		return toReturn
	}

	let tabs

	if (isProperty || isBasement) {
		// check for levels
		const propertyType = (state.location?.Type || '')?.toLowerCase()
		if (locationData.levels) {
			const levelsList = state.location.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `${isProperty ? 'Floor' : 'Level'} ${item.Level}`,
					content: (
						<Accordion
							accordionList={accordionList({
								propertyType,
								levelIndex: index,
								addressDisabled: index > 0,
								prefillHypothecation: index > 0,
							})}
							defaultActiveKey="location-details"
						/>
					),
				}
			})
			tabs = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab"
						activeTabId={activeId}
						onChange={setActiveId}
						tabList={levelTabsItem}
						secondary={false}
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			// render
			tabs = (
				<Accordion
					accordionList={accordionList({ propertyType, levelIndex: -1 })}
					defaultActiveKey="location-details"
				/>
			)
		}
	} else {
		let basementContent, propertyContent

		if (locationData.basement.levels) {
			const levelsList = locationData.basement.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `Level ${item.Level}`,
					content: (
						<Accordion
							accordionList={accordionList({
								propertyType: 'basement',
								levelIndex: index,
								addressDisabled: index > 0,
								prefillHypothecation: index > 0,
							})}
							defaultActiveKey="location-details"
						/>
					),
				}
			})
			basementContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-basement"
						activeTabId={innerBasementLevel}
						onChange={setInnerBasementLevel}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			basementContent = (
				<Accordion
					accordionList={accordionList({
						propertyType: 'basement',
						levelIndex: -1,
						prefillHypothecation: true,
					})}
					defaultActiveKey="location-details"
				/>
			)
		}

		if (locationData.property.levels) {
			const levelsList = locationData.property.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level${item.Level}`,
					title: `Floor ${item.Level}`,
					content: (
						<Accordion
							accordionList={accordionList({
								propertyType: 'property',
								levelIndex: index,
								addressDisabled: index > 0,
								prefillHypothecation: index > 0,
							})}
							defaultActiveKey="location-details"
						/>
					),
				}
			})
			propertyContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-property"
						activeTabId={innerPropertyLevel}
						onChange={setInnerPropertyLevel}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			propertyContent = (
				<Accordion
					accordionList={accordionList({ propertyType: 'property', levelIndex: -1 })}
					defaultActiveKey="location-details"
				/>
			)
		}

		const parentTabbarItems = [
			{ id: 'Property', title: 'Building', disabled: false, content: propertyContent },
			{ id: 'Basement', title: 'Basement', disabled: false, content: basementContent },
		]

		tabs = (
			<TabbarWrapper>
				<Tabbar
					className="mt-3"
					tabId="outer-tab"
					activeTabId={activeId}
					onChange={setActiveId}
					tabList={parentTabbarItems}
					secondary={false}
				/>
			</TabbarWrapper>
		)
	}

	return (
		<MainContainer
			header={headerTitle}
			divider={true}
			rightOnClick={proceed}
			rightLabel={proceedCTAText}
			rightDisabled={proceedDisabled}
			leftOnClick={goBack}
		>
			<FreeTextSwitchModal show={showModal} handleClose={() => setShowModal(false)} />
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<Text type="h5" fontWeight={700} style={{ lineHeight: '30.24px' }}>
				Enter details about the {sectionTitle}:
			</Text>
			{tabs}
		</MainContainer>
	)
}

export default LocationDetails
