import styled from 'styled-components'
import { theme, Text, Icon, Tooltip } from 'verak-ui'
import { useState, useEffect } from 'react'
import { CardStyle } from './commonStyle'
import { useFetch } from '../../../api/apihook'
import NearestCustomer from '../../../assets/NearestCustomer.png'

const Container = styled(CardStyle)`
	background: ${theme.colors.white};
`

const TabContainer = styled.div`
	font-size: 14px;
	border: 0.5px solid #9fa2e2;
	border-radius: 8px;
	font-weight: 700;
`
const TabItem = styled.div`
	padding: 4px 6px;
	color: ${({ selected }) => (selected ? '#6467B0' : '#999999')};
	background-color: ${({ selected }) => (selected ? 'rgba(159, 162, 226, 0.2)' : '#fff')};
	border-radius: 8px;
	border: ${({ selected }) => (selected ? '0.5px solid #9FA2E2' : '')};
	cursor: pointer;

	${({ selected, firstItem }) =>
		selected
			? firstItem
				? `margin-left: -1px;
					margin-top: -1px;
					margin-bottom: -1px;`
				: `margin-right: -1px;
					margin-top: -1px;
					margin-bottom: -1px;`
			: ``}
`

const ClippedText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

// english to hindi translation
const translations = {
	fire: 'आग 🔥',
	theft: 'चोरी 🚨',
	burglary: 'चोरी 🚨',
	riot: 'दंगा 💣',
	dacoity: 'डकैती 🔫',
}

const CaseStudyCard = ({
	selectedState,
	selectedCity,
	caseStudyList: caseStudies,
	nearestCustomerList: customerList,
	sendCustomerDetails,
}) => {
	const [activeCaseStudy, setActiveCaseStudy] = useState(0)
	const [activeCustomer, setActiveCustomer] = useState(0)
	const [activeTab, setActiveTab] = useState('case-study')

	const showPrevCaseStudy = () =>
		activeCaseStudy === 0
			? setActiveCaseStudy(caseStudies.length - 1)
			: setActiveCaseStudy(activeCaseStudy - 1)

	const showNextCaseStudy = () =>
		activeCaseStudy === caseStudies.length - 1
			? setActiveCaseStudy(0)
			: setActiveCaseStudy(activeCaseStudy + 1)

	const showPrevCustomer = () =>
		activeCustomer === 0
			? setActiveCustomer(customerList.length - 1)
			: setActiveCustomer(activeCustomer - 1)

	const showNextCustomer = () =>
		activeCustomer === customerList.length - 1
			? setActiveCustomer(0)
			: setActiveCustomer(activeCustomer + 1)

	const currentCaseStudy = caseStudies[activeCaseStudy] || {}

	useEffect(() => {
		if (selectedState) {
			if (currentCaseStudy.state !== selectedState || currentCaseStudy.city !== selectedCity) {
				// find
				let indexVal = caseStudies.findIndex(item => {
					return (
						item.state.toLowerCase() == selectedState && item.city.toLowerCase() == selectedCity
					)
				})
				if (indexVal > -1) {
					setActiveCaseStudy(indexVal)
				} else {
					// find matching state
					indexVal = caseStudies.findIndex(item => {
						return item.state.toLowerCase() == selectedState
					})
					if (indexVal > -1) {
						setActiveCaseStudy(indexVal)
					}
				}
			}
		} else if (selectedCity && currentCaseStudy.city !== selectedCity) {
			// search by city
			let indexVal = caseStudies.findIndex(item => {
				return item.city.toLowerCase() == selectedCity
			})
			if (indexVal > -1) {
				setActiveCaseStudy(indexVal)
			}
		}
	}, [selectedState, selectedCity, caseStudies?.length])

	var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const caseStudyDate = new Date(currentCaseStudy?.date)
	let incidentDate = '-'

	if (caseStudyDate) {
		incidentDate = `${caseStudyDate.getDate()} ${
			months[caseStudyDate.getMonth()]
		} ${caseStudyDate.getFullYear()}`
	}

	const onTabChange = value => {
		setActiveTab(value)
	}

	const caseStudyContent = (
		<>
			<div className="d-flex mt-2 mb-3">
				<div className="d-flex flex-column w-50">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Where?
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
						{currentCaseStudy.city}
					</Text>
				</div>
				<div className="d-flex flex-column w-50">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Date
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
						{incidentDate}
					</Text>
				</div>
			</div>
			<div className="d-flex">
				<div className="d-flex flex-column w-50">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						What happened?
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
						{currentCaseStudy.incident}
					</Text>
				</div>
				<div className="d-flex flex-column w-50">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Loss
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
						{currentCaseStudy.loss}
					</Text>
				</div>
			</div>
		</>
	)

	let customerContent
	if (customerList.length == 0) {
		customerContent = (
			<div className="flex-fill d-flex align-items-center justify-content-center">
				<div className="">
					<Text color={'#D75B28'} fontWeight="700">
						NO DATA AVAILABLE
					</Text>
					<Text fontSize={'13px'} className="mt-2" fontWeight="500">
						Please enter Pincode in the draft form to unlock customer data for you
					</Text>
				</div>
				<div className="">
					<img src={NearestCustomer} />
				</div>
			</div>
		)
	} else {
		const toDisplayCustomer = customerList[activeCustomer]

		customerContent = (
			<>
				<div className="d-flex mt-2 mb-2">
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Customer Name
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{toDisplayCustomer.CustomerName}
						</Text>
					</div>
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Shop/Business Name
						</Text>
						<div className="d-flex">
							<Text
								color={theme.colors.black}
								fontSize="14px"
								fontWeight="400"
								className="pt-1"
								style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
							>
								{toDisplayCustomer.LegalEntityName}
							</Text>
							{toDisplayCustomer.LegalEntityName ? (
								<Tooltip html={toDisplayCustomer.LegalEntityName}>
									<Icon name="infoIcon" color="#6467B0" />
								</Tooltip>
							) : null}
						</div>
					</div>
				</div>
				<div className="d-flex align-item-end mb-1 flex-fill">
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Address
						</Text>
						<div className="d-flex">
							<ClippedText color={theme.colors.black} fontSize="14px" fontWeight="400">
								{toDisplayCustomer.Address}
							</ClippedText>
							{toDisplayCustomer.Address ? (
								<Tooltip html={toDisplayCustomer.Address} className="ms-1">
									<Icon name="infoIcon" color="#6467B0" />
								</Tooltip>
							) : null}
						</div>
					</div>
					<div
						className="d-flex w-50 align-items-end justify-content-end"
						onClick={() => sendCustomerDetails(toDisplayCustomer)}
						style={{ cursor: 'pointer' }}
					>
						<Icon name="whatsapp" className={'me-1'} style={{ width: '20px', height: '20px' }} />
						<Text fontSize={'12px'} fontWeight="500" color={'#4F76E8'}>
							Send Customer’s Detail
						</Text>
					</div>
				</div>
			</>
		)
	}

	const onNextClick = () => {
		if (activeTab == 'case-study') {
			if (caseStudies.length > 0) {
				showNextCaseStudy()
			}
		} else {
			if (customerList.length > 0) {
				showNextCustomer()
			}
		}
	}
	const onPrevClick = () => {
		if (activeTab == 'case-study') {
			if (caseStudies.length > 0) {
				showPrevCaseStudy()
			}
		} else {
			if (customerList.length > 0) {
				showPrevCustomer()
			}
		}
	}

	return (
		<Container className="flex-fill d-flex flex-column bg-white">
			<div className="d-flex justify-content-between">
				{/* <Text color={theme.colors.blue} fontSize="14px" fontWeight="700">
					Case Study
				</Text> */}
				<TabContainer className="d-flex">
					<TabItem
						selected={activeTab == 'case-study'}
						firstItem
						onClick={() => onTabChange('case-study')}
					>
						Case Study
					</TabItem>
					<TabItem
						onClick={() => onTabChange('customers')}
						secondItem
						selected={activeTab == 'customers'}
						style={{}}
					>
						Customers
					</TabItem>
				</TabContainer>
				<div className="d-flex justify-content-between align-items-center">
					{activeTab == 'case-study' ? (
						<CaseStudyBanner>{translations[currentCaseStudy.type]}</CaseStudyBanner>
					) : null}
					<Icon
						onClick={onPrevClick}
						name="leftArrow"
						className={'me-1'}
						style={{ cursor: 'pointer' }}
					/>
					<Icon
						onClick={onNextClick}
						name="rightArrow"
						color={theme.colors.gray}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</div>
			{activeTab == 'case-study' ? caseStudyContent : customerContent}
		</Container>
	)
}

export default CaseStudyCard

const CaseStudyBanner = styled.div`
	padding: 2px 6px;
	background: #fff6ec;
	border-radius: 4px;
`
