import { useContext } from 'react'
import { useLocation } from 'react-router'

import { Context } from '../../../context/Provider'

import MaterialsDetails from './MaterialsDetails'
import MaterialsDetailsSFSP from './MaterialsDetailsSFSP'

const MaterialsHandler = () => {
	let location = useLocation()
	const [state, dispatch] = useContext(Context)
	let content = null

	const isSFSPEdgeCase = location?.state?.isSFSPEdgeCase

	if (state.quote?.PolicyName === 'SFSP' || isSFSPEdgeCase) {
		content = <MaterialsDetailsSFSP isSFSPEdgeCase={isSFSPEdgeCase} />
	} else {
		content = <MaterialsDetails />
	}

	return content
}

export default MaterialsHandler
