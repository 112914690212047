import { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import {
	Button,
	Checkbox,
	PageContentAndNavbarWrapper,
	SecondaryNavbar,
	Text,
	theme,
} from 'verak-ui'
import { useFetch } from '../../api/apihook'
import { useContextApi } from '../../context/Provider'
import {
	InsurerImagesMap,
	QuoteImg,
} from '../SQS/RFQStatus/QuotesReady/ComparisonComponents/quoteComparisonUtils'

import SpecificRequestsImg from '../../assets/SpecificRequests.png'

function CardComponent({
	title,
	children,
	footer,
	disable = false,
	isSelected = false,
	onSelect = () => {},
}) {
	return (
		<Card className="position-relative h-100" style={{ opacity: disable ? '0.5' : '1' }}>
			{disable ? (
				<div className="position-relative w-100">
					<NotSupportedTag
						className="text-center position-absolute top-0 bottom-0 p-3"
						style={{ background: '' }}
					>
						<Text fontSize="16px" color="#fff" fontWeight="700">
							NOT SUPPORTED
						</Text>
					</NotSupportedTag>
				</div>
			) : null}

			<div className="position-relative h-100" style={{ paddingBottom: '120px' }}>
				<Text fontSize="16px" color="#000" fontWeight="700" className="mb-3">
					{title}
				</Text>
				{children}
				<div className="mt-5 position-absolute bottom-0 w-100">
					<Text fontSize="16px" className="my-3">
						{footer}
					</Text>
					<SelectBtn
						primary
						isSelected={isSelected}
						label={isSelected ? 'Selected' : 'Select'}
						onClick={onSelect}
						className="w-100"
						disabled={disable}
					/>
				</div>
			</div>
		</Card>
	)
}

export default function ChooseInsurer() {
	const history = useHistory()
	const fetch = useFetch()
	const [state] = useContextApi()
	const [existingInsurerName, setExistingInsurerName] = useState('Bharti AXA')
	const [isExistingInsurerSupported, setIsExistingInsurerSupported] = useState(false)
	const [eligibleInsurers, setEligibleInsurers] = useState([])
	const [selectedInsurer, setSelectedInsurer] = useState('')

	useEffect(() => {
		const { Quote } = state?.sqs?.prevPolicyData
		const insurer = Quote?.Insurer || ''
		const existingInsSupported = Quote?.IsCalculatorSupported
		setExistingInsurerName(insurer)
		if (existingInsSupported) {
			setIsExistingInsurerSupported(existingInsSupported)
		}
	}, [])

	const filteredEligibleInsurers = useMemo(() =>
		eligibleInsurers.filter(ins => ins !== existingInsurerName, [existingInsurerName])
	)

	useEffect(() => {
		const fetchEligibleInsurers = async () => {
			const result = await fetch.getInstantQuoteInsurers()
			if (result.length) {
				setEligibleInsurers(result)
			}
		}
		fetchEligibleInsurers()
	}, [])

	const goBack = () => {
		history.goBack()
	}

	const handleInsurerSelection = insName => {
		if (insName === selectedInsurer) {
			setSelectedInsurer('')
		} else {
			setSelectedInsurer(insName)
		}
	}

	const handleProceed = async () => {
		const rfqId = state?.quote?.quoteId || ''
		await fetch.sendRenewalInsurerChoiceNotif(rfqId, selectedInsurer)
		history.push('/')
	}

	const disableProceed = !selectedInsurer

	const navLeftContent = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Insurer for quotes
		</Text>
	)
	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navLeftContent}
				onGoBack={goBack}
			/>

			<Container className="mt-3" style={{ marginBottom: '60px' }}>
				<div className="d-flex align-items-center mb-2">
					<Text color={'#333333'} fontSize="24px" fontWeight="700">
						Select an option to choose insurance company:
					</Text>
				</div>
				<Row>
					<Col>
						<CardComponent
							title="Existing Insurer"
							footer="Customer will get payment link directly on Whatsapp"
							disable={Boolean(isExistingInsurerSupported) === false}
							onSelect={() => setSelectedInsurer(existingInsurerName)}
							isSelected={selectedInsurer === existingInsurerName}
						>
							<QuoteImg
								style={{ width: 'auto' }}
								src={InsurerImagesMap[existingInsurerName]}
								alt={existingInsurerName}
							/>
							<Text className="my-3" fontSize="16px" fontWeight="600" color="#000">
								{existingInsurerName}
							</Text>
						</CardComponent>
					</Col>

					<Col>
						<CardComponent
							title="Different Insurer"
							footer="Customer will get the Whatsapp notification to trigger the purchase journey and change the quote"
							onSelect={() => {}}
							isSelected={filteredEligibleInsurers.includes(selectedInsurer)}
						>
							<EligibleInsurerImgGrid className="my-3">
								{filteredEligibleInsurers.map(insurer => (
									<QuoteImg
										style={{ width: '120px' }}
										src={InsurerImagesMap[insurer]}
										alt={insurer}
									/>
								))}
							</EligibleInsurerImgGrid>
							<div className="mb-5">
								{filteredEligibleInsurers.map(insurer => (
									<Checkbox
										text={insurer}
										value={selectedInsurer === insurer}
										key={insurer}
										onChange={() => handleInsurerSelection(insurer)}
										className="my-3"
									/>
								))}
							</div>
						</CardComponent>
					</Col>

					<Col>
						<CardComponent
							title="Specific Request"
							footer="Raise a request with the operations team to bring quotes as per customer’s choice or preference"
							onSelect={() => history.push('/renewals/specific-requests')}
						>
							<img src={SpecificRequestsImg} alt="specific requests" />
						</CardComponent>
					</Col>
				</Row>

				<div className="w-100 bg-white py-2 px-3 d-flex justify-content-end fixed-bottom">
					<Button
						label="Save and continue"
						icon="rightArrow"
						iconAlign="right"
						disabled={disableProceed}
						onClick={handleProceed}
					/>
				</div>
			</Container>
		</PageContentAndNavbarWrapper>
	)
}

const buttonCss = css`
	background: ${p => (p.isSelected ? theme.colors.primary : '#fff')};
	color: ${p => (p.isSelected ? '#fff' : theme.colors.primary)};
	border: ${p => (p.isSelected ? 'none' : `2px solid ${theme.colors.primary}`)};
`

const SelectBtn = styled(Button)`
	${buttonCss};
	&:hover {
		${buttonCss};
	}
`

const Card = styled.div`
	width: 100%;
	background: #fff;
	padding: 20px;
	opacity: ${p => (p.isNotSupported ? '0.5' : '1')};
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
	border-radius: 4px;
`
const NotSupportedTag = styled.div`
	background: linear-gradient(180deg, #ec5959 0%, #8e0000 100%);
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
	transform: rotate(-25.8deg);
	height: fit-content;
	z-index: 9999;
	width: 120%;
	margin-top: 120px;
	margin-left: -2rem;
`

const EligibleInsurerImgGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 22px;
`
