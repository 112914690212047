import styled from 'styled-components'
import { theme, Text, Icon, Tooltip } from 'verak-ui'
import { useState } from 'react'
import NearestCustomer from '../../../assets/NearestCustomer.png'

const Container = styled.div`
	padding: 8px 10px;
	background: transparent;
`

const ClippedText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`

const CaseStudyCard = ({ nearestCustomerList: customerList, sendCustomerDetails }) => {
	const [activeCustomer, setActiveCustomer] = useState(0)

	const showPrevCustomer = () =>
		activeCustomer === 0
			? setActiveCustomer(customerList.length - 1)
			: setActiveCustomer(activeCustomer - 1)

	const showNextCustomer = () =>
		activeCustomer === customerList.length - 1
			? setActiveCustomer(0)
			: setActiveCustomer(activeCustomer + 1)

	let customerContent
	if (customerList.length === 0) {
		customerContent = (
			<div className="flex-fill d-flex align-items-center justify-content-center">
				<div className="">
					<Text color={'#D75B28'} fontWeight="700">
						NO DATA AVAILABLE
					</Text>
					<Text fontSize={'13px'} className="mt-2" fontWeight="500">
						Please enter Pincode in the draft form to unlock customer data for you
					</Text>
				</div>
				<div className="">
					<img src={NearestCustomer} alt="" />
				</div>
			</div>
		)
	} else {
		const toDisplayCustomer = customerList[activeCustomer]

		customerContent = (
			<>
				<div className="d-flex mt-2 mb-2">
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Customer Name
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{toDisplayCustomer.CustomerName}
						</Text>
					</div>
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Shop/Business Name
						</Text>
						<div className="d-flex align-items-center">
							<Text
								color={theme.colors.black}
								fontSize="14px"
								fontWeight="400"
								className="pt-1"
								style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
							>
								{toDisplayCustomer.LegalEntityName}
							</Text>
							{toDisplayCustomer.LegalEntityName ? (
								<Tooltip html={toDisplayCustomer.LegalEntityName}>
									<Icon size="16px" name="infoIcon" color="#6467B0" />
								</Tooltip>
							) : null}
						</div>
					</div>
				</div>
				<div className="d-flex align-item-end mb-1 flex-fill">
					<div className="d-flex flex-column w-50">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Address
						</Text>
						<div className="d-flex align-items-center">
							<ClippedText color={theme.colors.black} fontSize="14px" fontWeight="400">
								{toDisplayCustomer.Address}
							</ClippedText>
							{toDisplayCustomer.Address ? (
								<Tooltip html={toDisplayCustomer.Address} className="ms-1">
									<Icon size="16px" name="infoIcon" color="#6467B0" />
								</Tooltip>
							) : null}
						</div>
					</div>
					<div
						className="d-flex w-50 align-items-end justify-content-start"
						onClick={() => sendCustomerDetails(toDisplayCustomer)}
						style={{ cursor: 'pointer' }}
					>
						<Icon name="whatsapp" className={'me-1'} style={{ width: '20px', height: '20px' }} />
						<Text fontSize={'12px'} fontWeight="500" color={'#4F76E8'}>
							Send Customer's Detail
						</Text>
					</div>
				</div>
			</>
		)
	}

	const onNextClick = () => {
		if (customerList.length > 0) {
			showNextCustomer()
		}
	}
	const onPrevClick = () => {
		if (customerList.length > 0) {
			showPrevCustomer()
		}
	}

	return (
		<Container className="flex-fill d-flex flex-column">
			{customerContent}
			<div className="d-flex justify-content-end">
				<div className="d-flex justify-content-between align-items-center">
					<Icon
						onClick={onPrevClick}
						name="leftArrow"
						className={'me-1'}
						style={{ cursor: 'pointer' }}
					/>
					<Icon
						onClick={onNextClick}
						name="rightArrow"
						color={theme.colors.gray}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			</div>
		</Container>
	)
}

export default CaseStudyCard
