export const sqsReducer = (state, action) => {
	switch (action.type) {
		case 'SET_RETURN_PATH':
			return {
				...state,
				returnPath: action?.data?.returnPath,
			}
		case 'SET_FLOW_COMPLETION_ROUTES':
			return {
				...state,
				flowCompletionRoutes: {
					finalRoute: action?.data?.flowCompletionRoutes?.finalRoute,
					redirectRoute: action?.data?.flowCompletionRoutes?.redirectRoute,
				},
			}
		case 'SET_CLIENT_EXISTS': {
			return {
				...state,
				clientExists: action.data.clientExists,
			}
		}
		case 'SET_PREV_POLICY_DATA': {
			return {
				...state,
				prevPolicyData: action.data,
			}
		}
		default:
			return state
	}
}
