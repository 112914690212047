import { commonWithData, propertyWithData, basementWithData } from './sampleValues'
import { nanoid } from 'nanoid'
const ADD_GROUP = 'ADD_GROUP'
const SELECT_POLICYTYPE = 'SELECT_POLICYTYPE'

const propertyWithoutLevels = { ...propertyWithData }
delete propertyWithoutLevels.levels

const basementWithoutLevels = { ...basementWithData }
delete basementWithoutLevels.levels

const defaultState = {
	QuoteType: '',

	PolicyName: '',
	ClientDetails: {
		ProposerName: '',
		CompanyName: '',
		Phone: '',
	},

	Cover: {
		Duration: '',
		StartDate: '',
	},

	NumRiskLoc: '',
	NumStock: '',
	PolicyKind: '',

	ValuationMethodMap: {},
	Addons: [],
	EscalationClause: [],

	StockDisclosureMethod: '',
	StockInProcess: '',
	StockRailSiding: '',
	DeclarationQ: {
		ReportingMethod: '',
		MaxValueOneLoc: '',
		MaxValueAllLoc: '',
	},
	Note: '',
	PolicyAddons: {},
	AutoConversion: [],

	RiskLocations: [
		// each floor and level be treated as new risk location
		// if user selects basement + property - 2 location will be created
	],
}

export const testingState = {
	QuoteType: '',

	PolicyName: '',
	ClientDetails: {
		ProposerName: '',
		CompanyName: '',
		Phone: '',
	},

	Cover: {
		Duration: 'Y2',
		StartDate: `${new Date()}`,
	},

	NumRiskLoc: '',
	NumStock: 3,
	PolicyKind: '',

	StockDisclosureMethod: 'DECLARATION_BASIS',
	StockInProcess: false,
	StockRailSiding: false,
	DeclarationQ: {
		ReportingMethod: 'AVG',
		MaxValueOneLoc: '100000',
		MaxValueAllLoc: '1000000',
	},

	RiskLocations: [
		// property type with no level
		{
			...commonWithData,
			// PIN: '500661',
			// Stock: { //  stock already comes from commonWithData
			//   Present: true
			// },
			SumInsured: [
				{ Name: 'BuildingValue', Amount: '363636' },
				{ Name: 'BoundaryWalls', Amount: '25000' },
				{ Name: 'PlantMachinery', Amount: '636363' },
				{ Name: 'FurnitureFixture', Amount: '3000' },
				{ Name: 'Electronics', Amount: '63633' },
				{ Name: 'OfficeEquipment', Amount: '5000' },
				{ Name: 'ValuableContents', Amount: '56546' },
				{ Name: 'Other', Amount: '6464' },
				{ Name: 'MaxValSingleItem', Amount: '6464' },
			],
			Level: '5',
			SubType: 'SINGLE',
			Type: 'PROPERTY',
			ID: 'nolevel-property||basement',
			label: '1',
		},
		// basement with no level
		{
			...commonWithData,
			// PIN: '500661',
			// Stock: { //  stock already comes from commonWithData
			//   Present: true
			// },
			Level: '-3',
			SumInsured: [
				{ Name: 'BuildingValue', Amount: '363636' },
				{ Name: 'BoundaryWalls', Amount: '25000' },
				{ Name: 'PlantMachinery', Amount: '636363' },
				{ Name: 'FurnitureFixture', Amount: '15000' },
				{ Name: 'Electronics', Amount: '63633' },
				{ Name: 'OfficeEquipment', Amount: '12000' },
				{ Name: 'ValuableContents', Amount: '56546' },
				{ Name: 'Other', Amount: '6464' },
				{ Name: 'MaxValSingleItem', Amount: '6464' },
			],
			SubType: 'WHOLE',
			Type: 'BASEMENT',
			ID: 'withlevel-property||basement',
			label: '2',
		},

		// property with levels

		{
			...commonWithData,
			// PIN: '500662',
			levels: [
				// {
				//   Level: '-2',
				//   Stock: {
				//     Present: true
				//   }
				// },
				// {
				//   Level: '-3',
				// },
				...propertyWithData?.levels,
			],
			Type: 'PROPERTY',
			ID: '123',
			label: '3',
		},

		// basement with level
		// {
		//   ...commonWithData,
		//   // PIN: '500662',
		//   levels: [
		//     // {
		//     //   Level: '-2',
		//     //   Stock: {
		//     //     Present: true
		//     //   }
		//     // },
		//     // {
		//     //   Level: '-3',
		//     // },
		//     ...basementWithData?.levels
		//   ],
		//   Type: 'BASEMENT',
		// },

		// property+basement with no level
		// {
		//   PIN: '500663',

		//   property: {
		//     ...propertyWithoutLevels,
		//     SubType: 'WHOLE',
		//     SumInsured: [
		//       {
		//         Name: '',
		//         Amount: '5,00,000',
		//         Level: '7',
		//       },
		//     ],
		//     Level: '7'
		//     // Stock: {
		//     //   Present: true
		//     // }
		//   },
		//   basement: {
		//     ...basementWithoutLevels,
		//     SubType: 'SINGLE',
		//     SumInsured: [
		//       {
		//         Name: '',
		//         Amount: '12,00,000',
		//         Level: '-2',
		//       },
		//     ],
		//     Level: '-2'
		//     // Stock: {
		//     //   Present: true
		//     // }
		//   },
		//   Type: 'PROPERTYBASEMENT',
		// },

		// property+basement with levels
		// {
		//   ...commonWithData,
		//   // PIN: '500664',

		//   property: {
		//     levels: [
		//       // {
		//       //   Level: '1',
		//       //   Stock: {
		//       //     Present: true
		//       //   }
		//       // },
		//       // {
		//       //   Level: '2',
		//       // },
		//     ...propertyWithData?.levels
		//     ],
		//   },
		//   basement: {
		//     levels: [
		//       // {
		//       //   Level: '-2',
		//       //   Stock: {
		//       //     Present: true
		//       //   }
		//       // },
		//       // {
		//       //   Level: '-3',
		//       // },
		//       ...basementWithData?.levels
		//     ],
		//   },
		//   Type: 'PROPERTYBASEMENT',
		// },
		// each floor and level be treated as new risk location
		// if user selects basement + property - 2 location will be created
	],
}

const RiskLocations = {
	Type: '',
	SubType: '',
	// basement
	NumBasementLevels: '',
	//single level
	FloorNumber: '',
	PrimaryOccupancy: {
		Category: '',
		SubCategory: '',
	},
	// single level information
	AddressLine1: '',
	AddressLine2: '',
	PIN: '',
	City: '',
	State: '',
	ConstructionAge: {
		Low: '',
		High: '',
	},
	Level: '',
	SecondaryOccupancy: {
		Category: '',
		SubCategory: '',
	},
	ThirdPartyUsage: {
		Present: '',
		Details: {
			Category: '',
			SubCategory: '',
		},
	},
	Ownership: '',
	CoOwners: '',
	Hypothecation: {
		Name: '',
		IsFin: '',
	},
}

const RiskLocation = { ...RiskLocations }

// basement mulitple

const mutipleBasement = {
	Level: '',
	RiskType: '',
	Occupancy: '',
	...RiskLocation,
}

const wholeBasement = {
	RiskType: '',
	Occupancy: '',
	NumOfFloors: '',
	...RiskLocation,
}

const basementOther = {
	SecArrangement: '',
	CeilingConst: {
		Type: '',
		Material: '',
	},
	WallsConst: {
		Type: '',
		Material: '',
	},
	FloorConst: {
		Type: '',
		Material: '',
	},
	BasementImgs: '',
	DeWaterFac: {
		Opt: '',
		Facilities: '',
	},
	AMCInForce: '',
	FFArrangement: '',
	FFArrangementImgs: '',
	SurrAreaImgs: '',
	FFArrangementOthers: '',
	FireStationDist: '',
	RailwayCross: '',
	WaterBodyDist: '',
}

const basementClaimHistory = {
	InsuredWithOther: {
		Opt: '',
		Company: '',
		Start: '',
		ExistingPolicy: '',
	},
	DeclinedByOther: {
		Opt: '',
		Company: '',
		Reason: '',
	},
	ClaimsHistory: {
		2021: {
			ClaimAmount: '',
			ClaimStatus: '',
			ClaimReason: '',
			RiskMitigation: '',
		},
	},
}

const basementGoodsInTrust = {
	PlantMachinerySI: '',
	FFFSI: '',
	ElectronicSI: '',
	ValuableContentsSI: '',
	OtherContentsSI: '',
	MaxContentSI: '',
	OfficeEquipSI: '',
	OtherItemsSI: '',
	StockTypes: [
		{
			Type: '',
			Amount: '',
			AmountOpen: '',
		},
	],
}

const basementSumInsured = {
	BuildingSI: '',
	PlantMachinerySI: '',
	FFFSI: '',
	ElectronicSI: '',
	ValuableContentsSI: '',
	OtherContentsSI: '',
	MaxContentSI: '',
	OfficeEquipSI: '',
	PropertyType: '',
}

// const initialQuote = {
//   PolicyName: '',
//   clientDetails: {
//     ProposerName: "",
//     CompanyName: "",
//     Phone: ""
//   },
//   coverage: {
//     Duration: '',
//     StartDate: "",
//     EndDate: ""
//   },
//   location: {
//     NumRiskLoc: 0,
//     NumStock: 0
//   },
//   StockDisclosureMethod: '',
//   stockDetails: {
//     StockInProcess: "",
//     StockRailSiding: ""
//   },
//   //DeclarationBasis
//   DeclarationQ: "",
//   //Floater or Floater Declaration
//   MaxValueAllLoc: "",
//   MaxValueOneLoc: "",
// }

export const quoteReducer = (prevState, action) => {
	switch (action.type) {
		case 'SET_QUOTE_ID':
			return {
				...prevState,
				quoteId: action.data.id,
			}
			break
		case 'NEW_QUOTE': {
			return {
				...defaultState,
				QuoteType: 'New',
			}
		}
		case 'RENEW_QUOTE': {
			return {
				...defaultState,
				QuoteType: 'Renewal',
			}
		}
		case 'SET_POLICY_TYPE': {
			return {
				...prevState,
				PolicyName: action.data.PolicyName,
				RfqType: 'New',
			}
		}
		case 'SET_CLIENT_DETAILS': {
			return {
				...prevState,
				ClientDetails: {
					ProposerName: action.data.ProposerName,
					CompanyName: action.data.CompanyName,
					Phone: action.data.Phone,
					Email: action.data.Email,
					WaPhone: action.data.WaPhone,
					Type: action.data.Type,
				},
			}
		}
		case 'SET_COVERAGE_DETAILS': {
			return {
				...prevState,
				Cover: {
					Duration: action.data.Duration,
					StartDate: action.data.StartDate,
				},
			}
		}
		case 'SET_RISK_LOCATION_COUNT': {
			// initialise all locations
			let locationList = []

			for (let index = 1; index <= action.data.NumRiskLoc; index++) {
				locationList.push({
					ID: nanoid(),
					label: `Location #${index}`,
					Stock: {
						Present: false,
					},
					SumInsured: [],
				})
			}
			return {
				...prevState,
				NumRiskLoc: action.data.NumRiskLoc,
				RiskLocations: locationList,
			}
		}

		case 'RESUME_QUOTE': {
			return {
				...action.data,
			}
		}

		case 'REMOVE_LOCATIONS': {
			return {
				...prevState,
				RiskLocations: [],
			}
		}
		case 'ADD_NEW_RISK_LOCATION': {
			const locationList = [...prevState.RiskLocations]

			let lastLocationLabelCount = 0
			if (locationList.length > 0) {
				let label = locationList[locationList.length - 1].label
				label = label.charAt(label.length - 1)
				lastLocationLabelCount = parseInt(label)
			}
			locationList.push({
				ID: nanoid(),
				label: `Location #${lastLocationLabelCount + 1}`,
				Stock: {
					Present: false,
				},
				SumInsured: [],
			})
			return {
				...prevState,
				RiskLocations: locationList,
				NumRiskLoc: locationList?.length || 1,
			}
		}

		case 'DELETE_RISK_LOCATION': {
			const locationList = prevState.RiskLocations.filter(item => {
				return item.ID !== action.data.locationId
			})

			return {
				...prevState,
				RiskLocations: locationList,
				NumRiskLoc: locationList?.length || 1,
			}
		}

		case 'SET_POLICY_KIND': {
			return {
				...prevState,
				PolicyKind: action.data.PolicyKind,
			}
		}
		case 'SET_LOCATION_WITH_STOCK': {
			return {
				...prevState,
				NumStock: action.data.NumStock,
			}
		}
		case 'SET_STOCK_DISCLOSURE_METHOD': {
			return {
				...prevState,
				StockDisclosureMethod: action.data.StockDisclosureMethod,
			}
		}
		case 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD': {
			return {
				...prevState,
				NumStock: action.data.NumStock,
				StockDisclosureMethod: action.data.StockDisclosureMethod,
			}
		}

		case 'SET_STOCK_IN_PROCESS': {
			return {
				...prevState,
				StockInProcess: action.data.StockInProcess,
			}
		}
		case 'SET_STOCK_RAIL_SIDING': {
			return {
				...prevState,
				StockRailSiding: action.data.StockRailSiding,
			}
		}

		case 'SET_STOCK_REPORTING_METHOD': {
			return {
				...prevState,
				DeclarationQ: {
					...prevState.DeclarationQ,
					ReportingMethod: action.data.ReportingMethod,
				},
			}
		}
		case 'SET_STOCK_VALUE': {
			return {
				...prevState,
				DeclarationQ: {
					...prevState.DeclarationQ,
					MaxValueAllLoc: action.data.MaxValueAllLoc,
					MaxValueOneLoc: action.data.MaxValueOneLoc,
				},
			}
		}
		case 'SET_VALUATION_METHOD': {
			return {
				...prevState,
				ValuationMethodMap: action.data.ValuationMethodMap,
			}
		}
		case 'SET_ADD_ONS': {
			return {
				...prevState,
				Addons: action.data.Addons,
			}
		}
		case 'SET_ESCALATION_CLAUSE': {
			return {
				...prevState,
				EscalationClause: action.data.EscalationClause,
			}
		}
		case 'SET_NOTE_FOR_INSURER': {
			return {
				...prevState,
				Note: action.data.Note,
			}
		}
		case 'SET_POLICY_ADD_ONS': {
			// used for add-ons
			return {
				...prevState,
				PolicyAddons: action.data.PolicyAddons,
			}
		}
		case 'SET_AUTO_CONVERSION': {
			// used for add-ons
			return {
				...prevState,
				AutoConversion: action.data.AutoConversion,
			}
		}

		// Money Insurance Add ons (CIC, CIS, CIT)
		case 'SET_CIC_DATA': {
			return {
				...prevState,
				CIC: action.data.CIC,
			}
		}
		case 'SET_CIS_DATA': {
			return {
				...prevState,
				CIS: action.data.CIS,
			}
		}
		case 'SET_CIT_DATA': {
			return {
				...prevState,
				CIT: action.data.CIT,
			}
		}
		default:
			return prevState
	}
}
