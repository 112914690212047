import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import { useHistory, useLocation } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../../../context/Provider'
import CloudInProgress from '../../../../assets/icons/cloudInProgress.svg'
import { useFetch } from '../../../../api/apihook'

export default function QuotesPending() {
	// const history = useHistory()

	const { state } = useLocation()

	const { downloadPDF } = useFetch()
	// const [, dispatch] = useContext(Context)

	const history = useHistory()

	const { getQuote, mergeRiskLocations } = useFetch()
	const [, dispatch] = useContext(Context)

	// similar flow to 'resume quote'
	const continueQuote = async () => {
		if (!state?.uid) {
			return
		}

		// get rfq data by uid
		let rfqData
		try {
			rfqData = await getQuote(state.uid)
		} catch (err) {
			console.log('ERR: failed to load RFQ')
		}

		const toMerge = {
			...rfqData,
			quoteId: state.uid,
		}
		const mergedData = mergeRiskLocations(toMerge)

		// set locationState context
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'RESUME_QUOTE',
				data: mergedData,
			})
			resolve()
		})

		if (mergedData.RiskLocations.length < mergedData.NumRiskLoc) {
			// add initial locations
			const count = mergedData.NumRiskLoc - mergedData.RiskLocations.length
			for (let index = 0; index < count; index++) {
				dispatch({
					type: 'ADD_NEW_RISK_LOCATION',
				})
			}
		}

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_QUOTE_ID',
				data: {
					id: state?.uid,
				},
			})
			resolve()
		})

		if (state?.locationID !== '') {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE_LOCATION',
					data: {
						locationId: state?.locationID,
					},
				})
				resolve()
			})
		}

		// the final review route should take the user to this route after the updates are done
		// instead of continuing the normal flow

		const flowCompletionRoutes = {
			finalRoute: '/note-for-insurer',
			redirectRoute: '/rfq-status/update-rfq-success',
		}
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_FLOW_COMPLETION_ROUTES',
				data: { flowCompletionRoutes },
			})
			resolve()
		})

		// set update flow as true
		await new Promise(resolve => {
			dispatch({
				type: 'SET_EDIT_RFQ',
				data: { editRfqMode: true },
			})
			dispatch({
				type: 'SET_OLD_RFQ',
				data: JSON.parse(JSON.stringify({ ...mergedData })),
			})
			resolve()
		})

		// navigate to RL selection screen
		history.push('/journey/risk-location-selection')
	}

	const downloadRfqPdf = async () => {
		const result = await downloadPDF(state.uid)
		if (result?.Pdf) {
			const downloadLink = document.createElement('a')
			document.body.appendChild(downloadLink)
			downloadLink.setAttribute('href', result.Pdf)
			downloadLink.setAttribute('target', '_blank')
			downloadLink.setAttribute('download', 'RFQ.pdf')
			downloadLink.click()
			downloadLink.remove()
		}
	}

	const goBack = () => history.push('/quotes-in-progress')

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{state?.PolicyType} &gt; Overview, {state?.uid} - {state?.ClientName}
		</Text>
	)

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			style={{ height: '80%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					borderRadius: '8px',
					width: '95%',
				}}
				className="container h-100 bg-white p-3 mt-4 mx-2 d-flex justify-content-between align-items-center flex-column"
			>
				<div
					style={{
						borderBottom: '1.5px solid #02475E',
					}}
					className="w-100 d-flex align-items-center justify-content-start"
				>
					<img height="64px" width="64px" src={CloudInProgress} alt="" />
					<Text className="mx-3" fontSize="24px" fontWeight="700" color={theme.colors.primary}>
						We are waiting for the quotes to come through!
					</Text>
				</div>

				<div style={{ marginTop: '-5rem' }} className="w-100 d-flex flex-column align-items-start">
					<Text color={theme.colors.gray3} fontSize="24px" fontWeight="700" className="mb-3">
						In the meantime you can
					</Text>

					<Button
						bgColor={theme.colors.secondary4}
						style={{ color: theme.colors.primary, height: '48px', width: '350px' }}
						label="Download RFQ & attachments"
						icon="downloadIcon"
						iconAlign="right"
						className="mb-3"
						iconColor={theme.colors.primary}
						iconStyles={{ marginLeft: '1rem' }}
						onClick={downloadRfqPdf}
					/>

					<Button
						bgColor={theme.colors.secondary4}
						style={{ color: theme.colors.primary, height: '48px', width: '350px' }}
						label="Update RFQ"
						icon="editPencilSquare"
						iconAlign="right"
						iconColor={theme.colors.primary}
						iconStyles={{ marginLeft: '1rem' }}
						onClick={continueQuote}
					/>
				</div>

				<div
					className="d-flex justify-content-start align-items-center mt-2"
					style={{
						background: 'white',
						padding: '12px 24px',
						width: '100%',
						borderTop: '1.5px solid #02475E',
					}}
				>
					<Button
						label="Back"
						bgColor={theme.colors.secondary4}
						style={{ color: theme.colors.primary }}
						icon="leftArrow"
						iconAlign="left"
						iconColor={theme.colors.primary}
						onClick={goBack}
					/>
				</div>
			</div>
		</PageContentAndNavbarWrapper>
	)
}
