import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import TieUpInsurer from '../../../../../assets/training/TieUpInsurer.svg'
import Covid from '../../../../../assets/training/Covid.svg'
import ITDept from '../../../../../assets/training/ITDept.svg'
import SatteliteImagery from '../../../../../assets/training/SatteliteImagery.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function CheatSheet_Paperless() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={Covid}>
						<InfoText fontWeight="700">
							Started due to Covid last year so had to be done without field visit
						</InfoText>
					</InfoBox>
					<ul>
						<li>5,000+ policies sold this way</li>
						<li>{claimsCount} claims done too</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={ITDept}>
						<InfoText fontWeight="700">
							We use data from IT(Tax) Dept. & ROC (Company Registrar)
						</InfoText>
					</InfoBox>
					<ol>
						<li>Verify business registration</li>
						<li>Check for existence for fraud</li>
					</ol>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={SatteliteImagery}>
						<InfoText fontWeight="700">
							We use Satellite images from ISRO (Govt of India Agency)
						</InfoText>
					</InfoBox>
					<ul>
						<li>Capture images of the outside facility</li>
						<li>No physical inspection needed</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={TieUpInsurer}>
						<InfoText fontWeight="700">Tie up with insurer for paperless process</InfoText>
					</InfoBox>
					<ol>
						<li>
							<b>No physical proposal form</b> - We fill in your details, RFQ sent as PDF on
							WhatsApp & email to client
						</li>
						<li>
							<b>No wet signature</b> - Mobile OTP during purchase process suffices as per IRDAI
							(Govt) rules.
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}
