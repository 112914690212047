const parseOccupancies = instantQuoteOccupancies => {
	let validTypeOfFacilities = new Set()
	let validNatureOfBusiness = new Set()
	let occupanciesWithoutNOB = new Set()

	for (const occupancy of Object.keys(instantQuoteOccupancies)) {
		const [typeOfFacility, natureOfBusiness] = occupancy.split(':-')

		validTypeOfFacilities.add(typeOfFacility)
		if (!natureOfBusiness) {
			occupanciesWithoutNOB.add(typeOfFacility)
		} else {
			validNatureOfBusiness.add(natureOfBusiness)
		}
	}

	return {
		validTypeOfFacilities: Array.from(validTypeOfFacilities),
		validNatureOfBusiness: Array.from(validNatureOfBusiness),
		occupanciesWithoutNOB: Array.from(occupanciesWithoutNOB),
	}
}

export const isInstantQuote = (data, instantQuoteOccupancies) => {
	const { Account = {}, Contact = {}, Lead = {} } = data
	const { Name: ProposerName, Phone } = Contact
	const { Name: LegalEntityName, Address } = Account
	const {
		FacilityFloor,
		FacilityType,
		BusinessType,
		WallMaterial,
		RoofMaterial,
		FacilityOwnership,
		SumInsuredBreakdown,
		PIN,
	} = Lead

	const { validTypeOfFacilities, validNatureOfBusiness, occupanciesWithoutNOB } =
		parseOccupancies(instantQuoteOccupancies)

	const validFields = {
		ProposerName: Boolean(ProposerName),
		LegalEntityName: Boolean(LegalEntityName),
		Phone: Phone?.length >= 10,
		Address: Boolean(Address),
		FacilityType: validTypeOfFacilities.includes(FacilityType),
		BusinessType: occupanciesWithoutNOB.includes(BusinessType)
			? true
			: validNatureOfBusiness.includes(BusinessType),
		WallMaterial: Boolean(WallMaterial),
		RoofMaterial: Boolean(RoofMaterial),
		FacilityFloor: typeof parseInt(FacilityFloor) === 'number',
		FacilityOwnership: Boolean(FacilityOwnership),
		PIN: typeof parseInt(PIN) === 'number',
		SumInsuredBreakdown: Object.values(SumInsuredBreakdown || {}).filter(Boolean).length > 0,
	}

	return Object.values(validFields).every(Boolean)
}
