export const oldRfqReducer = (state, action) => {
	switch (action.type) {
		case 'SET_OLD_RFQ':
			return {
				...state,
				...action.data,
			}
		case 'CLEAR_OLD_RFQ':
			return {}
		default:
			return state
	}
}
