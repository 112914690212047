import { useState, useContext } from 'react'
import { Context } from '../../../context/Provider'
import { convertToNumber } from '../../../utilities/convertToNumber'

import { SelectedDisclosureMethod, FloaterValuesError } from '../../../components/SharedComponents'
import { FloaterDeclarationError } from '../Modals'
import { Space, theme, Input, Modal, Text, MainContainer, Divider, Alert } from 'verak-ui'

const FloaterDeclaration2 = ({ toggleFloaterDec, handleSave }) => {
	const [state, dispatch] = useContext(Context)
	const [MaxValueAllLoc, setMaxValueAllLoc] = useState(
		state.quote?.DeclarationQ?.MaxValueAllLoc || ''
	)
	const [MaxValueOneLoc, setMaxValueOneLoc] = useState(
		state.quote?.DeclarationQ?.MaxValueOneLoc || ''
	)
	const [error, setError] = useState(false)
	const [modalState, setModalState] = useState(false)

	const modalContent = <FloaterDeclarationError />

	const checkForValues = () => {
		if (MaxValueAllLoc && MaxValueOneLoc) {
			return false
		} else {
			return true
		}
	}
	const disableProceed = checkForValues()

	const proceed = async () => {
		if (parseFloat(MaxValueOneLoc) > parseFloat(MaxValueAllLoc)) {
			setError(true)
		} else if (parseFloat(MaxValueAllLoc) < 20000000 || parseFloat(MaxValueOneLoc) < 2500000) {
			setModalState(true)
		} else {
			handleSave(MaxValueAllLoc, MaxValueOneLoc)
		}
	}

	const handleChangeInMaxValueAllLoc = e => {
		const numValue = convertToNumber(e.target.value)
		setMaxValueAllLoc(numValue)
	}
	const handleChangeInMaxValueOneLoc = e => {
		const numValue = convertToNumber(e.target.value)
		setMaxValueOneLoc(numValue)
	}

	let renderStocSumInsAlert = null

	if (parseInt(MaxValueAllLoc) > 500000000) {
		renderStocSumInsAlert = (
			<>
				<Space y={1} />
				<Alert
					bgColor={theme.colors.secondaryLightRed}
					iconName="warningTriangleSm"
					infoIconColor={theme.colors.red}
					titleColor={theme.colors.red}
					title="Important Notice"
				>
					<Space y={0.5} />
					<Text type="body2" style={{ marginLeft: '2rem' }}>
						The Sum Insured has exceeded ₹50 crore - you may continue with the quotation. However,
						you will not get an immediate quote - the Sales Manager will need to help you.
					</Text>
				</Alert>
			</>
		)
	}

	return (
		<MainContainer
			header="Stock details"
			top={2.5}
			leftOnClick={() => toggleFloaterDec(0)}
			rightOnClick={proceed}
			rightDisabled={disableProceed}
		>
			<SelectedDisclosureMethod method="Floater Declaration" />
			<Input
				label="Total value of stock:"
				labelStyle={textStyle}
				placeholder="Enter value here"
				value={MaxValueAllLoc}
				onChange={e => handleChangeInMaxValueAllLoc(e)}
				autoComma
				compulsory
				rupee
			/>
			<Space y={2.5} />
			<Alert
				variant="secondary"
				infoIconColor={theme.colors.secondary}
				titleColor={theme.colors.secondary}
				title="Please enter the maximum value for your stock (from the previous year) for each stock
				location and add it together"
			/>
			<Divider top={2.5} bottom={2.5} />
			<Input
				label="Maximum stock value at any one location:"
				labelStyle={textStyle}
				placeholder="Enter value here"
				value={MaxValueOneLoc}
				onChange={e => handleChangeInMaxValueOneLoc(e)}
				autoComma
				compulsory
				rupee
			/>

			{error ? (
				<>
					<Space y={1.5} />
					<FloaterValuesError />
				</>
			) : null}
			{renderStocSumInsAlert}

			<Modal
				show={modalState}
				handleClose={() => setModalState(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Error
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				{modalContent}
			</Modal>
		</MainContainer>
	)
}

export default FloaterDeclaration2

const textStyle = {
	fontSize: '24px',
	fontWeight: 700,
	color: `${theme.colors.gray7}`,
	marginBottom: '1rem',
}
