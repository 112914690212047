import {
	InfoBox,
	InfoText,
	VerticalDivider,
	HorizontalDivider,
} from '../../components/contentUtils'

import ChooseProcess from '../../../../../assets/training/ChooseProcess.svg'
import CheckWebsite from '../../../../../assets/training/CheckWebsite.svg'
import NavigateClaims from '../../../../../assets/training/NavigateClaims.svg'
import NohardCopy from '../../../../../assets/training/NohardCopy.svg'
import ShareFIR from '../../../../../assets/training/ShareFIR.svg'
import WebsiteSteps from '../../../../../assets/training/WebsiteSteps.svg'

export default function Training_CantTrustOnline() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				EMOTIONAL DRAMA DURING CLAIMS
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WebsiteSteps}>
						<InfoText>
							Simple <b>7 steps on website to follow</b> to start with claims process -{' '}
							<InfoText color="#4F76E8">https://verak.in/claims</InfoText>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={ChooseProcess}>
						<InfoText>
							Choose between <b>Whatsapp or Website</b> for the process
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={NohardCopy}>
						<InfoText>
							<b>No Hard Copy</b> - Just explain what happened, send photo, video, soft copies
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={ShareFIR}>
						<InfoText>
							<b>Share FIR</b> with us
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<InfoText className="text-center my-3" spaced fontWeight="700">
				REST VERAK WILL TAKE CARE
			</InfoText>
			<HorizontalDivider />
			<InfoText className="text-center my-3" spaced fontWeight="700">
				TELL THE CUSTOMER TO DO THIS LATER
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CheckWebsite}>
						<InfoText>
							Tell them to <b>check</b> - <InfoText color="#4F76E8">https://verak.in</InfoText>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={NavigateClaims}>
						<InfoText>
							<b>Navigate them to the</b>
							<InfoText color="#4F76E8">https://verak.in/claims</InfoText>
						</InfoText>
					</InfoBox>
				</div>
			</div>
		</div>
	)
}
