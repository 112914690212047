import { Text } from 'verak-ui'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { getAmountInWords } from '../../../utilities/getAmountInWords'
import { SectionCard, InfoItem } from './commonStyle'
import { isInstantQuote } from '../../../utilities/isInstantQuote'
import { useContextApi } from '../../../context/Provider'
import { useFetch } from '../../../api/apihook'
import { useEffect } from 'react'

const SumInsuredNames = {
	Stock: 'Stock SI',
	PlantMachinery: 'Plant & Machinery SI',
	FurnitureFixtures: 'Furniture & Fixtures SI',
	OfficeEquipment: 'Office Equipment SI',
	Electronics: 'Electronic Installation SI',
	Property: 'Property SI',
}

const SumInsuredInfo = ({ data }) => {
	const { Lead = {} } = data
	const [state, dispatch] = useContextApi()
	const { getInstantQuoteOccupancies } = useFetch()

	let ProvisionalQuote = Lead?.ProvisionalQuote || 0
	const SumInsuredBreakdown = Lead?.SumInsuredBreakdown || {}
	const SumInsured = Lead?.SumInsured || ''
	let coverValue = Number(SumInsured)

	// calculate si from breakdown if SumInsured not specified
	if (coverValue === 0) {
		coverValue = Object.values(SumInsuredBreakdown)
			.filter(Boolean)
			.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
	}

	let formattedCoverAmount = '-'
	let coverAmountInWords = ''
	if (!isNaN(coverValue)) {
		formattedCoverAmount = `₹ ${coverValue.toLocaleString('en-IN')}`
		coverAmountInWords = `${getAmountInWords(coverValue)} only`
	}

	let provisionalQuote = ''
	let provisionalQuoteRange = ''
	if (!isNaN(ProvisionalQuote) && ProvisionalQuote) {
		provisionalQuote = Number(ProvisionalQuote)
		const minValue = Math.round(provisionalQuote * 1.1)?.toLocaleString('en-IN')
		const maxValue = Math.round(provisionalQuote * 1.3)?.toLocaleString('en-IN')
		provisionalQuoteRange = `₹${minValue} - ₹${maxValue}`
	}

	const SumInsuredBreakdownKeys = Object.keys(SumInsuredBreakdown)
	const infoItemList = []
	if (SumInsuredBreakdownKeys.length > 0) {
		SumInsuredBreakdownKeys.forEach(key => {
			let value = SumInsuredBreakdown[key]
			if (value) {
				value = `₹ ${parseInt(value)?.toLocaleString('en-IN')}`
				infoItemList.push(<InfoItem label={SumInsuredNames[key]} value={value} key={key} withCol />)
			}
		})
	}
	if (coverValue) {
		infoItemList.push(
			<Col md="3" className="d-flex flex-column" key="SumInsured">
				<InfoItem label="Estimated Sum Insured" value={formattedCoverAmount} />
				<Banner
					background="#E9EFFE99"
					className="mt-1"
					color="#244BCE"
					fontSize="14px"
					fontWeight="500"
				>
					{coverAmountInWords}
				</Banner>
			</Col>
		)
	}

	const fetchInstantQuoteOccupancies = async () => {
		const result = await getInstantQuoteOccupancies()
		dispatch({ type: 'SET_INSTANT_QUOTE_OCCUPANCIES', data: result })
	}

	useEffect(() => {
		if (provisionalQuoteRange && !state?.app?.instantQuoteOccupancies) {
			fetchInstantQuoteOccupancies()
		}
	}, [provisionalQuoteRange]) // eslint-disable-line react-hooks/exhaustive-deps

	if (provisionalQuoteRange && state?.app?.instantQuoteOccupancies) {
		const isInstantQuotePossible = isInstantQuote(data, state?.app?.instantQuoteOccupancies)
		infoItemList.push(
			<Col
				md={isInstantQuotePossible ? '5' : '3'}
				className="d-flex flex-column"
				key="Provisional Quote"
			>
				<InfoItem label="Provisional Quotes" value={provisionalQuoteRange} key="ProvisionalQuote" />
				{isInstantQuotePossible && (
					<Banner background="#F4FFF9" className="mt-1">
						<Text color="#4AA161" fontSize="14px" fontWeight="500">
							<b>Instant Quote is possible</b> once you click on “Finalise RFQ” button. <br />
							Imp info is already collected, FOCUS on SELLING!
						</Text>
					</Banner>
				)}
			</Col>
		)
	}

	if (infoItemList.length === 0) {
		return null
	}
	return (
		<SectionCard className="mt-3 px-1 py-3">
			<div className="d-flex">
				<Text className="mb-2" color={'#6467B0'} fontWeight="700">
					Sum Insured Breakdown and Provisional Quotes
				</Text>
			</div>

			<Row>{infoItemList}</Row>
		</SectionCard>
	)
}

export default SumInsuredInfo

const Banner = styled(Text)`
	background: ${props => props.background};
	background: rgba(233, 239, 254, 0.6);
	border-radius: 6px;
	padding: 8px;
	word-wrap: break-word;
`
