import { useReducer, useMemo, createContext, useContext } from 'react'
import reducers from './reducers'
const initialState = {
	user: {
		authenticated: false,
	},
	app: {
		clientCreated: false,
		isFosUser: false,
	},
	quote: null,
	location: null, //hardcoded
	sqs: {
		clientExists: false,
	},
}

const initialContext = [{ ...initialState }, () => {}]

// lazy initializer for user state
const authInitializer = initialState => {
	// TODO: handle "token expiry" case here
	const user = localStorage.getItem('user')
	if (user) {
		return {
			...initialState,
			user: JSON.parse(user),
			loggedIn: true,
		}
	}
	return initialState
}

export const Context = createContext(initialContext)

export const useContextApi = () => {
	const context = useContext(Context)
	if (!context) {
		throw new Error('useContextApi must be used within AuthProvider')
	}
	return context
}

export function Provider({ children, ...props }) {
	const [state, dispatch] = useReducer(reducers, initialState)
	const value = useMemo(() => [state, dispatch], [state])

	return <Context.Provider value={value}>{children}</Context.Provider>
}
