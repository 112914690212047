export default class EnglishIndia {
	constructor() {
		this.config = {
			currency: {
				name: 'Rupee',
				plural: 'Rupees',
				symbol: '₹',
				fractionalUnit: {
					name: 'Paisa',
					plural: 'Paise',
					symbol: '',
				},
			},
			texts: {
				and: 'And',
				minus: 'Minus',
				only: 'Only',
				point: 'Point',
			},
			numberWordsMapping: [
				{ number: 10000000, value: 'Crore' },
				{ number: 100000, value: 'Lakh' },
				{ number: 1000, value: 'Thousand' },
				{ number: 100, value: 'Hundred' },
				{ number: 90, value: 'Ninety' },
				{ number: 80, value: 'Eighty' },
				{ number: 70, value: 'Seventy' },
				{ number: 60, value: 'Sixty' },
				{ number: 50, value: 'Fifty' },
				{ number: 40, value: 'Forty' },
				{ number: 30, value: 'Thirty' },
				{ number: 20, value: 'Twenty' },
				{ number: 19, value: 'Nineteen' },
				{ number: 18, value: 'Eighteen' },
				{ number: 17, value: 'Seventeen' },
				{ number: 16, value: 'Sixteen' },
				{ number: 15, value: 'Fifteen' },
				{ number: 14, value: 'Fourteen' },
				{ number: 13, value: 'Thirteen' },
				{ number: 12, value: 'Twelve' },
				{ number: 11, value: 'Eleven' },
				{ number: 10, value: 'Ten' },
				{ number: 9, value: 'Nine' },
				{ number: 8, value: 'Eight' },
				{ number: 7, value: 'Seven' },
				{ number: 6, value: 'Six' },
				{ number: 5, value: 'Five' },
				{ number: 4, value: 'Four' },
				{ number: 3, value: 'Three' },
				{ number: 2, value: 'Two' },
				{ number: 1, value: 'One' },
				{ number: 0, value: 'Zero' },
			],
			exactWordsMapping: [{ number: 100, value: 'One Hundred' }],
		}
	}
}
export const DefaultConverterOptions = {
	currency: false,
	ignoreDecimal: false,
	ignoreZeroCurrency: false,
	doNotAddOnly: false,
}
export const DefaultToWordsOptions = {
	localeCode: 'en-IN',
	converterOptions: DefaultConverterOptions,
}
export class ToWords {
	constructor(options = {}) {
		this.options = {}
		this.locale = undefined
		this.options = Object.assign({}, DefaultToWordsOptions, options)
	}
	getLocaleClass() {
		switch (this.options.localeCode) {
			case 'en-IN':
				return EnglishIndia
			default:
				return EnglishIndia
		}
	}
	getLocale() {
		if (this.locale === undefined) {
			const LocaleClass = this.getLocaleClass()
			this.locale = new LocaleClass()
		}
		return this.locale
	}
	convert(number, options = {}) {
		options = Object.assign({}, this.options.converterOptions, options)
		if (!this.isValidNumber(number)) {
			throw new Error(`Invalid Number "${number}"`)
		}
		if (options.ignoreDecimal) {
			number = Number.parseInt(number.toString())
		}
		let words = []
		if (options.currency) {
			words = this.convertCurrency(number, options)
		} else {
			words = this.convertNumber(number)
		}
		return words.join(' ')
	}
	convertNumber(number) {
		var _a, _b, _c
		const locale = this.getLocale()
		const isNegativeNumber = number < 0
		if (isNegativeNumber) {
			number = Math.abs(number)
		}
		const split = number.toString().split('.')
		const ignoreZero = this.isNumberZero(number) && locale.config.ignoreZeroInDecimals
		let words = this.convertInternal(Number(split[0]))
		const isFloat = this.isFloat(number)
		if (isFloat && ignoreZero) {
			words = []
		}
		const wordsWithDecimal = []
		if (isFloat) {
			if (!ignoreZero) {
				wordsWithDecimal.push(locale.config.texts.point)
			}
			if (
				split[1].startsWith('0') &&
				!((_a = locale.config) === null || _a === void 0 ? void 0 : _a.decimalLengthWordMapping)
			) {
				const zeroWords = []
				for (const num of split[1]) {
					zeroWords.push(...this.convertInternal(Number(num)))
				}
				wordsWithDecimal.push(...zeroWords)
			} else {
				wordsWithDecimal.push(...this.convertInternal(Number(split[1])))
				const decimalLengthWord =
					(_c =
						(_b = locale.config) === null || _b === void 0
							? void 0
							: _b.decimalLengthWordMapping) === null || _c === void 0
						? void 0
						: _c[split[1].length]
				if (decimalLengthWord) {
					wordsWithDecimal.push(decimalLengthWord)
				}
			}
		}
		const isEmpty = words.length <= 0
		if (!isEmpty && isNegativeNumber) {
			words.unshift(locale.config.texts.minus)
		}
		words.push(...wordsWithDecimal)
		return words
	}
	convertCurrency(number, options = {}) {
		var _a, _b, _c, _d
		const locale = this.getLocale()
		const currencyOptions =
			(_a = options.currencyOptions) !== null && _a !== void 0 ? _a : locale.config.currency
		const isNegativeNumber = number < 0
		if (isNegativeNumber) {
			number = Math.abs(number)
		}
		number = this.toFixed(number) // Extra check for isFloat to overcome 1.999 rounding off to 2
		const split = number.toString().split('.')
		let words = [...this.convertInternal(Number(split[0]))]
		if (currencyOptions.plural) {
			words.push(currencyOptions.plural)
		}
		const ignoreZero =
			this.isNumberZero(number) &&
			(options.ignoreZeroCurrency ||
				(((_b = locale.config) === null || _b === void 0 ? void 0 : _b.ignoreZeroInDecimals) &&
					number !== 0))
		if (ignoreZero) {
			words = []
		}
		const wordsWithDecimal = []
		const isFloat = this.isFloat(number)
		if (isFloat) {
			if (!ignoreZero) {
				wordsWithDecimal.push(locale.config.texts.and)
			}
			wordsWithDecimal.push(
				...this.convertInternal(
					Number(split[1]) *
						(!locale.config.decimalLengthWordMapping ? Math.pow(10, 2 - split[1].length) : 1)
				)
			)
			const decimalLengthWord =
				(_d =
					(_c = locale.config) === null || _c === void 0 ? void 0 : _c.decimalLengthWordMapping) ===
					null || _d === void 0
					? void 0
					: _d[split[1].length]
			if (
				decimalLengthWord === null || decimalLengthWord === void 0
					? void 0
					: decimalLengthWord.length
			) {
				wordsWithDecimal.push(decimalLengthWord)
			}
			wordsWithDecimal.push(currencyOptions.fractionalUnit.plural)
		} else if (locale.config.decimalLengthWordMapping && words.length) {
			wordsWithDecimal.push(currencyOptions.fractionalUnit.plural)
		}
		const isEmpty = words.length <= 0 && wordsWithDecimal.length <= 0
		if (!isEmpty && isNegativeNumber) {
			words.unshift(locale.config.texts.minus)
		}
		if (!isEmpty && locale.config.texts.only && !options.doNotAddOnly) {
			wordsWithDecimal.push(locale.config.texts.only)
		}
		if (wordsWithDecimal.length) {
			words.push(...wordsWithDecimal)
		}
		return words
	}
	convertInternal(number) {
		var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l
		const locale = this.getLocale()
		if (locale.config.exactWordsMapping) {
			const exactMatch =
				(_b = (_a = locale.config) === null || _a === void 0 ? void 0 : _a.exactWordsMapping) ===
					null || _b === void 0
					? void 0
					: _b.find(elem => {
							return number === elem.number
					  })
			if (exactMatch) {
				return [exactMatch.value]
			}
		}
		const match = locale.config.numberWordsMapping.find(elem => {
			return number >= elem.number
		})
		const words = []
		if (number <= 100 || (number < 1000 && locale.config.namedLessThan1000)) {
			words.push(match.value)
			number -= match.number
			if (number > 0) {
				if (
					(_d = (_c = locale.config) === null || _c === void 0 ? void 0 : _c.splitWord) === null ||
					_d === void 0
						? void 0
						: _d.length
				) {
					words.push(locale.config.splitWord)
				}
				words.push(...this.convertInternal(number))
			}
			return words
		}
		const quotient = Math.floor(number / match.number)
		const remainder = number % match.number
		let matchValue = match.value
		if (
			quotient > 1 &&
			((_f = (_e = locale.config) === null || _e === void 0 ? void 0 : _e.pluralWords) === null ||
			_f === void 0
				? void 0
				: _f.find(word => word === match.value)) &&
			((_g = locale.config) === null || _g === void 0 ? void 0 : _g.pluralMark)
		) {
			matchValue += locale.config.pluralMark
		}
		if (
			quotient === 1 &&
			((_j = (_h = locale.config) === null || _h === void 0 ? void 0 : _h.ignoreOneForWords) ===
				null || _j === void 0
				? void 0
				: _j.includes(matchValue))
		) {
			words.push(matchValue)
		} else {
			words.push(...this.convertInternal(quotient), matchValue)
		}
		if (remainder > 0) {
			if (
				(_l = (_k = locale.config) === null || _k === void 0 ? void 0 : _k.splitWord) === null ||
				_l === void 0
					? void 0
					: _l.length
			) {
				words.push(locale.config.splitWord)
			}
			words.push(...this.convertInternal(remainder))
		}
		return words
	}
	toFixed(number, precision = 2) {
		return Number(Number(number).toFixed(precision))
	}
	isFloat(number) {
		return Number(number) === number && number % 1 !== 0
	}
	isValidNumber(number) {
		return !isNaN(parseFloat(number)) && isFinite(number)
	}
	isNumberZero(number) {
		return number >= 0 && number < 1
	}
}
