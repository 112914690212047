import styled, { css } from 'styled-components'
import { theme, Text } from 'verak-ui'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

const Container = styled.div`
	padding: 8px 10px;
	background: transparent;
`

const selectedTab = css`
	border-left: solid 2px #f78670;
	border-right: solid 2px #f78670;
	border-top: solid 2px #f78670;
	background: linear-gradient(180deg, #fff0ed 0%, #ffffff 100%);
`

const TabItem = styled(Col)`
	height: 30px;
	cursor: pointer;
	${({ selected }) => (selected ? selectedTab : null)};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`

const TabContainer = styled(Row)`
	border-bottom: solid 2px #f78670;
`

const IncidentStatistics = ({ selectedState, selectedCity, incidentStats }) => {
	const [activeTab, setActiveTab] = useState('state')
	const changeTab = id => {
		setActiveTab(id)
	}

	let fireIncidents = '-',
		burglaryIncidents = '-'
	if (activeTab === 'state' && incidentStats?.[activeTab]?.fireIncidents) {
		fireIncidents = `🔥 ${incidentStats?.[activeTab]?.fireIncidents} / per month`
	}

	if (incidentStats?.[activeTab]?.burglaryTheftIncidents) {
		burglaryIncidents = `🚨 ${incidentStats?.[activeTab]?.burglaryTheftIncidents} / per month`
	}

	const statisticsValue = (
		<div className="d-flex mt-2">
			{activeTab === 'state' && (
				<div className="d-flex flex-column w-50">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Fire incidents
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
						{fireIncidents}
					</Text>
				</div>
			)}

			<div className="d-flex flex-column w-50">
				<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
					Burglary incidents
				</Text>
				<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
					{burglaryIncidents}
				</Text>
			</div>
		</div>
	)

	const StateValue = (
		<div className="mx-3">
			<div className="d-flex flex-column mt-2">
				<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
					State
				</Text>
				<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
					{selectedState || '-'}
				</Text>
			</div>
			{statisticsValue}
		</div>
	)

	const CityValue = (
		<div className="mx-3">
			<div className="d-flex flex-column mt-2">
				<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
					City
				</Text>
				<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
					{selectedCity || '-'}
				</Text>
			</div>
			{statisticsValue}
		</div>
	)
	let content
	if (activeTab === 'state') {
		content = StateValue
	} else {
		content = CityValue
	}

	return (
		<Container className="flex-fill d-flex flex-column">
			<TabContainer className="mx-2">
				<TabItem
					className="d-flex align-items-center justify-content-center"
					selected={activeTab === 'state'}
					onClick={() => changeTab('state')}
				>
					<Text color={'#F78670'} fontWeight={'700'}>
						State
					</Text>
				</TabItem>
				<TabItem
					className="d-flex align-items-center justify-content-center"
					selected={activeTab === 'city'}
					onClick={() => changeTab('city')}
				>
					<Text color={'#F78670'} fontWeight={'700'}>
						City
					</Text>
				</TabItem>
			</TabContainer>
			{content}
		</Container>
	)
}

export default IncidentStatistics
