import { Radio, Space, Text, Divider, SearchableSelect } from 'verak-ui'
import { insurerOps } from './options'
import {
	CustomInput,
	GridContainer,
	RadioGroup,
	SearchableSelectLabel,
	SearchableSelectWrapper,
	SharedInputStyles,
	SharedRadioStyles,
} from './SharedStyles'

export default function CoverDenialHistory({
	CoverDenied,
	setCoverDenied,
	declinedBy,
	setDeclinedBy,
}) {
	const isCoverDenied = CoverDenied === true
	const isCoverNotDenied = CoverDenied === false

	const setDenialData = (name, val) => {
		setDeclinedBy(prev => ({
			...prev,
			[name]: val,
		}))
	}

	const enterDenialData = (
		<GridContainer className="mb-3">
			<SearchableSelectWrapper>
				<SearchableSelectLabel compulsory>Select the Insurance company</SearchableSelectLabel>
				<SearchableSelect
					style={SharedInputStyles}
					options={insurerOps}
					value={declinedBy.DenialInsurer}
					onChange={v => setDenialData('DenialInsurer', v)}
					search
					placeholder="Select"
				/>
			</SearchableSelectWrapper>
			<CustomInput
				type="text"
				value={declinedBy.DenialReason}
				onChange={e => setDenialData('DenialReason', e.target.value)}
				placeholder="Enter details here"
				label="Specify the reason for decline"
				compulsory
			/>
		</GridContainer>
	)

	const askDenialData = isCoverDenied ? enterDenialData : null

	return (
		<>
			<Text className="mt-3" type="body1" fontWeight={500} style={{ lineHeight: '22.68px' }}>
				Was cover denied in the last 3 years?
			</Text>
			<RadioGroup>
				<Radio
					labelStyle={SharedRadioStyles}
					value={isCoverDenied}
					onChange={() => setCoverDenied(true)}
					secondary
					text="Yes"
					name="cover-denial"
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={isCoverNotDenied}
					onChange={() => setCoverDenied(false)}
					secondary
					text="No"
					name="cover-denial"
				/>
			</RadioGroup>
			{askDenialData}
			<Divider top={0.5} bottom={1.5} />
		</>
	)
}
