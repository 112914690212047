import styled from 'styled-components'
import { theme, Text, Button, Space, Icon } from 'verak-ui'
import PropTypes from 'prop-types'
import PaymentDropoff from '../assets/icons/paymentDropoff.svg'
import CustomerGeneratedRfq from '../assets/icons/customerGeneratedIcon.svg'

const getVal = type => {
	if (type === 'PROPERTY') {
		return 'Building'
	} else if (type === 'BASEMENT') {
		return 'Basement'
	} else if (type === 'PROPERTYBASEMENT') {
		return 'Building+Basement'
	}
	return ''
}

export const LocationReview = props => {
	return (
		<div className="flex-container">
			{props.type ? <Icon name={`${props.type.toLowerCase()}_mini`} /> : null}
			<Space x={1} />
			<div>
				<UpperTags label={props.label} type={props.type} />
				<Space y={1.25} />
				<LowerTags status={props.status} remove={props.remove} pinCode={props.pinCode} />
			</div>
		</div>
	)
}

const UpperTags = ({ label, type }) => {
	return (
		<div className="flex-container">
			<Text style={{ fontSize: '22px', fontWeight: 700 }} color={theme.colors.primary}>
				{label}
			</Text>
			<Space x={0.625} />
			{type ? (
				<TypeContainer>
					<Text style={textStyle} color={theme.colors.white}>
						{getVal(type)}
					</Text>
				</TypeContainer>
			) : null}
		</div>
	)
}

const LowerTags = ({ status, remove, pinCode }) => {
	return (
		<div className="flex-container">
			{remove ? (
				<Button onClick={remove} icon="remove" bgColor="transparent" style={{ padding: 0 }} />
			) : null}
			<Space x={0.625} />
			<StatusContainer status={status}>
				<Text style={textStyle} color={theme.colors.white}>
					Status: {status}
				</Text>
			</StatusContainer>
			<Space x={0.625} />

			{pinCode ? (
				<Text style={{ fontSize: '22px', fontWeight: 700 }} color={theme.colors.primary}>
					(PIN Code: {pinCode})
				</Text>
			) : null}
		</div>
	)
}

const tagStyleMap = {
	redPrimary: {
		borderColor: theme.colors.red,
		labelColor: theme.colors.white,
		bgColor: theme.colors.red,
		newBadgeLabelBgColor: theme.colors.white,
		newBadgeLabelColor: theme.colors.red,
	},
	redSecondary: {
		borderColor: theme.colors.red,
		labelColor: theme.colors.red,
		bgColor: theme.colors.secondaryLightRed,
	},
	redTertiary: {
		borderColor: theme.colors.red,
		labelColor: theme.colors.red,
		bgColor: theme.colors.white,
	},
	yellowPrimary: {
		borderColor: theme.colors.yellow,
		labelColor: theme.colors.yellow,
		bgColor: theme.colors.secondaryLightYellow,
	},
	yellowSecondary: {
		borderColor: theme.colors.yellow,
		labelColor: theme.colors.yellow,
		bgColor: theme.colors.white,
	},
	greenPrimary: {
		borderColor: theme.colors.green2,
		labelColor: theme.colors.white,
		bgColor: theme.colors.green2,
		newBadgeLabelBgColor: theme.colors.white,
		newBadgeLabelColor: theme.colors.green2,
	},

	greenSecondary: {
		borderColor: theme.colors.green4,
		labelColor: theme.colors.green4,
		bgColor: theme.colors.secondaryLightGreen,
	},
	verakDarkPurple: {
		borderColor: '#112762',
		labelColor: theme.colors.white,
		bgColor: '#112762',
	},
}

const paymentSuccessfulTags = [
	'OPS:SETTLEMENT:PENDING',
	'OPS:CLOSURE:PENDING',
	'OPS:POLICY:PENDING',
]

const getQuoteTagStyles = (label, discrepancyFlag, metaStatus, isSelfServeRfq) => {
	if (isSelfServeRfq) {
		return {
			bgColor: '#288C52',
			borderColor: '#75CC1F',
			labelColor: 'white',
			label: 'Customer Generated RFQ',
		}
	} else if (metaStatus?.FurtherRequirements) {
		return { ...tagStyleMap['redSecondary'], label: 'Requirements pending' }
	} else if (metaStatus?.PaymentDropoff && !paymentSuccessfulTags.includes(label)) {
		return { ...tagStyleMap['redSecondary'], label: 'Payment Process Drop Off' }
	}

	switch (label) {
		// red labels
		case 'underwriter requirements':
			return tagStyleMap['redPrimary']
		case 'AGENT:RFQ:ABANDONED':
			return { ...tagStyleMap['redSecondary'], label: 'RFQ Abandoned' }

		case 'UW:REVIEW:PENDING':
			return { ...tagStyleMap['redSecondary'], label: 'Underwriter review pending!' }

		case 'RFQ:CHANGES':
			return { ...tagStyleMap['redSecondary'], label: 'Changes in RFQ!' }

		case 'OPS:QUOTES:PENDING':
			return { ...tagStyleMap['redSecondary'], label: 'Quotes pending!' }

		case 'AGENT:QUOTE:ABANDONED':
			return { ...tagStyleMap['redSecondary'], label: 'Quote abandoned' }

		case 'rfq rejected':
		case 'quotes pending!':
		case 'premium in review!':
		case 'quote abandoned':
			return tagStyleMap['redSecondary']
		case 'requirements pending':
			return tagStyleMap['redTertiary']

		case 'AGENT:RFQ:PENDING':
			return { label: 'In Progress' }

		// yellow labels
		case 'CLIENT:VERIFICATION:PENDING':
			return { ...tagStyleMap['yellowPrimary'], label: 'Client Verification Pending' }

		case 'disputes raised':
			return tagStyleMap['yellowSecondary']

		// hybrid labels
		case 'AGENT:QUOTES:PENDING':
			if (discrepancyFlag) {
				return { ...tagStyleMap['redSecondary'], label: 'Quotes pending!' }
			} else {
				return { ...tagStyleMap['greenPrimary'], label: 'Quotes ready' }
			}

		// green labels
		case 'AGENT:ALL_QUOTES:PENDING':
			return { ...tagStyleMap['greenPrimary'], label: 'All quotes received' }
		case 'CLIENT:QUOTES:AVAILABLE':
		case 'OPS:RFQ_RESUBMISSION:PENDING':
			return { ...tagStyleMap['greenPrimary'], label: 'Quotes ready' }
		case 'premium reviewed!':
		case 'OPS:POLICY:PENDING':
			return { ...tagStyleMap['greenSecondary'], label: 'Purchase successful' }
		case 'OPS:CLOSURE:PENDING':
			return { ...tagStyleMap['greenSecondary'], label: 'Policy Issued' }
		case 'OPS:RFQ_SUBMISSION:PENDING':
			return { ...tagStyleMap['greenSecondary'], label: 'RFQ submitted' }
		case 'rfq completed':
			return tagStyleMap['greenSecondary']
		case 'AGENT:SETTLEMENT:FAILED':
			return { ...tagStyleMap['redSecondary'], label: 'Purchase unsuccessful!!' }
		case 'OPS:SETTLEMENT:PENDING':
			return { ...tagStyleMap['verakDarkPurple'], label: 'Purchase confirmation pending' }
		case 'RFQ:QUOTES:LOST':
		case 'RFQ:LOST':
			return { ...tagStyleMap['redSecondary'], label: 'Lost' }
		default:
			return {}
	}
}

// NOTE: pass in all the required props if the label isn't a part of the predefined labels list
export const QuoteStatusTag = ({
	status,
	customBorderColor,
	customLabelColor,
	customBgColor,
	isNew = false,
	discrepancyFlag = false,
	metaStatus,
	isSelfServeRfq,
	...props
}) => {
	const { borderColor, labelColor, bgColor, newBadgeLabelBgColor, newBadgeLabelColor, label } =
		getQuoteTagStyles(status, discrepancyFlag, metaStatus, isSelfServeRfq)

	const tagBorderColor = customBorderColor || borderColor
	const tagLabelColor = customLabelColor || labelColor
	const tagBgColor = customBgColor || bgColor

	let renderNewTag = null
	if (!isSelfServeRfq && isNew && !metaStatus?.PaymentDropoff) {
		renderNewTag = (
			<QuoteStatusNewtag className="me-2" color={newBadgeLabelColor} bgColor={newBadgeLabelBgColor}>
				NEW
			</QuoteStatusNewtag>
		)
	}

	if (label === 'In Progress') {
		return (
			<div className="w-100 me-3">
				<Text style={{ fontSize: '12px', fontWeight: '700' }} color={theme.colors.gray3}>
					Incomplete Draft
				</Text>
				<div
					className="w-100 mt-2"
					style={{ height: '8px', background: '#DFDFDF', borderRadius: '12px' }}
				>
					<div
						className="h-100"
						style={{
							background: '#75CC1F',
							width: '70%',
							borderTopLeftRadius: '12px',
							borderBottomLeftRadius: '12px',
						}}
					></div>
				</div>
			</div>
		)
	}

	let labelIcon
	if (isSelfServeRfq) {
		labelIcon = <img src={CustomerGeneratedRfq} alt="customer generated rfq" className="mx-1" />
	} else if (metaStatus.PaymentDropoff && !paymentSuccessfulTags.includes(status)) {
		labelIcon = <img src={PaymentDropoff} alt="paymentDropoff" className="mx-1" />
	}

	return (
		<QuoteStatusContainer
			className="d-flex w-100 align-items-center me-3"
			borderColor={tagBorderColor}
			bgColor={tagBgColor}
		>
			{labelIcon}
			{renderNewTag}
			<Text style={textStyle} color={tagLabelColor}>
				{label}
			</Text>
		</QuoteStatusContainer>
	)
}

const StatusContainer = styled.div`
	border-radius: 4px;
	padding: 5px 15px;
	background-color: ${({ status }) =>
		status === 'Complete' ? theme.colors.green2 : theme.colors.red};
`

const TypeContainer = styled(StatusContainer)`
	background-color: ${theme.colors.secondary};
`

const QuoteStatusContainer = styled.div`
	border-radius: 0.5rem;
	padding: 0.5rem;
	border: 1px solid ${p => p.borderColor};
	background: ${p => p.bgColor};
	width: fit-content;
`

const QuoteStatusNewtag = styled.div`
	border-radius: 4px;
	background: ${p => p.bgColor};
	color: ${p => p.color};
	font-size: 12px;
	font-weight: 700;
	padding: 0 0.25rem;
`

const textStyle = {
	fontSize: '12px',
	fontWeight: 700,
}

QuoteStatusTag.propTypes = {
	status: PropTypes.string.isRequired,
	customBorderColor: PropTypes.string,
	customLabelColor: PropTypes.string,
	customBgColor: PropTypes.string,
	isNew: PropTypes.bool,
}
