const PUCCA_MATERIALS = ['Bricks/Cement/Concrete', 'Tin']
const KUTCHA_MATERIALS = ['Bamboo', 'Plastic', 'Tarpaulin']

const materialList = [
	// PUCCA
	'Bricks/Cement/Concrete',
	'Tin',
	// KUTCHA
	'Bamboo',
	'Plastic',
	'Tarpaulin',
]

const getMaterialType = material => {
	if (PUCCA_MATERIALS.includes(material) || material === 'Cement') {
		return 'Pucca'
	}
	return 'Kutcha'
}

export { getMaterialType, materialList, PUCCA_MATERIALS, KUTCHA_MATERIALS }
