import styled from 'styled-components'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import PropTypes from 'prop-types'

export default function Timeline({ timelineItems, ...props }) {
	const TimeLineItemsMap = timelineItems.map((item, index) => {
		const content = item?.content
		const icon = item?.icon
		const disabled = item?.disabled

		return (
			<VerticalTimelineElement
				className="vertical-timeline-element--work"
				contentStyle={{
					opacity: disabled === true ? '.3' : '1',
					background: '#fff',
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					cursor: disabled === true ? 'not-allowed' : 'auto',
				}}
				key={item.id}
				contentArrowStyle={{ display: 'none' }}
				iconStyle={{
					background: '#fff',
					boxShadow:
						!icon && '0 0 0 4px #8D8D8D,inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)',
				}}
				icon={icon}
			>
				{content}
			</VerticalTimelineElement>
		)
	})

	return (
		<CustomDiv {...props}>
			<VerticalTimeline lineColor="#ECECEC" layout="1-column-left">
				{TimeLineItemsMap}
			</VerticalTimeline>
		</CustomDiv>
	)
}

const CustomDiv = styled.div`
	.vertical-timeline-element-content {
		padding: 1rem;
		border-radius: 8px;
	}
	.vertical-timeline-element-date {
		display: none;
	}
`
Timeline.propTypes = {
	timelineItems: PropTypes.array,
}
