const burglarySIItems = [
	'Stock',
	'PlantMachinery',
	'Electronics',
	'FurnitureFixture',
	'OfficeEquipment',
	'ValuableContents',
	'Other',
]

const calculateBurglarySI = sumInsuredBreakdown => {
	const burglarySI = Object.entries(sumInsuredBreakdown).reduce((acc, [key, value]) => {
		if (burglarySIItems.includes(key)) {
			return acc + parseInt(value)
		}
		return acc
	}, 0)

	return burglarySI
}

export { calculateBurglarySI, burglarySIItems }
