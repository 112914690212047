const defaultSecurityOptions = [
	'Anti-theft locks',
	'CCTV',
	'24 hour security',
	'Alarm system',
	'Night watchman',
]

function prefillTheftRisk(globalState) {

	// this works only for property or basement
	let theftRiskData = globalState.TheftRisk || {}
	let SecurityArrangements = globalState.TheftRisk?.SecurityArrangements;
	if (globalState.property) {
		theftRiskData = globalState.property.TheftRisk;
		SecurityArrangements = globalState.property.TheftRisk?.SecurityArrangements;
	}
	const otherOptionData =
		SecurityArrangements?.find(el => !defaultSecurityOptions.includes(el)) ||
		''
	return { ...theftRiskData, otherOptionData }
}

export { defaultSecurityOptions, prefillTheftRisk }
