import { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { MainContainer, Tabbar } from 'verak-ui'
import { Context } from '../../context/Provider'

import BasementTypeSelector from './basement/BasementTypeSelector'
// import { computeLevels } from './commonUtils/SharedUtils'
import PropertyTypeSelector from './property/PropertyTypeSelector'
import { useFetch } from '../../api/apihook'

function initData(data) {
	return {
		propertyType: data?.SubType || '',
		levels: data?.levels || '',
	}
}

export default function PropertyBasementLevelOccupancy() {
	const fetch = useFetch()

	const [state, dispatch] = useContext(Context)
	const { property, basement } = state.location || {}

	const [selectedTab, setSelectedTab] = useState('PROPERTY')

	const [isPropertyIncomplete, setIsPropertyIncomplete] = useState(true)
	const [isBasementIncomplete, setIsBasementIncomplete] = useState(true)

	const [propertyData, setPropertyData] = useState(initData(property) || {})
	const [basementData, setBasementData] = useState(initData(basement) || {})

	let history = useHistory()

	const goBack = () => {
		if (selectedTab === 'BASEMENT') {
			setSelectedTab('PROPERTY')
		} else {
			history.replace('/property-check')
		}
	}

	const proceed = async () => {
		if (selectedTab === 'PROPERTY') {
			setSelectedTab('BASEMENT')
		} else {
			// dispatch action

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_PROPERTYBASEMENT_USAGE_AND_SUBTYPE',
					data: {
						property: {
							SubType: propertyData.propertyType,
							// levels: computeLevels('property', propertyData.propertyType, propertyData.levels),
							levels: propertyData.levels,
						},
						basement: {
							SubType: basementData.basementType,
							// levels: computeLevels('basement', basementData.basementType, basementData.levels),
							levels: basementData.levels,
						},
					},
				})

				resolve()
			})

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES',
				})
				resolve()
			})

			await fetch.saveQuote()

			history.push('/property-risk/location-details')
		}
	}

	const handleBasementData = values => {
		setBasementData(values)
	}

	const handlePropertyData = values => {
		setPropertyData(values)
	}

	const handleBasementStatusChange = val => {
		setIsBasementIncomplete(val)
	}

	const handlePropertyStatusChange = val => {
		setIsPropertyIncomplete(val)
	}

	const tabList = [
		{
			id: 'PROPERTY',
			title: 'BUILDING',
			disabled: false,
			content: (
				<PropertySection
					onPropertyDataChange={handlePropertyData}
					onPropertyStatusChange={handlePropertyStatusChange}
				/>
			),
		},
		{
			id: 'BASEMENT',
			title: 'BASEMENT',
			disabled: isPropertyIncomplete,
			content: (
				<BasementSection
					onBasementDataChange={handleBasementData}
					onBasementStatusChange={handleBasementStatusChange}
				/>
			),
		},
	]

	return (
		<Container>
			<MainContainer
				header="Risk location occupancy"
				divider={false}
				leftOnClick={goBack}
				rightDisabled={isPropertyIncomplete || isBasementIncomplete}
				rightOnClick={proceed}
			>
				{/* tab section and the selected content */}
				<Tabbar
					onChange={selected => setSelectedTab(selected)}
					activeTabId={selectedTab}
					rounded={false}
					secondary
					tabId="RoundedSecondary-Tab"
					tabList={tabList}
				/>
			</MainContainer>
		</Container>
	)
}

// Property and Basement sections

function PropertySection({ onPropertyDataChange, onPropertyStatusChange }) {
	const setInputCompleteStatus = status => {
		onPropertyStatusChange(!status)
	}

	const saveData = val => {
		onPropertyDataChange(val)
	}
	return (
		<PropertyTypeSelector setInputCompleteStatus={setInputCompleteStatus} saveData={saveData} />
	)
}

function BasementSection({ onBasementDataChange, onBasementStatusChange }) {
	const setInputCompleteStatus = status => {
		onBasementStatusChange(!status)
	}

	const saveData = val => {
		onBasementDataChange(val)
	}
	return (
		<BasementTypeSelector setInputCompleteStatus={setInputCompleteStatus} saveData={saveData} />
	)
}
