import styled from 'styled-components'

import { theme, Text, Space, Divider, Alert } from 'verak-ui'

// Todo: Replace LocationInput is JourneyStart/RiskLocation.js
// Explanation: Rectangular box for display a number (risk location/stock)
export const LocationInput = styled.div`
	height: 100px;
	width: 140px;
	border-radius: 6px;
	border: 1px solid ${theme.colors.primary};
	font-size: 60px;
	text-align: center;
`

// Explanation: This is the oval orange container which shows what declaration type is selected
export const SelectedDisclosureMethod = ({ method }) => {
	return (
		<>
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				{/* Can be generalized */}
				<Text style={{ fontSize: '24px', fontWeight: '700' }} color={theme.colors.primary}>
					Selected method for stock disclosure:
				</Text>
				<Space x={1} />
				<MethodContainer>
					<Text
						style={{ fontSize: '18px', fontWeight: '700', letterSpacing: '0.02em' }}
						color={theme.colors.white}
					>
						{method}
					</Text>
				</MethodContainer>
			</div>
			<Divider top={2.5} bottom={2.5} />
		</>
	)
}
const MethodContainer = styled.div`
	width: min(336px, 100%);
	border-radius: 62px;
	background: ${theme.colors.secondary};
	padding: 8px 0px;
	text-align: center;
`

// Explanation: Error which comes up when max value at 1 location > total values at all locations
export const FloaterValuesError = () => {
	return (
		<Alert
			variant="secondary"
			infoIconColor={theme.colors.secondary}
			titleColor={theme.colors.secondary}
			title="Maximum stock value cannot exceed the total value"
		/>
	)
}

export const headerStyle = {
	fontSize: '24px',
	lineHeigth: '30px',
	fontWeight: 700,
}
export const compulsoryStyle = {
	color: '#EB5757',
}
