import { Text, Icon, Radio, Input, theme, Select } from 'verak-ui'
import { Col } from 'react-bootstrap'
import { KUTCHA_MATERIALS, materialList, PUCCA_MATERIALS } from '../../../utilities/getMaterialType'

const MaterialDetailsQuestion = ({
	icon,
	name,
	radio,
	value1,
	value2,
	radio1OnChange,
	radio2OnChange,
	description,
	onDescriptionChange,
	radioKey,
	radioTextStyle,
	questionStyle = {},
}) => {
	const materialTypeSelected = value1 || value2
	const isOtherSelected = !materialList.includes(description)
	const descriptionSelection = isOtherSelected ? 'Other' : description

	const listToUse = value1 ? KUTCHA_MATERIALS : PUCCA_MATERIALS

	return (
		<div className="d-flex flex-column">
			<div className="mb-3 mt-3 d-flex justify-content-between">
				<Col className="d-flex align-items-center">
					<Icon name={icon} className="me-3" />
					<Text type="body1" className="me-3" style={questionStyle}>
						{name}
						<span style={{ color: theme.colors.red }}>*</span>
					</Text>
				</Col>
				<Col className="d-flex justify-content-around">
					<Radio
						text="Kutcha"
						secondary
						name={`${radioKey}-kutcha`}
						value={value1}
						textStyle={radioTextStyle}
						onChange={radio1OnChange}
					/>
					<Radio
						text="Pucca"
						name={`${radioKey}-pucca`}
						value={value2}
						secondary
						onChange={radio2OnChange}
						textStyle={radioTextStyle}
					/>
				</Col>
			</div>
			<Text fontSize="0.875rem" className="mb-1">
				Material used for {name}
			</Text>
			{materialTypeSelected && (
				<Select
					style={{ height: '48px' }}
					value={descriptionSelection}
					onChange={selected => onDescriptionChange(selected === 'Other' ? '' : selected)}
				>
					<option selected disabled>
						Select material
					</option>
					{listToUse.map(material => (
						<option value={material} key={material}>
							{material}
						</option>
					))}
					<option value="Other">Other</option>
				</Select>
			)}
			{materialTypeSelected && isOtherSelected && (
				<Input
					style={{ width: '320px' }}
					value={description}
					onChange={e => onDescriptionChange(e.target.value)}
					placeholder="Enter material"
					className="mt-2"
				/>
			)}
		</div>
	)
}

export default MaterialDetailsQuestion
