import styled from 'styled-components'
import { theme, Text, Icon } from 'verak-ui'
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap'
import { getDateWordString, channelPartner } from '../../../utilities/sqsDateUtils'
import { CardStyle, InfoItem } from './commonStyle'

import chrome_web from '../../../assets/icons/chrome_web.svg'
import facebook from '../../../assets/icons/facebook.svg'
import instagram from '../../../assets/icons/instagram.svg'
import robot from '../../../assets/icons/robot.svg'
import google from '../../../assets/icons/google.svg'
import youtube from '../../../assets/icons/youtube.svg'

const Container = styled(CardStyle)`
	background: ${theme.colors.white};
	padding: 14px 4px;
	margin: 1rem 0px;
	li {
		margin-bottom: 0.5rem;
	}
	@media (max-width: 816px) {
		margin-top: 1rem;
	}
`

const leadSources = {
	web: { icon: chrome_web, name: 'Website' },
	facebook: { icon: facebook, name: 'Facebook' },
	instagram: { icon: instagram, name: 'Instagram' },
	chatbot: { icon: robot, name: 'Website Chatbot' },
	google: { icon: google, name: 'Google' },
	youtube: { icon: youtube, name: 'YouTube' },
}

const adTypeIcons = {
	Video: '📹',
	Image: '🖼️',
}

const getVideoAdType = campaignHeadline => {
	campaignHeadline = campaignHeadline?.toLowerCase()
	if (campaignHeadline?.includes('video')) return 'Video'
	if (campaignHeadline?.includes('photo')) return 'Image'
	return ''
}

const getLeadSourceTag = (campaignData = {}) => {
	if (!campaignData) return {}

	const { LeadSource = '', Platform = '' } = campaignData

	switch (true) {
		case Platform === 'IG':
			return leadSources['instagram']
		case LeadSource.startsWith('FB') || Platform === 'FB':
		case LeadSource === 'FB_COMMENT_BROCHURE':
			return leadSources['facebook']
		case LeadSource?.indexOf('WEB_CHATBOT') === 0:
			return leadSources['chatbot']
		case LeadSource?.indexOf('G_LG_') === 0:
			return leadSources['google']
		case LeadSource?.indexOf('YT_LG_') === 0:
			return leadSources['youtube']
		case LeadSource.indexOf('WEB') === 0:
			return leadSources['web']
		default:
	}
}

const tooltipTexts = {
	LEAD_ARRIVAL: 'Lead Form was filled at this time by the customer.',
	CALLBACK: 'Customer wants you to call them at this time.',
}

const renderTooltip = props => (
	<Tooltip {...props}>
		<div className="p-1 d-flex flex-column align-items-start text-left">
			<Text fontSize="10px" color={theme.colors.white}>
				{tooltipTexts[props?.type]}
			</Text>
		</div>
	</Tooltip>
)

const channelPartnerIcons = {
	CP_8: 'pickmywork',
	CP_9: 'taskmo',
	CP_10: 'mixsquads',
}

const CustomerSourceCard = ({ campaignData = {}, leadArrivalTime = '', callbackTime = '' }) => {
	let leadArrivalTimePreview = '-'
	if (leadArrivalTime) {
		leadArrivalTimePreview = getDateWordString(leadArrivalTime)
	}
	let callbackTimePreview = '-'
	if (callbackTime) {
		callbackTimePreview = getDateWordString(callbackTime)
	}

	const LeadSource = campaignData?.LeadSource || ''
	const headline = campaignData?.headline || '-'

	const channelPartnerName = channelPartner?.[LeadSource]
	const isChannelPartner = LeadSource && channelPartnerName
	const isFOSLead = LeadSource?.includes('FOS')

	let leadSourceTag = getLeadSourceTag(campaignData)

	let header = (
		<div className="d-flex">
			<Text className="mb-3" color={'#6467B0'} fontWeight="700">
				Customer Kidhar se aya?
			</Text>
		</div>
	)

	let leadArrivalTimeLabel, callbackLabel

	leadArrivalTimeLabel = (
		<div className="d-flex align-items-center">
			<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
				Lead arrival time
			</Text>
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 30, hide: 150 }}
				overlay={props => renderTooltip({ ...props, type: 'LEAD_ARRIVAL' })}
			>
				<span className="d-flex justify-content-center">
					<Icon
						style={{ marginLeft: '.5rem', height: '16px', width: '16px' }}
						name="infoIcon"
						color="black"
					/>
				</span>
			</OverlayTrigger>
		</div>
	)

	if (callbackTime) {
		callbackLabel = (
			<div className="d-flex align-items-center">
				<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
					Callback time
				</Text>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 30, hide: 150 }}
					overlay={props => renderTooltip({ ...props, type: 'CALLBACK' })}
				>
					<span className="d-flex justify-content-center">
						<Icon
							style={{ marginLeft: '.5rem', height: '16px', width: '16px' }}
							name="infoIcon"
							color="black"
						/>
					</span>
				</OverlayTrigger>
			</div>
		)
	}

	if (isChannelPartner || isFOSLead) {
		const channelPartnerIcon = isChannelPartner ? channelPartnerIcons[campaignData?.LeadSource] : ''

		const leadSourceTag = isChannelPartner ? (
			<IconTag
				data={{
					icon: channelPartnerIcon,
					name: channelPartnerName,
					type: 'LEAD_SOURCE',
					displayIcon: true,
				}}
			/>
		) : null

		return (
			<Container className="d-flex flex-column h-100">
				{header}
				<Row>
					<Col md="3">
						<InfoItem
							label={isChannelPartner ? 'Field Executive Name' : 'FOS Agent Name'}
							value={campaignData?.cpAgentName || '-'}
						/>
					</Col>
					<Col md="3">
						<InfoItem label={leadArrivalTimeLabel} value={leadArrivalTimePreview} />
					</Col>
					{isChannelPartner && (
						<>
							<Col md="3">
								<InfoItem label="Lead Source" value={leadSourceTag} />
							</Col>
							<Col md="3">
								<InfoItem
									label="Preferred Language Choice"
									value={campaignData?.language?.toLowerCase()}
									renderIfAvailable
								/>
							</Col>
						</>
					)}
				</Row>
			</Container>
		)
	}

	let talkAbout, typeOfAd, typeOfAdTag
	talkAbout = campaignData?.howToSell || '-'
	typeOfAd = getVideoAdType(campaignData?.headline)
	typeOfAdTag = { name: `${typeOfAd} Ad`, icon: adTypeIcons[typeOfAd] || '', type: 'AD_TYPE' }

	return (
		<Container className="h-100">
			{header}

			<Row>
				<Col md="3">
					<InfoItem label="Region of target" value={campaignData?.region || '-'} />
				</Col>
				<Col md="3">
					<InfoItem label={leadArrivalTimeLabel} value={leadArrivalTimePreview} />
				</Col>
				<Col md="3">
					{callbackTime && <InfoItem label={callbackLabel} value={callbackTimePreview} />}
				</Col>
			</Row>

			<Row className="mt-4">
				<Col md="3">
					<InfoItem label={`Creative Description ${typeOfAdTag.icon}`} value={headline} />
				</Col>
				<Col md="3">
					<InfoItem label="Talk about" value={talkAbout} />
				</Col>
				<Col md="3 d-flex flex-column">
					{typeOfAd && <IconTag data={typeOfAdTag} />}
					{!!leadSourceTag && Object.keys(leadSourceTag)?.length > 0 && (
						<IconTag data={{ ...leadSourceTag, type: 'LEADSOURCE' }} />
					)}
				</Col>
			</Row>
		</Container>
	)
}

const IconTag = ({ data }) => {
	const { icon, name, type } = data
	const background = type === 'LEAD_SOURCE' ? '#EDF6FF' : '#F0E5FE'

	let tagIcon
	switch (true) {
		case data?.displayIcon:
			tagIcon = <Icon name={icon} color="black" />
			break
		case type === 'LEADSOURCE':
			tagIcon = <img src={icon} alt="" />
			break
		default:
			tagIcon = <Text fontSize="14px">{icon}</Text>
	}

	return (
		<div className="mt-2 d-flex align-items-center">
			<div style={{ background }} className="p-1 rounded d-flex">
				{tagIcon}
				<Text style={{ marginLeft: '.4rem' }} color={'#4F76E8'} fontSize="12px" fontWeight="500">
					{name}
				</Text>
			</div>
		</div>
	)
}

export default CustomerSourceCard
