import { Context } from '../../context/Provider'
import { useContext } from 'react'
import { useHistory } from 'react-router'

import { useFetch } from '../../api/apihook'
import { getStockLocation } from '../../utilities/getStockLocation'

import { theme, MainContainer, Icon, Text, Space } from 'verak-ui'

const greenColor = {
	color: theme.colors.green2,
}

const redColor = {
	color: theme.colors.red,
}

const OneLocationWarning = () => {
	let history = useHistory()
	const [state, dispatch] = useContext(Context)
	const fetch = useFetch()
	const disclosureMethod = state.quote?.StockDisclosureMethod
	const RiskLocations = state.quote?.RiskLocations

	const proceed = async () => {
		if (disclosureMethod === 'FLOATER' || disclosureMethod === 'FLOATER_DECLARATION') {
			const locationInfo = getStockLocation(RiskLocations)
			dispatch({
				type: 'MOVE_TO_LOCATION',
				data: {
					locationId: locationInfo.ID,
				},
			})

			history.push({
				pathname: '/edge-case-2/declared-value-details-collection',
				state: {
					isInEdgeCase2Journey: true,
				},
			})
		} else {
			await new Promise(resolve => {
				dispatch({
					type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
					data: {
						NumStock: 1,
						StockDisclosureMethod: 'DECLARED_VALUE',
					},
				})

				resolve()
			})
			await new Promise(resolve => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES',
				})

				resolve()
			})

			await fetch.saveQuote()

			history.push('/journey/risk-location-selection')
		}
	}

	const info = [
		{
			type: 'DECLARATION_BASIS',
			icon: 'thumbsUp',
			text1: (
				<>
					Since the client previously chose the Declaration Basis
					<br /> <span style={greenColor}>no further action is required from them.</span>
				</>
			),
			text2: (
				<>
					Please note that the client will <br />{' '}
					<span style={greenColor}>
						not be required to disclose their stock value on a monthly basis
					</span>
				</>
			),
			label: 'Continue',
		},
		{
			type: 'FLOATER',
			icon: 'climbingStairs',
			text1: (
				<>
					Since the client previously chose the Floater Basis, <br />{' '}
					<span style={redColor}>further action is required!</span>
				</>
			),
			text2: (
				<span style={redColor}>
					The client is required to enter updated details regarding the stock value at the risk
					location with stock
				</span>
			),
			label: 'Re-enter stock details',
		},
		{
			type: 'FLOATER_DECLARATION',
			icon: 'climbingStairs',
			text1: (
				<>
					Since the client previously chose the Floater Declaration Basis,
					<br /> <span style={redColor}>Further action is required!</span>
				</>
			),
			text2: (
				<span style={redColor}>
					The client is required to enter updated details regarding the stock value at the risk
					location with stock
				</span>
			),
			label: 'Re-enter stock details',
		},
		{
			type: 'DECLARED_VALUE',
			icon: 'thumbsUp',
			text1: (
				<>
					Since the client previously chose the Declared Value method,
					<br /> <span style={greenColor}>no further action is required from them.</span>
				</>
			),
			label: 'Continue',
		},
	]

	const pageInfo = info.find(el => el.type === disclosureMethod)

	return (
		<Template
			icon={pageInfo.icon}
			text1={pageInfo.text1}
			text2={pageInfo.text2}
			label={pageInfo.label}
			proceed={proceed}
		/>
	)
}

export default OneLocationWarning

const Template = ({ icon, text1, text2, label, proceed }) => {
	return (
		<MainContainer
			header="Stock details"
			top={5}
			rightLabel={label}
			rightOnClick={proceed}
			style={containerStyle}
		>
			<Icon name={icon} style={{ margin: '0 auto' }} />
			<Space y={1.5} />
			<Text type="primary">{text1}</Text>
			<Space y={1.5} />
			<Text type="primary">{text2}</Text>
		</MainContainer>
	)
}

const containerStyle = {
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
}
