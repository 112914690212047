import { useContext } from 'react'
import { Context } from '../../context/Provider'

import { Space, Text, theme, Button, Icon } from 'verak-ui'
import { useHistory } from 'react-router'
import { useFetch } from '../../api/apihook'

export const Nto0 = ({ NumStock, closeModal }) => {
	let history = useHistory()
	const [state, dispatch] = useContext(Context)
	const fetch = useFetch()

	const isSFSP = state.quote?.PolicyName === 'SFSP'

	const proceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 0,
					StockDisclosureMethod: '',
				},
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_STOCK_VALUE',
				data: {
					MaxValueAllLoc: 0,
					MaxValueOneLoc: 0,
				},
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: '',
				},
			})
			resolve()
		})

		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()
		history.push('/journey/risk-location-selection')
	}

	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText>
				At the start of the quotation, the client had disclosed {NumStock} locations with stock.
			</CustomText>
			<Space y={1} />
			<CustomText>
				However, the client has NOT provided any stock details during this quotation.
			</CustomText>
			<Space y={1} />
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				<Button
					label="Return to previous screen"
					onClick={closeModal}
					style={{ width: '100%', height: '48px' }}
					secondary
				/>
				<Space x={0.625} />
				<Button
					label="Save & Continue"
					onClick={proceed}
					style={{ width: '100%', height: '48px' }}
				/>
			</div>
		</>
	)
}

export const Nto1 = ({ proceed, closeModal }) => {
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText>
				The client had initially declared that there are more than one risk locations containing
				stock.
			</CustomText>
			<Space y={1} />
			<CustomText>However, they have disclosed ONLY 1 risk location containing stock.</CustomText>
			<Space y={1} />
			<CustomText>This will impact the method of Stock valuation</CustomText>
			<Space y={1} />
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				<Button
					label="Back"
					onClick={closeModal}
					style={{ width: '100%', height: '48px' }}
					secondary
				/>
				<Space x={0.625} />
				<Button
					label="Save & Continue"
					onClick={proceed}
					style={{ width: '100%', height: '48px' }}
				/>
			</div>
		</>
	)
}

export const Nto1_Modal2 = () => {
	const history = useHistory()

	const proceed = () => {
		history.push('/edge-case-2/one-location-warning')
	}
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<CustomText color={theme.colors.red}>
				Since only 1 risk location has stock - the client will need to use the Declared Value basis
				for disclosing stock value.
			</CustomText>
			<ModalButton proceed={proceed} />
		</>
	)
}

export const DeclarationBasisError = () => {
	const history = useHistory()

	const proceed = () => {
		history.push('/edge-case-2/declaration-basis-warning')
	}
	return (
		<>
			<CustomText>
				As per the Guidelines, a Declaration policy for stock must: <br />
				1. Have a minimum Sum Insured of ₹1 crores <br />
				2. Have at least one location with a Sum Insured &#62; ₹25 Lakhs
			</CustomText>
			<Space y={1.5} />
			<CustomText color={theme.colors.red}>
				Since the client’s stock does NOT meet this criteria - the disclosure method will
				automatically be changed to DECLARED VALUE.
			</CustomText>
			<ModalButton proceed={proceed} />
		</>
	)
}

const ModalButton = ({ proceed }) => {
	return (
		<>
			<Space y={1.5} />
			<Button
				label="Continue"
				icon="rightArrow"
				iconAlign="right"
				style={buttonStyle}
				onClick={proceed}
			/>
		</>
	)
}

const CustomText = ({ children, color = theme.colors.primary }) => {
	return (
		<Text color={color} style={{ fontWeight: 700, fontSize: '18px' }}>
			{children}
		</Text>
	)
}

const buttonStyle = {
	width: '100%',
	height: '48px',
	fontSize: '18px',
}
