import { useState } from 'react'
import styled from 'styled-components'
import { theme, Text, Icon, Space } from 'verak-ui'
import { CardStyle } from './commonStyle'

import aag from '../../../assets/policy-covers/aag.svg'
import aandhi from '../../../assets/policy-covers/aandhi.svg'
import aatank from '../../../assets/policy-covers/aatank.svg'
import baadh from '../../../assets/policy-covers/baadh.png'
import bhukamp from '../../../assets/policy-covers/bhukamp.svg'
import bhuskhalan from '../../../assets/policy-covers/bhuskhalan.svg'
import chori from '../../../assets/policy-covers/chori.svg'
import dakaiti from '../../../assets/policy-covers/dakaiti.svg'
import danga from '../../../assets/policy-covers/danga.svg'
import dharna from '../../../assets/policy-covers/dharna.svg'
import pani_tanki from '../../../assets/policy-covers/pani_tanki.svg'
import visfot from '../../../assets/policy-covers/visfot.svg'
import ClaimsDoc from '../../../assets/ClaimsDoc.png'
import { Col, Row } from 'react-bootstrap'
import { claimsCount } from '../../../utilities/claimsCount'
const Container = styled(CardStyle)`
	background: ${theme.colors.white};
	li {
		margin-bottom: 0.5rem;
	}
`

const SectionTitle = styled.div`
	border-radius: 4px;
	background: #eff6ff;
	color: #000;
	cursor: pointer;
	box-shadow: ${({ active }) => (active ? '0px 1px 8px rgba(0, 0, 0, 0.15)' : 'none')};
	border: ${({ active }) => (active ? '1px solid #6467b0' : '1px solid transparent')};
`

const CheatSheetContent = styled.div`
	display: flex;
	font-size: 14px;
`

const BlueText = styled.span`
	color: ${theme.colors.blue};
	font-weight: bold;

	@media (max-width: 816px) {
		font-size: ${({ mobileFontSize }) => mobileFontSize || '14px'};
	}
`

const CustomImage = styled.img`
	margin: 0.8rem 1rem;
	height: 28px;
	width: auto;
`
const getTabs = onClaimsDisplay => {
	const cheatSheetContent = [
		{
			title: 'Why us?',
			icon: '⚡',
			content: (
				<CheatSheetContent>
					<div className="d-flex">
						<ul>
							<li>
								Settled <BlueText>{claimsCount} claims last year</BlueText>
							</li>
							<li>
								Existed for <BlueText>5+ years</BlueText>
							</li>
							<li>
								<BlueText>5,000+ Vyapari clients</BlueText> this year
							</li>
							<li>
								<BlueText>Paperless process</BlueText>: Purchase to Claims
							</li>
							<li>
								Quotes from 3+ reputed insurers : <br />
								<BlueText> United India, Oriental, National, New India</BlueText>
							</li>
						</ul>
						<ul>
							<li>
								Best prices <BlueText>guaranteed</BlueText>
							</li>
							<li>
								Our website - <BlueText>https://verak.in</BlueText>
							</li>
							<li>
								<BlueText>Give daily rate of premium to customer</BlueText>
							</li>
							<li>
								<BlueText>24x7 Whatsapp support</BlueText>: +91 9769161630
							</li>
							<li>
								<BlueText>Customer Care Number</BlueText>: +91 9076277062 <br />
								(10 AM to 7PM, Monday to Saturday)
							</li>
						</ul>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Vyapar S',
			icon: '⛱',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<ul className="w-50">
							<li>
								<BlueText>15+ durghatna ke nuksaan se</BlueText> suraksha
							</li>
							<li>
								Low Premium: <BlueText>₹250 per ₹1 Lac</BlueText> - Starting from ₹473
							</li>
						</ul>
						<ul>
							<li>Covers</li>
							<div className="d-flex align-items-center">
								<CustomImage src={chori} alt="policy-covers" />
								<CustomImage src={aag} alt="policy-covers" />
								<CustomImage src={dharna} alt="policy-covers" />
							</div>
							<div className="d-flex align-items-center">
								<CustomImage src={danga} alt="policy-covers" />
								<CustomImage src={dakaiti} alt="policy-covers" />
								<CustomImage src={baadh} alt="policy-covers" />
							</div>
						</ul>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Convincing',
			icon: '💰',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<BlueText>LIC Jeevan Bima Example</BlueText>
							<ul class>
								<li>Self die, family gets money from LIC policy</li>
								<li>Business loss, what about family?</li>
							</ul>
							<Space y={1} />
							<BlueText>Premium Discount</BlueText>
							<ul>
								<li>
									Ask for theft protection (चोरी से बचाव के उपाय) - Agar Tala bhi hai toh 10%
									discount milta hai
								</li>
								<li>Ask for fire protection (अग्नि सुरक्षा उपाय)</li>
								<li>GST number use karke 18% savings pahiye</li>
							</ul>
						</div>
						<div className="w-50">
							<BlueText>Price Match (Best Price Guaranteed)</BlueText>
							<ul style={{ fontSize: '13px' }}>
								<li>Will give customer best price</li>
								<li>
									Agar kahin se kam premium par yeh policy mil rahi hai, to aap hume batao aur hum
									aapke liye usi premium mein option la sakte hain
								</li>
							</ul>
							<BlueText>Maruti Suzuki car example</BlueText>
							<ul style={{ fontSize: '13px' }}>
								<li>₹5 Lac car = ₹8,000 premium</li>
								<li>₹5 Lac stock = ₹1,250 premium</li>
							</ul>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'T&C',
			icon: '📝',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<BlueText>#1: Har claims se 5,000 cut hoga</BlueText>
							<ul>
								<li>Please explain why</li>
							</ul>
							<Space y={1} />
							<BlueText>#2: Details given by customer is correct</BlueText>
							<ul>
								<li>Stock, machine, IT equipment, furniture value etc</li>
								<li>Fire safety & theft safety given correctly</li>
							</ul>
						</div>
						<div className="w-50">
							<BlueText>#3: Under-insurance clause</BlueText>
							<ul>
								<li>Explain what is 15% limit</li>
							</ul>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Covered',
			icon: '🔥',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<Text color={theme.colors.gray2}>Important</Text>
							<div className="d-flex align-items-center">
								<CustomImage src={chori} alt="policy-covers" />
								<Space x={1.2} />
								<CustomImage src={danga} alt="policy-covers" />
							</div>
							<div className="d-flex align-items-center">
								<CustomImage src={dakaiti} alt="policy-covers" />
								<Space x={0.6} />
								<CustomImage src={dharna} alt="policy-covers" />
							</div>
							<div className="d-flex align-items-center">
								<CustomImage src={visfot} alt="policy-covers" />
								<CustomImage src={aatank} alt="policy-covers" />
							</div>
						</div>
						<div className="w-50">
							<Text color={theme.colors.gray2}>Also mention</Text>
							<div className="d-flex align-items-center">
								<CustomImage src={aag} alt="policy-covers" />
								<Space x={1.5} />
								<CustomImage src={baadh} alt="policy-covers" />
							</div>
							<div className="d-flex align-items-center">
								<CustomImage src={aandhi} alt="policy-covers" />
								<Space x={1} />
								<CustomImage src={bhukamp} alt="policy-covers" />
							</div>
							<div className="d-flex align-items-center">
								<CustomImage src={bhuskhalan} alt="policy-covers" />
								<CustomImage src={pani_tanki} alt="policy-covers" />
							</div>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Benefits',
			icon: '🌟',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<ul>
								<li>
									Cash upto <BlueText>₹50,000 is covered</BlueText>
								</li>
								<li>
									<BlueText>Cost to replace documents up to ₹50,000</BlueText>
									<br />
									<br /> Example <br />
									<ol className="ps-3">
										<li className="mb-1">Company formation documents</li>
										<li className="mb-1">Business plans</li>
										<li className="mb-1">Accounting books</li>
									</ol>
								</li>
							</ul>
							<Space y={1} />
						</div>
						<div className="w-50">
							<ul>
								<li>
									<BlueText>Personal belongings of employees, owner & visitors</BlueText>
									<Space y={0.5} />
									<ol className="ps-3">
										<li className="mb-1">Covered upto ₹15,000 per person</li>
										<li className="mb-1">
											Max coverage <br /> 20 X ₹15,000 = ₹3 Lakhs
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Paperless',
			icon: '📱',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<ul>
								<li>
									<BlueText>Started due to Covid</BlueText> last year - had to be done without field
									visit
									<ol className="ps-3 mt-2" type="A">
										<li className="mb-1">5,000+ policies sold this way</li>
										<li className="mb-1">{claimsCount} claims done too</li>
									</ol>
								</li>
							</ul>
							<ul>
								<li>
									<BlueText>We use Satellite images from ISRO</BlueText> (Govt of India सरकारी
									अंतरिक्ष Agency) to capture images of the outside of the facility
									<ol className="ps-3 mt-2" type="A">
										<li className="mb-1">No physical inspection needed</li>
									</ol>
								</li>
							</ul>
						</div>
						<div className="w-50">
							<ul>
								<li>
									<BlueText>
										We use data from IT (Tax) Department & ROC (Company Registrar)
									</BlueText>{' '}
									to verify business registration & existence (for fraud)
								</li>
							</ul>
							<ul>
								<li>
									<BlueText>Tie up with insurers for paperless process:</BlueText>
									<ol className="ps-3 mt-2" type="A">
										<li className="mb-1">
											No physical proposal form - we fill in your details - RFQ sent in PDF from to
											Whatsapp number of clients
										</li>
										<li className="mb-1">
											No wet signatures - Mobile OTP during purchase process suffices as per IRDAI
											(Govt) rules
										</li>
									</ol>
								</li>
							</ul>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Tips for NO claim rejection',
			icon: '❌',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<div className="w-50">
							<ul>
								<li>
									What<BlueText className="mx-1">FLOOR</BlueText>is the business located on?
									<ol className="ps-3 mt-2" type="A">
										<li className="mb-0">1st floor, ground floor or basement (जमीन के नीचे)</li>
										<li className="mb-0">
											Customer should provide address on official WhatsApp number
										</li>
									</ol>
								</li>
							</ul>
							<ul>
								<li>
									What<BlueText className="mx-1">TYPE</BlueText>of business do they run?
									<ol className="ps-3 mt-2" type="A">
										<li className="mb-0">
											Facility type - Shop, Godown, Factory, Service center, Restaurant, Cafe
										</li>
										<li className="mb-0">
											Business Type - Selling what? Making what? Storing what?
										</li>
										<li className="mb-0">Be careful: Shop or godown? Kutcha or Pukka?</li>
									</ol>
								</li>
							</ul>
						</div>
						<div className="w-50">
							<ul>
								<li>
									What is their <BlueText>Stock?</BlueText>
								</li>
								Treat Finished Goods and Raw Material as 2 separate values
							</ul>
							<ul>
								<li>
									<BlueText className="me-1">
										NO documents for purchase - soft copy documents for claims
									</BlueText>
									(Remind Customer)
								</li>
								They will need to sign the claim form
							</ul>
							<ul>
								<li>
									<BlueText>IF you cannot do one call Sale</BlueText>
								</li>
								Push customer to verify details in the RFQ PDF which is sent to them
							</ul>
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Claim Docs',
			icon: '📝',
			content: (
				<CheatSheetContent className="w-100 flex-column mt-3">
					<Row>
						<Col xs={5}>
							<Text className="mt-2" fontSize={'30px'} color={theme.colors.blue}>
								Documents needed for a claim <br /> ------------>
							</Text>
						</Col>
						<Col xs={7}>
							<img src={ClaimsDoc} style={{ height: '200px' }} />
							<div className="my-2 d-flex justify-content-end">
								<div className="border p-1" onClick={onClaimsDisplay} style={{ cursor: 'pointer' }}>
									<Icon name="enlarge" />
								</div>
							</div>
						</Col>
					</Row>
				</CheatSheetContent>
			),
		},

		// {
		// 	title: 'Claims',
		// 	icon: '☎️',
		// 	content: <CheatSheetContent></CheatSheetContent>,
		// },
	]
	return cheatSheetContent
}

export default function CheatSheetCard({ onClaimsDisplay, style = {} }) {
	const [activeTab, setActiveTab] = useState(0)
	const cheatSheetContent = getTabs(onClaimsDisplay)

	const showPrevCheatSheet = () => {
		if (activeTab === 0) {
			return
		}
		setActiveTab(activeTab - 1)
	}
	const showNextCheatSheet = () => {
		if (activeTab === cheatSheetContent.length - 1) {
			return
		}
		setActiveTab(activeTab + 1)
	}

	const tableTitles = cheatSheetContent.map((cheatSheet, index) => (
		<SectionTitle
			key={cheatSheet.title}
			className="me-2 py-1 px-1 d-flex align-items-center flex-shrink-0"
			onClick={() => setActiveTab(index)}
			active={activeTab === index}
		>
			<Text fontWeight="500" fontSize="14px">
				{cheatSheet.icon}
				&nbsp;
				{cheatSheet.title}
			</Text>
		</SectionTitle>
	))
	const selectedCheatSheetContent = cheatSheetContent[activeTab].content

	return (
		<Container className="w-100" style={style}>
			<div className="d-flex justify-content-between">
				<Text className="mb-3" color={'#6467B0'} fontWeight="700">
					Cheat Sheet
				</Text>
				<div className="d-flex">
					<Icon onClick={showPrevCheatSheet} name="leftArrow" className={'me-2'} />
					<Icon onClick={showNextCheatSheet} name="rightArrow" color={theme.colors.gray} />
				</div>
			</div>

			<div className="d-flex overflow-auto mb-3 ">{tableTitles}</div>
			{selectedCheatSheetContent}
		</Container>
	)
}

export {
	SectionTitle,
	BlueText,
	CustomImage,
	CheatSheetContent,
	// images
	aag,
	aandhi,
	aatank,
	baadh,
	bhukamp,
	bhuskhalan,
	chori,
	dakaiti,
	danga,
	dharna,
	pani_tanki,
	visfot,
}
