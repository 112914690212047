import TheftRiskBasement from './TheftRiskBasement'
import TheftRiskPropertyBasement from './TheftRiskPropertyBasement'
import { Context } from '../../../context/Provider'
import { useContext } from 'react'

export default function TheftRiskHandler() {
  const [state] = useContext(Context)

  if (state?.location?.Type === 'BASEMENT') {
    return <TheftRiskBasement />
  } else if (
    state?.location?.Type === 'PROPERTY' ||
    state?.location?.Type === 'PROPERTYBASEMENT'
  ) {
    return <TheftRiskPropertyBasement />
  } else {
    return <></>
  }
}