import { Text, theme, Button, Modal, Icon, Switch, Space } from 'verak-ui'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFetch } from '../../../api/apihook'
import { Spinner } from 'react-bootstrap'
import { TnCModalContent } from '../SharedComponents/SendTnCModal'
import { getVideoSuggestions } from '../../../utilities/getVideoSuggestions'

const EmptyModal = styled.div`
	height: 300px;
`

const NotifTriggerButton = ({
	label,
	icon,
	disabled = false,
	notifStatus,
	style = {},
	moreSteps = false,
	disableStartMargin = false,
	...props
}) => {
	let actionButton
	if (notifStatus === 'TIMER_BLOCKED') {
		actionButton = (
			<div className="d-flex me-3">
				<Icon name="tickCircle" className={'me-2'} />
				<Text color={theme.colors.green} fontWeight="800">
					Sent
				</Text>
			</div>
		)
	} else {
		actionButton = (
			<Button
				label={moreSteps ? 'Select' : 'Send'}
				secondary
				textColor={theme.colors.white}
				onClick={props.onClick}
				bgColor={theme.colors.secondary}
				icon={moreSteps ? 'rightArrow' : ''}
				iconAlign="right"
				className={moreSteps ? 'ps-3 pe-1' : ''}
				style={{ width: '78px' }}
				disabled={disabled}
				applyDisableFilter={disabled}
			/>
		)
	}

	return (
		<div className="d-flex justify-content-between align-items-center w-100 mb-3">
			<div
				className="d-flex"
				style={{ fontSize: '14px', fontWeight: 700, marginLeft: disableStartMargin ? '' : '2rem' }}
			>
				{icon && <Icon name={icon} className="me-2" />}
				{label}
			</div>
			{actionButton}
		</div>
	)
}

// const NotifButton = styled(Button)`
// 	height: 48px;
// 	margin-top: 1rem;
// 	opacity: ${props => (props.disabled ? 0.4 : 1)};
// 	background: ${props => (props.notifTimerBlocked ? '#fff' : theme.colors.primary)};
// 	color: ${props => (props.notifTimerBlocked ? theme.colors.primary : '#fff')};
// 	cursor: ${props =>
// 		props.notifTimerBlocked || props.disabled ? 'not-allowed !important' : 'pointer'};
// 	border: 1px solid ${theme.colors.primary};
// 	&:hover,
// 	&:focus {
// 		background: ${props => (props.notifTimerBlocked ? '#fff' : theme.colors.primary)};
// 		color: ${props => (props.notifTimerBlocked ? theme.colors.primary : '#fff')};
// 	}
// `

const NotificationTriggerModal = ({ handleClose, rfqData, triggerTnCModal }) => {
	const {
		getYoutubeVideos,
		getNotificationStatus,
		toggleWhatsAppConsent,
		sendNotificationToClient,
		getOccupancies,
	} = useFetch()
	const rfqId = rfqData?.id || ''

	const [notifMediums, setNotifMediums] = useState(null)
	const [loading, setLoading] = useState(true)
	const [RFQReceiptStatus, setRFQReceiptStatus] = useState('')
	const [fireBrochureStatus, setFireBrochureStatus] = useState('')
	const [CQJLinkStatus, setCQJLinkStatus] = useState('')
	const [tncTriggerStatus, setTnCTriggerStatus] = useState('')
	const [clientEmail, setClientEmail] = useState('')
	const [YTTrigger, setYTTrigger] = useState(false)
	const [YTLanguage, setYTLanguage] = useState('ENGLISH')
	const [YTTriggerStatus, setYTTriggerStatus] = useState(null)
	const [YTVideos, setYTVideos] = useState(null)

	const [showTypeOfFacilityVideos, setShowTypeOfFacilityVideos] = useState(false)
	const [occupancies, setOccupancies] = useState(null)
	const [occupancyVideos, setOccupancyVideos] = useState(null)

	const [sendOnWhatsapp, setSendOnWhatsapp] = useState(false)
	const [fireBrochureLang, setFireBrochureLang] = useState('ENGLISH')

	const [tncScreen, setTnCScreen] = useState('')

	const getNotifMediumsAndStatus = async displaySpinner => {
		const notificationStatus = await getNotificationStatus(rfqId, displaySpinner)
		setLoading(false)
		setNotifMediums(notificationStatus?.AvailableMediums || null)
		setRFQReceiptStatus(notificationStatus?.RFQReceipt || '')
		setFireBrochureStatus(notificationStatus?.FireBrochure || '')
		setTnCTriggerStatus(notificationStatus?.TnC || '')
		setClientEmail(notificationStatus?.Email || '')
		setCQJLinkStatus(notificationStatus?.CQJLink || '')
		setYTTriggerStatus(notificationStatus?.YoutubeVideos || null)
		setSendOnWhatsapp(notificationStatus?.AvailableMediums?.includes('WHATSAPP') || false)
	}

	const fetchOccupancies = async () => {
		const result = await getOccupancies(rfqId)
		setOccupancies(result)
	}

	useEffect(() => {
		if (showTypeOfFacilityVideos) {
			fetchOccupancies()
		}
	}, [showTypeOfFacilityVideos]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (rfqData?.id) {
			getNotifMediumsAndStatus(false)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const fetchYoutubeVideos = async () => {
		const result = await getYoutubeVideos()
		const normalVideos = result?.filter(video => video?.type !== 'TYPE_OF_FACILITY_VIDEO')
		const typeOfFacilityVideos = result?.filter(video => video?.type === 'TYPE_OF_FACILITY_VIDEO')

		setOccupancyVideos(typeOfFacilityVideos)
		setYTVideos(normalVideos)
	}

	const showYTVideos = async () => {
		setYTTrigger(true)
		if (!YTVideos?.length) {
			await fetchYoutubeVideos()
		}
	}

	const changeYTLanguage = () => {
		if (YTLanguage === 'ENGLISH') {
			setYTLanguage('HINDI')
		} else {
			setYTLanguage('ENGLISH')
		}
	}

	const toggleWAConsent = async () => {
		setSendOnWhatsapp(!sendOnWhatsapp)
		await toggleWhatsAppConsent(rfqId, { status: !sendOnWhatsapp })
		await getNotifMediumsAndStatus(true)
	}

	const handleGoBack = () => {
		if (showTypeOfFacilityVideos) {
			setShowTypeOfFacilityVideos(false)
		} else if (YTTrigger) {
			setYTTrigger(false)
		} else if (tncScreen) {
			if (tncScreen === 'success' || tncScreen === 'insurerSelection') {
				// go back to the home screen
				setTnCScreen('')
			} else if (tncScreen === 'contactInfoSelection') {
				setTnCScreen('insurerSelection')
			}
		}
	}

	let mediums = notifMediums
	if (!sendOnWhatsapp) {
		mediums = notifMediums?.filter(medium => medium !== 'WHATSAPP')
	}

	const sendNotification = async (notificationType, videoId = '') => {
		let notifTemplate = ''
		switch (notificationType) {
			case 'RFQReceipt':
				notifTemplate = 'RFQ:RECEIPT'
				break
			case 'FireBrochure':
				if (fireBrochureLang === 'ENGLISH') {
					notifTemplate = 'FIRE:BROCHURE:ENGLISH'
				} else {
					notifTemplate = 'FIRE:BROCHURE:HINDI'
				}
				break
			case 'CQJLink':
				notifTemplate = 'CQJ:LINK'
				break
			case 'YTVideo':
				notifTemplate = 'YOUTUBE_VIDEO'
				break
			default:
				break
		}

		let reqBody = {}
		if (notificationType === 'FireBrochure') {
			reqBody = {
				mediums: ['WHATSAPP'],
			}
		} else if (notificationType === 'YTVideo') {
			reqBody = {
				videoId,
				language: YTLanguage,
				mediums,
			}
		}

		const result = await sendNotificationToClient(rfqId, notifTemplate, reqBody)
		if (!result.error) {
			// re-fetch the notification statuses
			await getNotifMediumsAndStatus(true)
		}
	}

	// disable the notification triigger buttons conditionally
	const disableRFQReceiptButton = rfqData?.Status === 'AGENT:RFQ:PENDING'
	const disableFireBrochureButton = rfqData?.Status === 'AGENT:RFQ:PENDING'
	const disableCQJLinkButton = !rfqData?.HasAcceptedQuote

	const titleElement = (
		<div className="d-flex align-items-center">
			{(YTTrigger || tncScreen) && (
				<Button
					className="mr-2"
					onClick={handleGoBack}
					bgColor="#fff"
					label=""
					style={{ padding: '0 0.25rem', height: 'auto' }}
					icon="leftArrow"
					iconColor="#999999"
				/>
			)}
			<Icon name="sendNotification" style={{ marginRight: '1rem' }} />
			<Text color={theme.colors.primary} fontSize="14px" fontWeight="600">
				NOTIFICATION TRIGGER
			</Text>
		</div>
	)

	const timerBlockedNotifs = [RFQReceiptStatus, fireBrochureStatus, CQJLinkStatus].includes(
		'TIMER_BLOCKED'
	)

	let toRenderContent
	if (loading) {
		toRenderContent = (
			<EmptyModal className="d-flex align-items-center justify-content-center">
				<Spinner className="mt-2" animation="border" variant="secondary" />
			</EmptyModal>
		)
	} else {
		const commonContent = (
			<>
				<div
					className="d-flex justify-content-between align-items-center p-2 rounded"
					style={{ background: theme.colors.secondary3 }}
				>
					<Text color="#4C4C4C">Send updates on whatsapp</Text>
					<Switch value={sendOnWhatsapp} disabled={sendOnWhatsapp} onChange={toggleWAConsent} />
				</div>
				<Text color="#4C4C4C" className="my-3">
					Notifications will be sent via &nbsp;
					<span style={{ color: theme.colors.secondary, fontWeight: 700 }}>
						{mediums?.join(', ') || ''}
					</span>
				</Text>
			</>
		)

		let bottomWarning

		if (timerBlockedNotifs) {
			bottomWarning = (
				<div
					className="d-flex justify-content-between align-items-center p-2 mt-3 rounded"
					style={{ background: '#F5DBDB' }}
				>
					<Icon name="lapTimer" />
					<Text color="#AC2519" fontSize="12px">
						Once sent, trigger will get active after 2 hours in case you want to resend
					</Text>
				</div>
			)
		}

		let notificationOption

		if (YTTrigger) {
			const isEnglish = YTLanguage === 'ENGLISH'
			if (showTypeOfFacilityVideos) {
				const occupancyList = occupancies?.map((occupancy, index) => {
					const [type, nature] = occupancy?.split(':-')
					return (
						<Text key={occupancy} className="me-2 my-1">
							{index + 1}. {type} : {nature || '-'}
						</Text>
					)
				})

				const [videoSuggestions, otherVideos] = getVideoSuggestions(occupancies, occupancyVideos)

				const getVideoTrigger = (video, index) =>
					video ? (
						<NotifTriggerButton
							key={`${index}-${video.id}`}
							label={`${index + 1}. ${video.title}`}
							notifStatus={YTTriggerStatus?.[video.id] || ''}
							onClick={() => sendNotification('YTVideo', video.id)}
							disableStartMargin
						/>
					) : null

				const videoSuggestionsList = videoSuggestions?.map((video, index) =>
					getVideoTrigger(video, index)
				)
				const otherVideosList = otherVideos?.map((video, index) => getVideoTrigger(video, index))

				notificationOption = (
					<div className="d-flex flex-column justify-content-between">
						<Text className="mb-2" fontSize="14px" fontWeight="700">
							Type of facilities in this RFQ
						</Text>
						<div className="ps-2">{occupancyList}</div>

						{videoSuggestions?.length > 0 && (
							<Text className="my-2" fontSize="14px" fontWeight="700">
								Suggested videos
							</Text>
						)}
						<div className="ps-3">{videoSuggestionsList}</div>

						<Text className="my-2" fontSize="14px" fontWeight="700">
							{videoSuggestions?.length > 0 ? 'Other' : 'All'} videos
						</Text>
						<div className="ps-3">{otherVideosList}</div>
					</div>
				)
			} else {
				notificationOption = (
					<>
						<div className="d-flex justify-content-between align-items-center mb-3">
							<div className="d-flex">
								<Icon name={'youtube'} className="me-2" />
								<Text>Youtube Explainer Videos</Text>
							</div>
							<div className="d-flex align-items-center">
								<Text
									color={!isEnglish ? theme.colors.secondary : theme.colors.blue}
									style={{ opacity: isEnglish ? 0.45 : 1 }}
									className="me-2"
								>
									Hindi
								</Text>
								<Switch value={YTLanguage === 'ENGLISH'} onChange={changeYTLanguage} />
								<Text
									className="ms-2"
									color={isEnglish ? theme.colors.secondary : theme.colors.blue}
									style={{ opacity: isEnglish ? 1 : 0.45 }}
								>
									English
								</Text>
							</div>
						</div>
						{YTVideos?.map((video, index) => {
							return (
								<NotifTriggerButton
									key={`index-${video.id}`}
									label={`${index + 1}. ${video.title}`}
									notifStatus={YTTriggerStatus?.[video.id] || ''}
									onClick={() => sendNotification('YTVideo', video.id)}
								/>
							)
						})}
						<Space y={1} />
						{YTVideos?.length > 0 && (
							<NotifTriggerButton
								label="Type of Facility videos"
								style={{ marginTop: '0.25rem' }}
								onClick={() => setShowTypeOfFacilityVideos(true)}
								notifStatus={''}
								moreSteps={true}
								disableStartMargin
							/>
						)}
					</>
				)
			}
		} else if (tncScreen) {
			notificationOption = (
				<TnCModalContent
					rfqData={rfqData}
					clientEmail={clientEmail}
					screen={tncScreen}
					setScreen={setTnCScreen}
				/>
			)
		} else {
			notificationOption = (
				<>
					<NotifTriggerButton
						label="RFQ e-receipt"
						icon="pdf"
						onClick={() => sendNotification('RFQReceipt')}
						disabled={disableRFQReceiptButton}
						notifStatus={RFQReceiptStatus}
						moreSteps={false}
					/>
					<NotifTriggerButton
						label="CQJ link to review quotes"
						icon="globe"
						onClick={() => sendNotification('CQJLink')}
						disabled={disableCQJLinkButton}
						notifStatus={CQJLinkStatus}
						moreSteps={false}
					/>
					<NotifTriggerButton
						label="Youtube Explainer Videos"
						icon="youtube"
						style={{ marginTop: '0.25rem' }}
						onClick={showYTVideos}
						disabled={disableFireBrochureButton}
						notifStatus={''}
						moreSteps={true}
					/>

					<NotifTriggerButton
						label="Send Terms & Conditions"
						icon="law"
						onClick={() => setTnCScreen('insurerSelection')}
						disabled={disableRFQReceiptButton}
						notifStatus={tncTriggerStatus}
						moreSteps={true}
					/>
				</>
			)
		}

		toRenderContent = (
			<>
				{commonContent}
				{notificationOption}
				{bottomWarning}
			</>
		)
	}

	return (
		<Modal show={true} handleClose={handleClose} title={titleElement} footer={<></>}>
			{toRenderContent}
		</Modal>
	)
}

export default NotificationTriggerModal

// styles
