import styled from 'styled-components'
import { Row } from 'react-bootstrap'
import { theme, Space, Text, Icon, Divider, Alert } from 'verak-ui'

// util functions and components

// ex -
// D15 - will return 15 days
// M3 - 3 months
// Y4 - 4 years
const getDurationStringFromCode = code => {
	if (code === 'D15') {
		return `15 days`
	} else if (code.includes('M')) {
		let months = parseInt(code.replace('M', ''))
		return `${months} ${months > 1 ? 'months' : 'month'}`
	} else if (code.includes('Y')) {
		let years = parseInt(code.replace('Y', ''))
		return `${years} ${years > 1 ? 'years' : 'year'}`
	}
}

const getCustomerDetails = (rfqData = {}) => {
	let customerDetails = rfqData
	const draftData = rfqData?.instantRFQData?.draftRFQ
	if (draftData?.Account && draftData?.Contact) {
		const { Name: EntityName } = draftData?.Account
		const { Name: ProposerName = '', Email = '', Phone = '', WaPhone = '' } = draftData?.Contact
		return {
			EntityName,
			ProposerName,
			Email,
			Phone,
			WaPhone,
		}
	}
	return customerDetails
}

const getFormattedDate = date => {
	if (!(date instanceof Date)) {
		return date
	}
	const d = new Date(date)

	const day = d.getDate() || ''
	const month = d.getMonth() + 1 || ''
	const year = d.getFullYear() || ''

	let fullDate = `${day}-${month}-${year}`
	if (!day || !month || !year) {
		fullDate = ''
	}
	return fullDate
}

const getJoinedString = (arr, key) => {
	let dataArr = []
	if (arr && arr?.length > 0) {
		dataArr = arr?.map(el => `${el[key]}`)
		return dataArr.join(', ')
	} else {
		return ''
	}
}

const getConstructionYearsString = (high, low) => {
	if (!low || !high) {
		return '-'
	}
	if (low === 20 && high === 100) {
		return `20+ years`
	} else {
		return `${low} to ${high} years`
	}
}

const avgString = 'Average of the closing balance for each day in the concerned month'
const maxString = 'Maximum closing balance for any day in the concerned month'

const getReportingStatement = method => {
	if (method === 'AVG') {
		return avgString
	} else if (method === 'MAX') {
		return maxString
	} else {
		return null
	}
}

const getOwnership = Ownership => {
	if (Ownership === 'SOLE') {
		return 'Sole ownership'
	} else if (Ownership === 'CO-OWNED') {
		return 'Co-ownership'
	} else if (Ownership === 'RENTED') {
		return 'Rented'
	}
	return '-'
}

const convertToPlural = number => (number > 1 ? 's' : '')

const getPropertyString = type => {
	switch (type) {
		case 'BASEMENT':
			return 'Basement only'
		case 'PROPERTY':
			return 'Building only'
		case 'PROPERTYBASEMENT':
			return 'Building + Basement'
		default:
			return ''
	}
}

const getStockDisclosureMethodString = method => {
	switch (method) {
		case 'DECLARED_VALUE':
			return 'Declared value'
		case 'FLOATER':
			return 'Floater basis'
		case 'FLOATER_DECLARATION':
			return 'Floater Declaration'
		case 'DECLARATION_BASIS':
			return 'Declaration basis'
		default:
			return ''
	}
}

// removing the comma if no description is present for "Kutcha, Pucca"
const constructionDesc = value => {
	if (value) {
		return `, ${value}`
	} else {
		return ''
	}
}

const convertAmountToWords = (amountStr, singular = false) => {
	// extract rupee string using regex
	let amountStrArr = amountStr.match(/(₹ ([0-9,.])+)/g)
	// temporary copy to use later
	let tempAmountStrArr = amountStrArr
	if (amountStrArr) {
		amountStrArr = amountStrArr.map(item => {
			// convert to a proper number here
			let num = item.split(' ')[1].split(',').join('')
			// console.log(item)
			return Math.floor(num / 10000000)
				? '₹ ' +
						Math.round((num / 10000000 + Number.EPSILON) * 100) / 100 +
						' Crore' +
						(!singular ? convertToPlural(Math.floor(num / 10000000)) : '')
				: Math.floor(num / 100000)
				? '₹ ' +
				  Math.round((num / 100000 + Number.EPSILON) * 100) / 100 +
				  ' Lakh' +
				  convertToPlural(Math.floor(num / 100000))
				: Math.floor(num / 1000)
				? '₹ ' +
				  Number(num)
						.toFixed(0)
						.toString()
						.replace(/(?=(\d{3})(?!\d))/g, ',') // add a ',' only 3 digits before by searching for empty string
				: Number(num)
				? `₹ ${Number(num).toFixed(0)}`
				: item.replace(/₹/, '')
		})
		// replace the occurances in the original string using the temporary one as a reference
		tempAmountStrArr.forEach(
			(item, key) => (amountStr = amountStr.replace(item, amountStrArr[key]))
		)
	}

	// conversion of dollars to rupees
	amountStrArr = amountStr.match(/(\$([0-9,])+)/g)
	tempAmountStrArr = amountStrArr
	if (amountStrArr) {
		amountStrArr = amountStrArr.map(item => {
			// convert to a proper number here
			let num = Number(item.split('$')[1].split(',').join('')) * 75
			// console.log(item)
			return Math.floor(num / 10000000)
				? '₹ ' + Math.round((num / 10000000 + Number.EPSILON) * 100) / 100 + ' Crore'
				: Math.floor(num / 100000)
				? '₹ ' + num / 100000 + ' Lakh'
				: Math.floor(num / 1000)
				? '₹ ' + num.toString().replace(/(?=(\d{3})(?!\d))/g, ',') // add a ',' only 3 digits before by searching for empty string
				: item
		})
		// replace the occurances in the original string using the temporary one as a reference
		tempAmountStrArr.forEach(
			(item, key) => (amountStr = amountStr.replace(item, amountStrArr[key]))
		)
	}
	return amountStr.replace(/ /, '')
}

// const calculateEndDate = (startDate, duration) => {
//   var date = new Date(startDate);
//   date.setMonth(date.getMonth() + parseInt(duration));
//   return date
// }

const extractNum = str => {
	return str.replace('level', '').replace('floor', '')
}

const getNumString = num => (num < 10 ? `0${num}` : `${num}`)

const getOccupancyValue = (occupancyString, key) => {
	if (occupancyString) {
		const occupancyArr = occupancyString.split(':-')
		if (key === 'Type') {
			return occupancyArr[0]
		} else if (key === 'Nature') {
			return occupancyArr[1]
		}
	}
	return ''
}

const noStockAlert = (
	<>
		<Space y={1} />
		<Alert
			iconName="warningTriangleSm"
			infoIconColor={theme.colors.primary}
			bgColor={theme.colors.secondary4}
		>
			<Text color={theme.colors.primary} style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
				Notice: Stock has not been declared at this location
			</Text>
		</Alert>
	</>
)

const InfoSection = ({
	sectionTitle,
	sectionSubTitle,
	onEditClick,
	showDivider = false,
	edit = true,
	containerStyles = {},
	goodsHeldInTrust,
	children,
}) => {
	const titleStyle = { color: theme.colors.grey7, fontWeight: 700, textTransform: 'uppercase' }
	return (
		<InfoSectionContainer className="p-4 my-3" style={containerStyles}>
			<>
				<div style={{ cursor: 'pointer' }} className="d-flex flex-column">
					<div className="d-flex justify-content-between">
						<div className="flex-container">
							<Text style={titleStyle}>{sectionTitle}</Text>
							{goodsHeldInTrust}
						</div>

						{edit && (
							<div
								onClick={onEditClick}
								className="d-flex justify-content-between align-items-center"
							>
								<Icon name="editPencilSquare" />
								<Space x={0.5} />
								<Text style={{ color: theme.colors.secondary }}>Edit Details </Text>
							</div>
						)}
					</div>
					<Space y={0.5} />
					{sectionSubTitle}
				</div>
				<Divider style={{ border: `0.5px solid #02475E` }} top="1rem" bottom="1rem" />
			</>

			{children}
		</InfoSectionContainer>
	)
}

const InfoRow = ({ leftText, rightText, subText }) => {
	return (
		<>
			<Space y={0.5} />
			<div className="d-flex justify-content-between">
				{leftText && (
					<Text color={theme.colors.gray2} fontSize="18px">
						{leftText}
					</Text>
				)}

				{rightText ? (
					<Text style={{ ...boldText, maxWidth: '50%', overflowWrap: 'break-word' }}>
						{rightText}
					</Text>
				) : null}
			</div>

			{subText ? (
				<Text color={theme.colors.gray3} fontSize="14px">
					{subText}
				</Text>
			) : null}

			<Space y={0.5} />
		</>
	)
}

const FileUploaded = () => {
	return (
		<FileUploadedWrapper className="d-flex justify-content-center flex-column">
			<Dash />
			<Dash />
			<Dash />
			<Dash />
			<Dash />
		</FileUploadedWrapper>
	)
}

const InfoSectionContainer = styled.div`
	background: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
`

const FileUploadedWrapper = styled.div`
	background: #ffffff;
	border: 0.5px dashed #c4c4c4;
	box-sizing: border-box;
	border-radius: 8px;
	height: 72px;
	width: 72px;
	padding: 4px;
	margin-left: 0.5rem;
`

const Dash = styled.div`
	background: #c8d6db;
	height: 8px;
	width: 100%;
	margin-bottom: 2px;
`

const StockType = styled(Row)`
	background: rgba(200, 214, 219, 0.4);
	border-radius: 10px;
	padding: 8px;
	margin: 8px;
`

const ClaimsHistoryTable = styled.table`
	background: #d3dcdf;
	border-radius: 8px;
	width: 100%;
	table-layout: fixed;
	padding: 8px;
`
const ClaimsHistoryTableRow = styled.tr`
	&:not(:last-child) {
		border-bottom: 1px solid #02475e66;
	}
`
const ClaimsHistoryTableData = styled.td`
	font-size: 18px;
	min-width: 50%;
	padding: 1rem;
	word-wrap: break-word;
	overflow-wrap: break-word;
	color: #6d6d6d;
	&:not(:last-child) {
		border-right: 1px solid #02475e66;
	}
`
const ClaimsHistoryTableHeader = styled.th`
	font-size: 14px;
	font-weight: 700;
	color: ${theme.colors.primary};
	padding: 1rem;
	border-bottom: 1px solid #02475e66;
`

const infoContainerStyle = {
	padding: '0',
	background: 'transparent',
}

const footerStyle = {
	padding: '1.5rem',
	background: '#fff',
	width: 'min(900px, 100%)',
}

const boldText = {
	color: theme.colors.primary,
	fontSize: '18px',
	fontWeight: 'bold',
}

const secondaryTitle = {
	color: theme.colors.secondary,
	fontSize: '14px',
	fontWeight: 700,
}

export {
	// utils and components
	getFormattedDate,
	getJoinedString,
	getConstructionYearsString,
	getNumString,
	FileUploaded,
	InfoSection,
	InfoRow,
	noStockAlert,
	getPropertyString,
	extractNum,
	getDurationStringFromCode,
	getStockDisclosureMethodString,
	convertAmountToWords,
	avgString,
	maxString,
	getReportingStatement,
	getOwnership,
	getOccupancyValue,
	getCustomerDetails,
	constructionDesc,
	//styles
	StockType,
	infoContainerStyle,
	footerStyle,
	boldText,
	secondaryTitle,
	ClaimsHistoryTable,
	ClaimsHistoryTableRow,
	ClaimsHistoryTableHeader,
	ClaimsHistoryTableData,
}
