export const compulsoryAddOns = [
	{
		icon: 'stfi',
		title: 'STFI',
		checkboxDisabled: true,
		prefilled: true,
	},
	{
		icon: 'rsmd',
		title: 'RSMD',
		checkboxDisabled: true,
		prefilled: true,
	},
]

export const optionalAddOns = [
	{
		icon: 'theft',
		title: 'Theft',
		prefilled: true,
	},
	{
		icon: 'terrorism',
		title: 'Terrorism',
		prefilled: true,
	},
	{
		icon: 'architects',
		title: 'Architects, Surveyors and Consulting Engineers Fees ',
	},
	{
		icon: 'removalOfDebris',
		title: 'Removal of Debris',
	},
	{
		// reason category headers are appended for subquestions are:
		// 1) helps in identification for querying in the future
		// 2) helps in arranging/grouping in the review/summary/rfq pdf for future
		// eg: How would we know Category I and II are under spontaneous combustion - just split based on '-' and group similar add ons

		icon: 'coldStorage',
		title: 'Deterioration of Stocks in Cold Storage premises',
		options: [
			{
				title: 'Deterioration of Stocks in Cold Storage premises-Due to accidental power failure',
				subtitle: 'consequent to damage at the premises of Power Station due to an insured peril',
				prefilled: true,
			},
			{
				title: 'Deterioration of Stocks in Cold Storage premises-Due to change in temperature ',
				subtitle:
					'arising out of loss or damage to the cold storage machinery(ies) in the Insured’s premises due to operation of insured peril.',
				prefilled: true,
			},
		],
	},
	{
		icon: 'forestFire',
		title: 'Forest Fire',
	},
	{
		icon: 'impactDamage',
		title: 'Impact Damage',
		subtitle:
			'due to Insured’s own Rail / Road Vehicles, Fork lifts, Cranes, Stackers and the like and articles dropped therefrom.',
		prefilled: true,
	},
	{
		icon: 'spontaneousCombustion',
		title: 'Spontaneous Combustion',
		prefilled: true,
	},
	{
		icon: 'omission',
		title: 'Omission to Insure additions',
		subtitle: 'alterations or extensions',
		prefilled: true,
	},
	{
		icon: 'earthquake',
		title: 'Earthquake',
		prefilled: true,
	},
	{
		icon: 'spoilage',
		title: 'Spoilage Material Damage Cover',
		prefilled: true,
	},
	{
		icon: 'leakageCover',
		title: 'Leakage Cover only',
		prefilled: true,
	},
	{
		icon: 'leakageAndContamination',
		title: 'Leakage And Contamination Cover',
		options: [
			{
				title:
					'Leakage And Contamination Cover-Where the tanks are within the Insured’s own premises',
			},
			{
				title: 'Leakage And Contamination Cover-Where the tanks are located elsewhere',
			},
		],
	},
	{
		icon: 'lossOfRent',
		title: 'Loss of Rent clause',
	},
	{
		icon: 'rent',
		title: 'Insurance Of Additional Expenses of Rent For An Alternative Accommodation',
	},
	{
		icon: 'startup',
		title: 'Start up Expenses',
	},
	{
		icon: 'moltenMaterial',
		title: 'Molten Material Spillage',
		options: [
			{
				title: 'Molten Material Spillage-Material Damage',
				prefilled: true,
			},
			{
				title: 'Molten Material Spillage-Loss Of Profits',
				prefilled: true,
			},
		],
	},
]
