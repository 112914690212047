export const calculateTotalStockVal = quote => {
	// console.log('$$', quote)
	let total = 0

	const calcStockForOneLoc = detailsList => {
		for (const item in detailsList) {
			total =
				total +
				parseInt(detailsList[item]?.AmountClosed || 0) +
				parseInt(detailsList[item]?.AmountOpen || 0)
		}
	}

	const calcStock = obj => {
		if (obj?.levels) {
			for (const level in obj?.levels) {
				let levelStockData = obj?.levels[level]?.Stock
				if (levelStockData?.Present) {
					calcStockForOneLoc(levelStockData?.Details)
				}
			}
		} else if (obj?.Stock?.Present) {
			calcStockForOneLoc(obj?.Stock?.Details)
		}
	}

	let RiskLocations = quote?.RiskLocations
	let Type = quote?.StockDisclosureMethod

	if (Type === 'DECLARED_VALUE' || Type === 'DECLARATION_BASIS') {
		for (const location in RiskLocations) {
			// RiskLocations is the array of RiskLocations
			if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
				calcStock(RiskLocations[location].property)
				calcStock(RiskLocations[location].basement)
			} else {
				calcStock(RiskLocations[location])
			}
		}
		return total
	} else {
		return parseInt(quote?.DeclarationQ?.MaxValueAllLoc || 0)
	}
}
