import { useHistory } from 'react-router'
import { useState, useEffect, useContext } from 'react'
import { useFetch } from '../../api/apihook'

import { compulsoryAddOns, optionalAddOns } from './AddOnsList'
import { Context } from '../../context/Provider'
import { convertToNumber } from '../../utilities/convertToNumber'
import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../../utilities/generateSumInsuredBreakdown'
import { goodsInTrustTotalSIForAllRiskLocations } from '../../utilities/goodsInTrustTotalSIForAllRiskLocations'

import { headerStyle } from '../../components/SharedComponents'
import { Icon, Space, Text, theme, Checkbox, Input, MainContainer } from 'verak-ui'

const handleNumberInput = value => {
	let numInput = value
	if (typeof value === 'string') {
		numInput = convertToNumber(value)
	}
	return parseFloat(numInput)
}

const stockRelatedAddOns = [
	'Deterioration of Stocks in Cold Storage premises',
	'Spontaneous Combustion',
]

const AddOns = () => {
	let history = useHistory()
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)

	// handling total Sum Insured for the policy -> Sum Insureds + Stock + Goods in trust
	const sumInsuredBreakdown = generateSumInsuredBreakdown(state?.quote?.RiskLocations || [])
	const stockValue = calculateTotalStockVal(state?.quote)
	const totalGoodsInTrust = goodsInTrustTotalSIForAllRiskLocations(
		state?.quote?.RiskLocations || []
	)

	const totalSumInsured =
		totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) +
		(stockValue || 0) +
		(totalGoodsInTrust || 0)

	// handling total stock value -> both for floater as well as for declared -> "stockValue" variable

	const prefillData = () => {
		let prefilledAddOns = state.quote?.Addons
		if (prefilledAddOns?.length > 0) {
			// needs to be returned without the 2 compulsory one's
			// reason removing of the compulsory addons were done at the end was because "disabled" checkboxes
			// were being treated as not selected and thus were getting deleted
			const compulsoryAddOns = ['STFI', 'RSMD']
			const newList = prefilledAddOns.filter(el => !compulsoryAddOns.includes(el.Name))

			return newList
		} else {
			return []
		}
	}

	const [addOnsList, setAddOnsList] = useState(prefillData())
	const appendNewAddOn = (title, amount) => {
		let alteredList = [...addOnsList]
		let addOn = alteredList.find(item => item.Name === title)
		if (addOn !== undefined) {
			addOn.SumInsured = amount
			setAddOnsList(alteredList)
		} else {
			const data = {
				Name: title,
				SumInsured: amount,
			}
			setAddOnsList([...addOnsList, data])
		}
	}

	const deleteAddOn = title => {
		let alteredList = addOnsList.filter(item => item.Name !== title)
		setAddOnsList(alteredList)
	}

	const handleBack = () => {
		// there so that it doesn't return to the edge case screens (if that case arises)
		history.push('/journey/risk-location-selection')
	}

	const proceed = async () => {
		// reason appending of the compulsory addons were done at the end was because "disabled" checkboxes
		// were being treated as not selected and thus were getting deleted
		const newList = [
			...addOnsList,
			{ Name: 'STFI', SumInsured: totalSumInsured },
			{ Name: 'RSMD', SumInsured: totalSumInsured },
		]
		await new Promise(resolve => {
			dispatch({
				type: 'SET_ADD_ONS',
				data: {
					Addons: newList,
				},
			})
			resolve()
		})

		// reason this condition is set is -> if its stock only then it won't have Sum Insured values
		// which makes asking escalation clauses redundant

		// logic will be implemented once the card raised for handling all the cases for PolicyKind is done
		// and deployed; ref: https://www.notion.so/873efff4a9d44e14afed0c04b5ea544e?v=728e4b7cd3f94c4ca72a034e9fc0c21c&p=affda837f5f745448d3c6c99e93cb940
		// if (state?.quote?.PolicyKind === "STOCK_ONLY") {
		//     history.push('/summary')
		// } else {
		//     history.push('/sfsp/escalation-clause')
		// }

		await fetch.saveQuote()

		history.push('/sfsp/escalation-clause')
	}

	return (
		<MainContainer header="Add-ons" rightOnClick={proceed} leftOnClick={handleBack} top={3}>
			<Text style={headerStyle} color={theme.colors.gray7}>
				Select the Add-ons required
			</Text>
			{compulsoryAddOns.map(el => (
				<SingleAddOn
					icon={el.icon}
					title={el.title}
					checkboxDisabled={el.checkboxDisabled}
					prefilled={el.prefilled}
					totalSumInsured={totalSumInsured}
					stockValue={stockValue}
					appendNewAddOn={appendNewAddOn}
					deleteAddOn={deleteAddOn}
				/>
			))}
			<Space y={2} />

			<Text style={headerStyle} color={theme.colors.gray7}>
				In addition to the above the client can opt for these Add-ons too:
			</Text>
			{optionalAddOns.map(el => (
				<SingleAddOn
					addOnsList={addOnsList}
					options={el.options}
					icon={el.icon}
					title={el.title}
					subtitle={el.subtitle}
					checkboxDisabled={el.checkboxDisabled}
					prefilled={el.prefilled}
					totalSumInsured={totalSumInsured}
					stockValue={stockValue}
					appendNewAddOn={appendNewAddOn}
					deleteAddOn={deleteAddOn}
				/>
			))}
		</MainContainer>
	)
}

export default AddOns

const SingleAddOn = ({
	addOnsList,
	options,
	icon,
	title,
	subtitle,
	checkboxDisabled,
	prefilled,
	totalSumInsured,
	stockValue,
	appendNewAddOn,
	deleteAddOn,
}) => {
	const getPrefilledAddOnPresence = addOnTitle => {
		let data = addOnsList?.find(el => el.Name === addOnTitle)
		if (data !== undefined) {
			return true
		} else {
			return false
		}
	}
	const getPrefilledAddOnValue = (addOnTitle, categoryHeader, addOnPrefilled) => {
		if (addOnPrefilled) {
			// basically means it should get a prefilled value
			if (stockRelatedAddOns.includes(categoryHeader)) {
				return stockValue
			} else {
				return totalSumInsured
			}
		} else {
			let data = addOnsList?.find(el => el.Name === addOnTitle)
			if (data !== undefined) {
				return data?.SumInsured
			} else {
				return null
			}
		}
	}

	const [isAddOnPresent, setAddOnPresence] = useState(getPrefilledAddOnPresence(title))
	const [addOnValue, setAddOnValue] = useState(getPrefilledAddOnValue(title, title, prefilled))

	const isMultipleType = options?.length > 0

	useEffect(() => {
		if (isAddOnPresent && addOnValue) {
			appendNewAddOn(title, handleNumberInput(addOnValue))
		} else {
			deleteAddOn(title)
		}
	}, [isAddOnPresent, addOnValue])

	return (
		<>
			{isMultipleType ? (
				<>
					<div className="flex-container">
						<Icon name={icon} />
						<Space x={1} />
						<Text style={{ fontSize: '18px', fontWeight: 700 }} color={theme.colors.gray7}>
							{title}
						</Text>
					</div>
					{options.map(el => (
						<SubQuestion
							addOnsList={addOnsList}
							title={el.title}
							subtitle={el.subtitle}
							prefilled={el.prefilled}
							totalSumInsured={totalSumInsured}
							stockValue={stockValue}
							appendNewAddOn={appendNewAddOn}
							deleteAddOn={deleteAddOn}
							addOnPresence={getPrefilledAddOnPresence(el.title)}
							addOnValue={getPrefilledAddOnValue(el.title, title?.split('-')[0], el.prefilled)}
						/>
					))}
				</>
			) : (
				<div className="full-flex-container" style={{ margin: '3rem 0 3.5rem' }}>
					<div className="flex-container">
						<Checkbox
							value={checkboxDisabled ? true : isAddOnPresent}
							onChange={() => setAddOnPresence(prevState => !prevState)}
							disabled={checkboxDisabled}
						/>
						<Space x={1} />
						<Icon name={icon} />
						<Space x={1} />
						<div>
							<Text style={{ fontSize: '18px', fontWeight: 400 }} color={theme.colors.gray7}>
								{title}
							</Text>
							<Text style={{ fontSize: '11px', fontWeight: 400 }} color={theme.colors.gray3}>
								{subtitle}
							</Text>
						</div>
					</div>
					<Input
						placeholder="Enter Sum Insured"
						value={addOnValue}
						disabled={prefilled}
						onChange={e => setAddOnValue(e.target.value)}
						rupee
						autoComma
					/>
				</div>
			)}
		</>
	)
}

const SubQuestion = ({
	title,
	subtitle,
	prefilled,
	appendNewAddOn,
	deleteAddOn,
	addOnPresence,
	addOnValue,
}) => {
	const categoryHeader = title?.split('-')[0]
	const UIforTitle = title?.split('-')[1] // dont need the category title

	const [isSubQuestionPresent, setSubQuestionPresence] = useState(addOnPresence)
	const [subQuestionValue, setSubQuestionValue] = useState(addOnValue)

	useEffect(() => {
		if (isSubQuestionPresent && subQuestionValue) {
			appendNewAddOn(title, handleNumberInput(subQuestionValue))
		} else {
			deleteAddOn(title)
		}
	}, [isSubQuestionPresent, subQuestionValue])

	return (
		<div className="full-flex-container" style={{ margin: '2rem 0 2rem 3.5rem' }}>
			<div className="flex-container">
				<Checkbox
					value={isSubQuestionPresent}
					onChange={() => setSubQuestionPresence(prevState => !prevState)}
				/>
				<Space x={1} />
				<div>
					<Text style={{ fontSize: '18px', fontWeight: 400 }} color={theme.colors.gray7}>
						{UIforTitle}
					</Text>
					<Text style={{ fontSize: '11px', fontWeight: 400 }} color={theme.colors.gray3}>
						{subtitle}
					</Text>
				</div>
			</div>
			<Input
				placeholder="Enter Sum Insured"
				value={subQuestionValue}
				disabled={prefilled}
				onChange={e => setSubQuestionValue(e.target.value)}
				rupee
				autoComma
			/>
		</div>
	)
}
