import { Modal, Button, Text, theme, Icon, Input, Switch, PhoneNoInput, Space } from 'verak-ui'
import { useState, useEffect } from 'react'
import { useFetch } from '../../../api/apihook'
import { validateEmail } from '../../../utilities/validateEmail'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const states = {
	DETAILS: 'DETAILS',
	NOTIFICATION_OPTIONS: 'NOTIFICATION_OPTIONS',
	YOUTUBE_VIDEOS: 'YOUTUBE_VIDEOS',
}

export default function SendNotificationsModal({ show, handleClose }) {
	const [activeState, setActiveState] = useState(states.DETAILS)

	const [youtubeVideos, setYoutubeVideos] = useState([])
	const [ProposerName, setProposerName] = useState('')
	const [WaPhone, setWaPhone] = useState('')
	const [Email, setEmail] = useState('')
	const [language, setLanguage] = useState('ENGLISH')

	const { getYoutubeVideos, sendNotificationWithoutRfq } = useFetch()

	const fetchYoutubeVideos = async () => {
		const result = await getYoutubeVideos()
		setYoutubeVideos(result)
	}

	useEffect(() => {
		if (youtubeVideos?.length === 0 && activeState === states.YOUTUBE_VIDEOS) {
			fetchYoutubeVideos()
		}
	}, [activeState]) // eslint-disable-line

	const emailInvalid = Email.length > 0 && !validateEmail(Email)
	const disableNext = !ProposerName || isNaN(WaPhone) || WaPhone.length !== 10 || emailInvalid

	const isEnglish = language === 'ENGLISH'

	const onLanguageChange = value => {
		setLanguage(value ? 'ENGLISH' : 'HINDI')
	}

	const triggerNotification = async (type, videoId) => {
		const payload = { ProposerName, WaPhone: `+91${WaPhone}`, language, type }
		if (type === 'VIDEO') {
			payload.videoId = videoId
			if (Email) {
				payload.Email = Email
			}
		}
		const result = await sendNotificationWithoutRfq(payload)
		if (result.message === 'ok') {
			const message = type === 'VIDEO' ? 'Youtube video' : 'Agent hiring form'
			toast.success(`${message} sent successfully`)
		}
	}

	const closeModal = () => {
		setActiveState(states.DETAILS)
		setProposerName('')
		setWaPhone('')
		setEmail('')
		setLanguage('ENGLISH')
		handleClose()
	}

	let modalContent
	switch (activeState) {
		case states.DETAILS: {
			modalContent = (
				<div className="d-flex flex-column w-100">
					<Text className="mb-3" color={theme.colors.primary}>
						Enter details of prospective client below:
					</Text>
					<Input
						value={ProposerName}
						onChange={e => setProposerName(e.target.value)}
						className="w-100 mb-3"
						placeholder="Enter"
						label="Client Name"
						compulsory
					/>
					<Text className="mb-2" fontSize="14px" color={theme.colors.gray2} fontWeight="500">
						WhatsApp Number<span style={{ color: theme.colors.red }}>*</span>
					</Text>
					<PhoneNoInput
						placeholder="Enter"
						value={WaPhone}
						className="w-100 mb-3"
						onChange={e => {
							if (e.target.value.length > 10) {
								return
							}
							setWaPhone(e.target.value)
						}}
						maxLength={10}
					/>
					<Input
						value={Email}
						onChange={e => setEmail(e.target.value)}
						className="w-100 mb-2"
						placeholder="Email ID"
						label="Email"
						error={emailInvalid}
						errorLabel={emailInvalid ? 'Please enter a valid email address' : ''}
					/>

					<div className="d-flex w-100 justify-content-center">
						<Button
							className="mt-3"
							primary
							label="Next"
							style={{ height: '48px', width: '300px' }}
							disabled={disableNext}
							onClick={() => setActiveState(states.NOTIFICATION_OPTIONS)}
						/>
					</div>
				</div>
			)
			break
		}
		case states.NOTIFICATION_OPTIONS: {
			modalContent = (
				<div className="d-flex flex-column">
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<Text fontSize="14px" fontWeight="700">
							Notification Language
						</Text>

						<div className="d-flex align-items-center">
							<Text
								className="me-2"
								color={!isEnglish ? theme.colors.secondary : theme.colors.blue}
								style={{ opacity: isEnglish ? 0.45 : 1 }}
							>
								Hindi
							</Text>
							<Switch value={isEnglish} onChange={onLanguageChange} />
							<Text
								className="ms-2"
								color={isEnglish ? theme.colors.secondary : theme.colors.blue}
								style={{ opacity: isEnglish ? 1 : 0.45 }}
							>
								English
							</Text>
						</div>
					</div>

					<div className="d-flex justify-content-between align-items-center mb-3">
						<Text fontSize="14px" fontWeight="700" style={{ marginLeft: '1.5rem' }}>
							Send Youtube Videos
						</Text>
						<Button
							label="Select"
							icon="rightArrow"
							iconAlign="right"
							className="pe-2 ps-3"
							bgColor={theme.colors.secondary}
							textColor={theme.colors.white}
							onClick={() => setActiveState(states.YOUTUBE_VIDEOS)}
						/>
					</div>

					<div className="d-flex justify-content-between align-items-center mb-3">
						<Text fontSize="14px" fontWeight="700" style={{ marginLeft: '1.5rem' }}>
							Send Agent Hiring Form
						</Text>
						<Button
							label="Send"
							className="px-4"
							bgColor={theme.colors.secondary}
							textColor={theme.colors.white}
							onClick={() => triggerNotification('AGENT_HIRING')}
						/>
					</div>
				</div>
			)
			break
		}
		case states.YOUTUBE_VIDEOS: {
			const youtubeVideoList = youtubeVideos.map((item, index) => (
				<div key={index} className="d-flex justify-content-between align-items-center mb-3">
					<Text fontSize="14px" fontWeight="700" style={{ marginLeft: '1.5rem' }}>
						{index + 1}. {item.title}
					</Text>
					<Button
						label="Send"
						bgColor={theme.colors.secondary}
						textColor={theme.colors.white}
						onClick={() => triggerNotification('VIDEO', item.id)}
					/>
				</div>
			))

			modalContent = (
				<div className="d-flex flex-column w-100">
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<div className="d-flex">
							<Icon name="youtube" className={'me-2'} />
							<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
								Youtube Explainer Videos
							</Text>
						</div>

						<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
							Language: {isEnglish ? 'English' : 'Hindi'}
						</Text>
					</div>
					<div className="px-2">{youtubeVideoList}</div>
				</div>
			)
			break
		}
		default:
	}

	const handleBackClick = () => {
		let toSet
		if (activeState === states.YOUTUBE_VIDEOS) toSet = states.NOTIFICATION_OPTIONS
		if (activeState === states.NOTIFICATION_OPTIONS) toSet = states.DETAILS
		setActiveState(toSet)
	}

	return (
		<>
			<Modal
				show={show}
				handleClose={closeModal}
				title={
					<div className="d-flex align-items-center justify-content-between">
						{activeState !== states.DETAILS ? (
							<Icon name="leftArrow" onClick={handleBackClick} style={{ marginRight: '4.5rem' }} />
						) : (
							<Space x={6} />
						)}
						<div className="w-100 d-flex align-items-center justify-content-between">
							<Icon name="sendNotification" className={'ms-5 me-1'} />
							<Text fontSize="14px" color={theme.colors.primary} fontWeight="700">
								Send Notifications
							</Text>
						</div>
					</div>
				}
				footer={<></>}
			>
				{modalContent}
			</Modal>
			<ToastContainer />
		</>
	)
}
