import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext, useEffect } from 'react'

import { useFetch } from '../../../api/apihook'
import { Context } from '../../../context/Provider'

import { headerStyle, compulsoryStyle } from '../../../components/SharedComponents'
import { Icon, Space, Text, theme, Button, Switch, Modal, MainContainer, Divider } from 'verak-ui'

const SumInsuredMethods = () => {
	let history = useHistory()
	let location = useLocation()
	let fetch = useFetch()
	let [state, dispatch] = useContext(Context)

	const isSFSPEdgeCase = location?.state?.isSFSPEdgeCase

	const newValues = ['BasementValue', 'PlinthFoundation', 'BoundaryWalls']

	// used for data/value storage
	const initialState = {
		BuildingValue: 'Reinstatement',
		PlantMachinery: 'Reinstatement',
		FurnitureFixture: 'Reinstatement',
		Electronics: 'Reinstatement',
		OfficeEquipment: 'Reinstatement',
		ValuableContents: 'Reinstatement',
	}

	const prefillData = () => {
		let valuationMethods = state?.quote?.ValuationMethodMap || []
		if (Object.keys(valuationMethods)?.length > 0) {
			return valuationMethods
		} else {
			return initialState
		}
	}

	const [methodsObj, setMethodsObj] = useState(() => prefillData())

	// used for UI purposes
	const methods = [
		{
			title: 'Building / Basement / Plinth & Foundation / Boundary wall',
			icon: 'building',
			attribute: 'BuildingValue',
		},
		{
			title: 'Plant & Machinery',
			icon: 'plantAndMachinery',
			attribute: 'PlantMachinery',
		},
		{
			title: 'Furniture & Fixture',
			icon: 'furniture',
			attribute: 'FurnitureFixture',
		},
		{
			title: 'Electronic Installations',
			icon: 'electronicInstallations',
			attribute: 'Electronics',
		},
		{
			title: 'Office Equipment (including IT systems)',
			icon: 'officeEquipment',
			attribute: 'OfficeEquipment',
		},
		{
			title: 'Contents',
			icon: 'valuables',
			attribute: 'ValuableContents',
			noDivider: true,
		},
	]

	const changeMethod = (attribute, methodVal) => {
		let alteredObj = { ...methodsObj }
		alteredObj[attribute] = methodVal
		setMethodsObj(alteredObj)
	}

	const handleBack = () => {
		if (isSFSPEdgeCase) {
			history.push('/journey/risk-location-selection')
		} else {
			window.history.back()
		}
	}

	const proceed = async () => {
		let finalObj = { ...methodsObj }
		const commonVal = finalObj['BuildingValue']

		// adding the 3 new one's
		newValues.forEach(val => (finalObj[val] = commonVal))

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_VALUATION_METHOD',
				data: {
					ValuationMethodMap: finalObj,
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		if (isSFSPEdgeCase) {
			history.push('/sfsp-edgecase/risk-location-selection')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}

	return (
		<MainContainer
			header="Sum Insured declaration"
			rightOnClick={proceed}
			leftOnClick={handleBack}
			top={1.5}
		>
			<Text color={theme.colors.gray7} style={headerStyle}>
				Choose the methods for disclosing the risk location Sum Insureds
				<span style={compulsoryStyle}>*</span>
			</Text>
			<Space y={1.5} />
			<Text color={theme.colors.primary} style={anchorStyle}>
				Learn more about the difference between reinstatement and market value
			</Text>
			<Space y={2.5} />

			{methods.map(el => (
				<IndividualMethod
					icon={el.icon}
					title={el.title}
					attribute={el.attribute}
					noDivider={el.noDivider}
					changeMethod={changeMethod}
					methodsObj={methodsObj}
				/>
			))}
		</MainContainer>
	)
}

export default SumInsuredMethods

const IndividualMethod = ({ icon, title, attribute, noDivider, changeMethod, methodsObj }) => {
	const [type, setType] = useState(methodsObj[attribute] || 'Reinstatement')
	const [modalState, setModal] = useState(false)

	const handleSwitchChange = () => {
		if (type === 'Reinstatement') {
			// this means he's moving from reinstatement to market value
			setModal(true)
		} else {
			changeMethod(attribute, 'Reinstatement')
			setType('Reinstatement')
		}
	}

	const handleContinue = () => {
		changeMethod(attribute, 'Market')
		setType('Market')
		setModal(false)
	}

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Icon name={icon} />
					<Space x={1} />
					<Text color={theme.colors.gray2} style={itemStyle}>
						{title}
					</Text>
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Text color={theme.colors.gray2}>Market Value</Text>
					<Space x={0.75} />
					<Switch onChange={handleSwitchChange} value={type === 'Reinstatement'} />
					<Space x={0.75} />
					<Text color={theme.colors.gray2}>Reinstatement Value</Text>
				</div>
			</div>
			{noDivider ? null : <Divider top={1} bottom={1} />}

			<Modal title="Notice" footer={<></>} show={modalState} handleClose={() => setModal(false)}>
				<div style={{ textAlign: 'center' }}>
					<StyledWarning>
						<Text
							style={{ fontSize: '18px', lineHeight: '24px', fontWeight: 700 }}
							color={theme.colors.primary}
						>
							Market Value
						</Text>
						<Space x={0.625} />
						<Text style={{ fontWeight: 700 }} color={theme.colors.red}>
							Not recommended
						</Text>
					</StyledWarning>

					<Space y={3} />
					<Icon name="warningTriangleSm" size="100px" style={{ margin: '0 auto' }} />
					<Space y={1} />

					<Text color={theme.colors.red} style={modalWarning}>
						We recommend the client opt for the Reinstatement Value as it will protect them at the
						time of claims by ensuring that depreciation is NOT charged by the insurer.
					</Text>
					<Space y={3} />
					<div className="flex-container">
						<Button label="Continue" onClick={handleContinue} style={{ width: '100%' }} secondary />
						<Space x={0.625} />
						<Button
							label="Use reinstatement value"
							onClick={() => setModal(false)}
							style={{ width: '100%' }}
						/>
					</div>
				</div>
			</Modal>
		</>
	)
}

const StyledWarning = styled.div`
	display: flex;
	align-items: center;
	padding: 0.625rem;
	border-radius: 5px;
	background: #c8d6db;
`

const anchorStyle = {
	textDecorationLine: 'underline',
	fontSize: '18px',
	lineHeight: '23px',
	fontWeight: 700,
	cursor: 'pointer',
}
const itemStyle = {
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: 400,
	maxWidth: '350px',
}
const modalWarning = {
	fontSize: '24px',
	lineHeight: '30px',
	fontWeight: 700,
}
