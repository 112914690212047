import CheatSheet from './CheatSheet'
import { useFetch } from '../../../../../../api/apihook'
import { useState, useEffect } from 'react'
import CaseStudyCard from '../../../../../DraftRFQ/components/CaseStudyCard'
import { NearestCustomerModal } from '../../../../../DraftRFQ/DraftRFQScreen'

export default function SalesAmmo({ selectedState, selectedCity, onClaimsDisplay, customerInfo }) {
	const [caseStudies, setCaseStudies] = useState([])
	const [nearestCustomers, setNearestCustomers] = useState([])
	const [showNearestCustomerPopup, setShowNearestCustomerPopup] = useState(null)

	const { getCaseStudies, getNearestCustomerInfo, sendNearestCustInfo } = useFetch()
	const fetchCaseStudies = async () => {
		if (!selectedCity && !selectedState) return []
		const result = await getCaseStudies(selectedCity, selectedState, false) // disable loader
		setCaseStudies(result)
	}

	useEffect(() => {
		fetchCaseStudies()
		fetchNearestCustomer()
	}, []) // eslint-disable-line

	const fetchNearestCustomer = async () => {
		if (selectedCity) {
			const result = await getNearestCustomerInfo(selectedCity)
			if (result && result.cases) {
				setNearestCustomers(result.cases)
			}
		}
	}

	const openNearestCustomerPopup = value => {
		setShowNearestCustomerPopup(value)
	}

	const triggerSendingCustInfo = async contactNo => {
		const result = await sendNearestCustInfo({
			CompanyName: showNearestCustomerPopup.LegalEntityName,
			CustomerName: showNearestCustomerPopup.CustomerName,
			Address: showNearestCustomerPopup.Address,
			ProposerPhone: contactNo,
			ProposerName: customerInfo?.customerName || '',
		})
		setShowNearestCustomerPopup(null)
	}

	return (
		<div className="d-flex flex-column">
			<div className="d-flex" style={{ minHeight: '200px', maxHeight: '260px' }}>
				<CaseStudyCard
					selectedState={selectedState}
					selectedCity={selectedCity}
					caseStudyList={caseStudies}
					nearestCustomerList={nearestCustomers}
					sendCustomerDetails={openNearestCustomerPopup}
				/>
			</div>

			<CheatSheet onClaimsDisplay={onClaimsDisplay} />
			<NearestCustomerModal
				show={showNearestCustomerPopup}
				phone={customerInfo?.WaPhone?.replace('+91', '')}
				handleClose={() => {
					setShowNearestCustomerPopup(null)
				}}
				onSendDetails={triggerSendingCustInfo}
			/>
		</div>
	)
}
