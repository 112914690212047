const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

export const userReducer = (prevState, action) => {
	switch (action.type) {
		case LOGIN: {
			const userInfo = {
				...prevState,
				authenticated: true,
				...action.data,
			}
			localStorage.setItem('user', JSON.stringify(userInfo.user))
			return userInfo
		}
		case LOGOUT: {
			localStorage.removeItem('user')
			return {
				authenticated: false,
			}
		}
		default:
			return { ...prevState }
	}
}
