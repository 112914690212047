// for testing
import {
	commonWithData,
	basementWithData,
	propertyWithData,
	propertyBasementWithData,
} from './sampleValues'

const propertyWithoutLevels = { ...propertyWithData }
delete propertyWithoutLevels.levels

const basementWithoutLevels = { ...basementWithData }
delete basementWithoutLevels.levels

const common = {
	Type: '',
	SubType: '',
	PrimaryOccupancy: '',
	SecondaryOccupancy: '',
	AddressLine1: 'gjernkjgnergnerjkg',
	AddressLine2: 'grejgerjgn',
	PIN: '420699',
	City: 'Mumbai',
	State: 'Goa',
	ConstructionAge: {
		Low: '5',
		High: '10',
	},
	ThirdPartyUsage: {
		Present: false,
		Details: [],
	},
	Ownership: '',
	CoOwners: [],
	Hypothecation: [],
	TheftRisk: '',
	ShopRisk: {
		ExceedsLimit: '',
		Goods: [],
	},
	Materials: {
		// not strings but empty objects by default except for "Images" which is an empty array
		Roof: '',
		Ceiling: '',
		Walls: '',
		Floors: '',
		Images: '',
	},
	FireRisk: {
		AMC: '',
		Measures: '',
		OtherPreventativeMeasures: '',
		DistFireStation: '',
		DistWaterBody: '',
		RailwayCrossing: '',
		Images: {
			FireFightingEquipments: [],
			SurroundingAreas: [],
		},
	},

	HistoricInfo: {
		InsuredElsewhere: '',
		OtherInsurer: '',
		StartDate: '',
		PolicyPDF: '',
		CoverDenied: '',
		DenialInsurer: '',
		DenialReason: '',
		Claims: [
			{
				Year: '',
				Amount: '',
				Status: '',
				Reasons: [],
				RiskMitigation: '',
			},
		],
	},

	GoodsInTrust: {
		Present: '',
		Property: [
			{
				Name: '',
				Amount: '',
			},
		],
		Stock: [
			{
				Type: '',
				Amount: '',
				AmountOpen: '',
			},
		],
		Ownership: '',
	},

	SumInsured: [
		{
			Name: '',
			Amount: '',
		},
	],

	Stock: {
		Present: '',
		Hypothecation: [
			{
				Name: '',
				IsFin: '',
			},
		],
		Details: [
			{
				Type: '',
				AmountClosed: '',
				AmountOpen: '',
				OpenPer: '',
				ClosedPer: '',
				GoodsHeldInTrust: '',
			},
		],
		ThirdPartyUsage: {
			Present: '',
			Details: [],
		},
	},
}

const property = {
	...common,
	Type: 'PROPERTY',
	// levels: [{ Level: '2' }, { Level: '3' }],
	SubType: 'Whole',
	Level: 4,
	FloorNumber: 7,
	PrimaryOccupancy: {
		Category: 'Manufacturing or other Industrial Property',
		SubCategory: 'Abrasive Manufacturing',
	},
	levels: [
		{
			Level: '2',
			PrimaryOccupancy: {
				Category: 'Manufacturing or other Industrial Property',
				SubCategory: 'Abrasive Manufacturing',
			},
		},
		{
			Level: '3',
			PrimaryOccupancy: {
				Category: 'Storage facility outside of manufacturing unit',
				SubCategory: 'Cold storage premises',
			},
		},
		{
			Level: '4',
			PrimaryOccupancy: {
				Category: 'Dwellings, Office, Shop or Hotel',
				SubCategory: 'Cycle Shed',
			},
		},
	],
	TheftRisk: {
		...common.TheftRisk,
		BoundaryWall: '',
		FactoryType: '',
		BlockPlan: '',
	},
	Products: [],
}

const basement = {
	// ...common,
	...commonWithData,
	Type: 'BASEMENT',
	SubType: 'Whole',
	Level: 4,
	FloorNumber: -7,
	PrimaryOccupancy: {
		Category: 'Manufacturing or other Industrial Property',
		SubCategory: 'Abrasive Manufacturing',
	},
	// levels: [
	// 	{
	// 		Level: '-2',
	// 		PrimaryOccupancy: {
	// 			Category: 'Manufacturing or other Industrial Property',
	// 			SubCategory: 'Abrasive Manufacturing',
	// 		},
	// 	},
	// 	{
	// 		Level: '-3',
	// 		PrimaryOccupancy: {
	// 			Category: 'Storage facility outside of manufacturing unit',
	// 			SubCategory: 'Cold storage premises',
	// 		},
	// 	},
	// 	{
	// 		Level: '-4',
	// 		PrimaryOccupancy: {
	// 			Category: 'Dwellings, Office, Shop or Hotel',
	// 			SubCategory: 'Cycle Shed',
	// 		},
	// 	},
	// ],
	FireRisk: {
		...common.FireRisk,
		HasDeWatering: '',
		DeWateringDesc: '',
	},
}

const propertyBasement = {
	...common,
	property: { ...property },
	basement: { ...basement },
	Type: 'PROPERTYBASEMENT',
}

export const BasementDefault = {
	...commonWithData,
	// ...common,
	// ...basement,
	// ...basementWithData,
	...basementWithoutLevels,
	SubType: 'Whole',
	Level: '-2',
	Materials: {
		Ceiling: { Type: 'Pucca', Description: 'soemthing' },
		Walls: { Type: 'Kutcha', Description: 'hello' },
		Floors: { Type: 'Kutcha', Description: 'huhwfhwjk' },
	},
	TheftRisk: {
		SecurityArrangements: ['CCTV', 'ewjkfjkwf'],
	},
}

export const PropertyDefault = {
	// ...common,
	...commonWithData,
	...propertyWithData,
	// ...property,
	// SubType: 'Whole',
	Level: 4,
	FloorNumber: 7,
	PrimaryOccupancy: {
		Category: 'Manufacturing or other Industrial Property',
		SubCategory: 'Abrasive Manufacturing',
	},
	TheftRisk: {
		SecurityArrangements: ['CCTV', 'ewjkfjkwf'],
		BoundaryWall: true,
		FactoryType: 'Working',
	},
	Products: ['Cold Storage premises', 'Bus Terminus'],
}

export const PropertyBasementDefault = {
	// ...common,
	// ...propertyBasement,
	...commonWithData,
	...propertyBasementWithData,
}

export const locationReducer = (prevState, action) => {
	switch (action.type) {
		case 'SELECT_LOCATION':
			break
		case 'DONE_WITH_LOCATION_CHANGES':
			return null
			break
		case 'INIT_LOCATION':
			const locationType = action.data.locationType

			let defaultState
			if (locationType === 'PROPERTY') {
				defaultState = {}
			} else if (locationType === 'BASEMENT') {
				defaultState = {}
			} else if (locationType === 'PROPERTYBASEMENT') {
				defaultState = {
					property: {},
					basement: {},
				}
			}
			return {
				...prevState,
				...defaultState,
				Type: locationType,
			}
			break

		case 'ADD_TO_LOCATION':
			return {
				...prevState,
				...action.data,
			}
			break

		// dont use this instead use UPDATE_RISK_LOCATION_DETAILS
		case 'ADD_LOCATIONDETAILS_FOR_PROPERTYBASEMENT':
			if (action.propertyType) {
				const levelNo = action.levelNo
				if (action.propertyType == 'BASEMENT') {
					if (levelNo) {
						// find level and update that
						const allLevels = [...prevState.basement.levels]
						const index = allLevels.findIndex(item => {
							return item.Level == levelNo
						})
						if (index > -1) {
							allLevels[index] = {
								...allLevels[index],
								...action.data,
							}
						}
						return {
							...prevState,
							basement: {
								...prevState.basement,
								levels: allLevels,
							},
						}
					} else {
						return {
							...prevState,
							basement: {
								...prevState.basement,
								...action.data,
							},
						}
					}
				} else {
					if (levelNo) {
						// find level and update that
						const allLevels = [...prevState.property.levels]
						const index = allLevels.findIndex(item => {
							return item.Level == levelNo
						})
						if (index > -1) {
							allLevels[index] = {
								...allLevels[index],
								...action.data,
							}
						}
						return {
							...prevState,
							property: {
								...prevState.property,
								levels: allLevels,
							},
						}
					} else {
						return {
							...prevState,
							property: {
								...prevState.property,
								...action.data,
							},
						}
					}
				}
			} else {
				console.log('error : please pass propertyType and levelNo')
				return { ...prevState }
			}
			break

		// dont use this instead use UPDATE_RISK_LOCATION_DETAILS
		case 'Add_TO_LOCATION_LEVELNO':
			const levelNo = action.levelNo
			const allLevels = [...prevState.levels]
			const index = allLevels.findIndex(item => {
				return item.Level == levelNo
			})
			if (index > -1) {
				allLevels[index] = {
					...allLevels[index],
					...action.data,
				}
			}
			return {
				...prevState,
				levels: allLevels,
			}
			break

		case 'UPDATE_RISK_LOCATION_DETAILS':
			const levelIndex = action.levelIndex
			const propertType = action.propertyType // basement or property
			const dataToUpdate = action.data
			const isBasement = prevState.Type === 'BASEMENT'
			const isProperty = prevState.Type === 'PROPERTY'
			const isPropertyBasement = prevState.Type === 'PROPERTYBASEMENT'
			const data = action.data

			const globalAddress = action.globalAddress
			const addressField = action.addressField

			if (isBasement || isProperty) {
				// address update
				if (addressField) {
					if (globalAddress) {
						return {
							...prevState,
							...data,
						}
					} else {
						const updatedLevels = [...prevState.levels]
						updatedLevels[levelIndex] = { ...updatedLevels[levelIndex], ...data }
						return {
							...prevState,
							levels: updatedLevels,
						}
					}
				}
				// normal fields
				else {
					if (levelIndex > -1) {
						// multiple levels - update data into level Index
						const updatedLevels = [...prevState.levels]
						updatedLevels[levelIndex] = { ...updatedLevels[levelIndex], ...data }
						return {
							...prevState,
							levels: updatedLevels,
						}
					} else {
						return {
							...prevState,
							...data,
						}
					}
				}
			} else {
				if (addressField) {
					if (globalAddress) {
						return {
							...prevState,
							property: { ...prevState.property, ...data },
							basement: { ...prevState.basement, ...data },
						}
					} else {
						const updatedLevels = [...prevState?.[propertType]?.levels]
						updatedLevels[levelIndex] = { ...updatedLevels?.[levelIndex], ...data }
						return {
							...prevState,
							[propertType]: {
								...prevState[propertType],
								levels: updatedLevels,
							},
						}
					}
				} else {
					if (levelIndex > -1) {
						if (propertType == 'basement') {
							const updatedLevels = [...prevState?.basement?.levels]
							updatedLevels[levelIndex] = { ...updatedLevels?.[levelIndex], ...data }
							return {
								...prevState,
								basement: {
									...prevState?.basement,
									levels: updatedLevels,
								},
							}
						} else {
							const updatedLevels = [...prevState?.property?.levels]
							updatedLevels[levelIndex] = { ...updatedLevels?.[levelIndex], ...data }
							return {
								...prevState,
								property: {
									...prevState.property,
									levels: updatedLevels,
								},
							}
						}
					} else {
						if (propertType == 'basement') {
							return {
								...prevState,
								basement: {
									...prevState.basement,
									...data,
								},
							}
						} else {
							return {
								...prevState,
								property: {
									...prevState.property,
									...data,
								},
							}
						}
					}
				}
			}

			break

		case 'SET_SUBTYPE':
			break
		case 'SET_LOCATION_PRIMARY_USAGE':
			{
				const primaryUsage = action.data.primaryUsage
				const category = action.data.category
			}
			break
		case 'SET_LOCATION_SECONDARY_USAGE':
			{
				const secondaryUsage = action.data.secondaryUsage
				const category = action.data.category
				return {}
			}
			break

		case 'SET_BASEMENT_USAGE_AND_SUBTYPE':
			// console.log('received ', action.data)
			if (action.data.levels.length == 1) {
				// delete prev levels, but retain Old ID
				const { ID, ...levelData } = action.data.levels[0]
				let toReturn = {
					...prevState,
					Type: 'BASEMENT',
					SubType: action.data.SubType,
					...levelData,
					ID: prevState.ID ? prevState.ID : ID,
				}
				delete toReturn.levels
				return toReturn
			} else {
				let toReturn = {
					...prevState,
					Type: 'BASEMENT',
					SubType: action.data.SubType,
					levels: [...action.data.levels],
				}

				// delete old single/whole basement values - Level & PrimaryOccupancy
				delete toReturn.Level
				delete toReturn.PrimaryOccupancy
				return toReturn
			}

			break

		case 'SET_PROPERTY_USAGE_AND_SUBTYPE':
			// console.log('received ', action.data)
			if (action.data.levels.length === 1) {
				// delete prev levels, but retain Old ID
				const { ID, ...levelData } = action.data.levels[0]
				let toReturn = {
					...prevState,
					Type: 'PROPERTY',
					SubType: action.data.SubType,
					...levelData,
					ID: prevState.ID ? prevState.ID : ID,
				}
				delete toReturn.levels
				return toReturn
			} else {
				let toReturn = {
					...prevState,
					Type: 'PROPERTY',
					SubType: action.data.SubType,
					levels: [...action?.data.levels],
				}
				// delete old single/whole property values - Level & PrimaryOccupancy
				delete toReturn.Level
				delete toReturn.PrimaryOccupancy
				return toReturn
			}
			break
		case 'SET_PROPERTYBASEMENT_USAGE_AND_SUBTYPE':
			let basement = {
				...(prevState.basement || {}),
				Type: 'BASEMENT',
				SubType: action.data?.basement?.SubType,
			}

			if (action.data?.basement?.levels?.length === 1) {
				const { ID: bID, ...bLevelData } = action.data.basement.levels[0]
				basement = {
					...basement,
					...bLevelData,
					ID: prevState.basement.ID ? prevState.basement.ID : bID,
				}
				delete basement.levels
			} else {
				basement = {
					...basement,
					levels: [...action?.data?.basement?.levels],
				}

				delete basement.Level
				delete basement.PrimaryOccupancy
			}

			let property = {
				...(prevState.property || {}),
				Type: 'PROPERTY',
				SubType: action.data?.property?.SubType,
			}

			if (action.data?.property?.levels?.length === 1) {
				const { ID: pID, ...pLevelData } = action.data.property.levels[0]
				property = {
					...property,
					...pLevelData,
					ID: prevState.property.ID ? prevState.property.ID : pID,
				}
				delete property.levels
			} else {
				property = {
					...property,
					levels: [...action.data?.property?.levels],
				}
				delete property.Level
				delete property.PrimaryOccupancy
			}

			return {
				...prevState,
				basement: basement,
				property: property,
			}
			break

		case 'SET_LOCATION_SHOP_RISK':
			let shopRiskValues = {
				Goods: [...action.data.Goods],
				ExceedsLimit: action.data.ExceedsLimit,
			}

			if (prevState.Type === 'PROPERTYBASEMENT') {
				return {
					...prevState,
					property: {
						...prevState.property,
						ShopRisk: shopRiskValues,
					},
					basement: {
						...prevState.basement,
						ShopRisk: shopRiskValues,
					},
				}
			}
			return {
				...prevState,
				ShopRisk: shopRiskValues,
			}

		case 'SET_LOCATION_THEFT_RISK':
			let valueToSet

			if (prevState.Type == 'PROPERTY') {
				valueToSet = {
					SecurityArrangements: [...action.data.SecurityArrangements],
					BoundaryWall: action.data.BoundaryWall,
					FactoryType: action.data.FactoryType,
					BlockPlan: action.data.BlockPlan,
				}
			} else if (prevState.Type == 'BASEMENT') {
				valueToSet = {
					SecurityArrangements: [...action.data.SecurityArrangements],
				}
			} else if (prevState.Type == 'PROPERTYBASEMENT') {
				// should invoke generic action

				return
			}

			return {
				...prevState,
				TheftRisk: valueToSet,
			}
			break

		case 'SET_LOCATION_PRODUCTS':
			// save this if type is property or property+basement
			return {
				...prevState,
				Products: [...action.data.Products],
			}
			break

		case 'SET_DEWATERING_INFO':
			// save this if type is property or property+basement
			return {
				...prevState,
				FireRisk: {
					...prevState.FireRisk,
					HasDeWatering: action.data.HasDeWatering,
					DeWateringDesc: action.data.DeWateringDesc,
				},
			}

		case 'SET_FIRE_RISK':
			// save this if type is property or property+basement
			return {
				...prevState,
				FireRisk: {
					...prevState?.FireRisk,
					...action.data.FireRisk,
				},
			}

		case 'SET_MATERIALS_INFO':
			return {
				...prevState,
				Materials: action.data,
			}
			break

		case 'SET_HISTORIC_INFO':
			if (prevState.Type === 'PROPERTYBASEMENT') {
				return {
					...prevState,
					property: {
						...prevState.property,
						HistoricInfo: action.data,
					},
					basement: {
						...prevState.basement,
						HistoricInfo: action.data,
					},
				}
			}
			return {
				...prevState,
				HistoricInfo: action.data,
			}

		case 'SET_GOODS_IN_TRUST_CHOICE':
			const goodsInTrustDefault = {
				Present: true,
				Property: [],
				Stock: [],
				Ownership: [],
			}
			if (prevState.Type === 'PROPERTYBASEMENT') {
				let propertyData = prevState.property || {},
					basementData = prevState.basement || {}
				if (prevState.property?.levels?.length) {
					propertyData = {
						...propertyData,
						levels: propertyData?.levels?.map(el => ({
							...el,
							GoodsInTrust: { ...goodsInTrustDefault, Present: action.data },
						})),
					}
				} else {
					propertyData = {
						...propertyData,
						GoodsInTrust: { ...goodsInTrustDefault, Present: action.data },
					}
				}
				if (prevState.basement?.levels?.length) {
					basementData = {
						...basementData,
						levels: basementData?.levels?.map(el => ({
							...el,
							GoodsInTrust: { ...goodsInTrustDefault, Present: action.data },
						})),
					}
				} else {
					basementData = {
						...basementData,
						GoodsInTrust: { ...goodsInTrustDefault, Present: action.data },
					}
				}
				return {
					...prevState,
					property: { ...propertyData },
					basement: { ...basementData },
				}
			} else if (prevState?.levels?.length) {
				return {
					...prevState,
					levels: prevState.levels.map(el => ({
						...el,
						GoodsInTrust: { ...goodsInTrustDefault, Present: action.data },
					})),
				}
			} else {
				const currentGoodsInTrust = prevState.GoodsInTrust || {}
				return {
					...prevState,
					GoodsInTrust: {
						...currentGoodsInTrust,
						Present: action.data,
					},
				}
			}

		case 'SET_GOODS_IN_TRUST_DATA':
			const currGoodsInTrust = prevState.GoodsInTrust || {}
			return {
				...prevState,
				GoodsInTrust: {
					...currGoodsInTrust,
					...action.data,
				},
			}

		case 'SET_SUM_INSURED_DATA':
			const currSumInsured = prevState.SumInsured || []
			return {
				...prevState,
				SumInsured: {
					...currSumInsured,
					...action.data,
				},
			}

		case 'SET_EDGE_CASE_ANSWERED':
			return {
				...prevState,
				Answered: action.data.Answered,
			}
			break

		default:
			return prevState
	}
}
