import { useHistory } from 'react-router-dom'
import { MainContainer, Divider, theme, Text, Space } from 'verak-ui'

import {
	infoContainerStyle,
	footerStyle,
	getDurationStringFromCode,
	convertAmountToWords,
} from '../RiskLocationReview/reviewScreenUtils'

import {
	BreadCrumbComponent,
	breadcrumbStyles,
	generateSumInsuredBreakdown,
	goodsInTrustTotalSIForSingleRiskLoc,
	goodsInTrustTotalSIForAllRiskLocations,
	mainContainerStyles,
	MainStickyContainer,
	totalSumInsuredForAllRiskLocations,
	totalSumInsuredForSingleRiskLocation,
} from './finalReviewUtils'
import {
	getPolicyTypeFullForm,
	getAddOnName,
	SummaryContainer,
	SummaryInfo,
	SummaryInfoSection,
	SummaryInfoSubItem,
	SummaryInfoTitle,
} from './SummaryUtils'
import {
	getStockDisclosureMethodString,
	getReportingStatement,
} from '../RiskLocationReview/reviewScreenUtils'

import { Col, Row } from 'react-bootstrap'
import { useContextApi } from '../../context/Provider'
import { parseFormattedDate } from '../../utilities/getCorrectDateFormat'
import { calculateDateFromCode } from '../../utilities/coverageDateUtils'
import { calculateTotalStockVal } from '../../utilities/calculateTotalStockVal'
import useScrollDirectionDetect from './useScrollDirectionDetect'
import { calculateStockForOneRiskLocation } from '../../utilities/calculateStockForOneRiskLocation'
import { useEffect } from 'react'
import { calculateNumStock } from '../../utilities/calculateNumStock'

const Summary = ({ onBack, onProceed }) => {
	const { scrollUp } = useScrollDirectionDetect()

	const history = useHistory()
	const [state, dispatch] = useContextApi()

	const { PolicyName, RiskLocations, Cover, DeclarationQ, StockDisclosureMethod } =
		state?.quote || {}

	const addOns = state?.quote?.Addons || []
	const isSFSP = state?.quote?.PolicyName === 'SFSP'

	// the numStock is updated here because the edgecase2 doesnt update it if the older numStock is >=2
	const numStock = calculateNumStock(state?.quote?.RiskLocations || [])

	useEffect(() => {
		dispatch({
			type: 'SET_LOCATION_WITH_STOCK',
			data: {
				NumStock: numStock,
			},
		})
	}, [])

	const displayAddOns = () => {
		const convertAddOnsDataFormat = () => {
			// basically used to convert { Name: 'Header1-Subheader1', Name: 'Header1-Subheader2' }
			// into: { Name: 'Header1', options: [{ Name: 'Subheader1' }, { Name: 'Subheader2' }] }

			let newAddOns = []
			for (const addOn in addOns) {
				let addOnItem = addOns[addOn]
				if (addOnItem?.Name?.includes('-')) {
					const categoryHeader = addOnItem.Name.split('-')[0]
					const subHeader = addOnItem.Name.split('-')[1]

					const prevExistingData = newAddOns?.find(el => el.Name === categoryHeader)
					if (prevExistingData !== undefined) {
						// old entry
						prevExistingData.options.push({ Name: subHeader, SumInsured: addOnItem.SumInsured })
					} else {
						// new entry
						newAddOns.push({
							Name: categoryHeader,
							options: [{ Name: subHeader, SumInsured: addOnItem.SumInsured }],
						})
					}
				} else {
					newAddOns.push(addOnItem)
				}
			}

			return newAddOns
		}

		const generateUI = data => {
			if (data?.options?.length > 0) {
				return (
					<>
						<SummaryInfoSubItem key={data.Name} leftText={`${data.Name}:`} />
						{data?.options.map(el => (
							<SummaryInfoSubItem
								key={el.Name}
								leftText={`${el.Name}:`}
								rightText={convertAmountToWords(`₹ ${el.SumInsured}`)}
								rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
								leftTextStyle={{ marginLeft: '2rem' }}
							/>
						))}
						<Divider top={1} bottom={1} />
					</>
				)
			} else {
				return (
					<SummaryInfoSubItem
						key={data.Name}
						leftText={`${data.Name}:`}
						rightText={convertAmountToWords(`₹ ${data.SumInsured}`)}
						rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
						divider
					/>
				)
			}
		}

		let newAddOns = convertAddOnsDataFormat()
		return newAddOns.map(el => generateUI(el))
	}

	// handles add-ons for Fire All Risk policy
	let policySubType = getPolicyTypeFullForm(PolicyName)
	let typeOfPolicyText = <SummaryInfo>{policySubType || '-'}</SummaryInfo>

	const PolicyAddons = state?.quote?.PolicyAddons
	if (Object.keys(PolicyAddons).length > 0) {
		let addons = Object.keys(PolicyAddons).map(addon => (
			<div key={addon}>
				<Space y={0.5} />
				<Text style={{ fontSize: '18px' }}>- {getAddOnName(addon)}</Text>
			</div>
		))

		typeOfPolicyText = (
			<>
				<SummaryInfo>Vyapar Suraksha</SummaryInfo>
				<Space y={0.5} />
				<Text style={{ fontSize: '18px' }}>- {policySubType}</Text>
				{addons}
			</>
		)
	}

	// Money Insurance questions
	const cicData = state?.quote?.CIC
	const miAddOns = Object.keys(PolicyAddons).filter(x => x !== 'BURGLARY_THEFT')
	const cicQues = PolicyAddons?.CIC?.Present ? (
		<>
			<SummaryInfo>Cash in Counter:</SummaryInfo>
			<Space y={0.5} />
			<SummaryInfoSubItem
				leftText="Amount"
				rightText={convertAmountToWords(`₹ ${cicData?.Amount}`)}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Is the register fully locked/secured?"
				rightText={cicData?.IsCounterLocked ? 'Yes' : 'No'}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Is a CCTV present at the location?"
				rightText={cicData?.IsCCTVPresent ? 'Yes' : 'No'}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
				divider
			/>
		</>
	) : null
	const cisData = state?.quote?.CIS
	const cisQues = PolicyAddons?.CIS?.Present ? (
		<>
			<SummaryInfo>Cash in Safe:</SummaryInfo>
			<Space y={0.5} />
			<SummaryInfoSubItem
				leftText="Amount"
				rightText={convertAmountToWords(`₹ ${cisData?.Amount}`)}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Is the safe fully locked/secured?"
				rightText={cisData?.IsSafeLocked ? 'Yes' : 'No'}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Make / Brand / Type of safe"
				rightText={cisData?.SafeDetails}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Is a CCTV present at the location?"
				rightText={cisData?.IsCCTVPresent ? 'Yes' : 'No'}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
				divider
			/>
		</>
	) : null
	const citData = state?.quote?.CIT
	const citQues = PolicyAddons?.CIT?.Present ? (
		<>
			<SummaryInfo>Cash in Transit:</SummaryInfo>
			<Space y={0.5} />
			<SummaryInfoSubItem
				leftText="Distance of the transit"
				rightText={`${citData?.DistTransit} kms`}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Total amount transferred in a year"
				rightText={convertAmountToWords(`₹ ${citData?.Amount}`)}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Estimated amount per transit"
				rightText={convertAmountToWords(`₹ ${citData?.EstAmountPerTransit}`)}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Carrying from"
				rightText={citData?.Source}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Carrying to"
				rightText={citData?.Destination}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Vehicle used to transfer cash"
				rightText={citData?.CarriageMode}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
			/>
			<SummaryInfoSubItem
				leftText="Who is carrying the cash?"
				rightText={citData?.CarriedBy}
				rightTextStyle={{ color: '#02475E', fontWeight: 700 }}
				divider
			/>
		</>
	) : null
	let miAddOnsUI = (
		<>
			{cicQues}
			{cisQues}
			{citQues}
		</>
	)

	const noOfRiskLocations = RiskLocations?.length || 0

	const coverDuration = getDurationStringFromCode(Cover?.Duration)

	let StartDate = parseFormattedDate(Cover?.StartDate)
	let EndDate = calculateDateFromCode(Cover?.Duration, StartDate)

	StartDate = `${StartDate.getDate()}-${StartDate.getMonth() + 1}-${StartDate.getFullYear()}`
	EndDate = `${EndDate.getDate()}-${EndDate.getMonth() + 1}-${EndDate.getFullYear()}`

	// stock valuation method
	// used for floater declaration
	const stockDisclosureMethod = getStockDisclosureMethodString(StockDisclosureMethod)
	const stockReportingType = getReportingStatement(DeclarationQ?.ReportingMethod)

	let displayStockValue = null
	const isFloaterOrFloaterDeclaration =
		StockDisclosureMethod === 'FLOATER' || StockDisclosureMethod === 'FLOATER_DECLARATION'

	const floaterDeclTotalStockVal = DeclarationQ?.MaxValueAllLoc || ''
	let floaterDeclTotalStockAmountString = ''
	if (floaterDeclTotalStockVal) {
		floaterDeclTotalStockAmountString = convertAmountToWords(`₹ ${floaterDeclTotalStockVal}`)
	}

	const floaterDeclMaxStockVal = DeclarationQ?.MaxValueOneLoc || ''
	let floaterDeclMaxStockAmountString = ''
	if (floaterDeclMaxStockVal) {
		floaterDeclMaxStockAmountString = convertAmountToWords(`₹ ${floaterDeclMaxStockVal}`)
	}

	if (isFloaterOrFloaterDeclaration) {
		displayStockValue = (
			<>
				<Col className="mt-4">
					<SummaryInfoTitle title="Total value of stock" />
					<SummaryInfo>{floaterDeclTotalStockAmountString || '-'}</SummaryInfo>
				</Col>
				<Col className="mt-4">
					<SummaryInfoTitle title="Maximum value of stock at any one location" />
					<SummaryInfo>{floaterDeclMaxStockAmountString || '-'}</SummaryInfo>
				</Col>
			</>
		)
	}

	// sum insured breakdown
	const sumInsuredBreakdown = generateSumInsuredBreakdown(RiskLocations || [])
	const stockValue = calculateTotalStockVal(state?.quote)
	const totalGoodsInTrust = goodsInTrustTotalSIForAllRiskLocations(RiskLocations || [])

	let totalSumInsuredAmount = ''
	const totalSumInsured =
		totalSumInsuredForAllRiskLocations(sumInsuredBreakdown) +
		(stockValue || 0) +
		(totalGoodsInTrust || 0)

	if (totalSumInsured) {
		totalSumInsuredAmount = convertAmountToWords(`₹ ${totalSumInsured}`)
	}

	let riskLocationWiseSI = []

	RiskLocations?.forEach((riskLocation, key) => {
		let totalSIAmount = '-'
		const totalSI = totalSumInsuredForSingleRiskLocation(riskLocation) || 0
		const totalStock =
			calculateStockForOneRiskLocation(riskLocation, state?.quote?.StockDisclosureMethod) || 0
		const totalGoodsInTrust = goodsInTrustTotalSIForSingleRiskLoc(riskLocation) || 0

		if (totalSI || totalStock || totalGoodsInTrust) {
			totalSIAmount = convertAmountToWords(`₹ ${totalSI + totalStock + totalGoodsInTrust}`)
		}

		let pinCode = riskLocation?.PIN || ''
		if (riskLocation?.Type === 'PROPERTYBASEMENT') {
			pinCode = riskLocation?.property?.PIN || ''
		}

		const riskLocationTitle = `Risk Location (${key + 1}) - Pin Code (${pinCode})`
		const RLData = {
			totalSIAmount,
			title: riskLocationTitle,
		}

		riskLocationWiseSI.push(RLData)
	})

	if (isFloaterOrFloaterDeclaration) {
		riskLocationWiseSI.push({
			totalSIAmount: floaterDeclTotalStockAmountString,
			title: 'Stock',
		})
	}

	const displayRiskLocationWiseSIData = riskLocationWiseSI.map((riskLocation, idx) => {
		const { totalSIAmount, title } = riskLocation

		return (
			<SummaryInfoSubItem
				key={`${title}-${totalSIAmount}`}
				leftText={title}
				rightText={totalSIAmount}
			/>
		)
	})

	let nextScreen = () => {
		history.push('/final-review')
	}
	if (typeof onProceed === 'function') {
		nextScreen = onProceed
	}

	let prevScreen = () => {
		history.push('/journey/risk-location-selection')
	}
	if (typeof onBack === 'function') {
		prevScreen = onBack
	}

	// init styles
	let breadcrumbContainerStyles = {
		...breadcrumbStyles,
		margin: '0',
		transform: 'translateY(-500px)',
	}
	if (scrollUp) {
		breadcrumbContainerStyles = breadcrumbStyles
	}

	const mainContainerSectionStyles = {
		...mainContainerStyles,
		width: 'min(1100px, 100%)',
		margin: 'auto',
	}

	const footerSectionStyles = {
		...footerStyle,
		margin: 'auto',
		width: 'min(1410px, 100%)',
		borderRadius: '0 0 8px 8px',
	}

	const infoContainerSectionStyle = {
		...infoContainerStyle,
		padding: '1rem',
	}

	return (
		<MainStickyContainer>
			<BreadCrumbComponent
				location="Summary"
				nextLabel="Next"
				nextOnClick={nextScreen}
				containerStyles={breadcrumbContainerStyles}
			/>
			<Text
				type="h5"
				fontWeight="700"
				color={theme.colors.primary}
				style={{ margin: '1rem 1.5rem' }}
			>
				Let’s look at the summary
			</Text>
			<MainContainer
				containerStyles={mainContainerSectionStyles}
				showHeader={false}
				rightLabel="Next"
				rightOnClick={nextScreen}
				leftOnClick={prevScreen}
				infoContainerStyle={infoContainerSectionStyle}
				footerStyle={footerSectionStyles}
				divider={false}
			>
				<SummaryContainer>
					{/* type of policy & no. of risk locations */}
					<Row>
						<Col className="pe-0">
							<SummaryInfoSection>
								<SummaryInfoTitle title="type of policy" icon="policyTypeSm" />
								{typeOfPolicyText}
							</SummaryInfoSection>
						</Col>
						<Col className="ps-0">
							<SummaryInfoSection>
								<SummaryInfoTitle title="No of risk locations" icon="riskLocationSm" />
								<SummaryInfo>{noOfRiskLocations}</SummaryInfo>
							</SummaryInfoSection>
						</Col>
					</Row>

					{/* duration, start & end date */}
					<SummaryInfoSection className="mb-3">
						<Col>
							<SummaryInfoTitle title="Duration of policy" icon="clockSm" />
							<SummaryInfo>{coverDuration || '-'}</SummaryInfo>
						</Col>
						<Col>
							<SummaryInfoTitle title="Start date" icon="startDateSm" />
							<SummaryInfo>
								<div className="d-flex flex-column">
									{StartDate || '-'}
									<Text
										className="mt-1"
										color={theme.colors.gray3}
										fontSize="18px"
										fontWeight="700"
									>
										12:00 AM
									</Text>
								</div>
							</SummaryInfo>
						</Col>
						<Col>
							<SummaryInfoTitle title="End date" icon="endDateSm" />
							<SummaryInfo>
								<div className="d-flex flex-column">
									{EndDate || '-'}
									<Text
										className="mt-1"
										color={theme.colors.gray3}
										fontSize="18px"
										fontWeight="700"
									>
										11:59 PM
									</Text>
								</div>
							</SummaryInfo>
						</Col>
					</SummaryInfoSection>

					{/* Total sum insured section */}
					<SummaryInfoSection className="mb-3">
						<Row className="mb-3">
							<Col>
								<SummaryInfoTitle title="Total Sum Insured" icon="sumInsuredSm" />
								<SummaryInfo>{totalSumInsuredAmount || '-'}</SummaryInfo>
							</Col>
						</Row>
						{displayRiskLocationWiseSIData}
					</SummaryInfoSection>

					{/* stock details section */}
					{stockDisclosureMethod && (
						<SummaryInfoSection className="mb-3">
							<Row className="mb-2">
								<Col>
									<SummaryInfoTitle title="Stock Details" icon="stockDetailsSm" />
									<SummaryInfo>{stockDisclosureMethod}</SummaryInfo>
								</Col>
							</Row>
							<SummaryInfoSubItem leftText={stockReportingType || ''} />
							{displayStockValue}
						</SummaryInfoSection>
					)}

					{/* Add ons opted for */}
					{(isSFSP && addOns?.length > 0) || miAddOns?.length > 0 ? (
						<SummaryInfoSection className="mb-3">
							<Row className="mb-2">
								<Col>
									{/* TODO: change icon - KB */}
									<SummaryInfoTitle title="Add-ons opted for" icon="rewindClock" />
								</Col>
							</Row>
							{displayAddOns()}
							{miAddOnsUI}
						</SummaryInfoSection>
					) : null}
				</SummaryContainer>
				<Divider bottom="0" style={{ width: '100%' }} />
			</MainContainer>
		</MainStickyContainer>
	)
}

export default Summary
