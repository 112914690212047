// reference: https://stackoverflow.com/a/62497293

import { useState, useEffect, useCallback } from 'react'

const useScrollDirectionDetect = (offset = 100) => {
	const [y, setY] = useState()
	const [scrollDir, setScrollDir] = useState('up')

	const scrollUp = scrollDir === 'up'
	const scrollDown = scrollDir === 'down'

	const checkScrollDirection = useCallback(
		e => {
			const window = e.currentTarget
			if (y > window.scrollY && y > offset) {
				setScrollDir('up')
			} else if (y < window.scrollY) {
				setScrollDir('down')
			}
			setY(window.scrollY)
		},
		[y, offset]
	)

	useEffect(() => {
		setY(window.scrollY)
		window.addEventListener('scroll', checkScrollDirection)

		return () => {
			window.removeEventListener('scroll', checkScrollDirection)
		}
	}, [checkScrollDirection])
	return {
		scrollUp,
		scrollDown,
	}
}

export default useScrollDirectionDetect
