import { useContext, useState } from 'react'
import { Context } from '../../context/Provider'
import { useHistory } from 'react-router'

import { theme, Tabbar, MainContainer } from 'verak-ui'

import {
	// utils and components
	getNumString,
	infoContainerStyle,
	footerStyle,
} from './reviewScreenUtils'

import { useFetch } from '../../api/apihook'
import ReviewComponent from '../FinalReview/ReviewComponent'
import {
	mainContainerHeaderNonStickyStyles,
	mainContainerHeaderStyles,
	mainContainerHeaderTextStyle,
	mainContainerStyles,
	MainStickyContainer,
	reviewComponentStyles,
	TabWrapper,
} from '../FinalReview/finalReviewUtils'
import useScrollDirectionDetect from '../FinalReview/useScrollDirectionDetect'
const getSingleTabObject = (level, type, commonData, levelIndex) => {
	let currentLocData = commonData
	if (commonData.Type === 'PROPERTYBASEMENT') {
		let currentType = type?.toLowerCase()
		if (currentType) {
			currentLocData = commonData[currentType] || {}
		}
	}
	const getTitle = SubType => {
		if (SubType === 'Whole') {
			if (type === 'PROPERTY') {
				return `Whole building`
			}
			return `Whole ${type?.toLowerCase()}`
		} else {
			if (type === 'BASEMENT') {
				return `Level ${level}`
			} else {
				return `Floor ${getNumString(level)}`
			}
		}
	}

	let floorString = ''
	if (currentLocData?.SubType?.toUpperCase() === 'WHOLE') {
		let noOfLevels = Math.abs(level || 0)
		let levelsSuffix = 'levels'
		if (noOfLevels === 1) {
			levelsSuffix = 'level'
		}
		let propertyType = type?.toLowerCase()
		if (propertyType === 'property') {
			propertyType = 'building'
		}
		floorString = `Whole ${propertyType} (${noOfLevels} ${levelsSuffix})`
	}

	return {
		id: type === 'BASEMENT' ? `level${level}` : `floor${level}`,
		title: getTitle(currentLocData?.SubType),
		disabled: false,
		content: (
			<ReviewComponent
				style={reviewComponentStyles}
				data={commonData}
				floorOrLevel={level}
				floorString={floorString}
				returnPath="/journey/risk-location-selection"
				propertyType={type?.toLowerCase()}
				levelIndex={levelIndex}
			/>
		),
	}
}

export default function RiskLocationReview() {
	const { scrollUp } = useScrollDirectionDetect()
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const history = useHistory()
	// common data
	const commonData = {
		...state.location,
		Cover: state?.quote?.Cover,
		StockDisclosureMethod: state?.quote?.StockDisclosureMethod,
		DeclarationQ: state?.quote?.DeclarationQ,
	}

	const getTabMap = (arr, type) => {
		const tabs = []
		arr.forEach((lvl, levelIndex) => {
			tabs.push(getSingleTabObject(lvl?.Level, type, commonData, levelIndex))
		})
		return tabs
	}

	let tabList = []

	const propertyType = state?.location?.Type

	if (propertyType === 'PROPERTYBASEMENT') {
		// PROPERTY MULTIPLE LEVELS
		if (state?.location?.property?.levels?.length > 0) {
			tabList = getTabMap(state?.location?.property?.levels, 'PROPERTY')
		} else {
			// SINGLE or WHOLE PROPERTY
			tabList = [
				getSingleTabObject(
					state?.location?.property?.Level?.toString(),
					'PROPERTY',
					commonData,
					-1
				),
			]
		}

		if (state?.location?.basement?.levels?.length > 0) {
			tabList = [...tabList, ...getTabMap(state?.location?.basement?.levels, 'BASEMENT')]
		} else {
			// SINGLE or WHOLE BASEMENT
			tabList = [
				...tabList,
				getSingleTabObject(
					state?.location?.basement?.Level?.toString(),
					'BASEMENT',
					commonData,
					-1
				),
			]
		}
	} else {
		// MULTIPLE PROPERTY OR BASEMENT
		if (state.location?.levels?.length > 0) {
			tabList = getTabMap(state.location?.levels, propertyType)
		} else {
			// SINGLE or WHOLE PROPERTY or BASEMENT
			tabList = [
				getSingleTabObject(state?.location?.Level?.toString(), propertyType, commonData, -1),
			]
		}
	}

	const [activeTab, setActiveTab] = useState(() => {
		return tabList[0]?.id
	})

	const onProceed = async () => {
		// switch tab
		dispatch({
			type: 'DONE_WITH_LOCATION_CHANGES',
		})
		await fetch.saveQuote()
		history.push('/journey/risk-location-selection')
	}

	let contentPreview

	if (tabList.length > 1) {
		contentPreview = (
			<TabWrapper scrollUp={scrollUp} top="116px">
				<Tabbar
					activeTabId={activeTab}
					tabList={tabList}
					flat
					tabId="RoundedSecondary-Tab"
					onChange={selected => setActiveTab(selected)}
				/>
			</TabWrapper>
		)
	} else {
		contentPreview = tabList[0].content
	}

	// init styles
	let mainContainerHeaderSectionStyles = {
		...mainContainerHeaderStyles,
		transform: 'translateY(-500px)',
	}
	if (scrollUp) {
		mainContainerHeaderSectionStyles = { ...mainContainerHeaderStyles, top: '60px' }
	}

	const footerSectionStyles = {
		...footerStyle,
		margin: 'auto',
		width: 'min(1070px, 100%)',
		borderRadius: '8px',
	}

	return (
		<MainStickyContainer>
			<MainContainer
				containerStyles={mainContainerStyles}
				headerBg={theme.colors.secondary2}
				headerStyle={mainContainerHeaderSectionStyles}
				headerTextStyle={{ ...mainContainerHeaderTextStyle, color: '#02475E' }}
				header={`Review Risk ${state?.location?.label || 'Location'} details`}
				rightLabel="Next"
				rightOnClick={onProceed}
				infoContainerStyle={infoContainerStyle}
				footerStyle={footerSectionStyles}
				footerContainerStyle={{ padding: '0 1rem' }}
				divider={false}
			>
				{contentPreview}
			</MainContainer>
		</MainStickyContainer>
	)
}
