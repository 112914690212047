import styled from 'styled-components'
import { theme, Text, Icon, Tooltip } from 'verak-ui'
import { useState } from 'react'
import NoCaseStudiesImage from '../../../assets/icons/noCaseStudies.svg'

const Container = styled.div`
	padding: 8px 10px;
	background: transparent;
`

// english to hindi translation
const translations = {
	fire: 'आग 🔥',
	theft: 'चोरी 🚨',
	burglary: 'चोरी 🚨',
	riot: 'दंगा 💣',
	dacoity: 'डकैती 🔫',
	flood: 'बाढ़ 🌊',
	earthquake: 'भूकंप 🌎',
}

const NoCaseStudies = (
	<div className="d-flex align-items-center flex-column">
		<img src={NoCaseStudiesImage} alt="no cae studies" />
		<Text color="#DD5853" fontSize="14px" fontWeight="500">
			No case studies available
		</Text>
		<Text color="#4C4C4C" fontSize="13px" fontWeight="500">
			Try using other tabs for convincing the customer
		</Text>
	</div>
)

const CaseStudyCard = ({ selectedCity, selectedState, caseStudies = [] }) => {
	const [activeCaseStudy, setActiveCaseStudy] = useState(0)

	const showPrevCaseStudy = () =>
		activeCaseStudy === 0
			? setActiveCaseStudy(caseStudies?.length - 1)
			: setActiveCaseStudy(activeCaseStudy - 1)

	const showNextCaseStudy = () =>
		activeCaseStudy === caseStudies?.length - 1
			? setActiveCaseStudy(0)
			: setActiveCaseStudy(activeCaseStudy + 1)

	const currentCaseStudy = caseStudies?.[activeCaseStudy] || {}

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	]
	const caseStudyDate = new Date(currentCaseStudy?.date)
	let incidentDate = '-'

	if (caseStudyDate) {
		incidentDate = `${caseStudyDate.getDate()} ${
			months[caseStudyDate.getMonth()]
		} ${caseStudyDate.getFullYear()}`
	}

	let caseStudyAddress = [currentCaseStudy?.area, currentCaseStudy?.city, currentCaseStudy?.state]
		.filter(Boolean)
		.join(', ')
	if (caseStudyAddress?.length > 30) {
		caseStudyAddress = (
			<>
				{caseStudyAddress?.slice(0, 30)}...
				<Tooltip icon="knowMoreSm" className="ms-1" html={caseStudyAddress} />
			</>
		)
	}

	let caseStudyIncident = currentCaseStudy?.incident
	if (caseStudyIncident?.length > 70) {
		caseStudyIncident = (
			<>
				{caseStudyIncident?.slice(0, 70)}...
				<Tooltip icon="knowMoreSm" className="ms-1" html={caseStudyIncident} />
			</>
		)
	}

	let cityDataNotAvailable =
		selectedCity &&
		selectedState &&
		caseStudies.length > 0 &&
		!caseStudies.some(caseStudy => caseStudy?.city === selectedCity)

	const casualtyCount = currentCaseStudy?.casualties ? `${currentCaseStudy?.casualties} dead` : ''
	const incidentLoss = currentCaseStudy?.loss?.length > 2 ? `₹ ${currentCaseStudy?.loss}` : ''

	let caseStudyContent
	if (caseStudies?.length === 0) {
		caseStudyContent = NoCaseStudies
	} else {
		caseStudyContent = (
			<>
				<div className="d-flex mb-1">
					<CaseStudyRow width="70%" className="d-flex flex-column">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Where?
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{caseStudyAddress}
						</Text>
					</CaseStudyRow>
					<CaseStudyRow width="30%" className="ms-1 d-flex flex-column">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Date
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{incidentDate}
						</Text>
					</CaseStudyRow>
				</div>
				<div className="d-flex">
					<CaseStudyRow width="70%" className="d-flex flex-column">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							What happened?
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{caseStudyIncident}
						</Text>
					</CaseStudyRow>
					<CaseStudyRow width="30%" className="ms-1 d-flex flex-column">
						<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
							Loss
						</Text>
						<Text color={theme.colors.black} fontSize="14px" fontWeight="400">
							{incidentLoss} {casualtyCount}
						</Text>
					</CaseStudyRow>
				</div>
			</>
		)
	}

	const onNextClick = () => {
		if (caseStudies.length > 0) {
			showNextCaseStudy()
		}
	}
	const onPrevClick = () => {
		if (caseStudies.length > 0) {
			showPrevCaseStudy()
		}
	}

	const caseStudySwitcher = caseStudies?.length > 1 && (
		<>
			<Icon
				onClick={onPrevClick}
				name="leftArrow"
				className={'me-1'}
				style={{ cursor: 'pointer' }}
			/>
			<Icon
				onClick={onNextClick}
				name="rightArrow"
				color={theme.colors.gray}
				style={{ cursor: 'pointer' }}
			/>
		</>
	)

	return (
		<Container className="h-100 flex-fill d-flex flex-column">
			<div className="h-100 d-flex flex-column justify-content-between">
				{caseStudyContent}
				<div className="d-flex align-items-center justify-content-between">
					{cityDataNotAvailable ? (
						<ErrorBanner className="d-flex align-items-center">
							<Icon color="#AC2519" name="warningSmall" size="16px" className="me-1" />
							<Text color="#AC2519" fontSize="12px" fontWeight="500">
								No City data available
							</Text>
						</ErrorBanner>
					) : (
						<div></div>
					)}

					<div className="d-flex align-items-center">
						<CaseStudyBanner>{translations[currentCaseStudy.type]}</CaseStudyBanner>

						{caseStudySwitcher}
					</div>
				</div>
			</div>
		</Container>
	)
}

export default CaseStudyCard

const CaseStudyBanner = styled.div`
	padding: 2px 6px;
	background: #fff6ec;
	border-radius: 4px;
`

const CaseStudyRow = styled.div`
	width: ${props => props.width || '50%'};
`

const ErrorBanner = styled.div`
	background: #f5dbdb;
	padding: 2px 4px;
	border-radius: 4px;
`
