import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'

import { stockMethodCheck } from '../../utilities/stockMethodCheck'
import { MainContainer, Icon, Space, Text, theme, Modal } from 'verak-ui'
import { ModalButton } from './Modals'
import { useFetch } from '../../api/apihook'

const StockSelectEdgeCase1 = () => {
	const [state, dispatch] = useContext(Context)
	const fetch = useFetch()

	const returnPath = state?.sqs?.returnPath || '/property-risk/sum-insured'

	const triggerDispatch = async disclosureMethod => {
		const currentStockDisclosureMethod = state?.quote?.StockDisclosureMethod || ''

		if (currentStockDisclosureMethod !== disclosureMethod) {
			await fetch.trackRfqEvent(
				`'Stock Disclosure Method' Changed from ${currentStockDisclosureMethod} to ${disclosureMethod}`
			)
		}

		if (disclosureMethod === 'DECLARED_VALUE') {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
					data: {
						NumStock: 2,
						StockDisclosureMethod: 'DECLARED_VALUE', // anyways remains the same
					},
				})
				resolve()
			})
			history.replace({
				pathname: returnPath,
				state: {
					propertyType: propertyType,
					levelIndex: levelIndex,
				},
			})
		} else {
			history.push({
				pathname: '/edge-case-1/stock-disclosure-methods',
				state: {
					propertyType: propertyType,
					levelIndex: levelIndex,
					currentLocationId: currentLocationId,
					disclosureMethod: disclosureMethod,
				},
			})
		}
	}

	const location = useLocation()
	const propertyType = location.state?.propertyType
	const levelIndex = location.state?.levelIndex
	const currentLocationId = location.state?.currentLocationId

	const data = {
		StockRailSiding: state?.quote?.StockRailSiding,
		StockInProcess: state?.quote?.StockInProcess,
		Duration: state?.quote?.Cover?.Duration,
	}

	const isDeclarationAllowed = stockMethodCheck(
		data.StockRailSiding,
		data.StockInProcess,
		data.Duration
	)

	const methods = [
		{
			icon: 'floater',
			name: 'Floater',
			disclosureMethod: 'FLOATER',
		},
		{
			icon: 'declarationBasis',
			name: 'Declaration Basis',
			disclosureMethod: 'DECLARATION_BASIS',
			disabled: !isDeclarationAllowed,
		},
		{
			icon: 'floaterDeclaration',
			name: 'Floater Declaration',
			disclosureMethod: 'FLOATER_DECLARATION',
			disabled: !isDeclarationAllowed,
			space: 0.5,
		},
		{
			icon: 'declaredValue',
			name: 'Declared Value',
			disclosureMethod: 'DECLARED_VALUE',
		},
	]
	let history = useHistory()
	return (
		<MainContainer header="Stock details" top={4}>
			<Space y={2.75} />
			<Text type="primary" color={theme.colors.gray7}>
				Please select a disclosure method for Stock value:
			</Text>
			<Space y={1.5} />
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				{methods.map(el => (
					<DisclosureMethod
						triggerDispatch={triggerDispatch}
						icon={el.icon}
						name={el.name}
						disclosureMethod={el.disclosureMethod}
						space={el.space}
						disabled={el.disabled}
						key={el.name}
					/>
				))}
			</div>
			<Space y={1.5} />
			{/* Couldn't modify the alert component to create this */}
			<div className="flex-container">
				<Icon name="infoIconOctagon" color={theme.colors.secondary} />
				<Space x={0.5} />
				{/* Learn more needs to be added */}
				<Text style={{ fontSize: '14px', fontWeight: '700' }} color={theme.colors.secondary}>
					Understand the difference between these declaration methods
				</Text>
			</div>
		</MainContainer>
	)
}

export default StockSelectEdgeCase1

const DisclosureMethod = ({ triggerDispatch, icon, name, disclosureMethod, space, disabled }) => {
	const [modalState, setModalState] = useState(false)
	const [modalContent, setModalContent] = useState(null)

	const proceed = () => {
		if (name !== 'Floater') {
			if (name === 'Declared Value') {
				setModalContent(
					<>
						<Text type="primary" color={theme.colors.primary}>
							Since the method of stock disclosure remains the Declared Value basis - no further
							action is required!
						</Text>
						<ModalButton proceed={() => triggerDispatch(disclosureMethod)} />
					</>
				)
			} else if (name === 'Floater Declaration' || name === 'Declaration Basis') {
				setModalContent(
					<>
						<Text type="primary" color={theme.colors.primary}>
							The client will be required to disclose the Stock Value for each location at the start
							of every Calendar month.
						</Text>
						<Space y={1.5} />
						<Text style={{ fontSize: '18px' }} color={theme.colors.primary}>
							If you are unsure about this - please ask the Sales Manager for help
						</Text>
						<Space y={1.5} />
						<Text style={{ fontSize: '18px' }} color={theme.colors.primary}>
							Insure First Insurance Brokers LLP will provide you reminders to meet this disclosure
							requirement - including the template for submission to insurers & guidance.
						</Text>
						<ModalButton proceed={() => triggerDispatch(disclosureMethod)} />
					</>
				)
			}
			setModalState(true)
		} else {
			triggerDispatch(disclosureMethod)
		}
	}

	return (
		<>
			<StyledButton disabled={disabled} onClick={proceed}>
				<Icon name={icon} />
				<Space y={space || 1.5} />
				<Text style={{ fontSize: '22px', fontWeight: '700' }} color={theme.colors.primary}>
					{name}
				</Text>
			</StyledButton>
			<Modal
				show={modalState}
				handleClose={() => setModalState(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Notice
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				{modalContent}
			</Modal>
		</>
	)
}

const StyledButton = styled.button`
	border: none;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	width: 183.5px;
	height: 210px;
	background: ${theme.colors.secondary3};
	opacity: ${({ disabled }) => (disabled ? '0.5' : 'initial')};
	border-radius: 10px;
	text-align: left;
	padding: 1.5rem;
`
