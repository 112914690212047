import { InfoBox, InfoText, VerticalDivider } from '../../components/contentUtils'

import BusinessValue from '../../../../../assets/training/BusinessValue.svg'
import HeavyLoss from '../../../../../assets/training/HeavyLoss.svg'

export default function Training_CantTrustOnline() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				EMOTIONAL DRAMA DURING CLAIMS
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={BusinessValue}>
						<InfoText>
							<b>Business = Businessman</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>Mehnat barbad na hojaye itne salo ki, insurance helps</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={HeavyLoss}>
						<InfoText>
							<b>Loss is heavy and affects many things</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							Kharcha bohot hota hai, bina business ke (bacho ki fees, loads & ghar ke kharcho par
							asar hoga)
						</li>
					</ul>
				</div>
			</div>
			<InfoText className="text-center my-3" spaced fontWeight="700">
				NUKSAAN HONE PAR HUM CLAIMS KE TIME BEST HELP KARTE HAI
			</InfoText>
		</div>
	)
}
