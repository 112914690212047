import { SectionCard, InfoItem } from './commonStyle'
import { Row, Col } from 'react-bootstrap'
import { Text, theme } from 'verak-ui'
import styled from 'styled-components'

export default function BasicDetailsCard({ displayFieldVisitBanner, data }) {
	const { clientInfo = {}, stateVal = '', city = '' } = data

	return (
		<SectionCard className="mb-3">
			{displayFieldVisitBanner && (
				<FieldVisitBanner className="d-flex w-100 justify-content-center">
					<Text fontSize="12px" color="white" fontWeight="500">
						Customer pass me hi hain, field visit kar sakte hai
					</Text>
				</FieldVisitBanner>
			)}

			<div className="p-3">
				<Text className="mb-2" color={'#6467B0'} fontWeight="700">
					Basic Details
				</Text>

				<Row>
					<Col md="3">
						<InfoItem label="Contact Person" value={clientInfo?.Contact?.Name} />
					</Col>
					<Col md="3">
						<InfoItem label="Organisation" value={clientInfo?.Account?.Name} />
					</Col>
					<Col md="3">
						<InfoItem label="Phone No." value={clientInfo?.Contact?.Phone} />
					</Col>
					<Col md="3">
						<InfoItem label="Pin Code" value={clientInfo?.Lead?.PIN || '-'} />
					</Col>
					<Col md="3">
						<InfoItem label="Address" value={clientInfo?.Account?.Address || '-'} />
					</Col>
					<Col md="3">
						<InfoItem label="State" value={stateVal} />
					</Col>
					<Col md="3">
						<InfoItem label="City" value={city} />
					</Col>
					<Col md="3">
						<InfoItem label="GST Number" value={clientInfo?.Lead?.GSTIN} />
					</Col>
				</Row>
			</div>
		</SectionCard>
	)
}

const FieldVisitBanner = styled.div`
	background: ${theme.colors.primary};
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	padding: 6px 10px;
`
