import { Modal, Space, Text, theme, Button } from 'verak-ui'

import WarningTilted from '../../../../assets/icons/warningTilted.svg'
import { Link } from 'react-router-dom'

const SubPoliciesModal = ({ showModal, closeModal }) => {
	let isFireAllRisk = false

	const title = <span style={{ color: theme.colors.red }}>Notice</span>
	let content =
		'Since this client already has a Fire & Allied Perils policy RFQ with us, this RFQ will now be converted into a  Fire All Risk RFQ package!'
	if (isFireAllRisk) {
		content =
			'Since this client already has a Fire All Risk RFQ package with us, this policy will now be added to the package!'
	}
	return (
		<Modal
			id="check-fire-all-risk"
			title={title}
			footer={<></>}
			show={showModal}
			handleClose={closeModal}
		>
			<>
				<div className="d-flex justify-content-center">
					<img src={WarningTilted} alt="warning" style={{ margin: 'auto 0' }} />
				</div>

				<Space y={1} />
				<Text
					type="h5"
					fontWeight="700"
					color={theme.colors.primary}
					style={{ textAlign: 'center' }}
				>
					{content}
				</Text>
				<Space y={1.5} />
				<Link to="/rfq-status/underwriter-requirements/fire-all-risk">
					<Button
						size="stretch"
						primary
						label="Continue"
						style={{ fontSize: '18px', height: '56px' }}
						onClick={closeModal}
					/>
				</Link>
			</>
		</Modal>
	)
}

export default SubPoliciesModal
