export const commonWithData = {
	Type: '',
	SubType: '',
	// PrimaryOccupancy: '',
	// SecondaryOccupancy: [
	// 	{ Category: 'Abrasive Manufacturing', SubCategory: 'Battery Manufacturing' },
	// ],
	AddressLine1: 'Shanti Niketan',
	AddressLine2: 'Street 1',
	PIN: '433454',
	City: 'Pune',
	State: 'Maharashtra',
	ConstructionAge: {
		Low: '1',
		High: '5',
	},
	ThirdPartyUsage: {
		Present: true,
		Details: [{ Category: 'Abrasive Manufacturing', SubCategory: 'Battery Manufacturing' }],
	},
	Ownership: 'CO-OWNED',
	CoOwners: ['Mr Someone'],
	Hypothecation: [
		{
			Name: 'SBI Bank',
			IsFin: 'BANK',
		},
	],
	TheftRisk: {
		FactoryType: 'Working',
		SecurityArrangements: ['Anti-theft locks', 'Night watchman'],
	},
	Materials: {
		// not strings but empty objects by default except for "Images" which is an empty array
		Roof: { Type: 'Pucca', Description: 'Cement' }, // roof is for property
		Ceiling: { Type: 'Pucca', Description: 'Cement' }, // ceiling is for basement
		Walls: { Type: 'Pucca', Description: 'Cement' },
		Floors: { Type: 'Kutcha', Description: 'No tiles used' },
		Images: [],
	},
	FireRisk: {
		AMC: '',
		Measures: ['Gas Flooding System', 'Foam System', 'Smoke Detectors'],
		OtherPreventativeMeasures: 'Some preventative measure',
		DistFireStation: '3',
		DistWaterBody: '2',
		RailwayCrossing: true,
		Images: {
			FireFightingEquipments: [],
			SurroundingAreas: [],
		},
	},

	HistoricInfo: {
		InsuredElsewhere: 'NA',
		OtherInsurer: '',
		StartDate: '',
		PolicyPDF: '',
		CoverDenied: true,
		DenialInsurer: 'Reliance General',
		DenialReason: 'Incorrect information',
		Claims: [],
		// Claims: [
		// 	{
		// 		Year: '2021',
		// 		Amount: 1000000,
		// 		Status: 'Pending',
		// 		Reasons: ['Earthquake', 'Flood'],
		// 		RiskMitigation: '',
		// 	},
		// 	{
		// 		Year: '2020',
		// 		Amount: 1500000,
		// 		Status: 'Settled',
		// 		Reasons: ['STFI'],
		// 		RiskMitigation: '',
		// 	},
		// ],
	},

	GoodsInTrust: {
		Present: '',
		Property: [
			{
				Name: '',
				Amount: '',
			},
		],
		Stock: [
			{
				Type: '',
				Amount: '',
				AmountOpen: '',
			},
		],
		Ownership: '',
	},

	SumInsured: [
		// for property
		{
			Name: '',
			Amount: '2000000',
			Level: '3',
		},
		{
			Name: '',
			Amount: '1000000',
			Level: '4',
		},
		// for basement
		{
			Name: '',
			Amount: '700000',
			Level: '-2',
		},
		{
			Name: '',
			Amount: '5,30,000',
			Level: '-3',
		},
	],

	Stock: {
		Present: true,
		Hypothecation: [
			{
				Name: '',
				IsFin: '',
			},
		],
		Details: [
			{
				Type: 'Bamboo Stocks',
				Amount: '100000',
				AmountOpen: '200000',
				OpenPer: '',
				ClosedPer: '',
			},
			{
				Type: 'Cardboard',
				Amount: '100000',
				AmountOpen: '200000',
				OpenPer: '',
				ClosedPer: '',
			},
		],
		ThirdPartyUsage: {
			Present: '',
			Details: [],
		},
	},
}

export const basementWithData = {
	// ...common,
	...commonWithData,
	Type: 'BASEMENT',
	SubType: 'Multiple',
	Level: 4,
	FloorNumber: -7,
	PrimaryOccupancy: {
		Category: 'Manufacturing or other Industrial Property',
		SubCategory: 'Abrasive Manufacturing',
	},
	// levels: [
	// 	{
	// 		Level: '-2',
	// 		PrimaryOccupancy: 'Abrasive Manufacturing',
	// 		SecondaryOccupancy: 'Battery Manufacturing',
	// 		ConstructionAge: { High: '5', Low: '1' },
	// 		Ownership: 'Co-Ownership',
	// 		CoOwners: 'Mr Someone',
	// 		ThirdPartyUsage: {
	// 			Present: true,
	// 			Details: 'Something fishy',
	// 		},
	// 		Hypothecation: [
	// 			{
	// 				Name: 'SBI Bank',
	// 				IsFin: 'BANK',
	// 			},
	// 			{
	// 				Name: 'Bank of Baroda',
	// 				IsFin: 'BANK',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		Level: '-3',
	// 		PrimaryOccupancy: 'Abrasive Manufacturing',
	// 		SecondaryOccupancy: 'Battery Manufacturing',
	// 		ConstructionAge: { High: '5', Low: '1' },
	// 		Ownership: 'Sole Ownership',
	// 		CoOwners: '',
	// 		ThirdPartyUsage: {
	// 			Present: false,
	// 			Details: '',
	// 		},
	// 		Hypothecation: [
	// 			{
	// 				Name: 'Axis Bank',
	// 				IsFin: 'BANK',
	// 			},
	// 		],
	// 	},
	// ],
	levels: [
		{
			Level: '-2',
			PrimaryOccupancy: {
				Category: 'Manufacturing or other Industrial Property',
				SubCategory: 'Abrasive Manufacturing',
			},
			SecondaryOccupancy: [
				{
					Category: 'Dwellings, Office, Shop or Hotel',
					SubCategory: 'Cycle Shed',
				},
			],
			ThirdPartyUsage: {
				Present: true,
				Details: [
					{
						Category: 'Dwellings, Office, Shop or Hotel',
						SubCategory: 'Cycle Shed',
					},
				],
			},
			Ownership: 'CO-OWNED',
			CoOwners: ['Mr Someone'],
			Hypothecation: [
				{
					Name: 'Axis Bank',
					IsFin: 'BANK',
				},
			],
		},
		{
			Level: '-3',
			PrimaryOccupancy: {
				Category: 'Storage facility outside of manufacturing unit',
				SubCategory: 'Cold storage premises',
			},
		},
		{
			Level: '-4',
			PrimaryOccupancy: {
				Category: 'Dwellings, Office, Shop or Hotel',
				SubCategory: 'Cycle Shed',
			},
		},
	],
	FireRisk: {
		...commonWithData.FireRisk,
		HasDeWatering: '',
		DeWateringDesc: '',
	},
}

export const propertyWithData = {
	// ...common,
	...commonWithData,
	Type: 'PROPERTY',
	SubType: 'Multiple',
	TheftRisk: {
		...commonWithData.TheftRisk,
		FactoryType: 'Working',
	},
	// levels: [
	// 	{
	// 		Level: '3',
	// 		PrimaryOccupancy: 'Abrasive Manufacturing',
	// 		SecondaryOccupancy: 'Battery Manufacturing',
	// 		ConstructionAge: { High: '5', Low: '1' },
	// 		Ownership: 'Co-Ownership',
	// 		CoOwners: 'Mr Someone',
	// 		ThirdPartyUsage: {
	// 			Present: true,
	// 			Details: 'Something fishy',
	// 		},
	// 		Hypothecation: [
	// 			{
	// 				Name: 'SBI Bank',
	// 				IsFin: 'BANK',
	// 			},
	// 			{
	// 				Name: 'Bank of Baroda',
	// 				IsFin: 'BANK',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		Level: '4',
	// 		PrimaryOccupancy: 'Abrasive Manufacturing',
	// 		SecondaryOccupancy: 'Battery Manufacturing',
	// 		ConstructionAge: { High: '5', Low: '1' },
	// 		Ownership: 'Sole Ownership',
	// 		CoOwners: '',
	// 		ThirdPartyUsage: {
	// 			Present: false,
	// 			Details: '',
	// 		},
	// 		Hypothecation: [
	// 			{
	// 				Name: 'Axis Bank',
	// 				IsFin: 'BANK',
	// 			},
	// 		],
	// 	},
	// ],
	levels: [
		{
			Level: '2',
			SumInsured: [
				{ Name: 'BuildingValue', Amount: '9999' },
				{ Name: 'BoundaryWalls', Amount: '25000' },
				{ Name: 'PlantMachinery', Amount: '636363' },
				{ Name: 'FurnitureFixture', Amount: '15000' },
				{ Name: 'Electronics', Amount: '63633' },
				{ Name: 'OfficeEquipment', Amount: '12000' },
				{ Name: 'ValuableContents', Amount: '56546' },
				{ Name: 'Other', Amount: '9999' },
				{ Name: 'MaxValSingleItem', Amount: '6464' },
			],
			PrimaryOccupancy: {
				Category: 'Manufacturing or other Industrial Property',
				SubCategory: 'Abrasive Manufacturing',
			},
			SecondaryOccupancy: [
				{
					Category: 'Dwellings, Office, Shop or Hotel',
					SubCategory: 'Cycle Shed',
				},
			],
			ThirdPartyUsage: {
				Present: true,
				Details: [
					{
						Category: 'Dwellings, Office, Shop or Hotel',
						SubCategory: 'Cycle Shed',
					},
				],
			},
			Ownership: 'RENTED',
			CoOwners: ['Mr Someone'],
			Hypothecation: [
				{
					Name: 'Axis Bank',
					IsFin: 'BANK',
				},
			],
		},
		// {
		// 	Level: '3',
		// 	PrimaryOccupancy: {
		// 		Category: 'Storage facility outside of manufacturing unit',
		// 		SubCategory: 'Cold storage premises',
		// 	},
		// },
		// {
		// 	Level: '4',
		// 	PrimaryOccupancy: {
		// 		Category: 'Dwellings, Office, Shop or Hotel',
		// 		SubCategory: 'Cycle Shed',
		// 	},
		// },
	],
}

const propertyWithoutLevels = { ...propertyWithData }
delete propertyWithoutLevels.levels

const basementWithoutLevels = { ...basementWithData }
delete basementWithoutLevels.levels

export const propertyBasementWithData = {
	// ...common,
	...commonWithData,
	Type: 'PROPERTYBASEMENT',
	TheftRisk: {
		...commonWithData.TheftRisk,
		FactoryType: 'Working',
	},
	Products: ['Vehicle stored in open including tractors'],
	// property: { ...propertyWithoutLevels, Level: '4' }, // for testing single property
	property: { ...propertyWithData },
	// basement: { ...basementWithoutLevels, Level: '-2' },
	basement: { ...basementWithData },
}
