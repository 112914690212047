import rfqCombinedRoutes from './rfqJourneyRoutes'
import sfspCombinedRoutes from './sfspRoutes'
import sqsCombinedRoutes from './sqsRoutes'

const allCombinedRoutes = [
	// FIRE RFQ JOURNEY ROUTES
	...rfqCombinedRoutes,
	// SFSP ROUTES
	...sfspCombinedRoutes,
	// SQS ROUTES
	...sqsCombinedRoutes,
	// OTHER ROUTES
	// {
	//   path: '/test',
	//   component: TestPage,
	//   exact: true,
	// },
]

export default allCombinedRoutes
