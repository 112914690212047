import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import Camera from '../../../../../assets/training/Camera.svg'
import Claims70Plus from '../../../../../assets/training/Claims70Plus.svg'
import DataFromIT from '../../../../../assets/training/DataFromIT.svg'
import NoPhysicalForm from '../../../../../assets/training/NoPhysicalForm.svg'
import NoWetSignature from '../../../../../assets/training/NoWetSignature.svg'
import Sold5000 from '../../../../../assets/training/Sold5000.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function Training_PaperlessProcess() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				STARTED DURING COVID
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={Sold5000}>
						<InfoText>
							<b>Sold 5000+ policies </b>
							this way during this year
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={Claims70Plus}>
						<InfoText>
							<b>{claimsCount} claims </b>done too this year
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				TIE UP WITH THE INSURERS FOR PAPERLESS PROCESS
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={NoPhysicalForm}>
						<InfoText>
							<b>No Physical Form</b> <br /> we will fill details RFQ sent in PDF form to Whatsapp
							no of clients
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={NoWetSignature}>
						<InfoText>
							<b>No Wet Signature</b>, Mobile OTP during purchase as per IRDAI rules
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				SATELLITE IMAGE FROM ISRO & GOVT DEPT DATA
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={DataFromIT}>
						<InfoText>
							<b>Data from IT (tax) dept. & ROC (Company registrar)</b> to verify business
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={Camera}>
						<InfoText>
							We capture <b>images</b> of the facility. Hence <b>NO physical inspection needed</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
		</div>
	)
}
