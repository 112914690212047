import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import {
	MainContainer,
	SearchableSelect,
	FreeTextToggle,
	Button,
	Text,
	theme,
	Icon,
	Input,
} from 'verak-ui'

import { Context } from '../../../context/Provider'
import { useContext, useState, useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { useFetch } from '../../../api/apihook'

const TitleText = styled(Text)`
	font-size: 24px;
	font-weight: 700;
	color: ${theme.colors.gray7};
`

const ColoredRow = styled(Row)`
	background: ${theme.colors.secondary4};
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	min-height: 48px;
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
`

function prefillProducts(globalStore) {
	if (globalStore.property) {
		return globalStore.property.Products || []
	}
	return globalStore.Products || []
}

const rowStyle = { alignItems: 'center', justifyContent: 'space-between', width: '100%' }

const SelectedGoods = ({ value, removeItem }) => {
	return (
		<ColoredRow>
			<div className="d-flex" style={rowStyle}>
				<Text>{value}</Text>
				<Icon style={{ cursor: 'pointer' }} name="remove" onClick={removeItem} />
			</div>
		</ColoredRow>
	)
}

export default function ManufacturingRisk() {
	const fetch = useFetch()
	const history = useHistory()
	const location = useLocation()

	const freeTextInput = useRef(null)
	const [state, dispatch] = useContext(Context)

	const { returnPath } = location?.state || {}

	const prefillData = prefillProducts(state.location || {})

	const [GoodsDesc, setGoodsDesc] = useState(prefillData)

	const [addAnotherActive, setAddAnotherActive] = useState(true)

	const [dropDownValues, setDropDownValues] = useState([])

	const [isFreeTextResponse, setIsFreeTextResponse] = useState(false)

	const fetchDropdownValues = async () => {
		let values = await fetch.getReqWithToken('fire/static/nature_of_stock')
		const dataMap = values.required?.map(({ Description }) => `${Description}`) || []
		setDropDownValues([...dataMap])
	}

	useEffect(() => {
		fetchDropdownValues()
	}, []) // eslint-disable-line

	const proceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					Products: GoodsDesc,
				},
			})

			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else {
			history.push(`/property-risk/materials`)
		}
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/theft-risk')
		}
	}

	const addItem = selected => {
		setGoodsDesc([...GoodsDesc, selected])
		setAddAnotherActive(false)
		setIsFreeTextResponse(false)
	}

	const addFreeTextItem = () => {
		if (!!freeTextInput.current.value) {
			addItem(freeTextInput.current.value)
		}
	}

	const removeItem = removeIdx => {
		setGoodsDesc([...GoodsDesc.filter((el, index) => index !== removeIdx)])
	}

	const dropDownMap = dropDownValues.map(el => {
		return {
			name: el,
			value: el,
		}
	})

	let addAnotherItem
	if (addAnotherActive) {
		if (isFreeTextResponse) {
			addAnotherItem = (
				<div className="d-flex align-items-center" style={{ width: '98%' }}>
					<Input className="w-100" ref={freeTextInput} placeholder="Free text response" />
					<Button
						style={{ height: '48px', width: '150px' }}
						onClick={addFreeTextItem}
						className="mx-2"
						label="Save & Add"
					/>
				</div>
			)
		} else {
			addAnotherItem = (
				<SearchableSelect
					placeholder="From Spontaneous Combustion List - AIFT 2021 PDF"
					customStyle={`width: 98.5%`}
					options={dropDownMap}
					onChange={selected => addItem(selected)}
				/>
			)
		}
	}

	return (
		<MainContainer
			header="Manufacturing risk assessment"
			rightLabel="Save & Continue"
			rightOnClick={proceed}
			leftOnClick={goBack}
			rightDisabled={GoodsDesc.length === 0}
		>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>

			<TitleText className="mb-4">
				Please provide a list of all products manufactured or processed at this risk location:{' '}
				<span style={{ color: '#eb5757' }}>*</span>
			</TitleText>

			<div className=" d-flex flex-column">
				<Text color={theme.colors.gray2} className="mb-3">
					Please select from the drop down menu below:
				</Text>

				<div className="container">
					{GoodsDesc.map((el, idx) => (
						<SelectedGoods value={el} key={idx} removeItem={() => removeItem(idx)} />
					))}
				</div>
			</div>

			{addAnotherItem}

			<div className="d-flex align-items-center justify-content-between" style={{ width: '97%' }}>
				<FreeTextToggle
					onChange={status => setIsFreeTextResponse(status)}
					isFreeTextResponse={isFreeTextResponse}
				/>
			</div>

			<Button
				label="Add another"
				icon="add"
				textColor={theme.colors.primary}
				bgColor={theme.colors.secondary4}
				className="ml-2 mt-3 mb-5"
				iconStyles={{ marginRight: '0.5rem' }}
				onClick={() => setAddAnotherActive(true)}
			/>
		</MainContainer>
	)
}
