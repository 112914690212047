const authorizedAccounts = [
	'amit@verak.in',
	'mahesh@verak.in',
	'kalpesh@verak.in',
	'abhaya@verak.in',
	'rishabh@verak.in',
	'gnanasekar@verak.in',
	'rahul@verak.in',
	'eashan@verak.in',
	'kunal@verak.in',
	'nakshatra@verak.in',
]

const authorizedTeamLeaders = [
	'brijesh@verak.in',
	'agent_3@verak.in',
	'avinash@verak.in',
	'test@verak.in',
	'abhaya@verak.in',
	'rishabh@verak.in',
	'rahul@verak.in',
	'eashan@verak.in',
	'kunal@verak.in',
	'nakshatra@verak.in',
]

const editDetailsAccess = [
	'amit@verak.in',
	'kalpesh@verak.in',
	'mahesh@verak.in',
	'eashan@verak.in',
	'rahul@verak.in',
	'test@verak.in',
]

export { authorizedAccounts, authorizedTeamLeaders, editDetailsAccess }
