import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import SafetyMeasures from '../../../../../assets/training/SafetyMeasures.svg'
import CaseStudies from '../../../../../assets/training/CaseStudies.svg'
import CautionTriangle from '../../../../../assets/training/CautionTriangle.svg'
import HugeLoss from '../../../../../assets/training/HugeLoss.svg'
import Mediclaim from '../../../../../assets/training/Mediclaim.svg'
import NearestCustomer from '../../../../../assets/training/NearestCustomer.svg'

export default function Training_StockValueVerify() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				NEED TO SELL FEAR! ASK FOR PINCODE AND USE
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={NearestCustomer}>
						<InfoText>
							<b>Give details of nearest customer </b>
							(They also understand the need)
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={CaseStudies}>
						<InfoText>
							<b>Use the Case studies </b> (They also thought nothing will happen)
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				GIVE LOGICAL EXPLANATION
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CautionTriangle}>
						<InfoText>
							<b>Abhi tak nahi hua, iska ye matlab nahi hai ki aagey bhi nahi hoga</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>Even with full precaution by self, loss can happen</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={HugeLoss}>
						<InfoText>
							<b>Huge loss can happen due to no fault of owner</b>
						</InfoText>
					</InfoBox>
					<InfoText fontSize="16px">Examples</InfoText>
					<ol>
						<li>Short circuit in nearby store</li>
						<li>Riot, floods can lead to damage anytime</li>
					</ol>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				TAKE EXAMPLE OF MEDICLAIM
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={Mediclaim}>
						<InfoText>
							<b>Healthy people buy Mediclaim</b> <br />
							Helps with hospital bills in case of emergency
						</InfoText>
					</InfoBox>
					<ul>
						<li>Morning walk ka ye matlab ye nahi hai ki mediclaim nahi loge</li>
						<li>Non smoker ho to iska matlab ye nahi ki aap Jeevan Bima nahi loge</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={SafetyMeasures}>
						<InfoText>
							Best Protection is only <b>via safety measures and Insurance</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							Safety measures aap rakhte ho but insurance na lena matlab half protection hai, jo no
							protection ke barabar hi hai
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
