import styled from 'styled-components'
import { theme, Text } from 'verak-ui'

// insurer icons
import BhartiAXA from '../../../../../assets/insurers/BhartiAXA.svg'
import DigitInsurance from '../../../../../assets/insurers/DigitInsurance.svg'
import LibertyGeneralInsurance from '../../../../../assets/insurers/LibertyInsurance.svg'
import OrientalInsurance from '../../../../../assets/insurers/OrientalInsurance.svg'
import RelianceGeneralInsurance from '../../../../../assets/insurers/RelianceGeneralInsurance.svg'
import BajajAllianz from '../../../../../assets/insurers/bajajAllianz.svg'
import ICICILombardGeneralInsurance from '../../../../../assets/insurers/ICICI.svg'
import RoyalSundaram from '../../../../../assets/insurers/RoyalSundaram.svg'
import TataAIG from '../../../../../assets/insurers/TataAIG.svg'
import UnitedIndia from '../../../../../assets/insurers/UnitedIndia.svg'
import NationalInsurance from '../../../../../assets/insurers/NationalInsurance.svg'
import CholaMS from '../../../../../assets/insurers/CholaMSGeneralInsurance.svg'
import NewIndia from '../../../../../assets/insurers/NewIndiaAssurance.svg'
import KotakGeneral from '../../../../../assets/insurers/KotakGeneralInsurance.svg'
import SBIGeneral from '../../../../../assets/insurers/SBIGeneral.svg'

// addon icons
import burglary_theft from '../../../../../assets/icons/addonIcons/burglary_theft.svg'
import burglary from '../../../../../assets/icons/addonIcons/burglary.svg'
import cash_in_safe from '../../../../../assets/icons/addonIcons/cash_in_safe.svg'
import cash_in_transit from '../../../../../assets/icons/addonIcons/cash_in_transit.svg'
import cash_in_counter from '../../../../../assets/icons/addonIcons/cash_in_counter.svg'
import electronic_equipments from '../../../../../assets/icons/addonIcons/electronic_equipments.svg'
import fidelity_larceny from '../../../../../assets/icons/addonIcons/fidelity_larceny.svg'
import flop from '../../../../../assets/icons/addonIcons/flop.svg'
import mlop from '../../../../../assets/icons/addonIcons/mlop.svg'
import mbd from '../../../../../assets/icons/addonIcons/mbd.svg'
import neon_sign_board from '../../../../../assets/icons/addonIcons/neon_sign_board.svg'
import plate_glass from '../../../../../assets/icons/addonIcons/plate_glass.svg'

const responsiveBreakpoint = '816px'

const ActionBar = styled.div`
	background: ${theme.colors.secondary3};
	padding: 16px 24px;
`

const KeyCell = styled.div`
	background: #fafafa;
	padding: 16px 8px 16px 16px;
	color: ${theme.colors.primary};
	font-weight: 700;
	font-size: 14px;
	position: sticky;
	left: 0;
	border-top: 0.5px solid #02475e2b;
	min-width: 260px;
	max-width: 260px;
	height: ${props => (props.height ? props.height : 'auto')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	z-index: 100;
	@media (max-width: ${responsiveBreakpoint}) {
		min-width: 130px;
		max-width: 130px;
	}
`

const KeyCellContent = styled.div`
	@media (max-width: ${responsiveBreakpoint}) {
		display: flex;
		flex-direction: column;
		align-items: end;
	}
`

const ValueCell = styled.div`
	background: #fff;
	padding: 16px 8px;
	width: 330px !important;
	height: ${props => (props.height ? props.height : 'auto')};
	text-align: center;
	display: flex;
	justify-content: center;
	border-top: 0.5px solid #02475e2b;
	margin-left: 5px;
	@media (max-width: ${responsiveBreakpoint}) {
		width: auto !important;
	}
`

const QuoteImg = styled.img`
	width: 100%;
	/* width: calc(300px - 16px); */
	object-fit: contain;
`

const QuoteCardWrapper = styled.div`
	height: 100%;
	width: 340px;
	flex-shrink: 0;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow: hidden;
	margin-right: 1rem;
	background: ${theme.colors.white};
	@media (max-width: 768px) {
		width: 230px;
	}
`

const TableContainer = styled.div`
	display: flex;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	padding-top: 1rem;
`

const Sidebar = styled.div`
	width: 260px;
	height: auto;
	position: sticky;
	@media (max-width: 768px) {
		width: 130px;
	}
`

const QuotesContainer = styled.div`
	width: calc(100% - ${({ displaySalesAmmo }) => (displaySalesAmmo ? '628px' : '270px')});
	height: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
	overflow-x: auto;
	flex-shrink: 0;
	@media (max-width: 768px) {
		width: calc(100% - 150px);
	}
`

const LeftText = styled(Text)`
	text-align: left;
`

const FeatureItem = styled.li`
	font-size: 14px;
	font-weight: 600px;
	color: rgba(0, 0, 0, 0.7);
	text-align: left;
`
// utils
const getAddOnLabel = value => {
	const mapping = {
		BURGLARY_THEFT: 'Burglary & Theft',
		CIS: 'Cash in Safe',
		CIC: 'Cash in Counter',
		CIT: 'Cash in Transit',
	}

	if (value && mapping[value]) {
		return mapping[value]
	}

	return value
}

const infoRowOrder = {
	Insurer: { label: 'INSURANCE PROVIDER', height: '130px' },
	TotalPremium: { label: 'TOTAL PREMIUM', subLabel: 'INCL. OF TAXES' },
	Cover: { label: 'COVER', height: '60px' },
	PolicyType: { label: 'TYPE OF POLICY', height: '70px' },
	Expiry: { label: 'QUOTE EXPIRY IN', height: '60px' },
	Conditions: {
		label: 'INSURER RESPONSE',
		subLabel: 'Sum Insured Breakup(Overall):',
	},
	Addons: {
		label: 'ADD-ONS OPTED FOR',
		moreInfo: '',
	},
	ClaimsSettlementRatio: {
		label: 'CLAIMS SETTLEMENT RATIO',
		height: '150px',
	},
	SolvencyRatio: {
		label: 'SOLVENCY RATIO',
		height: '150px',
	},
	EstablishedOn: { label: 'ESTABLISHED', height: '100px' },
}

const safetyPlanInfoRowOrder = {
	TotalPremium: { label: 'TOTAL PREMIUM', subLabel: 'INCL. OF TAXES' },
	CCTV_01: {
		label: 'CCTV CAMERA',
		height: '400px',
	},
	MEMORY_01: {
		label: 'MEMORY CARD',
		height: '400px',
	},
	HardwareUnits: {
		label: 'NUMBER OF CCTV',
		height: '60px',
	},
	Service: {
		label: 'SERVICE',
		height: '60px',
	},
	Insurer: { label: 'INSURANCE PROVIDER', height: '130px' },
	InsurerPremium: { label: 'INSURER PREMIUM', height: '70px' },
	Cover: { label: 'COVER', height: '60px' },
	PolicyType: { label: 'TYPE OF POLICY', height: '70px' },
	Expiry: { label: 'QUOTE EXPIRY IN', height: '60px' },
	Conditions: {
		label: 'INSURER RESPONSE',
		subLabel: 'Sum Insured Breakup(Overall):',
	},
	Addons: {
		label: 'ADD-ONS OPTED FOR',
		moreInfo: '',
	},
	ClaimsSettlementRatio: {
		label: 'CLAIMS SETTLEMENT RATIO',
		height: '150px',
	},
	SolvencyRatio: {
		label: 'SOLVENCY RATIO',
		height: '150px',
	},
	EstablishedOn: { label: 'ESTABLISHED', height: '100px' },
}

const addonStyleConfig = {
	Burglary: { icon: burglary, color: '#DDAB3A' },
	BURGLARY_THEFT: { icon: burglary_theft, color: '#4AA161' },
	CIS: { icon: cash_in_safe, color: '#2854E7' },
	CIC: { icon: cash_in_counter, color: '#677338' },
	CIT: { icon: cash_in_transit, color: '#4AA161' },
	'Money Insurance - Cash in Safe': { icon: cash_in_safe, color: '#2854E7' },
	'Money Insurance - Cash in Transit': { icon: cash_in_transit, color: '#4AA161' },
	'Plate Glass': { icon: plate_glass, color: '#D22D6F' },
	'Neon glass / sign board': { icon: neon_sign_board, color: '#02475E' },
	'Machinery breakdown & Damage': { icon: mbd, color: '#4498B5' },
	'Fire loss of profit': { icon: flop, color: '#9A5A41' },
	'Machinery loss of profit': { icon: mlop, color: '#6467B0' },
	'Electronic Equipment Insurance': { icon: electronic_equipments, color: '#7514F5' },
	'Fidelity & Larceny': { icon: fidelity_larceny, color: '#F78670' },
}

const insurerMetrics = {
	// Note: ClaimsSettlementRatio is "Fire" specific
	'Liberty General Insurance': {
		SolvencyRatio: 2.18,
		ClaimsSettlementRatio: 81.53,
		EstablishedOn: '2013',
	},
	'Reliance General Insurance': {
		SolvencyRatio: 1.52,
		ClaimsSettlementRatio: 76.17,
		EstablishedOn: '2000',
	},
	'Bajaj Allianz General Insurance': {
		SolvencyRatio: 2.54,
		ClaimsSettlementRatio: 88.83,
		EstablishedOn: '2001',
	},
	'Digit Insurance': { SolvencyRatio: 3.24, ClaimsSettlementRatio: 77.17, EstablishedOn: '2016' },
	'ICICI Lombard General Insurance': {
		SolvencyRatio: 2.17,
		ClaimsSettlementRatio: 80.4,
		EstablishedOn: '2001',
	},
	'Oriental Insurance': {
		SolvencyRatio: 0.92,
		ClaimsSettlementRatio: 91.51,
		EstablishedOn: '1947',
	},
	'Royal Sundaram General Insurance': {
		SolvencyRatio: 1.69,
		ClaimsSettlementRatio: 89.29,
		EstablishedOn: '2001',
	},
	'United India Insurance': {
		SolvencyRatio: 0.3,
		ClaimsSettlementRatio: 80.7,
		EstablishedOn: '1938',
	},
	'Tata AIG General Insurance': {
		SolvencyRatio: 1.84,
		ClaimsSettlementRatio: 84.52,
		EstablishedOn: '2001',
	},
	'National Insurance': {
		SolvencyRatio: 0.02,
		ClaimsSettlementRatio: 80.59,
		EstablishedOn: '1906',
	},
	'Kotak Mahindra General': {
		SolvencyRatio: 2.13,
		ClaimsSettlementRatio: 77.67,
		EstablishedOn: '2015',
	},
	'The New India Assurance Co. Ltd.': {
		SolvencyRatio: 2.11,
		ClaimsSettlementRatio: 89.87,
		EstablishedOn: '1919',
	},
	'Kotak General Insurance': {
		SolvencyRatio: 2.13,
		ClaimsSettlementRatio: 77.67,
		EstablishedOn: '2015',
	},
	'SBI General Insurance': {
		SolvencyRatio: 2.27,
		ClaimsSettlementRatio: 78.0,
		EstablishedOn: '2009',
	},
}

const InsurerImagesMap = {
	'Oriental Insurance': OrientalInsurance,
	'Bharti AXA': BhartiAXA,
	'Digit Insurance': DigitInsurance,
	'Liberty General Insurance': LibertyGeneralInsurance,
	'Reliance General Insurance': RelianceGeneralInsurance,
	'Bajaj Allianz General Insurance': BajajAllianz,
	'ICICI Lombard General Insurance': ICICILombardGeneralInsurance,
	'Royal Sundaram General Insurance': RoyalSundaram,
	'United India Insurance': UnitedIndia,
	'Tata AIG General Insurance': TataAIG,
	'National Insurance': NationalInsurance,
	'Chola MS General Insurance': CholaMS,
	'The New India Assurance Co. Ltd.': NewIndia,
	'Kotak General Insurance': KotakGeneral,
	'SBI General Insurance': SBIGeneral,
}

// filter options
const typesOfCompanyOptions = [{ name: 'All Companies', value: 'ALL_COMPANIES' }]

const preferredCompanyOptions = [{ name: 'NIL', value: 'NIL' }]

const sortOptions = [
	{ name: 'Price (High to Low)', value: 'HIGH_TO_LOW' },
	{ name: 'Price (Low to High)', value: 'LOW_TO_HIGH' },
	{ name: 'A-Z Insurers', value: 'SORT_INSURERS' },
]

export const getSumInsuredDisplayName = title => {
	switch (title) {
		case 'BuildingValue':
			return 'Building'
		case 'BasementValue':
			return 'Basement'
		case 'PlinthFoundation':
			return 'Plinth & Foundation'
		case 'BoundaryWalls':
			return 'Boundary Walls, etc.'
		case 'PlantMachinery':
			return 'Plant & Machinery'
		case 'FurnitureFixture':
			return 'Furniture & Fixture'
		case 'Electronics':
			return 'Electronic Installations'
		case 'OfficeEquipment':
			return 'Office Equipment (including IT systems)'
		case 'ValuableContents':
			return 'Valuable contents'
		case 'Other':
			return 'Other contents'
		case 'MaxValSingleItem':
			return 'Maximum value of a single content item'
		case 'Stock':
			return 'Stock'
		default:
			return title
	}
}

export {
	// styles
	LeftText,
	KeyCell,
	KeyCellContent,
	ValueCell,
	ActionBar,
	QuoteImg,
	TableContainer,
	QuotesContainer,
	Sidebar,
	QuoteCardWrapper,
	FeatureItem,
	// utils
	// getSingleRow,
	getAddOnLabel,
	infoRowOrder,
	safetyPlanInfoRowOrder,
	insurerMetrics,
	InsurerImagesMap,
	typesOfCompanyOptions,
	preferredCompanyOptions,
	sortOptions,
	addonStyleConfig,
}
