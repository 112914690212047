// determines if it's a fire all risk policy or only a normal fire policy
export const isFireAllRiskPolicy = (addOns = {}) => {
	let policyTag = null

	if (Object.keys(addOns)?.length > 0) {
		policyTag = 'FIRE_ALL_RISK'
	} else {
		policyTag = 'FIRE'
	}

	return policyTag
}
