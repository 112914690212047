import { Tooltip, Text, Icon, theme, MainContainer } from 'verak-ui'
import { Row, Col, Card, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

const PolicytTypeCard = styled(Card)`
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px #02475e40;
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
const VerticalDivider = styled.div`
	width: 1px;
	background: ${theme.colors.gray6};
	padding: 0;
`

const PolicyType = () => {
	const fetch = useFetch()
	let history = useHistory()
	let [state, dispatch] = useContext(Context)
	const navigate = async value => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_TYPE',
				data: {
					PolicyName: value,
				},
			})
			resolve()
		})

		history.push(`/journey/client-details`)
	}

	const goBack = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'CLEAR_DATA',
			})
			resolve()
		})
		history.goBack()
	}

	return (
		<Container>
			<MainContainer header="New quotations - Vyapar Suraksha" divider={false} leftOnClick={goBack}>
				<Row>
					<Col className="d-flex align-items-center">
						<Text type="primary">Please give an estimate of how much cover is required?</Text>
					</Col>
					<VerticalDivider />
					<Col className="ps-5">
						<PolicytTypeCard
							className="mb-3 p-3 d-flex flex-row align-items-center"
							onClick={() => navigate('BSUS')}
						>
							<Icon name="bsus" className="me-4" />
							<Text type={'body1'} color={theme.colors.primary} fontWeight={'700'}>
								less than ₹5 Crore
							</Text>
						</PolicytTypeCard>
						<PolicytTypeCard
							className="mb-3 p-3 d-flex flex-row align-items-center"
							onClick={() => navigate('BLUS')}
						>
							<Icon name="blus" className="me-4" />
							<Text type={'body1'} color={theme.colors.primary} fontWeight={'700'}>
								₹5 Crore to ₹50 Crore
							</Text>
						</PolicytTypeCard>
						<PolicytTypeCard
							className="mb-3 p-3 d-flex flex-row align-items-center"
							onClick={() => navigate('SFSP')}
						>
							<Icon name="sfsp" className="me-4" />
							<Text type={'body1'} color={theme.colors.primary} fontWeight={'700'}>
								above ₹50 Crore
							</Text>
						</PolicytTypeCard>
					</Col>
				</Row>
				<Row className="mt-4 mb-4">
					<Col className="d-flex">
						<Tooltip icon="knowMore" className="me-2 " />
						<Text color={theme.colors.gray4}>
							Note: If during the quotation process, the Sum Insured moves outside the selected
							range - you will be asked for some additional information. Your choice here will NOT
							hamper your quotation.
						</Text>
					</Col>
				</Row>
			</MainContainer>
		</Container>
	)
}

export default PolicyType
