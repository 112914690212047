export const isManufacturingOccupancy = enteredOccupancy => {
	const occupancy = enteredOccupancy.toLowerCase()
	const manufacturingKeywords = ['manufacture', 'manufacturing', 'factory', 'factories']

	let hasManufacturingKeywords = false
	for (let word of manufacturingKeywords) {
		if (occupancy.includes(word)) {
			hasManufacturingKeywords = true
			break
		}
	}

	return hasManufacturingKeywords
}
