// this component will contain the topbar just below the main app navbar
// and will accept the actual content[eg: MainContainer] as children
import styled from 'styled-components'

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`

const PageContentAndNavbarWrapper = ({ children, ...props }) => {
	return <Wrapper {...props}>{children}</Wrapper>
}

export default PageContentAndNavbarWrapper
