import { theme, Text, Button, Icon, Modal, Tooltip, Divider } from 'verak-ui'
import {
	useContext,
	useEffect,
	useState,
	useMemo,
	useRef,
	useImperativeHandle,
	forwardRef,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'

import PageContentAndNavbarWrapper from '../../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../../components/SecondaryNavbar'
import { ComponentContext } from '../../../../../context/ComponentProvider'
import {
	MenuContainer,
	MenuItemContainer,
} from '../../../QuoteInProgressComponents/quoteInProgressUtils'
import SendTnCModal from '../../../SharedComponents/SendTnCModal'
import QuoteCard from './QuoteCard'
import SalesAmmo from './SalesAmmo/SalesAmmo'

import { useContextApi } from '../../../../../context/Provider'
import { useFetch } from '../../../../../api/apihook'

import { getDateString } from '../../../../../utilities/sqsDateUtils'
import { convertAmountToWords } from '../../../../RiskLocationReview/reviewScreenUtils'
import { getFormattedAmount } from '../../../../../utilities/getFormattedAmount'
import roundOffNum from '../../../../../utilities/roundOffNum'
import { burglarySIItems, calculateBurglarySI } from '../../../../../utilities/calculateBurglarySI'
import calculateTotalPremium from '../../../../../utilities/calculateTotalPremium'
import {
	// styles
	KeyCell,
	KeyCellContent,
	ActionBar,
	TableContainer,
	QuotesContainer,
	Sidebar,
	// utils
	sortOptions,
	infoRowOrder,
	getSumInsuredDisplayName,
	getAddOnLabel,
	addonStyleConfig,
	safetyPlanInfoRowOrder,
} from './quoteComparisonUtils'
import ClaimsDoc from '../../../../../assets/ClaimsDoc.png'
import LikeThumbIcon from '../../../../../assets/icons/likeThumbIcon.svg'
import { useWindowDimensions } from '../../../../../hooks/useWindowDimensions'
import ClientDetailsForm from './ClientDetailsForm'
import PaymentDetails from './PaymentDetails'

const QuoteEscalationBanner = styled.div`
	background-color: ${theme.colors.red};
	color: ${theme.colors.white};
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding: 10px;
	margin: 10px 0;
	margin-left: 280px;
	align-self: flex-start;
`

function getPolicyType(sumInsured) {
	if (sumInsured > 500000000) {
		return 'SFSP'
	} else if (sumInsured >= 50000000 && sumInsured <= 500000000) {
		// 5 cr and 50 cr
		return 'BLUS'
	}
	return 'BSUS'
}

const states = {
	COMPARISON_SCREEN: 'COMPARISON_SCREEN',
	CLIENT_DETAILS: 'CLIENT_DETAILS',
	PAYMENT_DETAILS: 'PAYMENT_DETAILS',
}

export default function QuotesComparisonComponent() {
	const { renderSidePanel, setRenderSidePanel } = useContext(ComponentContext)
	const [state, dispatch] = useContextApi()
	const history = useHistory()
	const fetch = useFetch()

	const [selectedSort, setSelectedSort] = useState('HIGH_TO_LOW')
	const [showTnCModal, setShowTnCModal] = useState(false)
	const [showHighLQPModal, setShowHighLQPModal] = useState(false)

	const { state: locationState } = useLocation()
	const { fetchQuotes } = useFetch()

	const [quotes, setQuotes] = useState(locationState?.quotes || [])

	const SumInsuredBreakdown = locationState?.SumInsuredBreakdown || {}
	const selectedState = locationState?.selectedState || ''
	const selectedCity = locationState?.selectedCity || ''

	const { width } = useWindowDimensions()
	const isMobileView = width <= 816

	useEffect(() => {
		if (!!renderSidePanel) {
			setRenderSidePanel(false)
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const refetchQuotes = async () => {
		const result = await fetchQuotes(locationState?.uid)
		const modifiedQuotes = result.quotes.map(quote => {
			const existingQuote = quotes.find(eq => eq.id === quote.id)
			return {
				...existingQuote,
				...quote,
			}
		})
		setQuotes(modifiedQuotes)
	}

	const triggerShowHighLQPModal = async () => {
		const isHighLQP = Math.min(...quotes.map(q => calculateTotalPremium(q))) >= 10000

		if (!isHighLQP) return

		let highLQPModalShownRfqs = JSON.parse(localStorage.getItem('highLQPModalShownRfqs'))
		if (!Array.isArray(highLQPModalShownRfqs)) highLQPModalShownRfqs = []

		const modalShown = highLQPModalShownRfqs.includes(state?.rfq?.id)

		if (!modalShown && !showHighLQPModal) {
			await new Promise(resolve => {
				setShowHighLQPModal(true)
				resolve()
			})
			highLQPModalShownRfqs.push(state.rfq.id)
			localStorage.setItem('highLQPModalShownRfqs', JSON.stringify(highLQPModalShownRfqs))
		}
	}

	useEffect(() => {
		if (quotes.length > 0 && !showHighLQPModal) {
			triggerShowHighLQPModal()
		}
	}, []) // eslint-disable-line

	const businessName = state?.rfq?.EntityName || ''
	const customerName = state?.rfq?.ProposerName || ''
	const WaConsent = state?.rfq?.WaConsent
	const WaPhone = state?.rfq?.WaPhone
	const Phone = state?.rfq?.Phone

	const clientRequestedAddon = locationState.PolicyAddons

	const copyToClipboard = content => {
		if (content.startsWith('+91')) {
			content = content.replace('+91', '')
		}
		navigator.clipboard.writeText(content)
		toast.info('Copied to clipboard')
	}

	const copyStyles = { cursor: 'pointer' }

	const customerInfo = (
		<div className="p-2" style={{ textAlign: 'left' }}>
			{customerName && (
				<>
					<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
						Customer Name:
					</Text>
					<Text type="body1" color="white" fontWeight="700" fontSize="18px">
						{customerName}
					</Text>
				</>
			)}
			{businessName && (
				<>
					<Divider top="0.5" bottom="0.5" style={{ color: '#fff' }} />
					<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
						Business Name:
					</Text>
					<Text type="body1" color="white" fontWeight="700" fontSize="18px">
						{businessName}
					</Text>
				</>
			)}
			<>
				<Divider top="0.5" bottom="0.5" style={{ color: '#fff' }} />
				<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
					WhatsApp Consent:
				</Text>
				<Text type="body1" color="white" fontWeight="700" fontSize="18px" className="d-flex">
					{WaConsent ? '✅' : `❌`} {WaConsent ? (WaPhone ? WaPhone : Phone) : 'No consent given'}
					{WaConsent && (
						<Icon
							name="copyIcon"
							className="mx-2"
							onClick={() => copyToClipboard(WaPhone ? WaPhone : Phone)}
							style={copyStyles}
						/>
					)}
				</Text>
			</>
			<>
				<Divider top="0.5" bottom="0.5" style={{ color: '#fff' }} />
				<Text type="body1" color="#B3B3B3" fontWeight="700" fontSize="14px">
					Customer's Contact Number:
				</Text>
				<Text type="body1" color="white" fontWeight="700" fontSize="18px" className="d-flex">
					{Phone}
					<Icon
						name="copyIcon"
						className="mx-2"
						onClick={() => copyToClipboard(Phone)}
						style={copyStyles}
					/>
				</Text>
			</>
		</div>
	)

	const PolicyTypeBadge = (
		<Text
			fontWeight="700"
			color="#fff"
			style={{ padding: '6px 12px', background: theme.colors.secondary, borderRadius: '17px' }}
		>
			{getPolicyType(locationState?.totalSumInsured || 0)}
		</Text>
	)

	const leftNavText = (
		<div className="d-flex align-items-center">
			{!isMobileView && (
				<>
					{locationState?.PolicyType}({locationState?.PolicyNumber}) &nbsp;
					{PolicyTypeBadge}
					<Icon name="rightArrow" color={theme.colors.primary} />
				</>
			)}
			<Text fontSize="18px" mobileFontSize="14px" fontWeight="700">
				Policy Quotes, {locationState?.uid} {isMobileView ? <br /> : '-'}{' '}
				{locationState?.ClientName}
			</Text>
		</div>
	)

	const navLeftContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{leftNavText}
		</Text>
	)

	const navRightContent = (
		<div className="d-flex align-items-center">
			{isMobileView ? (
				PolicyTypeBadge
			) : (
				<Text type="body1" color={theme.colors.gray7} fontWeight="700">
					<span style={{ opacity: '.6' }}>Sum Insured: </span>{' '}
					{convertAmountToWords(`₹ ${locationState?.totalSumInsured}`)}
				</Text>
			)}
		</div>
	)

	const applyFilters = (quotesArr, filters) => {
		let quotes = []
		const { sortFilter } = filters

		let quotesWithPremium = quotesArr.filter(item => !!item.TotalPremium)
		let quotesWithoutPremium = quotesArr.filter(item => !item.TotalPremium)
		if (sortFilter === 'HIGH_TO_LOW') {
			let sortedQuotes = quotesWithPremium.sort((a, b) => b.TotalPremium - a.TotalPremium)
			quotes = [...sortedQuotes, ...quotesWithoutPremium]
		} else if (sortFilter === 'LOW_TO_HIGH') {
			let sortedQuotes = quotesWithPremium.sort((a, b) => a.TotalPremium - b.TotalPremium)
			quotes = [...sortedQuotes, ...quotesWithoutPremium]
		} else {
			quotes = quotesArr.sort((a, b) =>
				a.Insurer.toUpperCase() < b.Insurer.toUpperCase() ? -1 : 1
			)
		}
		// continue filters further
		return quotes
	}

	let quotesList = useMemo(
		() => applyFilters(quotes, { sortFilter: selectedSort }),
		[quotes, selectedSort]
	) // eslint-disable-line

	const openWAChatLog = () => {
		if (locationState?.uid) {
			fetch.getWABots(locationState?.uid).then(response => {
				if (response?.PurchaseJourney) {
					window.open(response.PurchaseJourney, '_blank').focus()
					toast(
						<div>
							Whatsapp Chatlogs are available <a href={response.PurchaseJourney}>here</a>
						</div>
					)
				} else {
					toast.error('Whatsapp Chatlog not available yet')
				}
			})
		}
	}
	const checkRfqDetails = async () => {
		const uid = locationState?.uid || ''
		if (uid) {
			let rfqDetails = await fetch.getQuote(uid)

			const toMerge = {
				...rfqDetails,
				quoteId: uid,
			}

			const data = fetch.mergeRiskLocations(toMerge)

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE',
					data: data,
				})
				resolve()
			})
		}

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_RFQ_VIEW_ONLY_MODE',
				data: { rfqViewOnlyMode: true },
			})
			resolve()
		})

		// navigate to final review screen
		history.push('/final-review')
	}

	const lastEscalatedTime = state?.rfq?.LastEscalated || ''
	let escalationBanner = null
	if (quotesList.length === 1 && lastEscalatedTime) {
		escalationBanner = (
			<QuoteEscalationBanner className="d-flex align-items-center">
				<Icon style={{ marginRight: '0.5rem' }} name="warningTriangleSm" color="white" /> Operations
				team is chasing this quote since {getDateString(lastEscalatedTime)}
			</QuoteEscalationBanner>
		)
	}

	// mobile navbars

	const mobileNavLeftContent = (
		<div className="d-flex flex-column">
			<Text color={theme.colors.black} fontSize="10px">
				Sum Insured
			</Text>
			<Text color={theme.colors.black} fontSize="14px" fontWeight="700">
				{convertAmountToWords(`₹ ${locationState?.totalSumInsured}`)}
			</Text>
		</div>
	)

	const mobileNavRightContent = (
		<Button
			className="p-0"
			bgColor="transparent"
			textColor="#4C4C4C"
			label="View RFQ Details"
			icon="rfqDetailsIcon"
			iconSize="24px"
			style={{ fontSize: '16px' }}
			iconStyles={{ marginRight: '0.5rem' }}
			onClick={checkRfqDetails}
		/>
	)

	const MobileActionBar = (
		<ActionBar className="w-100 d-flex flex-column justify-content-between align-items-center">
			<div className="w-100 d-flex justify-content-between align-items-center">
				<Button
					bgColor="transparent"
					textColor="#4C4C4C"
					label="Send T&C"
					icon="paperFold"
					iconSize="24px"
					className="p-0"
					style={{ fontSize: '14px' }}
					iconStyles={{ marginRight: '0.5rem', filter: 'brightness(0) invert(0.35)' }}
					onClick={() => setShowTnCModal(true)}
				/>

				<Tooltip trigger="click" position="bottom" html={customerInfo}>
					<Button
						bgColor="transparent"
						textColor="#4C4C4C"
						label="View Customer Info"
						icon="customerInfoIcon"
						iconSize="24px"
						className="p-0"
						style={{ fontSize: '14px' }}
						iconStyles={{ marginRight: '0.5rem' }}
					/>
				</Tooltip>
			</div>

			<div className="mt-3 w-100 d-flex justify-content-between align-items-center">
				<FilterSelect
					selectedOption={selectedSort}
					onChange={selected => setSelectedSort(selected)}
					label="Sort by"
					options={sortOptions}
				/>

				<Button
					bgColor="transparent"
					textColor="#4C4C4C"
					label="Read Whatsapp Chatlog"
					icon="whatsapp"
					iconSize="24px"
					className="p-0 ps-2"
					style={{ fontSize: '14px', width: '180px' }}
					iconStyles={{ marginRight: '4px' }}
					onClick={openWAChatLog}
				/>
			</div>
		</ActionBar>
	)

	const DesktopActionBar = (
		<ActionBar className="w-100 d-flex justify-content-between align-items-center">
			<div className="d-flex justify-content-between align-items-center">
				<FilterSelect
					selectedOption={selectedSort}
					onChange={selected => setSelectedSort(selected)}
					label="Sort by"
					options={sortOptions}
				/>
			</div>
			<div className="d-flex">
				<Button
					bgColor="transparent"
					textColor="#4C4C4C"
					label="Send T&C"
					icon="paperFold"
					style={{ fontSize: '16px', marginRight: '24px' }}
					iconStyles={{ marginRight: '0.5rem', filter: 'brightness(0) invert(0.35)' }}
					onClick={() => setShowTnCModal(true)}
				/>
				<Button
					bgColor="transparent"
					textColor="#4C4C4C"
					label="View RFQ Details"
					icon="rfqDetailsIcon"
					style={{ fontSize: '16px', marginRight: '24px' }}
					iconStyles={{ marginRight: '0.5rem' }}
					onClick={checkRfqDetails}
				/>

				<Tooltip trigger="click" position="bottom" html={customerInfo}>
					<Button
						bgColor="transparent"
						textColor="#4C4C4C"
						label="View Customer Info"
						icon="customerInfoIcon"
						style={{ fontSize: '16px' }}
						iconStyles={{ marginRight: '0.5rem' }}
					/>
				</Tooltip>

				<Button
					bgColor="transparent"
					textColor="#4C4C4C"
					label="Read Whatsapp Chatlog"
					icon="whatsapp"
					style={{ fontSize: '16px' }}
					iconStyles={{ marginRight: '4px' }}
					onClick={openWAChatLog}
				/>
			</div>
		</ActionBar>
	)

	// to control child state from parent
	const compareTableRef = useRef()

	const handleGoBack = () => {
		const [activeState, setActiveState] = compareTableRef?.current?.handleActiveState()
		switch (activeState) {
			case states.COMPARISON_SCREEN:
				history.goBack()
				break
			case states.CLIENT_DETAILS:
				setActiveState(states.COMPARISON_SCREEN)
				break
			case states.PAYMENT_DETAILS:
				setActiveState(states.CLIENT_DETAILS)
				break
			default:
				history.goBack()
		}
	}

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			// style={{ height: '100%' }}
		>
			{/* main navbar */}
			<SecondaryNavbar
				onGoBack={handleGoBack}
				headerBg={isMobileView ? 'white' : theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navLeftContent}
				rightContent={navRightContent}
			/>
			{isMobileView && (
				<SecondaryNavbar
					showGoBack={false}
					navbarNumber={2}
					headerBg={theme.colors.secondary2}
					leftContent={mobileNavLeftContent}
					rightContent={mobileNavRightContent}
				/>
			)}
			{isMobileView ? MobileActionBar : DesktopActionBar}
			{escalationBanner}
			<QuotesCompareTable
				ref={compareTableRef}
				quotes={quotesList}
				SumInsuredBreakdown={SumInsuredBreakdown}
				clientRequestedAddon={clientRequestedAddon}
				selectedState={selectedState}
				selectedCity={selectedCity}
				customerInfo={{ customerName, WaPhone }}
				refetchQuotes={refetchQuotes}
			/>
			<SendTnCModal
				show={showTnCModal}
				handleClose={() => setShowTnCModal(false)}
				rfqData={state?.rfq}
			/>
			<HighLQPModal show={showHighLQPModal} handleClose={() => setShowHighLQPModal(false)} />
			<ToastContainer />
		</PageContentAndNavbarWrapper>
	)
}

const QuotesCompareTable = forwardRef(function QuotesCompareTable(
	{
		selectedState,
		selectedCity,
		quotes,
		SumInsuredBreakdown,
		clientRequestedAddon,
		customerInfo,
		refetchQuotes,
	},
	ref
) {
	const history = useHistory()
	const { state: locationState } = useLocation()
	const [state] = useContextApi()
	const fetch = useFetch()

	const initModalContent = { header: '', content: '' }
	const [showContentModal, setShowContentModal] = useState(false)
	const [showClaimsModal, setShowClaimsModal] = useState(false)
	const [modalContent, setModalContent] = useState(initModalContent)
	const [showAddonBreakupModal, setShowAddonBreakupModal] = useState(false)
	const [showSIBreakupModal, setShowSIBreakupModal] = useState(false)
	const [activeQuoteId, setActiveQuoteId] = useState('')
	const [showQuoteSelectionWarning, setShowQuoteSelectionWarning] = useState(false)
	const [selectedQuote, setSelectedQuote] = useState(() => {
		if (locationState?.selectedQuote) {
			return locationState?.selectedQuote
		}
		return {}
	})
	const [selectionComplete, setSelectionComplete] = useState(
		() => locationState?.selectedQuote?.Selected || false
	)
	const [selfServeFlow, setSelfServeFlow] = useState(() => {
		if (locationState?.paymentDetails?.Status === 'UNPAID') {
			return states.PAYMENT_DETAILS
		}
		return states.COMPARISON_SCREEN
	})

	// pass child state reference to parent comp
	useImperativeHandle(ref, () => ({
		handleActiveState: () => [selfServeFlow, setSelfServeFlow],
	}))

	const navigateTo = (url, additionalState) => {
		const newState = {
			ClientName: locationState?.ClientName,
			uid: locationState?.uid,
			// sum insured
		}
		history.push(url, { ...newState, ...additionalState })
	}

	const showInfoModal = ({ type, content }) => {
		let modalContent = {
			header: '',
			content: content,
		}
		switch (type) {
			case 'REJECTION':
				modalContent.header = 'Why was the proposal rejected?'
				break
			case 'CONDITIONS':
				modalContent.header = 'Special conditions'
				break
			default:
				break
		}
		setShowContentModal(true)
		setModalContent(modalContent)
	}

	const onToggleSIBreakupModal = () => {
		setShowSIBreakupModal(p => !p)
	}

	const onToggleAddOnSIBreakup = quoteId => {
		setShowAddonBreakupModal(!showAddonBreakupModal)
		setActiveQuoteId(showAddonBreakupModal ? '' : quoteId)
	}

	const showPurchaseQuoteWarning = quote => {
		setShowQuoteSelectionWarning(true)
		setSelectedQuote(quote)
	}

	const selectQuoteForPurchase = async (skipRequest = false) => {
		let response
		if (!skipRequest) {
			response = await fetch.selectQuote(locationState?.uid, selectedQuote?.id)
		}
		if (response || skipRequest) {
			setShowQuoteSelectionWarning(false)
			setSelectionComplete(true)
			setSelfServeFlow(states.CLIENT_DETAILS)
		}
	}

	const isSafetyPlan = locationState?.RfqTag === 'SAFETY_PLAN'
	const infoRowOrderMap = isSafetyPlan ? safetyPlanInfoRowOrder : infoRowOrder

	const sidebarContents = Object.entries(infoRowOrderMap).map(item => {
		let { label, subLabel, height, moreInfo } = item[1]
		if (item[0] === 'HardwareUnits') {
			const hardwareProductKeys = selectedQuote?.HardwareProducts?.map(h => h.Key)

			const hasCCTVAndMemoryCard =
				hardwareProductKeys.includes('CCTV_01') && hardwareProductKeys.includes('MEMORY_01')
			if (hasCCTVAndMemoryCard) {
				label = 'QUANTITY OF CCTV CAMERA & MEMORY CARD (EACH)'
			}
		}
		return (
			<KeyCell className="sidebar-item" id={`sidebar-${item[0]}`} key={item[0]} height={height}>
				<KeyCellContent className="d-flex justify-content-between">
					<div className="d-flex flex-column justify-content-end text-end align-items-end">
						{label}
						<Text color={theme.colors.gray3}>{subLabel}</Text>
					</div>
					{moreInfo && (
						<Tooltip html={moreInfo}>
							<Icon name="infoIcon" color={theme.colors.black} style={{ marginLeft: '0.5rem' }} />
						</Tooltip>
					)}
				</KeyCellContent>
			</KeyCell>
		)
	})

	const adjustContainerHeights = () => {
		let sidebarItems = document.getElementsByClassName('sidebar-item')
		sidebarItems = Array.from(sidebarItems).filter(item =>
			variableHeights.includes(item.id.replace('sidebar-', ''))
		)

		sidebarItems.forEach(sidebarItem => {
			const cellClassName = sidebarItem.id.replace('sidebar-', 'cell-')

			// find corresponding container with max height
			const correspondingCells = document.getElementsByClassName(cellClassName)

			const maxHeight = Math.max(...Array.from(correspondingCells).map(cell => cell.clientHeight))

			// set the height of the sidebar item to maxHeight
			sidebarItem.style.height = `${maxHeight}px`

			// set other corresponding cells height to maxHeight
			Array.from(correspondingCells).forEach(cell => {
				cell.style.height = `${maxHeight}px`
			})
		})
	}

	const [addonsExpanded, setAddonsExpanded] = useState(false)

	const handleAddonExpand = val => {
		setAddonsExpanded(val)
	}

	const filteredQuotes = isSafetyPlan ? quotes.filter(q => q?.Selected) : quotes
	const quoteCards = filteredQuotes.map(quote => {
		return (
			<QuoteCard
				rfqTag={locationState?.RfqTag}
				rfqId={locationState?.uid}
				quote={quote}
				allQuotes={quotes}
				navigateTo={navigateTo}
				showInfoModal={showInfoModal}
				showQuoteSelectionWarning={showPurchaseQuoteWarning}
				SumInsuredBreakdown={SumInsuredBreakdown}
				showSIBreakupModal={showSIBreakupModal}
				onToggleSIBreakupModal={onToggleSIBreakupModal}
				onToggleAddOnSIBreakup={onToggleAddOnSIBreakup}
				clientRequestedAddon={clientRequestedAddon}
				selectQuoteForPurchase={selectQuoteForPurchase}
				addonsExpanded={addonsExpanded}
				handleAddonExpand={handleAddonExpand}
				refetchQuotes={refetchQuotes}
			/>
		)
	})

	const variableHeights = [
		'TotalPremium',
		'CCTV_01',
		'MEMORY_01',
		'Product',
		'Conditions',
		'Addons',
		'ClaimsSettlementRatio',
		'SolvencyRatio',
	]

	useEffect(() => {
		// adjust height of sidebar and quote card cells based on variable height elements
		const allCells = Array.from(document.getElementsByClassName('cell-Addons'))
		allCells.forEach(cell => (cell.style.height = 'auto'))

		adjustContainerHeights()
	}, [addonsExpanded]) // eslint-disable-line react-hooks/exhaustive-deps

	const { width } = useWindowDimensions()
	const isMobileView = width <= 816
	const isFosUser = state?.app?.isFosUser
	const displaySalesAmmo = !(isFosUser || isMobileView)

	const [customerData, setCustomerData] = useState({})

	const fetchCustomerDetails = async () => {
		const result = await fetch.getCustomerDetails(locationState?.uid)
		setCustomerData(result)
	}

	useEffect(() => {
		if (selectedQuote && selectionComplete) {
			fetchCustomerDetails()
		}
	}, [selectedQuote && selectionComplete]) // eslint-disable-line react-hooks/exhaustive-deps

	let displayContainer

	if (selfServeFlow === states.COMPARISON_SCREEN && quotes.length > 0) {
		displayContainer = (
			<>
				<TableContainer className="mb-3">
					<Sidebar>{sidebarContents}</Sidebar>
					<QuotesContainer displaySalesAmmo={displaySalesAmmo}>{quoteCards}</QuotesContainer>
					{!isMobileView && (
						<SalesAmmo
							selectedState={selectedState}
							selectedCity={selectedCity}
							customerInfo={customerInfo}
							onClaimsDisplay={() => setShowClaimsModal(true)}
						/>
					)}
				</TableContainer>
				<InfoModal
					show={showContentModal}
					handleClose={() => {
						setShowContentModal(false)
						setModalContent(initModalContent)
					}}
					modalContent={modalContent}
				/>
				<SIBreakupModal
					show={showSIBreakupModal}
					handleClose={() => setShowSIBreakupModal(false)}
					totalSumInsured={locationState?.totalSumInsured}
					sumInsuredBreakdown={SumInsuredBreakdown}
					quoteItem={quotes[0]}
				/>
				<AddonBreakupModal
					show={showAddonBreakupModal}
					handleClose={() => setShowAddonBreakupModal(false)}
					quotes={quotes}
					activeQuoteId={activeQuoteId}
					sumInsuredBreakdown={SumInsuredBreakdown}
				/>
				<ClaimsImageModal
					show={showClaimsModal}
					handleClose={() => {
						setShowClaimsModal(false)
					}}
				/>
				{showQuoteSelectionWarning && (
					<QuoteSelectionWarningModal
						handleClose={() => setShowQuoteSelectionWarning(false)}
						onContinue={() => selectQuoteForPurchase()}
					/>
				)}
			</>
		)
	} else if (
		selfServeFlow === states.CLIENT_DETAILS &&
		!!selectedQuote &&
		selectionComplete &&
		customerData?.PersonalInfo?.Name
	) {
		displayContainer = (
			<ClientDetailsForm
				rfqId={locationState?.uid}
				customerData={customerData}
				selectedQuote={selectedQuote}
				onBackClick={() => setSelfServeFlow(states.COMPARISON_SCREEN)}
				proceedNextStep={() => setSelfServeFlow(states.PAYMENT_DETAILS)}
			/>
		)
	} else if (selfServeFlow === states.PAYMENT_DETAILS && !!selectedQuote) {
		displayContainer = (
			<PaymentDetails
				paymentDetails={locationState?.paymentDetails}
				selectedQuote={selectedQuote}
				rfqId={locationState?.uid}
				totalSumInsured={locationState?.totalSumInsured}
				onBackClick={async () => {
					await fetchCustomerDetails()
					setSelfServeFlow(states.CLIENT_DETAILS)
				}}
			/>
		)
	} else {
		displayContainer = null
	}

	return displayContainer
})

const SIBreakupModal = ({ show, handleClose, sumInsuredBreakdown, totalSumInsured, quoteItem }) => {
	let insurerResponseConfig = {
		text: { content: 'Proposal accepted', color: theme.colors.primary },
		icon: { name: 'tickCircle', color: theme.colors.green },
	}

	if (quoteItem?.Conditions || quoteItem?.ReducedSumInsured) {
		if (quoteItem.Conditions?.length > 0) {
			insurerResponseConfig = {
				text: {
					content: (
						<>
							Proposal has been <span style={{ color: '#75CC1F' }}>accepted</span> with{' '}
							<span style={{ color: '#F2994A' }}>special conditions</span>
						</>
					),
					color: '#8D8D8D',
				},
				icon: { name: 'squareTick', color: theme.colors.green },
			}
		} else if (quoteItem?.ReducedSumInsured) {
			insurerResponseConfig = {
				text: { content: `Total Sum Insured has been reduced`, color: '#2F80ED' },
				icon: { name: 'lockInSquare', color: '#2F80ED' },
			}
		}
	}

	const sumInsuredBreakupItems = Object.entries(sumInsuredBreakdown).map(([key, value]) => {
		return (
			<div key={key} className="col-md-6 mt-1 p-1">
				<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
					{getSumInsuredDisplayName(key)}
				</Text>
				<Text fontSize="18px" fontWeight="600" color="#333">
					{convertAmountToWords(`₹ ${value}`)}
				</Text>
			</div>
		)
	})

	return (
		<Modal
			title={
				<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
					TOTAL SUM INSURED BREAKUP
				</Text>
			}
			show={show}
			handleClose={handleClose}
			closeButton={true}
			footer={<></>}
		>
			<div className="p-2">
				<div className="d-flex align">
					<Icon
						name={insurerResponseConfig?.icon.name}
						color={insurerResponseConfig?.icon?.color}
					/>
					<Text
						className="mx-2"
						color={insurerResponseConfig?.text?.color}
						fontSize="14px"
						fontWeight="700"
					>
						{insurerResponseConfig?.text?.content}
					</Text>
				</div>
				<div className="mt-2">
					<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
						Total Sum Insured
					</Text>
					<Text fontSize="20px" fontWeight="800" color="#333333">
						{convertAmountToWords(`₹ ${totalSumInsured}`)}
					</Text>
				</div>
				<div className="row mx-3">{sumInsuredBreakupItems}</div>
			</div>
		</Modal>
	)
}

const AddonBreakupModal = ({ show, handleClose, sumInsuredBreakdown, quotes, activeQuoteId }) => {
	const currentQuote = quotes.find(quote => quote.id === activeQuoteId)
	const allAddons = currentQuote?.Addons.map((addon, index) => {
		const addonName = addon?.Name
		const addonLabel = getAddOnLabel(addonName)
		const isLast = index === currentQuote?.Addons?.length - 1
		const isBurglary = addonName === 'Burglary' || addonName === 'BURGLARY_THEFT'

		let sumInsuredBreakupItems = []
		if (isBurglary) {
			Object.entries(sumInsuredBreakdown).forEach(([key, value]) => {
				if (!burglarySIItems.includes(key)) {
					return
				}
				sumInsuredBreakupItems.push(
					<div className="col-md-6 mt-1 p-1">
						<Text fontSize="14px" fontWeight="500" color={theme.colors.gray3}>
							{getSumInsuredDisplayName(key)}
						</Text>
						<Text fontSize="18px" fontWeight="600" color="#333">
							{convertAmountToWords(`₹ ${value}`)}
						</Text>
					</div>
				)
			})
		}

		let addonSumInsured = addon?.SumInsured
		if (isBurglary) addonSumInsured = calculateBurglarySI(sumInsuredBreakdown)
		const isAddonRejected = addon?.Rejected === true

		const siText = isBurglary ? (
			<Text className="mx-1" fontSize="12px" fontWeight="600" color="rgba(0,0,0,0.5)">
				(Included in total Sum Insured)
			</Text>
		) : isAddonRejected ? (
			<Text className="mx-1" fontWeight="600" color="rgba(0,0,0,0.5)" fontSize="12px">
				(NOT APPROVED BY INSURER)
			</Text>
		) : (
			<Text className="mx-1" fontSize="12px" fontWeight="800" color={theme.colors.green}>
				(Separate Sum Insured for this add on)
			</Text>
		)

		return (
			<>
				<div key={index} className="my-2 d-flex flex-column align-items-start">
					<div className="d-flex align-items-start">
						<img src={addonStyleConfig[addonName]?.icon} alt={addonName} />
						<Text
							className="mx-1 text-left"
							color={addonStyleConfig[addonName]?.color}
							fontWeight="700"
							fontSize="16px"
						>
							{addonLabel?.toUpperCase()}
						</Text>
					</div>
					{addonSumInsured && (
						<Text
							className="d-flex mx-1 align-items-center"
							fontSize="20px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Insured - {convertAmountToWords(`₹ ${roundOffNum(addonSumInsured || 0)}`)} {siText}
						</Text>
					)}
					{!isAddonRejected && (
						<Text className="mx-1" fontSize="16px" fontWeight="500" color={theme.colors.gray3}>
							Premium - {getFormattedAmount(`₹ ${roundOffNum(addon?.Amount || 0)}`)}*/year
						</Text>
					)}
				</div>
				{isBurglary && <div className="row mx-3">{sumInsuredBreakupItems}</div>}
				{!isLast && <Divider />}
			</>
		)
	})

	return (
		<Modal
			title={
				<Text fontSize="14px" fontWeight="700" color={theme.colors.primary}>
					ADD ON DETAILS
				</Text>
			}
			show={show}
			handleClose={handleClose}
			closeButton={true}
			footer={<></>}
		>
			<div className="p-2">{allAddons}</div>
		</Modal>
	)
}

const InfoModal = ({ show, handleClose, modalContent }) => {
	return (
		<Modal
			title={
				<Text type="h5" fontWeight="bold" color={theme.colors.primary}>
					{modalContent.header}
				</Text>
			}
			show={show}
			handleClose={handleClose}
			footer={<></>}
		>
			<div className="text-left">
				<Text fontSize="18px" fontWeight="400" color={theme.colors.gray3}>
					{modalContent.content}
				</Text>
			</div>
		</Modal>
	)
}

const HighLQPModal = ({ show, handleClose }) => {
	return (
		<Modal
			show={show}
			handleClose={handleClose}
			title={
				<Text color="#DC2F21" fontSize="24px" fontWeight="700">
					NOTE
				</Text>
			}
			footer={<></>}
		>
			<div className="d-flex flex-column align-items-center">
				<img src={LikeThumbIcon} alt="" />
				<Text fontSize="36px" fontWeight="700" color="#DC2F21">
					Good Job
				</Text>
				<Text fontSize="24px" fontWeight="600" color="#999999" className="text-center my-3">
					Aapne accha kam kiya hai <br />
					Yeh bada customer ho sakta hai, apne TL se feedback lijiye.
				</Text>
			</div>
		</Modal>
	)
}

const ClaimsImageModal = ({ show, handleClose, modalContent }) => {
	return (
		<Modal title={<></>} show={show} handleClose={handleClose} footer={<></>} size="lg">
			<img src={ClaimsDoc} style={{ width: '100%' }} alt="claims modal" />
		</Modal>
	)
}

const QuoteSelectionWarningModal = ({ handleClose, onContinue }) => {
	return (
		<Modal
			show={true}
			handleClose={handleClose}
			title={
				<Text fontWeight="700" fontSize="24px" color={theme.colors.red}>
					Notice
				</Text>
			}
			footer={<></>}
		>
			<div className="d-flex flex-column align-items-center w-100">
				<Icon name="exclamationTriangle" size="60px" />
				<Text
					fontWeight="700"
					fontSize="24px"
					color={theme.colors.primary}
					className="mt-2 text-center"
				>
					Proceed ONLY if the client is unable to do this on their own!
				</Text>
				<Text
					fontWeight="700"
					fontSize="24px"
					color={theme.colors.primary}
					className="mt-2 text-center"
				>
					Team leader ye dekh raha hai!!
				</Text>
				<Button
					primary
					label="Continue"
					icon="rightArrow"
					iconAlign="right"
					iconColor="white"
					style={{ height: '48px' }}
					className="mt-4 w-100"
					onClick={onContinue}
				/>
			</div>
		</Modal>
	)
}

const FilterSelect = ({ label, options, selectedOption, onChange, spacing, disabled }) => {
	const optionsMap = options.map(item => {
		return (
			<MenuItemContainer onClick={() => onChange(item.value)} key={item.value}>
				{item.name}
			</MenuItemContainer>
		)
	})

	const selectedOptionString = options.find(item => item.value === selectedOption)?.name

	return (
		<div className={`d-flex flex-column ${spacing}`}>
			<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
				{label}
			</Text>
			<Tooltip
				trigger="click"
				contentStyle={{ padding: '0' }}
				theme="light"
				position="bottom"
				html={<MenuContainer>{optionsMap}</MenuContainer>}
				className="d-flex align-items-center cursor-pointer"
			>
				<Text
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
					fontSize="16px"
					fontWeight="400"
					color={theme.colors.black}
				>
					{selectedOptionString}
					{!disabled && (
						<Icon
							name="rightArrow"
							color={theme.colors.black}
							style={{ transform: 'rotate(90deg)' }}
						/>
					)}
				</Text>
			</Tooltip>
		</div>
	)
}
