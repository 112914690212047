import { theme, Text, Button, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import TimelineView from '../../../../components/Timeline'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import TimelineWarningIcon from '../../../../assets/icons/timelineWarningIcon.svg'
import { useState } from 'react'
import SubPoliciesModal from './SubPoliciesModal'

const RecommendedSubPoliciesContainer = styled.div`
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
`

const SubPoliciesSection = styled.div`
	max-height: ${p => (p.show ? '800px' : '0')};
	pointer-events: ${p => (p.show ? 'auto' : 'none')};
	height: auto;
	opacity: ${p => (p.show ? '1' : '0')};
	transition: all 0.5s ease;
`

const RecommendedSubPolicyCardContainer = styled.div`
	background: #fff;
	border: 1px solid #f78670;
	border-radius: 8px;
	padding: 20px 10px;
	width: min(335px, 100%);
	position: relative;
`

const CardCloseBtn = styled(Button)`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	border-radius: 50%;
	padding: 2px 8px;
	height: auto;
`

const subPoliciesData = [
	{
		icon: 'burglaryAndTheft',
		name: 'Burglary & Theft',
		id: 'sub-policy-burglary-theft',
	},
	{
		icon: 'electronicEquipmentInsurance',
		name: 'Electronic Equipment Insurance',
		id: 'sub-policy-electronic-equipment-insurance',
	},
	{
		icon: 'fidelityAndLarceny',
		name: 'Fidelity & Larceny',
		id: 'sub-policy-fidelity-and-larceny',
	},
]

const RecommendedSubPolicyCard = ({ cardData, onRemoveSubPolicy = () => {} }) => {
	const { id, icon, name } = cardData
	return (
		<RecommendedSubPolicyCardContainer className="d-flex align-items-end">
			<Icon className="me-3" name={icon} />
			<Text type="body2" fontWeight="700" color={theme.colors.primary}>
				{name}
			</Text>
			<CardCloseBtn
				label="x"
				textColor="#fff"
				bgColor={theme.colors.gray4}
				onClick={() => onRemoveSubPolicy(id)}
			/>
		</RecommendedSubPolicyCardContainer>
	)
}

const RecommendedSubPolicies = () => {
	const [subPolicies, setSubPolicies] = useState(subPoliciesData)
	const [showSubPolicies, setShowSubPolicies] = useState(true)
	const [showModal, setShowModal] = useState(false)

	const openModal = () => setShowModal(true)
	const closeModal = () => setShowModal(false)

	const handleRemoveSubPolicy = id => {
		setSubPolicies(prev => prev.filter(subPolicy => subPolicy.id !== id))
	}

	const displaySubPolicies = subPolicies.map(subPolicy => (
		<RecommendedSubPolicyCard
			id={subPolicy?.id || 'sub-policy'}
			cardData={subPolicy}
			onRemoveSubPolicy={handleRemoveSubPolicy}
		/>
	))

	const toggleSubPolicies = e => {
		setShowSubPolicies(prev => !prev)
	}

	let arrowStyles = {
		transform: 'rotate(90deg)',
		transition: 'all 0.5s ease',
	}
	if (showSubPolicies) {
		arrowStyles = {
			...arrowStyles,
			transform: 'rotate(-90deg)',
		}
	}

	return (
		<RecommendedSubPoliciesContainer className="container p-4 mt-4 bg-white">
			<div
				className="d-flex justify-content-between align-items-center"
				style={{ cursor: 'pointer' }}
				onClick={toggleSubPolicies}
			>
				<Text type="body1" fontWeight="700">
					The Underwriter has recommended additional sub polices to go with this Fire & Allied
					Perils policy
				</Text>
				<Icon name="rightArrow" color={theme.colors.gray} style={arrowStyles} />
			</div>
			<SubPoliciesSection show={showSubPolicies}>
				<div className="mt-4 d-flex align-items-center flex-wrap gap-3">{displaySubPolicies}</div>

				<div className="d-flex justify-content-end align-items-center mt-4">
					<Button
						style={{ height: '48px', marginRight: '0.5rem' }}
						label="Not now"
						bgColor="#fff"
						textColor={theme.colors.secondary}
						onClick={toggleSubPolicies}
					/>
					<Button
						style={{ height: '48px' }}
						label="Accept & continue"
						textColor="#fff"
						bgColor="linear-gradient(180deg, #D55C28 0%, #FF934F 100%)"
						icon="rightArrow"
						iconAlign="right"
						onClick={openModal}
					/>
				</div>
			</SubPoliciesSection>
			<SubPoliciesModal showModal={showModal} closeModal={closeModal} />
		</RecommendedSubPoliciesContainer>
	)
}

export default function UnderwritingReviewPending() {
	const history = useHistory()

	// const { state } = useLocation()

	const navText = `Overview, {UID} - {Client Name} - {Policy Type}`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	const handleCompleteRequirements = () => {
		history.push('/rfq-status/underwriter-requirements/fill-missing-data')
	}

	const timelineItems = [
		{
			icon: (
				<img
					style={{ marginLeft: '-3px', marginTop: '-2px' }}
					src={TimelineWarningIcon}
					alt="warning"
				/>
			),
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							RFQ completion
						</Text>
						<div className="d-flex mt-2">
							<Text className="mr-2" color={theme.colors.primary} fontSize="12px" fontWeight="400">
								Sent: 14 July 2021, 5:11pm
							</Text>
							<Text className="mx-1" color="#C4C4C4">
								|
							</Text>
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								More requirments needed by the underwriter
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="Complete Requirements"
						icon="rightArrow"
						iconAlign="right"
						onClick={handleCompleteRequirements}
					/>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Client verification
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Awaiting RFQ completion
						</Text>
					</div>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Quotes generation
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Awaiting update
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Pending
						</Text>
					</div>
				</div>
			),
		},
	]

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					borderRadius: '8px',
					width: 'min(1200px, 100%)',
				}}
				className="container h-100 p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.red}>
					Underwriter has requested for more information
				</Text>

				<RecommendedSubPolicies />

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)
}
