import { useHistory } from 'react-router'
import styled from 'styled-components'
import useScrollDirectionDetect from '../hooks/useScrollDirectionDetect'
import { Button, theme } from 'verak-ui'
import PropTypes from 'prop-types'

const SecondaryNavbarContainer = styled.div`
	margin: 0;
	padding: 0.5rem 1rem;
	position: sticky;
	top: ${p => (p.navbarNumber ? `${60 * p.navbarNumber}px` : '60px')};
	min-height: 64px;
	background: ${p => p.headerBg || theme.colors.secondary};
	transform: ${p => (p.scrollUp ? 'translateY(0)' : 'translateY(-500px)')};
	transition: all 0.3s ease;
	z-index: 10;
	width: 100%;
	box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.15);
`

const goBackButtonDefaultStyles = {
	margin: '0',
	padding: '0.5rem 0.15rem',
	borderRadius: '50%',
	background: 'transparent',
}

// NOTE: the navbarNumber prop is required when there are multiple navbars to prevent them from stacking up on each other [numbering starts from 1 onwards]
const SecondaryNavbar = ({
	navbarNumber,
	leftContent,
	rightContent,
	headerBg,
	onGoBack,
	showGoBack = true,
	goBackButtonStyles = {},
	goBackIconColor,
	hideOnScroll = false,
}) => {
	const history = useHistory()
	const { scrollUp } = useScrollDirectionDetect()

	let enableHideOnScroll = true
	if (hideOnScroll) {
		enableHideOnScroll = scrollUp
	}

	const gobackStyles = { ...goBackButtonDefaultStyles, ...goBackButtonStyles }

	let handleGoback = () => history.goBack()
	if (typeof onGoBack === 'function') {
		handleGoback = onGoBack
	}

	let renderGoBack = null
	if (showGoBack) {
		renderGoBack = (
			<Button
				onClick={handleGoback}
				label=""
				icon="navBackArrow"
				iconColor={goBackIconColor}
				style={gobackStyles}
			/>
		)
	}

	return (
		<SecondaryNavbarContainer
			navbarNumber={navbarNumber}
			scrollUp={enableHideOnScroll}
			headerBg={headerBg}
			className="d-flex align-items-center"
		>
			{renderGoBack}
			<div className="w-100 d-flex justify-content-between align-items-center">
				{leftContent}
				{rightContent}
			</div>
		</SecondaryNavbarContainer>
	)
}

export default SecondaryNavbar

SecondaryNavbar.propTypes = {
	navbarNumber: PropTypes.number,
	leftContent: PropTypes.node,
	rightContent: PropTypes.node,
	headerBg: PropTypes.string,
	onGoBack: PropTypes.func,
	showGoBack: PropTypes.bool,
	goBackButtonStyle: PropTypes.object,
	goBackIconColor: PropTypes.string,
	hideOnScroll: PropTypes.bool,
}
