export const stockMethodCheck = (StockRailSiding, StockInProcess, Duration) => {
	let value = convertDuration(Duration)

	const isDeclarationAllowed =
		StockRailSiding === false && StockInProcess === false && value >= 12

	return isDeclarationAllowed
}

const convertDuration = Duration => {
	const type = Duration[0] // D, M ,Y
	const number = parseInt(Duration.substring(1)) // 15, 1-12, 2-10
	let value = 0

	if (type === "D") {
		value = number/30
	} else if (type === "Y") {
		value = number*12
	} else {
		// M
		value = number
	}

	return value
}