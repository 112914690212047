import styled from 'styled-components'
import { theme, Text } from 'verak-ui'

import { CardStyle } from './commonStyle'

import { getFormattedAmount } from '../../../utilities/getFormattedAmount'

const convertNumToWords = number => {
	const numberWords = {
		1: 'One',
		2: 'Two',
		3: 'Three',
		4: 'Four',
		5: 'Five',
		6: 'Six',
		7: 'Seven',
		8: 'Eight',
		9: 'Nine',
		10: 'Ten',
		11: 'Eleven',
		12: 'Twelve',
		13: 'Thirteen',
		14: 'Fourteen',
		15: 'Fifteen',
		16: 'Sixteen',
		17: 'Seventeen',
		18: 'Eighteen',
		19: 'Nineteen',
		20: 'Twenty',
		30: 'Thirty',
		40: 'Forty',
		50: 'Fifty',
		60: 'Sixty',
		70: 'Seventy',
		80: 'Eighty',
		90: 'Ninety',
		100: 'Hundred',
		1000: 'Thousand',
	}
	const numberArray = number.toString().split('')
	const numberArrayLength = numberArray.length
	if (numberArrayLength === 1) {
		return numberWords[numberArray[0]]
	}
	if (numberArrayLength === 2) {
		if (numberArray[1] === '0') {
			return numberWords[number]
		} else if (numberArray[0] === '1') {
			return `${numberWords[number]}`
		} else {
			return `${numberWords[numberArray[0] + '0']} ${numberWords[numberArray[1]]}`
		}
	} else if (numberArrayLength === 3) {
		if (numberArray[1] === '0' && numberArray[2] === '0') {
			return `${numberWords[numberArray[0]]} Hundred`
		} else {
			return `${numberWords[numberArray[0]]} Hundred and ${convertNumToWords(number % 100)}`
		}
	} else {
		return ''
	}
}

const getAmountInWords = amount => {
	const crores = Math.floor(amount / 10000000)
	const lakhs = Math.floor((amount % 10000000) / 100000)
	const thousands = Math.floor((amount % 100000) / 1000)
	const hundreds = Math.floor(amount % 1000)

	let amountStr = ''
	if (crores > 0) {
		amountStr += `${convertNumToWords(crores)} Crore `
	}
	if (lakhs > 0) {
		amountStr += `${convertNumToWords(lakhs)} Lakh `
	}
	if (thousands > 0) {
		amountStr += `${convertNumToWords(thousands)} Thousand `
	}
	if (hundreds > 0) {
		amountStr += `${convertNumToWords(hundreds)}`
	}
	return amountStr
}

const PremiumCalculatorCard = ({ totalSI, LeadSource }) => {
	let premium = Math.round(totalSI * (250 / 100000))

	// LeadSource: 'FOS_1', 'CP_1', 'FB_LG_1', 'SELF_SOURCED'
	if (['CP', 'FOS', 'SELF'].includes(LeadSource?.split('_')[0]) && premium < 873) {
		premium = 873
	} else if (premium < 473) {
		premium = 473
	}

	const perDay = getFormattedAmount(Math.round(premium / 365))
	const perMonth = getFormattedAmount(Math.round(premium / 12))
	const perYear = getFormattedAmount(premium)
	const siPreview = getFormattedAmount(totalSI)
	return (
		<Container className="flex-fill d-flex flex-column bg-white">
			<Text color={theme.colors.blue} fontSize="14px" fontWeight="700">
				Estimated Premium
			</Text>
			<div className="mt-2 mb-3 d-flex justify-content-between">
				<div className="d-flex flex-column w-100">
					<Text color={theme.colors.blue} fontSize="12px" fontWeight="400">
						Per Day ⭐
					</Text>
					<Text color={theme.colors.blue} fontSize="14px" fontWeight="700">
						₹ {perDay}
					</Text>
				</div>
				<div className="d-flex flex-column w-100">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Per Year
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="700">
						₹ {perYear}
					</Text>
				</div>
				<div className="d-flex flex-column w-100">
					<Text color={theme.colors.gray2} fontSize="12px" fontWeight="400">
						Per Month
					</Text>
					<Text color={theme.colors.black} fontSize="14px" fontWeight="700">
						₹ {perMonth}
					</Text>
				</div>
			</div>
			<TotalSISection className="d-flex justify-content-between">
				<div className="d-flex flex-column">
					<Text color={theme.colors.gray2} fontWeight="400" fontSize="12px">
						For Total Sum Insured
					</Text>
					<Text color={theme.colors.blue} fontWeight="400" fontSize="14px">
						₹ {siPreview}
					</Text>
				</div>
				<Text color={theme.colors.blue} className="w-50" fontSize={'11px'}>
					{totalSI ? getAmountInWords(totalSI) : '-'}
				</Text>
			</TotalSISection>
		</Container>
	)
}

export default PremiumCalculatorCard

const Container = styled(CardStyle)`
	border-radius: 8px;
	padding: 14px !important;
`

const TotalSISection = styled.div`
	border: 0.5px solid #9fa2e2;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
	border-radius: 9px;
	padding: 8px 12px;
`
