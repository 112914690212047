// packages
import { theme, Text, Button, Icon, Input, Space, OnDesktop, Tooltip } from 'verak-ui'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useEffect, useRef, useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner, Row, Col } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth0 } from '@auth0/auth0-react'

// components
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import TableComponent from '../../components/Table'

import DeleteRFQModal from './QuoteInProgressComponents/DeleteRFQModal'
import FilterButton from './QuoteInProgressComponents/FilterButton'
import EditDetailsModal from './QuoteInProgressComponents/EditDetailsModal'
import NotificationTriggerModal from './QuoteInProgressComponents/NotificationTriggerModal'
import RequestForQuotationModal from './QuoteInProgressComponents/RequestForQuotationModal'
import SendTnCModal from './SharedComponents/SendTnCModal'
import ReassignRfqModal from './QuoteInProgressComponents/ReassignRfqModal'
import ReactivateFlowModal from './QuoteInProgressComponents/ReactivateFlowModal'
// import EmptyResult from './QuoteInProgressComponents/EmptyResultContainer'

// context
import { useContextApi } from '../../context/Provider'
import { useLoadingOverlay } from '../../components/LoadingOverlay'
import { ComponentContext } from '../../context/ComponentProvider'

// utils
import { useFetch } from '../../api/apihook'
import { getDateString } from '../../utilities/sqsDateUtils'
import { authorizedTeamLeaders } from '../../utilities/authorizedAccounts'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import {
	// STYLES
	MenuItemContainer,
	MenuContainer,
	emptySearchStyle,
	HeaderContent,
	PseudoCheckbox,
	// VARS
	getStatusTag,
	getLastPathAndLocationId,
	newQuoteMenu,
	statusFilterMenuItems,
	sortMenuItems,
	headerItems,
	getLowestPremium,
	getResponsesContainer,
	lostFilter,
} from './QuoteInProgressComponents/quoteInProgressUtils'
import ReactivateIcon from '../../assets/icons/reactivateIcon.svg'

function getUniqueRfqs(oldRfqList, newRfqs) {
	const updatedRfqList = [...oldRfqList, ...newRfqs]
	return updatedRfqList
		.map(rfq => rfq.id)
		.filter((id, index, self) => self.indexOf(id) === index)
		.map(id => updatedRfqList.find(rfq => rfq.id === id))
}

const formatSearchValue = searchString => {
	let searchValue = ''
	const len = searchString.length

	switch (true) {
		case len === 10:
			searchValue = searchString
			break
		case len === 11 && searchString.startsWith('0'):
			searchValue = searchString.substring(1)
			break
		case len === 12 && searchString.startsWith('91'):
			searchValue = searchString.substring(2)
			break
		case len === 13 && searchString.startsWith('+91'):
			searchValue = searchString.substring(3)
			break
		default:
			searchValue = searchString
	}

	return searchValue
}

export default function QuotesInProgress() {
	const history = useHistory()

	const { state: locationState } = useLocation()

	const [, dispatch] = useContextApi()
	const { setRenderSidePanel } = useContext(ComponentContext)

	const { getOngoingRFQs, searchRFQbyQuery } = useFetch()

	useEffect(() => {
		setRenderSidePanel(true)
	}, [])

	const { isLoading, setIsLoading, setLoadingText } = useLoadingOverlay()

	const setLoadingState = ({ status, message }) => {
		setIsLoading(status)
		setLoadingText(message)
	}

	const [selectedPolicyType, setSelectedPolicyType] = useState('All Policies')

	const [sortRfqBy, setSortRfqBy] = useState('')
	const [filterBy, setFilterBy] = useState('')

	const [leadList, setLeadList] = useState(null)
	const [searchQuery, setSearchQuery] = useState('')
	const [timer, setTimer] = useState(null)
	const [searchResults, setSearchResults] = useState(null)

	const [clientEmail, setClientEmail] = useState('')

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showNotifTriggerModal, setShowNotifTriggerModal] = useState(false)
	const [showTnCModal, setShowTnCModal] = useState(false)
	const [showRequestQuotesModal, setShowRequestQuotesModal] = useState(false)

	const [selectedRfq, setSelectedRfq] = useState(null)

	const [showEditDetailsModal, setShowEditDetailsModal] = useState(false)

	// rfq re-assignment states
	const [showReassignmentModal, setShowReassignmentModal] = useState(false)
	const [showReassignmentSection, setShowReassignmentSection] = useState(false)
	const [selectedRfqs, setSelectedRfqs] = useState([])

	// reactivation flow states
	const [showReactivationModal, setShowReactivationModal] = useState(false)

	const { width } = useWindowDimensions()
	const isMobileView = width <= 816

	const { user } = useAuth0()

	const isAuthorizedTeamLeader = authorizedTeamLeaders.includes(user.email)

	// filtering policies based on Tag -> "FIRE" or "FIRE_ALL_RISK" or "ALL"
	let filterForPolicyType = ''
	if (selectedPolicyType === 'Vyapar Suraksha') {
		filterForPolicyType = ''
	}

	const table = useRef()

	// pagination states
	const [currentCursor, setCurrentCursor] = useState('') // to keep track of the last RFQ item's timestamp
	const [hasMore, setHasMore] = useState(true) // to indicate whether there are more items to load
	const [loadingMore, setLoadingMore] = useState(false) // to show loader at the bottom while loading more data

	const fetchOngoingRFQs = async (cursor, leads) => {
		if (leads?.length) {
			// if some leads were fetched earlier
			setLoadingMore(true)
		}
		const rfqSort = sortRfqBy.replace(' ', '')
		const result = await getOngoingRFQs(rfqSort, cursor, filterBy)
		const rfqList = result?.rfqs
		let filteredLeads = rfqList //?.filter(item => item?.Status !== 'RFQ:QUOTES:LOST') || []
		setHasMore(result.hasMore)

		// hide 'purchase successful' and 'purchase confirmation pending' rfqs, in the default view
		// will be accessible via the filter option
		if (!filterBy) {
			filteredLeads = filteredLeads.filter(
				item =>
					item?.Status !== 'OPS:POLICY:PENDING' &&
					item?.Status !== 'OPS:CLOSURE:PENDING' &&
					item?.Status !== 'OPS:SETTLEMENT:PENDING'
			)
		}

		if (leads?.length && cursor !== result.cursor) {
			setLoadingMore(false)
			setLeadList(prevLeads => getUniqueRfqs(prevLeads, filteredLeads))
		} else {
			// first time fetching
			setLeadList(filteredLeads)
		}
		setCurrentCursor(result.cursor)
	}

	const handleScroll = () => {
		if (
			window.scrollY > 0 &&
			window.innerHeight + window.scrollY > table?.current?.offsetHeight &&
			!loadingMore &&
			hasMore
		) {
			fetchOngoingRFQs(currentCursor, leadList)
		}
	}

	useEffect(() => {
		setLoadingState({ status: true, message: 'Fetching Ongoing RFQs' })
		fetchOngoingRFQs(currentCursor, leadList)
	}, [sortRfqBy, filterBy])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [leadList?.length, loadingMore, currentCursor])

	const navigateTo = (route, data = {}) => {
		history.push(route, data)
	}

	const policyTypeTag = Tag => {
		let previewTag = ''
		if (['FIRE', 'FIRE_ALL_RISK'].includes(Tag)) {
			previewTag = 'Vyapar Suraksha'
		}
		return previewTag
	}

	// TODO: add policy types here when other journeys are ready
	const policyTypes = ['All Policies', 'Vyapar Suraksha']
	const policyTypesMenuMap = policyTypes.map(policyItem => {
		return (
			<MenuItemContainer onClick={() => setSelectedPolicyType(policyItem)} key={policyItem}>
				{policyItem}
			</MenuItemContainer>
		)
	})

	const navLeftContent = (
		<>
			<Tooltip
				trigger="click"
				contentStyle={{ padding: '0' }}
				theme="light"
				position="bottom"
				html={<MenuContainer>{policyTypesMenuMap}</MenuContainer>}
				className="d-flex align-items-center cursor-pointer"
			>
				<Text type="body1" color={theme.colors.gray7} fontWeight="700">
					{selectedPolicyType}
				</Text>
				<Icon name="rightArrow" color={theme.colors.gray3} style={{ transform: 'rotate(90deg)' }} />
			</Tooltip>
		</>
	)

	const newQuoteItemClickHandler = option => {
		switch (option) {
			case 'New Quote':
				return startNewQuote()
			default:
				return
		}
	}

	const newQuoteMenuMap = newQuoteMenu.map((menuItem, index) => {
		const isLast = index !== newQuoteMenu.length - 1
		return (
			<MenuItemContainer
				isLast={isLast}
				onClick={() => newQuoteItemClickHandler(menuItem.text)}
				key={menuItem.id}
			>
				<Icon
					name={menuItem.icon}
					color={theme.colors.gray3}
					style={{ height: '13px', width: '13px', marginRight: '.5rem' }}
				/>
				{menuItem.text}
			</MenuItemContainer>
		)
	})

	const refreshRfqList = () => {
		setLoadingState({ status: true, message: 'Fetching latest Ongoing RFQs' })
		setCurrentCursor(null)
		setLeadList(null)
		fetchOngoingRFQs(null, [])
	}

	const refreshButton = isMobileView ? (
		<Icon
			name="refresh"
			iconColor={theme.colors.primary}
			size="24px"
			className="me-2"
			onClick={refreshRfqList}
		/>
	) : (
		<Button
			className="me-2"
			bgColor={theme.colors.white}
			label="Refresh Page"
			icon="refresh"
			iconStyles={{ marginRight: '0.5rem' }}
			onClick={refreshRfqList}
			style={{
				color: theme.colors.primary,
				border: `1px solid ${theme.colors.primary}`,
				borderRadius: '5rem',
			}}
		/>
	)

	const navRightContent = (
		<OnDesktop className="d-flex align-items-center">
			{refreshButton}
			<Tooltip
				trigger="click"
				position="bottom"
				theme="light"
				contentStyle={{ padding: '0' }}
				className="d-flex cursor-pointer"
				html={<MenuContainer>{newQuoteMenuMap}</MenuContainer>}
			>
				<Button
					bgColor={theme.colors.secondary}
					icon="plusIcon"
					iconColor={'white'}
					label={'Start an RFQ'}
					style={{ borderRadius: '5rem' }}
					iconStyles={{ marginRight: '0.5rem' }}
				/>
			</Tooltip>
		</OnDesktop>
	)

	const resumeQuote = leadItem => {
		const LastPathData = getLastPathAndLocationId(leadItem?.LastPath || '')
		const locationState = {
			ClientName: leadItem?.Name,
			ProposerName: leadItem?.ProposerName,
			uid: leadItem?.id,
			PolicyType: policyTypeTag(leadItem.Tag),
			Phone: leadItem?.Phone,
			LastPath: LastPathData[0],
			locationID: LastPathData[1],
			DraftRfqId: leadItem.DraftRfq,
		}

		navigateTo('/rfq-status/in-progress', locationState)
	}

	const handleRfqItemClick = async (leadItem, index) => {
		const locationState = {
			ClientName: leadItem?.Name,
			uid: leadItem?.id,
			PolicyType: policyTypeTag(leadItem.Tag),
			SumInsured: leadItem?.TotalSumInsured,
			PolicyAddons: leadItem?.PolicyAddons ? leadItem?.PolicyAddons : [],
		}

		await new Promise(resolve => {
			dispatch({
				type: 'SET_RFQ_DATA',
				data: {
					id: leadItem?.id || '',
					rfqStatus: leadItem?.Status || '',
					EntityName: leadItem?.Name || '',
					ProposerName: leadItem?.ProposerName || '',
					WaPhone: leadItem?.WaPhone || '',
					Phone: leadItem?.Phone || '',
					WaConsent: leadItem?.WaConsent || false,
					LastEscalated: leadItem?.Time?.LastEscalated || '',
				},
			})
			resolve()
		})

		switch (leadItem?.Status) {
			case 'AGENT:RFQ:ABANDONED':
				// TODO: add logic for rfq abandoned
				navigateTo('/rfq-status/rfq-abandoned', {
					...locationState,
					PolicyNumber: index,
					RFQType: leadItem?.RFQType || 'online',
				})
				break
			case 'AGENT:QUOTE:ABANDONED':
				// TODO: add logic for quote abandoned
				navigateTo('/rfq-status/quote-abandoned', {
					...locationState,
					PolicyNumber: index,
					RFQType: leadItem?.RFQType || 'online',
				})
				break
			case 'AGENT:RFQ:PENDING':
				resumeQuote(leadItem)
				break
			case 'UW:REVIEW:PENDING:AUTO':
				navigateTo('/rfq-status/underwriter-review-pending', locationState)
				break
			case 'RFQ:CHANGES': // TODO: random status value used, change this value
				navigateTo('/rfq-status/changes-in-rfq', locationState)
				break
			case 'CLIENT:VERIFICATION:PENDING':
				navigateTo('/rfq-status/client-verification-pending', locationState)
				break
			case 'OPS:QUOTES:PENDING':
			case 'OPS:RFQ_SUBMISSION:PENDING':
				navigateTo('/rfq-status/quotes-pending', locationState)
				break
			case 'UW:REVIEW:PENDING':
				navigateTo('/rfq-status/underwriter-review-pending', locationState)
				break
			case 'AGENT:QUOTES:PENDING':
			case 'AGENT:ALL_QUOTES:PENDING':
			case 'OPS:RFQ_RESUBMISSION:PENDING':
			case 'CLIENT:QUOTES:AVAILABLE':
				navigateTo('/rfq-status/quotes-ready', { ...locationState, PolicyNumber: index })
				break
			case 'AGENT:SETTLEMENT:FAILED':
			case 'OPS:SETTLEMENT:PENDING':
			case 'OPS:CLOSURE:PENDING':
			case 'OPS:POLICY:PENDING':
				navigateTo('/rfq-status/purchase', {
					...locationState,
					PolicyNumber: index,
					rfqItem: leadItem,
				})

				break
			case 'RFQ:QUOTES:LOST':
				navigateTo('/rfq-status/quotes-ready', { ...locationState, PolicyNumber: index })
				break
			case 'RFQ:LOST':
				break
			default:
				return
		}
	}

	const removeRFQ = (event, rfq) => {
		event.stopPropagation()
		setSelectedRfq(rfq)
		setShowDeleteModal(true)
	}

	const enableReactivationFlow = (event, rfq) => {
		event.stopPropagation()
		setSelectedRfq(rfq)
		setShowReactivationModal(true)
	}

	const getDeleteButton = (rfqItem, displayGap) => {
		return (
			<div
				style={{ width: '35px', marginRight: `${displayGap ? '0.7rem' : 'auto'}` }}
				onClick={event => removeRFQ(event, rfqItem)}
			>
				<Icon name="deleteTrash" className={`mx-2 mt-1`} />
			</div>
		)
	}

	const getRfqEscalationTrigger = leadItem => {
		return (
			<div className="d-flex align-items-center">
				<Button
					style={{ color: 'inherit', padding: 0 }}
					iconStyles={{
						marginRight: '1rem',
					}}
					textColor={theme.colors.primary}
					icon={'addQuoteReq'}
					iconColor={theme.colors.green}
					bgColor="white"
					onClick={() => {
						setSelectedRfq(leadItem)
						setShowRequestQuotesModal(true)
					}}
					label={'Request to add quotes'}
				/>
			</div>
		)
	}

	const getEditDetailsTrigger = leadItem => {
		return (
			<div style={{ height: '80px' }} className="d-flex align-items-center">
				<Button
					style={{ color: 'inherit', padding: 0, height: 'inherit', alignItems: 'start' }}
					iconStyles={{
						marginRight: '1rem',
					}}
					textColor={theme.colors.primary}
					icon="editPencilSquare"
					iconColor={theme.colors.primary}
					bgColor="white"
					onClick={() => {
						setSelectedRfq(leadItem)
						setShowEditDetailsModal(true)
					}}
					label={
						<div className="d-flex flex-column align-items-start">
							<Text fontWeight="bold">Edit Proposer Name/</Text>
							<Text fontWeight="bold">Legal Entity Name/</Text>
							<Text fontWeight="bold">Contact Number/</Text>
							<Text fontWeight="bold">Email</Text>
						</div>
					}
				/>
			</div>
		)
	}

	//
	const getNewNotificationTrigger = leadItem => {
		return (
			<div className="d-flex align-items-center">
				<Button
					style={{
						padding: '0',
						width: '100%',
						justifyContent: 'flex-start',
					}}
					iconStyles={{
						marginRight: '1rem',
					}}
					textColor={theme.colors.primary}
					icon="sendNotification"
					bgColor="white"
					onClick={event => {
						setSelectedRfq(leadItem)
						setShowNotifTriggerModal(true)
					}}
					label="Trigger notifications"
				/>
			</div>
		)
	}

	const getReactivateButton = (rfqItem, displayGap) => {
		return (
			<div
				style={{ width: '35px', marginRight: `${displayGap ? '0.7rem' : 'auto'}` }}
				onClick={event => enableReactivationFlow(event, rfqItem)}
			>
				<img height="24px" width="24px" src={ReactivateIcon} alt="reactivate rfq" />
			</div>
		)
	}

	const getMenuItemContainer = (rfqItem, displayGap) => {
		const isAbandonedRfq = rfqItem?.MetaStatus?.Abandoned === true

		const displayTriggerConditions = {
			NOTIFICATION_TRIGGER: !isAbandonedRfq,
			RFQ_ESCALATION_TRIGGER:
				!['OPS:CLOSURE:PENDING', 'OPS:POLICY:PENDING', 'AGENT:RFQ:PENDING'].includes(
					rfqItem.Status
				) && !isAbandonedRfq,
			EDIT_DETAILS_TRIGGER: rfqItem.Status !== 'OPS:CLOSURE:PENDING',
		}

		let notificationTrigger = null
		if (displayTriggerConditions['NOTIFICATION_TRIGGER']) {
			notificationTrigger = getNewNotificationTrigger(rfqItem)
		}

		let rfqEscalationTrigger
		if (displayTriggerConditions['RFQ_ESCALATION_TRIGGER']) {
			rfqEscalationTrigger = getRfqEscalationTrigger(rfqItem)
		}

		let editDetailsTrigger
		if (displayTriggerConditions['EDIT_DETAILS_TRIGGER']) {
			editDetailsTrigger = getEditDetailsTrigger(rfqItem)
		}

		if (Object.values(displayTriggerConditions).some(Boolean)) {
			return (
				<>
					{notificationTrigger}
					{rfqEscalationTrigger}
					{editDetailsTrigger}
				</>
			)
		}
		return null
	}

	const getActionItems = (rfqItem, displayGap) => {
		const reactivateButtonAllowedStatuses = ['RFQ:LOST', 'RFQ:QUOTES:LOST']

		const displayDeleteButton = ![
			'OPS:SETTLEMENT:PENDING',
			'OPS:CLOSURE:PENDING',
			'OPS:POLICY:PENDING',
			'AGENT:SETTLEMENT:FAILED',
			...reactivateButtonAllowedStatuses,
		].includes(rfqItem.Status)

		const displayReactivateButton = ['RFQ:LOST', 'RFQ:QUOTES:LOST'].includes(rfqItem.Status)

		const displayMenuContainer = !displayReactivateButton

		let deleteButton = null
		if (displayDeleteButton) {
			deleteButton = getDeleteButton(rfqItem, displayGap)
		}

		let reactivateButton = null
		if (displayReactivateButton) {
			reactivateButton = getReactivateButton(rfqItem, displayGap)
		}

		let menuItemContainer = null
		if (displayMenuContainer) {
			menuItemContainer = getMenuItemContainer(rfqItem, displayGap)
		}

		return (
			<div className="d-flex align-items-center">
				{deleteButton}
				{reactivateButton}
				{menuItemContainer && (
					<Tooltip style={{ width: '30px' }} theme="light" trigger="click" html={menuItemContainer}>
						<Icon
							bgColor="white"
							name="verticalMenuDots"
							className={'me-2'}
							onClick={e => {
								e.stopPropagation()
							}}
						/>
					</Tooltip>
				)}
			</div>
		)
	}

	const isRfqSelected = rfqId => selectedRfqs.includes(rfqId)

	const handleSelectRfq = (checked, rfqId) => {
		if (checked) {
			setSelectedRfqs([...selectedRfqs, rfqId])
		} else {
			setSelectedRfqs(selectedRfqs.filter(selectedRfqId => selectedRfqId !== rfqId))
		}
	}

	const onRfqItemClick = (rfqItem, index) => {
		if (showReassignmentSection) {
			const isRequestAlreadyRaised = rfqItem?.LastReassignment?.Status === 'PENDING'
			if (isRequestAlreadyRaised) {
				toast.error('Reassignment request has already been raised')
			} else {
				const isChecked = isRfqSelected(rfqItem.id)
				handleSelectRfq(!isChecked, rfqItem?.id)
			}
		} else {
			handleRfqItemClick(rfqItem, index + 1)
		}
	}

	const getReassignedByLabel = rfqItem => {
		if (rfqItem?.LastReassignment?.Status === 'APPROVED') {
			return (
				<Text className="mt-1 fst-italic" color="#EA337A" fontSize="14px" fontWeight="500">
					Reassigned by: {rfqItem?.LastReassignment?.From?.email}
				</Text>
			)
		}
		return null
	}

	const getTableRow = (rfqItem, index) => {
		let lowestPremiumContainer
		if (rfqItem?.lowestPremium) {
			lowestPremiumContainer = (
				<div className="text-center w-100">
					<Text style={{ minWidth: '70px' }} fontSize="16px" fontWeight="600">
						{getLowestPremium(rfqItem)}
					</Text>
				</div>
			)
		}
		const isRequestAlreadyRaised = rfqItem?.LastReassignment?.Status === 'PENDING'
		let tableItem = {
			...rfqItem,
			Name: (
				<div className="d-flex flex-column">
					<Text fontWeight="700">{rfqItem.Name}</Text>
					{getReassignedByLabel(rfqItem)}
				</div>
			),
			createdAt: getDateString(rfqItem?.createdAt),
			handleRowClick: () => onRfqItemClick(rfqItem, index),
			key: rfqItem.id,
			lowestPremium: lowestPremiumContainer,
			responses: getResponsesContainer(rfqItem?.Quotes),
			Status: (
				<div className="d-flex">
					{getStatusTag(rfqItem)}
					{getActionItems(rfqItem)}
				</div>
			),
			tableRowStyles:
				isRequestAlreadyRaised && showReassignmentSection
					? { cursor: 'not-allowed', background: '#f6f6f6' }
					: {},
		}
		if (showReassignmentSection) {
			return {
				checkbox: (
					<PseudoCheckbox isSelected={isRfqSelected(rfqItem?.id)}>
						{isRfqSelected(rfqItem?.id) && '✓'}
					</PseudoCheckbox>
				),
				...tableItem,
			}
		}
		return tableItem
	}

	const getCardLayout = (rfqItem, index) => {
		return (
			<div
				onClick={() => onRfqItemClick(rfqItem, index)}
				className="d-flex align-items-center w-100"
			>
				{showReassignmentSection && (
					<PseudoCheckbox className="me-2" isSelected={isRfqSelected(rfqItem?.id)}>
						{isRfqSelected(rfqItem?.id) && '✓'}
					</PseudoCheckbox>
				)}
				<RFQCard rfqData={rfqItem} extraItems={getActionItems(rfqItem, true)} key={rfqItem.id} />
			</div>
		)
	}

	const startNewQuote = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'CLEAR_DATA',
			})
			resolve()
		})
		navigateTo('/new-quote')
	}

	const NoResultsFoundContainer = (
		<div className="bg-white d-flex" style={emptySearchStyle}>
			<Text color={theme.colors.gray2} fontSize="14px" fontWeight="400">
				No results found :(
			</Text>
		</div>
	)

	let rfqContainer, emptyResultContainer
	const renderLayoutFn = isMobileView ? getCardLayout : getTableRow

	let rfqDataItems
	if (searchResults && searchResults.length > 0) {
		rfqDataItems = searchResults.map((rfqItem, index) => renderLayoutFn(rfqItem, index))
	} else if (searchResults && searchResults.length === 0) {
		emptyResultContainer = NoResultsFoundContainer
	} else {
		rfqDataItems = leadList?.map((rfqItem, index) => renderLayoutFn(rfqItem, index))
	}

	const checkboxTableHeader = {
		key: 'checkbox',
		content: <HeaderContent width="60px" />,
	}

	rfqContainer = isMobileView ? (
		rfqDataItems
	) : (
		<TableComponent
			hideOnMobileIndex={2}
			tableHeaderItems={
				showReassignmentSection ? [checkboxTableHeader, ...headerItems] : headerItems
			}
			tableDataItems={rfqDataItems || []}
		/>
	)

	const filterItems = statusFilterMenuItems
	if (isAuthorizedTeamLeader && !statusFilterMenuItems.find(filter => filter.value === 'Lost')) {
		statusFilterMenuItems.push(lostFilter)
	}

	const activeSort =
		sortRfqBy === '' ? 'Latest First' : sortMenuItems.find(item => item.value === sortRfqBy)?.text
	const activeFilter = filterItems.find(item => item.value === filterBy)?.text

	const setSortValue = v => {
		setCurrentCursor('') // reset the cursor so that the RFQs are fetched again, from the beginning
		setLeadList(null)
		setSortRfqBy(v)
	}

	const setFilterValue = v => {
		setCurrentCursor('') // reset the cursor so that the RFQs are fetched again, from the beginning
		setLeadList(null)
		setFilterBy(v)
	}

	const updateRfqData = async rfqData => {
		let rfqListCopy = [...leadList]
		let rfqToUpdateIndex = rfqListCopy.findIndex(rfq => rfq.id === rfqData.id)
		rfqListCopy[rfqToUpdateIndex] = { ...rfqData }
		setLeadList(rfqListCopy)
	}

	const handleSearch = event => {
		setSearchQuery(event.target.value)
		if (timer) {
			clearTimeout(timer)
		}
		const toSetTimer = setTimeout(() => {
			searchRfq(event.target.value)
		}, 1000)
		setTimer(toSetTimer)
	}

	const searchRfq = async searchValue => {
		const parsedValue = formatSearchValue(searchValue)
		if (parsedValue) {
			searchRFQbyQuery(parsedValue).then(result => {
				if (result?.rfqs) {
					setSearchResults(result.rfqs)
				} else {
					setSearchResults([])
				}
			})
		} else {
			setSearchResults(null)
		}
	}

	const goBack = () => history.replace('/')

	const handleRfqReassign = () => {
		if (!showReassignmentSection) {
			setShowReassignmentSection(true)
		} else if (selectedRfqs.length > 0) {
			setShowReassignmentModal(true)
		}
	}

	const closeReassignmentModal = () => setShowReassignmentModal(false)

	const deactivateReassignmentMode = () => {
		setShowReassignmentSection(false)
		setSelectedRfqs([])
		closeReassignmentModal()
	}

	const removeSelectedLostRfq = () => {
		const filteredRfqList = leadList.filter(rfq => rfq.id !== selectedRfq?.id)
		setLeadList([...filteredRfqList])
	}

	const showReasignmentButton = activeFilter !== 'Lost'

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navLeftContent}
				onGoBack={goBack}
				rightContent={navRightContent}
			/>

			{showDeleteModal && (
				<DeleteRFQModal
					show={true}
					handleClose={() => {
						setSelectedRfq('')
						setShowDeleteModal(false)
					}}
					rfqData={selectedRfq}
					resetDeleteRFQId={() => setSelectedRfq('')}
					refetchRFQs={refreshRfqList}
				/>
			)}

			{showReassignmentModal && (
				<ReassignRfqModal
					handleClose={closeReassignmentModal}
					selectedRfqs={selectedRfqs}
					deactivateReassignmentMode={deactivateReassignmentMode}
				/>
			)}

			{showEditDetailsModal && (
				<EditDetailsModal
					show={true}
					handleClose={() => {
						setSelectedRfq(null)
						setShowEditDetailsModal(false)
					}}
					selectedRfq={selectedRfq}
					updateRfqData={updateRfqData} // update changes in state
				/>
			)}

			{showTnCModal && (
				<SendTnCModal
					show={true}
					handleClose={() => {
						setSelectedRfq('')
						setShowTnCModal(false)
					}}
					clientEmail={clientEmail}
					rfqData={selectedRfq}
				/>
			)}

			{showNotifTriggerModal && (
				<NotificationTriggerModal
					handleClose={() => {
						// setSelectedRfq('')
						setShowNotifTriggerModal(false)
						setClientEmail('')
					}}
					rfqData={selectedRfq}
					triggerTnCModal={clientEmail => {
						setShowNotifTriggerModal(false)
						setShowTnCModal(true)
						setClientEmail(clientEmail)
					}}
				/>
			)}

			{showRequestQuotesModal && (
				<RequestForQuotationModal
					handleClose={() => {
						setSelectedRfq('')
						setShowRequestQuotesModal(false)
					}}
					rfqData={selectedRfq}
				/>
			)}

			{showReactivationModal && (
				<ReactivateFlowModal
					selectedRfq={selectedRfq}
					removeSelectedLostRfq={removeSelectedLostRfq}
					handleClose={() => setShowReactivationModal(false)}
					deactivateReassignmentMode={deactivateReassignmentMode}
				/>
			)}

			<div className="mb-5 px-2" ref={table}>
				<CustomDiv className="d-flex justify-content-start align-items-center my-3">
					<FullWidth>
						{showReassignmentSection ? (
							<Text color={theme.colors.primary} fontSize="24px" fontWeight="700">
								Select the RFQ(s) you want to reassign:
							</Text>
						) : (
							<CustomInput
								value={searchQuery}
								onChange={handleSearch}
								placeholder="Search by Name, Number"
							/>
						)}
					</FullWidth>

					<ActionButtonSection className={`d-flex`}>
						<ReassignmentButtonSection className={`d-flex align-items-center`}>
							{showReasignmentButton && (
								<ReassignmentButton
									className="mx-2"
									reassignbutton
									primary
									disabled={showReassignmentSection && selectedRfqs.length === 0}
									label={showReassignmentSection ? 'Raise request to reassign' : 'Reassign RFQs'}
									style={{
										height: '48px',
										width: showReassignmentSection ? '250px' : '180px',
										borderRadius: '6px',
									}}
									onClick={handleRfqReassign}
									icon="reassignIcon"
									iconStyles={{ marginRight: '6px' }}
								/>
							)}
							{showReassignmentSection && (
								<ReassignmentButton
									bgColor="#DC2F21"
									label="Cancel"
									onClick={() => {
										setShowReassignmentSection(false)
										setSelectedRfqs([])
									}}
									style={{ height: '48px', borderRadius: '6px' }}
								/>
							)}
						</ReassignmentButtonSection>
						<FilterDiv className="d-flex align-items-center w-100">
							<FilterButton
								filterText="Sort by"
								disableLeftMargin
								selectedOption={activeSort}
								setFilterValue={setSortValue}
								menuItems={sortMenuItems}
							/>
							<FilterButton
								filterText="Filter by"
								disableLeftMargin
								selectedOption={activeFilter}
								setFilterValue={setFilterValue}
								menuItems={filterItems}
							/>
						</FilterDiv>
					</ActionButtonSection>
				</CustomDiv>

				{rfqContainer}

				{emptyResultContainer}

				{loadingMore && (
					<div className="d-flex flex-column align-items-center">
						<Text className="mb-3">Loading more RFQs...</Text>
						<Spinner animation="border" />
					</div>
				)}
			</div>
			<ToastContainer />
		</PageContentAndNavbarWrapper>
	)
}

const RFQInfoCol = ({ topText, bottomElement, col }) => {
	let bottomContainer = bottomElement
	if (typeof bottomElement === 'string') {
		bottomContainer = (
			<Text className="bottom-text" color={theme.colors.primary} fontSize="14px" fontWeight="700">
				{bottomElement}
			</Text>
		)
	}
	return (
		<Col xs={col} className="my-2">
			<Text style={{ opacity: 0.4 }} color={theme.colors.primary} fontSize="9px" fontWeight="700">
				{topText}
			</Text>
			{bottomContainer}
		</Col>
	)
}

const RFQCard = ({ rfqData, extraItems }) => {
	return (
		<RFQCardWrapper key={rfqData?.id} className="w-100 my-2 mb-3 d-flex flex-column">
			<Row>
				<RFQInfoCol topText="CLIENT NAME" bottomElement={rfqData?.Name} />
				{rfqData?.LastReassignment?.Status === 'APPROVED' && (
					<Text className="mb-1 fst-italic" color="#EA337A" fontSize="14px" fontWeight="500">
						Reassigned by: {rfqData?.LastReassignment?.From?.email}
					</Text>
				)}
			</Row>
			<Row className="w-100">
				<RFQInfoCol col={5} topText="NUMBER" bottomElement={rfqData?.Phone} />
				<RFQInfoCol
					col={7}
					topText="LOWEST QUOTED PREMIUM"
					bottomElement={getLowestPremium(rfqData)}
				/>
			</Row>
			<Row className="w-100">
				<RFQInfoCol
					col={5}
					topText="RFQ CREATION DATE"
					bottomElement={getDateString(rfqData?.createdAt)}
				/>
				<RFQInfoCol
					col={7}
					topText="RESPONSES"
					bottomElement={getResponsesContainer(rfqData?.Quotes)}
				/>
			</Row>
			<Row className="pt-3 d-flex align-items-center">
				<Col xs={8}>{getStatusTag(rfqData)}</Col>
				<Col xs={4} className="d-flex align-items-center justify-content-between">
					{extraItems}
					<Icon name="rightArrow" color={theme.colors.gray3} />
				</Col>
			</Row>
		</RFQCardWrapper>
	)
}

// styles

const RFQCardWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	padding: 18px 14px;
`

const FullWidth = styled.div`
	width: 60% !important;
	@media (max-width: 768px) {
		width: 100% !important;
	}
`

const CustomInput = styled(Input)`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: none;
	width: 100%;
	&:focus {
		border: none;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	}
	@media (max-width: 768px) {
		margin-bottom: 1rem;
	}
`

const CustomDiv = styled.div`
	@media (max-width: 768px) {
		flex-direction: column !important;
		align-items: flex-start !important;
	}
`

const FilterDiv = styled.div`
	@media (max-width: 768px) {
		width: 100%;
	}
`

const ActionButtonSection = styled.div`
	@media (max-width: 816px) {
		width: 100%;
		justify-content: start;
		flex-direction: column !important;
	}
`

const ReassignmentButtonSection = styled.div`
	@media (max-width: 816px) {
		width: 100%;
		margin-top: 0.6rem;
		margin-bottom: 0.6rem;
	}
`

const ReassignmentButton = styled(Button)`
	@media (min-width: 816px) {
		width: ${props => (props.reassignbutton ? '170px' : 'auto')};
	}
	@media (max-width: 816px) {
		height: 40px !important;
	}
`
