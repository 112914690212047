import { theme, Text, Button, Icon, Input, Modal } from 'verak-ui'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState, useEffect, useContext, useRef } from 'react'
import { nanoid } from 'nanoid'
import { Row, Col } from 'react-bootstrap'

import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import TableComponent from '../../components/Table'
import { HeaderContent } from '../SQS/QuoteInProgressComponents/quoteInProgressUtils'
import MakeRfqModal from './MakeRfqModal'

import { useFetch } from '../../api/apihook'
import { getDateString } from '../../utilities/sqsDateUtils'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { getRedirectLink, checkIfRenewalsFlow } from '../../utilities/renewalsUtils'

import { ComponentContext } from '../../context/ComponentProvider'
import { Context } from '../../context/Provider'

const tableHeaderItems = [
	{
		key: 'contactName',
		content: <HeaderContent icon="buildingSm" text="Client Name" />,
		width: '200px',
	},
	{
		key: 'accountName',
		content: <HeaderContent icon="buildingSm" text="Legal Entity" />,
		width: '250px',
	},
	{ key: 'updatedAt', content: <HeaderContent icon="lastUpdatedSm" text="Last Updated" /> },
	{ key: 'Phone', content: <HeaderContent icon="phoneSm" text="Phone" /> },
	{ key: 'PolicyType', content: <HeaderContent icon="globeSm" text="Policy Type" /> },
	{
		key: 'actionItems',
		content: <HeaderContent icon="" text={<div style={{ width: '80' }}></div>} />,
	},
]

export default function ListDraftRfqs() {
	const isRenewalsFlow = checkIfRenewalsFlow()
	const [searchQuery, setSearchQuery] = useState('')
	const [searchResults, setSearchResults] = useState(null)
	const [timer, setTimer] = useState(null)

	const [draftRfqs, setDraftRfqs] = useState([])
	const [, dispatch] = useContext(Context)

	const [currentCursor, setCurrentCursor] = useState('')
	const [hasMore, setHasMore] = useState(true)
	const [loadingMore, setLoadingMore] = useState(false)

	const [showMakeRfqModal, setShowMakeRfqModal] = useState(false)
	const [selectedDraftRfq, setSelectedDraftRfq] = useState(null)

	const [draftDeleteId, setDraftDeleteId] = useState('')
	const [showDeleteDraftRfqModal, setShowDeleteDraftRfqModal] = useState(false)

	const table = useRef()
	const {
		getDraftRFQList,
		mergeRiskLocations,
		searchDraftRfqByQuery,
		getDraftRFQ,
		getRenewalsPolicyData,
	} = useFetch()

	const history = useHistory()

	const { width: viewportWidth } = useWindowDimensions()
	const isMobileView = viewportWidth <= 816

	const goBack = () => {
		history.replace('/')
	}

	const navigateToNewDraftRfq = () => {
		history.push(getRedirectLink(isRenewalsFlow, '/start-draft'))
	}

	const { setRenderSidePanel } = useContext(ComponentContext)

	useEffect(() => {
		setRenderSidePanel(false)
	}, []) // eslint-disable-line

	const fetchRFQs = (list, cursor, retainPrevDraftRfqs = true) => {
		getDraftRFQList(cursor, isRenewalsFlow ? 'RENEWAL' : 'NEW').then(r => {
			setDraftRfqs(r.draftRfqs)
			setHasMore(r.hasMore)
			setCurrentCursor(r.cursor)

			if (list && list.length > 0 && retainPrevDraftRfqs) {
				setDraftRfqs(p => [...p, ...r.draftRfqs])
			} else {
				setDraftRfqs(r.draftRfqs)
			}
		})
	}

	useEffect(() => {
		fetchRFQs()
	}, []) // eslint-disable-line

	const handleScroll = () => {
		if (
			window.scrollY > 0 &&
			window.innerHeight + window.scrollY > table?.current?.offsetHeight &&
			!loadingMore &&
			hasMore
		) {
			fetchRFQs(draftRfqs, currentCursor)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [draftRfqs?.length, loadingMore, currentCursor]) // eslint-disable-line

	const fetchPrevPolicyData = async phone => {
		const data = await getRenewalsPolicyData(phone, 'phone')
		const { Data, Quote } = data?.[0]
		return {
			CoverDuration: Data?.Cover?.Duration || '',
			CoverStartDate: Data?.Cover?.StartDate || '',
			Quote: {
				Insurer: Quote?.Insurer || '',
				TotalPremium: Quote?.TotalPremium || '',
				Addons: Quote?.Addons || [],
				IsPremiumDiscounted: Quote?.isPremiumDiscounted || false,
				IsCalculatorSupported: Quote?.isCalculatorSupported || false,
			},
		}
	}

	const proceedToStartRfq = async data => {
		dispatch({
			type: 'SET_POLICY_ADD_ONS',
			data: {
				PolicyAddons: {},
			},
		})

		dispatch({
			type: 'SET_DRAFT_RFQ',
			data: data,
		})

		const toMerge = {
			...data.Data,
			quoteId: '',
			Type: isRenewalsFlow ? 'RENEWAL' : 'NEW',
		}
		toMerge.RiskLocations.forEach((item, index) => {
			item.SubType = 'Single'
			item.ID = nanoid()
			item.GroupHash = index
		})
		let mergedData = mergeRiskLocations(toMerge)
		mergedData.RiskLocations.forEach(item => {
			item.SubType = ''
		})

		dispatch({
			type: 'RESUME_QUOTE',
			data: {
				...mergedData,
				NumRiskLoc: data.Data.RiskLocations.length,
				Type: isRenewalsFlow ? 'RENEWAL' : 'NEW',
			},
		})
		dispatch({
			type: 'SET_CLIENT_DETAILS',
			data: {
				ProposerName: data?.Contact.Name,
				CompanyName: data?.Account.Name,
				Phone: data?.Contact?.Phone,
			},
		})

		if (isRenewalsFlow && data?.Contact?.Phone) {
			const prevPolicyData = await fetchPrevPolicyData(data?.Contact?.Phone)
			dispatch({
				type: 'SET_PREV_POLICY_DATA',
				data: { ...prevPolicyData },
			})
		}

		const locationState = {
			accountId: data?.Account.UID,
			contactId: data?.Contact.UID,
			leadSource: data?.Account?.LeadSource,
			draftRFQ: data,
		}

		history.push('/new-quote/select-policy-type', locationState)
	}

	const navigateToSavedDrafts = () => {
		history.push(getRedirectLink(isRenewalsFlow, '/saved-drafts'))
	}

	const navLeftContent = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Draft RFQs
		</Text>
	)

	const navRightContent = (
		<div className="d-flex align-items-center">
			<Button
				className="me-2"
				bgColor={theme.colors.white}
				label="View Saved Drafts"
				icon="saveQuote"
				iconColor={theme.colors.primary}
				iconStyles={{ marginRight: '0.5rem' }}
				style={{
					color: theme.colors.primary,
					border: `1px solid ${theme.colors.primary}`,
					borderRadius: '5rem',
				}}
				onClick={navigateToSavedDrafts}
			/>
			<Button
				bgColor={theme.colors.secondary}
				icon="plusIcon"
				iconColor={'white'}
				label={'Create a Draft RFQ'}
				style={{ borderRadius: '5rem' }}
				iconStyles={{ marginRight: '0.5rem' }}
				onClick={navigateToNewDraftRfq}
			/>
		</div>
	)

	const proceedToEdit = async draftRfqItem => {
		const result = await getDraftRFQ(draftRfqItem._id)
		const phone = draftRfqItem?.Contact?.Phone || ''
		let prevPolicyData = {}
		if (phone) {
			prevPolicyData = await fetchPrevPolicyData(phone)
		}
		history.push(getRedirectLink(isRenewalsFlow, '/new-draft'), {
			...result,
			_id: draftRfqItem._id,
			PreviousPolicyDetails: prevPolicyData,
		})
	}

	const handleStartRfq = async () => {
		const result = await getDraftRFQ(selectedDraftRfq._id)
		proceedToStartRfq({ ...result, _id: selectedDraftRfq._id })
		setShowMakeRfqModal(false)
	}

	const handleShowMakeRfqModal = draftRfqItem => {
		setSelectedDraftRfq(draftRfqItem)
		setShowMakeRfqModal(true)
	}

	const deleteDraft = draftRfqId => {
		setDraftDeleteId(draftRfqId)
		setShowDeleteDraftRfqModal(true)
	}

	const onDraftRfqDelete = async refetch => {
		setShowDeleteDraftRfqModal(false)
		setDraftDeleteId('')
		// refetch draft rfqs
		if (refetch) {
			fetchRFQs(draftRfqs, currentCursor, false)
		}
	}

	const getActionItems = draftRfqItem => {
		return (
			<div className="d-flex align-items-center">
				<Icon
					className={'me-4'}
					color={theme.colors.primary}
					name="editPencilSquare"
					style={{ cursor: 'pointer' }}
					onClick={() => proceedToEdit(draftRfqItem)}
				/>
				<Icon
					color={theme.colors.red}
					name="deleteTrash"
					style={{ cursor: 'pointer' }}
					onClick={() => deleteDraft(draftRfqItem._id)}
				/>
				<Button
					label={'Make RFQ'}
					bgColor={theme.colors.white}
					textColor={theme.colors.secondary}
					iconColor={theme.colors.secondary}
					icon="rightArrow"
					iconAlign="right"
					onClick={() => handleShowMakeRfqModal(draftRfqItem)}
				/>
			</div>
		)
	}

	const getTableRow = (draftRfqItem, index) => {
		return {
			...draftRfqItem,
			contactName: draftRfqItem.Contact.Name,
			accountName: draftRfqItem.Account.Name,
			Phone: draftRfqItem.Contact.Phone,
			key: draftRfqItem._id,
			updatedAt: getDateString(draftRfqItem.updatedAt),
			actionItems: getActionItems(draftRfqItem),
		}
	}

	const getCardLayout = draftRfqItem => (
		<DraftRfqCard
			draftRfq={draftRfqItem}
			key={draftRfqItem._id}
			onEditClick={() => proceedToEdit(draftRfqItem)}
			onDeleteClick={() => deleteDraft(draftRfqItem._id)}
			onMakeRfqClick={() => handleShowMakeRfqModal(draftRfqItem)}
		/>
	)

	let draftRfqsContainer, emptyResultContainer

	let draftRfqItems
	const renderLayoutFn = isMobileView ? getCardLayout : getTableRow

	if (searchResults && searchResults.length > 0) {
		draftRfqItems = searchResults.map(draftRfqItem => renderLayoutFn(draftRfqItem))
	} else if (searchResults && searchResults.length === 0) {
		emptyResultContainer = (
			<Text color={theme.colors.gray2} fontSize="14px">
				No results found
			</Text>
		)
	} else {
		draftRfqItems = draftRfqs.map(draftRfqItem => renderLayoutFn(draftRfqItem))
	}

	draftRfqsContainer = isMobileView ? (
		draftRfqItems
	) : (
		<TableComponent
			hideOnMobileIndex={2}
			tableHeaderItems={tableHeaderItems}
			tableDataItems={draftRfqItems || []}
			tableDataRowCls="draft-rfq-row-item"
		/>
	)

	const handleSearch = event => {
		setSearchQuery(event.target.value)
		if (timer) {
			clearTimeout(timer)
		}
		const toSetTimer = setTimeout(() => {
			searchRfq(event.target.value)
		}, 1000)
		setTimer(toSetTimer)
	}

	const searchRfq = searchValue => {
		if (searchValue) {
			const searchType = isRenewalsFlow ? 'RENEWAL' : 'NEW'
			searchDraftRfqByQuery(searchValue, searchType).then(result => {
				if (result?.draftRfqs) {
					setSearchResults(result.draftRfqs)
				} else {
					setSearchResults([])
				}
			})
		} else {
			setSearchResults(null)
		}
	}

	return (
		<DPageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navLeftContent}
				onGoBack={goBack}
				rightContent={navRightContent}
			/>
			<div className="container p-3 mb-5" ref={table}>
				<Section className="d-flex justify-content-start align-items-center my-1 mb-3">
					<FullWidth>
						<CustomInput
							value={searchQuery}
							onChange={handleSearch}
							placeholder="Enter proposer name or company name or UID"
						/>
					</FullWidth>
				</Section>

				<Text
					className="mb-2"
					color={theme.colors.gray3}
					fontSize="14px"
					mobileFontSize="12px"
					fontWeight="500"
				>
					Select a draft to continue with the final RFQ
				</Text>

				{draftRfqsContainer}

				{emptyResultContainer}

				<MakeRfqModal
					showModal={showMakeRfqModal}
					closeModal={() => setShowMakeRfqModal(false)}
					createRfq={handleStartRfq}
				/>

				<DeleteDraftRfqModal
					show={showDeleteDraftRfqModal}
					handleClose={(refetch = false) => onDraftRfqDelete(refetch)}
					draftDeleteId={draftDeleteId}
				/>
			</div>
		</DPageContentAndNavbarWrapper>
	)
}

const CardInfoCol = ({ topText, bottomText }) => {
	return (
		<Col className="p-1 px-2 m-1">
			<Text
				style={{ textTransform: 'uppercase' }}
				color="rgba(0,0,0,0.4)"
				fontWeight="700"
				fontSize="10px"
			>
				{topText}
			</Text>
			<Text fontWeight="500" fontSize="14px" color={theme.colors.black}>
				{bottomText}
			</Text>
		</Col>
	)
}

const DraftRfqCard = ({ draftRfq, onEditClick, onDeleteClick, onMakeRfqClick }) => {
	return (
		<DraftRfqCardWrapper className="my-3 d-flex flex-column">
			<DraftRfqCardTopSection>
				<Row>
					<CardInfoCol topText="CLIENT NAME" bottomText={draftRfq.Contact.Name} />
					<CardInfoCol topText="PHONE NUMBER" bottomText={draftRfq.Contact.Phone} />
				</Row>
				<Row>
					<CardInfoCol topText="LEGAL ENTITY" bottomText={draftRfq.Account.Name} />
					<CardInfoCol topText="POLICY TYPE" bottomText="Vyapar Suraksha" />
				</Row>
				<Row>
					<CardInfoCol topText="LAST UPDATED" bottomText={getDateString(draftRfq.updatedAt)} />
				</Row>
			</DraftRfqCardTopSection>
			<DraftRfqCardBottomSection className="d-flex align-items-center justify-content-around">
				<Button
					bgColor="white"
					textColor={theme.colors.primary}
					iconColor={theme.colors.primary}
					icon="editPencilSquare"
					label="Edit"
					iconAlign="right"
					iconStyles={{ marginLeft: '.4rem' }}
					onClick={onEditClick}
				/>
				<Button
					bgColor="white"
					textColor="#DD5853"
					iconColor="#DD5853"
					icon="deleteTrash"
					label="Delete"
					iconAlign="right"
					iconStyles={{ marginLeft: '.4rem' }}
					onClick={onDeleteClick}
				/>
				<Button
					bgColor="white"
					textColor={theme.colors.secondary}
					iconColor={theme.colors.secondary}
					icon="rightArrow"
					label="Make RFQ"
					iconAlign="right"
					style={{ padding: '0px' }}
					onClick={onMakeRfqClick}
				/>
			</DraftRfqCardBottomSection>
		</DraftRfqCardWrapper>
	)
}

const DeleteDraftRfqModal = ({ show, handleClose, draftDeleteId }) => {
	const { deleteDraftRfq } = useFetch()
	const [deleteReason, setDeleteReason] = useState('')

	const disableProceed = !deleteReason

	const onDeleteClick = async () => {
		await deleteDraftRfq(draftDeleteId, deleteReason)
		handleClose(true) // refetch draft rfqs on modal close
	}

	const titleElement = (
		<Text color={theme.colors.primary} fontSize="14px" fontWeight="600">
			DELETE DRAFT RFQ
		</Text>
	)

	const footerElement = (
		<div className="p-3 pt-0 w-100">
			<Button
				disabled={disableProceed}
				applyDisableFilter={disableProceed}
				onClick={onDeleteClick}
				className="w-100 p-4"
				label="Remove"
				primary
			/>
		</div>
	)
	return (
		<Modal title={titleElement} footer={footerElement} show={show} handleClose={handleClose}>
			<Text color={theme.colors.gray2} fontSize="14px" fontWeight="400">
				Please enter why are you deleting this Draft RFQ
				<span style={{ color: '#eb5757' }}>*</span>
			</Text>
			<TextArea
				className="mt-2"
				placeholder="Enter here"
				value={deleteReason}
				onChange={e => setDeleteReason(e.target.value)}
			/>
		</Modal>
	)
}

// styles

const DraftRfqCardTopSection = styled.div`
	height: 70%;
	border-bottom: 1px solid #e5eaec;
	padding: 14px;
`

const DraftRfqCardBottomSection = styled.div`
	padding: 12px;
`

const DraftRfqCardWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
`

const FullWidth = styled.div`
	width: 100% !important;
`

const CustomInput = styled(Input)`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: none;
	width: 100%;
	&:focus {
		border: none;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	}
`

const Section = styled.div`
	@media (max-width: 768px) {
		flex-direction: column !important;
		align-items: flex-start !important;
		margin-top: 0.1rem !important;
		margin-bottom: 0.5rem !important;
	}
`

const TextArea = styled.textarea`
	border-radius: 8px;
	padding: 10px;
	border: 0.5px solid ${theme.colors.primary};
	width: 100%;
	outline: none;
	font-size: 14px;
	::placeholder,
	&::-webkit-input-placeholder {
		color: rgba(141, 141, 141, 1);
		font-size: 14px;
	}
`

const DPageContentAndNavbarWrapper = styled(PageContentAndNavbarWrapper)`
	tr.draft-rfq-row-item {
		cursor: default;
		&:hover {
			background: #fff;
		}
	}
`
