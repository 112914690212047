import { useState } from 'react'
import { Text, theme } from 'verak-ui'
import styled, { css } from 'styled-components'

import CaseStudy from './CaseStudy'
import NearestCustomer from './NearestCustomer'
import PremiumCalculator from './PremiumCalculator'
import IncidentStatistics from './IncidentStatistics'

const tabs = {
	CASE_STUDY: 'CASE_STUDY',
	OUR_CUSTOMERS: 'OUR_CUSTOMERS',
	PREMIUM_CALCULATOR: 'PREMIUM_CALCULATOR',
	STATISTICS: 'STATISTICS',
}

export default function MultiNudgeContainer({
	caseStudyProps,
	nearestCustomerProps,
	premiumCalculatorProps,
	incidentStatsProps,
}) {
	const [activeTab, setActiveTab] = useState(tabs.CASE_STUDY)

	const isTabActive = tab => activeTab === tab
	const isSafetyPlan = premiumCalculatorProps?.safetyToggle?.value

	const tabContents = {
		CASE_STUDY: <CaseStudy {...caseStudyProps} />,
		OUR_CUSTOMERS: <NearestCustomer {...nearestCustomerProps} />,
		PREMIUM_CALCULATOR: <PremiumCalculator {...premiumCalculatorProps} />,
		STATISTICS: <IncidentStatistics {...incidentStatsProps} />,
	}

	const { totalSI, provisionalQuoteData } = premiumCalculatorProps
	const isSumInsuredInput = Boolean(totalSI)
	const isProvisionalDataPresent = Boolean(
		provisionalQuoteData.provisionalSI && provisionalQuoteData.provisionalPremium
	)

	let premiumCalcTabTitle = isSafetyPlan ? 'Safety Plan' : 'Est. Premium'
	if (isProvisionalDataPresent && !isSumInsuredInput) {
		premiumCalcTabTitle = 'Prov. Quote'
	}

	return (
		<MultiNudgeContainerWrapper>
			<NudgesTabHeader>
				<TabHeader
					title="Case study"
					active={isTabActive(tabs.CASE_STUDY)}
					onChange={() => setActiveTab(tabs.CASE_STUDY)}
				/>
				<TabHeader
					title="Our customers"
					active={isTabActive(tabs.OUR_CUSTOMERS)}
					onChange={() => setActiveTab(tabs.OUR_CUSTOMERS)}
				/>
				<TabHeader
					title={premiumCalcTabTitle}
					active={isTabActive(tabs.PREMIUM_CALCULATOR)}
					onChange={() => setActiveTab(tabs.PREMIUM_CALCULATOR)}
				/>
				<TabHeader
					title="Statistics"
					active={isTabActive(tabs.STATISTICS)}
					onChange={() => setActiveTab(tabs.STATISTICS)}
				/>
			</NudgesTabHeader>
			<TabContentContainer>{tabContents[activeTab]}</TabContentContainer>
		</MultiNudgeContainerWrapper>
	)
}

const TabHeader = ({ active, onChange, title }) => {
	return (
		<TabHeaderWrapper active={active} onClick={onChange}>
			<Text color={theme.colors.primary} fontSize="12px" fontWeight="700">
				{title}
			</Text>
		</TabHeaderWrapper>
	)
}

const TabContentContainer = styled.div`
	border: solid 2px ${theme.colors.primary};
	border-radius: 8px;
	background: white;
	height: 150px;
`

const MultiNudgeContainerWrapper = styled.div`
	background: transparent;
	height: 190px;
	width: 100%;
	min-width: 450px;
	max-width: 500px;
	border-radius: 8px;
`

const NudgesTabHeader = styled.div`
	background: white;
	display: flex;
	align-items: center;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	justify-content: center;
	margin-top: 4px;
`

const SelectedTab = css`
	border-left: solid 2px ${theme.colors.primary};
	border-right: solid 2px ${theme.colors.primary};
	border-top: solid 2px ${theme.colors.primary};
	background: linear-gradient(180deg, #c8d6db 0%, #ffffff 100%);
`

const TabHeaderWrapper = styled.div`
	padding: 8px 12px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	user-select: none;
	margin: 0 4px;
	${({ active }) => (active ? SelectedTab : null)};
`
