import styled, { css } from 'styled-components'
import { Input, Text, theme } from 'verak-ui'

const GridContainer = styled.div`
	display: grid;
	place-items: center stretch;
	grid-template-columns: ${({ removeButtonCol, columns }) =>
		columns ? columns : removeButtonCol ? '1fr 1fr 0.1fr' : 'repeat(2, 1fr)'};
	gap: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		&:not(:last-of-type) {
			padding-bottom: 1rem;
			border-bottom: 1px solid ${theme.colors.primary};
		}
	}
`

const RadioDiv = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const SearchableSelectWrapper = styled.div`
	.select-search {
		width: 100%;
	}
`

const SelectWrapper = styled.div`
	width: 100%;
`

const CustomInput = styled(Input)`
	width: 100%;
`

const compulsoryMarker = css`
	&::after {
		content: '*';
		color: ${theme.colors.red};
	}
`
const SearchableSelectLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: #6d6d6d;
	font-weight: 500;
	margin-bottom: 0.5rem;
	${({ optional }) => (!optional ? compulsoryMarker : null)};
`

const SelectLabel = styled(SearchableSelectLabel)``

const RadioGroup = styled.div`
	display: flex;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const SharedInputStyles = {
	width: '100%',
	// background: "#fff"
}
const SharedRadioStyles = {
	margin: '1rem 0',
}

export {
	GridContainer,
	RadioDiv,
	SearchableSelectWrapper,
	CustomInput,
	SearchableSelectLabel,
	SelectWrapper,
	SelectLabel,
	RadioGroup,
	SharedInputStyles,
	SharedRadioStyles,
}
