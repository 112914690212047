import styled from 'styled-components'
import {
	Button,
	DatePicker,
	Radio,
	Space,
	Text,
	theme,
	Icon,
	Divider,
	SearchableSelect,
	HiddenFileInput,
} from 'verak-ui'
import { insurerOps } from './options'
import {
	GridContainer,
	RadioGroup,
	SearchableSelectLabel,
	SearchableSelectWrapper,
	SharedInputStyles,
	SharedRadioStyles,
} from './SharedStyles'
import { useRef } from 'react'
import { useFetch } from '../../../../api/apihook'
import {
	parseLinkFromSignedUrl,
	imageDeleteConfirmOptions,
} from '../../../../utilities/imageUploadUtilities'
import { parseFormattedDate } from '../../../../utilities/getCorrectDateFormat'

import Swal from 'sweetalert2'
import { useLoadingOverlay } from '../../../../components/LoadingOverlay'

// TODO: revisit this datepicker style
const DatePickerWrapper = styled.div`
	.react-datepicker__input-container input {
		width: 100% !important;
		height: 48px;
	}
`

const DatePickerLabel = styled(SearchableSelectLabel)``

const UploadDocContainer = styled.div`
	padding: 0.625rem;
	background-color: ${theme.colors.secondary3};
	border-radius: 0.5rem;
`

export default function AnotherInsuranceCompany({
	InsuredElsewhere,
	setInsuredElsewhere,
	otherInsurerData,
	setOtherInsurerData,
	policyPDF,
	setPolicyPDF,
	propertyType,
}) {
	const anotherIns = InsuredElsewhere === true
	const noAnotherIns = InsuredElsewhere === false

	const { setIsLoading, setLoadingText } = useLoadingOverlay()

	const fetch = useFetch()

	const fileInput = useRef()

	const triggerFileInput = () => fileInput?.current?.click()

	const setOtherInsurer = (name, value) => {
		setOtherInsurerData(prev => ({
			...prev,
			[name]: value,
		}))
	}

	const handleFileUpload = async files => {
		const file = files[0]
		setIsLoading(true)
		setLoadingText('Uploading Policy PDF...')
		const uploadUrl = await fetch.getSignedUrl('application/pdf')

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setPolicyPDF(s3Link)
	}

	const removePdf = () => {
		const optionsConfig = {
			...imageDeleteConfirmOptions,
			title: 'Do you want to delete this PDF?',
		}

		Swal.fire(optionsConfig).then(result => {
			if (result.isConfirmed) {
				setPolicyPDF('')
				Swal.fire('Your PDF has been deleted')
			}
		})
	}

	const renderOtherInusrers = (
		<GridContainer>
			<SearchableSelectWrapper>
				<SearchableSelectLabel compulsory>Select the Insurance company</SearchableSelectLabel>
				<SearchableSelect
					style={SharedInputStyles}
					options={insurerOps}
					value={otherInsurerData.OtherInsurer}
					onChange={val => setOtherInsurer('OtherInsurer', val)}
					search
					placeholder="Select"
				/>
			</SearchableSelectWrapper>
			<DatePickerWrapper>
				<DatePickerLabel className="mb-0" compulsory>
					Start date of coverage
				</DatePickerLabel>
				<DatePicker
					selected={parseFormattedDate(otherInsurerData.StartDate)}
					onChange={val => setOtherInsurer('StartDate', val)}
					maxDate={new Date()}
					placeholder="Select date"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
				/>
			</DatePickerWrapper>
		</GridContainer>
	)

	const uploadPolicyDoc = (
		<GridContainer className="mb-3">
			{!policyPDF && (
				<UploadDocContainer>
					<Text
						type="body2"
						color={theme.colors.secondary}
						fontWeight={700}
						style={{ lineHeight: '17.64px' }}
					>
						Upload your existing insurance policy (optional)
					</Text>
					<Space y={1} />
					<HiddenFileInput
						accept="application/pdf"
						ref={fileInput}
						onChange={e => handleFileUpload(e.target.files)}
					/>
					<Button
						secondary
						size="stretch"
						icon="uploadSm"
						iconStyles={{ marginRight: '0.25rem' }}
						label="Upload"
						onClick={triggerFileInput}
						style={{ backgroundColor: theme.colors.secondary3, border: '2px dashed #02475E' }}
					/>
				</UploadDocContainer>
			)}

			{policyPDF && (
				<>
					<UploadDocContainer>
						<div className="d-flex justify-content-between">
							<Text
								type="body2"
								color={theme.colors.primary}
								fontWeight={700}
								style={{ lineHeight: '17.64px' }}
							>
								Existing policy uploaded
							</Text>

							<Icon onClick={removePdf} name="remove" color={theme.colors.secondary} />
						</div>
						<Space y={1} />
						<a href={policyPDF} target="_blank" rel="noopener noreferrer">
							<Button
								secondary
								size="stretch"
								icon="infoIcon"
								iconColor={theme.colors.primary}
								iconStyles={{ marginRight: '0.25rem' }}
								label="Policy PDF"
								style={{ backgroundColor: theme.colors.secondary3, border: '2px dashed #02475E' }}
							/>
						</a>
					</UploadDocContainer>
				</>
			)}
		</GridContainer>
	)

	const askOtherInsurerData = anotherIns ? (
		<>
			{renderOtherInusrers}
			<Space y={1} />
			{uploadPolicyDoc}
		</>
	) : null

	return (
		<>
			<Text className="mt-3" type="body1" fontWeight={500} style={{ lineHeight: '22.68px' }}>
				Is this {propertyType} insured with another company for Fire & Allied Perils?
			</Text>
			<RadioGroup>
				<Radio
					labelStyle={SharedRadioStyles}
					value={anotherIns}
					onChange={() => setInsuredElsewhere(true)}
					secondary
					text="Yes"
					name="anotherinsurer"
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={noAnotherIns}
					onChange={() => setInsuredElsewhere(false)}
					secondary
					text="No"
					name="anotherinsurer"
				/>
			</RadioGroup>
			{askOtherInsurerData}
			<Divider top={0.5} bottom={1.5} />
		</>
	)
}
