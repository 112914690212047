import { nanoid } from 'nanoid'
import { useContext, useEffect, useState } from 'react'
import { useFetch } from '../../../../api/apihook'

import { Space, Text, Input, Radio, SearchableSelect, FreeTextToggle } from 'verak-ui'
import { useAPIStateContext } from '../../../../context/APIStateProvider'
import { Context } from '../../../../context/Provider'
import { filterArrObj } from '../../../../helpers/arrayOps'

import { otherUsageDefaults } from './options'
import { RenderRemoveButton, SpecifyMoreDetails } from './SharedComponents'
import {
	GridContainer,
	RadioDiv,
	SearchableSelectWrapper,
	SharedInputStyles,
	SharedRadioStyles,
} from './SharedStyles'

function initThirdParty(state, levelIdx, propertyType) {
	const { Type, levels, ThirdPartyUsage } = state.location || {}

	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let ThirdPartyUsageData = ThirdPartyUsage,
		levelsData = levels

	if (isPropertyBasement) {
		ThirdPartyUsageData = state.location?.[propertyType]?.ThirdPartyUsage || ''
		levelsData = state.location?.[propertyType]?.levels || []
	}
	const isMultiple = levelsData?.length
	if (isMultiple) {
		ThirdPartyUsageData = levelsData[levelIdx]?.ThirdPartyUsage || ''
	}

	// display empty default field if no third parties are present at the moment
	let thirdPartyActivities = [{ ...otherUsageDefaults }]
	if (ThirdPartyUsageData?.Details?.length) {
		thirdPartyActivities = ThirdPartyUsageData?.Details?.map(el => ({
			...el,
			id: nanoid(),
		}))
	}
	return {
		thirdPartyPresent: ThirdPartyUsageData ? ThirdPartyUsageData?.Present : '',
		thirdPartyActivitiesData: thirdPartyActivities,
	}
}

const ThirdPartyUsage = ({
	onSave,
	isActiveTab,
	disableProceed,
	levelIdx,
	propertyType,
	toggleNoticeModal,
}) => {
	const { cachedData } = useAPIStateContext()
	// const { getSubCategoryByRiskType } = useFetch()
	const [state] = useContext(Context)

	const { thirdPartyPresent, thirdPartyActivitiesData } = initThirdParty(
		state,
		levelIdx,
		propertyType
	)

	const [thirdParty, setThirdParty] = useState(thirdPartyPresent)
	const [thirdPartyActivities, setThirdPartyActivities] = useState(thirdPartyActivitiesData)

	const initializeSubCategories = () => {
		if (cachedData?.SubCategories?.required) {
			return cachedData?.SubCategories?.required?.map(({ Description }) => {
				return {
					name: Description,
					value: Description,
				}
			})
		}
	}

	const [subCategoryData, setSubCategoryData] = useState(initializeSubCategories())

	const [catLoading, setCatLoading] = useState(false)

	const initializeFreeTextResponse = () => {
		if (cachedData?.SubCategories?.required && thirdParty && thirdPartyActivities) {
			const thirdPartyPresent = cachedData?.SubCategories?.required.find(
				item => item.Description === thirdPartyActivities[0].SubCategory
			)
			return !thirdPartyPresent
		}
		return false
	}

	const [freeTextResponse, setFreeTextResponse] = useState(initializeFreeTextResponse())

	const toggleFreeTextResponse = val => {
		setThirdPartyActivities([{ SubCategory: '' }]) // reset otheractivities row

		toggleNoticeModal()
		setFreeTextResponse(val)
	}

	const checkThirdPartyCompletion = () => {
		if (thirdParty === true) {
			let thirdPartyitemsIncomplete

			thirdPartyitemsIncomplete = thirdPartyActivities.some(el => !el.SubCategory)

			if (thirdPartyitemsIncomplete) {
				return false
			}
		}
		return true
	}
	const thirdPartyComplete = checkThirdPartyCompletion()

	useEffect(() => {
		if (isActiveTab) {
			disableProceed(!thirdPartyComplete)
		}
	}, [isActiveTab, thirdPartyComplete]) // eslint-disable-line

	// update freetextresponse and subcategory when cache updates
	useEffect(() => {
		if (!freeTextResponse) {
			setFreeTextResponse(initializeFreeTextResponse())
		}

		if (!subCategoryData) {
			setSubCategoryData(initializeSubCategories())
		}
	}, [cachedData?.SubCategories?.required?.length]) // eslint-disable-line

	const addNewThirdPartyActivity = () => {
		setThirdPartyActivities([...thirdPartyActivities, { ...otherUsageDefaults, id: nanoid() }])
	}

	const triggerSave = list => {
		const isValid = checkOtherInputs(list)
		if (isValid) {
			const filteredThirdParties = filterArrObj(list, ['id'])
			onSave({
				ThirdPartyUsage: {
					Present: Boolean(thirdParty && list.length),
					Details: filteredThirdParties,
				},
			})
		}
	}

	const removeThirdPartyActivity = id => {
		const list = thirdPartyActivities.filter(el => el.id !== id)
		setThirdPartyActivities(list)
		triggerSave(list)
	}

	const checkOtherInputs = list => {
		let isValid = true
		list.forEach(item => {
			if (isValid) {
				isValid = !!item.SubCategory
			}
		})
		return isValid
	}

	const setThirdPartyItem = (id, name, value) => {
		const alteredItems = thirdPartyActivities.map(el => {
			return el.id === id ? { ...el, [name]: value } : el
		})
		setThirdPartyActivities(alteredItems)
		triggerSave(alteredItems)
	}

	const renderThirdPartyUseSections = thirdPartyActivities.map(el => {
		return (
			<GridContainer key={el.id} removeButtonCol={thirdPartyActivities.length > 1} className="mt-3">
				{!freeTextResponse ? (
					<>
						<SearchableSelectWrapper>
							<SearchableSelect
								style={SharedInputStyles}
								options={subCategoryData}
								value={el.SubCategory}
								key={subCategoryData?.length}
								emptyMessage={catLoading ? 'Loading...' : 'Not found'}
								onChange={val => setThirdPartyItem(el.id, 'SubCategory', val)}
								search
								placeholder="Select primary use from IIB rating sub-category"
							/>
						</SearchableSelectWrapper>
					</>
				) : (
					<div className="d-flex w-100">
						<Input
							type="text"
							style={{ width: '720px' }}
							placeholder="Enter in detail"
							value={el.SubCategory}
							onChange={e => {
								setThirdPartyItem(el.id, 'SubCategory', e.target.value)
							}}
						/>
					</div>
				)}

				<RenderRemoveButton
					onClick={() => removeThirdPartyActivity(el.id)}
					renderWhen={thirdPartyActivities.length > 1}
				/>
			</GridContainer>
		)
	})

	const ThirdPartyUseElement = (
		<>
			{renderThirdPartyUseSections}

			<div className="d-flex mt-3">
				<FreeTextToggle onChange={toggleFreeTextResponse} isFreeTextResponse={freeTextResponse} />
			</div>
		</>
	)

	const thirdPartyUsageOptions = thirdParty ? (
		<SpecifyMoreDetails
			title="Please specify the nature of business of such 3rd parties:"
			renderDetailItems={ThirdPartyUseElement}
			addNewDetailItem={addNewThirdPartyActivity}
			addItemButtonLabel="Add another nature of business"
		/>
	) : null

	const onSelect = val => {
		setThirdParty(val)
		if (!val) {
			onSave({
				ThirdPartyUsage: {
					Present: false,
					Details: [],
				},
			})
		}
	}

	return (
		<>
			<Text type="body1" fontWeight={500}>
				Does a 3rd party also use this {propertyType}?
			</Text>
			<RadioDiv className="d-flex mt-2">
				<Radio
					labelStyle={SharedRadioStyles}
					value={thirdParty === true}
					onChange={() => onSelect(true)}
					text="Yes"
					name={`${propertyType}-${levelIdx}-thirdParttyUseYes`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={thirdParty === false}
					onChange={() => onSelect(false)}
					text="No"
					name={`${propertyType}-${levelIdx}-thirdParttyUseNo`}
				/>
			</RadioDiv>
			{thirdPartyUsageOptions}
		</>
	)
}

export default ThirdPartyUsage
