import { Modal, Text, theme } from 'verak-ui'
import ReassignmentContainer from './ReassignmentContainer'

export default function ReassignRfqModal({
	handleClose,
	selectedRfqs,
	deactivateReassignmentMode,
}) {
	return (
		<Modal
			show={true}
			handleClose={handleClose}
			title={
				<Text color={theme.colors.red} fontSize="24px" fontWeight="700">
					Reassign To:{' '}
				</Text>
			}
			footer={<></>}
		>
			<ReassignmentContainer
				selectedRfqs={selectedRfqs}
				deactivateReassignmentMode={deactivateReassignmentMode}
			/>
		</Modal>
	)
}
