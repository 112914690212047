import { SidePanel, Text, Button } from 'verak-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useContext, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import Profile from '../assets/rajeshpai_profile.png'
import { Context } from '../context/Provider'
import { Row, Container } from 'react-bootstrap'
import {
	generateSumInsuredBreakdown,
	totalSumInsuredForAllRiskLocations,
} from '../screens/FinalReview/finalReviewUtils'
import { calculateTotalStockVal } from '../utilities/calculateTotalStockVal'
import { getFormattedAmount } from '../utilities/getFormattedAmount'

const DraftRFQValue = ({ field, value, className = 'mb-2', isRupeeValue = false }) => {
	if (!value) {
		return null
	}
	return (
		<Row className={className}>
			<Text fontWeight="700" fontSize={'12px'} color={'#4C4C4C'}>
				{field}
			</Text>
			<Text color={'#999999'}>{isRupeeValue ? `₹ ${getFormattedAmount(value)}` : value}</Text>
		</Row>
	)
}

const getPolicyKind = oldPolicyKind => {
	if (oldPolicyKind === 'BUILDING_ONLY') {
		return 'Property only'
	} else if (oldPolicyKind === 'BUILDING_AND_STOCK') {
		return 'Property and Stock'
	} else if (oldPolicyKind === 'STOCK_ONLY') {
		return 'Stock only'
	}
	return ''
}

export default function SidePanelComp({ renderSidePanel }) {
	const [sidebarOpen, setSidebarOpen] = useState(true)
	const toggleSidebar = () => setSidebarOpen(p => !p)
	const { isAuthenticated } = useAuth0()
	const [state, dispatch] = useContext(Context)
	const history = useHistory()
	const location = useLocation()

	const draftRFQ = state?.rfq?.draftRFQ

	const totalSIForAllLocations = useMemo(() => {
		if (!(isAuthenticated && renderSidePanel)) {
			return 0
		}
		if (draftRFQ) {
			const riskLocations = draftRFQ?.Data?.RiskLocations || []
			const SIBreakdown = generateSumInsuredBreakdown(riskLocations)
			const totalSI = totalSumInsuredForAllRiskLocations(SIBreakdown) || 0
			const totalStock =
				calculateTotalStockVal({
					RiskLocations: riskLocations,
					StockDisclosureMethod: 'DECLARED_VALUE',
				}) || 0
			return totalSI + totalStock
		}
		return 0
	}, [draftRFQ?.Data?.RiskLocations, isAuthenticated, renderSidePanel])

	if (isAuthenticated && renderSidePanel) {
		let draftRFQContent
		if (state?.rfq?.draftRFQ) {
			const getSumInsuredAmount = (riskLocationData, key) => {
				const sumInsuredItem = riskLocationData?.SumInsured.find(item => item.Name === key)
				return sumInsuredItem?.Amount
			}

			const getStockSI = riskLocationData => {
				const stockSI =
					riskLocationData.Stock?.Details?.reduce(
						(prev, curr) =>
							prev + parseInt(curr?.AmountOpen || 0) + parseInt(curr?.AmountClosed || 0),
						0
					) || 0
				return stockSI
			}

			const getTotalSI = riskLocationData => {
				let totalSI = 0
				riskLocationData?.SumInsured?.forEach(item => {
					if (item.Name !== 'MaxValSingleItem') {
						totalSI += parseInt(item.Amount || 0)
					}
				})
				const stockSI = getStockSI(riskLocationData)
				return totalSI + stockSI
			}

			const getStockType = riskLocationData => {
				const stockType = riskLocationData?.Stock?.Details[0]?.Type
				if (stockType) {
					return `(${stockType})`
				}
				return ''
			}

			const locationList = draftRFQ.Data.RiskLocations.map((item, index) => {
				const isBasement = item.Type == 'BASEMENT'
				let ceilingLabel = '-',
					floorLabel = '-',
					wallLabel = '-',
					roofLabel = '-'
				if (item?.Materials.Ceiling?.Type) {
					ceilingLabel =
						item?.Materials.Ceiling?.Type +
						(item?.Materials.Ceiling?.Description
							? ` (${item?.Materials.Ceiling.Description})`
							: '')
				}

				if (item?.Materials.Walls?.Type) {
					wallLabel =
						item?.Materials.Walls?.Type +
						(item?.Materials.Walls?.Description ? ` (${item?.Materials.Walls.Description})` : '')
				}

				if (item?.Materials.Floors?.Type) {
					floorLabel =
						item?.Materials.Floors?.Type +
						(item?.Materials.Floors?.Description ? ` (${item?.Materials.Floors.Description})` : '')
				}

				if (item?.Materials.Roof?.Type) {
					roofLabel =
						item?.Materials.Roof?.Type +
						(item?.Materials.Roof?.Description ? ` (${item?.Materials.Roof.Description})` : '')
				}

				return (
					<div className="mb-4" key={index}>
						<Text fontWeight="700" fontSize={'12px'} color={'#4C4C4C'} className="mb-1">
							Location {index + 1}
						</Text>
						<DraftRFQValue field="Address" value={item?.AddressLine1} />
						<DraftRFQValue field="Pincode" value={item?.PIN} />
						<DraftRFQValue field="Property Type" value={item.Type} />
						<DraftRFQValue field="Ownership Type" value={item.Ownership} />
						<DraftRFQValue
							field="Business Type / Primary Occupancy"
							value={item?.PrimaryOccupancy?.SubCategory}
						/>
						{/* <DraftRFQValue field="Stock Type" value={item?.Stock?.Details[0]?.Type} />
						<DraftRFQValue field="Stock Sum Insured" value={item?.Stock?.Details[0]?.AmountOpen} /> */}
						{/* <DraftRFQValue field="Risk Assesment" value={draftRFQ.Notes} /> */}
						<DraftRFQValue field="AMC for Fire Risk" value={item?.FireRisk?.AMC ? 'Yes' : ''} />
						<DraftRFQValue field="Risk Assesment" value={item?.FireRisk?.Measures.join(' ,')} />
						<Text fontWeight="700" fontSize={'12px'} color={'#4C4C4C'} className="mb-1">
							Building Materials
						</Text>
						<Container>
							{isBasement ? (
								<DraftRFQValue field="Ceiling" value={ceilingLabel} />
							) : (
								<DraftRFQValue field="Roof" value={roofLabel} />
							)}
							<DraftRFQValue field="Walls" value={wallLabel} />
							<DraftRFQValue field="Floors" value={floorLabel} />
						</Container>

						<Text fontWeight="700" fontSize={'12px'} color={'#4C4C4C'} className="mb-1">
							Sum Insured Breakup
						</Text>
						<Container>
							<DraftRFQValue
								field="Basement Value"
								value={getSumInsuredAmount(item, 'BasementValue')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Building Value"
								value={getSumInsuredAmount(item, 'BuildingValue')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Boundary Wall (including gates etc)"
								value={getSumInsuredAmount(item, 'BoundaryWalls')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Plants and Machinery"
								value={getSumInsuredAmount(item, 'PlantMachinery')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Furniture & Fixture"
								value={getSumInsuredAmount(item, 'FurnitureFixture')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Electronic Installations"
								value={getSumInsuredAmount(item, 'Electronics')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Office equipments (including IT systems)"
								value={getSumInsuredAmount(item, 'OfficeEquipment')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Valuable Contents"
								value={getSumInsuredAmount(item, 'ValuableContents')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Other Contents"
								value={getSumInsuredAmount(item, 'Other')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field="Maximum value of a single item"
								value={getSumInsuredAmount(item, 'MaxValSingleItem')}
								isRupeeValue={true}
							/>
							<DraftRFQValue
								field={`
									Stock Sum Insured
									${getStockType(item) || ''}
								`}
								value={getStockSI(item)}
								isRupeeValue={true}
							/>
							<DraftRFQValue field="Total" value={getTotalSI(item)} isRupeeValue={true} />
						</Container>
					</div>
				)
			})

			draftRFQContent = (
				<div className="pb-3">
					<DraftRFQValue field="Notes" value={draftRFQ.Notes} className="mb-3" />
					<DraftRFQValue field="Company Name" value={draftRFQ.Account.Name} />
					<DraftRFQValue field="Proposed Name" value={draftRFQ.Contact.Name} />
					<DraftRFQValue field="Email" value={draftRFQ.Contact?.Email} />
					<DraftRFQValue field="Whatsapp Number" value={draftRFQ.Contact?.WaPhone} />
					<DraftRFQValue
						field="Whatsapp Consent"
						value={draftRFQ.Contact?.WaConsent ? 'Yes' : 'No'}
					/>
					<DraftRFQValue
						field="Alternate product interest"
						value={draftRFQ?.Contact?.AlternateProductInterest}
					/>
					<DraftRFQValue field="PolicyKind" value={getPolicyKind(draftRFQ.Data.PolicyKind)} />
					<DraftRFQValue
						field="Total Sum Insured"
						value={totalSIForAllLocations}
						isRupeeValue={true}
						className="mb-4"
					/>
					{locationList}
				</div>
			)
		}

		const handleAddonBtn = async () => {
			if (location?.state?.from === 'rfq-progress-screen') {
				await new Promise(resolve => {
					dispatch({
						type: 'SET_EDIT_RFQ',
						data: { editRfqMode: true },
					})
					resolve()
				})
			}

			history.push({
				pathname: '/new-quote/select-policy-type',
				state: {
					nextScreenPath: '/journey/risk-location-selection',
				},
			})
		}

		let addonBtn = null
		// List of paths which may have the "quote" object but the include button shouldn't show up
		const pathsToExclude = [
			'/',
			'/quotes-in-progress',
			'/rfq-status/quotes-ready',
			'/rfq-email',
			'/new-quote/select-policy-type',
			'/rfq-status/underwriter-review-pending',
		]
		if (state?.quote?.RiskLocations?.length && !pathsToExclude.includes(window.location.pathname)) {
			addonBtn = (
				<Button
					label="Include an Add on"
					onClick={() => handleAddonBtn()}
					style={{ fontSize: '12px', margin: '1rem 0' }}
				/>
			)
		}

		return (
			<>
				{/* dummy element [side panel mask] */}
				<SidePanelMask open={sidebarOpen} />
				<SidePanel
					name="Mr. Rajesh Pai"
					designation="Sales Manager"
					phoneNo="9611344788"
					profile={Profile}
					sidepanelTitle="Customer Details"
					open={sidebarOpen}
					toggle={toggleSidebar}
					extraContent={
						<>
							{addonBtn}
							{draftRFQContent}
						</>
					}
					contentClassName="overflow-auto"
				/>
			</>
		)
	} else {
		return null
	}
}

// dummy element to mask the sidepanel
// width same as the sidepanel
const SidePanelMask = styled.div`
	/* min-width: 200px; */
	padding: 1rem;
	min-width: ${p => (p.open ? '200px' : '65px')};
	transition: all 0.3s ease;
	@media (max-width: 768px) {
		display: none;
	}
`
