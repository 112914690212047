import {
	Container,
	FlexContainer,
	selectStyle,
	secondarySelectStyle,
	textStyle,
	buttonStyle,
	StyledLabel,
	CompulsoryStar,
} from './DetailsCollectionTemplate'
import { Space, Text, theme, Button, Input, Select, Divider } from 'verak-ui'

const Address = ({
	data,
	hypothecationList,
	incrementHypothecation,
	decrementHypothecation,
	onHypothecationFieldChange,
	secondaryContainerStyle = { border: '1px solid #F78670' },
}) => {
	const renderHypothecation = hypothecationList.map((el, idx) => (
		<div key={idx}>
			{' '}
			{/* because of this line deletion occurs correctly - "key" */}
			<Space y={1.5} />
			<FlexContainer>
				<Input
					placeholder="Enter party name here"
					value={el.Name}
					onChange={e => onHypothecationFieldChange(idx, e.target.value, 'Name')}
				/>
				<Select
					value={el?.Type}
					style={{ ...selectStyle, ...secondarySelectStyle }}
					placeholder="Select"
					onChange={e => onHypothecationFieldChange(idx, e, 'Type')}
				>
					<option value="BANK">Bank</option>
					<option value="NBFC">NBFC</option>
					<option value="OTHER">Other</option>
				</Select>

				<Button
					icon="remove"
					bgColor={theme.colors.white}
					style={buttonStyle}
					onClick={() => decrementHypothecation(idx)}
				/>
			</FlexContainer>
		</div>
	))

	return (
		<Container style={secondaryContainerStyle}>
			<Text style={textStyle} color={theme.colors.secondary}>
				Address
			</Text>
			<Space y={1} />

			<FlexContainer>
				<Input label="Address Line #1" value={data?.AddressLine1} disabled compulsory />
				<Input label="Address Line #2" value={data?.AddressLine2} disabled />
			</FlexContainer>

			<Space y={1} />
			<Input label="PIN Code" value={data?.PIN} disabled compulsory />
			<Space y={1} />

			{/* Select component to be extended to include a "label" and "compulsory" property */}
			<FlexContainer>
				<div>
					<StyledLabel>
						City <CompulsoryStar />
					</StyledLabel>
					<Select style={selectStyle} disabled value={data?.City}>
						<option value={data?.City}>{data?.City}</option>
					</Select>
				</div>
				<div>
					<StyledLabel>
						State <CompulsoryStar />
					</StyledLabel>
					<Select style={selectStyle} disabled value={data?.State}>
						<option value={data?.State}>{data?.State}</option>
					</Select>
				</div>
			</FlexContainer>

			<Divider top={1} bottom={1} />
			<Text style={textStyle} color={theme.colors.secondary}>
				Please specify if stock has been hypothecated to any 3rd party:
			</Text>

			{renderHypothecation}

			<Space y={1.5} />
			<Button
				label="Add another party"
				textColor={theme.colors.primary}
				bgColor={theme.colors.white}
				icon="add"
				iconStyles={{ marginRight: '0.5rem' }}
				style={buttonStyle}
				onClick={incrementHypothecation}
			/>
		</Container>
	)
}

export default Address
