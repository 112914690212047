import { Button, Modal, Text, theme } from 'verak-ui'

import { ReactComponent as EditIcon } from '../../../assets/icons/editPencilSquare.svg'

export default function CannotCreateDraftModal({ showModal = true, onContinue = () => {} }) {
	return (
		<Modal
			show={showModal}
			handleClose={() => {}}
			closeButton={false}
			title={
				<Text type="primary" color={theme.colors.red}>
					Alert!
				</Text>
			}
			footer={<></>}
			style={{ textAlign: 'center' }}
		>
			<div
				className="m-auto p-2"
				style={{ background: '#FCEBE3', borderRadius: '8px', width: 'fit-content' }}
			>
				<EditIcon style={{ display: 'inline-block', width: '50px', height: '50px' }} />
			</div>
			<Text fontSize="18px" fontWeight="700" color={theme.colors.red} className="my-3">
				Draft cannot be created in this case please go to{' '}
				<span style={{ textDecoration: 'underline' }}>"Return to saved quote"</span> to edit the
				details
			</Text>

			<Button
				primary
				onClick={onContinue}
				style={{
					width: '100%',
					height: '50px',
					fontSize: '18px',
				}}
				label="Continue"
			/>
		</Modal>
	)
}
