import { theme, Text, Button, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState, useEffect } from 'react'
import { useContextApi } from '../../context/Provider'
import { authorizedAccounts } from '../../utilities/authorizedAccounts'

import SendNotificationsModal from './SharedComponents/SendNotificationsModal'
import { CheatSheetCard } from '../DraftRFQ/components'
import { useFetch } from '../../api/apihook'
import { ClaimsImageModal } from '../DraftRFQ/DraftRFQScreen'

const btnIconStyle = {
	marginRight: '.7rem',
}

const SalesAmmo = ({ show, handleClose, onClaimsDisplay }) => {
	return (
		<SalesAmmoOverlay show={show}>
			<SalesAmmoContainer show={show}>
				<CheatSheetCard
					style={{ boxShadow: 'none', padding: '0' }}
					onClaimsDisplay={onClaimsDisplay}
				/>
				<ImpDocsSection className="d-flex align-items-center pt-3">
					<Text fontWeight="bold" color="rgba(100, 103, 176, 1)">
						Important <br /> documents:
					</Text>
					<ImpDocLink
						href="https://docs.google.com/presentation/u/1/d/18v3F4S-9vlIWNSCu7H22bvz_pkmz1zydIA3C1FIZZKI/edit#slide=id.g145e8facc04_0_748"
						target="_blank"
					>
						<Icon name="docIcon" />
						How To Sell?
					</ImpDocLink>
					<ImpDocLink
						href="https://docs.google.com/presentation/u/1/d/1iuoGQMJ0NeV575b1wOq00hjjagmtODEwnM6KUbAv3u8/edit#slide=id.g145eb4f4d27_0_348"
						target="_blank"
					>
						<Icon name="docIcon" />
						Claims Process
					</ImpDocLink>
					<ImpDocLink
						href="https://docs.google.com/document/d/1KmsavEn_ynqdGGk757MR_7bn8LBv51xJ/edit"
						target="_blank"
					>
						<Icon name="docIcon" />
						Inbound RFQ Script
					</ImpDocLink>
					<ImpDocLink
						href="https://docs.google.com/spreadsheets/d/1d778lc9q8dXYSKAXG-ExFmmh-Bt9q2Rl"
						target="_blank"
					>
						<Icon name="docIcon" />
						Churn Script
					</ImpDocLink>
					<ImpDocLink
						href="https://docs.google.com/presentation/d/13TCPv4D57nfa68eUzH1D8Uiknae_40KJ4qxXcmIdbRo"
						target="_blank"
					>
						<Icon name="docIcon" />
						Rebuttals
					</ImpDocLink>
				</ImpDocsSection>
				<HideCheatSheetBtn icon="rightArrow" iconColor="#fff" onClick={handleClose} />
			</SalesAmmoContainer>
		</SalesAmmoOverlay>
	)
}

export default function HomePage() {
	const { user } = useAuth0()
	const history = useHistory()
	const { getPendingReassignmentCount } = useFetch()
	const [state, dispatch] = useContextApi()

	const [showNotificationsModal, setShowNotificationsModal] = useState(false)
	const [showSalesAmmo, setShowSalesAmmo] = useState(false)
	const [showClaimsModal, setShowClaimsModal] = useState(false)

	const [pendingReassignmentCount, setPendingReassignmentCount] = useState(0)

	const isFosUser = state?.app?.isFosUser || false

	const isAuthorizedAccount = authorizedAccounts.includes(user?.email)

	const fetchPendingReassignmentCount = async () => {
		const result = await getPendingReassignmentCount()
		if (parseInt(result?.count) > 0) {
			setPendingReassignmentCount(result.count)
		}
	}

	useEffect(() => {
		if (isAuthorizedAccount) {
			fetchPendingReassignmentCount()
		}
	}, [isAuthorizedAccount]) // eslint-disable-line

	useEffect(() => {
		if (state?.oldRfq) {
			dispatch({ type: 'CLEAR_OLD_RFQ' })
		}
	}, [])

	const navContent = (
		<div className="d-flex align-items-center">
			<Text type="body1" color={theme.colors.gray7} fontWeight="700">
				Welcome, {user?.name}
			</Text>
			<Text color={theme.colors.gray3} fontWeight="400" className="mx-3">
				Select an option to continue
			</Text>
		</div>
	)

	const navigateTo = route => {
		history.push(route)
	}

	const navigateToDraft = () => {
		navigateTo('/draft-rfqs')
	}

	const navigateToReassignmentRequests = () => navigateTo('/reassignment-requests')

	return (
		<PageContentAndNavbarWrapper style={{ position: 'relative', height: '100%' }}>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
				showGoBack={false}
			/>

			<div className="container p-3">
				<CustomFlex className="d-flex">
					<CustomButton
						iconStyles={btnIconStyle}
						icon="newQuote"
						label="Generate new RFQ"
						onClick={() => navigateTo('/new-quote')}
					/>

					<CustomButton
						iconStyles={btnIconStyle}
						icon="saveQuote"
						label="Return to saved quote"
						onClick={() => navigateTo('/quotes-in-progress')}
					/>

					<CustomButton
						iconStyles={btnIconStyle}
						icon="renewPolicy"
						label="Renew client policy"
						onClick={() => navigateTo('/renewals/draft-rfqs')}
					/>
				</CustomFlex>
				<CustomFlex className="d-flex mt-3">
					<CustomButton
						iconStyles={btnIconStyle}
						icon="sendNotification"
						iconColor={theme.colors.secondary}
						label={<p className="m-0 ms-2">Send Notifications</p>}
						onClick={() => setShowNotificationsModal(true)}
					/>

					<CustomButton
						iconStyles={btnIconStyle}
						icon="saveQuote"
						label="Draft RFQs"
						onClick={navigateToDraft}
					/>

					{isAuthorizedAccount && (
						<>
							<CustomButton
								iconStyles={btnIconStyle}
								icon="reassignIcon"
								iconColor={theme.colors.secondary}
								label="Reassignment Requests"
								onClick={navigateToReassignmentRequests}
								style={{ marginRight: '0px' }}
							/>
							{pendingReassignmentCount > 0 && (
								<CountIndicator>{pendingReassignmentCount}</CountIndicator>
							)}
						</>
					)}
				</CustomFlex>
			</div>
			{!isFosUser && (
				<>
					<ShowCheatSheetBtn
						label="Important Notes"
						icon="leftArrow"
						iconColor="#fff"
						iconAlign="left"
						onClick={() => setShowSalesAmmo(true)}
					/>
					<SalesAmmo
						show={showSalesAmmo}
						handleClose={() => setShowSalesAmmo(false)}
						onClaimsDisplay={() => setShowClaimsModal(true)}
					/>
					<ClaimsImageModal
						show={showClaimsModal}
						handleClose={() => {
							setShowClaimsModal(false)
						}}
					/>
				</>
			)}
			<SendNotificationsModal
				show={showNotificationsModal}
				handleClose={() => setShowNotificationsModal(false)}
			/>
		</PageContentAndNavbarWrapper>
	)
}

// components
const CustomFlex = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
`

const CustomButton = styled(Button)`
	justify-content: flex-start;
	align-items: center;
	height: 64px;
	padding: 16px;
	background: white;
	color: ${theme.colors.primary};
	width: min(328px, 90%);
	margin-right: 24px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	opacity: ${({ disabled }) => (disabled ? '.3' : '1')};
	&:hover {
		color: white;
	}
	@media (max-width: 768px) {
		margin: 0px;
		margin-bottom: 1rem;
	}
`

const SalesAmmoOverlay = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 999;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	background: rgba(255, 255, 255, ${p => (p.show ? '0.7' : '0')});
	height: calc(100vh - 80px);
	min-width: ${p => (p.show ? '100%' : '0')};
	transform: translateX(${p => (p.show ? '0' : '100%')});
	transition: all 0.3s ease-in-out;
	@media screen and (max-width: 768px) {
		display: none;
	}
`

const CardStyle = styled.div`
	background: ${theme.colors.white};
	padding: 14px;
	border-radius: 10px;
	box-shadow: 0px 0px 8px rgb(0 0 0 / 15%);
`

const SalesAmmoContainer = styled(CardStyle)`
	position: relative;
	width: ${p => (p.show ? '780px' : '0px')};
	display: ${p => (p.show ? 'block' : 'none')};
	opacity: ${p => (p.show ? '1' : '0')};
`

const ShowCheatSheetBtn = styled(Button)`
	position: absolute;
	right: 2px;
	bottom: 100px;
	border-radius: 8px 0 0 8px;
	padding: 0.5rem 0.75rem;

	@media screen and (max-width: 768px) {
		display: none;
	}
`

const HideCheatSheetBtn = styled(Button)`
	position: absolute;
	bottom: 0;
	left: -3rem;
	width: 2.5rem;
	border-radius: 8px 0 0 8px;
`

const ImpDocsSection = styled.div`
	border-top: 1px solid #e6e6e6;
	gap: 0.625rem;
`

const ImpDocLink = styled.a`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0.5rem;
	background: #eff6ff;
	border-radius: 7px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #333333;
`

const CountIndicator = styled.div`
	background: #ff0000;
	color: white;
	border-radius: 8px;
	width: 24px;
	height: 26px;
	position: relative;

	top: -10px;
	left: -10px;
	font-weight: bold;
	text-align: center;

	@media (max-width: 768px) {
		top: -95px;
		right: -180px;
		align-self: flex-end;
	}
`
