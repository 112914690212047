import { useContext, useState } from 'react'
import { Context } from '../../context/Provider'
import { useLocation, useHistory } from 'react-router'

import Floater from './DeclarationMethods/Floater'
import DeclarationTemplate from './DeclarationMethods/DeclarationTemplate'
import FloaterDeclaration2 from './DeclarationMethods/FloaterDeclaration2'

const DisclosureMethods = () => {
	const [state, dispatch] = useContext(Context)
	const [floaterDec, toggleFloaterDec] = useState(0)

	const returnPath = state?.sqs?.returnPath || '/property-risk/sum-insured'

	const location = useLocation()
	const history = useHistory()

	const propertyType = location.state?.propertyType
	const levelIndex = location.state?.levelIndex
	const currentLocationId = location.state?.currentLocationId
	const disclosureMethod = location.state?.disclosureMethod

	// Floater and FloaterDeclaration2 are handled in their respective files

	// DeclarationBasis.js
	const handleDeclarationBasisSaveAndContinue = async method => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: method,
				},
			})
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 2,
					StockDisclosureMethod: 'DECLARATION_BASIS',
				},
			})
			resolve()
		})

		history.replace({
			pathname: returnPath,
			state: {
				propertyType: propertyType,
				levelIndex: levelIndex,
			},
		})
	}

	// FloaterDeclaration.js
	const handleFloaterDeclarationSaveAndContinue = async method => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: method,
				},
			})
			resolve()
		})
		toggleFloaterDec(1)
	}

	let content = null
	if (disclosureMethod === 'FLOATER') {
		content = (
			<Floater
				propertyType={propertyType}
				levelIndex={levelIndex}
				currentLocationId={currentLocationId}
			/>
		)
	} else if (disclosureMethod === 'DECLARATION_BASIS') {
		content = (
			<DeclarationTemplate
				handleSave={handleDeclarationBasisSaveAndContinue}
				method="Declaration Basis"
			/>
		)
	} else if (disclosureMethod === 'FLOATER_DECLARATION') {
		if (floaterDec === 0) {
			content = (
				<DeclarationTemplate
					handleSave={handleFloaterDeclarationSaveAndContinue}
					method="Floater Declaration Basis"
				/>
			)
		} else {
			content = (
				<FloaterDeclaration2
					toggleFloaterDec={toggleFloaterDec}
					propertyType={propertyType}
					levelIndex={levelIndex}
					currentLocationId={currentLocationId}
				/>
			)
		}
	}

	return <>{content}</>
}

export default DisclosureMethods
