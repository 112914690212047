import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import CustomerAnswer from '../../../../../assets/training/CustomerAnswer.svg'
import CustomerWontLie from '../../../../../assets/training/CustomerWontLie.svg'
import FullPrecaution from '../../../../../assets/training/FullPrecaution.svg'
import ImpactClaim from '../../../../../assets/training/ImpactClaim.svg'
import RegisterInvoices from '../../../../../assets/training/RegisterInvoices.svg'

export default function Training_StockValueVerify() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				CALL IS RECORDED & SENT TO IRDAI AS PROOF
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CustomerAnswer}>
						<InfoText>
							<b>Based on Customer's answers </b>
							RFQ is created & policy will be made
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={RegisterInvoices}>
						<InfoText>
							Customer would <b>answer based on stock register OR invoices</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				INSURANCE CO. WILL ASK FOR SOFT COPY OF DOCUMENTS AT THE TIME OF CLAIM SETTLEMENT
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={ImpactClaim}>
						<InfoText>
							<b>Can lie now, but will impact during claim settlement</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={FullPrecaution}>
						<InfoText>
							<b>Even with full precaution</b>, the loss can happen
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />

			<InfoText className="text-center my-3" spaced fontWeight="700">
				WHY WILL A CUSTOMER LIE?
			</InfoText>

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CustomerWontLie}>
						<InfoText>
							<b>
								Customer is buying so that they can claim later, so why would they lie if it affects
								claim
							</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column"></div>
			</div>
		</div>
	)
}
