// filters out objects w/ all empty values
// keys mentioned in 'keysToFilter' array are removed from all objects
const filterArrObj = (arr, keysToFilter) => {
	const alteredItems = arr.filter(el => {
		const temp = JSON.parse(JSON.stringify(el))

		keysToFilter.forEach(k => delete temp[k])

		if (Object.values(el).every(e => !e)) {
			return null
		}
		return temp
	})

	return alteredItems
}

const flattenArrObj = (arr, keyToExtract) => {
	const alteredItems = arr.map(el => el[keyToExtract]).filter(el => el)

	return alteredItems
}

export { filterArrObj, flattenArrObj }
