import styled from 'styled-components'
import { Input, Select, Text, theme } from 'verak-ui'

const GridContainer = styled.div`
	display: grid;
	place-items: start stretch;
	grid-template-columns: ${({ removeButtonCol, columns }) =>
		columns ? columns : removeButtonCol ? '1fr 1fr 0.1fr' : 'repeat(2, 1fr)'};
	gap: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		&:not(:last-of-type) {
			padding-bottom: 1rem;
			border-bottom: 1px solid ${theme.colors.primary};
		}
	}
`

const RadioDiv = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const SearchableSelectWrapper = styled.div`
	.select-search {
		width: 100%;
	}
`

const CustomInput = styled(Input)`
	width: 100%;
`

const SearchableSelectLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: 500;
	margin-bottom: 0.5rem;
	&::after {
		content: '*';
		color: ${theme.colors.red};
	}
`

const SelectLabel = styled(SearchableSelectLabel)``

const CustomSelectLabel = styled(SearchableSelectLabel)`
	&::after {
		content: '';
	}
`

const CustomSelect = styled(Select)`
	width: 100%;
	height: 48px;
`

const SharedInputStyles = {
	width: '100%',
}
const SharedRadioStyles = {
	margin: '1rem 0',
}

export {
	GridContainer,
	RadioDiv,
	SearchableSelectWrapper,
	CustomInput,
	SearchableSelectLabel,
	SelectLabel,
	CustomSelectLabel,
	CustomSelect,
	SharedInputStyles,
	SharedRadioStyles,
}
