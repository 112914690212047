import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { theme, Text, Icon, Divider } from 'verak-ui'

// styled components
const SummaryContainer = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 8px 8px 0 0;
`

const SummaryInfoSection = styled(Row)`
	padding: 1.5rem;
	background: rgba(252, 235, 227, 0.5);
	border-radius: 10px;
	margin: 0.5rem;
`

// helper components
const SummaryInfoTitle = ({ title, icon }) => {
	let displayIcon = null
	if (icon) {
		displayIcon = <Icon style={{ marginRight: '0.25rem' }} name={icon} />
	}
	return (
		<div className="d-flex align-items-center mb-3">
			{displayIcon}
			<Text
				type="body2"
				fontWeight="700"
				color={theme.colors.secondary}
				style={{ textTransform: 'uppercase' }}
			>
				{title}
			</Text>
		</div>
	)
}

const SummaryInfo = ({ children }) => (
	<Text type="h5" fontWeight="700" color={theme.colors.primary}>
		{children}
	</Text>
)

const SummaryInfoSubItem = ({ leftText, rightText, rightTextStyle, leftTextStyle, divider }) => (
	<>
		<Row className={divider ? '' : 'mb-3'}>
			<Col>
				<Text type="body1" color="#333333" style={leftTextStyle}>
					{leftText}
				</Text>
			</Col>
			<Col style={{ textAlign: 'end' }}>
				<Text type="body1" color="#333333" style={rightTextStyle}>
					{rightText}
				</Text>
			</Col>
		</Row>
		{divider ? <Divider top={1} bottom={1} /> : null}
	</>
)

// helper functions
const getPolicyTypeFullForm = type => {
	switch (type) {
		case 'BSUS':
			return 'Bharat Sookshma Udyam Suraksha'
		case 'BLUS':
			return 'Bharat Laghu Udyam Suraksha'
		case 'SFSP':
			return 'Standard Fire and Special Perils'
		default:
			return ''
	}
}

const getAddOnName = addon => {
	switch (addon) {
		case 'BURGLARY':
			return 'Burglary'
		case 'BURGLARY_THEFT':
			return 'Burglary & Theft'
		case 'CIS':
			return 'Cash in Safe'
		case 'CIC':
			return 'Cash in Counter'
		case 'CIT':
			return 'Cash in Transit'
		default:
			return addon
	}
}

export {
	// styled components
	SummaryContainer,
	SummaryInfoSection,
	// helper components
	SummaryInfoTitle,
	SummaryInfo,
	SummaryInfoSubItem,
	// helper functions
	getPolicyTypeFullForm,
	getAddOnName,
}
