export const checkIfValueExists = value => {
	return value !== undefined && value !== null && value !== ''
}

export const isBuildingHeightInFloors = (Type, locationData) => {
    let heightInFloors = null
    if (Type === 'PROPERTYBASEMENT') {
        heightInFloors = locationData?.property?.Floors
    } else {
        heightInFloors = locationData?.Floors
    }
    return checkIfValueExists(heightInFloors)
}