import { hideNOBforBusinessTypes } from '../../DraftRFQ/components/PrimaryOccupancySelector'

export function computeLevels(type, subType, levelsData) {
	if (!subType.toLowerCase()?.includes('whole')) {
		return levelsData
	}
	const totalLevels = Number(levelsData[0]?.Level || 0)
	const remainingData = { ...(levelsData[0] || {}) }
	let updatedLevels = []

	if (type === 'basement') {
		for (let i = -1; i >= totalLevels * -1; i--) {
			updatedLevels.push({
				...remainingData,
				Level: i,
			})
		}
	} else {
		for (let i = 0; i < totalLevels; i++) {
			updatedLevels.push({
				...remainingData,
				Level: i,
			})
		}
	}
	console.log(updatedLevels)
	return updatedLevels
}

// util functions to handle nature of business and type of facility selection

let typesOfFacilities = [
	'Shop',
	'Factory',
	'Godown',
	'Office',
	'Restaurant/Cafe/Hotel',
	'Manufacturer',
	'Basement',
]

const isValidOccupancy = occupancyString => {
	// check if occupancy string contains valid Type of Facility and Nature of Business
	// stored as {Type}:-{Nature}
	const occupancy = occupancyString.split(':-')
	// if for eg. Motor showroom, etc is selected as facility type, skip nature of business check
	if (hideNOBforBusinessTypes.includes(occupancy?.[0])) {
		return Boolean(occupancy[0])
	}
	return Boolean(occupancy[0]) && Boolean(occupancy[1])
}

const getOccupancyValue = (occupancy, key) => {
	const occupancyValue = occupancy.split(':-')
	if (key === 'Type' && occupancyValue[0]) {
		return occupancyValue[0]
	} else if (key === 'Nature' && occupancyValue[1]) {
		return occupancyValue[1]
	}

	return ''
}

const checkIsOtherFacility = occupancy => {
	if (occupancy) {
		const typeValue = getOccupancyValue(occupancy, 'Type')
		return !typesOfFacilities.includes(typeValue)
	}
	return false
}

const OTHER_TYPE = 'Other (please mention)'

const facilityTypes = [
	<option disabled selected>
		Enter here
	</option>,
	typesOfFacilities.map(facilityItem => (
		<option value={facilityItem} key={facilityItem}>
			{facilityItem}
		</option>
	)),
	<option value={OTHER_TYPE}>{OTHER_TYPE}</option>,
]

export { getOccupancyValue, checkIsOtherFacility, isValidOccupancy, facilityTypes, OTHER_TYPE }
