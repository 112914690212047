import { useState } from 'react'
import { Modal, Text, Button, theme, Icon } from 'verak-ui'
import ReassignmentContainer from './ReassignmentContainer'
import { useFetch } from '../../../api/apihook'
import RetriggerCalculatorsIcon from '../../../assets/icons/retriggerCalculatorsIcon.svg'
import ReassignRfqDocsIcon from '../../../assets/icons/reassignRfqDocsIcon.svg'
import ReactivateIcon from '../../../assets/icons/reactivateIcon.svg'

const states = {
	REACTIVATE_RFQ: 'REACTIVATE_RFQ',
	RETRIGGER_CALCULATORS: 'RETRIGGER_CALCULATORS',
	REASSIGN_RFQ: 'REASSIGN_RFQ',
	REASSIGNMENT_SELECTOR: 'REASSIGNMENT_SELECTOR',
}

export default function ReactivateFlowModal({
	handleClose,
	selectedRfq,
	removeSelectedLostRfq,
	deactivateReassignmentMode,
}) {
	const [activeState, setActiveState] = useState(states.REACTIVATE_RFQ)
	const [calculatorsTriggered, setCalculatorsTriggered] = useState(false)
	const [rfqOwner, setRfqOwner] = useState(null)

	const { reactivateRfq, retriggerCalculatorsForRfq, getRfqOwner } = useFetch()

	const onClickReactivate = async () => {
		const result = await reactivateRfq(selectedRfq.id)
		if (result) {
			removeSelectedLostRfq()
			setActiveState(states.RETRIGGER_CALCULATORS)
		}
	}

	const onClickRetriggerCalculators = async () => {
		const result = await retriggerCalculatorsForRfq(selectedRfq.id)
		setCalculatorsTriggered(true)
		if (result) {
			if (!rfqOwner) {
				const result = await getRfqOwner(selectedRfq.id)
				setRfqOwner(result?.owner)
			}
			setActiveState(states.REASSIGN_RFQ)
		}
	}

	const onClickReassignRfq = async () => {
		setActiveState(states.REASSIGNMENT_SELECTOR)
	}

	const onRetriggerNoClick = async () => {
		if (!rfqOwner) {
			const result = await getRfqOwner(selectedRfq.id)
			setRfqOwner(result?.owner)
		}
		setActiveState(states.REASSIGN_RFQ)
	}

	const dialogFlowConfig = {
		[states.REACTIVATE_RFQ]: {
			icon: ReactivateIcon,
			mainText: 'Are you sure you want to reactivate this RFQ?',
			noButtonText: 'No',
			yesButtonText: 'Yes',
			onNoClick: () => handleClose(),
			onYesClick: () => onClickReactivate(),
			modalTitle: 'Reactivation',
		},
		[states.RETRIGGER_CALCULATORS]: {
			icon: RetriggerCalculatorsIcon,
			mainText: 'Do you want to get NEW QUOTES for this case?',
			noButtonText: "No, I don't need quotes",
			yesButtonText: 'Yes, I want quotes',
			onNoClick: onRetriggerNoClick,
			onYesClick: () => onClickRetriggerCalculators(),
			modalTitle: 'Want quotes?',
		},
		[states.REASSIGN_RFQ]: {
			icon: ReassignRfqDocsIcon,
			mainText: (
				<>
					Do you want to reassign this RFQ from{' '}
					<Text color={theme.colors.secondary} fontSize="18px" fontWeight="700">
						{rfqOwner?.name}({rfqOwner?.email})
					</Text>{' '}
					to someone else?
				</>
			),
			noButtonText: "No, don't reassign",
			yesButtonText: 'Yes, I want to reassign',
			onNoClick: () => handleClose(),
			onYesClick: () => onClickReassignRfq(),
			modalTitle: 'Reassignment',
			showBackButton: !calculatorsTriggered,
			onBackButtonClick: () => setActiveState(states.RETRIGGER_CALCULATORS),
		},
		[states.REASSIGNMENT_SELECTOR]: {
			modalTitle: 'Reassign To:',
		},
	}

	const modalTitle = (
		<div className="d-flex align-items-center">
			{dialogFlowConfig[activeState].showBackButton && (
				<Icon
					style={{ cursor: 'pointer' }}
					name="leftArrow"
					color="#999999"
					onClick={dialogFlowConfig[activeState].onBackButtonClick}
				/>
			)}
			<Text className="w-100 ms-1" color={theme.colors.primary} fontSize="18px" fontWeight="700">
				{dialogFlowConfig[activeState].modalTitle}
			</Text>
		</div>
	)

	let modalContent
	if (activeState === states.REASSIGNMENT_SELECTOR) {
		modalContent = (
			<ReassignmentContainer
				selectedRfqs={[selectedRfq?.id]}
				deactivateReassignmentMode={deactivateReassignmentMode}
				agentToHide={rfqOwner}
			/>
		)
	} else {
		modalContent = <DialogContent stateConfig={dialogFlowConfig[activeState]} />
	}

	return (
		<Modal
			closeButton={activeState === states.REASSIGNMENT_SELECTOR}
			title={modalTitle}
			show={true}
			handleClose={handleClose}
			footer={<></>}
		>
			{modalContent}
		</Modal>
	)
}

const DialogContent = ({ stateConfig }) => {
	const { icon, mainText, noButtonText, yesButtonText, onNoClick, onYesClick } = stateConfig

	return (
		<div className="d-flex flex-column align-items-center">
			<img src={icon} alt="reactivate rfq flow" />
			<Text
				color={theme.colors.primary}
				className="text-center mb-4"
				fontSize="18px"
				fontWeight="700"
			>
				{mainText}
			</Text>

			<div className="w-100 d-flex align-items-center">
				<Button
					onClick={onNoClick}
					className="w-100 me-1"
					style={{ height: '48px' }}
					secondary
					label={noButtonText}
				/>
				<Button
					onClick={onYesClick}
					className="w-100 ms-1"
					style={{ height: '48px' }}
					primary
					label={yesButtonText}
				/>
			</div>
		</div>
	)
}
