import TrainingPaperlessProcess from './content/Training_PaperlessProcess'
import TrainingCantTrustOnline from './content/Training_CantTrustOnline'
import TrainingStockValueVerify from './content/Training_StockValueVerify'
import TrainingDontNeedInsurance from './content/Training_DontNeedInsurance'
import TrainingDontBuyDirectly from './content/Training_DontBuyDirectly'
import TrainingEmotionalBlackmail from './content/Training_EmotionalBlackmail'
import TrainingWeHelpWithClaims from './content/Training_WeHelpWithClaims'
import TrainingClaimsProcess from './content/Training_ClaimsProcess'
import TrainingPreventClaimsRejection from './content/Training_PreventClaimsRejection'
import TrainingDocumentsRequired from './content/Training_DocumentsRequired'
import TrainingValuationDoubts from './content/Training_ValuationDoubts'

const materialIndex = {
	OBJECTION_HANDLING: { title: 'OBJECTION HANDLING', type: 'DIVIDER' },
	PAPERLESS_PROCESS: {
		icon: '🖥',
		title: 'Paperless Process',
		content: <TrainingPaperlessProcess />,
	},
	CANT_TRUST_ONLINE: {
		icon: '😨',
		title: "Can't trust online",
		content: <TrainingCantTrustOnline />,
	},
	DONT_NEED_INSURANCE: {
		icon: '🙅‍♂️',
		title: "Don't need insurance",
		content: <TrainingDontNeedInsurance />,
	},
	STOCK_VALUE_VERIFY: {
		icon: '📦',
		title: 'Stock value verify?',
		content: <TrainingStockValueVerify />,
	},
	DONT_BUY_DIRECTLY: {
		icon: '🔒',
		title: "Don't buy directly",
		content: <TrainingDontBuyDirectly />,
	},
	CLAIMS: { title: 'CLAIMS', type: 'DIVIDER' },
	EMOTIONAL_BLACKMAIL: {
		icon: '💔',
		title: 'Emotional blackmail',
		content: <TrainingEmotionalBlackmail />,
	},
	HELP_WITH_CLAIMS: {
		icon: '💪',
		title: 'We help with claims',
		content: <TrainingWeHelpWithClaims />,
	},
	CLAIMS_PROCESS: {
		icon: '⚙',
		title: 'Claim process',
		content: <TrainingClaimsProcess />,
	},
	PREVENT_CLAIM_REJECTION: {
		icon: '⚔',
		title: 'Prevent claim rejection',
		content: <TrainingPreventClaimsRejection />,
	},
	DOCUMENTS_REQUIRED: {
		icon: '📄',
		title: 'Documents required',
		content: <TrainingDocumentsRequired />,
	},
	VALUATION_DOUBTS: {
		icon: '💰',
		title: 'Valuation doubts',
		content: <TrainingValuationDoubts />,
	},
}

export default materialIndex
