import { Button, Modal, Text, theme } from 'verak-ui'

import { ReactComponent as MakeRfq } from '../../assets/icons/makeRfq.svg'

const MakeRfqModal = ({ showModal = true, closeModal = () => {}, createRfq = () => {} }) => {
	return (
		<Modal
			show={showModal}
			handleClose={closeModal}
			closeButton={false}
			title={
				<Text type="primary" color={theme.colors.red}>
					Notice
				</Text>
			}
			footer={<></>}
			style={{ textAlign: 'center' }}
		>
			<MakeRfq />
			<Text fontSize="20px" fontWeight="700" color={theme.colors.primary} className="mt-3">
				The details from Draft will be used to prepopulate some data in final RFQ now. Once the
				final RFQ is created, draft RFQ will be removed!
			</Text>

			<Button
				primary
				onClick={createRfq}
				className="mt-3"
				style={{
					width: '100%',
					height: '50px',
					fontSize: '18px',
				}}
				label="Continue"
			/>
		</Modal>
	)
}

export default MakeRfqModal
