import { useContext, useEffect, useState } from 'react'
import { Row, Container } from 'react-bootstrap'
import { Divider, Alert, Text, Space, theme } from 'verak-ui'

import { CustomSelectSingle } from './commonStyle'
import { renderLevels, InputGroup } from '../commonUtils/commonExports'
import { Context } from '../../../context/Provider'
import { nanoid } from 'nanoid'

import { isValidOccupancy } from '../commonUtils/SharedUtils'
import PrimaryOccupancySelector from '../../DraftRFQ/components/PrimaryOccupancySelector'

const SingleBasement = ({ onDataComplete, onInputComplete }) => {
	const [state] = useContext(Context)

	const { Type } = state.location || {}
	const { PrimaryOccupancy, Level: floorNum } =
		(Type === 'PROPERTYBASEMENT' ? state.location?.basement : state?.location) || {}

	const [Level, setLevel] = useState(floorNum || '')
	const [Occupancy, setOccupancy] = useState(PrimaryOccupancy?.SubCategory || '')

	const validateInput = () => {
		return !!Level && !!isValidOccupancy(Occupancy)
	}

	useEffect(() => {
		const val = validateInput()
		onInputComplete(val) // to enable save and continue button
		if (val) {
			onDataComplete({
				levels: [
					{
						Level: Level,
						ID: nanoid(),
						PrimaryOccupancy: {
							SubCategory: Occupancy,
						},
					},
				],
			})
		}
	}, [Level, Occupancy]) // eslint-disable-line

	return (
		<>
			<Divider top="2rem" bottom="2rem" />
			<Text style={{ fontWeight: 700, fontSize: '24px' }} mobileFontSize="14px">
				Please select the primary use of this risk location
				<span style={{ color: theme.colors.red }}>*</span>
			</Text>
			<Space y={1.5} />
			<Row>
				<div className="col-md-4">
					<InputGroup label="Level no.">
						<CustomSelectSingle
							value={Level}
							onChange={selected => setLevel(selected)}
							placeholder="-1 to -20"
						>
							{renderLevels('BASEMENT')}
						</CustomSelectSingle>
					</InputGroup>
				</div>
				<PrimaryOccupancySelector
					value={Occupancy}
					isRiskLocationDetailsScreen
					onChange={(key, value) => {
						setOccupancy(value.SubCategory)
					}}
				/>
			</Row>

			<Space y={1} />
			<Container>
				<Alert
					bgColor={theme.colors.secondaryLightRed}
					iconName="infoIconOctagon"
					infoIconColor={theme.colors.red}
					titleColor={theme.colors.red}
					title="Note"
				>
					<Text
						color={theme.colors.red}
						style={{
							fontWeight: 'bold',
						}}
					>
						IF the client uses this floor for multiple purposes - please ONLY enter the primary use
						above.
						<br />
						You will be asked about “other usage” later in the journey - which is where the client
						can disclose other activities.
					</Text>
				</Alert>
			</Container>
		</>
	)
}

export default SingleBasement
