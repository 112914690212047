import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap'
import { MainContainer, Divider, theme, Button, Input, Text } from 'verak-ui'

import confirmationLottie from '../../assets/confirmationLottie.svg'
import { useFetch } from '../../api/apihook'
import { validateEmail } from '../../utilities/validateEmail'

const SendEmailConfirmationScreen = ({ changeToScreen2 }) => {
	const { sendEmailConfirmation } = useFetch()

	const [email, setEmail] = useState('')

	const isEmailValid = email !== '' && validateEmail(email)

	const submitForm = async () => {
		if (validateEmail(email)) {
			const res = await sendEmailConfirmation(email)
			if (res?.status === 200) {
				changeToScreen2()
			} else {
				alert('Something went wrong, Please try again')
			}
		} else {
			// todo change this to toast
			alert('Please enter a valid email')
		}
	}

	const handleChange = e => {
		setEmail(e.target.value)
	}

	return (
		<MainContainer
			headerBg={theme.colors.secondary}
			icon={'yes'}
			header={'Some more info'}
			divider={false}
			hasLeftBtn={false}
		>
			<Row>
				<Col className="d-flex flex-column align-items-center mb-5 mt-5">
					<FormContainer>
						<Text
							className="mb-3 text-center"
							fontSize="18px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Please enter the customer email ID to send the confirmation link
						</Text>
						<Input
							placeholder="Enter"
							value={email}
							onChange={handleChange}
							style={{ width: '100%' }}
							label={
								<>
									Email ID <span style={{ color: '#eb5757' }}>*</span>
								</>
							}
							className="mb-3"
							type="email"
							error={!isEmailValid}
						/>
						<div className="d-flex">
							<Button
								disabled={!isEmailValid}
								style={{ width: '100%' }}
								label="Save and continue"
								onClick={submitForm}
							></Button>
						</div>
					</FormContainer>
				</Col>
			</Row>
		</MainContainer>
	)
}

const EmailConfirmationSent = () => {
	return (
		<MainContainer
			headerBg={theme.colors.secondary}
			icon={'yes'}
			header={'Progress!'}
			divider={false}
			hasLeftBtn={false}
		>
			<Row>
				<Col className="d-flex flex-column align-items-center mb-5">
					<Text
						className="mb-3 text-center"
						fontSize="24px"
						fontWeight="700"
						color={theme.colors.primary}
					>
						Confirmation link has been sent out to the customer.
					</Text>
					<ConfirmationSuccessImage className="mb-4" src={confirmationLottie} />

					<CustomDiv className="d-flex flex-column align-items-center">
						<Text
							className="mb-5 text-center"
							fontSize="18px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Please ask them to confirm their risk location details by clicking on the link sent to
							their Email/SMS
						</Text>

						<Text
							className="mb-5 text-center"
							fontSize="18px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							Once they do so, ask them to enter the OTP sent on their registered number to
							authorize the quote generation process.
						</Text>

						<Text
							className="mb-2 text-center"
							fontSize="14px"
							fontWeight="400"
							color={theme.colors.primary}
						>
							We will notify you when the customer has completed the process
						</Text>

						<Divider />

						<Button icon="rightArrow" iconAlign="right" label="Continue to home" />
					</CustomDiv>
				</Col>
			</Row>
		</MainContainer>
	)
}

export default function ConfirmationEmail() {
	const [activeScreen, setActiveScreen] = useState('send-email-confirmation')

	const changeToScreen2 = () => {
		setActiveScreen('email-confirmation-sent')
	}

	const renderScreen1 =
		activeScreen === 'send-email-confirmation' ? (
			<SendEmailConfirmationScreen changeToScreen2={changeToScreen2} />
		) : null

	const renderScren2 = activeScreen === 'email-confirmation-sent' ? <EmailConfirmationSent /> : null

	return (
		<Container>
			{renderScreen1}
			{renderScren2}
		</Container>
	)
}

const FormContainer = styled.div`
	width: 420px;
	padding: 16px;
	border: 1px solid #f78670;
	border-radius: 8px;
`

const lottieAnimation = keyframes`
from{
  transform: scale(1);
}
to{
  transform: scale(.5);
}
`

const fadeIn = keyframes`
from{
opacity: 0.3;
}
to{
  opacity: 1;
}
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const CustomDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`
