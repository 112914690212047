function hasMatch(occupancyString, videoTitle) {
	const videoTitleWords = videoTitle?.split(' ')
	let occupancyWords = occupancyString?.split(':-')

	if (occupancyWords[0].includes('/')) {
		occupancyWords = [...occupancyWords[0]?.split('/'), occupancyWords?.[1]]
	}
	const match = videoTitleWords?.some(word => occupancyWords?.includes(word))
	return match
}

const getVideoSuggestions = (occupancies, videos) => {
	if (!occupancies) return []
	let suggestedVideos = []
	let otherVideos = []

	const videoTitles = videos.map(video =>
		video.title.toLowerCase()?.replace('insurance', '')?.trim()
	)
	occupancies.forEach(occupancy => {
		const occupancyString = occupancy.toLowerCase()
		videoTitles.forEach(title => {
			if (hasMatch(occupancyString, title)) {
				const targetVideo = videos.find(video => video.title.toLowerCase().includes(title))
				if (targetVideo) {
					suggestedVideos.push(targetVideo)
				}
			}
		})
	})
	const suggestedVideoIds = suggestedVideos.map(video => video.id)
	otherVideos = videos.filter(video => !suggestedVideoIds.includes(video.id))
	return [suggestedVideos, otherVideos]
}

export { getVideoSuggestions }
