import CheatSheetWhyUs from './content/CheatSheet_WhyUs'
import CheatSheetVyaparSuraksha from './content/CheatSheet_VyaparSuraksha'
import CheatSheetWIC from './content/CheatSheet_WIC'
import CheatSheetPaperless from './content/CheatSheet_Paperless'
import CheatSheetNoClaimRejection from './content/CheatSheet_NoClaimRejection'
import CheatSheetBenefits from './content/CheatSheet_Benefits'
import CheatSheetConvincing from './content/CheatSheet_Convincing'
import CheatSheetTermsConditions from './content/CheatSheet_TermsConditions'
import CheatSheetMoneyInsurance from './content/CheatSheet_MoneyInsurance'

const materialIndex = {
	WHY_US: {
		icon: '⚡',
		title: 'Why Us',
		content: <CheatSheetWhyUs />,
	},
	VYAPAR_SURAKSHA: {
		icon: '⛱',
		title: 'Vyapar Suraksha',
		content: <CheatSheetVyaparSuraksha />,
	},
	CONVINCING: {
		icon: '💰',
		title: 'Convincing',
		content: <CheatSheetConvincing />,
	},
	TERMS_CONDITIONS: {
		icon: '📄',
		title: 'Terms & Conditions',
		content: <CheatSheetTermsConditions />,
	},
	COVERED: {
		icon: '🔥',
		title: 'What is covered?',
		content: <CheatSheetWIC />,
	},
	PAPERLESS: {
		icon: '🌐',
		title: 'Paperless',
		content: <CheatSheetPaperless />,
	},
	BENEFITS: {
		icon: '🎁',
		title: 'Built in Benefits',
		content: <CheatSheetBenefits />,
	},
	NO_CLAIM_REJECTION: {
		icon: '❌',
		title: 'No claim rejection',
		content: <CheatSheetNoClaimRejection />,
	},
	MONEY_INSURANCE: {
		icon: '💰',
		title: 'Money Ins. Addon',
		content: <CheatSheetMoneyInsurance />,
	},
}

export default materialIndex
