import { useState, useEffect } from 'react'
import { convertToNumber } from '../../../utilities/convertToNumber'

import {
	Container,
	FlexContainer,
	selectStyle,
	secondarySelectStyle,
	textStyle,
	buttonStyle,
	StyledLabel,
	CompulsoryStar,
} from './DetailsCollectionTemplate'
import { FreeTextSwitchModal } from '../../PropertyRiskDataCollection/commonUtils/commonExports'
import {
	Space,
	Text,
	theme,
	Button,
	Input,
	Checkbox,
	SearchableSelect,
	FreeTextToggle,
} from 'verak-ui'
import { useFetch } from '../../../api/apihook'

const StockType = ({
	detailsList,
	incrementStockType,
	decrementStockType,
	autoCalculate,
	onStockFieldChange,
	secondaryContainerStyle = { border: '1px solid #F78670' },
	type,
}) => {
	const [natureOfStockMap, setNatureOfStockMap] = useState([])
	const { getNatureOfStock } = useFetch()

	const prepareNatureOfStockMap = async () => {
		const natureOfStock = await getNatureOfStock()
		setNatureOfStockMap(
			natureOfStock.required.map(({ Description }) => {
				return {
					name: Description,
					value: Description,
				}
			})
		)
	}

	useEffect(() => {
		prepareNatureOfStockMap()
	}, [])

	const handleNumberInput = (idx, e, attribute) => {
		const numValue = convertToNumber(e.target.value)
		onStockFieldChange(idx, numValue, attribute)
	}

	const declarationEle = (idx, AmountClosed, AmountOpen) => (
		<>
			<Input
				label="Value of stock covered"
				placeholder="Enter value here"
				value={AmountClosed}
				image="stockCovered2"
				name="AmountClosed"
				autoComma
				rupee
				compulsory
				onChange={e => handleNumberInput(idx, e, 'AmountClosed')}
			/>
			<Input
				label="Value of stock kept in open"
				placeholder="Enter value here"
				value={AmountOpen}
				image="stockOpen2"
				name="AmountOpen"
				autoComma
				rupee
				compulsory
				onChange={e => handleNumberInput(idx, e, 'AmountOpen')}
			/>
		</>
	)

	const floaterEle = idx => (
		<>
			<Input
				label="Percentage (%) of stock covered"
				placeholder="Enter value here"
				image="stockCovered2"
				name="ClosedPer"
				type="number"
				value={detailsList?.[idx]?.['ClosedPer']} // todo: handle the "NaN" error
				onBlur={e => autoCalculate(idx, e.target.value, 'OpenPer')}
				onChange={e => onStockFieldChange(idx, e.target.value, 'ClosedPer')}
			/>
			<Input
				label="Percentage (%) of stock kept in open"
				placeholder="Enter value here"
				image="stockOpen2"
				name="OpenPer"
				type="number"
				value={detailsList?.[idx]?.['OpenPer']} // todo: handle the "NaN" error
				onBlur={e => autoCalculate(idx, e.target.value, 'ClosedPer')}
				onChange={e => onStockFieldChange(idx, e.target.value, 'OpenPer')}
			/>
		</>
	)

	const stockTypeEle = detailsList.map((el, idx) => (
		<Container key={idx} style={secondaryContainerStyle}>
			{/* because of this line deletion occurs correctly - "key" */}
			<div className="flex-container" style={{ justifyContent: 'space-between' }}>
				<Text style={textStyle} color={theme.colors.secondary}>
					Stock Type {idx + 1}
				</Text>
				{detailsList.length > 1 ? (
					<Button
						icon="remove"
						label="Remove"
						iconStyles={{ marginRight: '0.5rem' }}
						onClick={() => decrementStockType(idx)}
						style={{ background: '#FCEBE3' }}
						textColor={theme.colors.primary}
					/>
				) : null}
			</div>
			<Space y={1} />
			{/* Select component to be extended to include a "label" and "compulsory" property */}
			<StyledLabel>
				Nature of stock <CompulsoryStar />
			</StyledLabel>
			<IndividualNatureInput
				idx={idx}
				item={el}
				onStockFieldChange={onStockFieldChange}
				natureOfStockMap={natureOfStockMap}
			/>
			<FlexContainer>
				{type === 'Declaration'
					? declarationEle(idx, el?.AmountClosed, el?.AmountOpen)
					: floaterEle(idx)}
			</FlexContainer>
			<Space y={1.5} />
			<Checkbox
				value={el?.GoodsHeldInTrust}
				text="Are these Goods Held In Trust?"
				textStyle={{ fontSize: '18px', color: '#6D6D6D' }}
				containerStyle={{ marginLeft: '0.25rem' }}
				onChange={() => onStockFieldChange(idx, !el?.GoodsHeldInTrust, 'GoodsHeldInTrust')}
			/>
			<Space y={1} />

			{/* Showing the add another party only for the last element */}
			{idx === detailsList?.length - 1 ? (
				<>
					<Space y={1.5} />
					<Button
						label="Add another stock type"
						textColor={theme.colors.primary}
						bgColor={theme.colors.white}
						icon="add"
						iconStyles={{ marginRight: '0.5rem' }}
						style={buttonStyle}
						onClick={incrementStockType}
					/>
				</>
			) : null}
		</Container>
	))

	return stockTypeEle
}

export default StockType

const IndividualNatureInput = ({ idx, item, onStockFieldChange, natureOfStockMap }) => {
	const [isFreeTextResponse, setIsFreeTextResponse] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalShown, setModalShown] = useState(false)

	useEffect(() => {
		if (item.Type === '') {
			// can't combine these 2 conditions since the else is dependent on only the condition of the main 'if'
			if (!modalShown) {
				// case of when you click the add another stock type
				setIsFreeTextResponse(false)
			}
		} else {
			setIsFreeTextResponse(!natureOfStockMap?.some(el => el.name === item.Type))
			// checks if value is present in the nature list -> then its not a free text response
		}
	}, [natureOfStockMap, item])

	const changeToFreeTextResponse = () => {
		if (!modalShown) {
			setShowModal(true)
			setModalShown(true)
		}

		onStockFieldChange(idx, '', 'Type')
		setIsFreeTextResponse(prevState => !prevState)
	}

	return (
		<>
			<FreeTextSwitchModal
				show={showModal}
				handleClose={() => setShowModal(false)}
				warningFor="nature of stock"
			/>
			<Space y={1} />

			{isFreeTextResponse ? (
				<Input
					placeholder="Enter details here"
					value={item.Type}
					onChange={e => onStockFieldChange(idx, e.target.value, 'Type')}
				/>
			) : (
				<SearchableSelect
					placeholder="Nature of Stock"
					value={item.Type}
					style={{
						...selectStyle,
						...secondarySelectStyle,
					}}
					customStyle={{
						'@media(min-width: 768px)': {
							width: '360px !important',
						},
					}}
					options={natureOfStockMap}
					onChange={e => onStockFieldChange(idx, e, 'Type')}
				/>
			)}

			<Space y={0.5} />
			<div className="flex-container">
				<FreeTextToggle
					onChange={() => changeToFreeTextResponse()}
					isFreeTextResponse={isFreeTextResponse}
				/>
			</div>
			<Space y={1.5} />
		</>
	)
}
