import { Modal, Button, Input, Text, theme } from 'verak-ui'
import { useState } from 'react'

export default function AddCustomSIFieldModal({ handleClose, onAddSIField }) {
	const [siFieldName, setSiFieldName] = useState('')

	return (
		<Modal
			show={true}
			handleClose={handleClose}
			title={
				<Text color={theme.colors.red} fontSize="24px" fontWeight="700">
					Action Required
				</Text>
			}
			footer={<></>}
		>
			<div className="w-100 d-flex flex-column align-items-center">
				<Text className="text-center mb-3" color="#4c4c4c" fontSize="24px" fontWeight="700">
					What is being covered as a valuable content
				</Text>

				<div className="d-flex flex-column w-100">
					<Text color={theme.colors.gray2} fontSize="14px" fontWeight="500">
						Name of the Other/Valuable Content <span style={{ color: theme.colors.red }}>*</span>
					</Text>
					<Input
						value={siFieldName}
						onChange={e => setSiFieldName(e.target.value)}
						placeholder="Enter here"
						className="w-100 mt-2"
					/>
				</div>
				<Button
					onClick={() => {
						if (siFieldName) {
							onAddSIField(siFieldName)
						}
					}}
					primary
					label="Continue"
					style={{ height: '48px' }}
					className="w-100 mt-4"
					disabled={!siFieldName}
				/>
			</div>
		</Modal>
	)
}
