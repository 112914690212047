import styled from 'styled-components'
import { theme } from 'verak-ui'
import { useEffect, useRef } from 'react'

import { sections } from '../components/sections'

const ContentViewer = ({ activeSection, selectedTopic }) => {
	const content = sections?.[activeSection]?.contentIndex?.[selectedTopic]?.content
	const contentViewRef = useRef(null)

	useEffect(() => {
		const scrollValue = contentViewRef.current.scrollTop
		if (scrollValue > 0) {
			contentViewRef.current.scroll({
				top: -scrollValue,
				behavior: 'smooth',
			})
		}
	}, [selectedTopic])

	return (
		<ContentViewerWrapper className="d-flex flex-column" ref={contentViewRef}>
			{content}
		</ContentViewerWrapper>
	)
}

export default ContentViewer

const ContentViewerWrapper = styled.div`
	background: ${theme.colors.white};
	width: 72%;
	border-radius: 8px;
	overflow-y: auto;
	height: 100%;
	scrollbar-width: thin;
	scrollbar-color: #c5c5c5 #f6f6f6;
	* {
		font-family: Inter;
	}
`
