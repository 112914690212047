import { useState } from 'react'
import { Modal, Text, Button, theme, Space } from 'verak-ui'
import { useFetch } from '../api/apihook'
import AlertSiren from '../assets/icons/AlertSiren.svg'
import { useContextApi } from '../context/Provider'
import { editRfqAllowedStatuses, isRfqEqual } from '../helpers/editRfqUtils'
import { NewQuotesRequired, QuotesNotRequired } from './EditLostWarningModal'

const MODAL_STATES = {
	CONFIRM: 'CONFIRM',
	RESULT: 'RESULT',
}

const buttonStyle = {
	height: '48px',
	fontSize: '18px',
}

export default function EditNoticeModal({ show, handleClose, saveRfqWithNote, redirectForward }) {
	const [state, dispatch] = useContextApi()
	const fetch = useFetch()
	const [areQuotesRequired, setAreQuotesRequired] = useState(null)
	const [activeState, setActiveState] = useState(MODAL_STATES.CONFIRM)

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	const proceedResubmit = async () => {
		await saveRfqWithNote()

		if (state?.rfq?.editRfqMode === true) {
			const validRFQStatus = editRfqAllowedStatuses.includes(rfqStatus)
			if (validRFQStatus && state?.rfq?.editRfqMode && !isRfqEqual(state?.quote, state?.oldRfq)) {
				const result = await fetch.resubmitRfqOnUpdate(state?.quote?.quoteId, state?.oldRfq)
				setAreQuotesRequired(result?.newQuotesRequired)
			}
			await new Promise(resolve => {
				dispatch({ type: 'CLEAR_OLD_RFQ' })
				dispatch({
					type: 'SET_EDIT_RFQ',
					data: { editRfqMode: false },
				})
				resolve()
			})
		}
		setActiveState(MODAL_STATES.RESULT)
	}

	let modalContent = null
	switch (activeState) {
		case MODAL_STATES.CONFIRM:
			modalContent = (
				<div className="d-flex flex-column align-items-center">
					<img src={AlertSiren} alt="" />
					<Text fontSize="36px" fontWeight="700" color={theme.colors.red} className="my-2">
						Read Carefully
					</Text>

					<Text
						className="my-2"
						fontSize="22px"
						fontWeight="700"
						color={theme.colors.black}
						style={{ opacity: 0.5, textAlign: 'center' }}
					>
						Changes made may result in new quotes being added
					</Text>
					<Text
						fontSize="22px"
						fontWeight="700"
						color={theme.colors.gray2}
						style={{ textAlign: 'center' }}
						className="mb-3"
					>
						Click
						<span
							style={{
								color: theme.colors.primary,
								fontSize: '22px',
								fontWeight: '700',
								margin: 'auto 6px',
							}}
						>
							Proceed
						</span>
						to submit new changes
					</Text>

					<div className="d-flex align-items-center w-100">
						<Button
							secondary
							label="Cancel"
							style={buttonStyle}
							className="w-100"
							onClick={handleClose}
						/>
						<Space x={1} />
						<Button
							primary
							label="Proceed"
							className="w-100"
							style={buttonStyle}
							onClick={proceedResubmit}
						/>
					</div>
				</div>
			)
			break
		case MODAL_STATES.RESULT:
			const resultContainer = areQuotesRequired ? NewQuotesRequired : QuotesNotRequired
			modalContent = (
				<div className="d-flex flex-column align-items-center">
					{resultContainer}
					<Button
						primary
						label="Proceed"
						style={buttonStyle}
						className="w-100"
						onClick={() => {
							setActiveState(MODAL_STATES.CONFIRM)
							redirectForward()
						}}
					/>
				</div>
			)
			break
		default:
	}

	return (
		<Modal
			title={
				<Text fontSize="24px" fontWeight="700" color={theme.colors.red}>
					NOTE
				</Text>
			}
			footer={<></>}
			show={show}
			handleClose={handleClose}
			closeButton={activeState !== MODAL_STATES.RESULT}
		>
			{modalContent}
		</Modal>
	)
}
