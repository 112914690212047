import { useHistory } from 'react-router'
import FinalReviewScreen from '../../../FinalReview/FinalReview'

const ClientVerificationFinalReview = () => {
	const history = useHistory()

	const onBack = () => {
		history.push('/rfq-status/client-verification-pending')
	}
	const onProceed = () => {
		history.push('/rfq-status/client-verification-pending/sum-insured-breakdown')
	}
	return <FinalReviewScreen onBack={onBack} onProceed={onProceed} />
}

export default ClientVerificationFinalReview
