import { useState } from 'react'
import { theme, Text, Button, Icon, Checkbox } from 'verak-ui'
import {
	// styles
	QuoteImg,
	QuoteCardWrapper,
	LeftText,
	ValueCell,
	// utils
	infoRowOrder,
	insurerMetrics,
	InsurerImagesMap,
	addonStyleConfig,
	getAddOnLabel,
	getSumInsuredDisplayName,
	safetyPlanInfoRowOrder,
	FeatureItem,
} from './quoteComparisonUtils'

import { convertAmountToWords } from '../../../../RiskLocationReview/reviewScreenUtils'

// utils
import { getFormattedAmount } from '../../../../../utilities/getFormattedAmount'
import { coverageValues } from '../../../../../utilities/coverageDateUtils'
import { getDiffYears } from '../../../../../utilities/getCorrectDateFormat'
import roundOffNum from '../../../../../utilities/roundOffNum'
import { calculateBurglarySI } from '../../../../../utilities/calculateBurglarySI'

import noAddons from '../../../../../assets/icons/addonIcons/noAddons.svg'
import { useFetch } from '../../../../../api/apihook'
import { toast } from 'react-toastify'

const getNonRefCopy = val => {
	return JSON.parse(JSON.stringify(val))
}

export default function QuoteCard({
	rfqId,
	quote,
	allQuotes,
	SumInsuredBreakdown,
	navigateTo,
	showInfoModal,
	showSIBreakupModal,
	onToggleSIBreakupModal,
	onToggleAddOnSIBreakup,
	clientRequestedAddon,
	showQuoteSelectionWarning,
	selectQuoteForPurchase,
	addonsExpanded,
	handleAddonExpand,
	refetchQuotes,
	rfqTag,
}) {
	const [addons, setAddons] = useState(() => getNonRefCopy([...quote?.Addons]) || [])
	const [showSaveButton, setShowSaveButton] = useState(false)

	const { updateAddonSelection } = useFetch()

	const isSafetyPlan = rfqTag === 'SAFETY_PLAN'

	const cctvProduct = quote?.HardwareProducts.find(({ Key }) => Key === 'CCTV_01')

	const handleAddonChange = (selection, addonName) => {
		handleAddonExpand(true)

		const addonsCopy = getNonRefCopy(addons)
		const targetAddon = addonsCopy.find(a => a.Name === addonName)
		targetAddon.Rejected = !selection
		setAddons(addonsCopy)

		setShowSaveButton(true)
	}

	const handleSaveChanges = async () => {
		const payload = addons.reduce((acc, curr) => {
			return { ...acc, [curr.Name]: curr.Rejected }
		}, {})
		const result = await updateAddonSelection(rfqId, quote.id, payload)
		if (!result?.error) {
			toast.success('Addons updated successfully!')
		}
		await refetchQuotes()
		setShowSaveButton(false)
	}

	const handleCancelChange = () => {
		setAddons([...quote?.Addons])
		setShowSaveButton(false)
	}

	const getInsurerImageContainer = (value, emi) => {
		return (
			<div style={{ position: 'relative' }}>
				{emi && (
					<div style={{ position: 'absolute', right: '-4.4rem', top: '-.5rem' }}>
						<Icon name="emiAvailable" />
					</div>
				)}
				<QuoteImg src={InsurerImagesMap[value]} alt={value} />
			</div>
		)
	}

	// Value specific functions
	const getPremiumContainer = quoteItem => {
		const totalPremium = quoteItem?.Amount
		let durationValue = coverageValues.find(item => item.value === quoteItem?.Duration)
		let durationString = durationValue.name
		if (durationString === '12 months') {
			durationString = '1 Year'
		}

		if (isNaN(totalPremium)) {
			return (
				<Text className="mx-2" fontSize="36px" fontWeight="700" color={theme.colors.gray4}>
					- NA -
				</Text>
			)
		} else {
			const perDayPremium = Math.round(totalPremium / durationValue?.months / 30)
			return (
				<div className="d-flex flex-column w-100">
					<span style={{ fontSize: '16px' }} className="d-flex flex-column align-items-center mb-2">
						<Text
							className="mx-2 d-flex align-items-center"
							fontSize="36px"
							fontWeight="700"
							color={theme.colors.primary}
						>
							<span style={{ fontSize: '20px', margin: '0.5rem' }}>₹</span>
							{getFormattedAmount(totalPremium)} /{' '}
							<span style={{ fontSize: '20px', margin: '0.5rem' }}>{durationString}</span>
						</Text>
						<Text fontSize="16px" fontWeight="700" color="#4F76E8">
							Costs: ₹&nbsp;{getFormattedAmount(perDayPremium)} / per day (approx)
						</Text>
					</span>

					<Button
						primary={!quote?.Selected}
						secondary={quote?.Selected}
						label={quote?.Selected ? 'Selected' : isSafetyPlan ? 'Buy Safety Plan' : 'Purchase'}
						style={{ height: '48px' }}
						className="w-100"
						onClick={() => {
							if (!quote?.Selected) {
								showQuoteSelectionWarning(quoteItem)
							} else {
								selectQuoteForPurchase(true)
							}
						}}
					/>
				</div>
			)
		}
	}

	const NASymbol = () => {
		return (
			<Text className="mx-2" fontSize="18px" fontWeight="700" color={theme.colors.gray5}>
				NA
			</Text>
		)
	}

	const getCoverContainer = Cover => {
		return (
			<div className="d-flex align-item-center">
				<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
					{convertAmountToWords(`₹ ${Cover}`)}
				</Text>
			</div>
		)
	}

	const getDurationContainer = duration => {
		let durationValue = coverageValues.find(item => item.value === duration)
		let durationString = durationValue.name
		if (durationString === '12 months') {
			durationString = '1 Year'
		}
		return (
			<div className="d-flex align-item-center">
				<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
					{durationString}
				</Text>
			</div>
		)
	}

	const getExpiryContainer = quoteItem => {
		let toDisplay
		if (quoteItem.Status === 'REJECTED') {
			return <NASymbol />
		} else {
			// calculate days from lastUpdated
			//https://stackoverflow.com/a/3224854/5149570
			const today = new Date()
			const lastUpdatedDate = new Date(quoteItem.updatedAt)
			const diffTime = Math.abs(today - lastUpdatedDate)
			const daysPassed = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

			let expiresIn = Number(quoteItem.Expiry) ? Number(quoteItem.Expiry) : 8
			const diffDays = expiresIn - daysPassed

			if (diffDays >= 1) {
				if (diffDays > 1) {
					toDisplay = `${diffDays} days`
				} else {
					toDisplay = `${diffDays} day`
				}
				return (
					<span className="d-flex align-item-center">
						<Text fontSize="18px" fontWeight="700" color={theme.colors.primary}>
							{toDisplay}
						</Text>
					</span>
				)
			} else {
				return (
					<span className="d-flex align-item-center">
						<Text fontSize="18px" fontWeight="700" color={theme.colors.red}>
							Expired
						</Text>
					</span>
				)
			}
		}
	}

	const getAddonsContainer = sumInsuredBreakdown => {
		if (addons?.length > 0) {
			const showAddons = !addonsExpanded && addons?.length > 2 ? addons.slice(0, 2) : addons
			const allAddons = showAddons.map((addon, index) => {
				const addonName = addon?.Name
				const addonLabel = getAddOnLabel(addon?.Name)

				let addonSumInsured = addon?.SumInsured
				if (addon?.Name === 'Burglary' || addon?.Name === 'BURGLARY_THEFT') {
					addonSumInsured = calculateBurglarySI(sumInsuredBreakdown)
				}

				const isAddonRejected = addon?.Rejected === true

				return (
					<div className="d-flex">
						<Checkbox
							value={!isAddonRejected}
							onChange={val => handleAddonChange(val, addon?.Name, quote?.id)}
						/>
						<div key={index} className="my-2 d-flex flex-column align-items-start">
							<div className="d-flex align-items-start">
								<img src={addonStyleConfig[addonName]?.icon} alt={addonName} />
								<Text
									className="mx-1 text-left"
									color={addonStyleConfig[addonName]?.color}
									fontWeight="700"
									fontSize="16px"
								>
									{addonLabel?.toUpperCase()}
								</Text>
							</div>
							<Text
								className="d-flex mx-1 align-items-center"
								fontSize="18px"
								fontWeight="700"
								color={theme.colors.primary}
							>
								Insured - {convertAmountToWords(`₹ ${roundOffNum(addonSumInsured || 0)}`)}{' '}
							</Text>
							{/* {isAddonRejected && (
								<Text className="mx-1" fontWeight="600" color="rgba(0,0,0,0.5)" fontSize="12px">
									(NOT APPROVED BY INSURER)
								</Text>
							)} */}
							{!isAddonRejected && (
								<Text className="mx-1" fontSize="16px" fontWeight="500" color={theme.colors.gray3}>
									Premium - {getFormattedAmount(`₹ ${roundOffNum(addon?.Amount || 0)}`)}*/year
								</Text>
							)}
						</div>
					</div>
				)
			})

			return (
				<div className="w-100 px-4">
					<div className="d-flex flex-column align-items-start">{allAddons}</div>
					{showSaveButton && (
						<div className="d-flex justify-content-between align-items-center mt-1">
							<Button
								primary
								label="Save changes"
								onClick={handleSaveChanges}
								style={{ height: '48px', width: '130px', fontSize: '12px' }}
							/>
							<Button
								secondary
								label="Cancel"
								onClick={handleCancelChange}
								style={{ height: '48px', width: '100px', fontSize: '12px' }}
							/>
						</div>
					)}
					<div className="d-flex justify-content-between align-items-center mt-1">
						<Text color={theme.colors.gray3} fontSize="16px" fontWeight="500">
							{addons?.length > 2 && !addonsExpanded ? `${addons?.length - 2} more Add-ons` : ''}
						</Text>
						{addons?.length > 2 && (
							<div
								style={{ textAlign: 'right', cursor: 'pointer' }}
								onClick={() => handleAddonExpand(!addonsExpanded)}
							>
								<Text fontSize={'14px'} color={'#83A1F8'}>
									{addonsExpanded ? 'Collapse' : 'See All'}
								</Text>
							</div>
						)}
					</div>
				</div>
			)
		} else {
			return (
				<div className="p-2 d-flex flex-column">
					<div className="w-100 d-flex align-items-center">
						<img src={noAddons} alt="no addons" />
						<Text className="mx-1" fontSize="16px" fontWeight="700" color={theme.colors.red}>
							NO ADD ONS
						</Text>
					</div>
					<Text className="mt-1" fontSize="12px" fontWeight="500" color={theme.colors.gray3}>
						NOTE - Add ons can be opted later too
					</Text>
				</div>
			)
		}
	}

	const getConditionsContainer = (
		quoteItem,
		navigateTo,
		showInfoModal,
		SumInsuredBreakdown,
		showSIBreakupModal,
		onToggleSIBreakupModal
	) => {
		const navigateToInsurerRequirements = () => {
			const url = '/rfq-status/quotes-ready/complete-insurer-requirements'
			navigateTo(url, { ...quoteItem, quoteId: quoteItem.id })
		}

		const showRejectionReasonModal = RejectionReason => {
			showInfoModal({ type: 'REJECTION', content: RejectionReason })
		}

		const showConditionsModal = Conditions => {
			showInfoModal({ type: 'CONDITIONS', content: Conditions })
		}

		const furtherRequirements = quoteItem?.FurtherRequirements
		let checkFurtherRequirementsExists
		let awaitingQuotes = false
		if (furtherRequirements.length > 0) {
			if (furtherRequirements?.every(item => !item?.Answer)) {
				checkFurtherRequirementsExists = true
			} else {
				awaitingQuotes = true
			}
		}

		if (quoteItem?.Status === 'ACCEPTED') {
			let iconConfig = { name: 'tickCircle', color: theme.colors.green }
			let textConfig = { text: 'Proposal accepted', color: theme.colors.primary }
			const isConditional = !!quoteItem?.Conditions
			if (quoteItem.Conditions?.length > 0) {
				textConfig = {
					text: (
						<>
							Proposal has been <span style={{ color: '#75CC1F' }}>accepted</span> with{' '}
							<span style={{ color: '#F2994A' }}>special conditions</span>
						</>
					),
					color: '#8D8D8D',
				}
				iconConfig = { name: 'squareTick' }
			} else if (!!quoteItem.ReducedSumInsured) {
				textConfig = { text: `Total Sum Insured has been reduced`, color: '#2F80ED' }
				iconConfig = { name: 'lockInSquare', color: '#2F80ED' }
			}

			let sumInsuredBreakdownItems = []
			const sumInsuredList = Object.entries(SumInsuredBreakdown)

			sumInsuredBreakdownItems = sumInsuredList.slice(0, 2).map(([key, value], index) => {
				return (
					<div key={key}>
						<LeftText fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
							{getSumInsuredDisplayName(key)}
						</LeftText>
						<LeftText fontSize="20px" fontWeight="700" color={theme.colors.primary}>
							{convertAmountToWords(`₹ ${roundOffNum(value)}`)}
						</LeftText>
					</div>
				)
			})

			const handleClick = () => {
				if (isConditional) {
					showConditionsModal(quoteItem?.Conditions)
				}
			}

			return (
				<div className="d-flex flex-column p-3 px-4 w-100 align-items-start">
					<div
						className="d-flex align-items-center mb-3"
						style={{ cursor: 'pointer' }}
						onClick={handleClick}
					>
						<Icon name={iconConfig.name} color={iconConfig.color} size={'30px'} className="me-2" />
						<Text
							fontSize="14px"
							fontWeight="700"
							color={textConfig.color}
							className="w-100"
							style={{ textAlign: 'left' }}
						>
							{textConfig.text}
						</Text>
						{isConditional && (
							<Icon
								name="rightArrow"
								color="grey"
								style={{ background: '#f0f0f0', marginLeft: '0.5rem' }}
							/>
						)}
					</div>

					<>
						<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
							Total Sum Insured
						</Text>
						<Text fontSize="20px" fontWeight="800" color="#333333">
							{convertAmountToWords(`₹ ${quoteItem?.Cover}`)}
						</Text>
						<div className="mx-3 mt-2 px-1">{sumInsuredBreakdownItems}</div>
						{sumInsuredList.length > 2 && (
							<div className="w-100" style={{ textAlign: 'right' }}>
								<Text
									fontSize={'14px'}
									color={'#83A1F8'}
									style={{ cursor: 'pointer' }}
									onClick={onToggleSIBreakupModal}
								>
									See All
								</Text>
							</div>
						)}
					</>
				</div>
			)
		} else if (quoteItem?.Status === 'REJECTED') {
			return (
				<div className="d-flex flex-column p-3 w-100 align-items-start">
					<div className="d-flex align-items-center mb-3">
						<Icon name="warningTriangleSm" color={theme.colors.red} className="me-2" />
						<Text fontSize="14px" fontWeight="700" color={theme.colors.red}>
							Proposal has been rejected
						</Text>
					</div>
					{!!quoteItem?.RejectionReason && (
						<Button
							onClick={() => showRejectionReasonModal(quoteItem.RejectionReason)}
							label="See why"
							icon="rightArrow"
							iconAlign="right"
							bgColor={theme.colors.secondary4}
							iconColor={theme.colors.primary}
							textColor={theme.colors.primary}
							className="w-100"
							style={{ zIndex: '10' }}
						/>
					)}
				</div>
			)
		} else if (checkFurtherRequirementsExists || awaitingQuotes) {
			return (
				<div className="d-flex flex-column p-3 w-100 align-items-start ">
					<div className="d-flex align-items-center mb-3">
						<Icon
							name={awaitingQuotes ? 'clockSm' : 'warningTriangleSm'}
							color={awaitingQuotes ? theme.colors.gray3 : theme.colors.red}
						/>

						<Text
							className="text-left"
							fontSize="14px"
							fontWeight="700"
							color={awaitingQuotes ? theme.colors.gray3 : theme.colors.red}
						>
							{awaitingQuotes
								? 'Awaiting quotes'
								: 'This company is requesting for further information'}
						</Text>
					</div>
					<Button
						onClick={navigateToInsurerRequirements}
						label={awaitingQuotes ? '**Wait patiently**' : 'Complete required info'}
						disabled={awaitingQuotes}
						icon={checkFurtherRequirementsExists && 'rightArrow'}
						iconAlign="right"
						bgColor={awaitingQuotes ? theme.colors.gray3 : theme.colors.red}
						textColor={theme.colors.white}
						iconColor={theme.colors.white}
						className="w-100"
						style={{ zIndex: '10' }}
					/>
				</div>
			)
		}
	}

	const getMetricsContainer = (quoteItem, keyName, quotes) => {
		let allInsurers = quotes.filter(quote => quote.Status !== 'REJECTED').map(item => item.Insurer)

		const allSolvencyRatio = allInsurers.map(item => insurerMetrics[item]?.SolvencyRatio || 0)
		const allClaimsRatio = allInsurers.map(item => insurerMetrics[item]?.ClaimsSettlementRatio || 0)

		const metricValue = insurerMetrics[quoteItem.Insurer]?.[keyName]

		const highestSolvencyRatio = Math.max(...allSolvencyRatio)
		const highestClaimsRatio = Math.max(...allClaimsRatio)

		let highestSolvencyLabel
		if (highestSolvencyRatio === metricValue && allInsurers.length > 1) {
			highestSolvencyLabel = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						{allInsurers.length > 2 ? 'Highest' : 'Higher'} ratio
					</Text>
				</div>
			)
		}

		let highestClaimsLabel
		if (highestClaimsRatio === metricValue && allInsurers.length > 1) {
			highestClaimsLabel = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						{allInsurers.length > 2 ? 'Highest' : 'Higher'} ratio
					</Text>
				</div>
			)
		}
		let greaterThan90Label
		if (metricValue > 90.0) {
			greaterThan90Label = (
				<div className="d-flex mt-2 align-items-center">
					<Icon name="tickCircle" />
					<Text className="mx-2" color={theme.colors.gray3} fontSize="14px" fontWeight="400">
						Greater than 90%
					</Text>
				</div>
			)
		}

		let content
		if (keyName === 'SolvencyRatio') {
			content = (
				<>
					<Text fontSize="20px" fontWeight="700" color={theme.colors.primary}>
						{metricValue || <NASymbol />}
					</Text>
					{highestSolvencyLabel}
				</>
			)
		} else if (keyName === 'ClaimsSettlementRatio') {
			content = (
				<>
					<Text fontSize="20px" fontWeight="700" color={theme.colors.primary}>
						{metricValue ? <>({metricValue})%</> : <NASymbol />}
					</Text>
					{highestClaimsLabel}
					{greaterThan90Label}
				</>
			)
		} else if (keyName === 'EstablishedOn') {
			const years = getDiffYears(metricValue)
			content = (
				<div className="d-flex align-items-center">
					<Text fontSize="20px" fontWeight="700" color={theme.colors.primary} className="me-2">
						{metricValue}
					</Text>
					<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3}>
						{`${years} years ago`}
					</Text>
				</div>
			)
		}
		return <div className="d-flex flex-column p-3 w-100 align-items-start">{content}</div>
	}

	function getPolicyTypeFullForm(policyType) {
		switch (policyType) {
			case 'SFSP':
				return 'Standard Fire & Special Perils'
			case 'BLUS':
				return 'Bharat Laghu Udhyam Suraksha'
			case 'BSUS':
				return 'Bharat Sookshma Udhyam Suraksha'
			default:
				return ''
		}
	}

	function getPolicyType(sumInsured) {
		if (sumInsured > 500000000) {
			return 'SFSP'
		} else if (sumInsured >= 50000000 && sumInsured <= 500000000) {
			// 5 cr and 50 cr
			return 'BLUS'
		}
		return 'BSUS'
	}

	const getPolicyTypeContainer = quoteItem => {
		const policyType = getPolicyType(quoteItem?.Cover || 0)

		return (
			<div className="d-flex flex-column align-items-center justify-content-center">
				<Text fontSize="20px" mobileFontSize="14px" fontWeight="700" color={theme.colors.primary}>
					{policyType}
				</Text>
				<Text
					fontSize="16px"
					mobileFontSize="12px"
					fontWeight="700"
					color="#999999"
				>{`(${getPolicyTypeFullForm(policyType)})`}</Text>
			</div>
		)
	}

	// safety plan methods
	const getProductContainer = (quoteItem, keyName) => {
		const hardwareProduct = quoteItem?.Products?.find(p => p.Key === keyName)
		return (
			<div class="d-flex flex-column align-items-center">
				<img src={hardwareProduct?.Image} width="90px" alt={keyName} />
				<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
					{hardwareProduct?.Manufacturer} - {hardwareProduct?.Model}
				</Text>
				<div className="my-2 d-flex align-items-center justify-content-center">
					<div style={{ height: '1px', width: '40px', background: 'rgba(0,0,0,0.4)' }}></div>
					<Text className="mx-1" fontSize="14px" fontWeight="600" color="rgba(0,0,0,0.4)">
						PRODUCT DESCRIPTION
					</Text>
					<div style={{ height: '1px', width: '30px', background: 'rgba(0,0,0,0.4)' }}></div>
				</div>
				<ul>
					{hardwareProduct?.Features.map((feature, idx) => (
						<FeatureItem key={idx}>{feature}</FeatureItem>
					))}
				</ul>
			</div>
		)
	}

	const getServiceContainer = () => {
		return (
			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
				<span style={{ color: '#4AA161' }}>FREE!</span> Installation and Service
			</Text>
		)
	}

	const getHardwareUnitsContainer = () => {
		return (
			<Text color={theme.colors.primary} fontWeight="700" fontSize="18px">
				{cctvProduct.Units}
			</Text>
		)
	}

	const getInsurerPremiumContainer = quoteItem => {
		return (
			<div className="d-flex flex-column align-items-center">
				<Text color="#333333" fontSize="16px" fontWeight="800">
					₹ {getFormattedAmount(quoteItem?.InsurerPremium || 0)}
				</Text>
				<Text color="#7A7A7A" fontSize="13px" fontWeight="700">
					(ALREADY INCLUDED IN THE COST ABOVE)
				</Text>
			</div>
		)
	}

	const getDynamicContent = (keyName, quoteItem) => {
		const value = quoteItem[keyName]
		switch (keyName) {
			case 'Insurer':
				return getInsurerImageContainer(value, quoteItem.Emi)
			case 'TotalPremium':
				return getPremiumContainer(quoteItem, isSafetyPlan)
			case 'Cover':
				return getCoverContainer(value)
			case 'PolicyType':
				return getPolicyTypeContainer(quoteItem)
			case 'Duration':
				return getDurationContainer(value)
			case 'Expiry':
				return getExpiryContainer(quoteItem)
			case 'Addons':
				return getAddonsContainer(SumInsuredBreakdown)
			case 'Conditions':
				return getConditionsContainer(
					quoteItem,
					navigateTo,
					showInfoModal,
					SumInsuredBreakdown,
					showSIBreakupModal,
					onToggleSIBreakupModal
				)
			case 'SolvencyRatio':
			case 'EstablishedOn':
			case 'ClaimsSettlementRatio':
				return getMetricsContainer(quoteItem, keyName, allQuotes)
			case 'Service':
				return getServiceContainer()
			case 'CCTV_01':
			case 'MEMORY_01':
				return getProductContainer(quoteItem, keyName)
			case 'HardwareUnits':
				return getHardwareUnitsContainer(quoteItem)
			case 'InsurerPremium':
				return getInsurerPremiumContainer(quoteItem)
			default:
				return value?.toString()
		}
	}

	const infoRowOrderMap = isSafetyPlan ? safetyPlanInfoRowOrder : infoRowOrder

	const quoteItems = Object.entries(infoRowOrderMap).map((item, index) => {
		let itemKey = item[0]
		let cellHeight = item[1].height
		const disableCenterAlign = !['Conditions'].includes(itemKey)
		return (
			<ValueCell
				className={`cell-${itemKey}`}
				id={`${itemKey}-${quote?.id}`}
				key={index}
				height={cellHeight}
				centerAlign={disableCenterAlign}
			>
				{getDynamicContent(itemKey, quote)}
			</ValueCell>
		)
	})

	return <QuoteCardWrapper>{quoteItems}</QuoteCardWrapper>
}
