import { useHistory } from 'react-router'
import { useState, useEffect, useContext } from 'react'
import { MainContainer, Space, Radio, Text } from 'verak-ui'
import { Container } from 'react-bootstrap'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { Header, inputStyle, AddressField, Label } from './Common'
import { radioOptionTextStyle } from '../DraftRFQ/DraftRFQForm'
import FormInput from '../../components/FormInput'

import citImg from '../../assets/icons/cit.svg'

const CIT = () => {
	const history = useHistory()
	const [state, dispatch] = useContext(Context)
	let fetch = useFetch()
	const rlData = state?.quote?.RiskLocations[0]

	const prevData = state?.quote?.CIT
	const [CITData, setCITData] = useState(prevData || {})

	const [proceedDisabled, setProceedDisabled] = useState(true)

	const handleCITData = (val, key) => {
		let prevValues = { ...CITData }
		prevValues[key] = val
		setCITData(prevValues)
	}

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_CIT_DATA',
				data: {
					CIT: CITData,
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push('/summary')
	}

	const back = () => {
		history.goBack()
	}

	useEffect(() => {
		if (
			CITData?.DistTransit &&
			CITData?.Amount &&
			CITData?.EstAmountPerTransit &&
			CITData?.Source &&
			CITData?.Destination &&
			CITData?.CarriageMode &&
			CITData?.CarriedBy &&
			CITData?.Address
		) {
			setProceedDisabled(false)
		} else {
			setProceedDisabled(true)
		}
	}, [CITData])

	const isOtherCarriedBy = !['Owner', 'Employee'].includes(CITData?.CarriedBy)
	const isOtherCarriageMode = !['Two Wheeler', 'Car', 'Truck'].includes(CITData?.CarriageMode)

	return (
		<Container>
			<MainContainer
				header="Enter Details"
				divider={true}
				rightOnClick={proceed}
				rightDisabled={proceedDisabled}
				leftOnClick={back}
				top={1}
			>
				<Header img={citImg} header="Cash in Transit" />
				<Space y={1} />
				<Label compulsory>Maximum amount per transit</Label>
				<FormInput
					placeholder="Enter here"
					onChange={e => handleCITData(e.target.value, 'EstAmountPerTransit')}
					value={CITData?.EstAmountPerTransit}
					compulsory
					autoComma
					rupee
					style={inputStyle}
				/>
				<Space y={1} />
				<Label compulsory>Total amount transferred in a year</Label>
				<FormInput
					placeholder="Enter here"
					onChange={e => handleCITData(e.target.value, 'Amount')}
					value={CITData?.Amount}
					compulsory
					autoComma
					rupee
					style={inputStyle}
				/>
				<Space y={1} />
				<div className="d-flex align-items-center">
					<div className="d-flex flex-column">
						<Label compulsory>Carrying from (start location of journey)</Label>
						<FormInput
							placeholder="Eg: Thane"
							value={CITData?.Source}
							onChange={e => handleCITData(e.target.value, 'Source')}
							compulsory
							style={inputStyle}
						/>
					</div>

					<Text className="mx-4">---&gt;</Text>

					<div className="d-flex flex-column">
						<Label compulsory>Carrying to (end location of journey)</Label>
						<FormInput
							placeholder="Eg: Mumbai"
							value={CITData?.Destination}
							onChange={e => handleCITData(e.target.value, 'Destination')}
							compulsory
							style={inputStyle}
						/>
					</div>
				</div>

				<Space y={1} />
				<Label compulsory>Distance of the transit (up to 25 kms)</Label>
				<FormInput
					placeholder="Enter here (number only)"
					value={CITData?.DistTransit}
					onChange={e => handleCITData(parseFloat(e.target.value), 'DistTransit')}
					type="number"
					compulsory
					style={inputStyle}
				/>
				<Space y={1} />
				<Label compulsory>Vehicle used to transfer cash</Label>
				<div className="d-flex" style={{ width: isOtherCarriageMode ? '100%' : '62.5%' }}>
					<Radio
						className="flex-fill"
						text="Two Wheeler"
						secondary={true}
						value={CITData?.CarriageMode === 'Two Wheeler'}
						onClick={() => handleCITData('Two Wheeler', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Car"
						secondary={true}
						value={CITData?.CarriageMode === 'Car'}
						onClick={() => handleCITData('Car', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Truck"
						secondary={true}
						value={CITData?.CarriageMode === 'Truck'}
						onClick={() => handleCITData('Truck', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Others"
						secondary={true}
						value={isOtherCarriageMode}
						onClick={() => handleCITData('', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					{isOtherCarriageMode ? (
						<div className="d-flex flex-column">
							<Label compulsory>Other</Label>
							<FormInput
								placeholder="Mention type of Carriage Mode here"
								onChange={e => handleCITData(e.target.value, 'CarriageMode')}
								value={CITData?.CarriageMode}
								compulsory
								style={inputStyle}
							/>
						</div>
					) : null}
				</div>
				<Space y={1} />
				<Label compulsory>Who is carrying the cash?</Label>
				<div className="d-flex" style={{ width: isOtherCarriedBy ? '100%' : '62.5%' }}>
					<Radio
						className="flex-fill"
						text="Owner"
						secondary={true}
						value={CITData?.CarriedBy === 'Owner'}
						onClick={() => handleCITData('Owner', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Employee"
						secondary={true}
						value={CITData?.CarriedBy === 'Employee'}
						onClick={() => handleCITData('Employee', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Others"
						secondary={true}
						value={isOtherCarriedBy}
						onClick={() => handleCITData('', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
					{isOtherCarriedBy ? (
						<div className="d-flex flex-column">
							<Label compulsory>Other</Label>
							<FormInput
								placeholder="Mention type of Carrier"
								onChange={e => handleCITData(e.target.value, 'CarriedBy')}
								value={CITData?.CarriedBy}
								compulsory
								style={inputStyle}
							/>
						</div>
					) : null}
				</div>
				{/* disabled is true if CIC or CIS are present */}
				<AddressField
					type="transit"
					value={CITData?.Address}
					handleUpdate={handleCITData}
					rlData={rlData}
				/>
			</MainContainer>
		</Container>
	)
}

export default CIT
