import styled from 'styled-components'
import { forwardRef, useState } from 'react'

const NotesContainer = forwardRef(({ notes }, ref) => {
	const [notesValue, setNotesValue] = useState(notes || '')
	return (
		<NotesContainerWrapper
			ref={ref}
			placeholder="Notes"
			value={notesValue}
			onChange={e => setNotesValue(e.target.value)}
		></NotesContainerWrapper>
	)
})

export default NotesContainer

const NotesContainerWrapper = styled.textarea`
	background: #fff;
	height: 190px;
	width: 100%;
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border: none;
	outline: none;
	padding: 8px;
	resize: none;
	@media (min-width: 768px) {
		margin-left: 0.5rem;
	}
`
