import { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import { Tabbar, Text, theme, Divider, MainContainer } from 'verak-ui'
import { Context } from '../../../context/Provider'
import PropertyData from './components/PropertyData'
import { GoodsOwnership } from './components/SharedComponents'
import StockData from './components/StockData'
import { GoodsInTrustCheck } from './components/SharedComponents'
import { useFetch } from '../../../api/apihook'

const TabbarWrapper = styled.div`
	.nav-link {
		font-weight: 700;
	}
`

const TertiaryTabbarWrapper = styled(TabbarWrapper)`
	.nav-link.active {
		background: linear-gradient(180deg, ${theme.colors.secondary5} 0%, ${theme.colors.white} 100%);
	}
`

function genActiveId(levels, tabData = {}) {
	const { propertyType, levelNum } = tabData || {}

	if (propertyType) {
		return propertyType
	} else if (levelNum) {
		return `level-${levelNum}`
	} else if (levels) {
		return `level-${levels[0].Level}`
	} else {
		return 'Property'
	}
}

function initGoodsInTrustData(isBasement, isProperty, state, levels) {
	if (isBasement || isProperty) {
		if (levels) {
			return {
				levels: levels.map(item => {
					const isPresent = item?.GoodsInTrust?.Present
					const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
					return {
						Level: item.Level,
						Present: NewPresentVal,
						Property: item.GoodsInTrust ? [...(item?.GoodsInTrust?.Property || [])] : [],
						Stock: item.GoodsInTrust ? [...(item?.GoodsInTrust?.Stock || [])] : [],
						Ownership: item.GoodsInTrust ? [...(item?.GoodsInTrust?.Ownership || [])] : [],
					}
				}),
			}
		} else {
			// get location/other info from state.location
			const isPresent = state.location?.GoodsInTrust?.Present
			const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
			return {
				Present: NewPresentVal,
				Property: state.location?.GoodsInTrust
					? [...(state.location?.GoodsInTrust?.Property || [])]
					: [],
				Stock: state.location?.GoodsInTrust ? [...(state.location?.GoodsInTrust?.Stock || [])] : [],
				Ownership: state.location?.GoodsInTrust
					? [...(state.location?.GoodsInTrust?.Ownership || [])]
					: [],
			}
		}
	} else {
		let basementData = {},
			propertyData = {}
		if (state.location?.basement?.levels) {
			basementData = {
				levels: state.location?.basement?.levels?.map(item => {
					const isPresent = item?.GoodsInTrust?.Present
					const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
					return {
						Level: item.Level,
						Present: NewPresentVal,
						Property: item.GoodsInTrust ? [...(item.GoodsInTrust?.Property || [])] : [],
						Stock: item.GoodsInTrust ? [...(item.GoodsInTrust?.Stock || [])] : [],
						Ownership: item.GoodsInTrust ? [...(item.GoodsInTrust?.Ownership || [])] : [],
					}
				}),
			}
		} else {
			// get location/other info from state.location.basement
			const isPresent = state.location?.basement?.GoodsInTrust?.Present
			const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
			basementData = {
				Present: NewPresentVal,
				Property: state.location?.basement?.GoodsInTrust
					? state.location?.basement?.GoodsInTrust?.Property || []
					: [],
				Stock: state.location?.basement?.GoodsInTrust
					? state.location?.basement?.GoodsInTrust?.Stock
					: [],
				Ownership: state.location?.basement?.GoodsInTrust
					? state.location?.basement?.GoodsInTrust?.Ownership || []
					: [],
			}
		}

		if (state.location?.property?.levels) {
			propertyData = {
				levels: state.location?.property?.levels?.map((item, idx) => {
					const isPresent = item?.GoodsInTrust?.Present
					const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
					return {
						Level: item.Level,
						Present: NewPresentVal,
						Property: item.GoodsInTrust ? [...(item.GoodsInTrust?.Property || [])] : [],
						Stock: item.GoodsInTrust ? [...(item.GoodsInTrust?.Stock || [])] : [],
						Ownership: item.GoodsInTrust ? [...(item.GoodsInTrust?.Ownership || [])] : [],
					}
				}),
			}
		} else {
			// get location/other info from state.location.property
			const isPresent = state.location?.property?.GoodsInTrust?.Present
			const NewPresentVal = typeof isPresent === 'boolean' ? isPresent : true
			propertyData = {
				Present: NewPresentVal,
				Property: state.location?.property?.GoodsInTrust
					? [...state.location?.property?.GoodsInTrust?.Property]
					: [],
				Stock: state.location?.property?.GoodsInTrust
					? [...state.location?.property?.GoodsInTrust?.Stock]
					: [],
				Ownership: state.location?.property?.GoodsInTrust
					? [...state.location?.property?.GoodsInTrust?.Ownership]
					: [],
			}
		}
		return {
			basement: {
				...basementData,
			},
			property: {
				...propertyData,
			},
		}
	}
}

function initInnerBasementLevel(goodsInTrustData, tabData = {}) {
	const { propertyType, levelNum } = tabData || {}
	if (propertyType === 'Basement' && levelNum) {
		return `level-${levelNum}`
	}

	let basementLevel = ''
	if (
		(goodsInTrustData.basement &&
			goodsInTrustData.basement.levels &&
			goodsInTrustData.basement.levels[0].Level) ||
		goodsInTrustData?.levels?.[0].Level
	) {
		basementLevel =
			goodsInTrustData?.basement?.levels[0]?.Level || goodsInTrustData?.levels?.[0].Level
	}

	return `level-${basementLevel}`
}

function initInnerPropertyLevel(goodsInTrustData, tabData = {}) {
	const { propertyType, levelNum } = tabData || {}
	if (propertyType === 'Property' && levelNum) {
		return `level-${levelNum}`
	}

	let propertyLevel = ''
	if (
		(goodsInTrustData.property &&
			goodsInTrustData.property.levels &&
			goodsInTrustData.property.levels[0].Level) ||
		goodsInTrustData?.levels?.[0].Level
	) {
		propertyLevel =
			goodsInTrustData?.property?.levels?.[0]?.Level || goodsInTrustData?.levels?.[0].Level
	}

	return `level-${propertyLevel}`
}

export default function GoodsInTrustData() {
	const historyLoc = useLocation()
	const { edit, tabData, returnPath } = historyLoc?.state || {}

	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const { Type, SubType, levels } = state.location

	const isProperty = Type === 'PROPERTY'
	const isBasement = Type === 'BASEMENT'
	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	const [proceedDisabled, setProceedDisabled] = useState(false)
	const [activeId, setActiveId] = useState(() => genActiveId(levels, tabData))

	const onParentTabChange = val => {
		resetInnerTab()
		triggerSave()
		setActiveId(val)
	}
	const [innerTabId, setInnerTabId] = useState('Property')

	const onInnerTabChange = val => {
		triggerSave()
		setInnerTabId(val)
	}
	const resetInnerTab = () => {
		setInnerTabId('Property')
	}

	const [goodsInTrustData, setGoodsInTrustData] = useState(() =>
		initGoodsInTrustData(isProperty, isBasement, state, levels)
	)

	const [innerBasementLevel, setInnerBasementLevel] = useState(() =>
		initInnerBasementLevel(goodsInTrustData, tabData)
	)

	const onInnerBasementLevelChange = val => {
		resetInnerTab()
		triggerSave()
		setInnerBasementLevel(val)
	}

	const [innerPropertyLevel, setInnerPropertyLevel] = useState(() =>
		initInnerPropertyLevel(goodsInTrustData, tabData)
	)

	const onInnerPropertyLevelChange = val => {
		resetInnerTab()
		triggerSave()
		setInnerPropertyLevel(val)
	}

	const disableProceed = value => {
		setProceedDisabled(value)
	}

	const triggerSave = async () => {
		let toSend = {
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			data: '',
			propertyType: '',
			levelIndex: -1,
		}

		if (isBasement || isProperty) {
			// check if levels
			toSend.propertyType = isProperty ? 'property' : 'basement'
			const levelNo = activeId.substring(6, activeId.length)
			if (levels && levels.length > 0) {
				const index = levels.findIndex(item => {
					return item.Level === levelNo
				})
				const Present = goodsInTrustData.levels[index].Present
				toSend.levelIndex = index
				toSend.data = {
					GoodsInTrust: {
						Present,
						Property: Present ? [...goodsInTrustData.levels[index].Property] : [],
						Stock: Present ? [...goodsInTrustData.levels[index].Stock] : [],
						Ownership: Present ? [...goodsInTrustData.levels[index].Ownership] : [],
					},
				}
			} else {
				// no levels
				const Present = goodsInTrustData.Present
				toSend.data = {
					GoodsInTrust: {
						Present,
						Property: Present ? [...goodsInTrustData.Property] : [],
						Stock: Present ? [...goodsInTrustData.Stock] : [],
						Ownership: Present ? [...goodsInTrustData.Ownership] : [],
					},
				}
			}
		} else {
			toSend.propertyType = activeId === 'Property' ? 'property' : 'basement'
			if (activeId === 'Property') {
				if (goodsInTrustData.property && goodsInTrustData.property.levels) {
					const levelNo = innerPropertyLevel.substring(6, innerPropertyLevel.length)
					const index = goodsInTrustData.property.levels.findIndex(item => {
						return item.Level === levelNo
					})
					const Present = goodsInTrustData.property.levels[index].Present
					toSend.levelIndex = index
					toSend.data = {
						GoodsInTrust: {
							Present,
							Property: Present ? [...goodsInTrustData.property.levels[index].Property] : [],
							Stock: Present ? [...goodsInTrustData.property.levels[index].Stock] : [],
							Ownership: Present ? [...goodsInTrustData.property.levels[index].Ownership] : [],
						},
					}
				} else {
					const Present = goodsInTrustData.property.Present
					toSend.data = {
						GoodsInTrust: {
							Present,
							Property: Present ? [...goodsInTrustData.property.Property] : [],
							Stock: Present ? [...goodsInTrustData.property.Stock] : [],
							Ownership: Present ? [...goodsInTrustData.property.Ownership] : [],
						},
					}
				}
			} else {
				if (goodsInTrustData.basement && goodsInTrustData.basement.levels) {
					const levelNo = innerBasementLevel.substring(6, innerBasementLevel.length)
					const index = goodsInTrustData.basement.levels.findIndex(item => {
						return item.Level === levelNo
					})
					const Present = goodsInTrustData.basement.levels[index].Present
					toSend.levelIndex = index
					toSend.data = {
						GoodsInTrust: {
							Present,
							Property: Present ? [...goodsInTrustData.basement.levels[index].Property] : [],
							Stock: Present ? [...goodsInTrustData.basement.levels[index].Stock] : [],
							Ownership: Present ? [...goodsInTrustData.basement.levels[index].Ownership] : [],
						},
					}
				} else {
					const Present = goodsInTrustData.basement.Present
					toSend.data = {
						GoodsInTrust: {
							Present,
							Property: Present ? [...goodsInTrustData.basement.Property] : [],
							Stock: Present ? [...goodsInTrustData.basement.Stock] : [],
							Ownership: Present ? [...goodsInTrustData.basement.Ownership] : [],
						},
					}
				}
			}
		}

		await new Promise(resolve => {
			dispatch(toSend)
			resolve()
		})
	}

	const saveGoodsInTrustData = (data, propertyType, levelIndex) => {
		let oldValue =
			isBasement || isProperty ? goodsInTrustData : { ...goodsInTrustData[propertyType] }
		if (levelIndex > -1) {
			const oldLvlData = oldValue.levels[levelIndex]
			oldValue.levels[levelIndex] = { ...oldLvlData, ...data }
		} else {
			oldValue = {
				...oldValue,
				...data,
			}
		}
		const newGoodsData =
			isBasement || isProperty
				? { ...goodsInTrustData, ...oldValue }
				: {
						...goodsInTrustData,
						[propertyType]: {
							...oldValue,
						},
				  }
		setGoodsInTrustData(newGoodsData)
	}

	const history = useHistory()

	const dispatchData = async () => {
		await triggerSave()

		await new Promise(resolve => {
			dispatch({ type: 'SAVE_LOCATION_CHANGES' })
			resolve()
		})

		await fetch.saveQuote()
		history.push('/property-risk/sum-insured')
	}

	const checkForGoodsPresence = () => {
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'
		let currentLevelNo,
			isPresent = true
		if (isPropertyBasement) {
			if (activeId === 'Property') {
				if (goodsInTrustData.property.levels) {
					currentLevelNo = innerPropertyLevel.substring(6, activeId.length)
					isPresent = goodsInTrustData.property?.levels?.find(item => {
						return item.Level === currentLevelNo
					})?.Present
				} else {
					isPresent = goodsInTrustData.property?.Present
				}
			} else {
				if (goodsInTrustData.basement.levels) {
					currentLevelNo = innerBasementLevel.substring(6, activeId.length)
					isPresent = goodsInTrustData.basement?.levels?.find(item => {
						return item.Level === currentLevelNo
					})?.Present
				} else {
					isPresent = goodsInTrustData.basement?.Present
				}
			}
		} else {
			if (goodsInTrustData.levels) {
				currentLevelNo = activeId.substring(6, activeId.length)
				isPresent = goodsInTrustData.levels?.find(item => {
					return item.Level === currentLevelNo
				})?.Present
			} else {
				isPresent = goodsInTrustData.Present
			}
		}
		return isPresent
	}

	const proceed = () => {
		// switch tab here
		const goodsPresent = checkForGoodsPresence()
		if (innerTabId === 'Property' && goodsPresent) {
			onInnerTabChange('Stock')
		} else if (goodsInTrustData.levels) {
			const currentLevelNo = activeId.substring(6, activeId.length)
			const index = goodsInTrustData.levels.findIndex(item => {
				return item.Level === currentLevelNo
			})

			if (index < goodsInTrustData.levels.length - 1) {
				const nextLevel = goodsInTrustData.levels[index + 1].Level
				onParentTabChange(`level-${nextLevel}`)
			} else {
				// save and proceed
				dispatchData()
			}
		} else {
			// check if property+basement
			if (isPropertyBasement) {
				if (activeId === 'Property') {
					// check if it contains levels
					if (goodsInTrustData.property.levels) {
						const currentLevelNo = innerPropertyLevel.substring(6, activeId.length)
						const index = goodsInTrustData.property.levels.findIndex(item => {
							return item.Level === currentLevelNo
						})
						if (index < goodsInTrustData.property.levels.length - 1) {
							const nextLevel = goodsInTrustData.property.levels[index + 1].Level
							onInnerPropertyLevelChange(`level-${nextLevel}`)
						} else {
							//navigate to basement
							onParentTabChange('Basement')
						}
					} else {
						// navigate To basement
						onParentTabChange('Basement')
					}
				} else {
					if (goodsInTrustData.basement.levels) {
						const currentLevelNo = innerBasementLevel?.replace(/level-/, '') || ''
						const index = goodsInTrustData.basement.levels.findIndex(item => {
							return item.Level === currentLevelNo
						})

						if (index < goodsInTrustData.basement.levels.length - 1) {
							const nextLevel = goodsInTrustData.basement.levels[index + 1].Level
							onInnerBasementLevelChange(`level-${nextLevel}`)
						} else {
							// save and proceed
							dispatchData()
						}
					} else {
						// save and proceed
						dispatchData()
					}
				}
			} else {
				// proceed
				dispatchData()
			}
		}
	}

	const checkActiveTab = (tab, propertyType, levelIndex) => {
		// either property only or basement only
		const onlyBasementOrProperty = isBasement || isProperty
		let currentGoodsData = {}
		let currentLevel = ''

		if (onlyBasementOrProperty) {
			currentGoodsData = goodsInTrustData
			currentLevel = activeId
		} else {
			currentGoodsData = { ...goodsInTrustData[propertyType] }

			if (activeId === 'Property') {
				currentLevel = innerPropertyLevel
			} else {
				currentLevel = innerBasementLevel
			}
		}

		if (tab === innerTabId) {
			if (levelIndex > -1) {
				const tabLvlNo = currentGoodsData.levels[levelIndex]?.Level
				const currentLvlNo = currentLevel?.replace(/level-/, '') || ''
				if (currentLvlNo === tabLvlNo) {
					return true
				}
			} else if (onlyBasementOrProperty || propertyType === activeId?.toLowerCase() || '') {
				return true
			}
		}
		return false
	}

	const tertiaryTabList = props => [
		{
			id: 'Property',
			title: 'Property',
			disabled: false,
			content: (
				<PropertyData
					onSave={data => saveGoodsInTrustData(data, props.propertyType, props.levelIndex)}
					PropertyList={[...(props?.Property || [])]}
					isActiveTab={checkActiveTab('Property', props.propertyType, props.levelIndex)}
					disableProceed={disableProceed}
					{...props}
				/>
			),
		},
		{
			id: 'Stock',
			title: 'Stock',
			disabled: false,
			content: (
				<StockData
					onSave={data => saveGoodsInTrustData(data, props.propertyType, props.levelIndex)}
					StockList={[...(props?.Stock || [])]}
					isActiveTab={checkActiveTab('Stock', props.propertyType, props.levelIndex)}
					disableProceed={disableProceed}
					{...props}
				/>
			),
		},
	]

	let tabs

	if (isProperty || isBasement) {
		// check for levels
		if (goodsInTrustData.levels) {
			const levelsList = goodsInTrustData.levels
			const propertyType = Type?.toLowerCase() || 'basement'
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level-${item.Level}`,
					title: `Level ${item.Level}`,
					content: (
						<TertiaryTabbarWrapper className="mt-3">
							<GoodsInTrustCheck
								value={item.Present}
								handleChange={data => saveGoodsInTrustData(data, propertyType, index)}
							/>
							{item.Present && (
								<>
									<Tabbar
										rounded={false}
										secondary={true}
										tabList={tertiaryTabList({
											propertyType,
											levelIndex: index,
											Property: item.Property,
											Stock: item.Stock,
										})}
										activeTabId={innerTabId}
										onChange={onInnerTabChange}
									/>
									<Divider />
									<GoodsOwnership
										onSave={data => saveGoodsInTrustData(data, propertyType, index)}
										OwnershipList={[...(item?.Ownership || [])]}
									/>
								</>
							)}
						</TertiaryTabbarWrapper>
					),
				}
			})
			tabs = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab"
						activeTabId={activeId}
						onChange={onParentTabChange}
						tabList={levelTabsItem}
						secondary={false}
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			// render
			tabs = (
				<TertiaryTabbarWrapper className="mt-3">
					<GoodsInTrustCheck
						value={goodsInTrustData.Present}
						handleChange={data => saveGoodsInTrustData(data, 'basement', -1)}
					/>
					{goodsInTrustData.Present && (
						<>
							<Tabbar
								rounded={false}
								secondary={true}
								tabList={tertiaryTabList({
									propertyType: 'basement',
									levelIndex: -1,
									Property: goodsInTrustData.Property,
									Stock: goodsInTrustData.Stock,
								})}
								activeTabId={innerTabId}
								onChange={onInnerTabChange}
							/>
							<Divider />
							<GoodsOwnership
								onSave={data => saveGoodsInTrustData(data, 'basement', -1)}
								OwnershipList={[...(goodsInTrustData?.Ownership || [])]}
							/>
						</>
					)}
				</TertiaryTabbarWrapper>
			)
		}
	} else {
		let basementContent, propertyContent

		if (goodsInTrustData.basement.levels) {
			const levelsList = goodsInTrustData.basement.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level-${item.Level}`,
					title: `Level ${item.Level}`,
					content: (
						<TertiaryTabbarWrapper className="mt-3">
							<GoodsInTrustCheck
								value={item.Present}
								handleChange={data => saveGoodsInTrustData(data, 'basement', index)}
							/>
							{item.Present && (
								<>
									<Tabbar
										rounded={false}
										secondary={true}
										tabList={tertiaryTabList({
											propertyType: 'basement',
											levelIndex: index,
											Property: item.Property,
											Stock: item.Stock,
										})}
										activeTabId={innerTabId}
										onChange={onInnerTabChange}
									/>
									<Divider />
									<GoodsOwnership
										onSave={data => saveGoodsInTrustData(data, 'basement', index)}
										OwnershipList={[...(item?.Ownership || [])]}
									/>
								</>
							)}
						</TertiaryTabbarWrapper>
					),
				}
			})
			basementContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-basement"
						activeTabId={innerBasementLevel}
						onChange={onInnerBasementLevelChange}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			basementContent = (
				<TertiaryTabbarWrapper className="mt-3">
					<GoodsInTrustCheck
						value={goodsInTrustData.basement.Present}
						handleChange={data => saveGoodsInTrustData(data, 'basement', -1)}
					/>
					{goodsInTrustData.basement.Present && (
						<>
							<Tabbar
								rounded={false}
								secondary={true}
								tabList={tertiaryTabList({
									propertyType: 'basement',
									levelIndex: -1,
									Stock: goodsInTrustData.basement.Stock,
									Property: goodsInTrustData.basement.Property,
								})}
								activeTabId={innerTabId}
								onChange={onInnerTabChange}
							/>
							<Divider />
							<GoodsOwnership
								onSave={data => saveGoodsInTrustData(data, 'basement', -1)}
								OwnershipList={[...(goodsInTrustData?.basement?.Ownership || [])]}
							/>
						</>
					)}
				</TertiaryTabbarWrapper>
			)
		}

		if (goodsInTrustData.property.levels) {
			const levelsList = goodsInTrustData.property.levels
			const levelTabsItem = levelsList.map((item, index) => {
				return {
					id: `level-${item.Level}`,
					title: `Level ${item.Level}`,
					content: (
						<TertiaryTabbarWrapper className="mt-3">
							<GoodsInTrustCheck
								value={item.Present}
								handleChange={data => saveGoodsInTrustData(data, 'property', index)}
							/>
							{item.Present && (
								<>
									<Tabbar
										rounded={false}
										secondary={true}
										tabList={tertiaryTabList({
											propertyType: 'property',
											levelIndex: index,
											Property: item.Property,
											Stock: item.Stock,
										})}
										activeTabId={innerTabId}
										onChange={onInnerTabChange}
									/>
									<Divider />
									<GoodsOwnership
										onSave={data => saveGoodsInTrustData(data, 'property', index)}
										OwnershipList={[...(item?.Ownership || [])]}
									/>
								</>
							)}
						</TertiaryTabbarWrapper>
					),
				}
			})
			propertyContent = (
				<TabbarWrapper>
					<Tabbar
						className="mt-3"
						tabId="inner-tab-property"
						activeTabId={innerPropertyLevel}
						onChange={onInnerPropertyLevelChange}
						tabList={levelTabsItem}
						secondary
						rounded={true}
					/>
				</TabbarWrapper>
			)
		} else {
			propertyContent = (
				<TertiaryTabbarWrapper className="mt-3">
					<GoodsInTrustCheck
						value={goodsInTrustData.property.Present}
						handleChange={data => saveGoodsInTrustData(data, 'property', -1)}
					/>
					{goodsInTrustData.property.Present && (
						<>
							<Tabbar
								rounded={false}
								secondary={true}
								tabList={tertiaryTabList({
									propertyType: 'property',
									levelIndex: -1,
									Property: goodsInTrustData.property.Property,
									Stock: goodsInTrustData.property.Stock,
								})}
								activeTabId={innerTabId}
								onChange={onInnerTabChange}
							/>
							<Divider />
							<GoodsOwnership
								onSave={data => saveGoodsInTrustData(data, 'property', -1)}
								OwnershipList={[...(goodsInTrustData?.property?.Ownership || [])]}
							/>
						</>
					)}
				</TertiaryTabbarWrapper>
			)
		}

		const parentTabbarItems = [
			{ id: 'Property', title: 'Building', disabled: false, content: propertyContent },
			{ id: 'Basement', title: 'Basement', disabled: false, content: basementContent },
		]

		tabs = (
			<TabbarWrapper>
				<Tabbar
					className="mt-3"
					tabId="outer-tab"
					activeTabId={activeId}
					onChange={onParentTabChange}
					tabList={parentTabbarItems}
					secondary={false}
				/>
			</TabbarWrapper>
		)
	}

	const goBack = () => {
		history.replace('/property-risk/goods-in-trust')
	}

	return (
		<MainContainer
			header="Goods held in trust"
			headerBg={theme.colors.secondary}
			rightLabel="Next"
			rightOnClick={proceed}
			rightDisabled={proceedDisabled}
			leftOnClick={goBack}
		>
			<Text className="mb-4" type="h5" fontWeight={700} style={{ lineHeight: '30px' }}>
				Please specify at which risk locations the Goods Held In Trust are kept
			</Text>
			{tabs}
		</MainContainer>
	)
}
