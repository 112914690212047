import { theme } from 'verak-ui'
import {
	InfoBox,
	InfoText,
	VerticalDivider,
	HorizontalDivider,
} from '../../components/contentUtils'

import ClaimTension from '../../../../../assets/training/ClaimTension.svg'
import EmotionalStress from '../../../../../assets/training/EmotionalStress.svg'
import PeaceOfMind from '../../../../../assets/training/PeaceOfMind.svg'
import UnderstandImportance from '../../../../../assets/training/UnderstandImportance.svg'
import WhereHelp from '../../../../../assets/training/WhereHelp.svg'
import CheckWebsite from '../../../../../assets/training/CheckWebsite.svg'
import ClaimsExperts from '../../../../../assets/training/ClaimsExperts.svg'
import WhatsappLogo from '../../../../../assets/training/WhatsappLogo.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function Training_CantTrustOnline() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				HOW VERAK HELPS WITH CLAIMS
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={ClaimTension}>
						<InfoText>
							<b>Tension (Pareshani) at the time of claim</b>
						</InfoText>
					</InfoBox>
				</div>

				<InfoText
					className="col-md-1 d-flex justify-content-center"
					fontSize="24px"
					color={theme.colors.secondary}
				>
					&rarr;
				</InfoText>

				<div className="col d-flex flex-column">
					<InfoBox icon={PeaceOfMind}>
						<InfoText>
							<b>We give Peace of mind (Tasalli) as we are here to guide through process</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>

			<div className="row w-100 my-3 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={EmotionalStress}>
						<InfoText>
							<b>Emotional stress due to loss of work and family responsibility</b>
						</InfoText>
					</InfoBox>
				</div>

				<InfoText
					className="col-md-1 d-flex justify-content-center"
					fontSize="24px"
					color={theme.colors.secondary}
				>
					&rarr;
				</InfoText>

				<div className="col d-flex flex-column">
					<InfoBox icon={UnderstandImportance}>
						<InfoText>
							<b>
								We understand that your business is imp. to you and we give our best to help you
								with claims
							</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>

			<div className="row w-100 my-3 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WhereHelp}>
						<InfoText>
							<b>Where to ask for help</b>
						</InfoText>
					</InfoBox>
				</div>

				<InfoText
					className="col-md-1 d-flex justify-content-center"
					fontSize="24px"
					color={theme.colors.secondary}
				>
					&rarr;
				</InfoText>

				<div className="col d-flex flex-column">
					<InfoBox icon={WhatsappLogo}>
						<InfoText>
							<b>
								24/7 WhatsApp Support: 9769161630 <br />
								Hotline Number (10am-7pm)(Mon-Sat):9769161630
							</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
			<HorizontalDivider />
			<InfoText className="text-center my-3" spaced fontWeight="700">
				GIVE CONFIDENCE
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={ClaimsExperts}>
						<InfoText>
							<b>
								We have helped settling {claimsCount} claims <br /> Our claims experts will guide
							</b>
						</InfoText>
					</InfoBox>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={CheckWebsite}>
						<InfoText>
							<b>Make them check website for process and testimonials</b>
						</InfoText>
					</InfoBox>
				</div>
			</div>
		</div>
	)
}
