import { theme, Text, Button, Icon, Input, Space } from 'verak-ui'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState, useEffect, useContext } from 'react'
import { Col, Container } from 'react-bootstrap'

import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import {
	CustomerSourceCard,
	BasicDetailsCard,
	CustomerProvidedInfo,
	SumInsuredInfo,
} from './components'
import { PreviousPolicyDetails } from './components/PreviousPolicyDetails'
import CannotCreateDraftModal from './components/CannotCreateDraftModal'

import { ComponentContext } from '../../context/ComponentProvider'
import { useFetch } from '../../api/apihook'
import { channelPartner } from '../../utilities/sqsDateUtils'
import { checkIfRenewalsFlow, getRedirectLink } from '../../utilities/renewalsUtils'

const getNotes = (notes, gstin) => {
	if (gstin) notes = notes.trim() + '\nGST Number : ' + gstin
	return notes
}

const isPaymentDone = rfqStatus => {
	return ['OPS:SETTLEMENT:PENDING', 'OPS:POLICY:PENDING', 'OPS:CLOSURE:PENDING'].includes(rfqStatus)
}

/**
 *
 * @param {string} searchString
 * @returns {{searchId: string, idType: string, isValid: boolean}}
 */
const getSearchData = searchString => {
	let searchId = ''
	let idType = ''
	const len = searchString.length

	switch (true) {
		case len === 10:
			idType = 'phone'
			searchId = `+91${searchString}`
			break
		case len === 11 && searchString.startsWith('0'):
			idType = 'phone'
			searchId = `+91${searchString.substring(1)}`
			break
		case len === 12 && searchString.startsWith('91'):
			idType = 'phone'
			searchId = `+${searchString}`
			break
		// The SAN dialer has some issue where it appends 78 to the start of phone numbers
		// Ref: https://verak.slack.com/archives/C01A4E9L76C/p1672920124346559
		case len === 12 && searchString.startsWith('78'):
			idType = 'phone'
			searchId = `+91${searchString.substring(2)}`
			break
		case len === 13 && searchString.startsWith('+91'):
			idType = 'phone'
			searchId = searchString
			break
		default:
			idType = 'lead'
			searchId = searchString
	}

	return { searchId, idType }
}

const getDraftTag = leadSource => {
	if (leadSource?.startsWith('FB_SFT')) {
		return 'SAFETY_PLAN'
	}
	return ''
}

export default function SelectUID() {
	const isRenewalsFlow = checkIfRenewalsFlow()
	// Note: Redirection only works in prod and local and not on staging because of cloudflare limitations
	const getUID = () => {
		const queryParams = new URLSearchParams(window.location.search)
		if (queryParams.get('phone')) return queryParams.get('phone')
		if (queryParams.get('uid')) return queryParams.get('uid')
		return ''
	}

	const [searchUID, setSearchUID] = useState(() => getUID())
	const [message, setMessage] = useState({ type: '', text: '' })
	const [selectedPolicies, setSelectedPolicies] = useState(['Fire and Allied Perils']) // eslint-disable-line
	const [clientInfo, setClientInfo] = useState({
		Account: {
			Name: '-',
			Address: '-',
		},
		Contact: {
			Name: '-',
			Email: '-',
			Phone: '-',
		},
		Notes: '',
		Tag: '',
	})
	const [campaignData, setCampaignData] = useState({})
	const [city, setCity] = useState('')
	const [stateVal, setStateVal] = useState('')

	// renewals
	const [prevPolicyData, setPrevPolicyData] = useState({
		CoverDuration: '',
		CoverStartDate: '',
		Quote: {
			Insurer: '',
			TotalPremium: '',
			Addons: [],
			IsPremiumDiscounted: false,
			IsCalculatorSupported: false,
		},
	})
	const [prevRfqId, setPrevRfqId] = useState('')
	const [newDraftId, setNewDraftId] = useState('')
	const [newRfqId, setNewRfqId] = useState('')
	const [cantCreateDraft, setCantCreateDraft] = useState(false)

	// const isChecked = item => selectedPolicies.includes(item)

	const fetch = useFetch()

	const history = useHistory()
	const goBack = () => {
		history.replace(getRedirectLink(isRenewalsFlow, '/draft-rfqs'))
	}

	const navLeftContent = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Policy Type
		</Text>
	)

	const { setRenderSidePanel } = useContext(ComponentContext)

	useEffect(() => {
		setRenderSidePanel(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (clientInfo?.Account?.lead_source) {
			fetch.getFBCampaign(clientInfo?.Account?.lead_source).then(result => {
				setCampaignData({
					...result,
					LeadSource: clientInfo?.Account?.lead_source,
					Platform: clientInfo?.Lead?.Platform,
					cpAgentName: clientInfo?.Lead?.cpAgentName,
					language: clientInfo?.Lead?.language,
				})
			})
		}
	}, [clientInfo?.Account?.lead_source]) // eslint-disable-line react-hooks/exhaustive-deps

	// const handleChange = (policyName, checked) => {
	// 	const newSelectedPolicies =
	// 		checked && !selectedPolicies.includes(policyName)
	// 			? [...selectedPolicies, policyName]
	// 			: selectedPolicies.filter(item => item !== policyName)

	// 	setSelectedPolicies(newSelectedPolicies)
	// }

	const onUIDInput = value => {
		setSearchUID(value)
	}

	useEffect(() => {
		if (searchUID.length === 0 && message.type) {
			setMessage({ type: '', text: '' })
			setClientInfo({})
			setCampaignData({})
			setCity('')
			setStateVal('')
		} else if (searchUID.length >= 10) {
			handleSearch(searchUID)
		}
	}, [searchUID]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const fetchCityAndState = async PIN => {
			const response = await fetch.getLocationsWithPIN(PIN)
			const results = response?.[0] || []
			const locations = results?.PostOffice || []

			if (locations?.length) {
				const { District: City, State } = locations?.[0] || {}
				City && setCity(City)
				State && setStateVal(State)
			}
		}

		const PIN = clientInfo?.Lead?.PIN || ''
		if (String(PIN).length === 6) {
			fetchCityAndState(PIN)
		}
	}, [clientInfo?.Lead?.PIN]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleSearch = async value => {
		let toSearch = searchUID
		if (value) toSearch = value

		const { searchId, idType } = getSearchData(toSearch)
		if (searchId.length === 0) return

		// SAN Dialer issue: remove 78 from ui
		// Ref: https://verak.slack.com/archives/C01A4E9L76C/p1672920124346559
		if (toSearch.startsWith('78') && toSearch.length === 12) {
			setSearchUID(toSearch.substring(2))
		}

		if (message.type === 'error') {
			setMessage({ type: '', text: '' })
		}

		// TODO: handle UID/phone num search for renewals here...
		if (isRenewalsFlow) {
			const result = await fetch.getRenewalsPolicyData(searchId, idType)
			if (result.error) {
				setMessage({ type: 'error', text: 'UID/Phone number not found' })
			} else {
				const { Data, Quote, ID } = result?.[0]
				setPrevPolicyData({
					CoverDuration: Data?.Cover?.Duration || '',
					CoverStartDate: Data?.Cover?.StartDate || '',
					Quote: {
						Insurer: Quote?.Insurer || '',
						TotalPremium: Quote?.TotalPremium || '',
						Addons: Quote?.Addons || [],
						IsPremiumDiscounted: Quote?.isPremiumDiscounted || false,
						IsCalculatorSupported: Quote?.isCalculatorSupported || false,
					},
				})
				setPrevRfqId(ID || '')
			}
		}
		const result = await fetch.getLeadData(searchId, idType)
		if (result.error) {
			setMessage({ type: 'error', text: 'UID/Phone number not found' })
		} else {
			setClientInfo({
				Account: {
					UID: result?.CRM_UID || '',
					Name: result?.CompanyName || '',
					lead_source: result?.LeadSource || '',
					Address: result?.Data?.Address || '',
					AntiTheft: result?.Data?.AntiTheft || '',
				},
				Lead: {
					City: result?.City || '',
					createdAt: result?.createdAt || '',
					scheduledAt: result?.Time?.ScheduledCall || '',
					FacilityType: result?.Data?.FacilityType || '',
					BusinessType: result?.Data?.BusinessType || '',
					NatureOfStock: result?.Data?.NatureOfStock || '',
					RawMaterial: result?.Data?.RawMaterial || '',
					FinishedGoods: result?.Data?.FinishedGoods || '',
					ProvisionalQuote: result?.Data?.ProvisionalQuote || '',
					SumInsured: result?.Data?.AssetValue || '',
					SumInsuredBreakdown: result?.Data?.SumInsuredBreakdown || '',
					Platform: result?.Platform || '',
					language: result?.Language || '',
					cpAgentName: result?.CP_Agent || '',
					PIN: result?.PIN || result?.Data?.PIN || '',
					GSTIN: result?.GSTIN || '',
					FacilityOwnership: result?.Data?.Ownership || '',
					FacilityFloor: result?.Data?.Floor || '',
					RoofMaterial: result?.Data?.RoofMaterial || '',
					WallMaterial: result?.Data?.WallMaterial || '',
					FacilityImage: result?.Data?.FacillityImage || '',
					IsSelfServe: result?.isSelfServe,
					SelfServeRfqId: result?.rfqId,
					RfqStatus: result?.rfqStatus,
				},
				Contact: {
					Name: result?.Name || '',
					Email: result?.Email || '',
					Phone: result?.Phone || '',
					WaPhone: result?.WaPhone || '',
				},
				Notes: getNotes(result?.Notes, result?.GSTIN) || '',
			})
			setMessage({ type: 'success', text: 'UID/Phone number found' })
		}
	}

	const disableProceed = isRenewalsFlow
		? !prevRfqId
		: selectedPolicies.length === 0 ||
		  searchUID.length === 0 ||
		  !clientInfo?.Contact?.Name ||
		  clientInfo?.Lead?.IsSelfServe

	const onProceed = async () => {
		if (isRenewalsFlow) {
			const result = await fetch.createRenewalDraft(prevRfqId, {
				Tag: 'FIRE',
				Type: 'RENEWAL',
			})
			if (result.error) {
				setMessage({ type: 'error', text: result.error })
			} else {
				if (result.wasDraftCreated) {
					setNewDraftId(result.draftRfqId)
					const newDraftData = await fetch.getDraftRFQ(result.draftRfqId)
					history.push('/renewals/new-draft', {
						Data: {},
						Tag: getDraftTag(clientInfo?.Account?.lead_source),
						PreviousPolicyDetails: prevPolicyData,
						...newDraftData,
						_id: result.draftRfqId,
					})
				} else {
					setNewRfqId(result.rfqId)
					setCantCreateDraft(true)
					return
				}
			}
		} else {
			history.push('/new-draft', {
				...clientInfo,
				selectedPolicies,
				Data: {},
				Tag: getDraftTag(clientInfo?.Account?.lead_source),
			})
		}
	}

	let banner

	if (clientInfo?.Lead?.cpAgentName) {
		const isFosLead = clientInfo?.Account?.lead_source?.includes('FOS')
		let partnerName = isFosLead ? 'Verak FOS' : channelPartner[clientInfo?.Account?.lead_source]
		banner = (
			<Banner
				className="d-flex align-items-center justify-content-center"
				style={{ backgroundColor: '#3D3F8F' }}
			>
				<Text color={theme.colors.white} fontWeight={'500'} fontSize={'12px'}>
					{`This lead has come from ${partnerName} - ${
						isFosLead ? 'humare' : 'unke'
					} field executive ka naam please use kijye`}
				</Text>
			</Banner>
		)
	} else if (clientInfo?.Notes) {
		banner = (
			<Banner className="d-flex align-items-center justify-content-center">
				<Text color={theme.colors.white} fontWeight={'500'} fontSize={'12px'}>
					Customer ne extra information diya hai, usko dhyan me rakhke baat kijiye
				</Text>
			</Banner>
		)
	}

	const fieldVisitLocations = ['mumbai', 'thane', 'pune']
	const displayFieldVisitBanner = fieldVisitLocations.some(loc => {
		return (
			clientInfo?.Account?.Address?.toLowerCase().includes(loc) || city?.toLowerCase().includes(loc)
		)
	})

	const openSelfServeRfq = async () => {
		const rfqId = clientInfo?.Lead?.SelfServeRfqId
		await fetch.assignSelfServeRfq(rfqId)
		const locationState = {
			ClientName: clientInfo?.Contact?.Name,
			uid: rfqId,
			PolicyType: 'Vyapar Suraksha',
		}
		history.push('/rfq-status/quotes-ready', locationState)
	}

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navLeftContent}
				onGoBack={goBack}
			/>
			{banner}

			<Container className="mt-3" style={{ marginBottom: '60px' }}>
				<div className="d-flex align-items-center mb-2">
					<Text
						color={theme.colors.primary}
						fontSize="12px"
						fontWeight="700"
						style={{ opacity: 0.5 }}
					>
						ENTER UID/Phone number TO MAKE DRAFT RFQ
					</Text>
					<HorizontalLine className="flex-fill ms-2" />
				</div>
				<Col>
					{/* SEARCHBAR */}
					<Text className="mt-2 mb-1" color={theme.colors.gray2} fontSize="14px">
						UID/Phone number<span style={{ color: theme.colors.red }}>*</span>
					</Text>
					<div className="d-flex">
						<Col>
							<InputWrapper className="d-flex flex-fill">
								<CustomInput
									value={searchUID}
									onChange={e => onUIDInput(e.target.value?.trim())}
									placeholder="Paste UID or Phone number here"
									compulsory
									autoFocus
									inputContainerStyle={{ flex: 1 }}
								/>
								<IconWrapper
									className="d-flex justify-content-center align-items-center"
									name="magnifyingGlass"
									color={theme.colors.gray2}
									size="24px"
									onClick={() => handleSearch()}
								/>
							</InputWrapper>
							{message.text && (
								<div className="mt-2 d-flex align-items-center">
									<Icon name={message.type === 'error' ? 'warningTriangleSm' : 'tickCircle'} />
									<Space x={0.3} />
									<Text
										color={message.type === 'error' ? theme.colors.red : theme.colors.green}
										fontSize="14px"
									>
										{message.text}
									</Text>
								</div>
							)}
						</Col>
						{!isRenewalsFlow &&
							clientInfo?.Lead?.IsSelfServe &&
							!isPaymentDone(clientInfo?.Lead?.RfqStatus) && (
								<Button
									className="ms-2 mt-1 py-1 px-2"
									label="Open Customer Generated RFQ"
									icon="arrowUpLink"
									iconColor="white"
									iconStyles={{ marginLeft: '6px' }}
									iconAlign="right"
									style={{ height: '42px', background: '#288C52', border: '2px solid #75CC1F' }}
									onClick={openSelfServeRfq}
								/>
							)}
					</div>

					{!isRenewalsFlow &&
						clientInfo?.Lead?.IsSelfServe &&
						isPaymentDone(clientInfo?.Lead?.RfqStatus) && (
							<PaymentDoneWrapper className="mt-3 d-flex justify-content-between align-items-center">
								<div className="d-flex flex-column">
									<Text fontSize="12px" fontWeight="500" color="#333">
										ACTION REQUIRED
									</Text>

									<div className="mt-1 d-flex align-items-center">
										<Icon name="infoIcon" color="black" size="16px" />
										<Text className="ms-1" fontSize="14px" fontWeight="700" color="#333">
											<u>“Don't Sell”</u> to the customer, as the payment has already been done.
										</Text>
									</div>

									<div className="mt-1 d-flex align-items-center">
										<Icon name="infoIcon" color="black" size="16px" />
										<Text className="ms-1" fontSize="14px" fontWeight="700" color="#333">
											Ask Team Lead to make a <u>“Verification Call”</u> for this customer
										</Text>
									</div>
								</div>

								<Button
									className="ms-2 mt-1 py-1 px-2"
									label="Payment Verification Pending"
									icon="arrowUpLink"
									iconColor="white"
									iconStyles={{ marginLeft: '6px' }}
									iconAlign="right"
									style={{ height: '42px', background: '#288C52', border: '2px solid #75CC1F' }}
									onClick={openSelfServeRfq}
								/>
							</PaymentDoneWrapper>
						)}

					{/* SECTION - CUSTOMER KIDHAR SE AYA  */}
					{!isRenewalsFlow && (
						<CustomerSourceCard
							leadArrivalTime={clientInfo?.Lead?.createdAt}
							callbackTime={clientInfo?.Lead?.scheduledAt}
							campaignData={campaignData}
						/>
					)}
				</Col>
				<Col>
					{/* SECTION - BASIC DETAILS */}
					<BasicDetailsCard
						displayFieldVisitBanner={displayFieldVisitBanner}
						data={{
							clientInfo,
							stateVal,
							city,
						}}
					/>

					{isRenewalsFlow ? (
						<>
							{/* TODO: add previous policy details section here... */}
							<PreviousPolicyDetails policyDetails={prevPolicyData} />
						</>
					) : (
						<>
							{/* SECTION - CUSTOMER PROVIDED INFO */}
							<CustomerProvidedInfo data={clientInfo} />

							{/* SECTION - SUM INSURED / PROVISIONAL QUOTES */}
							<SumInsuredInfo data={clientInfo} />
						</>
					)}
				</Col>
			</Container>

			{isRenewalsFlow && cantCreateDraft ? (
				<CannotCreateDraftModal
					closeModal={() => setCantCreateDraft(false)}
					onContinue={() => {}}
				/>
			) : null}

			<div className="w-100 bg-white py-2 px-3 d-flex justify-content-end fixed-bottom">
				<Button
					label="Save and continue"
					icon="rightArrow"
					iconAlign="right"
					disabled={disableProceed}
					onClick={onProceed}
				/>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

// styles

const Banner = styled.div`
	height: 35px;
	background-color: ${theme.colors.primary};
`

const HorizontalLine = styled.div`
	background: ${theme.colors.primary};
	opacity: 0.2;
	height: 1px;
`

const IconWrapper = styled(Icon)`
	background: ${theme.colors.white};
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 48px;
	width: 48px;
	cursor: pointer;
	border: 0.5px solid #02475e;
	border-left: none;
`

const InputWrapper = styled.div`
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	cursor: pointer;
`

const CustomInput = styled(Input)`
	background: #ffffff;
	width: calc(100%);
	border: 0.5px solid #02475e;
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	&:focus {
		border-right: none;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	}
`

const PaymentDoneWrapper = styled.div`
	background: #c8d6db;
	border-radius: 6px;
	padding: 16px 24px;
`
