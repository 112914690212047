import { InfoText } from '../../components/contentUtils'
import styled from 'styled-components'
import { theme } from 'verak-ui'

export default function Training_DocumentsRequired() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				DOCUMENTS REQUIRED TO APPLY FOR A CLAIM
			</InfoText>
			<TableWrapper>
				<table>
					<thead>
						<th style={{ width: '100px' }}>Documents</th>
						<th>Natural Calamity Loss (Fire, Flood, EarthQuake)</th>
						<th>Man Made Loss (Burglary, Riots, Malicious Damage)</th>
					</thead>
					<tbody>
						<tr>
							<td>Official proof of event</td>
							<td>
								<ul>
									<li>Fire - Fire Brigade investigation report</li>
									<li>Other events - Meteorological department report (*)</li>
								</ul>
								(*) Verak will help customer to procure
							</td>
							<td>
								<ul>
									<li>FIR from local police station</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Official proof of event</td>
							<td>
								<ul>
									<li>Video of damage (Self recorded or via CCTV)</li>
									<li>Photographs of damage</li>
								</ul>
							</td>
							<td>
								<ul>
									<li>Video of damage (Self recorded or via CCTV)</li>
									<li>Photographs of damage</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Proof of loss</td>
							<td>
								<ul>
									<li>
										<b>Stock</b> - Ledger “Khata” entry or invoices from supplier
									</li>
									<li>
										<b>Machine</b> - Repair / replace bill (estimate) from supplier
									</li>
									<li>
										<b>Structure</b> - Contractor's repair estimate
									</li>
								</ul>
							</td>
							<td>
								<ul>
									<li>
										<b>Stock</b> - Ledger “Khata” entry or invoices from supplier
									</li>
									<li>
										<b>Machine</b> - Repair / replace bill (estimate) from supplier
									</li>
									<li>
										<b>Structure</b> - Contractor's repair estimate
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Insurer requirement</td>
							<td>
								<ul>
									<li>Claim form (Verak will fill customer needs to sign soft copy)</li>
								</ul>
							</td>
							<td>
								<ul>
									<li>Claim form (Verak will fill customer needs to sign soft copy)</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</TableWrapper>
		</div>
	)
}

const TableWrapper = styled.div`
	th,
	td {
		border-collapse: collapse;
		padding: 8px;
		font-family: Inter;
		border: 1px solid ${theme.colors.gray2};
		vertical-align: top;
	}
	table {
		border: 1px solid #f78670;
		border-radius: 8px;
	}
`
