import { theme, Text, Button, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Context } from '../../context/Provider'
import { useContext } from 'react'

const btnIconStyle = {
	marginRight: '.7rem',
}

export default function SelectLeadType() {
	const history = useHistory()

	const [, dispatch] = useContext(Context)

	let navbarText = 'What type of lead is this?'

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navbarText}
		</Text>
	)

	const navigate = route => history.push(route)

	const startFreshQuote = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'CLEAR_DATA',
			})
			resolve()
		})

		navigate('/new-quote/select-policy-type')
	}

	const goBack = () => history.goBack()

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar
				headerBg="white"
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
				onGoBack={goBack}
			/>

			<div className="container p-3">
				<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3} className="p-2">
					Select an option to continue
				</Text>

				<CustomFlex className="pt-3 d-flex">
					<CustomButton
						// temp style
						style={{ width: '500px' }}
						iconStyles={btnIconStyle}
						iconColor={theme.colors.secondary}
						icon="plusIcon"
						label={
							<div className="w-100 d-flex justify-content-between align-items-center">
								Begin quote for new lead
								<Icon name="rightArrow" color={theme.colors.gray2} />
							</div>
						}
						onClick={startFreshQuote}
					/>

					{/* <CustomButton
            iconStyles={btnIconStyle}
            icon="saveQuote"
            label={
              <div className="w-100 d-flex justify-content-between align-items-center">
                Self-sourced lead
                <Icon name="rightArrow" color={theme.colors.gray2} />
              </div>
            }
            onClick={() => navigate('/new-quote/self-sourced-lead')}
          /> */}
				</CustomFlex>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

const CustomFlex = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
`

const CustomButton = styled(Button)`
	justify-content: flex-start;
	opacity: ${({ disabled }) => (disabled ? '.3' : '1')};
	height: 64px;
	padding: 16px;
	background: white;
	color: ${theme.colors.primary};
	width: 100%;
	margin-right: 24px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	&:hover {
		color: white;
	}
	@media (max-width: 768px) {
		margin: 0px;
		margin-bottom: 1rem;
	}
`
