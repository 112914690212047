import { nanoid } from 'nanoid'
import {
	hypothecationDefault,
	stockDefault,
} from '../screens/StockDetailsCollection/DetailsCollectionTemplate/defaults'

const prefillStockData = (RiskLocation, getData, propertyType) => {
	let values = null
	if (RiskLocation?.Type === 'PROPERTYBASEMENT') {
		if (propertyType?.toLowerCase() === 'property') {
			values = getData(RiskLocation.property)
		} else {
			values = getData(RiskLocation.basement)
		}
	} else {
		values = getData(RiskLocation)
	}

	return values
}

export const prefillHypothecation = (RiskLocation, propertyType, levelIndex) => {
	const getData = RiskLocation => {
		if (levelIndex !== -1) {
			// has levels
			if (RiskLocation?.levels?.[levelIndex]?.Stock?.Hypothecation) {
				return RiskLocation?.levels[levelIndex]?.Stock?.Hypothecation
			} else {
				return [hypothecationDefault]
			}
		} else {
			if (RiskLocation?.Stock?.Hypothecation) {
				return RiskLocation?.Stock?.Hypothecation
			} else {
				return [hypothecationDefault]
			}
		}
	}

	return prefillStockData(RiskLocation, getData, propertyType)
}

export const prefillStockType = (RiskLocation, propertyType, levelIndex) => {
	const getData = RiskLocation => {
		if (levelIndex !== -1) {
			// has levels
			if (RiskLocation?.levels?.[levelIndex]?.Stock?.Details?.length) {
				return RiskLocation?.levels[levelIndex]?.Stock?.Details
			} else {
				// if products (present at the location) are entered earlier in the journey
				// they are prefilled in the stock screen - that extra screen which gets triggered
				// if type is Industrial or Manuf; doesn't get triggered if Stock values are already present (means he already modified it)
				if (RiskLocation?.Products?.length) {
					let stockList = []
					RiskLocation?.Products.forEach(product => {
						let stock = {...stockDefault}
						stock.Type = product
						stockList.push(stock)
					})
					return stockList
				} else {
					return [stockDefault]
				}
			}
		} else {
			if (RiskLocation?.Stock?.Details?.length) {
				return RiskLocation?.Stock?.Details
			} else {
				if (RiskLocation?.Products?.length) {
					let stockList = []
					RiskLocation?.Products.forEach(product => {
						let stock = {...stockDefault}
						stock.Type = product
						stockList.push(stock)
					})
					return stockList
				} else {
					return [stockDefault]
				}
			}
		}
	}

	return prefillStockData(RiskLocation, getData, propertyType)
}

export const prefillThirdParty = (RiskLocation, propertyType, levelIndex) => {
	const getData = RiskLocation => {
		if (levelIndex !== -1) {
			// has levels
			if (RiskLocation?.levels?.[levelIndex]?.Stock?.ThirdPartyUsage?.Present) {
				return {
					Present: true,
					Details: RiskLocation?.levels[levelIndex]?.Stock?.ThirdPartyUsage?.Details,
				}
			} else {
				if (RiskLocation?.levels?.[levelIndex]?.Stock?.ThirdPartyUsage?.Present === false) {
					return {
						Present: false,
						Details: [''],
					}
				} else {
					return {
						Present: '',
						Details: [''],
					}
				}
			}
		} else {
			if (RiskLocation?.Stock?.ThirdPartyUsage?.Present) {
				return {
					Present: true,
					Details: RiskLocation?.Stock?.ThirdPartyUsage?.Details,
				}
			} else {
				if (RiskLocation?.Stock?.ThirdPartyUsage?.Present === false) {
					return {
						Present: false,
						Details: [''],
					}
				} else {
					return {
						Present: '',
						Details: [''],
					}
				}
			}
		}
	}

	return prefillStockData(RiskLocation, getData, propertyType)
}
