import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import TypeOfBusiness from '../../../../../assets/training/TypeOfBusiness.svg'
import NeedSoftCopy from '../../../../../assets/training/NeedSoftCopy.svg'
import WhatStock from '../../../../../assets/training/WhatStock.svg'
import WhatFloor from '../../../../../assets/training/WhatFloor.svg'

export default function Training_CantTrustOnline() {
	return (
		<div className="d-flex flex-column p-3">
			<InfoText className="text-center my-3" spaced fontWeight="700">
				QUESTIONS TO ASK
			</InfoText>
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WhatFloor}>
						<InfoText>
							What <b>Floor</b> is the business located on?
						</InfoText>
					</InfoBox>
					<ul>
						<li>1st Floor, Ground floor or Basement</li>
						<li>Customer should provide address on official Whatsapp number</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={TypeOfBusiness}>
						<InfoText>
							What <b>Type of Business</b> do they run?
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							<b>Facility Type</b> - Shop, Godown, Factory, Service center, Cafe
						</li>
						<li>
							<b>Business Type</b> - Selling What? Making What? Storing What?
						</li>
						<li>Be careful: Shop or Godown? Kutcha or Pucca?</li>
					</ul>
				</div>
			</div>
			<HorizontalDivider />
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={WhatStock}>
						<InfoText>
							What is their <b>Stock</b>?
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							Treat <b>Finished Goods and Raw Material as 2 separate values</b>
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={NeedSoftCopy}>
						<InfoText>
							Remind customer that <b>no document for purchase</b> but{' '}
							<b>need soft copy for claims</b>
						</InfoText>
					</InfoBox>
					<ul>
						<li>
							They will need to <b>sign claims form</b>
						</li>
					</ul>
				</div>
			</div>
			<InfoText className="text-center my-3" spaced fontWeight="700">
				IF YOU CANNOT CLOSE DO A ONE CALL SALE PUSH CUSTOMER TO VERIFY DETAILS IN RFQ PDF SENT TO
				THEM ON WHATSAPP AND EMAIL ID
			</InfoText>
		</div>
	)
}
