import { useHistory } from 'react-router'
import { useState, useContext, useEffect } from 'react'

import { generateSumInsuredBreakdown } from '../../utilities/generateSumInsuredBreakdown'
import { getSumInsuredData } from '../../utilities/getSumInsuredData'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { headerStyle } from '../../components/SharedComponents'
import { Icon, Space, Text, theme, Checkbox, Input, Divider, MainContainer } from 'verak-ui'

const EscalationClause = () => {
	let history = useHistory()
	const fetch = useFetch()
	let [state, dispatch] = useContext(Context)
	const PolicyAddons = state?.quote?.PolicyAddons

	const escalationData = state?.quote?.EscalationClause ? state?.quote?.EscalationClause : []
	const [escalationList, setEscalationList] = useState(escalationData)

	const addEscalation = title => {
		if (!escalationList.includes(title)) {
			// not already present
			setEscalationList([...escalationList, title])
		}
	}
	const deleteEscalation = title => {
		let alteredList = escalationList.filter(item => item !== title)
		setEscalationList(alteredList)
	}

	const sumInsuredBreakdown = generateSumInsuredBreakdown(state?.quote?.RiskLocations)

	const proceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SET_ESCALATION_CLAUSE',
				data: {
					EscalationClause: escalationList,
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		// Triggering Money Insurance add ons (CIC, CIS, CIT)
		if (PolicyAddons?.CIC?.Present) {
			history.push('/cic')
		} else if (PolicyAddons?.CIS?.Present) {
			history.push('/cis')
		} else if (PolicyAddons?.CIT?.Present) {
			history.push('/cit')
		} else {
			history.push('/summary')
		}
	}

	return (
		<MainContainer header="Add escalation clause" rightOnClick={proceed} top={3}>
			<Text style={headerStyle} color={theme.colors.gray7}>
				The client can opt for the Escalation Clause to cover the below Sum Insured(s).
			</Text>
			<Space y={1.5} />
			<Text style={textStyle} color={theme.colors.gray2}>
				With this clause the client will receive an increase in Sum insured to cover any unexpected
				costs which may arise from unexpected price fluctuations (eg: Inflation).
			</Text>
			<Space y={1.5} />
			<Text style={textStyle} color={theme.colors.gray2}>
				Please select the values for which the client would like to apply it.
			</Text>
			<Space y={1.5} />
			<div className="flex-container">
				<Icon name="warningTriangleSm" />
				<Space x={0.25} />
				<Text style={{ ...textStyle, fontWeight: 700 }} color={theme.colors.red}>
					This is the Total Sum Insured across all risk locations declared.
				</Text>
			</div>
			<Space y={2.5} />

			{sumInsuredBreakdown.map((el, idx) => (
				<Escalation
					Name={el.Name}
					amount={el.Amount}
					noDivider={idx === sumInsuredBreakdown?.length - 1}
					escalationList={escalationList}
					addEscalation={addEscalation}
					deleteEscalation={deleteEscalation}
				/>
			))}
		</MainContainer>
	)
}

export default EscalationClause

const Escalation = ({
	Name,
	amount,
	noDivider,
	escalationList,
	addEscalation,
	deleteEscalation,
}) => {
	const [escalation, setEscalation] = useState(escalationList.includes(Name))

	useEffect(() => {
		if (escalation) {
			addEscalation(Name)
		} else {
			deleteEscalation(Name)
		}
	}, [escalation])

	const UIdata = getSumInsuredData(Name)
	return (
		<>
			<div className="full-flex-container">
				<div className="flex-container">
					<Checkbox value={escalation} onChange={() => setEscalation(prevState => !prevState)} />
					<Space x={1} />
					<Icon name={UIdata.icon} />
					<Space x={1} />
					<Text style={{ ...textStyle, fontWeight: 700 }} color={theme.colors.gray2}>
						{UIdata.title}
					</Text>
				</div>
				<Input value={amount} autoComma disabled rupee />
			</div>
			{noDivider ? null : <Divider top={1.5} bottom={1.5} />}
		</>
	)
}

const textStyle = {
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: 400,
}
