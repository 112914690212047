export const declarationMethodCheck = RiskLocations => {
	let total = 0
	let hasMinStockVal = false

	const calcStockTotal = detailsList => {
		let value = 0
		for (const detailsObj in detailsList) {
			value = detailsList[detailsObj]?.AmountClosed + detailsList[detailsObj]?.AmountOpen
			total = total + value
			if (value >= 2500000) {
				hasMinStockVal = true
			}	
		}
	}
	const analyseLocation = locationItem => {
		if (locationItem?.levels) {
			for (const level in locationItem?.levels) {
				if (locationItem?.levels[level]?.Stock?.Present) {
					calcStockTotal(locationItem?.levels[level]?.Stock?.Details)
				}
			}
		} else if (locationItem?.Stock?.Present) {
			calcStockTotal(locationItem?.Stock?.Details)
		}
	}

	for (const location in RiskLocations) {
		if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
			analyseLocation(RiskLocations[location].property)
			analyseLocation(RiskLocations[location].basement)
		} else {
			analyseLocation(RiskLocations[location])
		}
	}

    if (!hasMinStockVal || (total < 10000000)) {
        return true
    } else {
        return false
    }
}
