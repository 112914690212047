import { Text, Button, Icon, Tooltip, theme, Switch } from 'verak-ui'
import { useHistory, useLocation } from 'react-router'
import { useState } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import MakeRfqModal from '../MakeRfqModal'
import { PreviousPolicyDetailsTooltip } from './PreviousPolicyDetails'
import SendWebsiteLinkModal from '../SendWebsiteLinkModal'
import ViewOnLayout from '../../../components/ViewOnLayout'

import { parsePhoneNumber } from '../../../utilities/parsePhoneNo'
import { useFetch } from '../../../api/apihook'
import { checkIfRenewalsFlow, getRedirectLink } from '../../../utilities/renewalsUtils'

export default function DraftTopBar({
	account,
	contact,
	triggerSave,
	displayReviewScreen,
	proceedToStartRfq,
	disableSaveDraft,
	disableMake,
	addressChatlog,
	safetyToggle,
	setSafetyToggle,
	previousPolicyDetails,
}) {
	const isRenewalsFlow = checkIfRenewalsFlow()
	const { sendWebsiteLink, triggerDraftDataCollection } = useFetch()
	const history = useHistory()
	const { state: locationState } = useLocation()

	const handleGoBack = () => {
		history.replace(getRedirectLink(isRenewalsFlow, '/draft-rfqs'))
	}

	const [showMakeRfqModal, setShowMakeRfqModal] = useState(false)
	const [showWebsiteLinkModal, setShowWebsiteLinkModal] = useState(false)

	const handleStartRfq = () => {
		proceedToStartRfq()
		setShowMakeRfqModal(false)
	}
	let previewPhoneNo = parsePhoneNumber(contact.Phone)

	const copyToClipboard = value => {
		navigator.clipboard.writeText(value)
		toast.info('Copied!!')
	}

	const copyPhoneNumberButton = (
		<div
			className={'ms-1'}
			style={{ cursor: 'pointer' }}
			onClick={() => copyToClipboard(previewPhoneNo)}
		>
			<Icon name="copyToClipboard" width={18} height={18} />
		</div>
	)

	const addressTooltip = account.address && (
		<Tooltip icon="knowMoreSm" className="ms-1" html={account.address} />
	)

	const uid = locationState?.Account?.UID || ''

	const triggerWebsiteLink = async () => {
		const res = await sendWebsiteLink(uid)
		if (res.data) {
			setShowWebsiteLinkModal(true)
		}
	}

	const triggerDataCollectionByWA = async () => {
		let draftId = locationState._id
		if (!draftId) {
			draftId = await triggerSave()
		}
		await triggerDraftDataCollection(draftId)
	}

	const threeDotMenuContent = (
		<div className="d-flex flex-column align-items-center">
			<Button
				style={{
					padding: '0',
					width: '100%',
					justifyContent: 'flex-start',
				}}
				iconStyles={{
					marginRight: '1rem',
				}}
				textColor={theme.colors.primary}
				icon="sendNotification"
				bgColor="white"
				onClick={triggerWebsiteLink}
				label="Send website access"
				disabled={!uid}
			/>

			<Button
				className="p-0"
				iconStyles={{
					marginRight: '1rem',
				}}
				textColor={theme.colors.primary}
				icon="sendNotification"
				bgColor="white"
				onClick={triggerDataCollectionByWA}
				label="Collect details from client on Whatsapp"
			/>
			<Button
				style={{
					padding: '0',
					width: '100%',
					justifyContent: 'flex-start',
				}}
				iconStyles={{
					marginRight: '1rem',
				}}
				icon="makeRfqIcon"
				iconColor={theme.colors.primary}
				textColor={theme.colors.primary}
				bgColor="white"
				disabled={disableMake}
				onClick={() => setShowMakeRfqModal(true)}
				label="Make RFQ"
			/>
		</div>
	)

	const openChatlog = () => {
		// addressChatlog
		if (addressChatlog) {
			window.open(addressChatlog, '_blank').focus()
		}
	}

	return (
		<>
			<TopBar className="mb-2 d-flex flex-fill align-items-center">
				<ViewOnLayout allow="mobile">
					<TopInfoSection className="w-100 flex-fill">
						<TopBarButton
							className="me-3 back-button"
							bgColor={theme.colors.white}
							label={
								<Text id="back-text" color={theme.colors.secondary}>
									Back
								</Text>
							}
							icon="leftArrow"
							onClick={handleGoBack}
							iconColor={theme.colors.secondary}
						/>
						<TopBarSection className="w-100 flex-fill d-flex">
							<ClientInfo label="Contact Person" icon="userIcon" value={contact.Name} />
							<ClientInfo label="Organisation" icon="briefCaseIcon" value={account.Name} />
						</TopBarSection>
					</TopInfoSection>

					<TopBarSection className="ms-3 d-flex" desktopWidth="75%">
						<ClientInfo
							label="Phone No."
							icon="phoneIcon"
							value={previewPhoneNo}
							extraComponent={copyPhoneNumberButton}
						/>

						<ClientInfo
							label={<>Address {account.address && addressTooltip}</>}
							icon="mapPinIcon"
							value={account.address}
						/>

						<SafetyToggleWrapper className="d-flex align-items-center justify-content-between">
							<Text color={theme.colors.primary} fontSize="14px" fontWeight="700">
								Safety Plan
							</Text>
							<Switch
								value={safetyToggle.value}
								disabled={safetyToggle.disabled}
								onChange={val => setSafetyToggle({ ...safetyToggle, value: val })}
							/>
						</SafetyToggleWrapper>
					</TopBarSection>
				</ViewOnLayout>

				<ViewOnLayout allow="desktop">
					<TopBarSection className="w-100 flex-fill d-flex">
						<TopBarButton
							className="me-3 back-button"
							bgColor={theme.colors.white}
							label={
								<Text id="back-text" color={theme.colors.secondary}>
									Back
								</Text>
							}
							icon="leftArrow"
							onClick={handleGoBack}
							iconColor={theme.colors.secondary}
						/>

						<ClientInfo label="Contact Person" icon="userIcon" value={contact.Name} />
						<ClientInfo label="Organisation" icon="briefCaseIcon" value={account.Name} />
						<ClientInfo
							label="Phone No."
							icon="phoneIcon"
							value={previewPhoneNo}
							extraComponent={copyPhoneNumberButton}
						/>

						<ClientInfo
							label={<>Address {account.address && addressTooltip}</>}
							icon="mapPinIcon"
							value={account.address}
						/>

						<SafetyToggleWrapper className="d-flex align-items-center justify-content-between">
							<Text color={theme.colors.primary} fontSize="14px" fontWeight="700">
								Safety Plan
							</Text>
							<Switch
								className="ms-2"
								value={safetyToggle.value}
								disabled={safetyToggle.disabled}
								onChange={val => setSafetyToggle({ ...safetyToggle, value: val })}
							/>
						</SafetyToggleWrapper>
						{isRenewalsFlow ? (
							<PreviousPolicyDetailsTooltip policyDetails={previousPolicyDetails} />
						) : null}
					</TopBarSection>
				</ViewOnLayout>

				<TopBarSection className="ms-3 d-flex flex-fill align-items-center">
					<TopBarButton
						className="p-0"
						bgColor={theme.colors.white}
						icon="saveIcon"
						iconSize="28px"
						iconColor={theme.colors.primary}
						onClick={triggerSave}
						style={{ width: '48px', marginRight: '12px' }}
					/>

					{addressChatlog && (
						<WhatsappChatlogButton
							label={'Read Chatlog'}
							applyDisableFilter={disableSaveDraft}
							icon="whatsapp"
							iconAlign="left"
							iconStyles={{ marginRight: '.5rem' }}
							style={{ marginRight: '12px', width: '180px' }}
							textColor="#4AA161"
							onClick={openChatlog}
						/>
					)}

					<TopBarButton
						label={'Finalise RFQ'}
						disabled={disableSaveDraft}
						applyDisableFilter={disableSaveDraft}
						icon="rightArrow"
						iconAlign="right"
						style={{ border: 'solid 1px', marginRight: '12px', width: '170px' }}
						onClick={displayReviewScreen}
					/>

					<Tooltip
						style={{ width: '30px' }}
						theme="light"
						trigger="click"
						html={threeDotMenuContent}
					>
						<Icon name="verticalMenuDots" className="me-2" style={{ cursor: 'pointer' }} />
					</Tooltip>
				</TopBarSection>
			</TopBar>
			<MakeRfqModal
				showModal={showMakeRfqModal}
				closeModal={() => setShowMakeRfqModal(false)}
				createRfq={handleStartRfq}
			/>
			<ToastContainer />
			<SendWebsiteLinkModal
				showModal={showWebsiteLinkModal}
				closeModal={() => setShowWebsiteLinkModal(false)}
				uid={uid}
			/>
		</>
	)
}

// util components

const ClientInfo = ({ label, icon, value, extraComponent }) => {
	return (
		<ClientInfoWrapper className="client-info-wrapper me-2 flex-fill">
			<div className="d-flex align-items-center">
				<Icon className="me-2" name={icon} />
				<TopBarText color={theme.colors.gray2} fontWeight={'400'}>
					{label}
				</TopBarText>
			</div>
			<TopBarText
				color={theme.colors.gray7}
				style={{
					fontWeight: 700,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
				className="d-flex align-items-center"
			>
				{value}
				{extraComponent}
			</TopBarText>
		</ClientInfoWrapper>
	)
}

// for mobile, tablet
const responsiveBreakpoint = `816px`

//  styles

const ClientInfoWrapper = styled.div`
	@media (min-width: ${responsiveBreakpoint}) {
		max-width: 180px;
	}

	@media (max-width: ${responsiveBreakpoint}) {
		max-width: 45%;
		text-overflow: ellipsis !important;
	}
`

const TopInfoSection = styled.div`
	display: flex;
	align-items: center;
	@media (min-width: ${responsiveBreakpoint}) {
		width: ${props => props.desktopWidth};
	}
	@media (max-width: ${responsiveBreakpoint}) {
		#back-text {
			display: none;
		}
		.back-button {
			width: 1rem;
			padding: 0.3rem;
			margin: 0px !important;
			margin-left: 0.3rem !important;
		}
	}
`

const TopBarText = styled(Text)`
	font-size: 14px;
	@media (max-width: ${responsiveBreakpoint}) {
		font-size: 12px;
	}
`

const TopBarButton = styled(Button)`
	height: 48px;
	@media (min-width: ${responsiveBreakpoint}) {
		width: 150px;
	}
	@media (max-width: ${responsiveBreakpoint}) {
		height: 38px;
		font-size: 10px;
		width: 108px;
	}
`

const TopBar = styled.div`
	height: 80px;
	background-color: white;
	@media (max-width: ${responsiveBreakpoint}) {
		height: auto;
		width: 100%;
		flex-direction: column;
		align-items: start !important;
	}
`

const TopBarSection = styled.div`
	@media (max-width: ${responsiveBreakpoint}) {
		width: 100%;
		padding: 0.5rem;
	}
`

const WhatsappChatlogButton = styled(Button)`
	background: #f4fff9;
	border: 1px solid #4aa161;
	border-radius: 8px;
	height: 48px;
`

const SafetyToggleWrapper = styled.div`
	background: #e6e6e6;
	border-radius: 8px;
	padding: 12px 18px;
	width: 160px;
	margin-left: -3rem;
`
