import { theme, Text, Button, Checkbox, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState } from 'react'

export default function SelectAddons() {
	const [selectedAddons, setSelectedAddons] = useState([])

	const history = useHistory()

	const navigateToNextPage = () => {
		history.push('/new-quote/selected-journeys')
	}

	const handleChange = (key, state) => {
		let arrCopy = [...selectedAddons]

		if (state && !arrCopy.includes(key)) {
			arrCopy = [...arrCopy, key]
		} else {
			if (arrCopy.includes(key)) {
				arrCopy = [...arrCopy.filter(el => el !== key)]
			}
		}
		setSelectedAddons([...arrCopy])
	}

	const isChecked = value => selectedAddons.includes(value)

	const proceedDisabled = selectedAddons.length === 0

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Select policies to add to package
		</Text>
	)

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar headerBg="white" navbarNumber={1} leftContent={navContent} rightContent="" />

			<div className="container p-3 mb-5">
				<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3} className="px-2">
					Please select all that apply
				</Text>

				<div className="d-flex mt-4">
					<PolicyItem
						value={isChecked('Burglary & Theft')}
						onChange={state => handleChange('Burglary & Theft', state)}
						policyType="offline"
						icon="burglaryAndTheft"
						label="Burglary & Theft"
					/>

					<PolicyItem
						value={isChecked('Plate Glass')}
						onChange={state => handleChange('Plate Glass', state)}
						policyType="offline"
						icon="plateGlass"
						label="Plate Glass"
					/>
				</div>

				<div className="d-flex mt-4">
					<PolicyItem
						value={isChecked('Neon Glass / Sign Board')}
						onChange={state => handleChange('Neon Glass / Sign Board', state)}
						policyType="offline"
						icon="neonGlass"
						label="Neon Glass / Sign Board"
					/>

					<PolicyItem
						value={isChecked('Machinery Breakdown & Damage')}
						onChange={state => handleChange('Machinery Breakdown & Damage', state)}
						policyType="offline"
						icon="machineryBreakdown"
						label="Machinery Breakdown & Damage"
					/>
				</div>

				<div className="d-flex mt-4">
					<PolicyItem
						value={isChecked('Cash In Safe')}
						onChange={state => handleChange('Cash In Safe', state)}
						policyType="offline"
						icon="cashInSafe"
						label="Cash In Safe"
					/>

					<PolicyItem
						value={isChecked('Cash In Transit')}
						onChange={state => handleChange('Cash In Transit', state)}
						policyType="offline"
						icon="cashInTransit"
						label="Cash In Transit"
					/>
				</div>

				<div className="d-flex mt-4">
					<PolicyItem
						value={isChecked('Fire Loss Of Profit')}
						onChange={state => handleChange('Fire Loss Of Profit', state)}
						policyType="offline"
						icon="fireLossOfProfit"
						label="Fire Loss Of Profit"
					/>

					<PolicyItem
						value={isChecked('Machinery Loss Of Profit')}
						onChange={state => handleChange('Machinery Loss Of Profit', state)}
						policyType="offline"
						icon="machineryLossOfProfit"
						label="Machinery Loss Of Profit"
					/>
				</div>

				<div className="d-flex mt-4">
					<PolicyItem
						value={isChecked('Electronic Equipment Insurance')}
						onChange={state => handleChange('Electronic Equipment Insurance', state)}
						policyType="offline"
						icon="electronicEquipmentInsurance"
						label="Electronic Equipment Insurance"
					/>

					<PolicyItem
						value={isChecked('Fidelity and Larceny')}
						onChange={state => handleChange('Fidelity and Larceny', state)}
						policyType="offline"
						icon="fidelityAndLarceny"
						label="Fidelity and Larceny"
					/>
				</div>
			</div>

			<div
				className="d-flex justify-content-end align-items-center fixed-bottom"
				style={{ background: 'white', padding: '12px 24px', width: '100%' }}
			>
				<Button
					disabled={proceedDisabled}
					label="Save and Continue"
					icon="rightArrow"
					onClick={navigateToNextPage}
					iconAlign="right"
				/>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

const PolicyItem = ({ label = '', icon = '', value, onChange }) => {
	return (
		<PolicyItemWrapper className="d-flex justify-content-between align-items-center">
			<div className="d-flex align-items-center">
				<Checkbox value={value} onChange={onChange} />
				<div className="d-flex">
					<Icon name={icon} />
					<Text
						style={{ marginLeft: '10px' }}
						color={theme.colors.primary}
						fontSize="18px"
						fontWeight="700"
					>
						{label}
					</Text>
				</div>
			</div>
		</PolicyItemWrapper>
	)
}

const PolicyItemWrapper = styled.div`
	padding: 16px;
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	width: min(510px, 95%);
	cursor: pointer;
	margin-right: 2rem;
`
