import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css' // css normalization across browsers
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from './context/Provider'
import { ComponentProvider } from './context/ComponentProvider'
import App from './App'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import { BrowserRouter as Router } from 'react-router-dom'


ReactDOM.render(
	<StrictMode>
		<Router>
			<Auth0ProviderWithHistory>
				<Provider>
					<ComponentProvider>
						<App />
					</ComponentProvider>
				</Provider>
			</Auth0ProviderWithHistory>
		</Router>
	</StrictMode>,
	document.getElementById('root')
)
