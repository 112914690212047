import { ToWords } from './toWordsConverter'

const toWords = new ToWords({
	localeCode: 'en-IN',
	converterOptions: {
		doNotAddOnly: true,
	},
})

const getAmountInWords = amount => {
	if (amount === '0') return ''
	if (amount) {
		try {
			const amountInWords = toWords.convert(amount, { currency: true, ignoreDecimal: true })
			return amountInWords
		} catch (err) {
			console.log('[Amount conversion error]' + err)
		}
	}
	return ''
}

export { getAmountInWords }
