import { Space, Text, Radio, Input, Divider } from 'verak-ui'
import { RadioGroup, SharedRadioStyles } from './SharedStyles'

const Warranties = ({
	hasWarranties,
	warrantiesDesc,
	handleWarrantiesPresence,
	handleWarrantiesDesc,
}) => {
	return (
		<>
			<Divider top={0.5} bottom={1.5} />
			<Text className="mt-3" type="body1" fontWeight={500} style={{ lineHeight: '22.68px' }}>
				Did the previous insurer add any warranties?
			</Text>
			<RadioGroup>
				<Radio
					labelStyle={SharedRadioStyles}
					value={hasWarranties === true}
					onChange={() => handleWarrantiesPresence(true)}
					secondary
					text="Yes"
					name="warranties"
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={hasWarranties === false}
					onChange={() => handleWarrantiesPresence(false)}
					secondary
					text="No"
					name="warranties"
				/>
			</RadioGroup>

			<Space y={0.5} />
			{hasWarranties === true ? (
				<Input
					type="text"
					value={warrantiesDesc}
					onChange={e => handleWarrantiesDesc(e.target.value)}
					placeholder="Enter here"
					label="If yes, please specify"
					compulsory
				/>
			) : null}
		</>
	)
}

export default Warranties
