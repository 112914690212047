import {
	Text,
	theme,
	Button,
	Modal,
	Icon,
	Checkbox,
	Divider,
	Input,
	PhoneNoInput,
	Space,
} from 'verak-ui'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFetch } from '../../../api/apihook'
import { Spinner } from 'react-bootstrap'

import IRDAI from '../../../assets/icons/logo/IRDAI.svg'

import ICICI from '../../../assets/insurers/ICICI.svg'
import Oriental from '../../../assets/insurers/OrientalInsurance.svg'
import BajajAllianz from '../../../assets/insurers/bajajAllianz.svg'
import RelianceGeneral from '../../../assets/insurers/RelianceGeneralInsurance.svg'
import Digit from '../../../assets/insurers/DigitInsurance.svg'
import LibertyGeneral from '../../../assets/insurers/LibertyInsurance.svg'
import Chola from '../../../assets/insurers/CholaMSGeneralInsurance.svg'
import NewIndia from '../../../assets/insurers/NewIndiaAssurance.svg'
import UnitedIndia from '../../../assets/insurers/UnitedIndia.svg'
import NationalInsurance from '../../../assets/insurers/NationalInsurance.svg'

import WhatsappIcon from '../../../assets/icons/whatsappIcon.svg'
import CheckIcon from '../../../assets/icons/Check.png'

const insurerNames = [
	{
		text: 'ICICI Lombard',
		value: 'ICICI Lombard General Insurance',
		icon: ICICI,
	},
	{
		text: 'Oriental Insurance',
		value: 'Oriental Insurance',
		icon: Oriental,
	},
	{
		text: 'New India',
		value: 'The New India Assurance Co. Ltd.',
		icon: NewIndia,
	},
	{
		text: 'Bajaj Allianz',
		value: 'Bajaj Allianz General Insurance',
		icon: BajajAllianz,
	},
	{
		text: 'Reliance General',
		value: 'Reliance General Insurance',
		icon: RelianceGeneral,
	},
	{
		text: 'Digit Insurance',
		value: 'Digit Insurance',
		icon: Digit,
	},
	{
		text: 'Liberty General',
		value: 'Liberty General Insurance',
		icon: LibertyGeneral,
	},
	{
		text: 'United India Insurance',
		value: 'United India Insurance',
		icon: UnitedIndia,
	},
	{
		text: 'National Insurance',
		value: 'National Insurance',
		icon: NationalInsurance,
	},
	// {
	// 	text: 'Chola MS',
	// 	value: 'Chola MS General Insurance',
	// 	icon: Chola,
	// },
]

const insurersWithTnC = {
	BUSINESS: [...insurerNames],
	MONEY: [
		{
			text: 'Oriental Insurance',
			value: 'Oriental Insurance',
			icon: Oriental,
		},
	],
}

const IrdaiTnCConfig = {
	text: 'General IRDAI T&Cs',
	icon: IRDAI,
}

const insuranceTypes = {
	BUSINESS: 'BUSINESS',
	MONEY: 'MONEY',
}

const EmptyModal = styled.div`
	height: 300px;
`

const TnCItem = ({ icon, text, selected, onSelect }) => {
	return (
		<div
			className="d-flex flex-column justify-content-between p-3"
			style={{
				boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
				borderRadius: '4px',
				cursor: 'pointer',
			}}
		>
			<img
				style={{ width: '180px', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)', borderRadius: '4px' }}
				className="p-2"
				src={icon}
				alt="tnc-type"
				onClick={onSelect}
			/>
			<Checkbox
				value={selected}
				onChange={onSelect}
				text={text}
				textStyle={{ fontSize: '14px', fontWeight: '700', color: '#333333' }}
				className="mt-2"
			/>
		</div>
	)
}

export const TnCModalContent = ({ rfqData, clientEmail, screen, setScreen }) => {
	const { getNotificationStatus, sendTnC } = useFetch()
	// TODO: use the exact email field name once the API starts sending it
	const rfqId = rfqData?.id || ''

	const [loading, setLoading] = useState(clientEmail ? false : true)
	const [irdaiTnC, setIrdaiTnC] = useState(false)
	const [insurerTnCs, setInsurerTnCs] = useState([])
	const [sendOnWhatsapp, setSendOnWhatsapp] = useState(rfqData?.WaConsent || false)
	const [fetchedClientEmail, setFetchedClientEmail] = useState(clientEmail || '')
	const [insuranceType, setInsuranceType] = useState(insuranceTypes.BUSINESS)

	const getClientEmail = async displaySpinner => {
		const data = await getNotificationStatus(rfqId, displaySpinner)
		setLoading(false)
		setFetchedClientEmail(data?.Email || '')
	}

	useEffect(() => {
		if (!clientEmail) {
			getClientEmail(false)
		}
	}, [])

	const handleSendOnWhatsapp = () => setSendOnWhatsapp(!sendOnWhatsapp)

	const handleInsurerTnCSelect = insurer => {
		let newInsurerTnCs = [...insurerTnCs]
		if (insurerTnCs.includes(insurer)) {
			newInsurerTnCs = insurerTnCs.filter(t => t !== insurer)
		} else {
			newInsurerTnCs = [...insurerTnCs, insurer]
		}
		setInsurerTnCs(newInsurerTnCs)
	}

	const handleSendTnC = async () => {
		let insurers = [...insurerTnCs]
		if (irdaiTnC) {
			insurers = [...insurerTnCs, 'IRDAI']
		}

		let mediums = ['EMAIL']
		if (sendOnWhatsapp) {
			mediums = ['EMAIL', 'WHATSAPP']
		}

		const reqBody = {
			mediums,
			insurers,
			insuranceType,
		}
		const response = await sendTnC(rfqId, reqBody)
		if (response.message === 'ok') {
			setScreen('success')
		}
	}

	let toRenderContent
	if (loading) {
		toRenderContent = (
			<EmptyModal className="d-flex align-items-center justify-content-center">
				<Spinner className="mt-2" animation="border" variant="secondary" />
			</EmptyModal>
		)
	} else if (screen === 'insurerSelection') {
		toRenderContent = (
			<>
				<SwiwelSection className="my-2 d-flex align-items-center">
					<SwiwelButton
						isSelected={insuranceType === insuranceTypes.BUSINESS}
						onClick={() => {
							insuranceType !== insuranceTypes.BUSINESS && setInsurerTnCs([])
							setInsuranceType(insuranceTypes.BUSINESS)
						}}
						label="Business Insurance"
					/>
					<SwiwelButton
						isSelected={insuranceType === insuranceTypes.MONEY}
						onClick={() => {
							insuranceType !== insuranceTypes.MONEY && setInsurerTnCs([])
							setInsuranceType(insuranceTypes.MONEY)
						}}
						label="Money Insurance"
					/>
				</SwiwelSection>

				{insuranceType === insuranceTypes.BUSINESS && (
					<>
						<Text color={theme.colors.primary}>
							Select if you need to send general IRDAI Terms & Conditions:
						</Text>
						<TnCItemsContainer className="mt-3">
							<TnCItem
								key="irdai-tncs"
								icon={IrdaiTnCConfig.icon}
								text={IrdaiTnCConfig.text}
								selected={irdaiTnC}
								onSelect={() => setIrdaiTnC(!irdaiTnC)}
							/>
							<div />
						</TnCItemsContainer>
					</>
				)}

				<Text className="mt-4" color={theme.colors.primary}>
					Select insurers below:
				</Text>
				<TnCItemsContainer className="mt-3">
					{insurersWithTnC[insuranceType].map(insurer => (
						<TnCItem
							key={`insurer-tnc-${insurer?.text}`}
							icon={insurer?.icon}
							text={insurer?.text}
							selected={insurerTnCs.includes(insurer?.value)}
							onSelect={() => handleInsurerTnCSelect(insurer?.value)}
						/>
					))}
				</TnCItemsContainer>

				<Button
					primary
					label="Next"
					className="mt-3"
					style={{ width: '100%', height: '48px' }}
					onClick={() => setScreen('contactInfoSelection')}
					disabled={insurerTnCs.length === 0 && irdaiTnC === false}
				/>
			</>
		)
	} else if (screen === 'contactInfoSelection') {
		toRenderContent = (
			<>
				{fetchedClientEmail && (
					<>
						<Input
							type="text"
							label="Email ID"
							disabled
							style={{ width: '100%' }}
							value={fetchedClientEmail}
						/>

						<CustomDivider />
					</>
				)}

				<Text fontSize="14px" fontWeight="700" color={theme.colors.secondary}>
					Send updates to the client on
				</Text>
				<Checkbox
					className="my-3"
					value={sendOnWhatsapp}
					onChange={handleSendOnWhatsapp}
					text={
						<Text className="d-flex" color={theme.colors.gray2} fontWeight="700">
							<img className="me-2" src={WhatsappIcon} alt="whatsapp-available" />
							Whatsapp
						</Text>
					}
				/>

				<Button
					primary
					label="Send"
					className="mt-3"
					style={{ width: '100%', height: '48px' }}
					onClick={handleSendTnC}
					disabled={!fetchedClientEmail && !sendOnWhatsapp}
				/>
			</>
		)
	} else if (screen === 'success') {
		toRenderContent = (
			<div className="d-flex flex-column align-items-center">
				<Space y={2} />
				<img src={CheckIcon} alt="tnc-sent" />
				<Text className="mt-3" color={theme.colors.primary}>
					Terms & Conditions have been SENT!
				</Text>
				<Space y={2} />
			</div>
		)
	}

	return toRenderContent
}

const SendTnCModal = ({ show, handleClose, rfqData, clientEmail }) => {
	const [screen, setScreen] = useState('insurerSelection')

	const closeTnCModal = () => {
		handleClose()
		setScreen('insurerSelection')
	}

	const titleElement = (
		<div className="d-flex align-items-center">
			{screen === 'contactInfoSelection' && (
				<Button
					className="mr-2"
					onClick={() => setScreen('insurerSelection')}
					bgColor="#fff"
					label=""
					style={{ padding: '0 0.25rem' }}
					icon="leftArrow"
					iconColor="#999999"
				/>
			)}
			<Icon name="paperFold" style={{ marginRight: '1rem' }} />
			<Text color={theme.colors.primary} fontWeight="600">
				Send Terms & Conditions:
			</Text>
		</div>
	)

	return (
		<Modal show={show} handleClose={closeTnCModal} title={titleElement} footer={<></>}>
			<TnCModalContent
				rfqData={rfqData}
				clientEmail={clientEmail}
				screen={screen}
				setScreen={setScreen}
			/>
		</Modal>
	)
}

const SwiwelButton = ({ isSelected, onClick, label }) => {
	return (
		<SwiwelButtonWrapper isSelected={isSelected} onClick={onClick}>
			{label}
		</SwiwelButtonWrapper>
	)
}

const TnCItemsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
`

const CustomDivider = styled(Divider)`
	margin: 1rem auto;
	width: 100%;
	border-width: 0px;
	@media (max-width: 768px) {
		width: 100%;
	}
`

const SwiwelSection = styled.div`
	background: ${theme.colors.secondary4};
	width: auto;
	border-radius: 60px;
	width: 100%;
	transition: all 0.1s ease;
	&:hover {
		background: #7ca2b0;
		color: ${theme.colors.primary};
	}
`

const SwiwelButtonWrapper = styled.div`
	width: 50%;
	border-radius: 60px;
	background: ${props => (props.isSelected ? theme.colors.primary : theme.colors.secondary4)};
	color: ${props => (props.isSelected ? 'white' : theme.colors.primary)};
	padding: 10px 30px;
	text-align: center;
	cursor: pointer;
	font-weight: 700;
	transition: all 0.1s ease;
	user-select: none;
	&:hover {
		background: ${props => (!props.isSelected ? '#7ca2b0' : theme.colors.primary)};
		color: ${props => (!props.isSelected ? theme.colors.primary : 'white')};
	}
`

export default SendTnCModal
