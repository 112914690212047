import { Input, Modal, Text, Button, Space, theme } from 'verak-ui'
import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { useFetch } from '../../../api/apihook'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { validateEmail } from '../../../utilities/validateEmail'
import { editDetailsAccess } from '../../../utilities/authorizedAccounts'
import { useAuth0 } from '@auth0/auth0-react'

const removeCountryCode = phone => {
	if (phone?.startsWith('+91')) {
		return phone.replace('+91', '')
	}
	return phone
}

export default function EditDetailsModal({ show, handleClose, selectedRfq, updateRfqData }) {
	const { getCustomerDetails, updateCustomerDetails } = useFetch()

	const [ProposerName, setProposerName] = useState('')
	const [LegalEntityName, setLegalEntityName] = useState('')
	const [Email, setEmail] = useState('')
	const [Phone, setPhone] = useState('')
	const [WaPhone, setWaPhone] = useState('')

	const [isDataLoaded, setIsDataLoaded] = useState(false)

	const { user } = useAuth0()
	const contactEditDisabled = !editDetailsAccess.includes(user?.email)

	const oldDataRef = useRef(null)

	const fetchCustomerInfo = async () => {
		const result = await getCustomerDetails(selectedRfq?.id)
		if (!result.error) {
			const { ContactInfo = {}, PersonalInfo = {} } = result
			const { Phone = '', Email = '', WaPhone = '' } = ContactInfo
			const { Name: ProposerName = '', LegalEntityName: EntityName = '' } = PersonalInfo

			setProposerName(ProposerName)
			setLegalEntityName(EntityName)
			setEmail(Email)
			setPhone(removeCountryCode(Phone))
			setWaPhone(removeCountryCode(WaPhone))
			setIsDataLoaded(true)
			oldDataRef.current = {
				ProposerName,
				LegalEntityName: EntityName,
				Email,
				Phone,
				WaPhone,
			}
		} else {
			toast.error(`Failed to get rfq details`)
		}
	}

	useEffect(() => fetchCustomerInfo(), [])

	const getUpdatedChanges = () => {
		let updatedChanges = {}
		if (ProposerName !== oldDataRef?.current?.ProposerName) {
			updatedChanges.ProposerName = ProposerName
		}
		if (LegalEntityName !== oldDataRef?.current?.LegalEntityName) {
			updatedChanges.LegalEntityName = LegalEntityName
		}
		if (Email !== oldDataRef?.current?.Email) {
			updatedChanges.Email = Email
		}
		if (Phone !== removeCountryCode(oldDataRef?.current?.Phone) && Phone.length === 10) {
			updatedChanges.Phone = `+91${Phone}`
		}
		if (WaPhone !== removeCountryCode(oldDataRef?.current?.WaPhone) && WaPhone.length === 10) {
			updatedChanges.WaPhone = `+91${WaPhone}`
		}
		return updatedChanges
	}

	const saveChanges = async () => {
		const updatedDetails = getUpdatedChanges()
		if (Object.keys(updatedDetails).length === 0) {
			toast.error('No details changed')
			return
		}
		const result = await updateCustomerDetails(selectedRfq.id, updatedDetails)

		if (result.error) {
			toast.error('Details updated failed')
		} else {
			// update parent component state
			updateRfqData({
				...selectedRfq,
				ProposerName,
				Name: LegalEntityName,
				Email,
				Phone: Phone ? `+91${Phone}` : '',
				WaPhone: WaPhone ? `+91${WaPhone}` : '',
			})

			toast.success('Details updated successfully')
			handleClose()
		}
	}

	const titleElement = (
		<Text color={theme.colors.primary} fontSize="14px" fontWeight="600">
			Edit Details
		</Text>
	)

	const isProposerNameValid = !isDataLoaded || Boolean(ProposerName.length > 0)
	const isLegalEntityNameValid = !isDataLoaded || Boolean(LegalEntityName.length > 0)
	const isPhoneValid = !isDataLoaded || Phone.length === 10 || Phone === ''
	const isWaPhoneValid = !isDataLoaded || WaPhone.length === 10 || WaPhone === ''
	const isEmailValid = !isDataLoaded || validateEmail(Email) || Email === ''

	const disableProceed = !(
		isProposerNameValid &&
		isLegalEntityNameValid &&
		isPhoneValid &&
		isWaPhoneValid &&
		isEmailValid
	)

	return (
		<>
			<Modal show={show} handleClose={handleClose} title={titleElement} footer={<></>}>
				<div className="w-100">
					<Input
						value={ProposerName}
						onChange={e => setProposerName(e.target.value)}
						className="w-100"
						label="Proposer's Name (Optional)"
						placeholder="Enter new Proposer Name"
						errorLabel={!isProposerNameValid && 'Proposer Name is required'}
					/>
					<Space y={1} />
					<Input
						value={LegalEntityName}
						onChange={e => setLegalEntityName(e.target.value)}
						className="w-100"
						label="Legal Entity Name (Optional)"
						placeholder="Enter new Legal Entity Name"
						errorLabel={!isLegalEntityNameValid && 'Legal Entity Name is required'}
					/>
					<Space y={1} />
					<Input
						value={Email}
						onChange={e => setEmail(e.target.value)}
						className="w-100"
						label="Email (Optional)"
						placeholder="Enter new Email"
						errorLabel={!isEmailValid && 'Please enter valid email'}
					/>
					<Space y={1} />
					<Input
						value={Phone}
						onChange={e => {
							if (!isNaN(e.target.value)) {
								setPhone(e.target.value)
							}
						}}
						className="w-100"
						label="Contact Number (Optional)"
						placeholder="Enter new Phone number"
						maxLength={10}
						disabled={contactEditDisabled}
						errorLabel={!isPhoneValid && 'Please enter a valid Phone Number'}
					/>
					<Space y={1} />
					<Input
						value={WaPhone}
						onChange={e => {
							if (!isNaN(e.target.value)) {
								setWaPhone(e.target.value)
							}
						}}
						maxLength={10}
						className="w-100"
						label="WhatsApp Number (Optional)"
						placeholder="Enter new WhatsApp number"
						disabled={contactEditDisabled}
						errorLabel={!isWaPhoneValid && 'Please enter a valid Whatsapp Number'}
					/>
					<Space y={1} />
					<BlueBanner>
						Edit Name will be used in all policy documents being issued. <br />
						Please make the edit only if this is required.
					</BlueBanner>
					<Space y={1} />
					<Button
						disabled={disableProceed}
						style={{ height: '3rem' }}
						label="Save Changes"
						primary
						className="w-100"
						onClick={saveChanges}
					/>
				</div>
			</Modal>
			<ToastContainer />
		</>
	)
}

const BlueBanner = styled.div`
	background: #d3defd;
	padding: 5px 5px 5px 12px;
	font-weight: 500;
	font-size: 12px;
	color: #244bce;
	border-radius: 4px;
`
