import { useContext, useState } from 'react'
import { Row } from 'react-bootstrap'
import { RadioHolder, CustomRadio } from '../commonUtils/commonExports'

import { Text, Space, theme, Divider } from 'verak-ui'

import SingleProperty from '../property/SingleProperty'
import MultipleProperty from '../property/MultipleProperty'
import WholeProperty from '../property/WholeProperty'
import { Context } from '../../../context/Provider'

function initSubType(state) {
	const { Type } = state.location || {}
	if (Type === 'PROPERTYBASEMENT') {
		return state.location?.property?.SubType || ''
	}
	return state.location?.SubType || ''
}

//Aniket add changes here to support different levels for property
const PropertyTypeSelector = ({ saveData, setInputCompleteStatus }) => {
	const [state] = useContext(Context)

	const [propertyType, setPropertyType] = useState(initSubType(state))
	const onTypeSelect = val => {
		setPropertyType(val)
	}

	const setLevelToSingle = () => {
		setPropertyType('Single')
	}

	const setData = data => {
		saveData({
			propertyType: propertyType,
			levels: data.levels,
		})
	}

	let selectedOption
	if (propertyType === 'Single') {
		selectedOption = (
			<SingleProperty
				onInputComplete={value => setInputCompleteStatus(value)}
				onDataComplete={setData}
			/>
		)
	} else if (propertyType === 'Multiple') {
		selectedOption = (
			<MultipleProperty
				onInputComplete={value => setInputCompleteStatus(value)}
				onPropertyLevelSingle={setLevelToSingle}
				onDataComplete={setData}
			/>
		)
	} else if (propertyType === 'Whole') {
		selectedOption = (
			<WholeProperty
				onInputComplete={value => setInputCompleteStatus(value)}
				onDataComplete={setData}
			/>
		)
	}

	const isStockOnly = state?.quote?.PolicyKind === 'STOCK_ONLY'

	let titleText = 'How many floors does the client want insured?'

	if (isStockOnly) {
		titleText = 'How many floors contain the stock to be insured?'
	}

	return (
		<>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<Row className="d-flex align-items-center">
				<Text style={{ fontWeight: 700, fontSize: '24px' }}>
					{titleText}
					<span style={{ color: theme.colors.red }}>*</span>
				</Text>
			</Row>
			<Row>
				<RadioHolder className="d-flex">
					<form onSubmit={e => e.preventDefault()}></form>
					<CustomRadio
						value={propertyType === 'Single'}
						onChange={() => onTypeSelect('Single')}
						secondary
						text="SINGLE"
						name="propertyLevel"
						key="SINGLE_PROPERTY_OPTION"
					/>
					<Space x={2.5} mobile={false} />
					<CustomRadio
						value={propertyType === 'Multiple'}
						onChange={() => onTypeSelect('Multiple')}
						secondary
						text="MULTIPLE"
						name="propertyLevel"
						key="MULTIPLE_PROPERTY_OPTION"
					/>
					<Space x={2.5} mobile={false} />
					<CustomRadio
						value={propertyType === 'Whole'}
						onChange={() => onTypeSelect('Whole')}
						secondary
						text="WHOLE BUILDING"
						name="propertyLevel"
						key="WHOLE_PROPERTY_OPTION"
					/>
				</RadioHolder>
			</Row>
			<Row>{selectedOption}</Row>
			<Divider />
		</>
	)
}

export default PropertyTypeSelector
