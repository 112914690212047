import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { theme, Text } from 'verak-ui'

const CardStyle = styled(Row)`
	background: ${theme.colors.white};
	border-radius: 10px;
	box-shadow: 0px 0px 8px rgb(0 0 0 / 15%);
`

const SectionCard = styled(Row)`
	background: ${theme.colors.white};
	border-radius: 10px;
	box-shadow: 0px 0px 8px rgb(0 0 0 / 15%);
	margin: 8px auto;
	width: 100%;
`

const InfoItem = ({
	label,
	value,
	renderIfAvailable = false,
	withCol = false,
	colWidth = 3,
	key,
}) => {
	if (renderIfAvailable && !value) return null

	let labelContainer = (
		<Text fontSize="12px" fontWeight="500" color={theme.colors.gray2}>
			{label}
		</Text>
	)
	let valueContainer = (
		<Text fontSize="14px" fontWeight="500" color={theme.colors.black}>
			{Boolean(value) ? value : '-'}
		</Text>
	)

	if (typeof label !== 'string') labelContainer = label
	if (typeof value !== 'string') valueContainer = value

	let container = (
		<div className="d-flex flex-column mt-2" key={withCol ? '' : key}>
			{labelContainer}
			{valueContainer}
		</div>
	)

	if (withCol) {
		return (
			<Col key={key} md={colWidth}>
				{container}
			</Col>
		)
	}
	return container
}

export { CardStyle, SectionCard, InfoItem }
