import { Text, theme } from 'verak-ui'
import styled from 'styled-components'

import GoogleLogo from '../../../../assets/google_logo.svg'
import IndiamartLogo from '../../../../assets/indiamart_logo.svg'

import { sections } from '../components/sections'

const IndexItem = ({ data, selected, onChangeSelectedTopic }) => {
	const [key, value] = data
	const { icon, title } = value
	return (
		<IndexItemWrapper
			onClick={() => onChangeSelectedTopic(key)}
			selected={selected}
			className="d-flex align-items-center"
		>
			{icon}
			<Text fontSize="14px" fontWeight="500" className="ms-2">
				{title}
			</Text>
		</IndexItemWrapper>
	)
}

const NavigationSidebar = ({
	activeSection,
	onChangeActiveSection,
	selectedTopic,
	onChangeSelectedTopic,
	companyName,
	showShortcuts,
}) => {
	const indexList = Object.entries(sections[activeSection].contentIndex).map(indexItem => {
		const isDivider = indexItem[1].type === 'DIVIDER'
		return isDivider ? (
			<Divider name={indexItem[1].title} key={indexItem[0]} />
		) : (
			<IndexItem
				onChangeSelectedTopic={onChangeSelectedTopic}
				selected={selectedTopic === indexItem[0]}
				key={indexItem[0]}
				data={indexItem}
			/>
		)
	})

	return (
		<NavigationSidebarWrapper className="d-flex flex-column justify-content-between">
			<TopContainer>
				<div className="d-flex align-items-center justify-content-center mt-2">
					<NavTab
						onClick={() => onChangeActiveSection(sections.TRAINING.value)}
						isActive={activeSection === sections.TRAINING.value}
					>
						How to Sell?
					</NavTab>
					<NavTab
						onClick={() => onChangeActiveSection(sections.CHEATSHEET.value)}
						isActive={activeSection === sections.CHEATSHEET.value}
					>
						Cheat sheet
					</NavTab>
				</div>

				<IndexContainer className="d-flex flex-column">{indexList}</IndexContainer>
			</TopContainer>

			{showShortcuts && <Shortcuts companyName={companyName} />}
		</NavigationSidebarWrapper>
	)
}

export default NavigationSidebar

const Divider = ({ name }) => {
	return (
		<DividerWrapper className="d-flex w-100 align-items-center">
			<TextBorder color="#C8D6DB" fontSize="8px" fontWeight="600">
				{name}
			</TextBorder>
			<Border></Border>
		</DividerWrapper>
	)
}

const Shortcuts = ({ companyName }) => {
	return (
		<ShortcutCardWrapper className="d-flex align-items-center justify-content-center">
			<ShortcutCard className="me-2">
				<a
					href={`https://dir.indiamart.com/search.mp?ss=${companyName}&prdsrc=1`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={IndiamartLogo} alt="indiamart" className="" />
				</a>
			</ShortcutCard>
			<ShortcutCard className="ms-2">
				<a
					href={`https://www.google.com/search?q=${companyName}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={GoogleLogo} alt="google" className="" />
				</a>
			</ShortcutCard>
		</ShortcutCardWrapper>
	)
}

const NavigationSidebarWrapper = styled.div`
	background: ${theme.colors.primary};
	width: 28%;
	border-radius: 8px;
	scrollbar-width: thin;
	scrollbar-color: #c5c5c5 #f6f6f6;
	height: 100%;
	font-family: Inter !important;
`

const ShortcutCardWrapper = styled.div`
	background: #ffffff;
	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: ${theme.colors.primary};
	border-radius: 8px;
`

const ShortcutCard = styled.div`
	background: #ffffff;
	border: 1px solid rgba(2, 71, 94, 0.2);
	border-radius: 8px;
	padding: 4px 8px;
	margin: 8px 0px;
	cursor: pointer;
`

const NavTab = styled.div`
	background: ${props =>
		props.isActive ? 'linear-gradient(180deg, #c8d6db 0%, #ffffff 100%)' : theme.colors.white};
	color: ${theme.colors.primary};
	border: 2px solid ${props => (props.isActive ? theme.colors.primary : 'transparent')};
	border-bottom: none;
	border-radius: 8px 8px 0px 0px;
	padding: 8px 16px;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	user-select: none;
	margin: 0px 4px;
	font-family: Inter;
`

const IndexItemWrapper = styled.div`
	background: ${props => (props.selected ? theme.colors.white : theme.colors.primary)};
	color: ${props => (props.selected ? theme.colors.primary : theme.colors.white)};
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
	div {
		font-family: Inter;
	}
	&:hover {
		background: ${props => (props.selected ? theme.colors.white : '#0e607b')};
	}
`

const TextBorder = styled(Text)`
	letter-spacing: 1px;
	margin: 4px 0px;
	margin-left: 8px;
`

const Border = styled.div`
	border: 0.5px solid #c8d6db;
	margin: 0px 8px;
	flex-grow: 1;
`

const IndexContainer = styled.div`
	height: 100%;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #c5c5c5 #f6f6f6;
	border-top: 2px solid ${theme.colors.primary};
	background: ${theme.colors.primary};
`

const TopContainer = styled.div`
	height: calc(100% - 100px);
	background: white;
`

const DividerWrapper = styled.div`
	background: ${theme.colors.primary};
`
