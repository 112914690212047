export const convertToNumber = value => {
	if (value) {
		let updatedValue = value.split(',').join('')
		// accept 0s also
		if (updatedValue || updatedValue === 0) {
			return updatedValue
		}
	}

	return ''
}
