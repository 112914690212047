import md5 from 'js-md5'
import { checkIfRenewalsFlow } from './renewalsUtils'

const generateHash = draftRfq => {
	const { _id, Account, Data } = draftRfq
	if (_id) {
		return md5(`${_id}${Account.UID}${Data.Cover}`)
	}
	return md5(`${Account.UID}${Data.Cover}`)
}

const autosaveDraft = draftToSave => {
	const renewalsPrefix = checkIfRenewalsFlow() ? 'renewals_' : ''
	const toHash = generateHash(draftToSave)
	const sessionKey = 'session_' + renewalsPrefix + md5(toHash)

	if (localStorage.getItem(sessionKey) === null) {
		const toSave = { ...draftToSave, timestamp: new Date().toISOString() }
		localStorage.setItem(sessionKey, JSON.stringify(toSave))

		console.log(`[INFO] Saving new draft - ${sessionKey}`)
	} else {
		let savedDraft = localStorage.getItem(sessionKey)
		savedDraft = JSON.parse(savedDraft)
		delete savedDraft.timestamp
		savedDraft = JSON.stringify(savedDraft)

		const currentDraft = JSON.stringify(draftToSave)

		// compare drafts without timestamp
		if (currentDraft !== savedDraft) {
			const toSave = JSON.stringify({ ...draftToSave, timestamp: new Date().toISOString() })
			localStorage.setItem(sessionKey, toSave)

			console.log(`[INFO] Saving updated draft - ${sessionKey}`)
		}
	}
}

export { autosaveDraft }
