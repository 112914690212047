export const getSumInsuredData = title => {
	switch (title) {
		case 'BuildingValue':
			return { title: 'Building', icon: 'building' }
		case 'BasementValue':
			return { title: 'Basement', icon: 'building' }
		case 'PlinthFoundation':
			return { title: 'Plinth & Foundation', icon: 'plinthAndFoundation' }
		case 'BoundaryWalls':
			return { title: 'Boundary Walls, etc.', icon: 'boundaryWalls' }
		case 'PlantMachinery':
			return { title: 'Plant & Machinery', icon: 'plantAndMachinery' }
		case 'FurnitureFixture':
			return { title: 'Furniture & Fixture', icon: 'furniture' }
		case 'Electronics':
			return { title: 'Electronic Installations', icon: 'electronicInstallations' }
		case 'OfficeEquipment':
			return { title: 'Office Equipment (including IT systems)', icon: 'officeEquipment' }
		case 'ValuableContents':
			return { title: 'Valuable contents', icon: 'valuables' }
		case 'Other':
			return { title: 'Other contents', icon: 'otherValuables' }
		case 'MaxValSingleItem':
			return { title: 'Maximum value of a single content item', icon: 'valuables' }
		case 'Stock':
			return { title: 'Stock', icon: 'building' }
		default:
			return { title: title, icon: 'building' }
	}
}
