import styled from 'styled-components'

import { Row, Container } from 'react-bootstrap'

import { Radio, Button, theme, Text, Select, Space, Modal, Alert } from 'verak-ui'

// styles

export const CustomRadio = styled(Radio)`
	.radio__label {
		color: ${theme.colors.secondary};
		font-weight: bold;
		cursor: pointer;
	}
	@media (max-width: 768px) {
		.radio__label {
			font-size: 14px;
		}
		.radio-before {
			margin: 0.8rem 0;
		}
	}
`

export const RadioHolder = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const MobileHeaderWrapper = styled.div`
	@media (min-width: 768px) {
		display: none !important;
	}
	@media (max-width: 768px) {
		display: flex;
	}
`

export const CustomFlexHolder = styled.div`
	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const CustomRow = styled(Row)`
	margin: 1rem 0;

	@media (max-width: 768px) {
		background: ${theme.colors.secondary3};
		padding: 10px;
		border-radius: 10px;
	}
`

export const Wrapper = styled.div`
	@media (max-width: 768px) {
		margin-bottom: ${({ showLabel }) => `${showLabel ? '0rem' : '.6rem'}`};
		display: ${({ mobile }) => `${!mobile ? 'none !important' : 'flex'}`};
	}
`

export const CustomText = styled(Text)`
	@media (min-width: 768px) {
		height: ${({ showLabel }) => `${!showLabel && '2px'}`};
		visibility: ${({ showLabel }) => `${!showLabel && 'hidden'}`};
		margin-bottom: ${({ showLabel }) => `${showLabel && '1rem'}`};
	}
	@media (max-width: 768px) {
		margin-top: 0.8rem;
		margin-bottom: 0.8rem;
	}
`

export const CustomSelect = styled(Select)`
	width: 390px;
	height: 48px;

	@media (max-width: 768px) {
		width: 100%;
		background: white !important;
	}
`

// other data

export const primaryPropertyTypes = [
	'Manufacturing or other Industrial Property',
	'Dwellings, Office, Shop or Hotel',
	'Utility located outside of manufacturing unit',
	'Storage facility outside of manufacturing unit',
	'Tank or gas storage facility outside of manufacturing unit',
]

export const subPropertyTypes = [
	{ name: 'Abrasive Manufacturing', value: 'Abrasive Manufacturing' },
	{ name: 'Agarbatti manufacturing', value: 'Agarbatti manufacturing' },
	{ name: 'Aircraft Hangers', value: 'Aircraft Hangers' },
	{ name: 'Aerated Water Factories', value: 'Aerated Water Factories' },
]

// components

// helper/utility components

const InputGroupWrapper = styled.div`
	@media (max-width: 768px) {
		margin-bottom: ${({ showLabel }) => `${showLabel ? '0rem' : '.6rem'}`};
		display: ${({ mobile }) => `${!mobile ? 'none !important' : 'flex'}`};
	}
`

export function InputGroup({
	label,
	children,
	leftBorder,
	style = {},
	mobile = true,
	showLabel = true,
	secondaryLabelElement,
}) {
	let primaryLabel

	if (label === '') {
		primaryLabel = <Space y={1} />
	} else {
		primaryLabel = (
			<CustomText showLabel={showLabel} fontSize="18px">
				{label}

				{secondaryLabelElement}

				{showLabel && !secondaryLabelElement && <Space y={1.7} />}
			</CustomText>
		)
	}

	return (
		<InputGroupWrapper
			className="d-flex flex-column justify-content-center"
			mobile={mobile}
			showLabel={showLabel}
			style={{
				paddingRight: '.8rem',
				marginRight: '0.6rem',
				borderLeft: leftBorder ? `1px solid ${theme.colors.gray1} !important` : '',
				...style,
			}}
		>
			{primaryLabel}

			{children}
		</InputGroupWrapper>
	)
}

export function CustomAlert({ innerText }) {
	return (
		<Container>
			<Alert title="Note" titleColor={theme.colors.secondary} variant="warning">
				<Text
					color={theme.colors.secondary}
					style={{
						fontWeight: 'bold',
					}}
				>
					{innerText}
				</Text>
			</Alert>
		</Container>
	)
}

// functions

export const renderLevels = (type, sign = false, toExclude = []) => {
	const options = []

	if (type === 'PROPERTY') {
		for (let i = 0; i <= 100; i++) {
			let indexPresent = toExclude.indexOf(i + '')
			if (indexPresent === -1) {
				options.push(
					<option key={i} value={i}>
						{i}
					</option>
				)
			}
		}
	} else if (type === 'BASEMENT') {
		for (let i = 1; i <= 20; i++) {
			let value = sign ? `${i}` : `-${i}`
			let indexPresent = toExclude.indexOf(value)
			if (indexPresent === -1) {
				options.push(
					<option key={i} value={value}>
						{value}
					</option>
				)
			}
		}
	}
	return options
}

export function FreeTextSwitchModal({ show, handleClose, warningFor = 'risk occupancy' }) {
	return (
		<Modal
			title={
				<Text type="h5" fontWeight="bold" color={theme.colors.red}>
					Notice
				</Text>
			}
			footer={<></>}
			show={show}
			handleClose={handleClose}
		>
			<div className="text-center">
				<Text type="h5" fontWeight="bold" color={theme.colors.primary}>
					Since you will be entering a free text response for the {warningFor}, this RFQ will need
					to be reviewed by an underwriter appointed by Insure First Insurance Brokers LLP.
				</Text>
				<Text type="h5" fontWeight="bold" color={theme.colors.secondary}>
					Please note that this will delay the TAT of the quote.
				</Text>

				<Button
					className="mt-3"
					label="Continue"
					icon="rightArrow"
					iconAlign="right"
					style={{
						width: '100%',
						height: '48px',
						fontSize: '18px',
					}}
					onClick={handleClose}
				/>
			</div>
		</Modal>
	)
}
