import { useHistory } from 'react-router'
import SumInsuredBreakdown from '../../../FinalReview/SumInsuredBreakdown'

const ClientVerificationSumInsuredBreakdown = () => {
	const history = useHistory()

	const onBack = () => {
		history.push('/rfq-status/client-verification-pending/final-review')
	}
	const onProceed = () => {
		history.push('/quotes-in-progress')
	}
	const proceedBtnText = 'Go back to home'

	return (
		<SumInsuredBreakdown onBack={onBack} onProceed={onProceed} proceedBtnText={proceedBtnText} />
	)
}

export default ClientVerificationSumInsuredBreakdown
