import { userReducer } from './userReducer'
import { appReducer } from './appReducer'
import { quoteReducer } from './quoteReducer'
import { locationReducer } from './locationReducer'
import { sqsReducer } from './sqsReducer'
import { rfqReducer } from './rfqReducer'
import { oldRfqReducer } from './oldRfqReducer'

// get location data for RL with no levels
function getLocationWithNoLevels(locationId, currentRiskLocation, propertyOrbasementKey = '') {
	// ONLY PROPERTY OR BASEMENT
	if (propertyOrbasementKey === '') {
		if (currentRiskLocation.ID === locationId) {
			return currentRiskLocation
		} else {
			return null
		}
	} else {
		// PROPERTY + BASEMENT
		if (currentRiskLocation[propertyOrbasementKey].ID === locationId) {
			return currentRiskLocation
		} else {
			return null
		}
	}
}

// get location data for RL with levels
function getLocationWithLevels(locationId, currentRiskLocation, propertyOrbasementKey = '') {
	// ONLY PROPERTY OR BASEMENT
	if (propertyOrbasementKey === '') {
		if (currentRiskLocation.levels.find(levelItem => levelItem.ID === locationId)) {
			return currentRiskLocation
		} else {
			return null
		}
	} else {
		// PROPERTY + BASEMENT
		if (
			currentRiskLocation[propertyOrbasementKey].levels.find(
				levelItem => levelItem.ID === locationId
			)
		) {
			return currentRiskLocation
		} else {
			return null
		}
	}
}

const mainReducer = ({ user, app, quote, location, sqs, rfq, oldRfq }, action) => {
	if (action.type === 'RESUME_QUOTE_LOCATION') {
		const locationId = action.data.locationId

		let toLocation = quote.RiskLocations.find(currentRiskLocation => {
			const isPropertyBasement = currentRiskLocation.Type === 'PROPERTYBASEMENT'
			let toReturn
			// check if PROPERTY+BASEMENT has levels
			if (!isPropertyBasement) {
				if (currentRiskLocation?.levels?.length > 0) {
					toReturn = getLocationWithLevels(locationId, currentRiskLocation)
				} else {
					// for no levels
					toReturn = getLocationWithNoLevels(locationId, currentRiskLocation)
				}
			} else {
				if (currentRiskLocation.basement) {
					if (currentRiskLocation.basement?.levels?.length > 0) {
						toReturn = getLocationWithLevels(locationId, currentRiskLocation, 'basement')
					} else {
						toReturn = getLocationWithNoLevels(locationId, currentRiskLocation, 'basement')
					}
				}
			}
			return !!toReturn
		})

		let updatedLocation = { ...location }

		if (toLocation) {
			updatedLocation = toLocation
		}

		return {
			user: { ...user },
			app: { ...app },
			quote: { ...quote },
			location: updatedLocation,
			sqs: { ...sqs },
			rfq: { ...rfq },
			oldRfq: { ...oldRfq },
		}
	} else if (action.type == 'MOVE_TO_LOCATION') {
		const locationId = action.data.locationId
		const toLocation = quote.RiskLocations.find(item => {
			return item.ID === locationId
		})
		let updatedLocation = { ...location }
		if (toLocation) {
			updatedLocation = toLocation
		}
		return {
			user: { ...user },
			app: { ...app },
			quote: { ...quote },
			location: { ...updatedLocation },
			sqs: { ...sqs },
			rfq: { ...rfq },
			oldRfq: { ...oldRfq },
		}
	} else if (
		action.type === 'SAVE_LOCATION_CHANGES' ||
		action.type === 'SAVE_LOCATION_CHANGES_AND_NAVIGATE'
	) {
		// move data from state.location into state.quote.riskLocations

		const toSaveData = { ...location }
		let toUpdateLocation
		const toUpdatedQuote = {
			...quote,
		}
		const index = toUpdatedQuote.RiskLocations.findIndex(item => {
			return item.ID === toSaveData.ID
		})
		if (index > -1) {
			toUpdatedQuote.RiskLocations[index] = toSaveData
		}

		if (action.type === 'SAVE_LOCATION_CHANGES_AND_NAVIGATE') {
			toUpdateLocation = null
		} else {
			toUpdateLocation = { ...location }
		}
		return {
			user: { ...user },
			app: { ...app },
			quote: toUpdatedQuote,
			location: toUpdateLocation,
			sqs: { ...sqs },
			rfq: { ...rfq },
			oldRfq: { ...oldRfq },
		}
	} else if (action.type === 'CLEAR_DATA') {
		return {
			user: { ...user },
			app: {
				...app,
				clientCreated: false,
			},
			quote: null,
			location: null,
			sqs: null,
			rfq: null,
			oldRfq: null,
		}
	}
	return {
		user: userReducer(user, action),
		app: appReducer(app, action),
		quote: quoteReducer(quote, action),
		location: locationReducer(location, action), // this will contain data of current location which is being edited
		sqs: sqsReducer(sqs, action),
		rfq: rfqReducer(rfq, action),
		oldRfq: oldRfqReducer(oldRfq, action),
	}
}

export default mainReducer
