import { useState } from 'react'
import { Text, Icon, Space, theme } from 'verak-ui'
import styled from 'styled-components'
import {
	BlueText,
	CustomImage,
	CheatSheetContent,
	// images
	aag,
	aandhi,
	aatank,
	baadh,
	bhukamp,
	bhuskhalan,
	chori,
	dakaiti,
	danga,
	dharna,
	pani_tanki,
	visfot,
} from '../../../../../DraftRFQ/components/CheatSheetCard'
import { claimsCount } from '../../../../../../utilities/claimsCount'

import ClaimsDoc from '../../../../../../assets/ClaimsDoc.png'
import { useWindowDimensions } from '../../../../../../hooks/useWindowDimensions'
const CheatSheetPointers = styled.ul`
	list-style-type: disc;
	li {
		padding: ${props => (props.padding === false ? '8px 4px' : '16px 4px')};
		border-bottom: ${props => (props.padding === false ? 'none' : '1px solid #e6e6e6')};
		font-weight: 500px;
	}
	li:last-child {
		border-bottom: none;
	}
`

const getTabs = onClaimsDisplay => {
	const cheatSheetContent = [
		{
			title: 'Why us?',
			icon: '⚡',
			content: (
				<CheatSheetContent>
					<div className="d-flex">
						<CheatSheetPointers>
							<li>
								Settled <BlueText>{claimsCount} claims last year</BlueText>
							</li>
							<li>
								Existed for <BlueText>5+ years</BlueText>
							</li>
							<li>
								<BlueText>5,000+ Vyapari clients</BlueText> this year
							</li>
							<li>
								<BlueText>Paperless process</BlueText>: Purchase to Claims
							</li>
							<li>
								Instant Quotes from 3+ reputed insurers :{' '}
								<BlueText>United India, Oriental, National, New India</BlueText>
							</li>
							<li>
								Best prices <BlueText>guaranteed</BlueText>
							</li>
							<li>Give daily rate of premium to customer</li>
							<li>
								Our website - <BlueText>https://verak.in</BlueText>
							</li>
							<li>
								<BlueText>Daily rate of premium</BlueText>
							</li>
							<li>
								<BlueText>24x7 Whatsapp support</BlueText>: +91 9769161630
							</li>
							<li>
								<BlueText>Customer Care Number</BlueText>: +91 9076277062 <br />
								(10 AM to 7PM, Monday to Saturday)
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Vyapar S',
			icon: '⛱',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li>
								<BlueText>15+ durghatna ke nuksaan se</BlueText> suraksha
							</li>
							<li>
								Low Premium: <BlueText>₹250 per ₹1 Lac</BlueText> - Starting from ₹473
							</li>
							<li>
								Covers
								<div className="d-flex align-items-center">
									<CustomImage src={chori} alt="policy-covers" />
									<CustomImage src={aag} alt="policy-covers" />
									<CustomImage src={dharna} alt="policy-covers" />
								</div>
								<div className="d-flex align-items-center">
									<CustomImage src={danga} alt="policy-covers" />
									<CustomImage src={dakaiti} alt="policy-covers" />
									<CustomImage src={baadh} alt="policy-covers" />
								</div>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Convincing',
			icon: '💰',
			content: (
				<CheatSheetContent style={{ marginLeft: '-1rem' }} className="mt-3">
					<div className="d-flex flex-column w-100">
						<CheatSheetPointers>
							<li>
								<BlueText>LIC Jeevan Bima Example</BlueText>
								<ul>
									<li>Self die, family gets money from LIC policy</li>
									<li>Business loss, what about family?</li>
								</ul>
							</li>

							<li>
								<BlueText>Premium Discount</BlueText>
								<ul>
									<li>
										Ask for theft protection (चोरी से बचाव के उपाय) - Agar Tala bhi hai toh 10%
										discount milta hai
									</li>
									<li>Ask for fire protection (अग्नि सुरक्षा उपाय)</li>
									<li>GST number use karke 18% savings pahiye</li>
								</ul>
							</li>

							<li>
								<BlueText>Price Match (Best Price Guaranteed)</BlueText>
								<ul style={{ fontSize: '13px' }}>
									<li>Will give customer best price</li>
									<li>
										Agar kahin se kam premium par yeh policy mil rahi hai, to aap hume batao aur hum
										aapke liye usi premium mein option la sakte hain
									</li>
								</ul>
							</li>

							<li>
								<BlueText>Maruti Suzuki car example</BlueText>
								<ul style={{ fontSize: '13px' }}>
									<li>₹5 Lac car = ₹8,000 premium</li>
									<li>₹5 Lac stock = ₹1,250 premium</li>
								</ul>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'T&C',
			icon: '📝',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li>
								<BlueText>#1: Har claims se 5,000 cut hoga</BlueText>
								<ul>
									<li>Please explain why</li>
								</ul>
							</li>
							<li>
								<BlueText>#2: Details given by customer is correct</BlueText>
								<ul>
									<li>Stock, machine, IT equipment, furniture value etc</li>
									<li>Fire safety & theft safety given correctly</li>
								</ul>
							</li>
							<li>
								<BlueText>#3: Under-insurance clause</BlueText>
								<ul>
									<li>Explain what is 15% limit</li>
								</ul>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Covered',
			icon: '🔥',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li>
								<Text color={theme.colors.black}>Important</Text>
								<div className="d-flex align-items-center">
									<CustomImage src={chori} alt="policy-covers" />
									<Space x={1.2} />
									<CustomImage src={danga} alt="policy-covers" />
								</div>
								<div className="d-flex align-items-center">
									<CustomImage src={dakaiti} alt="policy-covers" />
									<Space x={0.6} />
									<CustomImage src={dharna} alt="policy-covers" />
								</div>
								<div className="d-flex align-items-center">
									<CustomImage src={visfot} alt="policy-covers" />
									<CustomImage src={aatank} alt="policy-covers" />
								</div>
							</li>

							<li>
								<Text color={theme.colors.black}>Also mention</Text>
								<div className="d-flex align-items-center">
									<CustomImage src={aag} alt="policy-covers" />
									<Space x={1.5} />
									<CustomImage src={baadh} alt="policy-covers" />
								</div>
								<div className="d-flex align-items-center">
									<CustomImage src={aandhi} alt="policy-covers" />
									<Space x={1} />
									<CustomImage src={bhukamp} alt="policy-covers" />
								</div>
								<div className="d-flex align-items-center">
									<CustomImage src={bhuskhalan} alt="policy-covers" />
									<CustomImage src={pani_tanki} alt="policy-covers" />
								</div>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Benefits',
			icon: '🌟',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li>
								Cash upto <BlueText>₹50,000 is covered</BlueText>
							</li>
							<li>
								<BlueText>Cost to replace documents up to ₹50,000</BlueText>
								<br />
								<br /> Example <br />
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">Company formation documents</li>
									<li className="mb-1">Business plans</li>
									<li className="mb-1">Accounting books</li>
								</CheatSheetPointers>
							</li>

							<li>
								<BlueText>Personal belongings of employees, owner & visitors</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">Covered upto ₹15,000 per person</li>
									<li className="mb-1">
										Max coverage <br /> 20 X ₹15,000 = ₹3 Lakhs
									</li>
								</CheatSheetPointers>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Paperless',
			icon: '📱',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li className="pb-0">
								<BlueText className="mb-2">Started due to Covid</BlueText>
								<br />
								last year - had to be done without field visit
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="">5,000+ policies sold this way</li>
									<li className="mb-1">{claimsCount} claims done too</li>
								</CheatSheetPointers>
							</li>

							<li className="pb-0">
								<BlueText>We use Satellite images from ISRO</BlueText>
								<br />
								(Govt of India सरकारी अंतरिक्ष Agency)
								<br />
								to capture images of the outside of the facility
								<br />
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">No physical inspection needed</li>
								</CheatSheetPointers>
							</li>

							<li>
								<BlueText>We use data from IT (Tax)Department & ROC (Company Registrar)</BlueText>
								<br /> to verify business registration & existence (for fraud) <br />
							</li>

							<li className="pb-0">
								<BlueText>Tie up with insurers for paperless process:</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">
										<strong>No physical proposal form</strong> - we fill in your details - RFQ sent
										in PDF from to Whatsapp number of clients
									</li>
									<li className="mb-1">
										<strong>No wet signatures</strong> - Mobile OTP during purchase process suffices
										as per IRDAI (Govt) rules
									</li>
								</CheatSheetPointers>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Tips for NO claim rejection',
			icon: '❌',
			content: (
				<CheatSheetContent>
					<div className="d-flex w-100">
						<CheatSheetPointers>
							<li className="pb-0">
								<BlueText className="mb-2">What FLOOR is the business located on?</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="">1st floor, ground floor or basement (जमीन के नीचे)</li>
									<li className="mb-1">
										Customer should provide address on official WhatsApp number
									</li>
								</CheatSheetPointers>
							</li>

							<li className="pb-0">
								<BlueText>What TYPE of business do they run?</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">
										<strong>Facility type</strong> - Shop, Godown, Factory, Service center,
										Restaurant, Cafe
									</li>
									<li className="mb-1">
										<strong>Business Type</strong> - Selling what? Making what? Storing what?
									</li>
									<li className="mb-1">
										<strong>Be careful</strong> - Shop or godown? Kutcha or Pukka?
									</li>
								</CheatSheetPointers>
							</li>

							<li className="pb-0">
								<BlueText>What is their Stock?</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">
										Treat Finished Goods and Raw Material as 2 separate values
									</li>
								</CheatSheetPointers>
							</li>

							<li className="pb-0">
								<BlueText>NO documents for purchase - soft copy documents for claims</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">They will need to sign the claim form</li>
								</CheatSheetPointers>
							</li>
							<li className="pb-0">
								<BlueText>IF you cannot do one call Sale</BlueText>
								<CheatSheetPointers padding={false} className="ps-3">
									<li className="mb-1">
										Push customer to verify details in the RFQ PDF which is sent to them
									</li>
								</CheatSheetPointers>
							</li>
						</CheatSheetPointers>
					</div>
				</CheatSheetContent>
			),
		},
		{
			title: 'Claim Docs',
			icon: '📝',
			content: (
				<CheatSheetContent className="w-100 flex-column mt-3">
					<BlueText className="mb-2">Documents needed for a claim</BlueText>
					<img src={ClaimsDoc} style={{ width: '100%' }} alt="claims doc" />
					<div className="my-2 d-flex justify-content-end">
						<div className="border p-1" onClick={onClaimsDisplay} style={{ cursor: 'pointer' }}>
							<Icon name="enlarge" />
						</div>
					</div>
				</CheatSheetContent>
			),
		},
		// {
		// 	title: 'Claims',
		// 	icon: '☎️',
		// 	content: <CheatSheetContent></CheatSheetContent>,
		// },
	]
	return cheatSheetContent
}

const CheatSheet = ({ onClaimsDisplay, fullWidth = false }) => {
	const [activeTab, setActiveTab] = useState(0)

	const cheatSheetContent = getTabs(onClaimsDisplay)

	const showPrevCheatSheet = () => {
		if (activeTab === 0) {
			return
		}
		setActiveTab(activeTab - 1)
	}
	const showNextCheatSheet = () => {
		if (activeTab === cheatSheetContent.length - 1) {
			return
		}
		setActiveTab(activeTab + 1)
	}

	const tabTitles = cheatSheetContent.map((cheatSheet, index) => (
		<TabTitle
			key={cheatSheet.title}
			className="my-1 mx-2 tag p-2 badge"
			onClick={() => setActiveTab(index)}
			active={activeTab === index}
		>
			<Text fontWeight="500" fontSize="14px">
				{cheatSheet.icon}
				&nbsp;
				{cheatSheet.title}
			</Text>
		</TabTitle>
	))

	return (
		<ContainerWrapper fullWidth={fullWidth} className="d-flex flex-column mt-3">
			<div className="d-flex justify-content-between">
				<BlueText mobileFontSize="14px">Cheat Sheet</BlueText>
				<div className="d-flex">
					<Icon onClick={showPrevCheatSheet} name="leftArrow" className={'me-2'} />
					<Icon onClick={showNextCheatSheet} name="rightArrow" color={theme.colors.gray} />
				</div>
			</div>
			<div className="mt-2">{tabTitles}</div>
			<div>{cheatSheetContent[activeTab].content}</div>
		</ContainerWrapper>
	)
}

export default CheatSheet

const ContainerWrapper = styled.div`
	width: ${({ fullWidth }) => (fullWidth ? '100%' : '350px')};
	flex-shrink: 0;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow: hidden;
	margin-right: 1rem;
	background: ${theme.colors.white};
	padding: 10px;
`

const TabTitle = styled.span`
	border-radius: 4px;
	background: #eff6ff;
	color: #000;
	cursor: pointer;
	box-shadow: ${({ active }) => (active ? '0px 1px 8px rgba(0, 0, 0, 0.15)' : 'none')};
	border: ${({ active }) => (active ? '1px solid #6467b0' : '1px solid transparent')};
	margin: 0.25rem;
`
