import styled from 'styled-components'
import { theme, Icon } from 'verak-ui'

const Pagination = ({ totalPages, activePage, pageNumberClicked }) => {
	const pageNumberList = []
	for (let i = 0; i <= totalPages - 1; i++) {
		pageNumberList.push(
			<PageNumber
				key={`pagination-item-${i}`}
				onClick={() => pageNumberClicked(i)}
				active={activePage === i}
			>
				{i + 1}
			</PageNumber>
		)
	}

	const handleArrowClick = direction => {
		if (direction === 'prev' && activePage > 0) {
			pageNumberClicked(activePage - 1)
		} else if (direction === 'next' && activePage < totalPages - 1) {
			pageNumberClicked(activePage + 1)
		}
	}

	return (
		<div className="d-flex flex-column align-items-center">
			<PaginationWrapper>
				<div className="d-flex align-items-center">
					<PageNumber onClick={() => handleArrowClick('prev')}>
						<Icon name="leftArrow" color={theme.colors.gray3} />
					</PageNumber>
					{pageNumberList}
					<PageNumber onClick={() => handleArrowClick('next')}>
						<Icon name="rightArrow" color={theme.colors.gray3} />
					</PageNumber>
				</div>
			</PaginationWrapper>
		</div>
	)
}

export default Pagination

const PageNumber = styled.div`
	padding: 4px 12px;
	background: ${({ active }) => (active ? theme.colors.blue : '#fff')};
	color: ${({ active }) => (active ? '#fff' : theme.colors.primary)};
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background: ${({ active }) => (active ? '#22657B' : '#f6f6f6')};
	}
`

const PaginationWrapper = styled.div`
	background: white;
	padding: 4px;
	width: 100%;
	flex-wrap: wrap;
	flex-grow: 1;
`
