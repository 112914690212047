const sumInsuredOptions = [
	{
		icon: 'building',
		label: 'Building',
		labelExplanation: 'Do not include basement value here',
		inputName: 'BuildingValue',
	},
	{
		icon: 'building',
		label: 'Basement value',
		inputName: 'BasementValue',
	},
	{
		// SFSP
		icon: 'plinthAndFoundation',
		label: 'Plinth & foundation',
		inputName: 'PlinthFoundation',
	},
	{
		icon: 'boundaryWalls',
		label: 'Boundary walls (including gates etc.)',
		inputName: 'BoundaryWalls',
	},
	{
		icon: 'plantAndMachinery',
		label: 'Plant & Machinery',
		inputName: 'PlantMachinery',
	},
	{
		icon: 'furniture',
		label: 'Furniture & Fixture',
		inputName: 'FurnitureFixture',
	},
	{
		icon: 'electronicInstallations',
		label: 'Electronic Installations',
		inputName: 'Electronics',
	},
	{
		icon: 'officeEquipment',
		label: 'Office Equipment (including IT systems)',
		inputName: 'OfficeEquipment',
	},
	{
		icon: 'valuables',
		label: 'Valuable contents',
		inputName: 'ValuableContents',
	},
	{
		icon: 'otherValuables',
		label: 'Other contents',
		inputName: 'Other',
	},
]

const singleItemOps = {
	icon: 'singleItem',
	label: 'Maximum value of a single content item?',
	inputName: 'MaxValSingleItem',
}

const singleItemDefaults = {
	Name: 'MaxValSingleItem',
	Amount: '',
}

const sumInsuredDefaults = sumInsuredOptions.map(el => ({
	Name: el.inputName,
	Amount: '',
}))

const predefinedSumInsuredFields = [
	'BuildingValue',
	'BasementValue',
	'BoundaryWalls',
	'PlantMachinery',
	'FurnitureFixture',
	'OfficeEquipment',
	'Electronics',
	'ValuableContents',
	'Other',
	'MaxValSingleItem',
]

export {
	sumInsuredOptions,
	sumInsuredDefaults,
	singleItemOps,
	singleItemDefaults,
	predefinedSumInsuredFields,
}
