export const getFloaterPreFilled = RiskLocations => {
	// console.log('$$', RiskLocations)
	// getting max and total values
	let total = 0
	let maxOneLoc = 0

	const calcValOneLoc = detailsList => {
		let value = 0
		for (const item in detailsList) {
			value = value + detailsList[item]?.AmountClosed + detailsList[item]?.AmountOpen
		}
		total = total + parseFloat(value)
		if (maxOneLoc < value) {
			maxOneLoc = value
		}
	}

	const analyseLocation = locationData => {
		if (locationData?.levels) {
			for (const level in locationData?.levels) {
				if (locationData?.levels[level]?.Stock?.Present) {
					calcValOneLoc(locationData?.levels[level]?.Stock?.Details)
				}
			}
		} else if (locationData?.Stock?.Present) {
			calcValOneLoc(locationData?.Stock?.Details)
		}
	}

	for (const location in RiskLocations) {
		// RiskLocations is the array of RiskLocations
		if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
			analyseLocation(RiskLocations[location].property)
			analyseLocation(RiskLocations[location].basement)
		} else {
			analyseLocation(RiskLocations[location])
		}
	}

	return { total, maxOneLoc }
}

export const updateFloaterValues = RiskLocations => {
	let locationsToModify = []

	const updateValues = (detailsList, newDetailsList) => {
		for (const item in detailsList) {
			let stockTypeObj = {}
			let sum = detailsList[item]?.AmountClosed + detailsList[item]?.AmountOpen

			stockTypeObj.OpenPer = parseFloat(((detailsList[item]?.AmountOpen / sum) * 100).toFixed(2))
			stockTypeObj.ClosedPer = 100 - stockTypeObj.OpenPer

			newDetailsList.push({
				...detailsList[item],
				OpenPer: stockTypeObj.OpenPer,
				ClosedPer: stockTypeObj.ClosedPer,
				AmountOpen: '',
				AmountClosed: '',
			})
		}
	}

	const findStock = (locationData, propertyType, locationId) => {
		if (locationData?.levels) {
			for (const level in locationData?.levels) {
				if (locationData?.levels[level]?.Stock?.Present) {
					let newDetailsList = []
					updateValues(locationData?.levels[level]?.Stock?.Details, newDetailsList)
					locationsToModify.push({
						levelIndex: level,
						propertyType: propertyType,
						ID: locationId,
						Stock: {
							...locationData?.levels[level]?.Stock,
							Details: newDetailsList,
						},
					})
				}
			}
		} else if (locationData?.Stock?.Present) {
			let newDetailsList = []
			updateValues(locationData?.Stock?.Details, newDetailsList)
			locationsToModify.push({
				levelIndex: -1,
				propertyType: propertyType,
				ID: locationId,
				Stock: {
					...locationData?.Stock,
					Details: newDetailsList,
				},
			})
		}
	}

	for (const location in RiskLocations) {
		// RiskLocations is the array of RiskLocations
		if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
			findStock(RiskLocations[location]?.property, 'property', RiskLocations[location]?.ID)
			findStock(RiskLocations[location]?.basement, 'basement', RiskLocations[location]?.ID)
		} else {
			findStock(
				RiskLocations[location],
				RiskLocations[location]?.Type?.toLowerCase(),
				RiskLocations[location]?.ID
			)
		}
	}

	return locationsToModify
}
