import { Context } from '../../context/Provider'
import { useContext } from 'react'
import { useHistory } from 'react-router'

import { Icon, Text, Space, theme, MainContainer } from 'verak-ui'
import { useFetch } from '../../api/apihook'

const greenColor = {
	color: theme.colors.green,
}

const DeclarationBasisWarning = () => {
	let history = useHistory()
	const [state, dispatch] = useContext(Context)
	const fetch = useFetch()

	const proceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SET_STOCK_DISCLOSURE_METHOD',
				data: {
					StockDisclosureMethod: 'DECLARED_VALUE',
				},
			})
			resolve()
		})
		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push('/journey/risk-location-selection')
	}

	return (
		<MainContainer header="Stock details" top={5} rightOnClick={proceed} style={containerStyle}>
			<Icon name="thumbsUp" style={{ margin: '0 auto' }} />
			<Space y={1.5} />
			<Text type="primary">
				<span style={greenColor}>No further action is required</span>
				<br /> in making this switch from Declaration Basis to Declared Value.
			</Text>
			<Space y={1.5} />
			<Text type="primary">
				Please note that now the client will
				<br />{' '}
				<span style={greenColor}>
					not be required to disclose their stock value on a monthly basis.
				</span>
			</Text>
		</MainContainer>
	)
}

export default DeclarationBasisWarning

const containerStyle = {
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
}
