import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// https://stackoverflow.com/a/54343182/5149570
function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);