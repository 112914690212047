import { useState } from 'react'
import { Icon, Modal, Space, theme, Text, Button } from 'verak-ui'
import { useContextApi } from '../context/Provider'
import { areAllLocationsComplete } from '../utilities/locationStatus'
import { useHistory } from 'react-router-dom'
import { useFetch } from '../api/apihook'
import { isRfqEqual, editRfqAllowedStatuses } from '../helpers/editRfqUtils'

import AlertSiren from '../assets/icons/AlertSiren.svg'

const buttonStyle = {
	height: '48px',
	fontSize: '18px',
}

const MODAL_STATES = {
	WARNING: 'WARNING',
	CONFIRM: 'CONFIRM',
	RESULT: 'RESULT',
}

export const QuotesNotRequired = (
	<div className="d-flex flex-column align-items-center">
		<img src={AlertSiren} alt="" />
		<Text className="my-3 text-center" color={theme.colors.gray2} fontSize="24px" fontWeight="700">
			Changes have been submitted, <br /> new quotes are NOT required
		</Text>
	</div>
)

export const NewQuotesRequired = (
	<div className="d-flex flex-column">
		<Icon name="editPencilSquare" className="my-3 align-self-center" size="80px" />
		<Text color={theme.colors.primary} fontSize="24px" fontWeight="700">
			Changes made would result in:
		</Text>
		<ul>
			<Text color="#DC2F21" fontSize="24px" fontWeight="700">
				<li>OLD quotes being deleted</li>
			</Text>
			.
			<Text color="#57BA70" fontSize="24px" fontWeight="700">
				<li>NEW quotes will be requested for from the backend team!</li>
			</Text>
		</ul>
	</div>
)

const EditLostWarningModal = ({ showModal, onCloseClick }) => {
	const [state, dispatch] = useContextApi()
	const history = useHistory()
	const fetch = useFetch()
	const [areQuotesRequired, setAreQuotesRequired] = useState(null)
	const [modalSection, setModalSection] = useState(MODAL_STATES.WARNING)

	const riskLocations = state?.quote?.RiskLocations || []
	const allRLComplete = areAllLocationsComplete(riskLocations)

	const disableSave = !allRLComplete

	const titleText = (
		<Text type="h5" fontWeight="700" color={theme.colors.red}>
			NOTE
		</Text>
	)

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	const onProceedClick = async () => {
		// run checks if rfq is complete, the resubmit the rfq
		if (allRLComplete && state?.rfq?.editRfqMode) {
			await new Promise(resolve => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
				})
				resolve()
			})

			const validRFQStatus = editRfqAllowedStatuses.includes(rfqStatus)
			if (validRFQStatus && state?.rfq?.editRfqMode && !isRfqEqual(state?.quote, state?.oldRfq)) {
				const result = await fetch.resubmitRfqOnUpdate(state?.quote?.quoteId, state?.oldRfq)
				setAreQuotesRequired(result?.newQuotesRequired)
			}
			await new Promise(resolve => {
				dispatch({ type: 'CLEAR_OLD_RFQ' })
				dispatch({
					type: 'SET_EDIT_RFQ',
					data: { editRfqMode: false },
				})
				resolve()
			})
		}

		setModalSection(MODAL_STATES.RESULT)
	}

	let content
	switch (true) {
		case modalSection === MODAL_STATES.WARNING:
			content = (
				<>
					<Icon name="warningFrownFace" size="100px" style={{ margin: '0 auto' }} />
					<Space y={1} />
					<Text
						fontSize="36px"
						fontWeight="700"
						color={theme.colors.red}
						style={{ textAlign: 'center' }}
					>
						Read Carefully
					</Text>
					<Space y={1} />
					<Text
						fontSize="22px"
						fontWeight="700"
						color={theme.colors.black}
						style={{ opacity: 0.5, textAlign: 'center' }}
					>
						You are about to leave the Edit RFQ journey.
					</Text>
					<Space y={1} />
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column w-50 align-items-center">
							<Text
								color={theme.colors.black}
								className="text-center"
								fontSize="22px"
								fontWeight="700"
							>
								Continue with your edit <span style={{ opacity: 0.5 }}>changes</span>
							</Text>
							<Text fontSize="50px">↓</Text>
							<Button
								secondary
								label="Keep editing"
								style={buttonStyle}
								className="w-100"
								onClick={onCloseClick}
							/>
						</div>
						<Space x={1} />
						<div className="d-flex flex-column w-50 align-items-center">
							<Text
								color={theme.colors.black}
								className="text-center"
								fontSize="22px"
								fontWeight="700"
							>
								<span style={{ opacity: 0.5 }}>Request for</span> new quotes
							</Text>
							<Text fontSize="50px">↓</Text>
							<Button
								primary
								disabled={disableSave}
								label="Save & continue"
								style={buttonStyle}
								className="w-100"
								onClick={() => setModalSection(MODAL_STATES.CONFIRM)}
							/>
						</div>
					</div>
				</>
			)
			break
		case modalSection === MODAL_STATES.CONFIRM:
			content = (
				<div className="d-flex flex-column align-items-center">
					<img src={AlertSiren} alt="" />
					<Text fontSize="36px" fontWeight="700" color={theme.colors.red} className="my-2">
						Read Carefully
					</Text>

					<Text
						className="my-2"
						fontSize="22px"
						fontWeight="700"
						color={theme.colors.black}
						style={{ opacity: 0.5, textAlign: 'center' }}
					>
						Changes made may result in new quotes being added
					</Text>
					<Text
						fontSize="22px"
						fontWeight="700"
						color={theme.colors.gray2}
						style={{ textAlign: 'center' }}
						className="mb-3"
					>
						Click
						<span
							style={{
								color: theme.colors.primary,
								fontSize: '22px',
								fontWeight: '700',
								margin: 'auto 6px',
							}}
						>
							Proceed
						</span>
						to submit new changes
					</Text>

					<div className="d-flex align-items-center w-100">
						<Button
							secondary
							label="Cancel"
							style={buttonStyle}
							className="w-100"
							onClick={() => {
								setModalSection(MODAL_STATES.WARNING)
								onCloseClick()
							}}
						/>
						<Space x={1} />
						<Button
							primary
							label="Proceed"
							className="w-100"
							style={buttonStyle}
							onClick={onProceedClick}
						/>
					</div>
				</div>
			)
			break
		case modalSection === MODAL_STATES.RESULT && areQuotesRequired !== null:
			const resultContainer = areQuotesRequired ? NewQuotesRequired : QuotesNotRequired
			content = (
				<div className="d-flex flex-column align-items-center">
					{resultContainer}
					<Button
						primary
						label="Proceed"
						style={buttonStyle}
						className="w-100"
						onClick={() => {
							setModalSection(MODAL_STATES.WARNING)
							onCloseClick()
							history.push('/')
						}}
					/>
				</div>
			)
			break
		default:
	}

	return (
		<Modal
			id="progress-losing"
			title={titleText}
			closeButton={false}
			footer={<></>}
			show={showModal}
		>
			{content}
		</Modal>
	)
}

export default EditLostWarningModal
