import { useState, useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import {
	MainContainer,
	Text,
	Divider,
	theme,
	Input,
	Select,
	SearchableSelect,
	Icon,
	HiddenFileInput,
	Button,
	FileUploaded,
} from 'verak-ui'
import { useFetch } from '../../../../../api/apihook'
import calculateTotalPremium from '../../../../../utilities/calculateTotalPremium'
import { parseLinkFromSignedUrl } from '../../../../../utilities/imageUploadUtilities'
import { validateEmail } from '../../../../../utilities/validateEmail'
import { stateOps } from '../../../../PropertyRiskData/LocationDetails/components/options'

const CompulsoryStar = () => <span style={{ color: '#eb5757' }}>*</span>

const salutationsList = ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.', 'Shri.', 'Smt.']

const CustomUploadButton = ({ onClick = () => {}, disabled = false }) => (
	<Button
		secondary
		size="stretch"
		iconStyles={{ marginRight: '0.25rem' }}
		label={<Icon name="orangePlusIcon" />}
		onClick={onClick}
		style={{ backgroundColor: '#fff', border: '2px dashed #8D8D8D', width: '72px', height: '48px' }}
		disabled={disabled}
	/>
)

// these insurers have certain conditions on PAN / GST requirements based on the premium amount
const insurersWithPANGSTConditions = [
	'Royal Sundaram General Insurance',
	'Reliance General Insurance',
	'Liberty General Insurance',
	'Oriental Insurance',
	'ICICI Lombard General Insurance',
	'The New India Assurance Co. Ltd.',
	'National Insurance',
	'Kotak General Insurance',
	'Chola MS General Insurance',
	'Digit Insurance',
	'United India Insurance',
	'SBI General Insurance',
]

/**
 *
 * @param {Object} customerData
 * @returns [salutation, fullName]
 */
const parseFullName = customerData => {
	const { PersonalInfo = {} } = customerData
	const { Name: ContactName = '' } = PersonalInfo
	const [salutation, fullName] = ContactName.split('.')
	if (salutation && fullName) {
		return [`${salutation}.`, fullName]
	}
	return ['', ContactName]
}

const parsePhone = phone => {
	if (phone.startsWith('+91')) {
		return phone.slice(3)
	}
	return phone
}

const prefillAcountData = customerData => {
	const { Address = {}, Verification = {}, PersonalInfo = {} } = customerData
	const LegalEntityName = PersonalInfo?.LegalEntityName || ''
	return {
		Address,
		Verification,
		Name: LegalEntityName,
	}
}

const prefillContactData = customerData => {
	const { ContactInfo = {}, PersonalInfo = {} } = customerData
	const { Designation = '' } = PersonalInfo
	const { Phone = '', Email = '', Landline = '' } = ContactInfo

	return {
		Name: parseFullName(customerData)[1],
		Designation,
		Phone: parsePhone(Phone),
		Email,
		Landline,
	}
}

const ClientDetailForm = ({ rfqId, selectedQuote, customerData, proceedNextStep, onBackClick }) => {
	const fetch = useFetch()

	const [accountData, setAccountData] = useState(() => prefillAcountData(customerData))
	const [contactData, setContactData] = useState(() => prefillContactData(customerData))
	const [salutation, setSalutation] = useState(() => {
		const [salutation] = parseFullName(customerData)
		return salutation
	})
	const [pincodeDataFetched, setPincodeDataFetched] = useState({ city: false, state: false })
	const [previousPIN, setPreviousPIN] = useState(accountData?.Address?.PIN || '')

	const handleContactDataChange = (key, value) => {
		setContactData(prevState => ({ ...prevState, [key]: value }))
	}

	const handleAccountDataChange = (value, key, subKey = false) => {
		if (subKey) {
			setAccountData(prevState => ({
				...prevState,
				[key]: { ...prevState[key], [subKey]: value },
			}))
			return
		}
		setAccountData(prevState => ({ ...prevState, [key]: value }))
	}

	const handleVerificationDataChange = (value, key, subKey = false) => {
		if (subKey) {
			setAccountData(prevState => ({
				...prevState,
				Verification: {
					...prevState.Verification,
					[key]: { ...prevState.Verification[key], [subKey]: value },
				},
			}))
			return
		}
		setAccountData(prevState => ({
			...prevState,
			Verification: { ...prevState.Verification, [key]: value },
		}))
	}

	const fetchCityAndState = async PIN => {
		if (PIN) {
			setPincodeDataFetched({ city: false, state: false })
			const response = await fetch.getLocationsWithPIN(PIN)

			const results = response?.[0] || []
			const locations = results?.PostOffice || []

			if (locations?.length) {
				const { District: City, State } = locations?.[0] || {}
				setAccountData({
					...accountData,
					Address: { ...accountData.Address, City, State },
				})
				setPincodeDataFetched({ city: true, state: true })
				return { City, State }
			}
			return null
		}
	}

	const handlePINChange = PIN => {
		if (PIN.length === 6 && previousPIN !== PIN) {
			fetchCityAndState(PIN)
			setPreviousPIN(PIN)
		}
	}

	const PANCardUploadRef = useRef()
	const GSTUploadRef = useRef()

	const handleFileUpload = async (file, key) => {
		const signedUrl = await fetch.getSignedUrl(file.type, rfqId)
		const uploadResponse = await fetch.uploadBlobToSignedUrl(signedUrl, file)
		const s3Link = parseLinkFromSignedUrl(uploadResponse?.url)
		handleVerificationDataChange(s3Link, 'Images', key)
	}

	const totalPremium = calculateTotalPremium(selectedQuote)

	// check if GST and PAN are mandatory based on premium and company
	// all 4 are mandatory by default
	let isGSTNumMandatory = true
	let isGSTCertMandatory = true
	let isPANNumMandatory = true
	let isPANCertMandatory = true
	const insurerName = selectedQuote?.Insurer || ''

	if (insurersWithPANGSTConditions.includes(insurerName)) {
		isGSTCertMandatory = false
		isGSTNumMandatory = false

		if (totalPremium < 50000) {
			isPANCertMandatory = false
			isPANNumMandatory = false
		} else if (totalPremium >= 50000 && totalPremium < 100000) {
			// PAN Number will remain mandatory
			isPANNumMandatory = true
			isPANCertMandatory = false
		} else {
			// else PAN Certificate & PAN Num will remain mandatory
			isPANNumMandatory = true
			isPANCertMandatory = true
		}
	}

	const PANNumber = accountData?.Verification?.PAN
	const GSTNumber = accountData?.Verification?.GSTIN

	const errors = {
		Salutation: !salutation,
		ContactName: !contactData?.Name,
		LegalEntityName: !accountData?.Name,
		Phone: contactData?.Phone ? contactData?.Phone?.length !== 10 : false,
		Email: contactData?.Email ? !validateEmail(contactData?.Email) : false,
		AddressLine1: accountData?.Address?.Line1 ? !accountData?.Address?.Line1 : false,
		PIN: accountData?.Address?.PIN ? accountData?.Address?.PIN?.length !== 6 : false,
		City: accountData?.Address?.City ? !accountData?.Address?.City : false,
		State: accountData?.Address?.City && !accountData?.Address?.State,
		PAN:
			isPANNumMandatory || PANNumber
				? !Boolean(PANNumber?.match(/^[A-Z]{5}\d{4}[A-Z]{1}$/))
				: false,
		PANImage: isPANCertMandatory ? !accountData?.Verification?.Images?.PAN : false,
		GSTIN:
			isGSTNumMandatory || GSTNumber
				? !Boolean(GSTNumber.match(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/))
				: false,
		GSTINImage: isGSTCertMandatory ? !accountData?.Verification?.Images?.GST : false,
		Aadhar: accountData?.Verification?.Aadhar
			? !Boolean(accountData?.Verification?.Aadhar?.match(/^\d{12}$/))
			: false,
	}

	const compulsoryFieldsNotFilled = {
		Salutation: !!salutation && !errors.Salutation,
		ContactName: !!contactData?.Name && !errors.ContactName,
		LegalEntityName: !!accountData?.Name && !errors.LegalEntityName,
		Phone: contactData?.Phone && !errors.Phone,
		Email: !!contactData?.Email && !errors.Email,
		AddressLine1: !!accountData?.Address?.Line1 && !errors.AddressLine1,
		PIN: !!accountData?.Address?.PIN && !errors.PIN,
		City: !!accountData?.Address?.City && !errors.City,
		State: !!accountData?.Address?.City && !errors.State,
		PAN: !!isPANNumMandatory && !errors.PAN,
		PANImage: !!isPANCertMandatory && !errors.PANImage,
		GSTIN: !!isGSTNumMandatory && !errors.GSTIN,
		GSTINImage: !!isGSTCertMandatory && !errors.GSTINImage,
	}

	const checkProceedDisabled = () => {
		return !(
			!Object.values(errors).some(error => error) ||
			Object.values(compulsoryFieldsNotFilled).every(field => field)
		)
	}

	const proceedDisabled = checkProceedDisabled()

	const triggerPANCardUpload = () => PANCardUploadRef?.current?.click()
	const triggerGSTUpload = () => GSTUploadRef?.current?.click()

	const onFormSubmit = async () => {
		const payload = {
			account: {
				...accountData,
			},
			contact: {
				...contactData,
				Phone: `+91${contactData?.Phone}`,
				Name: `${salutation}${contactData?.Name}`,
			},
		}
		const result = await fetch.updateCustomerInfo(rfqId, payload)
		if (result) {
			proceedNextStep()
		}
	}

	return (
		<MainContainer
			header="Please enter client's details below to close the quote"
			hasLeftBtn={true}
			rightOnClick={onFormSubmit}
			leftOnClick={onBackClick}
			rightDisabled={proceedDisabled}
		>
			<Text
				color={theme.colors.gray7}
				fontSize="1.5rem"
				mobileFontSize="0.875rem"
				fontWeight="700"
				className="mb-3"
			>
				Personal information
			</Text>

			<InputLabel>
				Name of the Proposer
				<CompulsoryStar />
			</InputLabel>
			<Row>
				<Col xs={2}>
					<SelectInput
						placeholder="--Select--"
						value={salutation}
						onChange={val => setSalutation(val)}
						error={errors.Salutation}
					>
						<option key="default" disabled>
							Title
						</option>
						{salutationsList.map(value => {
							return <option key={value}>{value}</option>
						})}
					</SelectInput>
				</Col>
				<Col xs={10}>
					<InputField
						placeholder="Enter here"
						value={contactData?.Name}
						onChange={e => handleContactDataChange('Name', e.target.value)}
						error={errors?.ContactName}
						errorLabel={errors?.ContactName && 'Please enter a valid Full Name'}
					/>
				</Col>
			</Row>

			<Row className="mt-4 mb-3">
				<Col>
					<InputLabel>
						Legal Entity Name
						<CompulsoryStar />
					</InputLabel>
					<InputField
						placeholder="Enter here"
						value={accountData?.Name}
						onChange={e => handleContactDataChange(e.target.value, 'Name')}
						error={errors?.LegalEntityName}
						errorLabel={errors?.LegalEntityName && 'Please enter a valid Legal Entity Name'}
					/>
				</Col>
				<Col>
					<InputLabel>Designation</InputLabel>
					<InputField
						placeholder="Enter here"
						value={contactData?.Designation}
						onChange={e => handleContactDataChange('Designation', e.target.value)}
					/>
				</Col>
			</Row>

			<Divider top={1} bottom={1} />

			<Text
				color={theme.colors.gray7}
				fontSize="1.5rem"
				mobileFontSize="0.875rem"
				fontWeight="700"
				className="mb-3"
			>
				Contact Information
			</Text>

			<Row className="mb-3">
				<Col>
					<InputLabel>
						Mobile Number
						<CompulsoryStar />
					</InputLabel>
					<InputField
						placeholder="Enter here"
						value={contactData?.Phone}
						onChange={e => handleContactDataChange('Phone', e.target.value)}
						error={errors?.Phone}
						errorLabel={errors?.Phone && 'Please enter a valid Phone Number'}
					/>
				</Col>
				<Col>
					<InputLabel>Landline Number</InputLabel>
					<InputField
						placeholder="Enter here"
						value={contactData?.Landline}
						onChange={e => handleContactDataChange('Landline', e.target.value)}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col xs={6}>
					<InputLabel>Email Address</InputLabel>
					<InputField
						placeholder="Enter here"
						value={contactData?.Email}
						onChange={e => handleContactDataChange('Email', e.target.value)}
						error={errors?.Email}
						errorLabel={errors?.Email && 'Please enter a valid Email Address'}
					/>
				</Col>
			</Row>

			<Text
				color={theme.colors.gray7}
				fontSize="1.5rem"
				mobileFontSize="0.875rem"
				fontWeight="700"
				className="mb-3"
			>
				Address
			</Text>

			<Row className="mb-3 d-flex align-items-start">
				<Col>
					<InputLabel>
						Residential Address
						<CompulsoryStar />
					</InputLabel>
					<InputField
						placeholder={'Address First Line'}
						value={accountData?.Address?.Line1}
						onChange={e => handleAccountDataChange(e.target.value, 'Address', 'Line1')}
						error={errors?.AddressLine1}
						errorLabel={errors?.AddressLine1 && 'Please enter a valid Address Line 1'}
					/>
				</Col>
				<Col>
					<InputLabel>Address Line 2</InputLabel>
					<InputField
						placeholder={'Address Second Line'}
						value={accountData?.Address?.Line2}
						onChange={e => handleAccountDataChange(e.target.value, 'Address', 'Line2')}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col xs={6}>
					<InputLabel>
						PIN Code
						<CompulsoryStar />
					</InputLabel>
					<InputField
						maxLength={6}
						placeholder={'Enter Pincode Here'}
						value={accountData?.Address?.PIN}
						onBlur={e => handlePINChange(e.target.value)}
						onChange={e => handleAccountDataChange(e.target.value, 'Address', 'PIN')}
						error={errors?.PIN}
						errorLabel={errors?.PIN && 'Please enter a valid PIN Code'}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<InputLabel>City</InputLabel>
					<EditField
						showEditButton={pincodeDataFetched?.city}
						onEditClick={() =>
							setPincodeDataFetched({
								...pincodeDataFetched,
								city: false,
							})
						}
					>
						<InputField
							placeholder={'Your Residential City'}
							value={accountData?.Address?.City}
							onChange={e => handleAccountDataChange(e.target.value, 'Address', 'City')}
							disabled={pincodeDataFetched?.city}
							error={errors?.City}
							errorLabel={errors?.City && 'Please enter a valid City'}
						/>
					</EditField>
					{pincodeDataFetched?.city && (
						<Text className="mt-2" fontWeight="300" fontSize="14px" color={theme.colors.green}>
							City autofilled from PIN Code
						</Text>
					)}
				</Col>
				<Col>
					<InputLabel>State</InputLabel>
					<EditField
						showEditButton={pincodeDataFetched?.state}
						onEditClick={() =>
							setPincodeDataFetched({
								...pincodeDataFetched,
								state: false,
							})
						}
					>
						<SearchableSelect
							search
							value={accountData?.Address?.State}
							onChange={val => handleAccountDataChange(val, 'Address', 'State')}
							options={stateOps}
							placeholder="Select State"
							disabled={pincodeDataFetched?.state}
							error={errors?.State}
						>
							<option>Select State</option>
						</SearchableSelect>
					</EditField>
					{(pincodeDataFetched?.state || errors?.State) && (
						<Text
							className="mt-2"
							fontWeight="300"
							fontSize="14px"
							color={errors?.State ? theme.colors.red : theme.colors.green}
						>
							{errors?.State ? 'Please enter a valid state' : 'State autofilled from PIN Code'}
						</Text>
					)}
				</Col>
			</Row>

			<Divider top={1} bottom={1} />

			<Text
				color={theme.colors.gray7}
				fontSize="1.5rem"
				mobileFontSize="0.875rem"
				fontWeight="700"
				className="mb-3"
			>
				Legal Information
			</Text>

			<Row className="mb-3">
				<Col>
					<InputLabel>
						Enter your PAN card number
						{isPANNumMandatory && <CompulsoryStar />}
					</InputLabel>
					<InputField
						placeholder="eg. AAA1929B91IS"
						value={accountData?.Verification?.PAN}
						onChange={e => handleVerificationDataChange(e.target.value, 'PAN')}
						error={errors?.PAN}
						errorLabel={errors?.PAN && 'Please enter a valid PAN number'}
					/>
				</Col>
				<Col>
					<InputLabel>
						Upload your PAN card front (image/pdf) here
						{isPANCertMandatory && <CompulsoryStar />}
					</InputLabel>
					<HiddenFileInput
						accept="image/jpeg,application/pdf"
						ref={PANCardUploadRef}
						onChange={e => handleFileUpload(e.target.files[0], 'PAN')}
					/>
					<div className="d-flex align-items-center">
						{!accountData?.Verification?.Images?.PAN ? (
							<CustomUploadButton
								onClick={triggerPANCardUpload}
								disabled={false}
								applyDisableFilter={false}
							/>
						) : (
							<FileUploaded
								className="mt-1"
								imageUrl={accountData?.Verification?.Images?.PAN}
								onDelete={() => {
									handleVerificationDataChange('', 'Images', 'PAN')
								}}
							/>
						)}
					</div>
					{errors?.PANImage && (
						<Text color={theme.colors.red} fontSize="0.875rem">
							Please upload a valid PAN card
						</Text>
					)}
				</Col>
			</Row>
			<Row className="mb-3 d-flex justify-content-end">
				<Col xs={6}>
					<InputLabel>Enter password, if the uploaded file is password protected</InputLabel>
					<InputField
						placeholder="Enter here"
						value={accountData?.Verification?.Passwords?.PAN}
						onChange={e => handleVerificationDataChange(e.target.value, 'Passwords', 'PAN')}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<InputLabel>
						Enter GST number
						{isGSTNumMandatory && <CompulsoryStar />}
					</InputLabel>
					<InputField
						placeholder="eg. 38208HYUBA1212"
						maxLength={15}
						value={accountData?.Verification?.GSTIN}
						onChange={e => {
							handleVerificationDataChange(e.target.value, 'GSTIN')
						}}
						error={errors?.GSTIN}
						errorLabel={errors?.GSTIN && 'Please enter a valid GST number'}
					/>
				</Col>
				<Col>
					<InputLabel>
						Upload your GST certificate (image/pdf) here
						{isGSTCertMandatory && <CompulsoryStar />}
					</InputLabel>
					<HiddenFileInput
						accept="image/jpeg,application/pdf"
						ref={GSTUploadRef}
						onChange={e => handleFileUpload(e.target.files[0], 'GSTIN')}
					/>
					<div className="d-flex align-items-center">
						{!accountData?.Verification?.Images?.GSTIN ? (
							<CustomUploadButton
								onClick={triggerGSTUpload}
								disabled={false}
								applyDisableFilter={false}
							/>
						) : (
							<FileUploaded
								className="mt-1"
								imageUrl={accountData?.Verification?.Images?.GSTIN}
								onDelete={() => {
									handleVerificationDataChange('', 'Images', 'GSTIN')
								}}
							/>
						)}
					</div>
					{errors?.GSTINImage && (
						<Text color={theme.colors.red} fontSize="0.875rem">
							Please upload a valid GST certificate
						</Text>
					)}
				</Col>
			</Row>
			<Row className="mb-3 d-flex justify-content-end">
				<Col xs={6}>
					<InputLabel>Enter password, if the uploaded file is password protected</InputLabel>
					<InputField
						placeholder="Enter here"
						value={accountData?.Verification?.Passwords?.GSTIN}
						onChange={e => handleVerificationDataChange(e.target.value, 'Passwords', 'GSTIN')}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col xs={6}>
					<InputLabel>Please enter your Aadhar number</InputLabel>
					<InputField
						placeholder="eg. 123456789123"
						maxLength="12"
						value={accountData?.Verification?.Aadhar}
						onChange={e => handleVerificationDataChange(e.target.value, 'Aadhar')}
						error={errors?.Aadhar}
						errorLabel={errors?.Aadhar && 'Please enter a valid Aadhar number'}
					/>
				</Col>
			</Row>
		</MainContainer>
	)
}

const EditField = ({ onEditClick, showEditButton = false, editBtnStyle = {}, children }) => {
	if (showEditButton) {
		return (
			<div className="w-100 position-relative">
				{children}
				<Button
					className="position-absolute end-0 me-2 mb-1 p-0"
					onClick={onEditClick}
					icon="edit"
					bgColor="rgba(0,0,0,0.0)"
					style={{ zIndex: '1000', top: '.25rem', ...editBtnStyle }}
				/>
			</div>
		)
	}
	return children
}

export default ClientDetailForm

const InputLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: 500;
	margin-bottom: 0.5rem;
`

const InputField = styled(Input)`
	height: 48px;
	width: 100%;
`

const SelectInput = styled(Select)`
	width: 100%;
	height: 48px;
`
