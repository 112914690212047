import { useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { Button, Icon, Input, Text, MainContainer } from 'verak-ui'
import { Context } from '../../../context/Provider'
import { useFetch } from '../../../api/apihook'

const TitleText = styled(Text)`
	font-size: 24px;
	font-weight: 700;
	text-align: center;
`

function prefillDewatering(globalstore) {
	if (globalstore.basement) {
		return globalstore.basement.FireRisk || {}
	}
	return globalstore.FireRisk || {}
}

const DeWatering = ({ onInputComplete }) => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)

	const prefillData = prefillDewatering(state.location || {})

	const [hasDewatering, setHasDewatering] = useState(Boolean(prefillData.HasDeWatering || ''))
	const [deWateringDesc, setDeWateringDesc] = useState(prefillData.DeWateringDesc || '')

	const proceedDisabled = !hasDewatering || (hasDewatering && deWateringDesc.length === 0)

	const history = useHistory()
	const location = useLocation()

	const returnPath = location?.state?.returnPath || ''

	const proceed = async (desc, value) => {
		const { Type } = state?.location || {}
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'

		const data = {
			FireRisk: {
				...prefillData,
				HasDeWatering: Boolean(value),
				DeWateringDesc: desc,
			},
		}

		if (isPropertyBasement) {
			const basementGlobalData = state.location?.basement?.FireRisk || {}
			const propertyGlobalData = state.location?.property?.FireRisk || {}

			const propertyDataToSend = {
				FireRisk: {
					...propertyGlobalData,
					HasDeWatering: Boolean(value),
					DeWateringDesc: desc,
				},
			}
			const basementDataToSend = {
				FireRisk: {
					...basementGlobalData,
					HasDeWatering: Boolean(value),
					DeWateringDesc: desc,
				},
			}

			await new Promise(resolve => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: 'basement',
					levelIndex: -1,
					data: basementDataToSend,
				})
				resolve()
			})

			await new Promise(resolve => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: 'property',
					levelIndex: -1,
					data: propertyDataToSend,
				})
				resolve()
			})
		} else {
			await new Promise(resolve => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: 'basement',
					levelIndex: -1,
					data: data,
				})
				resolve()
			})
		}

		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()
		onInputComplete()
	}

	const noDewatering = async () => {
		setDeWateringDesc('')
		setHasDewatering(false)
		proceed('', false)
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/materials')
		}
	}

	return (
		<MainContainer
			header="STFI Risk Assessment"
			rightOnClick={() => proceed(deWateringDesc, hasDewatering)}
			leftOnClick={goBack}
			rightDisabled={proceedDisabled}
			rightLabel="Save & Continue"
		>
			<div className="d-flex justify-content-center mt-5 mb-4">
				<Icon name="dewatering" />
			</div>
			<TitleText>Does this basement have any de-watering facilities?</TitleText>
			<div className="d-flex justify-content-center mt-4 mb-4">
				<Button
					onClick={noDewatering}
					label="No"
					secondary={true}
					icon="no"
					className="me-3 pe-5 ps-5"
					iconStyles={{ marginRight: '1rem' }}
				/>
				<Button
					onClick={() => setHasDewatering(true)}
					label="Yes"
					icon="yes"
					className="pe-5 ps-5"
					iconStyles={{ marginRight: '1rem' }}
				/>
			</div>
			{hasDewatering && (
				<div className="d-flex justify-content-center mb-5">
					<div>
						<Text className="mb-2">Please specify what facilities are in place</Text>
						<Input
							value={deWateringDesc}
							onChange={e => setDeWateringDesc(e.target.value)}
							placeholder="Enter here"
						/>
					</div>
				</div>
			)}
		</MainContainer>
	)
}

export default DeWatering
