export const getStockLocation = RiskLocations => {
	let locAddress = null

	const findStock = (details, propertyType, locationId) => {
		if (details?.levels) {
			for (const level in details?.levels) {
				if (details?.levels[level]?.Stock?.Present) {
                    locAddress = { levelIndex: level, propertyType: propertyType, ID: locationId }
				}
			}
		} else if (details?.Stock?.Present) {
            locAddress = { levelIndex: -1, propertyType: propertyType, ID: locationId }
		}
	}

	for (const location in RiskLocations) {
		if (RiskLocations[location].Type === 'PROPERTYBASEMENT') {
			findStock(RiskLocations[location].property, 'property', RiskLocations[location].ID)
            findStock(RiskLocations[location].basement, 'basement', RiskLocations[location].ID)
		} else {
            findStock(RiskLocations[location], RiskLocations[location].Type.toLowerCase(), RiskLocations[location].ID)
		}
	}
    return locAddress
}
