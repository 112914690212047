import { useContext, useEffect, useState } from 'react'
import { Row, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Text, Button, Space, Select, theme, Divider, Alert } from 'verak-ui'

import { renderLevels, InputGroup } from '../commonUtils/commonExports'

import { Context } from '../../../context/Provider'
import { nanoid } from 'nanoid'

import { isValidOccupancy } from '../commonUtils/SharedUtils'
import PrimaryOccupancySelector from '../../DraftRFQ/components/PrimaryOccupancySelector'

const MobileHeaderWrapper = styled.div`
	@media (min-width: 768px) {
		display: none !important;
	}
	@media (max-width: 768px) {
		display: flex;
	}
`
const CustomRow = styled(Row)`
	margin: 1rem 0;

	@media (max-width: 768px) {
		background: ${theme.colors.secondary3};
		padding: 10px;
		border-radius: 10px;
	}
`

const CustomSelectMultipleBasement = styled(Select)`
	width: 210px;
	height: 48px;

	@media (max-width: 768px) {
		width: 100%;
		background: white !important;
	}
`

const MobileHeader = ({ text, removeCallback }) => {
	return (
		<>
			<MobileHeaderWrapper className="d-flex justify-content-between">
				<Text mobileFontSize="14px" color={theme.colors.secondary}>
					{text}
				</Text>

				<Button
					icon="remove"
					label=""
					onClick={removeCallback}
					style={{
						borderRadius: '50%',
						height: 'auto',
						margin: '0',
						padding: '0',
					}}
				/>
			</MobileHeaderWrapper>
			<Space y={0.7} />
		</>
	)
}

const FloorRow = ({
	Level,
	Occupancy,
	rowNum,
	index,
	excludeLevels,
	handleChange,
	removeLevel,
}) => {
	const showLabel = index === 0

	const triggerInputSave = (occupancyString = Occupancy) => {
		handleChange(rowNum, 'Occupancy', occupancyString)
	}

	return (
		<CustomRow>
			<MobileHeader removeCallback={() => removeLevel(rowNum)} text={`NO (${index + 1})`} />

			<div className="row w-100 p-0 m-0">
				<div className="col-md-1">
					<InputGroup className="srno" label="Sr. no." mobile={false} showLabel={showLabel}>
						<Text fontSize="18px">{index + 1}.</Text>
					</InputGroup>
				</div>
				<div className="col-md-3">
					<InputGroup label="Floor no." showLabel={showLabel}>
						<CustomSelectMultipleBasement
							value={Level}
							onChange={selected => handleChange(rowNum, 'Level', selected)}
							placeholder="0 to 100"
						>
							{renderLevels('PROPERTY', false, excludeLevels)}
						</CustomSelectMultipleBasement>
					</InputGroup>
				</div>

				<PrimaryOccupancySelector
					value={Occupancy}
					isRiskLocationDetailsScreen
					riskLocationType="multiple"
					showHeaderLabel={showLabel}
					onChange={(key, value) => {
						triggerInputSave(value.SubCategory)
					}}
				/>

				<div className="col-md-1 d-flex flex-column align-items-start">
					<RemoveButton
						icon="remove"
						label=""
						onClick={() => {
							removeLevel(rowNum)
						}}
						style={{
							borderRadius: '50%',
							height: 'auto',
							marginTop: showLabel ? '5rem' : '0',
							padding: '0',
						}}
					/>
				</div>
			</div>
		</CustomRow>
	)
}

const MultipleProperty = ({ onPropertyLevelSingle, onInputComplete, onDataComplete }) => {
	const [state] = useContext(Context)

	const { Type } = state.location || {}
	const { levels } = (Type === 'PROPERTYBASEMENT' ? state.location?.property : state.location) || {}

	const levelObj = {
		Level: '',
		Occupancy: '',
	}

	const initLevels = [
		{ ...levelObj, rowNum: 0 },
		{ ...levelObj, rowNum: 1 },
	]

	const initPropertyLevels = () => {
		if (levels?.length) {
			return levels.map((el, idx) => ({
				Level: el.Level,
				Occupancy: el.PrimaryOccupancy?.SubCategory || '',
				rowNum: idx,
			}))
		}
		return initLevels
	}

	const [propertyLevels, setPropertyLevels] = useState([...initPropertyLevels()])

	useEffect(() => {
		calculateInputValidation(propertyLevels)
	}, [propertyLevels.length]) // eslint-disable-line

	const calculateInputValidation = levels => {
		let allInputAvailable = true
		levels.forEach(item => {
			if (allInputAvailable) {
				allInputAvailable = !!item.Level && !!isValidOccupancy(item.Occupancy)
			}
		})
		onInputComplete(allInputAvailable)

		if (allInputAvailable) {
			const formattedData = propertyLevels.map(item => {
				return {
					Level: item.Level,
					ID: nanoid(),
					PrimaryOccupancy: {
						SubCategory: item.Occupancy,
					},
				}
			})

			onDataComplete({
				levels: formattedData,
			})
		}
	}

	const addAnotherLevel = () => {
		setPropertyLevels([...propertyLevels, { ...levelObj, rowNum: propertyLevels.length }])
	}

	const removeLevel = removeIdx => {
		const updatedLevel = [...propertyLevels.filter(({ rowNum }) => rowNum !== removeIdx)]
		setPropertyLevels(updatedLevel)

		// check if new property levels is only one
		if (updatedLevel.length === 1) {
			// change type from multiple to single
			onPropertyLevelSingle()
		}
	}

	const handleChange = (rowNum, key, value) => {
		const propertyCopy = [...propertyLevels]

		for (let levelObj of propertyCopy) {
			if (levelObj.rowNum === rowNum) {
				levelObj[key] = value
				break
			}
		}

		calculateInputValidation(propertyCopy)
		setPropertyLevels(propertyCopy)
	}

	let floorItems = []

	if (propertyLevels.length > 0) {
		floorItems = propertyLevels.map((item, index, list) => {
			const { Level, Occupancy, rowNum } = item
			let totalLevels = []
			list.forEach(fItem => {
				if (fItem.Level && fItem.Level !== Level) {
					totalLevels.push(fItem.Level)
				}
			})

			return (
				<FloorRow
					Level={Level}
					Occupancy={Occupancy}
					rowNum={rowNum}
					index={index}
					key={index}
					excludeLevels={totalLevels}
					handleChange={handleChange}
					removeLevel={removeLevel}
				/>
			)
		})
	}

	return (
		<>
			<Divider top="2rem" bottom="2rem" />
			<Text style={{ fontWeight: 700, fontSize: '24px' }} mobileFontSize="14px">
				Please select the primary use of each floor
				<span style={{ color: theme.colors.red }}>*</span>
			</Text>

			<Container>
				{/* floor row component */}
				{floorItems}

				<Button
					bgColor="#fff"
					icon="add"
					iconStyles={{
						marginRight: '0.5rem',
					}}
					label="Add another level"
					onClick={addAnotherLevel}
					style={{
						padding: '0',
					}}
					textColor="#02475E"
				/>
			</Container>

			<Space y={1} />
			<Container>
				<Alert
					bgColor={theme.colors.secondaryLightRed}
					iconName="infoIconOctagon"
					infoIconColor={theme.colors.red}
					titleColor={theme.colors.red}
					title="Note"
				>
					<Text
						color={theme.colors.red}
						style={{
							fontWeight: 'bold',
						}}
					>
						IF the client uses this floor for multiple purposes - please ONLY enter the primary use
						above.
						<br />
						You will be asked about “other usage” later in the journey - which is where the client
						can disclose other activities.
					</Text>
				</Alert>
			</Container>
		</>
	)
}

const RemoveButton = styled(Button)`
	@media (max-width: 768px) {
		display: none;
	}
`

export default MultipleProperty
