import { nanoid } from 'nanoid'
import { useContext } from 'react'
import { useState } from 'react'

import { Space, Text, Radio } from 'verak-ui'
import { Context } from '../../../../context/Provider'
import { filterArrObj, flattenArrObj } from '../../../../helpers/arrayOps'

import { coOwnwerDefaults } from './options'
import { RenderRemoveButton, SpecifyMoreDetails } from './SharedComponents'
import { CustomInput, GridContainer, RadioDiv, SharedRadioStyles } from './SharedStyles'

function initOwnershipData(state, levelIdx, propertyType) {
	const { Type, levels, CoOwners, Ownership } = state.location || {}

	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let OwnerShipData = Ownership,
		CoOwenersData = CoOwners,
		levelsData = levels

	if (isPropertyBasement) {
		OwnerShipData = state.location?.[propertyType]?.Ownership || ''
		CoOwenersData = state.location?.[propertyType]?.CoOwners || ['']
		levelsData = state.location?.[propertyType]?.levels || []
	}
	const isMultiple = levelsData?.length
	if (isMultiple) {
		OwnerShipData = levelsData[levelIdx]?.Ownership || ''
		CoOwenersData = levelsData[levelIdx]?.CoOwners || []
	}

	return {
		ownershipStatus: OwnerShipData || '',
		coOwnerData: CoOwenersData?.length
			? CoOwenersData?.map(el => ({ name: el, id: nanoid() }))
			: [{ ...coOwnwerDefaults }],
	}
}

const OwnershipStatus = ({ onSave, levelIdx, propertyType }) => {
	const [state] = useContext(Context)
	const { ownershipStatus, coOwnerData } = initOwnershipData(state, levelIdx, propertyType)

	const [ownership, setOwnership] = useState(ownershipStatus)

	const isOwner = ownership === 'SOLE'
	const isCoOwner = ownership === 'CO-OWNED'
	const isRented = ownership === 'RENTED'

	const [coOwners, setCoOwners] = useState(coOwnerData)

	const addNewCoOwner = () => {
		setCoOwners([...coOwners, { ...coOwnwerDefaults, id: nanoid() }])
	}

	const removeCoOwner = id => {
		const list = coOwners.filter(el => el.id !== id)
		setCoOwners(list)
		triggerSave(ownership, list)
	}

	const setCoOwnerItem = (id, name, value) => {
		// console.log(coOwners)
		const alteredItems = coOwners.map(el => {
			return el.id === id ? { ...el, [name]: value } : el
		})
		// console.log(alteredItems)
		setCoOwners(alteredItems)
		triggerSave(ownership, alteredItems)
	}

	const triggerSave = (val, list) => {
		onSave({
			Ownership: val,
			CoOwners: isCoOwner ? flattenArrObj(filterArrObj(list, ['id']), 'name') : [],
		})
	}

	const renderCoOwnerSections = coOwners.map(el => (
		<GridContainer
			key={el.id}
			columns={`1fr ${coOwners.length > 1 ? '0.1fr' : ''}`}
			className="mt-3"
		>
			<CustomInput
				type="text"
				label=""
				placeholder="Enter party name here"
				value={el.name || ''}
				onChange={e => setCoOwnerItem(el.id, 'name', e.target.value)}
			/>
			<RenderRemoveButton onClick={() => removeCoOwner(el.id)} renderWhen={coOwners.length > 1} />
		</GridContainer>
	))

	const coOwnerOptions = isCoOwner ? (
		<SpecifyMoreDetails
			title="Please specify details of the co-owners below:"
			renderDetailItems={renderCoOwnerSections}
			addNewDetailItem={addNewCoOwner}
			addItemButtonLabel="Add another party"
		/>
	) : null

	const onSelect = val => {
		setOwnership(val)
		triggerSave(val, [])
	}

	return (
		<>
			<Text type="body1" fontWeight={500}>
				Does the client own or rent this {propertyType}?
			</Text>
			<RadioDiv className="d-flex mt-2">
				<Radio
					labelStyle={SharedRadioStyles}
					value={isOwner}
					onChange={() => onSelect('SOLE')}
					text="Sole ownership"
					name={`${propertyType}-${levelIdx}-ownership-yes`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={isCoOwner}
					onChange={() => onSelect('CO-OWNED')}
					text="Co-Ownership"
					name={`${propertyType}-${levelIdx}-ownership-no`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={isRented}
					onChange={() => onSelect('RENTED')}
					text="Rented"
					name="ownership"
				/>
			</RadioDiv>
			{coOwnerOptions}
		</>
	)
}

export default OwnershipStatus
