import { useHistory } from 'react-router'
import { theme } from 'verak-ui'

import { RfqEmailSent } from '../../ConfirmationScreens/ReviewRFQ'

const RFQUpdateSuccess = () => {
	const history = useHistory()

	const onProceed = async () => {
		history.push('/quotes-in-progress')
	}

	return (
		<RfqEmailSent
			title={
				<>
					Updated RFQ details have been{' '}
					<span style={{ color: theme.colors.green }}>collected successfully</span>
				</>
			}
			onProceed={onProceed}
		/>
	)
}

export default RFQUpdateSuccess
