import { useHistory, useLocation } from 'react-router'
import { useState, useRef, useContext, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import { useFetch } from '../../../api/apihook'
import { Context } from '../../../context/Provider'
import { useLoadingOverlay } from '../../../components/LoadingOverlay'
import {
	parseLinkFromSignedUrl,
	imageDeleteConfirmOptions,
} from '../../../utilities/imageUploadUtilities'
import { convertToNumber } from '../../../utilities/convertToNumber'
import { PlinthFoundationModal } from '../../SFSP/SFSPEdgeCase/Modals'
import {
	isBuildingHeightInFloors,
	checkIfValueExists,
} from '../../../utilities/isBuildingHeightInFloors'

import {
	MainContainer,
	Divider,
	FileUploader,
	FileUploaded,
	HiddenFileInput,
	Tooltip,
	Icon,
	Space,
	Text,
	theme,
	Radio,
	Input,
	Tabbar,
	Modal,
  Checkbox,
} from 'verak-ui'
import { headerStyle } from '../../../components/SharedComponents'

const TitleText = styled(Text)`
	font-weight: 700;
	font-size: 24px;
	color: ${theme.colors.gray7};
`

const MaterialsDetails = ({ isSFSPEdgeCase }) => {
	let fetch = useFetch()
	let history = useHistory()
	let location = useLocation()

	const returnPath = location?.state?.returnPath || ''

	const [state, dispatch] = useContext(Context)
	const Type = state?.location?.Type

	// check for "Property" subtype == "Whole" and if "Whole" the no of floors present
	let isWholeBuilding = false
	let noOfFloors = null
	if (Type === 'PROPERTYBASEMENT') {
		if (state?.location?.property?.SubType === 'Whole') {
			isWholeBuilding = true

			// get no of levels if "Property" is a whole building
			noOfFloors = state?.location?.property?.Level
		}
	} else {
		if (state?.location?.SubType === 'Whole') {
			isWholeBuilding = true

			// get no of levels if "Property" is a whole building
			noOfFloors = state?.location?.Level
		}
	}

	const [rightDisabled, setRightDisabled] = useState(true)
	const [activeTab, setActiveTab] = useState('property')
	const [propertyData, setPropertyData] = useState({})
	const [basementData, setBasementData] = useState({})
	const [buildingHeight, setBuildingHeight] = useState()
	const [isHeightInFloors, setHeightType] = useState(
		isBuildingHeightInFloors(Type, state?.location)
	)

	const changeBuildingHeightType = () => {
		setHeightType(prevState => !prevState)
	}

	// plinth and foundation modal -> SFSP edge case management
	const [plintFoundationModal, setPlinthFoundationModal] = useState(false)
	const proceedForNo = async () => {
		await new Promise(resolve => {
			// creates a new variable in the schema which handles this since its a non compulsory
			// question, Backend discards it thus causes no real harm
			dispatch({ type: 'SET_EDGE_CASE_ANSWERED', data: { Answered: true } })

			resolve()
		})
		history.push('/sfsp-edgecase/risk-location-selection')
	}
	const proceedForYes = async () => {
		await new Promise(resolve => {
			dispatch({ type: 'SET_EDGE_CASE_ANSWERED', data: { Answered: true } })

			resolve()
		})
		history.push({
			pathname: '/sfsp-edgecase/sum-insured',
			state: {
				isSFSPEdgeCase: true,
			},
		})
	}

	// Prefilling Data
	const prefillMaterialDetails = currentTab => {
		if (Type === 'PROPERTYBASEMENT') {
			if (currentTab === 'property') {
				return state?.location?.property?.Materials || {}
			} else {
				return state?.location?.basement?.Materials || {}
			}
		} else {
			return state?.location?.Materials || {}
		}
	}
	const prefillBuildingHeight = currentTab => {
		if (Type === 'PROPERTYBASEMENT') {
			if (currentTab === 'property') {
				let heightInFloors = state?.location?.property?.Floors
				if (checkIfValueExists(heightInFloors)) {
					return heightInFloors
				} else {
					return state?.location?.property?.BuildingHeight || ''
				}
			}
		} else {
			let heightInFloors = state?.location?.Floors
			if (checkIfValueExists(heightInFloors)) {
				return heightInFloors
			} else {
				return state?.location?.BuildingHeight || ''
			}
		}
	}

	// Getting data from child components
	const handlePropertyData = (materialsData, buildingHeight) => {
		setPropertyData(materialsData)
		setBuildingHeight(buildingHeight)
	}
	const handleBasementData = materialsData => {
		setBasementData(materialsData)
	}

	const propertyEle = (
		<PropertyUI
			defaultBuildingHeight={prefillBuildingHeight('property')}
			prefilledData={prefillMaterialDetails('property')}
			handlePropertyData={handlePropertyData}
			isHeightInFloors={isHeightInFloors}
			changeBuildingHeightType={changeBuildingHeightType}
			isWholeBuilding={isWholeBuilding}
			noOfFloors={noOfFloors}
		/>
	)
	const basementEle = (
		<BasementUI
			prefilledData={prefillMaterialDetails('basement')}
			handleBasementData={handleBasementData}
		/>
	)

	const dispatchBuildingHeight = async buildingHeight => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					BuildingHeight: buildingHeight,
				},
			})
			resolve()
		})
	}
	const dispatchFloors = async buildingHeight => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					Floors: buildingHeight,
				},
			})
			resolve()
		})
	}

	// dispatch fns for storing data
	let dispatchPropertyData = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'property',
				levelIndex: -1,
				data: {
					Materials: propertyData,
				},
			})
			resolve()
		})
		if (isHeightInFloors) {
			if (isWholeBuilding) {
				// handles the case of whole building - where data was already entered
				await dispatchFloors(parseInt(noOfFloors)) // string isn't accepted by the backend
			} else {
				await dispatchFloors(buildingHeight)
			}
			await dispatchBuildingHeight('')
		} else {
			await dispatchBuildingHeight(buildingHeight)
			await dispatchFloors('')
		}
	}
	let dispatchBasementData = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'UPDATE_RISK_LOCATION_DETAILS',
				propertyType: 'basement',
				levelIndex: -1,
				data: {
					Materials: basementData,
				},
			})
			resolve()
		})
	}

	// tab related code
	const tabList = [
		{
			id: 'property',
			title: 'Building',
			content: propertyEle,
		},
		{
			id: 'basement',
			title: 'Basement',
			content: basementEle,
		},
	]
	const changeTab = () => {
		if (activeTab === 'property') {
			dispatchPropertyData()
			setActiveTab('basement')
		} else {
			dispatchBasementData()
			setActiveTab('property')
		}
	}

	const saveAndProceed = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else if (isSFSPEdgeCase) {
			// trigger popup for Plinth & Foundation
			setPlinthFoundationModal(true)
		} else {
			history.push('/property-risk/fire-system')
		}
	}

	// checks if "Type" and "Description" are both present
	const areValuesPresent = data => {
		return data?.Type && data?.Description
	}
	// checks if data is complete
	const isPropertyDataComplete = () => {
		return (
			areValuesPresent(propertyData['Roof']) &&
			areValuesPresent(propertyData['Walls']) &&
			areValuesPresent(propertyData['Floors'])
		)
	}
	const isBasementDataComplete = () => {
		return (
			areValuesPresent(basementData['Ceiling']) &&
			areValuesPresent(basementData['Walls']) &&
			areValuesPresent(basementData['Floors'])
		)
	}

	const proceed = async () => {
		if (Type === 'PROPERTYBASEMENT') {
			if (propertyData) {
				await dispatchPropertyData()
			}
			if (basementData) {
				await dispatchBasementData()
			}
			// the final history.push always takes place from the basements tab
			if (activeTab === 'property') {
				setActiveTab('basement')
			} else if (activeTab === 'basement') {
				if (isPropertyDataComplete()) {
					saveAndProceed()
				} else {
					setActiveTab('property')
				}
			}
		} else {
			if (Type === 'PROPERTY') {
				await dispatchPropertyData()
			} else if (Type === 'BASEMENT') {
				await dispatchBasementData()
			}
			saveAndProceed()
		}
	}

	// disabling of the proceed btn
	useEffect(() => {
		if (Type === 'PROPERTYBASEMENT') {
			if (
				(activeTab === 'property' && isPropertyDataComplete()) ||
				(activeTab === 'basement' && isBasementDataComplete())
			) {
				setRightDisabled(false)
			} else {
				setRightDisabled(true)
			}
		} else {
			if (isPropertyDataComplete() || isBasementDataComplete()) {
				setRightDisabled(false)
			}
		}
	}, [activeTab, propertyData, basementData, buildingHeight])

	let content = null
	if (Type === 'PROPERTYBASEMENT') {
		content = (
			<Tabbar className="mt-3" tabList={tabList} activeTabId={activeTab} onChange={changeTab} />
		)
	} else if (Type === 'PROPERTY') {
		content = propertyEle
	} else if (Type === 'BASEMENT') {
		content = basementEle
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/theft-risk')
		}
	}

	return (
		<MainContainer
			header="Construction details"
			rightOnClick={proceed}
			rightDisabled={rightDisabled}
			leftOnClick={goBack}
		>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<TitleText className="mb-2">Please enter some further details:</TitleText>
			<Divider top={1.5} bottom={1.5} />
			{content}

			<Modal
				show={plintFoundationModal}
				handleClose={() => setPlinthFoundationModal(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Notice
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				<PlinthFoundationModal proceedForNo={proceedForNo} proceedForYes={proceedForYes} />
			</Modal>
		</MainContainer>
	)
}

export default MaterialsDetails

export const PropertyUI = ({
	defaultBuildingHeight,
	prefilledData,
	handlePropertyData,
	isHeightInFloors,
	changeBuildingHeightType,
	isWholeBuilding,
	noOfFloors,
}) => {
	const { setIsLoading, setLoadingText } = useLoadingOverlay()

	const [typeOfConstr, setTypeOfConstr] = useState(prefilledData?.Walls?.Type || '')
	const [propertyRoof, setPropertyRoof] = useState(prefilledData?.Roof?.Description || '')
	const [propertyWalls, setPropertyWalls] = useState(prefilledData?.Walls?.Description || '')
	const [propertyFloor, setPropertyFloor] = useState(prefilledData?.Floors?.Description || '')
	const [propertyImages, setPropertyImages] = useState([...(prefilledData?.Images || [])])
	const [buildingHeight, setBuildingHeight] = useState(defaultBuildingHeight)

	const fetch = useFetch()
	const propertyFileInput = useRef()

	const handleNumberInput = () => {
		let numInput = buildingHeight
		if (typeof buildingHeight === 'string') {
			numInput = convertToNumber(buildingHeight)
		}
		return parseFloat(numInput)
	}

	const clearSelection = () => {
		setTypeOfConstr('')
		setPropertyRoof('')
		setPropertyWalls('')
		setPropertyFloor('')
	}

	useEffect(() => {
		const materialsData = {
			Roof: { Type: typeOfConstr, Description: propertyRoof },
			Walls: { Type: typeOfConstr, Description: propertyWalls },
			Floors: { Type: typeOfConstr, Description: propertyFloor },
			Images: propertyImages,
		}
		handlePropertyData(materialsData, handleNumberInput())
	}, [typeOfConstr, propertyRoof, propertyWalls, propertyFloor, propertyImages, buildingHeight])

	const triggerFileUpload = () => propertyFileInput?.current?.click()

	const handleFileUpload = async files => {
		const file = files[0]
		setLoadingText('Uploading...')
		setIsLoading(true)
		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setPropertyImages([...propertyImages, s3Link])
	}

	const removeImage = img => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setPropertyImages(propertyImages.filter(el => el !== img))
				Swal.fire('Your file has been deleted')
			}
		})
	}
	const propertyUploadedImagesMap =
		propertyImages?.length > 0 &&
		propertyImages?.map((img, key) => (
			<FileUploaded key={key} imageUrl={img} onDelete={() => removeImage(img)} />
		))

	return (
		<>
			<Row className="mb-4 mt-4">
				<Col className="d-flex align-items-center">
					<Icon name="typeOfConstruction" className="me-3" />
					<Text type="body1" className="me-3">
						Type of construction
						<span style={{ color: theme.colors.red }}>*</span>
					</Text>
				</Col>
				<Col className="d-flex justify-content-around">
					<Radio
						text="Kutcha"
						name="propertytype-kutcha"
						value={typeOfConstr === 'Kutcha'}
						onChange={() => setTypeOfConstr('Kutcha')}
						secondary
					/>
					<Radio
						text="Pucca"
						name="propertytype-pucca"
						value={typeOfConstr === 'Pucca'}
						onChange={() => setTypeOfConstr('Pucca')}
						secondary
					/>
				</Col>
			</Row>
			<Divider />

			<Question
				icon="ceiling"
				title="Roof"
				inputValue={propertyRoof}
				inputOnChange={e => setPropertyRoof(e.target.value)}
			/>
			<Question
				icon="wall"
				title="Walls"
				inputValue={propertyWalls}
				inputOnChange={e => setPropertyWalls(e.target.value)}
			/>
			<Question
				icon="floor"
				title="Floor"
				inputValue={propertyFloor}
				inputOnChange={e => setPropertyFloor(e.target.value)}
			/>
			<Row>
				<Col
					onClick={clearSelection}
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
				>
					<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
					<Text>Clear selection</Text>
				</Col>
			</Row>
			<Divider />

			<Question
				icon="buildingHeight"
				title={
					isHeightInFloors ? 'Building height (in number of floors)' : 'Building height (in meters)'
				}
				inputValue={isHeightInFloors && isWholeBuilding ? noOfFloors : buildingHeight}
				inputOnChange={e => setBuildingHeight(e.target.value)}
				customInput={true}
				compulsory={false}
				checkbox={true}
				isHeightInFloors={isHeightInFloors}
				changeBuildingHeightType={changeBuildingHeightType}
				isWholeBuilding={isWholeBuilding}
			/>

			<Divider />
			<Text type={'body1'}>Upload photos of each of the 4 corners of the building</Text>
			<>
				<div className="d-flex mt-4 mb-4">
					<HiddenFileInput
						ref={propertyFileInput}
						onChange={e => handleFileUpload(e.target.files)}
					/>
					<FileUploader onClick={triggerFileUpload} />
					{propertyUploadedImagesMap}
				</div>
			</>
		</>
	)
}

export const BasementUI = ({ prefilledData, handleBasementData }) => {
	const { setIsLoading, setLoadingText } = useLoadingOverlay()

	const [typeOfConstr, setTypeOfConstr] = useState(prefilledData?.Walls?.Type || '')
	const [basementCeiling, setBasementCeiling] = useState(prefilledData?.Ceiling?.Description || '')
	const [basementWalls, setBasementWalls] = useState(prefilledData?.Walls?.Description || '')
	const [basementFloor, setBasementFloor] = useState(prefilledData?.Floors?.Description || '')
	const [basementImages, setBasementImages] = useState([...(prefilledData?.Images || [])])

	const fetch = useFetch()
	const basementFileInput = useRef()

	const clearSelection = () => {
		setTypeOfConstr('')
		setBasementCeiling('')
		setBasementWalls('')
		setBasementFloor('')
	}

	useEffect(() => {
		const materialsData = {
			Ceiling: { Type: typeOfConstr, Description: basementCeiling },
			Walls: { Type: typeOfConstr, Description: basementWalls },
			Floors: { Type: typeOfConstr, Description: basementFloor },
			Images: basementImages,
		}
		handleBasementData(materialsData)
	}, [typeOfConstr, basementCeiling, basementWalls, basementFloor, basementImages])

	const triggerFileUpload = () => basementFileInput?.current?.click()

	const handleFileUpload = async files => {
		const file = files[0]
		setLoadingText('Uploading...')
		setIsLoading(true)
		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)
		setIsLoading(false)
		setBasementImages([...basementImages, s3Link])
	}

	const removeImage = img => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				setBasementImages(basementImages.filter(el => el !== img))
				Swal.fire('Your file has been deleted')
			}
		})
	}
	const basementUploadedImagesMap =
		basementImages?.length > 0 &&
		basementImages?.map((img, key) => (
			<FileUploaded key={key} imageUrl={img} onDelete={() => removeImage(img)} />
		))

	return (
		<>
			<Row className="mb-4 mt-4">
				<Col className="d-flex align-items-center">
					<Icon name="typeOfConstruction" className="me-3" />
					<Text type="body1" className="me-3">
						Type of construction
						<span style={{ color: theme.colors.red }}>*</span>
					</Text>
				</Col>
				<Col className="d-flex justify-content-around">
					<Radio
						text="Kutcha"
						name="basementtype-kutcha"
						value={typeOfConstr === 'Kutcha'}
						onChange={() => setTypeOfConstr('Kutcha')}
						secondary
					/>
					<Radio
						text="Pucca"
						name="basementtype-pucca"
						value={typeOfConstr === 'Pucca'}
						onChange={() => setTypeOfConstr('Pucca')}
						secondary
					/>
				</Col>
			</Row>
			<Divider />

			<Question
				icon="ceiling"
				title="Ceiling"
				tooltip="Ceiling here referred to the roof of the basement"
				inputValue={basementCeiling}
				inputOnChange={e => setBasementCeiling(e.target.value)}
			/>
			<Question
				icon="wall"
				title="Walls"
				inputValue={basementWalls}
				inputOnChange={e => setBasementWalls(e.target.value)}
			/>
			<Question
				icon="floor"
				title="Floor"
				inputValue={basementFloor}
				inputOnChange={e => setBasementFloor(e.target.value)}
			/>
			<Row>
				<Col
					onClick={clearSelection}
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
				>
					<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
					<Text>Clear selection</Text>
				</Col>
			</Row>

			<Divider />
			<Text type={'body1'}>Upload photos of each of the 4 corners of the basement</Text>
			<>
				<div className="d-flex mt-4 mb-4">
					<HiddenFileInput
						ref={basementFileInput}
						onChange={e => handleFileUpload(e.target.files)}
					/>
					<FileUploader onClick={triggerFileUpload} />
					{basementUploadedImagesMap}
				</div>
			</>
		</>
	)
}

const Question = ({
	icon,
	title,
	tooltip,
	inputValue,
	inputOnChange,
	customInput,
	compulsory = true,
	checkbox,
	isHeightInFloors,
	changeBuildingHeightType,
	isWholeBuilding,
}) => {
	const displayTooltip = tooltip ? <Tooltip text={tooltip} icon="knowMore"></Tooltip> : null

	return (
		<div className="full-flex-container" style={{ margin: '2rem 0' }}>
			<div className="flex-container" style={checkbox ? { alignSelf: 'flex-start' } : {}}>
				<Icon name={icon} />
				<Space x={1} />
				<Text style={{ fontSize: '18px' }}>
					{title}
					{compulsory ? <span style={{ color: theme.colors.red }}>*</span> : null}
				</Text>
				{displayTooltip}
			</div>
			<Row>
				<Col>
					{customInput ? null : (
						<Text className="mb-2">
							Please specify the material used
							{compulsory ? <span style={{ color: theme.colors.red }}>*</span> : null}
						</Text>
					)}
					<Input
						placeholder={isHeightInFloors ? 'Enter the number of floors' : 'Enter here'}
						value={inputValue}
						onChange={inputOnChange}
						autoComma={customInput}
						disabled={isHeightInFloors && isWholeBuilding}
					/>

					{checkbox ? (
						<>
							<Space y={1} />
							<Checkbox
								text="Answer in number of floors"
								textStyle={{ fontSize: '14px', letterSpacing: '0.03em', color: '#6d6d6d' }}
								value={isHeightInFloors}
								onChange={changeBuildingHeightType}
							/>
						</>
					) : null}
				</Col>
			</Row>
		</div>
	)
}
