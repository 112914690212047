import { nanoid } from 'nanoid'

// Address.js
export const hypothecationDefault = {
	Name: '',
	Type: '',
	id: 'hypothecation-item-default',
}

// StockType.js
export const stockDefault = {
	Type: '',
	AmountClosed: '',
	AmountOpen: '',
	OpenPer: '',
	ClosedPer: '',
	GoodsHeldInTrust: false,
	id: 'stock-item-default',
}
