import { Text, theme, Button, Modal, Input, Select } from 'verak-ui'
import { useState } from 'react'
import { useLoadingOverlay } from '../../../components/LoadingOverlay'
import { useFetch } from '../../../api/apihook'

const removalReasons = [
	'Already have / Bought from elsewhere',
	'Buy Later',
	'High Premium',
	'Not Interested',
	'Not Required',
	'Wrong/Test',
	'Other',
]

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const LostPolicyToParties = [
	'Another Agent',
	'Bank',
	'Bajaj',
	'HDFC',
	'ICICI',
	'Kotak',
	'New India',
	'Oriental',
	'Paytm',
	'Policy Bazaar',
	'Reliance',
	'SBI',
	'United India',
	'Other',
]

const selectStyles = {
	width: '100%',
	height: '48px',
}

const DeleteRFQModal = ({ show, handleClose, rfqData, resetDeleteRFQId, refetchRFQs }) => {
	const [deletionReason, setDeletionReason] = useState('')
	const [AHPRenewalMonth, setAHPRenewalMonth] = useState('')

	const [LostPolicyTo, setLostPolicyTo] = useState('')
	const [LostPolicyToOtherParty, setLostPolicyToOtherParty] = useState('')

	const [BuyLaterMonth, setBuyLaterMonth] = useState('')

	const [OtherReason, setOtherReason] = useState('')

	const { setIsLoading, setLoadingText } = useLoadingOverlay()
	const { deleteRfq, archiveRfq } = useFetch()

	const setLoadingState = ({ status, message }) => {
		setIsLoading(status)
		setLoadingText(message)
	}

	const computeDisableProceed = () => {
		let disabled = false
		if (deletionReason === 'Already have / Bought from elsewhere' && LostPolicyTo === 'Other') {
			disabled = !LostPolicyToOtherParty
		} else if (deletionReason === 'Other') {
			disabled = !OtherReason
		} else if (deletionReason === '') {
			disabled = true
		}
		return disabled
	}

	const disableProceed = computeDisableProceed()

	const deletingRfq = rfqData?.Status === 'AGENT:RFQ:PENDING'

	const rfqId = rfqData?.id || ''

	const handleDeleteRFQ = async () => {
		setLoadingState({ status: true, message: 'Deleting RFQ' })
		handleClose()
		if (deletingRfq) {
			await deleteRfq(rfqId, deletionReason)
		} else {
			const reason = deletionReason === 'Other' ? OtherReason : deletionReason
			const additionalData = Omit({
				AHPRenewalMonth,
				LostPolicyTo: LostPolicyTo === 'Other' ? LostPolicyToOtherParty : LostPolicyTo,
				BuyLaterMonth,
			})
			await archiveRfq(rfqId, reason, additionalData)
		}

		refetchRFQs()
		resetDeleteRFQId()
		setDeletionReason('')
	}

	const titleElement = (
		<Text color={theme.colors.primary} fontSize="14px" fontWeight="600">
			{deletingRfq ? 'Delete' : 'State the reason for removing'} RFQ
		</Text>
	)

	const footerElement = (
		<div className="p-3 pt-0 w-100">
			<Button
				disabled={disableProceed}
				applyDisableFilter={disableProceed}
				onClick={handleDeleteRFQ}
				className="w-100 p-4"
				bgColor={'#DC2F21'}
				label={deletingRfq ? 'DELETE' : 'REMOVE'}
			/>
		</div>
	)

	const resetStates = () => {
		// reset other states
		setOtherReason('')
		setAHPRenewalMonth('')
		setLostPolicyTo(null)
		setLostPolicyToOtherParty('')
		setBuyLaterMonth('')
	}

	const handleDeletionReasonChange = selected => {
		setDeletionReason(selected)
		resetStates()
	}

	const removalReasonsOptions = removalReasons.map(reason => (
		<option key={reason} value={reason}>
			{reason}
		</option>
	))

	let otherReason, buyLaterReason, alreadyHavePolicyReason
	if (!deletingRfq) {
		switch (deletionReason) {
			case 'Already have / Bought from elsewhere': {
				const monthOptions = months.map((month, index) => (
					<option key={index} value={index + 1}>
						{month}
					</option>
				))
				const LostPolicyToOptions = LostPolicyToParties.map((party, index) => (
					<option key={index} value={party}>
						{party}
					</option>
				))
				const freeTextOtherParty =
					LostPolicyTo === 'Other' ? (
						<>
							<Text className="mt-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
								Other
								<span style={{ color: theme.colors.red }}>*</span>
							</Text>
							<Input
								value={LostPolicyToOtherParty}
								onChange={e => setLostPolicyToOtherParty(e.target.value)}
								placeholder="Other"
								style={{ height: '48px' }}
								className="mt-2 w-100"
							/>
						</>
					) : null

				alreadyHavePolicyReason = (
					<>
						<Text className="mt-3 mb-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Renewal Month (Optional)
						</Text>
						<Select
							placeholder="Select Renewal Month"
							value={AHPRenewalMonth}
							onChange={val => setAHPRenewalMonth(val)}
							style={selectStyles}
						>
							{monthOptions}
						</Select>

						<Text className="mt-3 my-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Lost the policy to? (Optional)
						</Text>
						<Select
							placeholder="Select"
							value={LostPolicyTo}
							onChange={val => setLostPolicyTo(val)}
							style={selectStyles}
						>
							<option selected disabled>
								Select
							</option>
							{LostPolicyToOptions}
						</Select>

						{freeTextOtherParty}
					</>
				)
				break
			}
			case 'Buy Later': {
				const monthOptions = months.map((month, index) => (
					<option key={index} value={index + 1}>
						{month}
					</option>
				))
				buyLaterReason = (
					<>
						<Text className="mt-3 mb-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
							Month of Purchase (Optional)
						</Text>
						<Select
							placeholder="Select Month of Purchase"
							value={BuyLaterMonth}
							onChange={val => setBuyLaterMonth(val)}
							style={selectStyles}
						>
							{monthOptions}
						</Select>
					</>
				)
				break
			}
			default:
		}
	}

	if (deletionReason === 'Other') {
		otherReason = (
			<>
				<Text className="mt-3 mb-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
					Enter reason
					<span style={{ color: theme.colors.red }}>*</span>
				</Text>
				<Input
					value={OtherReason}
					onChange={e => setOtherReason(e.target.value)}
					placeholder="Enter reason"
					style={{ height: '48px' }}
					className="w-100"
				/>
			</>
		)
	}

	return (
		<Modal show={show} handleClose={handleClose} title={titleElement} footer={footerElement}>
			<Text className="mb-2" color={theme.colors.gray2} fontSize="14px" fontWeight="500">
				Enter reason for removal
				<span style={{ color: '#eb5757' }}>*</span>
			</Text>
			<Select
				placeholder="Select reason"
				value={deletionReason}
				onChange={handleDeletionReasonChange}
				style={selectStyles}
			>
				<option disabled selected>
					Select reason
				</option>
				{removalReasonsOptions}
			</Select>

			{alreadyHavePolicyReason}
			{buyLaterReason}
			{otherReason}
		</Modal>
	)
}

export default DeleteRFQModal

const Omit = obj => {
	const missing = [null, undefined, '']
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !missing.includes(v)))
}
