import { useContext } from 'react'
import { Context } from '../../context/Provider'

import { useLocation } from 'react-router-dom'
import { getStockLocation } from '../../utilities/getStockLocation'

import DetailsCollectionTemplate from './DetailsCollectionTemplate/DetailsCollectionTemplate'
import { theme, Text, Space, Alert } from 'verak-ui'

const getDisclosureMethodString = method => {
	switch (method) {
		case 'DECLARED_VALUE':
			return 'Declared Value'
		case 'DECLARATION_BASIS':
			return 'Declaration Basis'
		case 'FLOATER':
			return 'Floater'
		case 'FLOATER_DECLARATION':
			return 'Floater Declaration'
		default:
			return 'Declared Value'
	}
}

const StockHandler = () => {
	const [state] = useContext(Context)
	let disclosureMethod = state?.quote?.StockDisclosureMethod
	const RiskLocations = state.quote?.RiskLocations

	const location = useLocation()

	let propertyType,
		levelIndex,
		currentLocationId = null

	// checking if you are in the Edge Case - 2 journey
	const isInEdgeCase2Journey = location?.state?.isInEdgeCase2Journey || false

	if (isInEdgeCase2Journey) {
		const locationWithStock = getStockLocation(RiskLocations)
		propertyType = locationWithStock.propertyType
		levelIndex = locationWithStock.levelIndex
		disclosureMethod = 'DECLARED_VALUE'
		// since it's the 'N' to 1 case of edge case 2 it's always gonna be "DECLARED_VALUE"
	} else {
		propertyType = location?.state?.propertyType
		levelIndex = location?.state?.levelIndex
		currentLocationId = location?.state?.currentLocationId
	}

	// handles the logic of if no stock type present it defaults to the "Declared value" method
	if (disclosureMethod === 'FLOATER' || disclosureMethod === 'FLOATER_DECLARATION') {
		return (
			<DetailsCollectionTemplate
				type="Floater"
				propertyType={propertyType}
				levelIndex={levelIndex}
				currentLocationId={currentLocationId}
				isInEdgeCase2Journey={isInEdgeCase2Journey}
			/>
		)
	} else {
		return (
			<DetailsCollectionTemplate
				secondaryContainerStyle={secondaryStyle}
				primaryContainerStyle={primaryStyle}
				type="Declaration"
				propertyType={propertyType}
				levelIndex={levelIndex}
				currentLocationId={currentLocationId}
				isInEdgeCase2Journey={isInEdgeCase2Journey}
			>
				<Space y={1.5} />
				<Alert
					title="Note"
					infoIconColor={theme.colors.gray7}
					titleColor={theme.colors.gray7}
					variant="warning"
				>
					<Space y={0.625} />
					<Text
						color={theme.colors.gray7}
						style={{
							fontWeight: 'bold',
						}}
					>
						Since the client has opted for the {getDisclosureMethodString(disclosureMethod)} method
						of disclosing stock value, please make sure that you enter the MAXIMUM value for each
						stock type in the space provided below. It is recommended that you take the MAXIMUM
						stock value at any point in the preceeding year for the fields below.
					</Text>
				</Alert>
			</DetailsCollectionTemplate>
		)
	}
}

export default StockHandler

const primaryStyle = {
	background: `${theme.colors.secondary4}`,
	borderRadius: '8px',
}
const secondaryStyle = {
	background: `${theme.colors.secondary3}`,
	borderRadius: '8px',
}
