import { useContext, useRef } from 'react'
// import { postReq } from "./API";
import { Context } from '../context/Provider'
import { useAuth0 } from '@auth0/auth0-react'
import { useAPIStateContext } from '../context/APIStateProvider'
import { useLoadingOverlay } from '../components/LoadingOverlay'
import { convertBase64ToBlob, toBase64 } from '../utilities/imageUploadUtilities'
import convertToBackendSchema from '../utilities/convertToBackendSchema'
import { isFireAllRiskPolicy } from '../utilities/isFireAllRiskPolicy'
import { nanoid } from 'nanoid'
import { EventSourcePolyfill } from 'event-source-polyfill'
import jwt from 'jwt-decode'

const url = process.env.REACT_APP_SERVER_URL
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

const md5 = require('js-md5')

const getRfqTag = (stateRef, isSafetyPlan) => {
	if (isSafetyPlan || stateRef.current.quote.Tag === 'SAFETY_PLAN') {
		return 'SAFETY_PLAN'
	}
	return isFireAllRiskPolicy(stateRef.current.quote?.PolicyAddons)
}

export const useFetch = () => {
	const { setSuccess, isSuccessAPI, cachedData, setCache } = useAPIStateContext()

	const { isLoading, setIsLoading, setLoadingText, setErrorState } = useLoadingOverlay()

	const setLoadingState = ({ status, message }) => {
		setIsLoading(status)
		setLoadingText(message)
	}

	const { getAccessTokenSilently } = useAuth0()
	const [state] = useContext(Context)
	const stateRef = useRef()
	stateRef.current = state

	const fetching = () => {
		if (!isLoading) {
			setSuccess(false)
			setLoadingState({ status: true, message: '' })
		}
	}

	const handleResponse = (response, hideErrorPopup = false) => {
		return response.json().then(value => {
			if ((!response.ok || value.error) && !hideErrorPopup) {
				let errorPreview
				if (value.error) {
					errorPreview = value.error
				} else if (!response.ok) {
					errorPreview = response.error ? response.statusText : ''
				}
				setErrorState({
					status: true,
					errorMessage: `	Error: ${errorPreview}`,
					errorStatusCode: response.status,
				})
			} else {
				setSuccess(true)
			}
			setLoadingState({ status: false, message: '' })
			return value
		})
	}

	const handleResponseNoJSON = response => {
		if (!response.ok) {
			setErrorState({
				status: true,
				errorMessage: 'Error: ' + (response.error ?? response.statusText),
				errorStatusCode: response.status,
			})
		} else {
			setSuccess(true)
		}
		setLoadingState({ status: false, message: '' })
		return response
	}

	const sendRequest = async (
		endPoint,
		type = 'POST',
		body,
		showLoader = true,
		handleTimeout = true,
		hideErrorPopup = false
	) => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})
		if (showLoader) {
			setLoadingState({ status: true, message: 'Loading...' })
		}

		const controller = new AbortController()
		let timeoutId

		if (handleTimeout) {
			timeoutId = setTimeout(() => {
				setLoadingState({ status: false, message: '' })
				setErrorState({
					status: true,
					errorMessage: `You seem to be offline, please try again in sometime`,
					errorStatusCode: '',
				})
				controller.abort()
			}, 5000)
		}

		const result = await fetch(`${url}/${endPoint}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: body ? JSON.stringify(body) : null,
			signal: handleTimeout ? controller.signal : null,
		}).then(response => {
			if (handleTimeout) {
				clearTimeout(timeoutId)
			}
			return handleResponse(response, hideErrorPopup)
		})

		return result
	}

	const constructLocationWithLevels = listing => {
		let locationItem = {}
		const firstLevel = listing[0]
		locationItem.levels = [...listing]
		locationItem.Type = firstLevel.Type
		locationItem.AddressLine1 = firstLevel.AddressLine1
		locationItem.AddressLine2 = firstLevel.AddressLine2
		locationItem.PIN = firstLevel.PIN
		locationItem.City = firstLevel.City
		locationItem.State = firstLevel.State
		locationItem.ConstructionAge = firstLevel.ConstructionAge
		locationItem.TheftRisk = firstLevel.TheftRisk
		locationItem.Products = firstLevel?.Products
		locationItem.Materials = firstLevel.Materials
		locationItem.FireRisk = firstLevel.FireRisk
		locationItem.HistoricInfo = firstLevel.HistoricInfo
		locationItem.ShopRisk = firstLevel.ShopRisk
		locationItem.BuildingHeight = firstLevel.BuildingHeight
		locationItem.Floors = firstLevel.Floors

		if (listing.length == 1 && (firstLevel.SubType == 'Whole' || firstLevel.SubType == 'Single')) {
			return firstLevel
		}

		locationItem.levels = listing.map(eItem => {
			const {
				Type,
				AddressLine1,
				AddressLine2,
				PIN,
				City,
				State,
				ConstructionAge,
				TheftRisk,
				Materials,
				FireRisk,
				HistoricInfo,
				...levelData
			} = eItem

			return { ...levelData, Level: `${levelData.Level}` }
		})

		return locationItem
	}

	const mergeRiskLocations = quote => {
		const { RiskLocations, ...toReturn } = quote

		toReturn.RiskLocations = []
		const locationList = {}

		RiskLocations.forEach(item => {
			if (!locationList[item.GroupHash]) {
				locationList[item.GroupHash] = []
			}
			locationList[item.GroupHash].push(item)
		})

		Object.keys(locationList).forEach((GroupHash, index) => {
			const listing = locationList[GroupHash]
			let locationItem = {}

			if (listing.length == 1) {
				const firstItem = listing[0]
				if (firstItem.SubType == 'Whole') {
					locationItem = { ...firstItem, Level: `${firstItem.Level}` }
				} else {
					// single level in either basment or property
					locationItem = constructLocationWithLevels(listing)
				}
			} else {
				// check if all contains same Type if yes then only property or only basement else PROPERTYBASEMENT
				let allSameType = false
				let typeList = {}
				listing.forEach(item => {
					if (!typeList[item.Type]) {
						typeList[item.Type] = []
					}
					typeList[item.Type].push(item)
				})
				allSameType = Object.keys(typeList).length === 1

				if (allSameType) {
					// calculate and merge all levels and move common data
					locationItem = constructLocationWithLevels(listing)
					locationItem.ID = nanoid()
				} else {
					const propertyItem = constructLocationWithLevels(typeList['PROPERTY'])
					const basementItem = constructLocationWithLevels(typeList['BASEMENT'])
					locationItem = {
						ID: nanoid(),
						Type: 'PROPERTYBASEMENT',
						property: { ...propertyItem },
						basement: { ...basementItem },
					}
				}
			}
			locationItem.label = `Location #${index + 1}`
			toReturn.RiskLocations.push(locationItem)
		})
		return toReturn
	}

	const saveQuote = async (updatedPolicyName = '', isSafetyPlan = false) => {
		const result = await saveQuoteHandler(updatedPolicyName, isSafetyPlan)
		return result
	}

	const getClientDetails = async clientId => {
		if (!clientId) {
			return null
		}

		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/client/details/${clientId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: null,
		}).then(handleResponse)

		return result
	}

	const saveQuoteHandler = async (updatedPolicyName = '', isSafetyPlan) => {
		// if levels present split them and create individual risk locations

		const { quoteId, RiskLocations, ...data } = stateRef.current.quote
		if (!quoteId) {
			return null
		}
		let toSave = data
		let locationListing = []
		if (RiskLocations && RiskLocations.length > 0) {
			locationListing = [...RiskLocations]
		}

		let individualRiskLocations = []
		if (locationListing.length > 0) {
			locationListing.forEach(item => {
				const isBasement = item.Type === 'BASEMENT'
				const isProperty = item.Type === 'PROPERTY'
				const isPropertyBasement = item.Type === 'PROPERTYBASEMENT'
				const { levels, label, ...rItem } = item
				let addressHash = ''
				if (rItem.AddressLine1) {
					if (!rItem.AddressLine2) {
						rItem.AddressLine2 = ''
					}
					if (!rItem.PIN) {
						rItem.PIN = ''
					}
					addressHash = md5(`${rItem.AddressLine1},${rItem.AddressLine2},${rItem.PIN}`)
				} else if (isPropertyBasement) {
					let AddressLine2 = '',
						PIN = ''

					if (rItem.property && rItem.property.AddressLine1) {
						if (rItem.property.AddressLine2) {
							AddressLine2 = rItem.property.AddressLine2
						}

						if (rItem.property.PIN) {
							PIN = rItem.property.PIN
						}

						addressHash = md5(`${rItem.property.AddressLine1},${AddressLine2},${PIN}`)
					} else if (rItem.basement && rItem.basement.AddressLine1) {
						if (rItem.basement.AddressLine2) {
							AddressLine2 = rItem.basement.AddressLine2
						}

						if (rItem.basement.PIN) {
							PIN = rItem.basement.PIN
						}
						addressHash = md5(`${rItem.basement.AddressLine1},${AddressLine2},${PIN}`)
					}
				}
				if (isBasement || isProperty) {
					if (levels && levels.length > 0) {
						levels.forEach(lItem => {
							individualRiskLocations.push({
								...rItem,
								...lItem,
								GroupHash: addressHash,
								SubType: 'Single',
								Level: parseInt(lItem.Level),
							})
						})
					} else {
						individualRiskLocations.push({
							...item,
							GroupHash: addressHash,
							Level: parseInt(item.Level),
						})
					}
				} else if (isPropertyBasement) {
					// extra check
					if (rItem.basement) {
						const { levels: bLevels, ...bItem } = rItem.basement
						if (bLevels && bLevels.length > 0) {
							bLevels.forEach(bLItem => {
								individualRiskLocations.push({
									...bItem,
									...bLItem,
									ID: bLItem.ID ? bLItem.ID : nanoid(),
									GroupHash: addressHash,
									SubType: 'Single',
									Level: parseInt(bLItem.Level),
								})
							})
						} else {
							individualRiskLocations.push({
								...bItem,
								ID: bItem.ID ? bItem.ID : nanoid(),
								GroupHash: addressHash,
								Level: parseInt(bItem.Level),
							})
						}
					}

					// extra check
					if (rItem.property) {
						const { levels: pLevels, ...pItem } = rItem.property
						if (pLevels && pLevels.length > 0) {
							pLevels.forEach(pLItem => {
								individualRiskLocations.push({
									...pItem,
									...pLItem,
									ID: pLItem.ID ? pLItem.ID : nanoid(),
									GroupHash: addressHash,
									SubType: 'Single',
									Level: parseInt(pLItem.Level),
								})
							})
						} else {
							individualRiskLocations.push({
								...pItem,
								ID: pItem.ID ? pItem.ID : nanoid(),
								GroupHash: addressHash,
								Level: parseInt(pItem.Level),
							})
						}
					}
				}
			})
		}

		toSave.RiskLocations = [...individualRiskLocations]

		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		let currentPath = window.location.pathname || ''
		let currentSearch = window.location.search || ''
		let currentHash = window.location.hash || ''
		if (currentPath.includes('/stock-details-collection')) {
			currentPath = '/property-risk/sum-insured'
		} else if (currentPath.includes('/risk-location-review')) {
			currentPath = '/journey/risk-location-selection'
		}

		const getLocationIdForPB = propertyOrbasementKey => {
			if (stateRef.current?.location[propertyOrbasementKey]?.levels?.length > 0) {
				return stateRef.current?.location[propertyOrbasementKey].levels[0].ID
			} else {
				return stateRef.current?.location[propertyOrbasementKey].ID
			}
		}

		// LastPath + current active location id
		let lastPathLocationId = ''

		if (stateRef.current?.location?.Type === 'PROPERTYBASEMENT') {
			// set id from basement or property
			if (stateRef.current?.location.basement) {
				lastPathLocationId = getLocationIdForPB('basement')
			}
		} else if (stateRef.current?.location?.levels?.length > 0) {
			lastPathLocationId = stateRef.current?.location.levels[0].ID
		} else {
			lastPathLocationId = stateRef.current?.location?.ID
		}

		const locationId = stateRef.current?.location?.ID ? `?locationID=${lastPathLocationId}` : ''
		const currentCombinedPath = `${currentPath}${currentSearch}${currentHash}${locationId}`

		const result = await fetch(`${url}/fire/rfq/${quoteId}`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				Data: toSave,
				LastPath: currentCombinedPath,
				PolicyChangedTo: updatedPolicyName,
				Tag: getRfqTag(stateRef, isSafetyPlan),
				Type: toSave?.Type || 'NEW',
			}),
		}).then(handleResponse)

		return result
	}

	const getQuote = async quoteId => {
		if (!quoteId) {
			return null
		}
		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/rfq/${quoteId}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: null,
		}).then(handleResponse)

		return result
	}

	const getNatureOfStock = async () => {
		const cachedNatureOfStock = cachedData?.NatureOfStock || ''

		if (cachedNatureOfStock) {
			return cachedNatureOfStock
		}

		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/static/nature_of_stock`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: null,
		}).then(handleResponse)

		if (isSuccessAPI) {
			setCache({ NatureOfStock: result })
		}

		return result
	}

	const getSubCategoryByRiskType = async () => {
		const cachedSubCat = cachedData?.SubCategories || ''

		if (cachedSubCat) {
			return cachedSubCat
		}

		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/static/risk_loc`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: null,
		}).then(handleResponse)

		if (isSuccessAPI) {
			// sort the array alphabetically
			result?.required?.sort((a, b) => a.Description.localeCompare(b.Description))
			setCache({ SubCategories: result })
		}

		return result
	}

	//  send email confirmation to client on journey end
	const sendEmailConfirmation = async email => {
		const rfqId = stateRef.current?.quote?.quoteId

		if (!rfqId) {
			return null
		}

		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/sendConfirmation/${rfqId}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})

		return result
	}

	// sends rfq email for pilot flow
	const sendRfqEmailPilot = async payload => {
		const rfqId = stateRef.current?.quote?.quoteId
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`fire/rfq/review/${rfqId}`, 'POST', payload)
		return result
	}

	// anamdon rfq - for pilot flow
	const abandonRfqPilot = async () => {
		const { quote } = stateRef.current
		const rfqId = quote?.quoteId || ''

		if (!rfqId) {
			return null
		}

		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/rfq/${rfqId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then(handleResponse)

		return result
	}

	//  get signed url to upload image,pdf
	const getSignedUrl = async (mimeType = 'image/jpeg', rfq_id) => {
		const rfqId = stateRef.current?.quote?.quoteId ?? rfq_id

		if (!rfqId) {
			return null
		}

		fetching()
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/uploadUrl/${rfqId}?contentType=${mimeType}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then(handleResponse)

		return result
	}

	//  upload blob to the signed url
	const uploadBlobToSignedUrl = async (link, file) => {
		const base64String = await toBase64(file)
		const blobContent = await convertBase64ToBlob(base64String)

		fetching()
		const result = await fetch(link, {
			method: 'PUT',
			body: blobContent,
		}).then(handleResponseNoJSON)

		return result
	}

	//  GET request with auth token
	const getReqWithToken = async endpoint => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})
		const result = await fetch(`${url}/${endpoint}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then(handleResponse)
		return result
	}

	// NOTE: 'currentRfqId' is also allowed to be passed here in case the rfq data is not in the reducer yet.
	// for eg: events for clicking on an rfq on the dashboard
	const trackRfqEvent = async (eventName, eventParams = {}, currentRfqId = '') => {
		const rfqId = stateRef.current?.quote?.quoteId || currentRfqId || ''

		if (!rfqId) {
			return null
		}

		const reqBody = JSON.stringify({
			name: eventName,
			...eventParams,
		})

		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/fire/rfq/event/${rfqId}`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: reqBody,
		})

		return result
	}

	// methods for SQS (Sales Quote Software)
	const getOngoingRFQs = async (sortBy, cursor = '', status) => {
		let result
		let endpoint = `sqs/rfqs`
		const queryParams = new URLSearchParams()
		if (cursor) {
			queryParams.set('cursor', cursor)
		}
		if (sortBy) {
			queryParams.set('sortBy', sortBy)
		}
		if (status) {
			queryParams.set('status', status)
		}

		const queryString = queryParams.toString()
		if (queryString) {
			endpoint = `${endpoint}?${queryParams.toString()}`
		}
		result = await sendRequest(endpoint, 'GET', null, false, false)
		return result
	}

	const searchRFQbyQuery = async query => {
		const result = await sendRequest(`sqs/rfqs/?search=${query}`, 'GET')
		return result
	}

	const getConnectedContacts = async accountId => {
		const result = await sendRequest(`sqs/crm/account/${accountId}`, 'GET')
		return result
	}

	const assignSelfServeRfq = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/assign`)
		return result
	}

	const getLeadData = async (
		toSearch,
		type = 'phone', // phone or lead
		showLoader = true,
		handleTimeout = true,
		hideErrorPopup = false
	) => {
		if (!toSearch || !type) {
			return
		}
		const queryParams = new URLSearchParams()
		if (type === 'phone') queryParams.set('phone', toSearch)
		if (type === 'lead') queryParams.set('leadId', toSearch)

		if (showLoader) {
			setLoadingState({ status: showLoader, message: `Searching ${type}...` })
		}

		const result = await sendRequest(
			`sqs/lead?${queryParams}`,
			'GET',
			null,
			false,
			handleTimeout,
			hideErrorPopup
		)
		return result
	}

	const getRFQData = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`fire/rfq/${rfqId}`, 'GET')
		return result
	}

	const resubmitRfqOnUpdate = async (rfqId, oldRfq) => {
		if (!rfqId) {
			return null
		}
		if (Object.keys(oldRfq).length > 0) {
			oldRfq = convertToBackendSchema(oldRfq)
		}
		const result = await sendRequest(`fire/rfq/resubmit/${rfqId}`, 'POST', { Data: oldRfq })
		return result
	}

	// get city and state from PIN code
	const getLocationsWithPIN = async PIN => {
		const data = await fetch(`https://api.postalpincode.in/pincode/${PIN}`, {
			method: 'GET',
		})
			.then(response => {
				if (!response.ok) {
					throw new Error("Can't fetch locations")
				} else {
					return response.json()
				}
			})
			.catch(error => {
				console.log(error)
			})

		return data
	}

	const triggerNotification = rfqId => {
		const result = sendRequest(`sqs/rfqs/${rfqId}/notify-client`, 'POST')
		return result
	}

	const handleAbandonedQuotes = async (rfqId, payload) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`sqs/rfqs/${rfqId}/abandon`, 'POST', payload)
		return result
	}

	const sendFireBrochure = async (ProposerName, Phone, Language) => {
		const result = await sendRequest('sqs/send-brochure', 'POST', { ProposerName, Phone, Language })
		return result
	}

	const escalateRfq = async (rfqId, escalationData) => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`sqs/rfqs/${rfqId}/escalate`, 'POST', { ...escalationData })
		return result
	}

	const checkContactExists = async phone => {
		const result = await sendRequest(`sqs/contacts?phone=%2B91${phone}`, 'GET')
		return result
	}

	const getAssociatedAccounts = async contactId => {
		const result = await sendRequest(`sqs/contacts/${contactId}/accounts`, 'GET')
		return result
	}

	const getNotifications = async () => {
		const result = await sendRequest(`sqs/alerts`, 'GET', null, null, false)
		return result
	}

	const markNotificationAsSeen = async (notificationId, showLoader = true, payload = {}) => {
		const result = await sendRequest(
			`sqs/alerts/${notificationId}/mark-seen`,
			'PUT',
			payload,
			showLoader
		)
		return result
	}

	const markAllNotificationsAsSeen = async (showLoader = false, payload = {}) => {
		const result = await sendRequest(`sqs/alerts`, 'PUT', payload, showLoader)
		return result
	}

	const registerEventListener = async (cb, cbError) => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const user = jwt(token)
		const auth0AgentId = user.sub.replace('auth0|', '')
		const eventList = new EventSourcePolyfill(`${url}/events`, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
		})
		eventList.onmessage = result => cb(result, auth0AgentId)
		eventList.onerror = cbError
		return eventList
	}

	const createAccount = async payload => {
		const response = sendRequest('sqs/account', 'POST', payload)
		return response
	}

	const createRfq = async payload => {
		const response = await sendRequest(`fire/rfq`, 'POST', payload)
		return response
	}

	// const getCustomerInfo = async rfqId => {
	// 	if (!rfqId) {
	// 		return null
	// 	}
	// 	const response = await sendRequest(`sqs/rfqs/${rfqId}/customer-info`, 'GET')
	// 	return response
	// }

	const updateCustomerDetails = async (rfqId, payload) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfqs/${rfqId}/customer-info`, 'POST', payload)
		return response
	}

	const getYoutubeVideos = async () => {
		const response = await sendRequest('sqs/static/youtube_videos', 'GET')
		return response
	}

	const sendNotificationWithoutRfq = async payload => {
		const response = await sendRequest(`sqs/send-notification`, 'POST', payload, true, false)
		return response
	}

	const getCaseStudies = async (city, state, showLoader = true) => {
		const queryParams = new URLSearchParams()
		if (city) queryParams.set('city', city)
		if (state) queryParams.set('state', state)
		const response = await sendRequest(`sqs/case_studies?${queryParams}`, 'GET', false, showLoader)
		return response?.caseStudies
	}

	const getDraftRFQList = async (cursor = '', type = 'NEW') => {
		const queryParams = new URLSearchParams()
		if (cursor) queryParams.set('cursor', cursor)
		if (type) queryParams.set('type', type)
		const response = await sendRequest(`sqs/draftRfqs?${queryParams}`, 'GET')
		return response
	}

	const searchDraftRfqByQuery = async (searchQuery, type = 'NEW') => {
		const result = await sendRequest(
			`sqs/draftRfqs?search=${searchQuery.trim()}&type=${type}`,
			'GET'
		)
		return result
	}

	const getDraftRFQ = async id => {
		const response = await sendRequest(`sqs/draftRfq/${id}`, 'GET')
		return response
	}

	const createDraftRFQ = async data => {
		const response = await sendRequest(`sqs/draftRfq`, 'POST', data)
		return response
	}

	const updateDraftRFQ = async (id, data) => {
		if (!id) {
			return null
		}
		const response = await sendRequest(`sqs/draftRfq/${id}`, 'PUT', data)
		return response
	}

	const deleteDraftRfq = async (rfqId, reason) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/draftRfq/${rfqId}/lost`, 'PUT', { reason })
		return response
	}

	const deleteRfq = async (rfqId, deleteReason) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`fire/rfq/${rfqId}`, 'DELETE', { reason: deleteReason })
		return response
	}

	const archiveRfq = async (rfqId, deleteReason, additionalData) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfqs/${rfqId}/archive`, 'PUT', {
			reason: deleteReason,
			additionalData,
		})
		return response
	}

	const getFBCampaign = async leadSource => {
		const response = await sendRequest(
			`sqs/static/campaigns?leadSource=${leadSource}`,
			'GET',
			null,
			null,
			false
		)
		return response
	}

	const getWABots = async rfqId => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfqs/${rfqId}/chatlogs`, 'GET')
		return response
	}

	const triggerWAAddressPing = async (rfqId, type = 'DraftRfq') => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/trigger-address-collection`, 'POST', {
			id: rfqId,
			type: type,
		})
		return response
	}

	const getNearestCustomerInfo = async (city, pin) => {
		let url = `sqs/nearest-customer`

		const queryParams = new URLSearchParams()
		if (city) {
			queryParams.set('city', city)
		}
		if (pin) {
			queryParams.set('pin', pin)
		}

		const response = await sendRequest(`${url}?${queryParams}`, 'GET')
		return response
	}

	const sendNearestCustInfo = async toSend => {
		const response = await sendRequest(`sqs/send-nearest-customer-data`, 'POST', {
			...toSend,
		})
		return response
	}

	const sendWebsiteLink = async uid => {
		if (!uid) {
			return null
		}
		const response = await sendRequest(`sqs/send-uid-unlock`, 'POST', {
			uid,
		})
		return response
	}

	const getAgentList = async (teamName, showLoader = true) => {
		const result = await sendRequest(`sqs/agents/${teamName}`, 'GET', null, showLoader)
		return result
	}

	const raiseReassignmentRequest = async (agentEmail, rfqIds, reason) => {
		const response = await sendRequest(`sqs/reassignments`, 'POST', {
			to: agentEmail,
			rfqIds,
			reason,
		})
		return response
	}

	const getPendingReassignmentRequests = async (type, cursor) => {
		const queryParams = new URLSearchParams()
		queryParams.set('type', type)
		if (cursor) queryParams.set('cursor', cursor)

		const result = await sendRequest(`sqs/reassignments?${queryParams.toString()}`, 'GET', null)
		return result
	}

	const getPendingReassignmentCount = async () => {
		const result = await sendRequest('sqs/counters/pending-reassignments', 'GET', null, false)
		return result
	}

	const handleReassignmentRequest = async (reassignmentId, assignTo, status) => {
		const result = await sendRequest(`sqs/reassignments/${reassignmentId}`, 'PUT', {
			to: assignTo,
			status,
		})
		return result
	}

	const getNotificationStatus = async (rfqId, displaySpinner = false) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(
			`sqs/rfqs/${rfqId}/notification-status`,
			'GET',
			null,
			displaySpinner
		)
		return response
	}

	const sendTnC = async (rfqId, reqBody) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfqs/${rfqId}/notify/RFQ:TC`, 'POST', reqBody)
		return response
	}

	const fetchQuotes = async rfqId => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/quotes/${rfqId}`, 'GET')
		return response
	}

	const downloadPDF = async rfqId => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfq/pdf/${rfqId}`, 'GET')
		return response
	}

	const triggerDraftDataCollection = async draftId => {
		if (!draftId) {
			return null
		}
		const response = await sendRequest(
			`sqs/draftRfq/${draftId}/trigger-detail-collection`,
			'POST',
			null
		)
		return response
	}

	const answerInsurerRequirements = async (quoteId, reqBody) => {
		if (!quoteId) {
			return null
		}
		const response = await fetch.sendRequest(`sqs/quotes/${quoteId}/requirements`, 'POST', reqBody)
		return response
	}

	const toggleWhatsAppConsent = async (rfqId, payload) => {
		if (!rfqId) {
			return null
		}
		const response = await sendRequest(`sqs/rfqs/${rfqId}/set-whatsapp-consent`, 'PUT', payload)
		return response
	}

	const sendNotificationToClient = async (rfqId, notifTemplate, reqBody) => {
		if (!rfqId || !notifTemplate) {
			return null
		}
		const response = await sendRequest(
			`sqs/rfqs/${rfqId}/notify/${notifTemplate}`,
			'POST',
			reqBody,
			true,
			false
		)
		return response
	}

	const getPerformanceMetric = async (period = 'CURRENT_MONTH') => {
		const response = await sendRequest(`sqs/performance-analysis/${period}`, 'GET')
		return response
	}

	const getIncidentStats = async (city, state) => {
		const queryParams = new URLSearchParams()
		if (city) queryParams.set('city', city)
		if (state) queryParams.set('state', state)
		const response = await sendRequest(
			`sqs/static/incident_stats?${queryParams}`,
			'GET',
			null,
			true, // show loader
			true, // handle timeout
			true // hide error popup
		)
		return response
	}

	const reactivateRfq = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/reactivate`, 'PUT')
		return result
	}

	const retriggerCalculatorsForRfq = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/retrigger-calculators`, 'POST')
		return result
	}

	const getRfqOwner = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/owner`, 'GET')
		return result
	}

	const getOccupancies = async rfqId => {
		if (!rfqId) {
			return null
		}
		const result = await sendRequest(`sqs/rfqs/${rfqId}/occupancies`, 'GET', null, false)
		return result
	}

	const getInstantQuoteOccupancies = async () => {
		const result = await sendRequest(`sqs/static/instant_quote_occupancies`, 'GET', null, false)
		return result
	}

	const selectQuote = async (rfqId, quoteId) => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/select-quote`, 'PUT', { quoteId })
		return result
	}

	const getCustomerDetails = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/customer-details`, 'GET')
		return result
	}

	const updateCustomerInfo = async (rfqId, payload) => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/customer-details`, 'PUT', payload)
		return result
	}

	const getPaymentLink = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/payment-link`)
		return result
	}

	const getPaymentInfo = async rfqId => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/payment-details`, 'GET')
		return result
	}

	const updateAddonSelection = async (rfqId, quoteId, rejectedAddons) => {
		const result = await sendRequest(`sqs/rfqs/${rfqId}/quotes/${quoteId}/addons`, 'PUT', {
			rejected: rejectedAddons,
		})
		return result
	}

	const getHardwareProducts = async () => {
		const result = await sendRequest(`sqs/static/hardware_products`, 'GET')
		return result
	}

	const getRenewalsPolicyData = async (
		toSearch,
		type = 'phone', // phone or lead
		showLoader = true,
		handleTimeout = true,
		hideErrorPopup = false
	) => {
		if (!toSearch || !type) {
			return
		}
		const queryParams = new URLSearchParams()
		if (type === 'phone') queryParams.set('phone', toSearch)
		if (type === 'lead') queryParams.set('leadId', toSearch)

		if (showLoader) {
			setLoadingState({ status: showLoader, message: `Searching ${type}...` })
		}

		const result = await sendRequest(
			`sqs/policy-details?${queryParams}`,
			'GET',
			null,
			false,
			handleTimeout,
			hideErrorPopup
		)
		return result
	}

	const createRenewalDraft = async (
		oldRfqId,
		body = {},
		showLoader = true,
		handleTimeout = true,
		hideErrorPopup = false
	) => {
		if (!oldRfqId) {
			return
		}

		if (showLoader) {
			setLoadingState({ status: showLoader, message: `Generating Renewals Draft RFQ...` })
		}

		const result = await sendRequest(
			`sqs/draft-from-rfq/${oldRfqId}`,
			'POST',
			body,
			false,
			handleTimeout,
			hideErrorPopup
		)
		return result
	}

	const getInstantQuoteInsurers = async () => {
		const result = await sendRequest('sqs/static/instant_quote_insurers', 'GET', null)
		return result
	}

	const sendRenewalInsurerChoiceNotif = async (rfqId, preferredInsurer) => {
		if (!rfqId || !preferredInsurer) return null

		const reqBody = {
			PreferredInsurer: preferredInsurer,
		}
		const result = await sendRequest(`sqs/rfqs/${rfqId}/renewal-purchase`, 'POST', reqBody)
		return result
	}

	return {
		sendRequest,
		saveQuote,
		getQuote,
		getAgentList,
		raiseReassignmentRequest,
		getPendingReassignmentCount,
		getPendingReassignmentRequests,
		handleReassignmentRequest,
		getNatureOfStock,
		getSubCategoryByRiskType,
		sendEmailConfirmation,
		sendRfqEmailPilot,
		abandonRfqPilot,
		getSignedUrl,
		uploadBlobToSignedUrl,
		mergeRiskLocations,
		getReqWithToken,
		getClientDetails,
		getOngoingRFQs,
		getConnectedContacts,
		getLeadData,
		assignSelfServeRfq,
		cachedData,
		trackRfqEvent,
		getLocationsWithPIN,
		resubmitRfqOnUpdate,
		triggerNotification,
		handleAbandonedQuotes,
		sendFireBrochure,
		escalateRfq,
		checkContactExists,
		getAssociatedAccounts,
		getNotifications,
		markNotificationAsSeen,
		markAllNotificationsAsSeen,
		registerEventListener,
		createAccount,
		createRfq,
		// getCustomerInfo,
		updateCustomerDetails,
		getYoutubeVideos,
		sendNotificationWithoutRfq,
		getCaseStudies,
		getDraftRFQList,
		searchDraftRfqByQuery,
		getDraftRFQ,
		createDraftRFQ,
		updateDraftRFQ,
		deleteDraftRfq,
		deleteRfq,
		archiveRfq,
		getFBCampaign,
		getWABots,
		getRFQData,
		triggerWAAddressPing,
		getNearestCustomerInfo,
		sendNearestCustInfo,
		searchRFQbyQuery,
		sendWebsiteLink,
		getNotificationStatus,
		sendTnC,
		fetchQuotes,
		downloadPDF,
		triggerDraftDataCollection,
		answerInsurerRequirements,
		toggleWhatsAppConsent,
		sendNotificationToClient,
		getPerformanceMetric,
		getIncidentStats,
		reactivateRfq,
		retriggerCalculatorsForRfq,
		getRfqOwner,
		getOccupancies,
		getInstantQuoteOccupancies,
		selectQuote,
		getCustomerDetails,
		updateCustomerInfo,
		getPaymentLink,
		getPaymentInfo,
		updateAddonSelection,
		getHardwareProducts,
		getRenewalsPolicyData,
		createRenewalDraft,
		getInstantQuoteInsurers,
		sendRenewalInsurerChoiceNotif,
	}
}
