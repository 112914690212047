import { nanoid } from 'nanoid'
import md5 from 'js-md5'

/**
 * @param {Object} frontendSchema - Frontend schema
 * @returns {Object} Backend schema
 */
const convertToBackendSchema = (frontendSchema = {}) => {
	const schemaCopy = JSON.parse(JSON.stringify(frontendSchema))
	const RiskLocations = schemaCopy.RiskLocations

	let backendSchema = {
		...schemaCopy,
	}

	let locationListing = []
	if (RiskLocations && RiskLocations.length > 0) {
		locationListing = [...RiskLocations]
	}

	let individualRiskLocations = []
	if (locationListing.length > 0) {
		locationListing.forEach(item => {
			const isBasement = item.Type === 'BASEMENT'
			const isProperty = item.Type === 'PROPERTY'
			const isPropertyBasement = item.Type === 'PROPERTYBASEMENT'
			const { levels, label, ...rItem } = item
			let addressHash = ''
			if (rItem.AddressLine1) {
				if (!rItem.AddressLine2) {
					rItem.AddressLine2 = ''
				}
				if (!rItem.PIN) {
					rItem.PIN = ''
				}
				addressHash = md5(`${rItem.AddressLine1},${rItem.AddressLine2},${rItem.PIN}`)
			} else if (isPropertyBasement) {
				let AddressLine2 = '',
					PIN = ''

				if (rItem.property && rItem.property.AddressLine1) {
					if (rItem.property.AddressLine2) {
						AddressLine2 = rItem.property.AddressLine2
					}

					if (rItem.property.PIN) {
						PIN = rItem.property.PIN
					}

					addressHash = md5(`${rItem.property.AddressLine1},${AddressLine2},${PIN}`)
				} else if (rItem.basement && rItem.basement.AddressLine1) {
					if (rItem.basement.AddressLine2) {
						AddressLine2 = rItem.basement.AddressLine2
					}

					if (rItem.basement.PIN) {
						PIN = rItem.basement.PIN
					}
					addressHash = md5(`${rItem.basement.AddressLine1},${AddressLine2},${PIN}`)
				}
			}
			if (isBasement || isProperty) {
				if (levels && levels.length > 0) {
					levels.forEach(lItem => {
						individualRiskLocations.push({
							...rItem,
							...lItem,
							GroupHash: addressHash,
							SubType: 'Single',
							Level: parseInt(lItem.Level),
						})
					})
				} else {
					individualRiskLocations.push({
						...item,
						GroupHash: addressHash,
						Level: parseInt(item.Level),
					})
				}
			} else if (isPropertyBasement) {
				// extra check
				if (rItem.basement) {
					const { levels: bLevels, ...bItem } = rItem.basement
					if (bLevels && bLevels.length > 0) {
						bLevels.forEach(bLItem => {
							individualRiskLocations.push({
								...bItem,
								...bLItem,
								ID: bLItem.ID ? bLItem.ID : nanoid(),
								GroupHash: addressHash,
								SubType: 'Single',
								Level: parseInt(bLItem.Level),
							})
						})
					} else {
						individualRiskLocations.push({
							...bItem,
							ID: bItem.ID ? bItem.ID : nanoid(),
							GroupHash: addressHash,
							Level: parseInt(bItem.Level),
						})
					}
				}

				// extra check
				if (rItem.property) {
					const { levels: pLevels, ...pItem } = rItem.property
					if (pLevels && pLevels.length > 0) {
						pLevels.forEach(pLItem => {
							individualRiskLocations.push({
								...pItem,
								...pLItem,
								ID: pLItem.ID ? pLItem.ID : nanoid(),
								GroupHash: addressHash,
								SubType: 'Single',
								Level: parseInt(pLItem.Level),
							})
						})
					} else {
						individualRiskLocations.push({
							...pItem,
							ID: pItem.ID ? pItem.ID : nanoid(),
							GroupHash: addressHash,
							Level: parseInt(pItem.Level),
						})
					}
				}
			}
		})
	}

	backendSchema.RiskLocations = [...individualRiskLocations]

	return backendSchema
}

export default convertToBackendSchema
