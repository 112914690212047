import { HorizontalDivider, ImageWithText, InfoText } from '../../components/contentUtils'

import Chori from '../../../../../assets/training/Chori.svg'
import Aag from '../../../../../assets/training/Aag.svg'
import Danga from '../../../../../assets/training/Danga.svg'
import Dakaiti from '../../../../../assets/training/Dakaiti.svg'
import Baadh from '../../../../../assets/training/Baadh.svg'
import Aatank from '../../../../../assets/training/Aatank.svg'
import Dharna from '../../../../../assets/training/Dharna.svg'
import Visphot from '../../../../../assets/training/Visphot.svg'
import Aandhi from '../../../../../assets/training/Aandhi.svg'
import Bhukamp from '../../../../../assets/training/Bhukamp.svg'
import Bhuskhalan from '../../../../../assets/training/Bhuskhalan.svg'
import PaniKiTankiFatna from '../../../../../assets/training/PaniKiTankiFatna.svg'

export default function CheatSheet_WIC() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="d-flex flex-column justify-content-center">
				<InfoText fontWeight="700" fontSize="16px" className="text-center" spaced>
					IMPORTANT
				</InfoText>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Chori} text="चोरी" />
					<ImageWithText image={Dharna} text="धरना" />
					<ImageWithText image={Danga} text="दंगा" />
				</div>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Dakaiti} text="डकैती" />
					<ImageWithText image={Visphot} text="विस्फोट" />
					<ImageWithText image={Aatank} text="आतंक" />
				</div>
			</div>

			<HorizontalDivider />

			<div className="d-flex flex-column justify-content-center">
				<InfoText fontWeight="700" fontSize="16px" className="text-center" spaced>
					IMPORTANT
				</InfoText>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Aag} text="आग" />
					<ImageWithText image={Baadh} text="बाढ़" />
					<ImageWithText image={Aandhi} text="आंधी" />
				</div>
				<div className="d-flex my-3 justify-content-around">
					<ImageWithText image={Bhukamp} text="भुकंप" />
					<ImageWithText image={Bhuskhalan} text="भूस्खलन" />
					<ImageWithText
						image={PaniKiTankiFatna}
						text={
							<>
								पानी की <br /> टंकी फटना
							</>
						}
					/>
				</div>
			</div>
		</div>
	)
}
