import { theme, Text, Button } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../components/SecondaryNavbar'
import TimelineView from '../../../components/Timeline'
import { useHistory, useLocation } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../../context/Provider'
import { useFetch } from '../../../api/apihook'

import TimelineWarningIcon from '../../../assets/icons/timelineWarningIcon.svg'

export default function UnderwritingReviewPending() {
	const history = useHistory()

	const { state } = useLocation()

	const { getQuote, mergeRiskLocations } = useFetch()
	const [, dispatch] = useContext(Context)

	// similar flow to 'resume quote'
	const seeRFQDetails = async () => {
		if (!state?.uid) {
			return
		}

		// get rfq data by uid
		let rfqData
		try {
			rfqData = await getQuote(state.uid)
		} catch (err) {
			console.log('ERR: failed to load RFQ')
		}

		const toMerge = {
			...rfqData,
			quoteId: state.uid,
		}
		const mergedData = mergeRiskLocations(toMerge)

		// set locationState context
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'RESUME_QUOTE',
				data: mergedData,
			})
			resolve()
		})

		if (mergedData.RiskLocations.length < mergedData.NumRiskLoc) {
			// add initial locations
			const count = mergedData.NumRiskLoc - mergedData.RiskLocations.length
			for (let index = 0; index < count; index++) {
				dispatch({
					type: 'ADD_NEW_RISK_LOCATION',
				})
			}
		}

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_QUOTE_ID',
				data: {
					id: state?.uid,
				},
			})
			resolve()
		})

		if (state?.locationID !== '') {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE_LOCATION',
					data: {
						locationId: state?.locationID,
					},
				})
				resolve()
			})
		}

		// the final review route should take the user to this route after the updates are done
		// instead of continuing the normal flow

		const flowCompletionRoutes = {
			finalRoute: '/note-for-insurer',
			redirectRoute: '/rfq-status/update-rfq-success',
		}
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_FLOW_COMPLETION_ROUTES',
				data: { flowCompletionRoutes },
			})
			resolve()
		})

		// set update flow as true
		await new Promise(resolve => {
			dispatch({
				type: 'SET_EDIT_RFQ',
				data: { editRfqMode: true },
			})
			resolve()
		})

		// set old rfq
		await new Promise(resolve => {
			dispatch({
				type: 'SET_OLD_RFQ',
				data: JSON.parse(JSON.stringify({ ...mergedData })),
			})
			resolve()
		})

		// navigate to RL selection screen
		history.push('/journey/risk-location-selection', {
			from: 'rfq-progress-screen',
		})
	}

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Overview, {state?.uid} - {state?.ClientName} - {state?.PolicyType}
		</Text>
	)

	const timelineItems = [
		{
			id: 'rfq-completion',
			icon: (
				<img
					style={{ marginLeft: '-3px', marginTop: '-2px' }}
					src={TimelineWarningIcon}
					alt="warning"
				/>
			),
			content: (
				<div className="full-flex-container">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							RFQ completion
						</Text>
						<div className="d-flex mt-2">
							<Text color={theme.colors.secondary} fontSize="12px" fontWeight="400">
								Underwriter review pending, you will be notified if any changes are required.
							</Text>
						</div>
					</div>
					<Button
						style={{ height: '56px' }}
						label="See RFQ details"
						icon="rightArrow"
						iconAlign="right"
						onClick={seeRFQDetails}
					/>
				</div>
			),
		},
		// {
		// 	disabled: true,
		// 	content: (
		// 		<div className="d-flex flex-column">
		// 			<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
		// 				Client verification
		// 			</Text>
		// 			<div className="d-flex mt-2">
		// 				<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
		// 					Awaiting RFQ completion
		// 				</Text>
		// 			</div>
		// 		</div>
		// 	),
		// },
		{
			id: 'quote-generation',
			disabled: true,
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Quotes generation
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Awaiting update
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			),
		},
		{
			id: 'purchase',
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Pending
						</Text>
					</div>
				</div>
			),
		},
	]

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			// style={{ height: '100%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
					borderRadius: '8px',
					width: '95%',
				}}
				className="container h-100 bg-white p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.yellow}>
					RFQ is in review by the underwriter
				</Text>

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)
}
