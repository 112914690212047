import {
	Container,
	selectStyle,
	secondarySelectStyle,
	textStyle,
	buttonStyle,
} from './DetailsCollectionTemplate'
import { Space, Text, theme, Button, Radio, Divider, SearchableSelect } from 'verak-ui'

import { useFetch } from '../../../api/apihook'
import { useState, useEffect } from 'react'

const ThirdPartyStorage = ({
	details,
	setValueOnIndex,
	decrementNature,
	thirdPartyStock,
	incrementNature,
	thirdPartyStockPresent,
	thirdPartyStockAbsent,
	primaryContainerStyle = { border: '1px solid #02475E' },
}) => {
	const prefilledOptions = details => {
		let options = []
		details.forEach(nature => {
			options.push({
				name: nature,
				value: nature,
			})
		})
		return options
	}

	const [natureOfStockMap, setNatureOfStockMap] = useState(prefilledOptions(details))
	const { getNatureOfStock } = useFetch()

	const prepareNatureOfStockMap = async () => {
		const natureOfStock = await getNatureOfStock()
		setNatureOfStockMap(
			natureOfStock.required.map(({ Description }) => {
				return {
					name: Description,
					value: Description,
				}
			})
		)
	}

	useEffect(() => {
		prepareNatureOfStockMap()
	}, []) // eslint-disable-line

	const onValueSelection = (index, event) => {
		setValueOnIndex(index, event)
	}

	const typesEle = details?.map((el, idx) => (
		<div key={idx}>
			{' '}
			{/* because of this line deletion occurs correctly - "key" */}
			<Space y={1} />
			<div className="flex-container">
				<SearchableSelect
					options={natureOfStockMap}
					style={{
						...selectStyle,
						...secondarySelectStyle,
					}}
					customStyle={{
						'@media(min-width: 768px)': {
							width: '360px !important',
						},
					}}
					value={el}
					onChange={value => onValueSelection(idx, value)}
					placeholder="Nature of Stock"
				/>
				<Space x={1.25} />
				{idx !== 0 ? (
					<Button
						icon="remove"
						bgColor={theme.colors.white}
						style={buttonStyle}
						onClick={() => decrementNature(idx)}
					/>
				) : null}
			</div>
		</div>
	))

	let thirdPartyStockEle =
		thirdPartyStock === true ? (
			<>
				<Divider top={0.5} bottom={1.5} />
				<Text style={{ ...textStyle, fontWeight: 500 }} color={theme.colors.gray7}>
					Please specify the nature of 3rd party stock stored at this location:
				</Text>
				{typesEle}
				<Space y={1.5} />
				<Button
					label="Add another stock type"
					textColor={theme.colors.primary}
					bgColor={theme.colors.white}
					icon="add"
					iconStyles={{ marginRight: '0.5rem' }}
					style={buttonStyle}
					onClick={incrementNature}
				/>
			</>
		) : null

	return (
		<Container style={primaryContainerStyle}>
			<Text style={textStyle} color={theme.colors.primary}>
				3rd party storage details
			</Text>
			<Space y={1.5} />

			<Text style={{ ...textStyle, fontWeight: 500 }} color={theme.colors.gray7}>
				Is stock owned by 3rd parties also stored at this location?
			</Text>

			<div className="flex-container">
				<Radio
					text="Yes"
					name="thirdPartyOwned"
					value={thirdPartyStock === true}
					onChange={thirdPartyStockPresent}
				/>
				<Space x={3} />
				<Radio
					text="No"
					name="thirdPartyOwned"
					value={thirdPartyStock === false}
					onChange={thirdPartyStockAbsent}
				/>
			</div>

			{thirdPartyStockEle}
		</Container>
	)
}

export default ThirdPartyStorage
