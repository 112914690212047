import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { convertToNumber } from '../../../../utilities/convertToNumber'

import { useFetch } from '../../../../api/apihook'

import { Button, Input, Space, Text, theme, SearchableSelect } from 'verak-ui'
import { filterArrayObjects } from '../../../../utilities/filterArrayObjects'

const SecondaryBgContainer = styled.div`
	background: ${theme.colors.secondary3};
	border-radius: 0.5rem;
`
const SecondaryBgItem = styled(SecondaryBgContainer)`
	border-radius: 0;
	&:not(:last-of-type) {
		border-bottom: 1px solid ${theme.colors.gray4};
	}
`

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`
const compulsoryMarker = css`
	&::after {
		content: '*';
		color: ${theme.colors.red};
	}
`
const SearchableSelectLabel = styled(Text)`
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: ${theme.colors.gray2};
	font-weight: 500;
	margin-bottom: 0.5rem;
	${({ optional }) => (!optional ? compulsoryMarker : null)};
`
const SearchableSelectWrapper = styled.div`
	.select-search {
		width: 100%;
	}
`

const stockType = {
	Type: '',
	AmountClosed: '',
	AmountOpen: '',
}

const isValidAmount = amount => {
	return amount || amount === 0
}

const StockDataItem = ({
	data,
	handleChange,
	typeNum = 1,
	removebtn = false,
	stockList = [],
	removeStockType = () => {},
	...props
}) => {
	const { itemKey, Type, AmountClosed, AmountOpen } = data

	const isAmtClosedNum = isValidAmount(AmountClosed)
	const isAmtOpenNum = isValidAmount(AmountOpen)

	const anyFieldsFilled = Boolean(Type || isAmtClosedNum || isAmtOpenNum)
	const allFieldsFilled = Boolean(Type && isAmtClosedNum && isAmtOpenNum)
	const isSectionIncomplete = anyFieldsFilled && !allFieldsFilled

	const natureStockErr = isSectionIncomplete && !Type
	const stockCoveredValueErr = isSectionIncomplete && !AmountClosed
	const stockOpenValueErr = isSectionIncomplete && !AmountOpen

	const renderRemoveBtn = removebtn ? (
		<Button
			bgColor={theme.colors.white}
			icon="remove"
			label=""
			onClick={removeStockType}
			className="p-0 h-25"
			textColor={theme.colors.primary}
		/>
	) : null

	const handleNumberInput = (itemKey, attribute, e) => {
		const numValue = convertToNumber(e.target.value)
		handleChange(itemKey, attribute, numValue)
	}

	return (
		<SecondaryBgItem className="mt-4 pb-1">
			<div className="d-flex align-items-center justify-content-between">
				<Text
					type="body1"
					fontWeight={700}
					style={{ lineHeight: '0' }}
					color={theme.colors.secondary}
				>
					Stock Type {typeNum}
				</Text>
				<Space x={1} />
				{renderRemoveBtn}
			</div>

			<GridContainer className="mt-4 mb-3">
				<SearchableSelectWrapper>
					<SearchableSelectLabel>Nature of stock</SearchableSelectLabel>
					<SearchableSelect
						key={stockList.length}
						style={{ width: '100%' }}
						options={stockList}
						value={Type}
						onChange={val => handleChange(itemKey, 'Type', val)}
						search
						placeholder="From Spontaneous Combustion List  - AIFT 2021 PDF"
						error={natureStockErr}
					/>
				</SearchableSelectWrapper>
				<Space moblie={false} />
				<Input
					className="w-100"
					image="stockCovered"
					label="Value of stock covered"
					value={AmountClosed}
					onChange={e => handleNumberInput(itemKey, 'AmountClosed', e)}
					placeholder="Enter value here"
					autoComma
					rupee
					compulsory
					error={stockCoveredValueErr}
				/>
				<Input
					className="w-100"
					image="stockInOpen"
					label="Value of stock kept in open"
					value={AmountOpen}
					onChange={e => handleNumberInput(itemKey, 'AmountOpen', e)}
					placeholder="Enter value here"
					autoComma
					rupee
					compulsory
					error={stockOpenValueErr}
				/>
			</GridContainer>
		</SecondaryBgItem>
	)
}

export default function StockData({ onSave, StockList, isActiveTab, disableProceed, levelIndex }) {
	const [stockTypes, setStockTypes] = useState(() => {
		return StockList?.length
			? [
					...StockList.map(item => {
						return {
							itemKey: nanoid(),
							...item,
						}
					}),
			  ]
			: [{ ...stockType, itemKey: nanoid() }]
	})
	const { getNatureOfStock } = useFetch()
	const [stockList, setStockList] = useState([])

	useEffect(() => {
		const fetchStockList = async () => {
			const result = await getNatureOfStock()
			const stockNatureMap =
				result?.required.map(({ Description }) => {
					return {
						name: Description,
						value: Description,
					}
				}) || []
			setStockList(stockNatureMap)
		}

		fetchStockList()
	}, []) // eslint-disable-line

	useEffect(() => {
		const triggerSave = () => {
			let filteredStockItems = stockTypes.filter(el => {
				const isAmtClosedNum = isValidAmount(el.AmountClosed)
				const isAmtOpenNum = isValidAmount(el.AmountOpen)

				return el.Type || isAmtClosedNum || isAmtOpenNum
			})

			const filteredCopy = JSON.parse(JSON.stringify(filteredStockItems))
			filteredStockItems = filterArrayObjects(filteredCopy, ['itemKey'])
			onSave({
				Stock: filteredStockItems,
			})
		}
		triggerSave()

		if (isActiveTab) {
			const incompleteItems = stockTypes.some(el => {
				const isAmtClosedNum = isValidAmount(el.AmountClosed)
				const isAmtOpenNum = isValidAmount(el.AmountOpen)

				const anyItemFilled = el.Type || isAmtClosedNum || isAmtOpenNum
				const allItemsFilled = el.Type && isAmtClosedNum && isAmtOpenNum

				return anyItemFilled && !allItemsFilled
			})
			disableProceed(incompleteItems)
		}
	}, [stockTypes, isActiveTab]) // eslint-disable-line

	const addStockType = () => {
		const itemKey = nanoid()
		setStockTypes(prev => [...prev, { ...stockType, itemKey }])
	}

	const removeStockType = key => {
		const filterdStockTypes = stockTypes.filter(el => el.itemKey !== key)
		setStockTypes(filterdStockTypes)
	}

	const setStockDataItem = (key, name, val) => {
		const alteredItems = stockTypes.map(el => {
			let alteredVal = val
			if (name !== 'Type') {
				if (val || val === 0) {
					alteredVal = parseFloat(val)
				} else {
					alteredVal = ''
				}
			}
			return el.itemKey === key ? { ...el, [name]: alteredVal, itemKey: key } : el
		})
		setStockTypes(alteredItems)
	}

	const renderStockTypes = stockTypes.map((el, itemNo) => (
		<StockDataItem
			typeNum={itemNo + 1}
			key={el.itemKey}
			removebtn={stockTypes.length > 1}
			removeStockType={() => removeStockType(el.itemKey)}
			data={el}
			handleChange={setStockDataItem}
			stockList={stockList}
		/>
	))

	return (
		<>
			{/* <GoodsInTrustCheck value={goodsInTrustPresent} handleChange={setGoodsInTrustPresent} /> */}
			<SecondaryBgContainer className="p-3 mb-3">
				{renderStockTypes}
				<Button
					bgColor="transparent"
					icon="add"
					iconStyles={{
						marginRight: '0.5rem',
					}}
					label="Add another stock type"
					onClick={addStockType}
					className="p-2 mt-3 mb-1"
					textColor={theme.colors.primary}
				/>
			</SecondaryBgContainer>
			{/* <Divider />
			<GoodsOwnership
				type="property"
				ownerDetails={ownerDetails}
				setOwnerDetails={setOwnerDetails}
			/> */}
		</>
	)
}
