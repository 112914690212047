import { Input, Modal, Text, Button, Space, Select, theme } from 'verak-ui'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'

import { useContextApi } from '../../context/Provider'
import { getOccupancyValue } from '../RiskLocationReview/reviewScreenUtils'
import { useFetch } from '../../api/apihook'
import 'react-toastify/dist/ReactToastify.css'
import { validateEmail } from '../../utilities/validateEmail'
import PrimaryOccupancySelector from '../DraftRFQ/components/PrimaryOccupancySelector'
import { useAuth0 } from '@auth0/auth0-react'
import { editDetailsAccess } from '../../utilities/authorizedAccounts'

const removeCountryCode = phone => {
	if (phone?.startsWith('+91')) {
		return phone.replace('+91', '')
	}
	return phone
}

export default function EditClientDetailsModal({
	show,
	handleClose,
	locationData,
	setSQSUpdateFlow,
}) {
	const [state, dispatch] = useContextApi()
	const { updateCustomerDetails, saveQuote } = useFetch()

	const { user } = useAuth0()

	const contactEditDisabled = !editDetailsAccess.includes(user?.email)

	useEffect(() => {
		if (locationData?.locationId) {
			dispatch({ type: 'MOVE_TO_LOCATION', data: { locationId: locationData.locationId } })
		}
	}, [])

	const currentLocation = state?.location

	let rfqData = state?.rfq
	if (Object.keys(state?.rfq).length === 0) {
		// state.rfq is empty while rfq creation
		const { CompanyName, ProposerName, Phone } = state?.quote?.ClientDetails
		rfqData = {
			EntityName: CompanyName,
			ProposerName,
			Phone,
		}
	}

	const [ProposerName, setProposerName] = useState(rfqData?.ProposerName || '')
	const [EntityName, setLegalEntityName] = useState(rfqData?.EntityName || '')
	const [Email, setEmail] = useState(rfqData?.Email || '')
	const [Phone, setPhone] = useState(removeCountryCode(rfqData?.Phone) || '')
	const [WaPhone, setWaPhone] = useState(removeCountryCode(rfqData?.WaPhone) || '')
	const [clientDetailsEdited, setClientDetailsEdited] = useState(false)
	const [occupancyEdited, setOccupancyEdited] = useState(false)

	const clientDetailsChangeHandle = (key, value) => {
		switch (key) {
			case 'ProposerName':
				setProposerName(value)
				break
			case 'EntityName':
				setLegalEntityName(value)
				break
			case 'Email':
				setEmail(value)
				break
			case 'Phone':
				setPhone(value)
				break
			case 'WaPhone':
				setWaPhone(value)
				break
			default:
		}
		setClientDetailsEdited(true)
	}

	const [occupancyValue, setOccupancyValue] = useState(locationData?.Occupancy || '')

	const titleElement = (
		<Text color={theme.colors.primary} fontSize="14px" fontWeight="600">
			Edit Client Details
		</Text>
	)

	const isProposerNameValid = Boolean(ProposerName.length > 0)
	const isLegalEntityNameValid = Boolean(EntityName.length > 0)
	const isPhoneValid = Phone.length === 10 || Phone === ''
	const isWaPhoneValid = WaPhone.length === 10 || WaPhone === ''
	const isEmailValid = validateEmail(Email) || Email === ''

	const disableProceed = !(
		isProposerNameValid &&
		isLegalEntityNameValid &&
		isPhoneValid &&
		isWaPhoneValid &&
		isEmailValid
	)

	let locationString = 'this Location'
	if (!!locationData?.levelNum) {
		const currentLevelNumber = locationData?.levelNum
		const floorOrLevel = currentLevelNumber >= 0 ? 'Floor' : 'Level'
		locationString = `${floorOrLevel} (${currentLevelNumber})`
	}

	const getUpdatedChanges = () => {
		let updatedChanges = {}
		if (ProposerName !== rfqData?.ProposerName) {
			updatedChanges.ProposerName = ProposerName
		}
		if (EntityName !== rfqData?.Name) {
			updatedChanges.LegalEntityName = EntityName
		}
		if (Email !== rfqData?.Email) {
			updatedChanges.Email = Email
		}
		if (Phone !== removeCountryCode(rfqData?.Phone)) {
			updatedChanges.Phone = `+91${Phone}`
		}
		if (WaPhone !== removeCountryCode(rfqData?.WaPhone)) {
			updatedChanges.WaPhone = `+91${WaPhone}`
		}
		return updatedChanges
	}

	// update location occupancy and client details
	const saveChanges = async () => {
		// update a risk location's occupancy
		const updatedOccupancyPayload = {
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			propertyType: currentLocation?.Type,
			levelIndex: locationData?.levelNum,
			data: {
				PrimaryOccupancy: {
					...currentLocation?.PrimaryOccupancy,
					SubCategory: occupancyValue,
				},
			},
		}

		if (occupancyEdited) {
			// trigger edit rfq flow
			setSQSUpdateFlow()

			// update occupancy in global state
			await new Promise(resolve => {
				dispatch(updatedOccupancyPayload)
				resolve()
			})
			await new Promise(resolve => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES',
				})
				resolve()
			})

			// save rfq changes
			await saveQuote()
		}

		if (clientDetailsEdited) {
			// update customer details and update state
			const updatedDetails = getUpdatedChanges()
			const result = await updateCustomerDetails(state?.quote?.quoteId, updatedDetails)

			if (!result?.error) {
				dispatch({
					type: 'SET_RFQ_DATA',
					data: {
						...state?.rfq,
						ProposerName,
						Email,
						EntityName,
						Phone: Phone ? `+91${Phone}` : '',
						WaPhone: WaPhone ? `+91${WaPhone}` : '',
					},
				})
			}
		}
		toast.success('Details updated successfully')
		handleClose()
	}

	return (
		<>
			<Modal show={show} handleClose={handleClose} title={titleElement} footer={<></>}>
				<div className="w-100">
					<Section className="mb-4">
						<Text color={theme.colors.primary} fontSize="16px" fontWeight="700" className="mb-3">
							Details specific to this location
						</Text>

						<PrimaryOccupancySelector
							value={occupancyValue}
							onChange={(key, value) => {
								setOccupancyValue(value.SubCategory)
								setOccupancyEdited(true)
							}}
						/>
						<BlueBanner className="mt-3">
							Change in type of business is only specific to {locationString}
						</BlueBanner>
					</Section>

					<Section>
						<Text color={theme.colors.primary} fontSize="16px" fontWeight="700">
							Details common for all locations
						</Text>
						<Space y={1} />
						<Input
							value={ProposerName}
							onChange={e => clientDetailsChangeHandle('ProposerName', e.target.value)}
							className="w-100"
							label="Proposer's Name (Optional)"
							placeholder="Enter new Proposer Name"
							errorLabel={!isProposerNameValid && 'Proposer Name is required'}
						/>
						<Space y={1} />
						<Input
							value={EntityName}
							onChange={e => clientDetailsChangeHandle('EntityName', e.target.value)}
							className="w-100"
							label="Legal Entity Name (Optional)"
							placeholder="Enter new Legal Entity Name"
							errorLabel={!isLegalEntityNameValid && 'Legal Entity Name is required'}
						/>
						<Space y={1} />
						<Input
							value={Email}
							onChange={e => clientDetailsChangeHandle('Email', e.target.value)}
							className="w-100"
							label="Email (Optional)"
							placeholder="Enter Email ID"
							errorLabel={!isEmailValid && 'Please enter a valid Email ID'}
						/>
						<Space y={1} />
						<Input
							value={Phone}
							onChange={e => {
								if (!isNaN(e.target.value)) {
									clientDetailsChangeHandle('Phone', e.target.value)
								}
							}}
							className="w-100"
							label="Contact Number (Optional)"
							disabled={contactEditDisabled}
							placeholder="Enter new Phone number"
							maxLength={10}
							errorLabel={!isPhoneValid && 'Please enter a valid Phone Number'}
						/>
						<Space y={1} />
						<Input
							value={WaPhone}
							onChange={e => {
								if (!isNaN(e.target.value)) {
									clientDetailsChangeHandle('WaPhone', e.target.value)
								}
							}}
							maxLength={10}
							className="w-100"
							label="WhatsApp Number (Optional)"
							placeholder="Enter new WhatsApp number"
							disabled={contactEditDisabled}
							errorLabel={!isWaPhoneValid && 'Please enter a valid Whatsapp Number'}
						/>
						<Space y={1} />
						<BlueBanner>
							Edited Name and contact number will be used in all policy documents being issued.
							Please make the edit only if this is required.
						</BlueBanner>
					</Section>

					<Space y={1} />
					<Button
						disabled={disableProceed}
						style={{ height: '3rem' }}
						label="Save Changes"
						primary
						className="w-100"
						onClick={saveChanges}
					/>
				</div>
			</Modal>
			<ToastContainer />
		</>
	)
}

const BlueBanner = styled.div`
	background: #d3defd;
	padding: 5px 5px 5px 12px;
	font-weight: 500;
	font-size: 12px;
	color: #244bce;
	border-radius: 4px;
`

const Section = styled.div`
	padding: 16px 12px;
	background: #f3f3f3;
	border-radius: 4px;
`
