import { useState, useEffect } from 'react'
import { useFetch } from '../../../api/apihook'
import { useContextApi } from '../../../context/Provider'
import AccountSelectionModal from '../../JourneyStart/AccountSelectionModal'

const ExistingClientSelectionFlow = ({
	currentAccountData,
	setAccount,
	contact,
	setContact,
	existingClient,
	setExistingClient,
	setAccountId,
	contactId,
	setContactId,
	showAccountSelectionModal,
	closeAccountSelectionModal,
}) => {
	const { getAssociatedAccounts, checkContactExists } = useFetch()
	const [, dispatch] = useContextApi()
	const [accounts, setAccounts] = useState([])
	const [selectedAccount, setSelectedAccount] = useState(null)

	const selectAccount = (account, isNewlyCreatedNotSelected) => {
		const { Name, id, UID } = account
		// select the account in the account selection popup
		setSelectedAccount(account)
		// if the id of the selected account is not one of these, it is the account id of the existing client
		let accountId = ''
		if (existingClient && !['proposerNameSelection', 'clientAddition'].includes(id)) {
			accountId = id
		}
		setAccountId(accountId)
		setAccount(prevAccount => ({
			...prevAccount,
			Name,
			UID,
		}))
		closeAccountSelectionModal()
	}

	const fetchAccounts = async existingContactId => {
		let associatedAccounts = await getAssociatedAccounts(existingContactId)
		associatedAccounts = associatedAccounts.map(account => ({
			...account,
			UID: account.Connections?.CRM || '',
		}))
		setAccounts(associatedAccounts)
		return associatedAccounts
	}

	useEffect(() => {
		const checkForExistingContactAndAccount = async () => {
			let clientPhone = contact?.Phone?.replace('+91', '')
			const {
				contactId: existingContactId,
				name,
				contactCrmId: contactUID,
			} = await checkContactExists(clientPhone)
			if (!!existingContactId) {
				setExistingClient(true)
				setContactId(existingContactId)
				setContact(prevContact => ({
					...prevContact,
					Name: name,
					UID: contactUID,
				}))

				dispatch({
					type: 'SET_CLIENT_EXISTS',
					data: { clientExists: true },
				})

				// fetch only if no accounts in list
				if (!accounts?.length) {
					let selectedAccountData = {
						id: '',
						Name: currentAccountData?.Name,
						UID: currentAccountData?.UID || '',
					}
					let accountList = await fetchAccounts(existingContactId)
					// check if company name already presnet in accountList
					const index = accountList.findIndex(item => {
						return item.Name === currentAccountData?.Name
					})
					if (index > -1) {
						const accountItem = accountList[index]
						setAccountId(accountItem.id)
						selectedAccountData = {
							id: accountItem.id,
							Name: accountItem.Name,
							UID: accountItem.Connections?.CRM || '',
						}
					} else {
						// add it and select it in accountselection popup
						setAccountId('')
						selectedAccountData.id = 'clientAddition'
						setAccounts(prevAccounts => [...prevAccounts, selectedAccountData])
					}
					// select the relevant account in the popup
					setSelectedAccount(selectedAccountData)
					// update the account details
					setAccount(prevAccount => ({
						...prevAccount,
						Name: selectedAccountData.Name,
						UID: selectedAccountData.UID,
					}))
				}
			} else {
				setExistingClient(false)
			}
		}
		checkForExistingContactAndAccount()
	}, [])

	if (showAccountSelectionModal) {
		return (
			<AccountSelectionModal
				show={true}
				handleClose={closeAccountSelectionModal}
				contactId={contactId}
				selectAccount={selectAccount}
				existingClient={existingClient}
				isAccountSelected={true}
				proposerName={contact?.Name}
				account={selectedAccount}
				associatedAccounts={accounts}
			/>
		)
	}
	return null
}

export default ExistingClientSelectionFlow
