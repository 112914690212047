import { useHistory, useLocation } from 'react-router-dom'
import { Row, Container } from 'react-bootstrap'
import styled from 'styled-components'

import { MainContainer, Divider, Alert, Text, theme, Icon } from 'verak-ui'

import basementIcon from '../../assets/icons/basement.svg'
import basementPropertyIcon from '../../assets/icons/basement_property.svg'
import propertyIcon from '../../assets/icons/property.svg'
import { Context } from '../../context/Provider'
import { useContext } from 'react'
import { useFetch } from '../../api/apihook'

const PropertyCardWrapper = styled.div`
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${theme.colors.secondary3};
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.3s ease;
	&:hover {
		transform: translate(0px, -2px) scale(1.02);
		box-shadow: ${theme.elevation['2dp']};
	}

	@media (min-width: 768px) {
		height: 300px;
		width: 250px;
		margin-right: 12px;
		margin-left: 12px;
		margin-bottom: 1.5rem;
	}
	@media (max-width: 768px) {
		padding: 16px;
		height: 90px;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 0.8rem;
	}
`

const CardHolder = styled.div`
	display: flex;
	justify-content: space-around;
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}
`

const ImgIcon = styled.img`
	@media (min-width: 768px) {
		margin-bottom: 1rem;
		height: 152px;
		width: 152px;
	}
	@media (max-width: 768px) {
		height: 60px;
		width: 60px;
		margin-right: 0.8rem;
	}
`

const CustomText = styled(Text)`
	@media (max-width: 768px) {
		font-size: 0.9rem;
		text-align: left !important;
	}
`

const InfoContainer = styled.div`
	margin-left: 0.75rem;
	margin-right: 1rem;
	padding-right: 1rem;
	width: min(770px, 100%);
	@media (max-width: 768px) {
		margin: 0;
		padding-right: 1.5rem;
	}
`

const PropertyCheck = () => {
	const fetch = useFetch()
	let history = useHistory()
	const location = useLocation()
	const [state, dispatch] = useContext(Context) //eslint-disable-line
	const goBack = () => {
		dispatch({
			type: 'DONE_WITH_LOCATION_CHANGES',
		})

		let goBackPath = ''
		const returnPath = location?.state?.returnPath
		if (returnPath) {
			goBackPath = returnPath
		} else {
			goBackPath = '/journey/risk-location-selection'
		}
		history.replace(goBackPath)
	}

	const navigate = link => {
		history.push(link)
	}

	const PropertyCard = ({ title, icon, link, type }) => {
		const onTypeSelect = () => {
			dispatch({
				type: 'INIT_LOCATION',
				data: {
					locationType: type,
				},
			})
			navigate(link)
		}

		return (
			<PropertyCardWrapper onClick={onTypeSelect}>
				<ImgIcon src={icon} />
				<CustomText
					style={{ fontWeight: '700' }}
					fontSize="24px"
					color={theme.colors.primary}
					className="text-center"
				>
					{title}
				</CustomText>
			</PropertyCardWrapper>
		)
	}

	const cardList = [
		{
			title: 'Basement only',
			icon: basementIcon,
			link: '/risk-location-details/basement',
			type: 'BASEMENT',
		},
		{
			title: 'Building and Basement',
			icon: basementPropertyIcon,
			link: '/risk-location-details/property-and-basement',
			type: 'PROPERTYBASEMENT',
		},
		{
			title: 'Building only',
			icon: propertyIcon,
			link: '/risk-location-details/property',
			type: 'PROPERTY',
		},
	]

	const renderCardList = cardList.map(el => {
		const { title, icon, link, type } = el
		return <PropertyCard title={title} icon={icon} link={link} type={type} key={type} />
	})

	const currentLocationLabel = state?.location?.label || 'Location'

	let sectionTitle = `Please select the option that best describes ${currentLocationLabel}`
	if (state?.quote?.PolicyKind === 'STOCK_ONLY') {
		sectionTitle = `Where is the stock stored at ${currentLocationLabel}?`
	}

	return (
		<Container>
			<MainContainer header="Risk location details" divider={false} leftOnClick={goBack}>
				<Row>
					<Row className="d-flex align-items-center text-center">
						<Text style={{ fontWeight: 700, fontSize: '24px' }} className="text-center">
							{sectionTitle}
						</Text>
					</Row>

					<Row className="mt-4">
						<CardHolder>{renderCardList}</CardHolder>
					</Row>
				</Row>

				<InfoContainer>
					<Alert className="mb-4" bgColor={theme.colors.secondaryLightRed}>
						<Icon name="infoIcon" color={theme.colors.gray7} />
						<div className="ms-2">
							<Text>
								'Building' refers to any structure above ground. 'Basement' refers to any structure
								below ground.
							</Text>
							<Text>
								Therefore, a 2 storey factory with no underground unit will be treated as ‘Building
								only’
							</Text>
						</div>
					</Alert>
				</InfoContainer>
				<Divider />
			</MainContainer>
		</Container>
	)
}

export default PropertyCheck
