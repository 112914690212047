import styled from 'styled-components'
import { Button, Space, Text, theme } from 'verak-ui'

function ReinstatementModalContent() {
	return (
		<div className="text-center p-2">
			<Text type="body1" color={theme.colors.primary}>
				The reinstatement value is a method of claim settlement under a fire insurance policy.
				<Space y={1} />
				In the case of the reinstatement value clause, the insurance company reinstates the damaged
				property or asset by paying its replacement value as the claim amount to the policyholder.
			</Text>
		</div>
	)
}

function ReinstatementModalFooter({ closeModal }) {
	return (
		<Button
			label="Continue"
			icon="rightArrow"
			iconAlign="right"
			style={{ height: '64px', fontSize: '18px' }}
			className="m-3"
			onClick={closeModal}
		/>
	)
}

const TitleStyles = styled(Text)`
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	color: ${theme.colors.primary};
	padding: 0.5rem;
`

const ReinstatementModalTitle = <TitleStyles>What is Reinstatement value?</TitleStyles>

export { ReinstatementModalTitle, ReinstatementModalContent, ReinstatementModalFooter }
