import { Button, Text, Icon, Radio, Checkbox, theme, Space, Select } from 'verak-ui'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import MaterialDetailsQuestion from './components/MaterialDetailsQuestion'
import { isManufacturingOccupancy } from '../../utilities/checkManufacturing'
import PrimaryOccupancySelector from './components/PrimaryOccupancySelector'
import FormInput from '../../components/FormInput'
import { predefinedSumInsuredFields } from '../PropertyRiskData/SumInsured/components/options'

import AddCustomSIFieldModal from './components/AddCustomSIFieldModal'

import cisImg from '../../assets/icons/cis.svg'
import citImg from '../../assets/icons/cit.svg'
import cicImg from '../../assets/icons/cic.svg'
import { convertToAmount } from '../SalesAmmunition/MultiNudgeContainer/PremiumCalculator'

const wholeBuildingOptions = Array.from({ length: 100 }).map((_i, index) => (
	<option value={index}>{index}</option>
))
const wholeBasementOptions = Array.from({ length: 20 }).map((_i, index) => (
	<option value={index + 1}>{index + 1}</option>
))

const EditField = ({ onEditClick, showEditButton = false, children }) => {
	if (showEditButton) {
		return (
			<div className="w-100 position-relative">
				{children}
				<Button
					className="position-absolute bottom-0 end-0 me-2 mb-1 p-0"
					onClick={onEditClick}
					icon="edit"
					bgColor="rgba(0,0,0,0.0)"
					style={{ zIndex: '1000', top: '2rem' }}
				/>
			</div>
		)
	}
	return children
}

export default function DraftRFQForm({
	riskLocation,
	activeRiskLocation,
	onChange,
	contactInfo,
	accountInfo,
	updateContactInfo,
	updateAccountInfo,
	miAddons,
	CICData,
	CISData,
	CITData,
	updateMIAddons,
	updateCICData,
	updateCISData,
	updateCITData,
	safetyToggle,
	cctvUnits,
	updateCCTVUnits,
	policyInfo,
	setPolicyInfo,
	fetchCityAndState,
	onPolicyKindChange,
	showAsterisk,
	saveDraft,
	draftId,
	onAddressRequest,
	onAddressFetch,
	onAccountEdit,
	existingClient,
	sumInsuredContainerRef,
}) {
	return (
		<RiskLocationForm
			riskLocationData={riskLocation}
			onRiskLocationDataChange={onChange}
			activeRiskLocation={activeRiskLocation}
			contactInfo={contactInfo}
			accountInfo={accountInfo}
			updateContactInfo={updateContactInfo}
			updateAccountInfo={updateAccountInfo}
			miAddons={miAddons}
			CICData={CICData}
			CISData={CISData}
			CITData={CITData}
			updateMIAddons={updateMIAddons}
			updateCICData={updateCICData}
			updateCISData={updateCISData}
			updateCITData={updateCITData}
			safetyToggle={safetyToggle}
			cctvUnits={cctvUnits}
			updateCCTVUnits={updateCCTVUnits}
			policyInfo={policyInfo}
			setPolicyInfo={setPolicyInfo}
			fetchCityAndState={fetchCityAndState}
			onPolicyKindChange={onPolicyKindChange}
			showAsterisk={showAsterisk}
			saveDraft={saveDraft}
			draftId={draftId}
			onAddressRequest={onAddressRequest}
			onAddressFetch={onAddressFetch}
			onAccountEdit={onAccountEdit}
			existingClient={existingClient}
			sumInsuredContainerRef={sumInsuredContainerRef}
		/>
	)
}

const RiskLocationForm = ({
	riskLocationData,
	activeRiskLocation,
	onRiskLocationDataChange,
	contactInfo,
	accountInfo,
	updateContactInfo,
	updateAccountInfo,
	miAddons,
	CICData,
	CISData,
	CITData,
	updateMIAddons,
	updateCICData,
	updateCISData,
	updateCITData,
	safetyToggle,
	cctvUnits,
	updateCCTVUnits,
	policyInfo,
	setPolicyInfo,
	fetchCityAndState,
	onPolicyKindChange,
	showAsterisk,
	saveDraft,
	draftId,
	onAddressRequest,
	onAddressFetch,
	onAccountEdit,
	existingClient,
	sumInsuredContainerRef,
}) => {
	const [isWATriggered, setIsWATriggered] = useState(false)
	const [addressError, setAddressError] = useState(false)
	const [waAddressResponse, setWAAddressResponse] = useState(null)
	const [waAddressFetched, setWAAddressFetched] = useState(null)
	const isSafetyPlan = safetyToggle?.value

	// Safety Plan Questions
	let cctvUnitsQues = null
	const cctvQtyOptions = []
	for (let i = 1; i <= 10; i++) {
		cctvQtyOptions.push(
			<option key={i} value={i}>
				{i}
			</option>
		)
	}
	if (isSafetyPlan) {
		cctvUnitsQues = (
			<FormSubSection style={{ marginTop: '2rem' }}>
				<Label
					style={{ fontSize: '18px', fontWeight: 700 }}
					text="Safety Plan Details"
					compulsory={false}
				/>
				<Label text="Quantity of CCTV Cameras" className="mb-2 mt-2" />
				<Select
					style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
					compulsory
					value={cctvUnits}
					onChange={e => updateCCTVUnits(e)}
				>
					{cctvQtyOptions}
				</Select>
			</FormSubSection>
		)
	}

	// Money Insurance
	const prefillAddress = handleFn => {
		let address = riskLocationData?.AddressLine1 || ''
		if (riskLocationData?.AddressLine2) address += `, ${riskLocationData?.AddressLine2}`
		if (riskLocationData?.City) address += `, ${riskLocationData?.City}`
		if (riskLocationData?.PIN) address += ` - ${riskLocationData?.PIN}`
		if (riskLocationData?.State) address += `, ${riskLocationData?.State}`
		handleFn(address, 'Address')
	}
	const handleAddOns = addOn => {
		let prevItems = [...miAddons]
		if (prevItems.includes(addOn)) {
			prevItems = prevItems.filter(x => x !== addOn)
		} else {
			prevItems.push(addOn)
		}
		updateMIAddons(prevItems)
	}

	const CCTVWarning = (
		<Text color={theme.colors.red}>
			Note: Add on will be rejected without the presence of a CCTV
		</Text>
	)
	let cisComp = null
	const handleCISData = (val, key) => {
		let newValues = { ...CISData }
		newValues[key] = val
		updateCISData(newValues)
	}
	if (miAddons.includes('CIS')) {
		cisComp = (
			<AddonContainer>
				<FormInput
					placeholder="Enter here"
					label="Amount to be insured"
					onChange={e => handleCISData(e.target.value, 'Amount')}
					value={CISData?.Amount}
					compulsory
					autoComma
					rupee
				/>
				<Space y={1} />
				<Label text="Is the safe fully locked/secured?" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CISData?.IsSafeLocked === true}
						onClick={() => handleCISData(true, 'IsSafeLocked')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CISData?.IsSafeLocked === false}
						onClick={() => handleCISData(false, 'IsSafeLocked')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				<Space y={0.5} />
				<FormInput
					placeholder="Eg: Godrej, Valencia, Amazon Basics"
					label="Make / Brand / Type of safe"
					value={CISData?.SafeDetails}
					onChange={e => handleCISData(e.target.value, 'SafeDetails')}
					compulsory
				/>
				<Space y={1} />
				<Label text="Is a CCTV present at the location?" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CISData?.IsCCTVPresent === true}
						onClick={() => handleCISData(true, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CISData?.IsCCTVPresent === false}
						onClick={() => handleCISData(false, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				{CISData?.IsCCTVPresent === false ? CCTVWarning : null}
				<FormInput
					label="Enter Address of the Safe to be insured"
					placeholder="Enter here"
					onChange={e => handleCISData(e.target.value, 'Address')}
					value={CISData?.Address}
					compulsory
				/>
				<Space y={0.5} />
				<Button
					label="Use Risk Location 1 Address"
					onClick={() => prefillAddress(handleCISData)}
					style={{ fontWeight: 500 }}
				/>
			</AddonContainer>
		)
	}

	let citComp = null
	const handleCITData = (val, key) => {
		let newValues = { ...CITData }
		newValues[key] = val
		updateCITData(newValues)
	}

	const [citTotalDisabled, setCITTotalDisabled] = useState(false)
	useEffect(() => {
		if (citTotalDisabled && CITData?.EstAmountPerTransit) {
			const totalAmount = convertToAmount(CITData?.EstAmountPerTransit) * 350
			handleCITData(totalAmount, 'Amount')
		}
	}, [CITData?.EstAmountPerTransit]) // eslint-disable-line

	if (miAddons.includes('CIT')) {
		citComp = (
			<AddonContainer>
				<FormInput
					placeholder="Enter here"
					label="Maximum amount per transit"
					onChange={e => {
						// lock total field on entering EstAmount first time
						if (!CITData?.EstAmountPerTransit) {
							setCITTotalDisabled(true)
						}
						handleCITData(e.target.value, 'EstAmountPerTransit')
					}}
					value={CITData?.EstAmountPerTransit}
					compulsory
					autoComma
					rupee
				/>
				<Space y={1} />
				<EditField showEditButton={citTotalDisabled} onEditClick={() => setCITTotalDisabled(false)}>
					<FormInput
						placeholder="Enter here"
						label="Total amount transferred in a year"
						onChange={e => handleCITData(e.target.value, 'Amount')}
						value={CITData?.Amount}
						disabled={citTotalDisabled}
						compulsory
						autoComma
						rupee
					/>
				</EditField>
				<Space y={1} />
				<FormInput
					placeholder="Eg: Thane"
					label="Carrying from (Start location of journey)"
					value={CITData?.Source}
					onChange={e => handleCITData(e.target.value, 'Source')}
					compulsory
				/>
				<Space y={1} />
				<FormInput
					placeholder="Eg: Mumbai"
					label="Carrying to (End location of journey)"
					value={CITData?.Destination}
					onChange={e => handleCITData(e.target.value, 'Destination')}
					compulsory
				/>
				<Space y={1} />
				<FormInput
					placeholder="Enter here (number only)"
					label="Distance of the transit (up to 25 kms)"
					value={CITData?.DistTransit}
					onChange={e => handleCITData(parseFloat(e.target.value), 'DistTransit')}
					type="number"
					compulsory
				/>
				<Space y={1} />
				<Label text="Vehicle used to transfer cash" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Two Wheeler"
						secondary={true}
						value={CITData?.CarriageMode === 'Two Wheeler'}
						onClick={() => handleCITData('Two Wheeler', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Car"
						secondary={true}
						value={CITData?.CarriageMode === 'Car'}
						onClick={() => handleCITData('Car', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Truck"
						secondary={true}
						value={CITData?.CarriageMode === 'Truck'}
						onClick={() => handleCITData('Truck', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Others"
						secondary={true}
						value={!['Two Wheeler', 'Car', 'Truck'].includes(CITData?.CarriageMode)}
						onClick={() => handleCITData('', 'CarriageMode')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				{!['Two Wheeler', 'Car', 'Truck'].includes(CITData?.CarriageMode) ? (
					<>
						<FormInput
							placeholder="Mention type of Carriage Mode here"
							onChange={e => handleCITData(e.target.value, 'CarriageMode')}
							value={CITData?.CarriageMode}
							compulsory
						/>
					</>
				) : null}
				<Space y={1} />
				<Label text="Who is carrying the cash?" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Owner"
						secondary={true}
						value={CITData?.CarriedBy === 'Owner'}
						onClick={() => handleCITData('Owner', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Employee"
						secondary={true}
						value={CITData?.CarriedBy === 'Employee'}
						onClick={() => handleCITData('Employee', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="Others"
						secondary={true}
						value={!['Owner', 'Employee'].includes(CITData?.CarriedBy)}
						onClick={() => handleCITData('', 'CarriedBy')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				{!['Owner', 'Employee'].includes(CITData?.CarriedBy) ? (
					<>
						<FormInput
							placeholder="Mention type of Carrier"
							onChange={e => handleCITData(e.target.value, 'CarriedBy')}
							value={CITData?.CarriedBy}
							compulsory
						/>
					</>
				) : null}
				<Space y={0.5} />
				<FormInput
					label="Enter Address of the Add on to be insured"
					placeholder="Enter here"
					onChange={e => handleCITData(e.target.value, 'Address')}
					value={CITData?.Address}
					compulsory
				/>
				<Space y={0.5} />
				<Button
					label="Use Risk Location 1 Address"
					onClick={() => prefillAddress(handleCITData)}
					style={{ fontWeight: 500 }}
				/>
			</AddonContainer>
		)
	}

	let cicComp = null
	const handleCICData = (val, key) => {
		let newValues = { ...CICData }
		newValues[key] = val
		updateCICData(newValues)
	}
	if (miAddons.includes('CIC')) {
		cicComp = (
			<AddonContainer>
				<FormInput
					placeholder="Enter here"
					label="Amount to be insured"
					onChange={e => handleCICData(e.target.value, 'Amount')}
					value={CICData?.Amount}
					compulsory
					autoComma
					rupee
				/>
				<Space y={1} />
				<Label text="Is the register fully locked/secured?" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CICData?.IsCounterLocked === true}
						onClick={() => handleCICData(true, 'IsCounterLocked')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CICData?.IsCounterLocked === false}
						onClick={() => handleCICData(false, 'IsCounterLocked')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				<Space y={1} />
				<Label text="Is a CCTV present at the location?" />
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CICData?.IsCCTVPresent === true}
						onClick={() => handleCICData(true, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CICData?.IsCCTVPresent === false}
						onClick={() => handleCICData(false, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				{CICData?.IsCCTVPresent === false ? CCTVWarning : null}
				<FormInput
					label="Enter Address of the Counter to be insured"
					placeholder="Enter here"
					onChange={e => handleCICData(e.target.value, 'Address')}
					value={CICData?.Address}
					compulsory
				/>
				<Space y={0.5} />
				<Button
					label="Use Risk Location 1 Address"
					onClick={() => prefillAddress(handleCICData)}
					style={{ fontWeight: 500 }}
				/>
			</AddonContainer>
		)
	}

	// RFQ level fields
	const handleRiskLocationDataChange = (key, value) => {
		onRiskLocationDataChange({ ...riskLocationData, [key]: value })
	}

	const handleGenericInfoChange = (key, value) => {
		setPolicyInfo(pValue => {
			return { ...pValue, [key]: value }
		})
	}

	const handleStockChange = (fieldName, index, value) => {
		let Stock = {
			...riskLocationData?.Stock,
			Details: [...riskLocationData?.Stock.Details],
		}

		if (Stock.Details.length === 0) {
			Stock.Details = [{ AmountClosed: '', Type: '' }]
		}

		Stock.Present = true
		let selectedStock = Stock.Details[index]

		if (fieldName === 'AmountClosed') {
			value = parseInt(value.split(',').join(''))
			selectedStock['ClosedPer'] = 100
		}
		selectedStock[fieldName] = value // fieldName - either AmountClosed or Type
		handleRiskLocationDataChange('Stock', Stock)
	}

	const handleRiskAssesmentChange = (RiskType, itemsArray, item) => {
		const RiskAssesmentItems = riskLocationData?.[RiskType]?.[itemsArray]

		const updatedRiskAssesmentItems = RiskAssesmentItems.includes(item)
			? RiskAssesmentItems.filter(i => i !== item)
			: [...RiskAssesmentItems, item] // add or remove item from array

		onRiskLocationDataChange({
			...riskLocationData,
			[RiskType]: {
				...riskLocationData?.[RiskType],
				[itemsArray]: updatedRiskAssesmentItems,
			},
		})
	}

	const handleAMCChange = value => {
		onRiskLocationDataChange({
			...riskLocationData,
			FireRisk: {
				...riskLocationData?.FireRisk,
				AMC: value,
			},
		})
	}

	const handleMaterialChange = (material, type, value) => {
		const resetDescription = {}
		if (value === 'Pucca') {
			resetDescription.Description = ''
		}
		onRiskLocationDataChange({
			...riskLocationData,
			Materials: {
				...riskLocationData?.Materials,
				[material]: {
					...riskLocationData?.Materials?.[material],
					[type]: value,
					...resetDescription,
				},
			},
		})
	}

	const getSumInsuredAmount = key => {
		const sumInsuredItem = riskLocationData?.SumInsured.find(item => item.Name === key)
		return parseInt(sumInsuredItem?.Amount || 0)
	}

	const getTotalSI = () => {
		let totalSI = 0
		riskLocationData?.SumInsured?.forEach(item => {
			if (item.Name !== 'MaxValSingleItem') {
				totalSI += parseInt(item.Amount || 0)
			}
		})
		const stockSI =
			riskLocationData.Stock?.Details?.reduce(
				(prev, curr) => prev + parseInt(curr?.AmountOpen || 0) + parseInt(curr?.AmountClosed || 0),
				0
			) || 0
		return totalSI + stockSI
	}

	const handleSumInsuredChange = (sumInsuredName, value) => {
		const sumInsuredItemsCopy = [...riskLocationData?.SumInsured]
		const sumInsuredItem = sumInsuredItemsCopy.find(item => item.Name === sumInsuredName)

		if (sumInsuredItem) {
			sumInsuredItem.Amount = value.split(',').join('')
		} else {
			sumInsuredItemsCopy.push({
				Name: sumInsuredName,
				Amount: value.split(',').join(''),
			})
		}
		onRiskLocationDataChange({
			...riskLocationData,
			SumInsured: sumInsuredItemsCopy,
		})
	}

	const handleAddressChange = (key, value) => {
		handleRiskLocationDataChange(key, value)
	}

	const triggerWAAddress = async () => {
		// perform save first
		const draftRFQId = await saveDraft()
		const result = await onAddressRequest(draftRFQId)
		if (result) {
			setIsWATriggered(true)
		}
	}

	const fetchAddressFromWA = async () => {
		await onAddressFetch()
		setWAAddressFetched(true)
	}

	useEffect(() => {
		if (riskLocationData?.PIN && riskLocationData?.PIN?.length === 6) {
			setAddressError(false)
			fetchCityAndState(riskLocationData?.PIN).then(result => {
				if (result) {
					onRiskLocationDataChange({ ...riskLocationData, City: result.City, State: result.State })
				} else {
					setAddressError(true)
				}
			})
			if (isWATriggered) {
				setWAAddressResponse(true)
			}
		} else if (isWATriggered) {
			setWAAddressResponse(false)
		}
	}, [activeRiskLocation, riskLocationData?.PIN]) // eslint-disable-line react-hooks/exhaustive-deps

	const handlePINChange = value => {
		if (value.length <= 6) {
			handleRiskLocationDataChange('PIN', value)
		}
	}

	const handleWAPhoneChange = value => {
		if (value.length <= 10) {
			updateContactInfo({
				...contactInfo,
				WaPhone: value,
			})
		}
	}

	const validateEmail = email => {
		if (email) {
			const re = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
			return re.test(String(email).toLowerCase())
		}
		return true
	}

	const isItemPresent = (item, riskItems) => {
		return Boolean(riskItems && riskItems.includes(item))
	}

	const isBasement = riskLocationData?.Type === 'BASEMENT'
	const disableContactInfo = activeRiskLocation !== 0

	const onTypeChange = value => {
		// clear corresponding SI breakup value
		const sumInsuredItemsCopy = [...riskLocationData?.SumInsured]
		if (value === 'PROPERTY') {
			const sumInsuredItem = sumInsuredItemsCopy.find(item => item.Name === 'BasementValue')
			if (sumInsuredItem) {
				sumInsuredItem.Amount = '0'
			}

			onRiskLocationDataChange({
				...riskLocationData,
				SumInsured: sumInsuredItemsCopy,
				Type: value,
				Level: '',
				Materials: {
					...riskLocationData?.Materials,
					Ceiling: {},
				},
			})
		} else {
			const sumInsuredItem = sumInsuredItemsCopy.find(item => item.Name === 'BuildingValue')
			if (sumInsuredItem) {
				sumInsuredItem.Amount = '0'
			}

			onRiskLocationDataChange({
				...riskLocationData,
				SumInsured: sumInsuredItemsCopy,
				Type: value,
				Level: '',
				Materials: {
					...riskLocationData?.Materials,
					Roof: {},
				},
			})
		}
	}

	const handlePolicyKind = value => {
		if (disableContactInfo) {
			return
		}
		handleGenericInfoChange('PolicyKind', value)
		onPolicyKindChange(value)
	}

	const isRented = riskLocationData?.Ownership === 'RENTED'
	let structureSIInput
	if (!isRented) {
		if (isBasement) {
			structureSIInput = (
				<>
					<FormInput
						placeholder="Enter here"
						label="Basement Value"
						value={getSumInsuredAmount('BasementValue')}
						onChange={e => handleSumInsuredChange('BasementValue', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
				</>
			)
		} else {
			structureSIInput = (
				<>
					<FormInput
						placeholder="Enter here"
						label="Building Value"
						value={getSumInsuredAmount('BuildingValue')}
						onChange={e => handleSumInsuredChange('BuildingValue', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Boundary Wall (including gates etc)"
						value={getSumInsuredAmount('BoundaryWalls')}
						onChange={e => handleSumInsuredChange('BoundaryWalls', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
				</>
			)
		}
	}

	const onOwnershipChange = (e, value) => {
		if (value === 'RENTED') {
			const toRemove = ['BoundaryWalls', 'BuildingValue', 'BasementValue']
			const sumInsuredItemsCopy = [...riskLocationData?.SumInsured]
			sumInsuredItemsCopy.forEach(item => {
				if (toRemove.includes(item.Name)) {
					item.Amount = ''
				}
			})
			onRiskLocationDataChange({
				...riskLocationData,
				SumInsured: sumInsuredItemsCopy,
				Ownership: value,
			})

			onRiskLocationDataChange({ ...riskLocationData, Ownership: value })
		} else {
			handleRiskLocationDataChange('Ownership', value)
		}
	}
	let ValuebleContentsAmt = getSumInsuredAmount('ValuableContents')
	let OtherContentsAmt = getSumInsuredAmount('Other')
	let MaxValSingleItemAmt = getSumInsuredAmount('MaxValSingleItem')
	let allowedMaxItemAmt =
		ValuebleContentsAmt > OtherContentsAmt ? ValuebleContentsAmt : OtherContentsAmt
	let maxValError = false
	let maxValueErrorMessage = ''

	if (allowedMaxItemAmt > 0) {
		if (MaxValSingleItemAmt === 0) {
			maxValError = true
			maxValueErrorMessage = "Maximum value can't be 0"
		} else {
			maxValError = MaxValSingleItemAmt > allowedMaxItemAmt
			if (maxValError) {
				maxValueErrorMessage = "Maximum value can't be greater than Valuable or Other Contents"
			}
		}
	} else if (MaxValSingleItemAmt > 0) {
		maxValueErrorMessage = 'Maximum value is invalid without Valuable or Other Contents'
		maxValError = true
	}
	let stockInput

	if (policyInfo?.PolicyKind === 'STOCK_ONLY' || policyInfo?.PolicyKind === 'BUILDING_AND_STOCK') {
		const addStockType = () => {
			let Stock = {
				...riskLocationData?.Stock,
				Details: [...riskLocationData?.Stock.Details],
			}
			Stock.Present = true
			Stock.Details.push({ AmountClosed: '', Type: '', AmountOpen: 0, OpenPer: 0, ClosedPer: 0 })
			handleRiskLocationDataChange('Stock', Stock)
		}

		const removeStockType = index => {
			let Stock = {
				...riskLocationData?.Stock,
			}
			Stock.Details.splice(index, 1)
			handleRiskLocationDataChange('Stock', Stock)
		}

		const handleStockNameChange = (type, name, index) => {
			const value = `${type}:${name}`
			handleStockChange('Type', index, value)
		}

		const goodsTypeList = [
			<option disabled key={'default'}>
				Enter here
			</option>,
			<option value={'Finished goods'} key={'Finished goods'}>
				Finished goods
			</option>,
			<option value={'Raw Material'} key={'Raw Material'}>
				Raw Material
			</option>,
			<option value={'Work-in-progress'} key={'Work-in-progress'}>
				Work-in-progress
			</option>,
		]
		stockInput = (
			<Row>
				{riskLocationData?.Stock?.Details.map((item, index) => {
					let Type = item?.Type ? item?.Type?.split(':') : []

					if (Type?.length === 1) {
						Type = ['', Type[0]]
					}
					return (
						<StockWrapper key={index}>
							<div className="d-flex justify-content-end align-items-center">
								{riskLocationData?.Stock?.Details.length > 1 ? (
									<Icon
										name="remove"
										onClick={() => removeStockType(index)}
										style={{ cursor: 'pointer' }}
									/>
								) : null}
							</div>

							<Text className="mb-1" color={theme.colors.gray2}>
								{' '}
								{`Stock Type ${index + 1}`}
							</Text>

							<Select
								style={{
									width: '100%',
									height: '48px',
									backgroundColor: 'white',
									borderWidth: '0.5px',
								}}
								compulsory
								value={Type[0] ? Type[0] : ''}
								onChange={selected =>
									handleStockNameChange(selected, Type[1] ? Type[1] : '', index)
								}
								className="mb-3"
								placeholder="Enter here"
							>
								{goodsTypeList}
							</Select>

							<FormInput
								placeholder="Enter here"
								value={Type[1] ? Type[1] : ''}
								onChange={e => handleStockNameChange(Type[0] ? Type[0] : '', e.target.value, index)}
								label={`What is the ${Type[0] ? Type[0] : 'raw material/finished good/WIP'}?`}
								compulsory={showAsterisk}
								className="mb-3"
							/>

							<FormInput
								placeholder="Enter here"
								label="Stock Sum Insured"
								type="number"
								value={item?.AmountClosed || ''}
								onChange={e => handleStockChange('AmountClosed', index, e.target.value)}
								autoComma
								rupee
								compulsory={showAsterisk}
							/>
						</StockWrapper>
					)
				})}

				<Button
					bgColor="transparent"
					icon="add"
					iconStyles={{
						marginRight: '0.5rem',
					}}
					label="Add another stock type"
					onClick={addStockType}
					className="p-2 mb-1"
					textColor={theme.colors.primary}
				/>
			</Row>
		)
	}
	const isIndustrialOrManufacturing = isManufacturingOccupancy(
		riskLocationData?.PrimaryOccupancy?.SubCategory
	)
	let workingQuestion

	if (isIndustrialOrManufacturing) {
		let FactoryType = riskLocationData?.TheftRisk?.FactoryType
		const setFactoryType = value => {
			onRiskLocationDataChange({
				...riskLocationData,
				TheftRisk: {
					...riskLocationData?.TheftRisk,
					FactoryType: value,
				},
			})
		}
		workingQuestion = (
			<Row>
				<div className="d-flex align-items-center">
					<Icon name="factory" className="me-3" />
					<Text type="body2">
						What kind of factory?
						<span style={{ color: theme.colors.red }}>*</span>
					</Text>
				</div>

				<div className="d-flex justify-content-around align-items-center" style={{}}>
					<Radio
						value={FactoryType === 'Working'}
						onClick={() => setFactoryType('Working')}
						name="factoryType"
						textStyle={radioOptionTextStyle}
						text={
							<>
								<Text fontSize="14px">Operational</Text>
								<Text color={theme.colors.gray4}>(Working)</Text>
							</>
						}
					/>

					<Radio
						value={FactoryType === 'Silent'}
						onClick={() => setFactoryType('Silent')}
						name="factoryType"
						textStyle={radioOptionTextStyle}
						text={
							<>
								<Text fontSize="14px">Non-Operational</Text>
								<Text color={theme.colors.gray4}>(Silent)</Text>
							</>
						}
					/>
				</div>
			</Row>
		)
	}

	// OCCUPANCY IS STORED IN FORMAT: {Type}:-{Nature}

	const handleOccupancyChange = (key, currentValue, updatedValue) => {
		const currentOccupancy = currentValue.split(':-')
		let combinedOccupancy = ''
		if (key === 'Type') {
			combinedOccupancy = `${updatedValue}:-`
		} else if (key === 'Nature') {
			combinedOccupancy = `${currentOccupancy[0] || ''}:-${updatedValue}`
		}
		handleRiskLocationDataChange('PrimaryOccupancy', {
			SubCategory: combinedOccupancy,
		})
	}

	const handleFacilityTypeChange = selected => {
		let valueToUpdate = ' '
		if (selected !== 'Other (please mention)') {
			valueToUpdate = selected
		}
		handleOccupancyChange('Type', riskLocationData?.PrimaryOccupancy?.SubCategory, valueToUpdate)
	}

	const handleLevelNumber = (value, fromWholeType) => {
		let levelValue = ''
		let riskLocationSubType = 'Single'

		if (!fromWholeType && !value.startsWith('Whole')) {
			setIsWholeType(false)
		} else if (value.startsWith('Whole') || fromWholeType) {
			!isWholeType && setIsWholeType(true)
			riskLocationSubType = 'Whole'
		}

		levelValue = parseInt(value)

		onRiskLocationDataChange({
			...riskLocationData,
			Level: levelValue,
			SubType: riskLocationSubType,
		})
	}

	const levelOptions = [
		<option disabled key={'default'}>
			Enter here
		</option>,
	]
	if (riskLocationData?.Type === 'BASEMENT') {
		for (let i = -1; i >= -5; i--) {
			levelOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			)
		}
		levelOptions.push(<option value="Whole Basement">Whole Basement</option>)
	} else {
		for (let i = 0; i <= 10; i++) {
			levelOptions.push(
				<option key={i} value={i}>
					{i}
				</option>
			)
		}
		levelOptions.push(<option value="Whole Building">Whole Building</option>)
	}

	let locationWATrigger, triggerReponse

	if (activeRiskLocation === 0) {
		const alreadyAddressCaptured = riskLocationData?.AddressLine1 && riskLocationData?.PIN

		if (isWATriggered) {
			locationWATrigger = (
				<Button
					label={'Fill cutomer details'}
					className="mt-3 w-100"
					icon={'download2'}
					style={{ background: !alreadyAddressCaptured ? '#6467B0' : '', height: '48px' }}
					iconStyles={{ marginRight: '8px' }}
					onClick={fetchAddressFromWA}
					disabled={!!alreadyAddressCaptured}
				/>
			)
		} else {
			locationWATrigger = (
				<Button
					label={'Request customer to send address on Whatsapp'}
					className="mt-3 w-100"
					icon={'whatsapp'}
					style={{ background: !alreadyAddressCaptured ? '#6467B0' : '', height: '48px' }}
					iconAlign={'right'}
					iconStyles={{ marginLeft: '8px' }}
					disabled={!!alreadyAddressCaptured}
					onClick={triggerWAAddress}
				/>
			)
		}
	}

	if (isWATriggered && waAddressFetched) {
		if (!waAddressResponse) {
			triggerReponse = (
				<Text color={'#DD5853'} className="mt-1">
					No data received, try again in some time
				</Text>
			)
		} else {
			triggerReponse = (
				<Text color={'#4AA161'} className="mt-1">
					Address updated successfully
				</Text>
			)
		}
	}

	const nameOnPolicyInput = (
		<FormInput
			placeholder="Enter here"
			value={accountInfo?.Name}
			onChange={e => updateAccountInfo({ ...accountInfo, Name: e.target.value })}
			label="Name on policy"
			className="mb-2"
			disabled={disableContactInfo || existingClient}
		/>
	)

	let nameOnPolicyField = nameOnPolicyInput
	if (existingClient) {
		nameOnPolicyField = (
			<EditField showEditButton={true} onEditClick={onAccountEdit}>
				{nameOnPolicyInput}
			</EditField>
		)
	}

	const isProperty = riskLocationData.Type === 'PROPERTY'
	const [isWholeType, setIsWholeType] = useState(false)

	const [showCustomSIFieldModal, setShowCustomSIFieldModal] = useState(false)
	const [customSIFields, setCustomSiFields] = useState(() => {
		const sumInsuredFields = riskLocationData?.SumInsured || []
		const customFields =
			sumInsuredFields
				.filter(siField => !predefinedSumInsuredFields.includes(siField.Name))
				.map(siField => siField.Name) || []
		return customFields
	})

	const handleAddCustomSIField = siFieldName => {
		setShowCustomSIFieldModal(false)
		setCustomSiFields([...customSIFields, siFieldName])
	}

	return (
		<FormSection>
			{/* Names common for each location */}
			{nameOnPolicyField}
			<FormInput
				placeholder="Enter here"
				label="Proposer Name"
				value={contactInfo?.Name}
				className="mb-3"
				onChange={e => updateContactInfo({ ...contactInfo, Name: e.target.value })}
				disabled={disableContactInfo || existingClient}
			/>

			<PrimaryOccupancySelector
				value={riskLocationData?.PrimaryOccupancy?.SubCategory}
				onChange={handleRiskLocationDataChange}
			/>

			{/* risk location level data */}

			<Label text="Location Type" />
			<Row>
				<Col>
					<Radio
						text="Structure above ground"
						secondary={true}
						value={riskLocationData?.Type === 'PROPERTY'}
						onClick={() => onTypeChange('PROPERTY')}
						textStyle={radioOptionTextStyle}
					/>
				</Col>
				<Col>
					<Radio
						text="Basement"
						secondary={true}
						value={riskLocationData?.Type === 'BASEMENT'}
						onClick={() => onTypeChange('BASEMENT')}
						textStyle={radioOptionTextStyle}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Label
						text={`${
							riskLocationData?.Type === 'BASEMENT' ? 'Basement level number' : 'Floor number'
						}`}
						className="mb-2"
					></Label>
					<Select
						style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
						compulsory
						className="mb-3"
						value={
							isWholeType ? `Whole ${isProperty ? 'Building' : 'Basement'}` : riskLocationData.Level
						}
						onChange={selected =>
							handleLevelNumber(selected, selected.startsWith('Whole') ? true : false)
						}
					>
						{levelOptions}
					</Select>
					{isWholeType && (
						<>
							<Text color={theme.colors.gray2}>
								Number of {isProperty ? 'floors' : 'levels'} in this{' '}
								{isProperty ? 'building' : 'basement'}
							</Text>
							<Select
								value={riskLocationData.Level}
								onChange={selected => {
									handleLevelNumber(selected, true)
								}}
								style={{ height: '48px' }}
								className="my-2 w-100"
							>
								{isProperty ? wholeBuildingOptions : wholeBasementOptions}
							</Select>
						</>
					)}
				</Col>
			</Row>

			<Label text="Ownership Type" />
			<div className="d-flex ">
				<Radio
					className="flex-fill"
					text="Sole ownership"
					secondary={true}
					value={riskLocationData?.Ownership === 'SOLE'}
					onClick={e => onOwnershipChange(e, 'SOLE')}
					textStyle={radioOptionTextStyle}
				/>
				<Radio
					className="flex-fill"
					text="Co-Ownership"
					secondary={true}
					value={riskLocationData?.Ownership === 'CO-OWNED'}
					onClick={e => onOwnershipChange(e, 'CO-OWNED')}
					textStyle={radioOptionTextStyle}
				/>
				<Radio
					className="flex-fill"
					text="Rented"
					secondary={true}
					value={riskLocationData?.Ownership === 'RENTED'}
					onClick={e => onOwnershipChange(e, 'RENTED')}
					textStyle={radioOptionTextStyle}
				/>
			</div>

			<Label text="Asset Type" />

			<Row
				style={{ background: disableContactInfo ? theme.colors.gray6 : '', borderRadius: '10px' }}
			>
				<Col>
					<Radio
						text="Structure"
						secondary={true}
						value={policyInfo?.PolicyKind === 'BUILDING_ONLY'}
						onClick={() => handlePolicyKind('BUILDING_ONLY')}
						disabled={disableContactInfo}
						textStyle={radioOptionTextStyle}
					/>
				</Col>
				<Col>
					<Radio
						text="Stock"
						secondary={true}
						value={policyInfo?.PolicyKind === 'STOCK_ONLY'}
						onClick={() => handlePolicyKind('STOCK_ONLY')}
						disabled={disableContactInfo}
						textStyle={radioOptionTextStyle}
					/>
				</Col>
				<Col>
					<Radio
						text="Both"
						secondary={true}
						value={policyInfo?.PolicyKind === 'BUILDING_AND_STOCK'}
						onClick={() => handlePolicyKind('BUILDING_AND_STOCK')}
						disabled={disableContactInfo}
						textStyle={radioOptionTextStyle}
					/>
				</Col>
			</Row>

			{stockInput}

			{workingQuestion}

			{riskLocationData?.Type === 'BASEMENT' ? (
				<MaterialDetailsQuestion
					icon="ceiling"
					name="Ceiling"
					value1={riskLocationData?.Materials?.Ceiling?.Type === 'Kutcha'}
					value2={riskLocationData?.Materials?.Ceiling?.Type === 'Pucca'}
					radio1OnChange={() => handleMaterialChange('Ceiling', 'Type', 'Kutcha')}
					radio2OnChange={() => handleMaterialChange('Ceiling', 'Type', 'Pucca')}
					description={riskLocationData?.Materials?.Ceiling?.Description}
					onDescriptionChange={value => handleMaterialChange('Ceiling', 'Description', value)}
					radioKey="ceiling"
					radioTextStyle={radioOptionTextStyle}
					questionStyle={{ fontSize: '14px' }}
				/>
			) : (
				<MaterialDetailsQuestion
					icon="ceiling"
					name="Roof"
					value1={riskLocationData?.Materials?.Roof?.Type === 'Kutcha'}
					value2={riskLocationData?.Materials?.Roof?.Type === 'Pucca'}
					radio1OnChange={() => handleMaterialChange('Roof', 'Type', 'Kutcha')}
					radio2OnChange={() => handleMaterialChange('Roof', 'Type', 'Pucca')}
					description={riskLocationData?.Materials?.Roof?.Description}
					onDescriptionChange={value => handleMaterialChange('Roof', 'Description', value)}
					radioKey="roof"
					radioTextStyle={radioOptionTextStyle}
					questionStyle={{ fontSize: '14px' }}
				/>
			)}

			<MaterialDetailsQuestion
				icon="wall"
				name="Walls"
				value1={riskLocationData?.Materials?.Walls?.Type === 'Kutcha'}
				value2={riskLocationData?.Materials?.Walls?.Type === 'Pucca'}
				radio1OnChange={() => handleMaterialChange('Walls', 'Type', 'Kutcha')}
				radio2OnChange={() => handleMaterialChange('Walls', 'Type', 'Pucca')}
				description={riskLocationData?.Materials?.Walls?.Description}
				onDescriptionChange={value => handleMaterialChange('Walls', 'Description', value)}
				radioKey="wallls"
				radioTextStyle={radioOptionTextStyle}
				questionStyle={{ fontSize: '14px' }}
			/>
			<MaterialDetailsQuestion
				icon="floor"
				name="Floors"
				value1={riskLocationData?.Materials?.Floors?.Type === 'Kutcha'}
				value2={riskLocationData?.Materials?.Floors?.Type === 'Pucca'}
				radio1OnChange={() => handleMaterialChange('Floors', 'Type', 'Kutcha')}
				radio2OnChange={() => handleMaterialChange('Floors', 'Type', 'Pucca')}
				description={riskLocationData?.Materials?.Floors?.Description}
				onDescriptionChange={value => handleMaterialChange('Floors', 'Description', value)}
				radioKey="Floors"
				radioTextStyle={radioOptionTextStyle}
				questionStyle={{ fontSize: '14px' }}
			/>

			<div className="d-flex align-items-center mt-1">
				<Label text="AMC for Fire Risk" compulsory={false} />
				<Space x={1} />
				<CheckboxContainer className="d-flex justify-content-between">
					<Radio
						text="Yes"
						secondary={true}
						value={riskLocationData?.FireRisk?.AMC}
						onChange={() => handleAMCChange(true)}
						className="me-4"
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						text="No"
						secondary={true}
						value={
							typeof riskLocationData?.FireRisk?.AMC == 'boolean' &&
							!riskLocationData?.FireRisk?.AMC
						}
						onChange={() => handleAMCChange(false)}
						textStyle={radioOptionTextStyle}
					/>
				</CheckboxContainer>
			</div>
			<Space y={1} />

			<Label text="Risk Assesment (Optional)" compulsory={false} />
			<Space y={0.5} />

			{/* fire risk */}
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'gasFlooding'}
					text={'Gas Flooding System'}
					value={isItemPresent('Gas Flooding System', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Gas Flooding System')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'fireAlarm'}
					text={'Fire Alarm System'}
					value={isItemPresent('Fire Alarm System', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Fire Alarm System')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'foamSystem'}
					text={'Foam System'}
					value={isItemPresent('Foam System', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Foam System')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'sprinkler'}
					text={'Sprinkler'}
					value={isItemPresent('Sprinkler', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Sprinkler')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'hoseReel'}
					text={'Small bore hose reel'}
					value={isItemPresent('Small bore hose reel', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Small bore hose reel')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'hydrant'}
					text={'Hydrant'}
					value={isItemPresent('Hydrant', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Hydrant')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'fixedWaterSpray'}
					text={'Fixed Water Spray'}
					value={isItemPresent('Fixed Water Spray', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Fixed Water Spray')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'handHeldFireExt'}
					text={'Handheld fire extinguisher'}
					value={isItemPresent('Handheld fire extinguisher', riskLocationData?.FireRisk?.Measures)}
					onChange={() =>
						handleRiskAssesmentChange('FireRisk', 'Measures', 'Handheld fire extinguisher')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					icon={'smokeDetector'}
					text={'Smoke detectors'}
					value={isItemPresent('Smoke detectors', riskLocationData?.FireRisk?.Measures)}
					onChange={() => handleRiskAssesmentChange('FireRisk', 'Measures', 'Smoke detectors')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>

			{/* theft risk */}
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="Locks"
					icon="antiTheftLock"
					value={isItemPresent(
						'Anti-theft locks',
						riskLocationData?.TheftRisk?.SecurityArrangements
					)}
					onChange={() =>
						handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', 'Anti-theft locks')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="CCTV"
					icon="cctv"
					value={isItemPresent('CCTV', riskLocationData?.TheftRisk?.SecurityArrangements)}
					onChange={() => handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', 'CCTV')}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="24 hour security"
					icon="hrSecurity"
					value={isItemPresent(
						'24 hour security',
						riskLocationData?.TheftRisk?.SecurityArrangements
					)}
					onChange={() =>
						handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', '24 hour security')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="Alarm system"
					icon="alarm"
					value={isItemPresent('Alarm system', riskLocationData?.TheftRisk?.SecurityArrangements)}
					onChange={() =>
						handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', 'Alarm system')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="Boundary Wall"
					icon="boundaryWalls"
					value={isItemPresent('Boundary Wall', riskLocationData?.TheftRisk?.SecurityArrangements)}
					onChange={() =>
						handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', 'Boundary Wall')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>
			<CheckboxContainer className="d-flex justify-content-between">
				<Checkbox
					text="Night watchman"
					icon="watchman"
					value={isItemPresent('Night watchman', riskLocationData?.TheftRisk?.SecurityArrangements)}
					onChange={() =>
						handleRiskAssesmentChange('TheftRisk', 'SecurityArrangements', 'Night watchman')
					}
					textStyle={radioOptionTextStyle}
				/>
			</CheckboxContainer>

			{cctvUnitsQues}

			<div className="w-100 pt-1" ref={sumInsuredContainerRef}>
				<FormSubSection className="my-3">
					<Label
						style={{ fontSize: '18px', fontWeight: 700 }}
						text="Sum Insured Breakup"
						compulsory={
							policyInfo?.PolicyKind !== 'STOCK_ONLY' &&
							!riskLocationData?.Stock?.Details?.[0]?.AmountClosed
						}
					/>
					<Space y={0.5} />
					{structureSIInput}
					<FormInput
						placeholder="Enter here"
						label="Plants and Machinery"
						value={getSumInsuredAmount('PlantMachinery')}
						onChange={e => handleSumInsuredChange('PlantMachinery', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Furniture & Fixture"
						value={getSumInsuredAmount('FurnitureFixture')}
						onChange={e => handleSumInsuredChange('FurnitureFixture', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Electronic Installations"
						value={getSumInsuredAmount('Electronics')}
						onChange={e => handleSumInsuredChange('Electronics', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Office equipments (including IT systems)"
						value={getSumInsuredAmount('OfficeEquipment')}
						onChange={e => handleSumInsuredChange('OfficeEquipment', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Valuable Contents"
						value={ValuebleContentsAmt}
						onChange={e => handleSumInsuredChange('ValuableContents', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					<FormInput
						placeholder="Enter here"
						label="Other Contents"
						value={OtherContentsAmt}
						onChange={e => handleSumInsuredChange('Other', e.target.value)}
						autoComma
						rupee
					/>
					<Space y={1} />
					{OtherContentsAmt || ValuebleContentsAmt ? (
						<>
							<FormInput
								placeholder="Enter here"
								label="Maximum value of a single item"
								value={MaxValSingleItemAmt}
								onChange={e => handleSumInsuredChange('MaxValSingleItem', e.target.value)}
								autoComma
								rupee
								errorLabel={maxValueErrorMessage}
								error={maxValError}
							/>
							<Space y={1} />
						</>
					) : null}

					{customSIFields?.map((siField, index) => {
						return (
							<>
								{index !== 0 && <Space y={0.5} />}
								<FormInput
									placeholder="Enter here"
									label={siField}
									value={getSumInsuredAmount(siField).toString()}
									onChange={e => handleSumInsuredChange(siField, e.target.value)}
									autoComma
									rupee
								/>
								<Space y={0.5} />
							</>
						)
					})}

					<Button
						icon="add"
						className="p-0 mt-1 mb-2"
						iconStyles={{ marginRight: '.5rem' }}
						label="Add Other/Valuable Content"
						bgColor="transparent"
						textColor={theme.colors.primary}
						onClick={() => setShowCustomSIFieldModal(true)}
					/>

					<FormInput
						disabled
						value={getTotalSI()}
						placeholder="Enter here"
						label="Total Sum Insured"
						autoComma
						rupee
					/>
					<Space y={1} />
				</FormSubSection>
			</div>

			<FormInput
				placeholder="Enter here"
				value={contactInfo?.Email}
				onChange={e =>
					updateContactInfo({
						...contactInfo,
						Email: e.target.value,
					})
				}
				label="Email ID (Optional)"
				disabled={disableContactInfo}
				error={!validateEmail(contactInfo?.Email)}
				className="mb-3"
			/>

			<FormInput
				placeholder="Enter here"
				label="Whatsapp Number"
				value={contactInfo?.WaPhone}
				onChange={e => handleWAPhoneChange(e.target.value)}
				compulsory
				disabled={disableContactInfo}
				error={contactInfo?.WaPhone?.length !== 10}
			/>

			<FormSubSection className="my-3">
				<Text fontSize={'18px'} fontWeight="700" className="mb-2" color={theme.colors.gray2}>
					Address Details
				</Text>
				<FormInput
					value={riskLocationData?.AddressLine1}
					onChange={e => handleAddressChange('AddressLine1', e.target.value)}
					placeholder="Address Line 1"
					compulsory
					error={!riskLocationData?.AddressLine1}
					className="mb-3"
				/>
				<FormInput
					value={riskLocationData?.AddressLine2}
					onChange={e => handleAddressChange('AddressLine2', e.target.value)}
					placeholder="Address Line 2"
					className="mb-3"
				/>
				<FormInput
					placeholder="Pincode"
					value={riskLocationData?.PIN}
					onChange={e => handlePINChange(e.target.value)}
					compulsory
					error={riskLocationData?.PIN?.length !== 6}
					errorLabel={
						addressError
							? 'The pincode seems to be incorrect, but still you can fill out City and State below'
							: ''
					}
					className="mb-3"
				/>
				<FormInput
					value={riskLocationData?.City}
					onChange={e => handleAddressChange('City', e.target.value)}
					placeholder="City"
					compulsory
					error={!riskLocationData?.City}
					errorLabel={addressError && !riskLocationData?.City ? "City can't be left blank" : ''}
					className="mb-3"
				/>
				<FormInput
					value={riskLocationData?.State}
					onChange={e => handleAddressChange('State', e.target.value)}
					placeholder="State"
					compulsory
					error={!riskLocationData?.State}
					errorLabel={addressError && !riskLocationData?.State ? "State can't be left blank" : ''}
				/>
				{triggerReponse}
				{locationWATrigger}
			</FormSubSection>

			<FormInput
				placeholder="Enter here"
				label="Alternate Product Interest"
				value={contactInfo?.AlternateProductInterest}
				onChange={e =>
					updateContactInfo({
						...contactInfo,
						AlternateProductInterest: e.target.value,
					})
				}
				disabled={disableContactInfo}
			/>

			{disableContactInfo ? null : (
				<>
					<Space y={1} />
					<Label text="Add on (Optional & Multi-selectable)" compulsory={false} />
					<MIContainer>
						<img src={cicImg} alt="cash in counter" style={{ marginRight: '1rem' }} />
						<Checkbox
							text="Cash in Counter"
							value={isItemPresent('CIC', miAddons)}
							onChange={() => handleAddOns('CIC')}
							textStyle={radioOptionTextStyle}
						/>
					</MIContainer>
					{cicComp}
					<MIContainer>
						<img src={cisImg} alt="cash in safe" style={{ marginRight: '1rem' }} />
						<Checkbox
							text="Cash in Safe"
							value={isItemPresent('CIS', miAddons)}
							onChange={() => handleAddOns('CIS')}
							textStyle={radioOptionTextStyle}
						/>
					</MIContainer>
					{cisComp}
					<MIContainer style={{ marginTop: '0rem' }}>
						<img src={citImg} alt="cash in transit" style={{ marginRight: '1rem' }} />
						<Checkbox
							text="Cash in Transit"
							value={isItemPresent('CIT', miAddons)}
							onChange={() => handleAddOns('CIT')}
							textStyle={radioOptionTextStyle}
						/>
					</MIContainer>
					{citComp}
				</>
			)}

			<Space y={1} />
			<FormInput
				value={contactInfo.UID}
				// onChange={e => updateAccountInfo({ ...accountInfo, UID: e.target.value })}
				placeholder="Enter here"
				label="UID (Optional)"
				disabled
			/>
			<Space y={1} />

			{showCustomSIFieldModal && (
				<AddCustomSIFieldModal
					handleClose={() => setShowCustomSIFieldModal(false)}
					onAddSIField={handleAddCustomSIField}
				/>
			)}
		</FormSection>
	)
}

// Note: Imported in individual CIC, CIS, CIT frames too
export const Label = ({ text, compulsory = true, ...props }) => {
	return (
		<Text color={theme.colors.gray2} fontSize="14px" fontWeight="400" {...props}>
			{text}
			{compulsory && <span style={{ color: theme.colors.red }}>*</span>}
		</Text>
	)
}

// Note: Imported in individual CIC, CIS, CIT frames too

const MIContainer = styled.div`
	display: flex;
	margin-top: 1rem;
	margin-bottom: 1rem;
`

const FormSection = styled.div`
	overflow-y: scroll;
	padding: 8px 14px;
	scrollbar-width: thin;
	scrollbar-color: #c5c5c5 #f6f6f6;
`

const CheckboxContainer = styled.div`
	.styled-check {
		display: block;
		position: inherit;
		margin-top: -1.2rem;
	}
	.check-container + label {
		padding-left: 1rem;
	}
	margin: 0.8rem 0px;
`

// TODO: to be changed with accordion
const FormSubSection = styled.div`
	padding: 12px;
	background: #f2f2f2;
	border-radius: 8px;
`

const StockWrapper = styled.div`
	background: #f2f2f2;
	margin-bottom: 8px;
	border-radius: 8px;
	padding: 16px;
`

// Styles
export const radioOptionTextStyle = { fontSize: '14px' }

const AddonContainer = styled.div`
	padding: 12px;
	border-radius: 8px;
	background: #f2f2f2;
	margin: 8px auto;
`
