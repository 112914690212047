import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { theme, Text, Space, Button, Modal, Divider, MainContainer, Icon } from 'verak-ui'
import { Context } from '../../context/Provider'

import BuildingOnly from '../../assets/icons/BuildingOnly.svg'
import BuildingStock from '../../assets/icons/Building+Stock.svg'
import StockOnly from '../../assets/icons/Stock.svg'
import { useState, useContext } from 'react'

const buttonStyle = {
	height: '64px',
	fontSize: '18px',
}

const PropertyCardWrapper = styled.div`
	padding: 1.5rem;
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	background: ${props => (props.$isSelected ? theme.colors.secondary4 : theme.colors.secondary3)};
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.3s ease;
	&:hover {
		transform: translate(0px, -2px) scale(1.02);
		box-shadow: ${theme.elevation['2dp']};
	}

	@media (min-width: 768px) {
		height: 300px;
		width: 250px;
		margin-right: 12px;
		margin-left: 12px;
		margin-bottom: 1.5rem;
	}
	@media (max-width: 768px) {
		padding: 16px;
		height: 90px;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 0.8rem;
	}
`

const CardHolder = styled.div`
	display: flex;
	justify-content: space-around;
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}
`

const ImgIcon = styled.img`
	@media (min-width: 768px) {
		margin-bottom: 1rem;
		height: 152px;
		width: 152px;
	}
	@media (max-width: 768px) {
		height: 60px;
		width: 60px;
		margin-right: 0.8rem;
	}
`

const CustomText = styled(Text)`
	@media (max-width: 768px) {
		font-size: 0.9rem;
		text-align: left !important;
	}
`

const StockOnlyModal = ({ showModal, closeModal }) => {
	const [state, dispatch] = useContext(Context)
	let history = useHistory()

	const titleText = (
		<Text type="h5" fontWeight="700" color={theme.colors.red}>
			Stock Only notice
		</Text>
	)

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_KIND',
				data: {
					PolicyKind: 'STOCK_ONLY',
				},
			})
			resolve()
		})

		history.push('/stock-disclosure-choice/stock-location')
	}

	return (
		<Modal
			id="stock-only-notice"
			title={titleText}
			closeButton={true}
			footer={<></>}
			show={showModal}
			handleClose={closeModal}
		>
			<Space y={1} />
			<Text type="h5" fontWeight="700" color={theme.colors.primary} style={{ textAlign: 'center' }}>
				Although this is a Stock Only policy - the insurers need information about the structure
				where the stock is stored.
			</Text>
			<Space y={1} />
			<Text
				type="h5"
				fontWeight="700"
				color={theme.colors.secondary}
				style={{ textAlign: 'center' }}
			>
				Hence, if you see questions about the structure in which stock is stored - please explain to
				the client that this is required for underwriting purposes.
			</Text>
			<Space y={1.5} />
			<Button size="stretch" primary label="Continue" style={buttonStyle} onClick={proceed} />
		</Modal>
	)
}

const QuoteTypeCard = ({ title, icon, link, onClick, value, isSelected }) => {
	const [state, dispatch] = useContext(Context)
	let history = useHistory()

	const onTypeSelect = async () => {
		if (link) {
			await new Promise((resolve, reject) => {
				dispatch({
					type: 'SET_POLICY_KIND',
					data: {
						PolicyKind: value,
					},
				})
				resolve()
			})

			history.push(link)
		} else if (typeof onClick === 'function') {
			onClick()
		}
	}

	return (
		<PropertyCardWrapper onClick={onTypeSelect} $isSelected={isSelected}>
			<ImgIcon src={icon} />
			<CustomText
				style={{ fontWeight: '700' }}
				fontSize="24px"
				color={theme.colors.primary}
				className="text-center"
			>
				{title}
			</CustomText>
			{isSelected && (
				<Icon
					name="yes"
					color={theme.colors.green}
					style={{ position: 'absolute', top: '10px', right: '10px' }}
				/>
			)}
		</PropertyCardWrapper>
	)
}

const QuoteTypeSelection = () => {
	//   const fetch = useFetch()
	let history = useHistory()
	const [showStockOnlyModal, setShowStockOnlyModal] = useState(false)
	const [state, dispatch] = useContext(Context)

	const showStockModal = () => setShowStockOnlyModal(true)
	const closeStockModal = () => setShowStockOnlyModal(false)

	const goBack = () => {
		history.goBack()
	}

	const cardList = [
		{
			title: 'Property only',
			icon: BuildingOnly,
			link: state?.rfq?.draftRFQ?._id
				? '/stock-disclosure-choice/stock-location'
				: '/journey/risk-location',
			value: 'BUILDING_ONLY',
		},
		{
			title: 'Property & Stock',
			icon: BuildingStock,
			link: state?.rfq?.draftRFQ?._id
				? '/stock-disclosure-choice/stock-location'
				: '/journey/risk-location',
			value: 'BUILDING_AND_STOCK',
		},
		{
			title: 'Stock only',
			icon: StockOnly,
			link: '',
			onClick: showStockModal,
			value: 'STOCK_ONLY',
		},
	]

	const renderCardList = cardList.map((cardItem, key) => {
		const { title, icon, link, onClick, value } = cardItem
		return (
			<QuoteTypeCard
				title={title}
				icon={icon}
				link={link}
				onClick={onClick}
				key={key}
				value={value}
				isSelected={value == state.quote.PolicyKind}
			/>
		)
	})

	return (
		<MainContainer header="Type of quote" divider={false} leftOnClick={goBack}>
			<Row>
				<Row className="d-flex align-items-center text-center">
					<Text style={{ fontWeight: 700, fontSize: '24px' }} className="text-center">
						What type of policy does the client need?
					</Text>
				</Row>

				<Row className="mt-4">
					<CardHolder>{renderCardList}</CardHolder>
				</Row>
			</Row>
			<StockOnlyModal showModal={showStockOnlyModal} closeModal={closeStockModal} />
			<Divider />
		</MainContainer>
	)
}

export default QuoteTypeSelection
