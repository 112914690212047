/* PACKAGE IMPORTS */
import styled from 'styled-components'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

/* CSS */
import './App.css'
import 'animate.css'

/* COMPONENTS */
import { PageContainer as PageWrapper } from 'verak-ui'
import LoadingOverlay from './components/LoadingOverlay'
import { LoadingOverlayProvider } from './components/LoadingOverlay'
import Sidepanel from './components/Sidepanel'

/* CONTEXT */
import { ComponentContext } from './context/ComponentProvider'
import { APIStateProvider } from './context/APIStateProvider'

/* AUTH */
import ProtectedRoute from './auth/protected-route'

/* HELPERS */
import ScrollToTop from './helpers/ScrollToTop'

// OTHERS
// import TestPage from './screens/TestPage'

/* SCREENS */
import HomeScreen from './screens/HomeScreen'

/* ROUTES */
import allCombinedRoutes from './routes/routes'

import AppbarWrapper from './components/AppbarWrapper'

function Container() {
	const { isAuthenticated } = useAuth0()

	const { extraContainer, renderSidePanel } = useContext(ComponentContext)

	return (
		<APIStateProvider>
			<LoadingOverlayProvider>
				<PageWrapper>
					<AppbarWrapper />
					<ScrollToTop />
					<PageContentWrapper
						shrinkLeftPanel={!isAuthenticated}
						style={{ marginTop: extraContainer?.status ? '135px' : '60px' }}
					>
						<Sidepanel renderSidePanel={renderSidePanel} />
						<RightPanel>
							<Switch>
								<Route key={'/'} path={'/'} exact={true}>
									<HomeScreen />
								</Route>
								<Route key={'/logout'} path={'/logout'} exact={true}>
									<Redirect to="/" />
								</Route>
								{allCombinedRoutes.map(route => (
									<ProtectedRoute
										key={route.path}
										path={route.path}
										exact={route.exact}
										component={route.component}
									></ProtectedRoute>
								))}
							</Switch>
						</RightPanel>
						<LoadingOverlay />
					</PageContentWrapper>
				</PageWrapper>
			</LoadingOverlayProvider>
		</APIStateProvider>
	)
}

export default Container

// STYLES

const PageContentWrapper = styled.div`
	height: calc(100% - 64px);
	/* width: min(1280px, 100%); */
	width: 100%;
	margin-top: 60px;
	display: flex;
	justify-content: center;
`

const RightPanel = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: start; */
	align-items: center;

	/* padding: 0 2rem; */
	@media (max-width: 768px) {
		padding: 0;
	}
`
