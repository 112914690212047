/**
 *
 * @param {object} newRfq New rfq object
 * @param {object} oldRfq Old rfq object
 * @returns
 */
const isRfqEqual = (newRfq, oldRfq) => {
	return JSON.stringify(newRfq) === JSON.stringify(oldRfq)
}

const editRfqAllowedStatuses = [
	'AGENT:QUOTES:PENDING',
	'OPS:RFQ_SUBMISSION:PENDING',
	'UW:REVIEW:PENDING',
]

export { isRfqEqual, editRfqAllowedStatuses }
