import styled from 'styled-components'
import { Space, Text, theme, Button } from 'verak-ui'
import FormInput from '../../components/FormInput'
import { getAddress } from './utils'

export const Header = ({ img, header }) => {
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<img src={img} alt="cash in counter" />
				<Space x={1} />
				<Text style={{ fontSize: '24px', fontWeight: 700 }} color={theme.colors.gray7}>
					{header}
				</Text>
			</div>
			<Space y={1.5} />
			<Text
				style={{ textTransform: 'uppercase', fontSize: '16px', fontWeight: 700 }}
				color={theme.colors.secondary}
			>
				Please enter details for the below fields
			</Text>
			<Space y={1.5} />
		</>
	)
}

export const CCTVWarning = (
	<Text color={theme.colors.red}>Note: Add on will be rejected without the presence of a CCTV</Text>
)

export const AddressField = ({ type, value, handleUpdate, rlData }) => {
	let labelText = 'Enter Address of the Add-on to be insured'
	if (type !== 'transit') {
		labelText = `Address of the ${type} to be insured`
	}
	return (
		<>
			<Space y={1.5} />
			<Label compulsory className="mb-1">
				{labelText}
			</Label>
			<FormInput
				placeholder="Enter here"
				value={value}
				onChange={e => handleUpdate(e.target.value, 'Address')}
				compulsory
				style={inputStyle}
			/>
			<Space y={1} />
			<Button
				label="Use Risk Location 1 Address"
				onClick={() => getAddress(rlData, handleUpdate)}
				style={{ fontWeight: 500 }}
			/>
		</>
	)
}

export const inputStyle = { width: '320px' }

export const Label = ({ compulsory, children }) => {
	return (
		<LabelText>
			{children} {compulsory && <span style={{ color: theme.colors.red }}>*</span>}
		</LabelText>
	)
}

export const LabelText = styled(Text)`
	font-size: 18px;
	font-weight: 500;
	color: ${theme.colors.gray7};
	margin-bottom: 0.4rem;
`
