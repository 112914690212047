import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import VyapariClients from '../../../../../assets/training/VyapariClients.svg'
import BestPrice from '../../../../../assets/training/BestPrice.svg'
import Calculator from '../../../../../assets/training/Calculator.svg'
import Existing5Years from '../../../../../assets/training/Existing5Years.svg'
import HotlinePhone from '../../../../../assets/training/HotlinePhone.svg'
import InstantQuotes from '../../../../../assets/training/InstantQuotes.svg'
import PaperlessProcess from '../../../../../assets/training/PaperlessProcess.svg'
import SettledClaims from '../../../../../assets/training/SettledClaims.svg'
import VerakLogo from '../../../../../assets/training/VerakLogo.svg'
import WhatsappSupport from '../../../../../assets/training/WhatsappSupport.svg'
import { claimsCount } from '../../../../../utilities/claimsCount'

export default function CheatSheet_WhyUs() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={HotlinePhone}>
					<InfoText fontWeight="700">
						Hotline Number <br /> (10am - 7pm) <br /> (Mon-Sat): <br /> 9076277062
					</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={WhatsappSupport}>
					<InfoText fontWeight="700">
						27x7 Whatsapp Support: <br /> 9769161630
					</InfoText>
				</InfoBox>
			</div>

			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={SettledClaims}>
					<InfoText>
						Settled <b>{claimsCount} claims this year</b>
					</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={Existing5Years}>
					<InfoText>
						Existing for <b>5+ years</b>
					</InfoText>
				</InfoBox>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={VyapariClients}>
					<InfoText fontWeight="700">5000+ vyapari clients this year</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={PaperlessProcess}>
					<InfoText fontWeight="700">
						<b>Paperless process</b> from purchase to claims
					</InfoText>
				</InfoBox>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={InstantQuotes}>
					<InfoText fontWeight="700">
						Instant Quotes from Oriental, United India, National & New India
					</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={BestPrice}>
					<InfoText fontWeight="700">Best Price Guarantee</InfoText>
				</InfoBox>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<InfoBox className="col" icon={Calculator}>
					<InfoText fontWeight="700">We give best premium options.</InfoText>
				</InfoBox>

				<VerticalDivider />

				<InfoBox className="col" icon={VerakLogo}>
					<InfoText fontWeight="700">
						Our Website <br /> https://verak.in
					</InfoText>
				</InfoBox>
			</div>
		</div>
	)
}
