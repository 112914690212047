import styled from 'styled-components'

import { SelectedDisclosureMethod } from '../../../components/SharedComponents'
import { Icon, Space, Text, theme, MainContainer } from 'verak-ui'

const DeclarationTemplate = ({ handleSave, method }) => {
	const types = [
		{
			text: 'Average of the closing balance for each day in the concerned month',
			value: 'AVG',
		},
		{
			text: 'Maximum closing balance for any day in the concerned month',
			value: 'MAX',
		},
	]

	let declarationChoices = types.map(el => (
		<DeclarationType handleSave={handleSave} text={el.text} value={el.value} key={el.value} />
	))
	return (
		<MainContainer header="Stock location details" divider={false}>
			<SelectedDisclosureMethod method={method} />
			<Space y={2.5} />
			<Text type="primary" color={theme.colors.gray7}>
				Please select how the client will report their stock values to the insurer on a monthly
				basis:
			</Text>
			{declarationChoices}
			<Space y={2.5} />
			{/* Should technically be a variation of the Alert component */}
			<WarningContainer>
				<div className="flex-container">
					<Icon name="infoIconOctagon" />
					<Space x={0.5} />
					<Text style={{ fontSize: '18px', fontWeight: '700' }}>Warning</Text>
				</div>
				<Space y={0.625} />
				<Text style={textStyle}>
					Once the client has selected the above - it CANNOT be changed mid-way through the policy
					term.
				</Text>
				<Space y={0.625} />
				<Text style={textStyle}>
					If there is a question - please contact the Sales Manger for further information.
				</Text>
			</WarningContainer>
			<Space y={2.5} />
		</MainContainer>
	)
}

export default DeclarationTemplate

const DeclarationType = ({ handleSave, text, value }) => {
	const proceed = () => {
		handleSave(value)
	}
	return (
		<StyledButton onClick={proceed}>
			<Text type="primary" color={theme.colors.primary}>
				{text}
			</Text>
			<Space x={3} />
			<Icon name="rightArrow" color={theme.colors.primary} size="32px" />
		</StyledButton>
	)
}

const StyledButton = styled.button`
	margin-top: 2.5rem;
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem;
	background: ${theme.colors.secondary2};
	border-radius: 10px;
	text-align: left;
`

const WarningContainer = styled.div`
	padding: 0.625rem;
	border-radius: 10px;
	width: 100%;
	background: ${theme.colors.secondaryLightRed};
	color: ${theme.colors.red};
`

const textStyle = {
	fontSize: '14px',
	fontWeight: 700,
	marginBottom: '1rem',
}
