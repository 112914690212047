import { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
	Divider,
	MainContainer,
	Tooltip,
	FileUploader,
	FileUploaded,
	HiddenFileInput,
	Checkbox,
	Text,
	Icon,
	Radio,
	theme,
	Input,
	Tabbar,
	Accordion,
} from 'verak-ui'
import { Row, Col } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'

import { Context } from '../../../context/Provider'
import { infoStrings } from './fireInfoStrings'
import { useFetch } from '../../../api/apihook'
import {
	parseLinkFromSignedUrl,
	imageDeleteConfirmOptions,
} from '../../../utilities/imageUploadUtilities'
import Swal from 'sweetalert2'
import { useLoadingOverlay } from '../../../components/LoadingOverlay'

const initData = {
	AMC: '',
	Measures: [],
	OtherPreventativeMeasures: '',
	DistFireStation: '',
	DistWaterBody: '',
	RailwayCrossing: '',
	Images: {
		FireFightingEquipments: [],
		SurroundingAreas: [],
	},
}

function basementHasSomeData(basementData) {
	const skipItems = ['AMC', 'Images', 'DeWateringDesc', 'HasDeWatering']

	return Object.keys(basementData)
		.filter(el => !skipItems.includes(el))
		.some(el => {
			let basementItem = basementData[el]

			let isBoolean = typeof basementItem === 'boolean'
			let isArray = Array.isArray(basementItem)
			let isObject = typeof basementItem === 'object' && basementItem !== null

			if (isBoolean) {
				return true
			} else if (isArray) {
				return Boolean(basementItem.length)
			} else if (isObject) {
				let hasKeys = Object.keys(basementItem)?.length
				return Boolean(hasKeys)
			} else if (isNaN(basementItem)) {
				return false
			}
			return Boolean(basementItem)
		})
}

function sameAsProperty(propertyData, basementData) {
	const skipItems = ['AMC', 'Images', 'DeWateringDesc', 'HasDeWatering']

	return Object.keys(basementData)
		.filter(el => !skipItems.includes(el))
		.every(el => {
			const basementItem = basementData[el]
			if (el === 'Measures') {
				const propertyMeasures = propertyData[el]
				if (basementItem?.length === propertyMeasures?.length) {
					return basementItem?.every(measure => propertyMeasures?.includes(measure))
				}
				return false
			}
			return basementItem === propertyData[el]
		})
}

function prefillFireRisk(propertyType, currentTab, globalstore) {
	const isProperty = propertyType === 'PROPERTY'
	const isBasement = propertyType === 'BASEMENT'

	let globalRiskData = ''

	if (isProperty || isBasement) {
		globalRiskData = globalstore.FireRisk || {}
	} else {
		if (currentTab === 'property') {
			globalRiskData = globalstore.property.FireRisk || {}
		} else {
			globalRiskData = globalstore.basement.FireRisk || {}
		}
	}

	return { ...initData, ...globalRiskData }
}

const FireSystem = ({ onBack }) => {
	const fetch = useFetch()
	const history = useHistory()
	const location = useLocation()
	const [state, dispatch] = useContext(Context)

	const Type = state?.location?.Type || ''
	const prefillPropertyData = prefillFireRisk(Type, 'property', state.location || {})
	const prefillBasementData = prefillFireRisk(Type, 'basement', state.location || {})

	const returnPath = location.state?.returnPath || ''

	const [propertyData, setPropertyData] = useState(prefillPropertyData)
	const [basementData, setBasementData] = useState(prefillBasementData)
	const [activeTab, setActiveTab] = useState('property')

	// Notes: Prefilling is done only for Basement, whenever property data is updated
	// Prefill data updates simultaneously - provided the toggle is on
	const [isDataPrefilled, setIsDataPrefilled] = useState(
		Boolean(
			Type === 'PROPERTYBASEMENT' &&
				(sameAsProperty(prefillPropertyData, prefillBasementData) ||
					!basementHasSomeData(prefillBasementData || {}))
		)
	)

	const onResetPrefilledData = () => {
		setIsDataPrefilled(val => !val)
	}

	const handleChangeBasement = (key, value) => {
		setBasementData({
			...basementData,
			[key]: value,
		})
	}

	const handleChangeProperty = (key, value) => {
		setPropertyData({
			...propertyData,
			[key]: value,
		})
	}

	const handlePropertyMeasuresChanges = (item, status) => {
		if (status === true && !propertyData?.Measures.includes(item)) {
			setPropertyData({
				...propertyData,
				Measures: [...propertyData?.Measures, item],
			})
		} else if (!status && propertyData?.Measures.includes(item)) {
			setPropertyData({
				...propertyData,
				Measures: [...propertyData?.Measures.filter(el => el !== item)],
			})
		}
	}

	const handleBasementMeasuresChanges = (item, status) => {
		if (status === true && !basementData?.Measures.includes(item)) {
			setBasementData({
				...basementData,
				Measures: [...basementData?.Measures, item],
			})
		} else if (!status && basementData?.Measures.includes(item)) {
			setBasementData({
				...basementData,
				Measures: [...basementData?.Measures.filter(el => el !== item)],
			})
		}
	}

	// only for basement tab
	useEffect(() => {
		if (activeTab === 'basement' && isDataPrefilled) {
			setBasementData(prev => ({
				...propertyData,
				AMC: prev?.AMC,
				Images: {
					...(propertyData?.Images || {}),
					FireFightingEquipments: prev?.FireFightingEquipments || [],
				},
			}))
		}
	}, [activeTab, isDataPrefilled]) // eslint-disable-line

	const changeTab = () => {
		if (activeTab === 'property') {
			setActiveTab('basement')
		} else {
			setActiveTab('property')
		}
	}

	const proceed = async () => {
		let dispatchPropertyData = async () => {
			await new Promise(resolve => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: 'property',
					levelIndex: -1,
					data: {
						FireRisk: propertyData,
					},
				})
				resolve()
			})
		}
		let dispatchBasementData = async data => {
			await new Promise(resolve => {
				dispatch({
					type: 'UPDATE_RISK_LOCATION_DETAILS',
					propertyType: 'basement',
					levelIndex: -1,
					data: {
						FireRisk: data,
					},
				})
				resolve()
			})
		}

		const triggerSaveAndNavigate = async () => {
			await new Promise(resolve => {
				dispatch({
					type: 'SAVE_LOCATION_CHANGES',
				})
				resolve()
			})
			await fetch.saveQuote()

			if (returnPath) {
				history.push(returnPath)
			} else {
				history.push('/property-risk/historic-info')
			}
		}

		if (Type === 'PROPERTYBASEMENT') {
			// going to the next page will always happen from basement
			if (activeTab === 'property') {
				await dispatchPropertyData()
				setActiveTab('basement')
			} else if (activeTab === 'basement') {
				// handles prefilling of data, ideally test this again during internal QAF
				let toSaveBasementData = { ...basementData }
				if (isDataPrefilled) {
					toSaveBasementData = {
						...propertyData,
						AMC: basementData.AMC,
						Images: {
							FireFightingEquipments: basementData.Images.FireFightingEquipments,
							SurroundingAreas: propertyData.Images.SurroundingAreas,
						},
					}
				}
				await dispatchBasementData(toSaveBasementData)
				await dispatchPropertyData()
				triggerSaveAndNavigate()
			}
		} else {
			if (Type === 'PROPERTY') {
				await dispatchPropertyData()
			} else if (Type === 'BASEMENT') {
				await dispatchBasementData(basementData)
			}
			triggerSaveAndNavigate()
		}
	}

	const propertyEle = (
		<PropertyUI
			propertyData={propertyData}
			handleChange={handleChangeProperty}
			handleMeasuresChanges={handlePropertyMeasuresChanges}
			Type={Type}
		/>
	)
	const basementEle = (
		<BasementUI
			basementData={basementData}
			handleChange={handleChangeBasement}
			handleMeasuresChanges={handleBasementMeasuresChanges}
			isDataPrefilled={isDataPrefilled}
			onResetPrefilledData={onResetPrefilledData}
			Type={Type}
		/>
	)

	const tabList = [
		{
			id: 'property',
			title: 'Building',
			content: propertyEle,
		},
		{
			id: 'basement',
			title: 'Basement',
			content: basementEle,
		},
	]

	let content = null
	if (Type === 'PROPERTYBASEMENT') {
		content = (
			<Tabbar className="mt-3" tabList={tabList} activeTabId={activeTab} onChange={changeTab} />
		)
	} else if (Type === 'PROPERTY') {
		content = propertyEle
	} else if (Type === 'BASEMENT') {
		content = basementEle
	}

	const onBackButtonClick = () => {
		if (state.location.Type === 'PROPERTY') {
			if (returnPath) {
				history.replace(returnPath)
			} else {
				history.replace('/property-risk/materials')
			}
		} else {
			onBack()
		}
	}

	return (
		<MainContainer
			header="Fire risk assessment"
			rightOnClick={proceed}
			top={1}
			leftOnClick={onBackButtonClick}
		>
			<Text className="mb-3" fontSize="1rem" fontWeight="700" color={theme.colors.red}>
				Note: * marked questions are mandatory
			</Text>
			<TitleText>Enter all fire protection mechanisms:</TitleText>
			{content}
		</MainContainer>
	)
}

export default FireSystem

const PropertyUI = ({ propertyData, handleChange, handleMeasuresChanges, Type }) => {
	const [state] = useContext(Context)

	const { setIsLoading, setLoadingText } = useLoadingOverlay()

	const fetch = useFetch()

	const fireFightingFileInput = useRef()
	const surroundingAreasFileInput = useRef()

	const triggerFireFightingFileUpload = () => fireFightingFileInput?.current?.click()

	const triggerSurroundingAreasFileUpload = () => surroundingAreasFileInput?.current?.click()

	const handleFileUpload = async (files, key) => {
		const file = files[0]

		setLoadingText('Uploading...')
		setIsLoading(true)

		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

		const ImagesArray = propertyData?.Images

		const updatedImages = {
			...ImagesArray,
			[key]: [...ImagesArray[key], s3Link],
		}

		setIsLoading(false)

		handleChange('Images', updatedImages)
	}

	const removeImage = (img, key) => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				const ImagesArray = propertyData?.Images

				const updatedImages = {
					...ImagesArray,
					[key]: ImagesArray[key].filter(el => el !== img),
				}

				handleChange('Images', updatedImages, propertyData, 'property')

				Swal.fire('Your file has been deleted')
			}
		})
	}

	const firefightingUploadedImagesMap =
		propertyData?.Images?.FireFightingEquipments?.length > 0 &&
		propertyData?.Images?.FireFightingEquipments?.map((img, key) => (
			<FileUploaded
				key={key}
				imageUrl={img}
				onDelete={() => removeImage(img, 'FireFightingEquipments')}
			/>
		))

	const surroundingAreasUploadedImagesMap =
		propertyData?.Images?.SurroundingAreas?.length > 0 &&
		propertyData?.Images?.SurroundingAreas?.map((img, key) => (
			<FileUploaded
				key={key}
				imageUrl={img}
				onDelete={() => removeImage(img, 'SurroundingAreas')}
			/>
		))

	const handleIntegerVals = (fieldName, val) => {
		const convertedVal = val ? parseInt(val) : ''
		handleChange(fieldName, convertedVal)
	}

	const isCheckedPropertyMeasures = value => propertyData?.Measures?.indexOf(value) !== -1

	const additionalInfoAccordianList = [
		{
			id: 'additional_info',
			header: (
				<>
					Additional Questions
					<sup style={{ color: theme.colors.secondary2, fontWeight: 'normal' }}> Optional</sup>
				</>
			),
			content: (
				<>
					<Divider style={{ marginTop: '2rem' }} />
					<Row className="mt-3">
						<Col className="d-flex align-items-center mb-3">
							<Icon name="firefighting" className="me-3" />
							<Text type="body1">Upload photos of the firefighting equipment</Text>
						</Col>
					</Row>
					<Row>
						<div className="d-flex mt-4 mb-4">
							<HiddenFileInput
								ref={fireFightingFileInput}
								onChange={e => handleFileUpload(e.target.files, 'FireFightingEquipments')}
							/>
							<FileUploader onClick={triggerFireFightingFileUpload} />
							{firefightingUploadedImagesMap}
						</div>
					</Row>
					<Divider />
					<Row>
						<Col className="d-flex align-items-center mb-3">
							<Icon name="surroundingAreas" className="me-3" />
							<Text type="body1">Upload images of surrounding areas</Text>
							<Tooltip icon="knowMore" className="ms-3" text={infoStrings['uploadSurroundings']} />
						</Col>
					</Row>
					<Row>
						<div className="d-flex mt-4 mb-4">
							<HiddenFileInput
								ref={surroundingAreasFileInput}
								onChange={e => handleFileUpload(e.target.files, 'SurroundingAreas')}
							/>
							<FileUploader onClick={triggerSurroundingAreasFileUpload} />
							{surroundingAreasUploadedImagesMap}
						</div>
					</Row>
					<Divider />
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="otherMeasures" className="me-3" />
							<Text type="body1">Any other preventative initatives - please specify below</Text>
							<Tooltip icon="knowMore" className="ms-3" text={infoStrings['anyOtherInitiatives']} />
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={propertyData?.OtherPreventativeMeasures || ''}
								onChange={e => handleChange('OtherPreventativeMeasures', e.target.value)}
								placeholder="Enter here"
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="firestation" className="me-3" />
							<Text type="body1">
								What is the distance from this risk location to the nearest fire station?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={propertyData?.DistFireStation || ''}
								onChange={e => handleIntegerVals('DistFireStation', e.target.value)}
								placeholder="Enter distance in KMs"
								type="number"
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-2">
							<Icon name="railwaycrossing" className="me-3" />
							<Text type="body1">
								Is there a railway crossing in between the public fire station and the risk
								location?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4 d-flex">
							<Radio
								id="property-railwaycross-yes"
								name="property-railwaycross"
								value={propertyData?.RailwayCrossing === true}
								onClick={() => handleChange('RailwayCrossing', true)}
								text="Yes"
								secondary={true}
								style={{ width: '154px' }}
							/>
							<Radio
								id="property-railwaycross-no"
								name="property-railwaycross"
								value={propertyData?.RailwayCrossing === false}
								onClick={() => handleChange('RailwayCrossing', false)}
								text="No"
								secondary={true}
								style={{ width: '154px' }}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="openwaterbody" className="me-3" />
							<Text type="body1">
								What is the distance from this risk location to the nearest open water body?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={propertyData?.DistWaterBody || ''}
								onChange={e => handleIntegerVals('DistWaterBody', e.target.value)}
								placeholder="Enter distance in KMs"
								type="number"
							/>
						</Col>
					</Row>
				</>
			),
		},
	]

	return (
		<>
			<Row className="mt-3">
				<Col className="d-flex align-items-center">
					<Icon name="amc" className="me-3" />
					<Text type="body1" className="flex-fill">
						Is an AMC for fire appliances in force?
						<Tooltip icon="knowMore" text={infoStrings['amcForce']} />
					</Text>
					<Radio
						id="property-amc-yes"
						name="property-amc"
						value={propertyData?.AMC === true}
						onClick={() => handleChange('AMC', true)}
						text="Yes"
						secondary={true}
						style={{ width: '154px' }}
					/>
					<Radio
						id="property-amc-no"
						name="property-amc"
						value={propertyData?.AMC === false}
						onClick={() => handleChange('AMC', false)}
						text="No"
						secondary={true}
						style={{ width: '154px' }}
					/>
				</Col>
			</Row>
			<Divider />
			<Text className="mb-3">Select all that apply</Text>
			<Row className="mb-3">
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Gas Flooding System')}
						onChange={state => handleMeasuresChanges('Gas Flooding System', state)}
						icon={'gasFlooding'}
						text={'Gas Flooding System'}
						info={infoStrings['glassFlooding']}
					/>
				</Col>
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Fire Alarm System')}
						onChange={state => handleMeasuresChanges('Fire Alarm System', state)}
						icon={'fireAlarm'}
						text={'Fire Alarm System'}
						info={infoStrings['fireAlarm']}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Foam System')}
						onChange={state => handleMeasuresChanges('Foam System', state)}
						icon={'foamSystem'}
						text={'Foam System'}
						info={infoStrings['foamSystem']}
					/>
				</Col>
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Sprinkler')}
						onChange={state => handleMeasuresChanges('Sprinkler', state)}
						icon={'sprinkler'}
						text={'Sprinkler'}
						info={infoStrings['sprinkler']}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Small bore hose reel')}
						onChange={state => handleMeasuresChanges('Small bore hose reel', state)}
						icon={'hoseReel'}
						text={'Small bore hose reel'}
						info={infoStrings['smallBore']}
					/>
				</Col>
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Hydrant')}
						onChange={state => handleMeasuresChanges('Hydrant', state)}
						icon={'hydrant'}
						text={'Hydrant'}
						info={infoStrings['hydrant']}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Fixed Water Spray')}
						onChange={state => handleMeasuresChanges('Fixed Water Spray', state)}
						icon={'fixedWaterSpray'}
						text={'Fixed Water Spray'}
						info={infoStrings['fireWaterSpray']}
					/>
				</Col>
				<Col>
					<Checkbox
						value={isCheckedPropertyMeasures('Handheld fire extinguisher')}
						onChange={state => handleMeasuresChanges('Handheld fire extinguisher', state)}
						icon={'handHeldFireExt'}
						text={'Handheld fire extinguisher'}
						info={infoStrings['handheldExtinguisher']}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col className="d-flex">
					<Checkbox
						value={isCheckedPropertyMeasures('Smoke detectors')}
						onChange={state => handleMeasuresChanges('Smoke detectors', state)}
						icon={'smokeDetector'}
						text={'Smoke detectors'}
						info={infoStrings['smokeDetector']}
					/>
				</Col>
			</Row>
			{/* clear selection */}
			<Row className="mb-4">
				<Col
					onClick={() => handleChange('Measures', [])}
					style={{ cursor: 'pointer' }}
					className="d-flex align-items-center"
				>
					<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
					<Text>Clear selection</Text>
				</Col>
			</Row>
			<Divider style={{ margin: '1rem 0' }} />
			<Accordion
				headerStyle={{
					color: theme.colors.gray7,
					fontSize: '24px',
					marginBottom: Type === 'PROPERTYBASEMENT' ? '-1rem' : null,
				}}
				defaultActiveKey={state?.quote?.PolicyName === 'SFSP' ? 'additional_info' : ''}
				variant="transparent"
				accordionList={additionalInfoAccordianList}
			/>
		</>
	)
}

const BasementUI = ({
	basementData,
	handleChange,
	handleMeasuresChanges,
	isDataPrefilled,
	onResetPrefilledData,
	Type,
}) => {
	const { setIsLoading, setLoadingText } = useLoadingOverlay()
	const [state] = useContext(Context)

	const fetch = useFetch()

	const fireFightingFileInput = useRef()
	const surroundingAreasFileInput = useRef()

	const triggerFireFightingFileUpload = () => fireFightingFileInput?.current?.click()

	const triggerSurroundingAreasFileUpload = () => surroundingAreasFileInput?.current?.click()

	const handleFileUpload = async (files, key) => {
		const file = files[0]

		setLoadingText('Uploading...')
		setIsLoading(true)

		const uploadUrl = await fetch.getSignedUrl()

		const uploadResult = await fetch.uploadBlobToSignedUrl(uploadUrl, file)

		const s3Link = parseLinkFromSignedUrl(uploadResult?.url)

		setIsLoading(false)

		const ImagesArray = basementData?.Images

		const updatedImages = {
			...ImagesArray,
			[key]: [...ImagesArray[key], s3Link],
		}

		handleChange('Images', updatedImages)
	}

	const removeImage = (img, key) => {
		Swal.fire(imageDeleteConfirmOptions).then(result => {
			if (result.isConfirmed) {
				const ImagesArray = basementData?.Images

				const updatedImages = {
					...ImagesArray,
					[key]: ImagesArray[key].filter(el => el !== img),
				}

				handleChange('Images', updatedImages)

				Swal.fire('Your file has been deleted')
			}
		})
	}
	const firefightingUploadedImagesMap =
		basementData?.Images?.FireFightingEquipments?.length > 0 &&
		basementData?.Images?.FireFightingEquipments?.map((img, key) => (
			<FileUploaded
				key={key}
				imageUrl={img}
				onDelete={() => removeImage(img, 'FireFightingEquipments')}
			/>
		))

	const surroundingAreasUploadedImagesMap =
		basementData?.Images?.SurroundingAreas?.length > 0 &&
		basementData?.Images?.SurroundingAreas?.map((img, key) => (
			<FileUploaded
				key={key}
				imageUrl={img}
				onDelete={() => removeImage(img, 'SurroundingAreas')}
			/>
		))

	const handleIntegerVals = (fieldName, val) => {
		const convertedVal = val ? parseInt(val) : ''
		handleChange(fieldName, convertedVal)
	}

	const isCheckedBasementMeasures = value => basementData?.Measures?.indexOf(value) !== -1

	const additionalInfoAccordianList = [
		{
			id: 'additional_info',
			header: (
				<>
					Additional Questions{' '}
					<sup style={{ color: theme.colors.secondary2, fontWeight: 'normal' }}> Optional</sup>
				</>
			),
			content: (
				<>
					<Divider style={{ marginTop: '2rem' }} />
					<Row className="mt-3">
						<Col className="d-flex align-items-center mb-3">
							<Icon name="surroundingAreas" className="me-3" />
							<Text type="body1">Upload images of surrounding areas</Text>
							<Tooltip icon="knowMore" className="ms-3" text={infoStrings['uploadSurroundings']} />
						</Col>
					</Row>
					<Row>
						<div className="d-flex mt-4 mb-4">
							<HiddenFileInput
								ref={surroundingAreasFileInput}
								onChange={e => handleFileUpload(e.target.files, 'SurroundingAreas')}
							/>
							<FileUploader onClick={triggerSurroundingAreasFileUpload} />
							{surroundingAreasUploadedImagesMap}
						</div>
					</Row>
					<Divider />
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="otherMeasures" className="me-3" />
							<Text type="body1">Any other preventative initatives - please specify below</Text>
							<Tooltip icon="knowMore" className="ms-3" text={infoStrings['anyOtherInitiatives']} />
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={basementData?.OtherPreventativeMeasures || ''}
								onChange={e => handleChange('OtherPreventativeMeasures', e.target.value)}
								placeholder="Enter here"
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="firestation" className="me-3" />
							<Text type="body1">
								What is the distance from this risk location to the nearest fire station?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={basementData?.DistFireStation || ''}
								onChange={e => handleIntegerVals('DistFireStation', e.target.value)}
								placeholder="Enter distance in KMs"
								type="number"
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-2">
							<Icon name="railwaycrossing" className="me-3" />
							<Text type="body1">
								Is there a railway crossing in between the public fire station and the risk
								location?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4 d-flex">
							<Radio
								name="basement-railwaycross"
								id="basement-railwaycross-yes"
								value={basementData?.RailwayCrossing === true}
								onClick={() => handleChange('RailwayCrossing', true)}
								text="Yes"
								secondary={true}
								style={{ width: '154px' }}
							/>
							<Radio
								name="basement-railwaycross"
								id="basement-railwaycross-no"
								value={basementData?.RailwayCrossing === false}
								onClick={() => handleChange('RailwayCrossing', false)}
								text="No"
								secondary={true}
								style={{ width: '154px' }}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="d-flex align-items-center mb-4">
							<Icon name="openwaterbody" className="me-3" />
							<Text type="body1">
								What is the distance from this risk location to the nearest open water body?
							</Text>
						</Col>
					</Row>
					<Row>
						<Col className="mb-4">
							<Input
								value={basementData?.DistWaterBody || ''}
								onChange={e => handleIntegerVals('DistWaterBody', e.target.value)}
								placeholder="Enter distance in KMs"
								type="number"
							/>
						</Col>
					</Row>
				</>
			),
		},
	]

	return (
		<>
			<Row className="mt-3">
				<Col className="d-flex align-items-center">
					<Icon name="amc" className="me-3" />
					<Text type="body1" className="flex-fill">
						Is an AMC for fire appliances in force?
						<Tooltip icon="knowMore" text={infoStrings['amcForce']} />
					</Text>
					<Radio
						name="basement-amc"
						id="basement-amc-yes"
						value={basementData?.AMC === true}
						onClick={() => handleChange('AMC', true)}
						text="Yes"
						secondary={true}
						style={{ width: '154px' }}
					/>
					<Radio
						name="basement-amc"
						id="basement-amc-no"
						value={basementData?.AMC === false}
						onClick={() => handleChange('AMC', false)}
						text="No"
						secondary={true}
						style={{ width: '154px' }}
					/>
				</Col>
			</Row>

			{Type === 'PROPERTYBASEMENT' ? (
				<Row className="mt-3">
					<Col>
						<Checkbox
							onChange={onResetPrefilledData}
							text={
								<>
									Fire risk protection mechanisms are the same as Building{' '}
									<span style={{ color: '#eb5757' }}>*</span>
								</>
							}
							value={isDataPrefilled}
						/>
					</Col>
				</Row>
			) : null}

			<Divider />

			{isDataPrefilled ? null : (
				<>
					<Text className="mb-3">Select all that apply</Text>
					<Row className="mb-3">
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Gas Flooding System')}
								onChange={state => handleMeasuresChanges('Gas Flooding System', state)}
								icon={'gasFlooding'}
								text={'Gas Flooding System'}
								info={infoStrings['glassFlooding']}
							/>
						</Col>
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Fire Alarm System')}
								onChange={state => handleMeasuresChanges('Fire Alarm System', state)}
								icon={'fireAlarm'}
								text={'Fire Alarm System'}
								info={infoStrings['fireAlarm']}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Foam System')}
								onChange={state => handleMeasuresChanges('Foam System', state)}
								icon={'foamSystem'}
								text={'Foam System'}
								info={infoStrings['foamSystem']}
							/>
						</Col>
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Sprinkler')}
								onChange={state => handleMeasuresChanges('Sprinkler', state)}
								icon={'sprinkler'}
								text={'Sprinkler'}
								info={infoStrings['sprinkler']}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Small bore hose reel')}
								onChange={state => handleMeasuresChanges('Small bore hose reel', state)}
								icon={'hoseReel'}
								text={'Small bore hose reel'}
								info={infoStrings['smallBore']}
							/>
						</Col>
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Hydrant')}
								onChange={state => handleMeasuresChanges('Hydrant', state)}
								icon={'hydrant'}
								text={'Hydrant'}
								info={infoStrings['hydrant']}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Fixed Water Spray')}
								onChange={state => handleMeasuresChanges('Fixed Water Spray', state)}
								icon={'fixedWaterSpray'}
								text={'Fixed Water Spray'}
								info={infoStrings['fireWaterSpray']}
							/>
						</Col>
						<Col>
							<Checkbox
								value={isCheckedBasementMeasures('Handheld fire extinguisher')}
								onChange={state => handleMeasuresChanges('Handheld fire extinguisher', state)}
								icon={'handHeldFireExt'}
								text={'Handheld fire extinguisher'}
								info={infoStrings['handheldExtinguisher']}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col className="d-flex">
							<Checkbox
								value={isCheckedBasementMeasures('Smoke detectors')}
								onChange={state => handleMeasuresChanges('Smoke detectors', state)}
								icon={'smokeDetector'}
								text={'Smoke detectors'}
								info={infoStrings['smokeDetector']}
							/>
						</Col>
					</Row>

					{/* {clearSelection} */}
					<Row className="mb-3">
						<Col
							onClick={() => handleChange('Measures', [])}
							style={{ cursor: 'pointer' }}
							className="d-flex align-items-center"
						>
							<Icon name="minus" color={theme.colors.red} size="16px" className="me-2" />
							<Text>Clear selection</Text>
						</Col>
					</Row>
					<Divider />
				</>
			)}

			<Row>
				<Col className="d-flex align-items-center mb-3">
					<Icon name="firefighting" className="me-3" />
					<Text type="body1">Upload photos of the firefighting equipment</Text>
				</Col>
			</Row>
			<Row>
				<div className="d-flex mt-4 mb-4">
					<HiddenFileInput
						ref={fireFightingFileInput}
						onChange={e => handleFileUpload(e.target.files, 'FireFightingEquipments')}
					/>
					<FileUploader onClick={triggerFireFightingFileUpload} />
					{firefightingUploadedImagesMap}
				</div>
			</Row>

			{isDataPrefilled ? null : (
				<>
					<Divider style={{ margin: '1rem 0' }} />
					<Accordion
						headerStyle={{
							color: theme.colors.gray7,
							fontSize: '24px',
							marginBottom: Type === 'PROPERTYBASEMENT' ? '-1rem' : null,
						}}
						variant="transparent"
						defaultActiveKey={state?.quote?.PolicyName === 'SFSP' ? 'additional_info' : ''}
						accordionList={additionalInfoAccordianList}
					/>
				</>
			)}
		</>
	)
}

const TitleText = styled(Text)`
	font-size: 24px;
	font-weight: 700;
`
