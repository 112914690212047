import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text, theme, Select, Input } from 'verak-ui'
import { InputGroup } from '../../PropertyRiskDataCollection/commonUtils/commonExports'

const FormInput = styled(Input)`
	width: 100%;
`

const StyledSelect = styled(Select)`
	width: 100%;
	height: 48px;
	background-color: #fff;
	border-width: 0.5px;
`

export const typesOfFacilities = [
	'Shop',
	'Godown/Warehouse',
	'Office',
	'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen',
	'Factory/Manufacturer',
	'Motor vehicle showroom',
	'Motor vehicle garage',
	'Hospitals, Clinics, Diagnostic centres',
	'Petrol, Diesel Pump',
	'Schools, colleges, coaching centers, skill training institutes and other educational institutions',
	'Laundries / Dry Cleaning',
	'Indoor stadiums, Health Club, Gymnasium and Swimming pool',
]

const facilityToNatureMap = {
	Shop: [
		'Retailing of FMCG/Grocery products',
		'Retailing of medical/pharmaceutical products',
		'Retailing of garments',
		'Retailing of electronic/electrical products',
		'Retailing of hardware products',
		'Retailing of jewellery',
		'Retailing of plastic products',
		'Retailing of chemical products/fertilizers',
		'Retailing of paints',
		'Retailing of Furniture',
		'Retailing of footwear',
		'Retailing of stationery products',
		'Beauty Salon, Beauty Parlour, Cosmetic shop',
	],
	'Godown/Warehouse': [
		'Storage of FMCG/Grocery products',
		'Storage of garments',
		'Storage of furniture',
		'Storage of electronic/electrical products',
		'Storage of hardware products',
	],
	Office: [
		'Real estate',
		'Consultancy',
		'Digital Marketing',
		'Travel Agency',
		'IT Services (Software)',
		'Legal Services',
		'Financial Service (Loan, mutual funds etc)',
		'Interior Designing Services',
	],
	'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen': [
		'Restaurant',
		'Cafe',
		'Hotel',
		'Resort',
		'Canteen',
		'Cloud Kitchen',
	],
	'Factory/Manufacturer': [
		'Garments',
		'Electronic/Electrical Products',
		'Furniture/Wooden Materials',
		'Spices',
		'Plastic Materials',
		'Hardware Products',
		'Rubber Products',
		'Paper Products',
		'Food Items',
		'Oil',
		'Steel Materials',
		'Automotive Parts',
		'Card-Board Boxes',
		'Medicines/Pharma Products',
		'Paints',
		'Footwear',
		'Detergent Powder',
		'Ice-Cream',
		'Cloth Materials (Other than garments)',
		'Tiles/Marbles',
		'Coconuts',
		'Glass Materials',
		'Chemicals',
		'Making of sweets/confectionery items',
		'Steel/Aluminium/Metal (Workshop)',
	],
	'Petrol, Diesel Pump': ['With CNG', 'Without CNG'],
}

export const hideNOBforBusinessTypes = [
	'Motor vehicle showroom',
	'Motor vehicle garage',
	'Hospitals, Clinics, Diagnostic centres',
	'Schools, colleges, coaching centers, skill training institutes and other educational institutions',
	'Laundries / Dry Cleaning',
	'Indoor stadiums, Health Club, Gymnasium and Swimming pool',
]

const inputGroupWrapperClassName = {
	single: 'col-md-4',
	multiple: 'col-md-3 ms-3',
	whole: 'col-md-6',
}

const getOccupancyValue = (occupancy, key) => {
	const occupancyValue = occupancy.split(':-')
	if (key === 'Type' && occupancyValue[0]) {
		return occupancyValue[0]
	} else if (key === 'Nature' && occupancyValue[1]) {
		return occupancyValue[1]
	}
	return ''
}

const PrimaryOccupancySelector = ({
	value,
	onChange,
	isRiskLocationDetailsScreen = false,
	riskLocationType = 'single',
	showHeaderLabel = true,
}) => {
	const [isTypeOther, setIsTypeOther] = useState(false)
	const [isNatureOther, setIsNatureOther] = useState(false)

	const typeValue = getOccupancyValue(value, 'Type')
	const natureValue = getOccupancyValue(value, 'Nature')

	let subTypeOfBusiness = facilityToNatureMap[typeValue] || []

	useEffect(() => {
		if (!typesOfFacilities.includes(typeValue) && typeValue !== '') {
			setIsTypeOther(true)
		}

		if (!subTypeOfBusiness.includes(natureValue) && natureValue !== '') {
			setIsNatureOther(true)
		}
	}, [])

	let facilityTypes = [
		<option disabled key="default">
			Enter here
		</option>,
		typesOfFacilities.map(facilityItem => (
			<option value={facilityItem} key={facilityItem}>
				{facilityItem}
			</option>
		)),
		<option value="Other (please mention)" key={'others'}>
			Other (please mention)
		</option>,
	]

	let subTypeOfBusinessOptions = [
		<option disabled key="default">
			Enter here
		</option>,
		subTypeOfBusiness.map(item => (
			<option value={item} key={item}>
				{item}
			</option>
		)),
		<option value="Other (please mention)" key={'others'}>
			Other (please mention)
		</option>,
	]

	const handleFacilityTypeChange = valueToUpdate => {
		if (valueToUpdate == 'Other (please mention)') {
			setIsTypeOther(true)
			valueToUpdate = ''
		} else if (isTypeOther) {
			setIsTypeOther(false)
		}

		if (isNatureOther) {
			setIsNatureOther(false)
		}
		handleOccupancyChange('Type', value, valueToUpdate)
	}

	const handleNatureChange = valueToUpdate => {
		if (valueToUpdate == 'Other (please mention)') {
			setIsNatureOther(true)
			valueToUpdate = ''
		} else if (isNatureOther) {
			setIsNatureOther(false)
		}
		handleOccupancyChange('Nature', value, valueToUpdate)
	}
	const handleOccupancyChange = (key, currentValue, updatedValue) => {
		const currentOccupancy = currentValue.split(':-')
		let combinedOccupancy = ''
		if (key === 'Type') {
			// exclude ":-" for Motor showrooms, motor garage, which dont have nature of business
			if (hideNOBforBusinessTypes.includes(updatedValue)) {
				combinedOccupancy = updatedValue
			} else {
				combinedOccupancy = `${updatedValue}:-`
			}
		} else if (key === 'Nature') {
			combinedOccupancy = `${currentOccupancy[0] || ''}:-${updatedValue}`
		}
		onChange('PrimaryOccupancy', {
			SubCategory: combinedOccupancy,
		})
	}

	let freeTextPlaceholder = 'For e.g: Garment, Furniture etc.'

	if (typeValue == 'Office') {
		freeTextPlaceholder = 'For e.g: IT Services, Legal Services Etc'
	}

	// hiding nature of business check
	let hideNOB = false
	if (hideNOBforBusinessTypes.includes(typeValue)) {
		hideNOB = true
	}

	if (isRiskLocationDetailsScreen) {
		const wrapperClassName = inputGroupWrapperClassName[riskLocationType]
		return (
			<>
				<div className={`${wrapperClassName}`}>
					{/* type of facility selection */}
					<InputGroup
						label="Type of facility"
						leftBorder={true}
						showLabel={showHeaderLabel}
						style={{ margin: '0', padding: '0' }}
					>
						<StyledSelect
							style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
							compulsory
							className="mb-2"
							value={isTypeOther ? 'Other (please mention)' : typeValue}
							onChange={selected => handleFacilityTypeChange(selected)}
						>
							{facilityTypes}
						</StyledSelect>

						{isTypeOther ? (
							<FormInput
								placeholder="Mention type of facility here"
								value={typeValue}
								className="mb-3"
								onChange={e => handleOccupancyChange('Type', value, e.target.value)}
							/>
						) : null}
					</InputGroup>
				</div>

				<div className={`${wrapperClassName}`}>
					<InputGroup
						label="Nature of Business"
						leftBorder={true}
						showLabel={showHeaderLabel}
						style={{ margin: '0', padding: '0' }}
					>
						{!hideNOB ? (
							<>
								{subTypeOfBusiness.length > 0 ? (
									<StyledSelect
										style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
										compulsory
										className="mb-2"
										value={isNatureOther ? 'Other (please mention)' : natureValue}
										onChange={selected => handleNatureChange(selected)}
									>
										{subTypeOfBusinessOptions}
									</StyledSelect>
								) : null}

								{isNatureOther || subTypeOfBusiness.length === 0 ? (
									<FormInput
										placeholder={freeTextPlaceholder}
										compulsory
										value={natureValue}
										className="mb-3"
										onChange={e => {
											handleOccupancyChange('Nature', value, e.target.value)
										}}
									/>
								) : null}
							</>
						) : (
							<span className="ms-3">--</span>
						)}
					</InputGroup>
				</div>
			</>
		)
	}

	return (
		<>
			<Text color={theme.colors.gray2} className="mb-2">
				Type of Facility <span style={{ fontWeight: 'bold', color: theme.colors.red }}>*</span>
			</Text>
			<StyledSelect
				style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
				compulsory
				className="mb-2"
				value={isTypeOther ? 'Other (please mention)' : typeValue}
				onChange={selected => handleFacilityTypeChange(selected)}
			>
				{facilityTypes}
			</StyledSelect>

			{isTypeOther ? (
				<FormInput
					placeholder="Mention type of facility here"
					value={typeValue}
					className="mb-3"
					onChange={e => handleOccupancyChange('Type', value, e.target.value)}
				/>
			) : null}

			{!hideNOB ? (
				<>
					<Text color={theme.colors.gray2} className="mb-2">
						Nature of Business Conducted{' '}
						<span style={{ fontWeight: 'bold', color: theme.colors.red }}>*</span>
					</Text>
					{subTypeOfBusiness.length > 0 ? (
						<StyledSelect
							style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
							compulsory
							className="mb-2"
							value={isNatureOther ? 'Other (please mention)' : natureValue}
							onChange={selected => handleNatureChange(selected)}
						>
							{subTypeOfBusinessOptions}
						</StyledSelect>
					) : null}

					{isNatureOther || subTypeOfBusiness.length === 0 ? (
						<FormInput
							placeholder={freeTextPlaceholder}
							compulsory
							value={natureValue}
							className="mb-3"
							onChange={e => {
								handleOccupancyChange('Nature', value, e.target.value)
							}}
						/>
					) : null}
				</>
			) : null}
		</>
	)
}

export default PrimaryOccupancySelector
