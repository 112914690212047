import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import LocationDetails from '../../../../../assets/training/LocationDetails.svg'
import BusinessDetails from '../../../../../assets/training/BusinessDetails.svg'
import UnderInsurance from '../../../../../assets/training/UnderInsurance.svg'
import Rs5000Cut from '../../../../../assets/training/Rs5000Cut.svg'

export default function CheatSheet_TermsConditions() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={LocationDetails}>
						<InfoText fontWeight="700">Location Details must be correct</InfoText>
					</InfoBox>
					<ul>
						<li>
							<b>Pin Code, Address and Floor Number</b> given by customer should be correct
							otherwise claim can be rejected
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={BusinessDetails}>
						<InfoText fontWeight="700">Business Details must be correct</InfoText>
					</InfoBox>
					<ul>
						<li>
							If customer says they have <b>fire safety & theft safety measures</b> - but these are
							NOT found in claims inspection - claim can be rejeced
						</li>
					</ul>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={Rs5000Cut}>
						<InfoText fontWeight="700">Har claim se ₹5,000 cut hoga</InfoText>
					</InfoBox>
					<ul>
						<li>Please explain why</li>
						<li>
							<b>"Excess" har ek insurance product me hai</b>
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={UnderInsurance}>
						<InfoText fontWeight="700">Under-Insurance clause</InfoText>
					</InfoBox>
					<ul>
						<li>
							Explain the <b>15% extra cover</b>
						</li>
						<li>
							<b>Ye condition business owner ke benefit ke liye hai </b> - stock ki value har din
							change hoti hai
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
