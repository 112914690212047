import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { convertToNumber } from '../../../../utilities/convertToNumber'

import { Button, Icon, Input, Space, Text, theme, Divider, Alert } from 'verak-ui'
// import { filterArrayObjects } from '../../../../utilities/filterArrayObjects'
import {
	// otherItem,
	propertyItemsDefaults,
	propertyOptions,
	singleItemDefaults,
	singleItemOps,
} from './options'
// import { GoodsInTrustCheck } from './SharedComponents'

const CustomInput = styled(Input)`
	width: ${props => (props.$removeBtn ? '260px' : '300px')};
	@media (max-width: 920px) {
		width: ${props => (props.$removeBtn ? '110px' : '150px')};
	}
	@media (max-width: 768px) {
		width: 100%;
		flex: 1;
	}
`
const PropertyItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
	}
`
const PropertyItemWrapper = styled.div`
	&:not(:last-of-type) {
		border-bottom: 0.5px solid ${theme.colors.gray6};
	}
`

const OtherItemContainer = styled.div`
	display: grid;
	grid-template-columns: ${({ $removeBtn }) => ($removeBtn ? '1fr 0.1fr' : '1fr')};
`

const iconStyles = {
	borderRadius: '8px',
}

const PropertyDataItem = ({
	icon,
	title,
	inputName,
	setDataItem,
	value,
	removeBtn = false,
	disabled = false,
	error = false,
	itemKey,
	removeItem = () => {},
}) => {
	const renderRemoveBtn = removeBtn ? (
		<Button
			bgColor={theme.colors.white}
			icon="remove"
			label=""
			onClick={removeItem}
			className="p-2"
			textColor={theme.colors.primary}
		/>
	) : null

	const handleNumberInput = (inputName, e, itemKey) => {
		const numValue = convertToNumber(e.target.value)
		setDataItem(inputName, numValue, itemKey)
	}

	return (
		<PropertyItemWrapper className="d-flex align-items-center">
			<Icon name={icon} style={iconStyles} className="me-3" />
			<PropertyItem className="py-3 w-100">
				<Text type="body1" style={{ letterSpacing: '0' }}>
					{title}
				</Text>

				<Space mobileY={1} />
				<OtherItemContainer $removeBtn={removeBtn}>
					<CustomInput
						$removeBtn={removeBtn}
						placeholder="Enter value here"
						name={inputName}
						value={value}
						onChange={e => handleNumberInput(inputName, e, itemKey || '')}
						disabled={disabled}
						error={error}
						autoComma
						rupee
					/>
					{renderRemoveBtn}
				</OtherItemContainer>
			</PropertyItem>
		</PropertyItemWrapper>
	)
}

function getPropertyDataOps(name) {
	return propertyOptions.find(el => el.inputName === name) || {}
}

export default function PropertyData({
	onSave,
	PropertyList,
	isActiveTab,
	disableProceed,
	levelIndex,
}) {
	const [dataItems, setDataItems] = useState(() => {
		return propertyItemsDefaults.map(pItem => {
			const value = PropertyList.find(item => {
				return item.Name === pItem.Name
			})
			return {
				...pItem,
				Amount: value?.Amount ? parseFloat(value.Amount) : '',
			}
		})
	})
	const [singleItem, setSingleItem] = useState(() => {
		const extractedVal = PropertyList.find(el => el.Name === 'MaxValSingleItem')?.Amount
		return {
			...singleItemDefaults,
			Amount: extractedVal ? parseFloat(extractedVal) : '',
		}
	})

	const ValuebleContentsAmt =
		parseFloat(dataItems.find(el => el.Name === 'ValuableContents').Amount) || 0
	const OtherContentsAmt = parseFloat(dataItems.find(el => el.Name === 'Other').Amount) || 0
	const MaxValSingleItemAmt = parseFloat(singleItem.Amount) || 0

	const isMaxValSingleItemLess =
		ValuebleContentsAmt >= MaxValSingleItemAmt || OtherContentsAmt >= MaxValSingleItemAmt

	const singleItemMandatory = ValuebleContentsAmt || OtherContentsAmt

	const singleItemError = Boolean(
		singleItemMandatory && (!MaxValSingleItemAmt || !isMaxValSingleItemLess)
	)

	useEffect(() => {
		const filteredPropertyItems = dataItems.filter(el => el.Amount)
		if (singleItem.Amount && filteredPropertyItems.length) {
			filteredPropertyItems.push(singleItem)
		}
		onSave({
			Property: filteredPropertyItems.flat(),
		})
		if (isActiveTab) {
			disableProceed(singleItemError)
		}
	}, [dataItems, singleItem, singleItemError, isActiveTab]) // eslint-disable-line

	const setSingleItemAmt = (name, val) => {
		setSingleItem({ Name: name, Amount: val ? parseFloat(val) : '' })
	}

	const setDataItemAmt = (name, amt) => {
		setDataItems(() =>
			dataItems.map(el =>
				el.Name === name
					? {
							Name: name,
							Amount: amt ? parseFloat(amt) : '',
					  }
					: el
			)
		)
	}

	const renderPropertyDataItems = dataItems.map(el => (
		<PropertyDataItem
			setDataItem={setDataItemAmt}
			value={el.Amount}
			key={el.Name}
			{...getPropertyDataOps(el.Name)}
		/>
	))

	const maxValItem = (
		<PropertyDataItem
			setDataItem={setSingleItemAmt}
			value={singleItem.Amount}
			key={singleItem.Name}
			disabled={!singleItemMandatory}
			error={singleItemError}
			{...singleItemOps}
		/>
	)

	const maxValueAlert =
		singleItemMandatory && !isMaxValSingleItemLess ? (
			<Alert
				style={{ color: theme.colors.red }}
				title="'Maximum value of a single item' cannot exceed the amount entered for 'Valuable Contents' or 'Other Content'"
			/>
		) : null

	return (
		<>
			<Divider />
			{renderPropertyDataItems}
			{maxValItem}
			{maxValueAlert}
		</>
	)
}
