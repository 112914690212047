import { useContext, useState } from 'react'
import { Context } from '../../context/Provider'
import { useLocation, useHistory } from 'react-router'
import { useFetch } from '../../api/apihook'

import Floater from './DeclarationMethods/Floater'
import DeclarationTemplate from './DeclarationMethods/DeclarationTemplate'
import FloaterDeclaration2 from './DeclarationMethods/FloaterDeclaration2'

const DisclosureMethods = () => {
	const [state, dispatch] = useContext(Context)

	const fetch = useFetch()
	const location = useLocation()
	let history = useHistory()
	const disclosureMethod = location.state?.disclosureMethod

	// FloaterDeclaration.js
	const [floaterDec, toggleFloaterDec] = useState(0)
	const [reportingMethod, setReportingMethod] = useState('')

	const setStockDisclosureMethod = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_DISCLOSURE_METHOD',
				data: {
					StockDisclosureMethod: disclosureMethod,
				},
			})

			resolve()
		})
	}

	// DeclarationBasis.js
	const handleDeclarationBasisSaveAndContinue = async method => {
		await setStockDisclosureMethod()

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: method,
				},
			})

			dispatch({
				type: 'SET_STOCK_VALUE',
				data: {
					MaxValueAllLoc: '',
					MaxValueOneLoc: '',
				},
			})
			resolve()
		})
		await fetch.saveQuote()
		if (state.quote?.PolicyName === 'SFSP') {
			history.push('/sfsp/sum-insured-methods')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}

	// Floater.js
	const handleFloaterSaveAndContinue = async (MaxValueAllLoc, MaxValueOneLoc) => {
		await setStockDisclosureMethod()

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_VALUE',
				data: {
					MaxValueAllLoc: MaxValueAllLoc || '',
					MaxValueOneLoc: MaxValueOneLoc || '',
				},
			})

			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: '',
				},
			})
			resolve()
		})

		await fetch.saveQuote()
		if (state.quote?.PolicyName === 'SFSP') {
			history.push('/sfsp/sum-insured-methods')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}

	// FloaterDeclaration.js
	const handleReportingMethod = method => {
		setReportingMethod(method)
		toggleFloaterDec(1)
	}
	const handleFloaterDeclarationSaveAndContinue = async (MaxValueAllLoc, MaxValueOneLoc) => {
		await setStockDisclosureMethod()

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_REPORTING_METHOD',
				data: {
					ReportingMethod: reportingMethod || '',
				},
			})
			resolve()
		})

		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_VALUE',
				data: {
					MaxValueAllLoc: MaxValueAllLoc || '',
					MaxValueOneLoc: MaxValueOneLoc || '',
				},
			})
			resolve()
		})
		await fetch.saveQuote()
		if (state.quote?.PolicyName === 'SFSP') {
			history.push('/sfsp/sum-insured-methods')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}

	let content = null
	if (disclosureMethod === 'FLOATER') {
		content = <Floater handleSave={handleFloaterSaveAndContinue} />
	} else if (disclosureMethod === 'DECLARATION_BASIS') {
		content = (
			<DeclarationTemplate
				handleSave={handleDeclarationBasisSaveAndContinue}
				method="Declaration Basis"
			/>
		)
	} else if (disclosureMethod === 'FLOATER_DECLARATION') {
		if (floaterDec === 0) {
			content = (
				<DeclarationTemplate
					toggleFloaterDec={toggleFloaterDec}
					handleSave={handleReportingMethod}
					method="Floater Declaration Basis"
				/>
			)
		} else {
			content = (
				<FloaterDeclaration2
					toggleFloaterDec={toggleFloaterDec}
					handleSave={handleFloaterDeclarationSaveAndContinue}
				/>
			)
		}
	}

	return <>{content}</>
}

export default DisclosureMethods
