import styled from 'styled-components'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'

import { LocationInput } from '../../components/SharedComponents'
import { StockLocationsIs0, StockLocationsIs1, StockLocationsIsMoreThan1 } from './Modals'
import { Icon, Space, Text, theme, Button, Modal, MainContainer } from 'verak-ui'

const NumberStockLocations = () => {
	const [state, dispatch] = useContext(Context)
	let NumRiskLoc = state.quote.NumRiskLoc
	const isDraftRFQ = state?.rfq?.draftRFQ?._id
	// handles the extreme edge case if the sales exec enters num risk loc and then decides to go for "stock only"
	if (state?.quote?.PolicyKind === 'STOCK_ONLY' && !isDraftRFQ) {
		NumRiskLoc = undefined
	}

	const [NumStock, setNumStock] = useState(state.quote?.NumStock || 0)
	const [modalState, setModalState] = useState(false)
	const [modalContent, setModalContent] = useState(<StockLocationsIs0 />)

	const proceed = () => {
		if (NumStock === 0) {
			setModalContent(<StockLocationsIs0 />)
		} else if (NumStock === 1) {
			setModalContent(<StockLocationsIs1 />)
		} else if (NumStock > 1) {
			setModalContent(<StockLocationsIsMoreThan1 NumStock={NumStock} />)
		}
		setModalState(true)
	}

	const incrementStockCount = () => {
		setNumStock(prevState => prevState + 1)
	}
	const decrementStockCount = () => {
		setNumStock(prevState => prevState - 1)
	}
	return (
		<MainContainer
			header="Let’s understand the client requirements"
			top={5.375}
			rightOnClick={proceed}
			rightDisabled={state?.quote?.PolicyKind === 'STOCK_ONLY' && NumStock === 0}
			// for stock only 1 needs to be minimum since he hasn't enetered a risk loc
		>
			<Space y={2.375} />
			<Icon name="box" />
			<Space y={1.5} />
			<Text type="primary" color={theme.colors.gray7}>
				How many of the {NumRiskLoc} risk locations contain stock?
			</Text>
			<Space y={1.5} />
			<FlexContainer>
				{/* Color for disabled filter needs to be modified */}
				<Button
					icon="minus"
					style={buttonStyle}
					disabled={NumStock === 0}
					applyDisableFilter={NumStock === 0}
					onClick={decrementStockCount}
				/>
				<Space x={1.25} />
				<LocationInput>{NumStock}</LocationInput>
				<Space x={1.25} />
				<Button
					icon="addBg"
					style={buttonStyle}
					disabled={NumStock >= NumRiskLoc}
					applyDisableFilter={NumStock >= NumRiskLoc}
					onClick={incrementStockCount}
				/>
			</FlexContainer>
			<Modal
				show={modalState}
				handleClose={() => setModalState(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Notice
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				{modalContent}
			</Modal>
		</MainContainer>
	)
}

export default NumberStockLocations

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
`
const buttonStyle = {
	padding: '0',
	margin: '0',
	height: 'auto',
	borderRadius: '50%',
}
