import { useState, useContext } from 'react'
import { Context } from '../../../context/Provider'

import DeWatering from './DeWatering'
import FireRisk from './FireRisk'

export default function FireRiskHandler() {
	const [state] = useContext(Context)
	const Type = state?.location?.Type

	const [currentPage, setCurrentPage] = useState(Type !== "PROPERTY" ? 0 : 1)

	const moveToFireScreen = () => {
		setCurrentPage(1)
	}

	const moveBackToDewatering = () => {
		setCurrentPage(0);
	}

	if (currentPage === 0) {
		return (
			<DeWatering onInputComplete={moveToFireScreen} />
		)
	} else {
		return (
			<FireRisk onBack={moveBackToDewatering} />
		)
	}

}
