import { nanoid } from 'nanoid'
import { useState, useContext, useEffect } from 'react'

import { Space, Text, Input, Radio, SearchableSelect, FreeTextToggle } from 'verak-ui'

import { Context } from '../../../../context/Provider'
import { filterArrObj } from '../../../../helpers/arrayOps'

import { otherUsageDefaults } from './options'
import { RenderRemoveButton, SpecifyMoreDetails } from './SharedComponents'
import {
	GridContainer,
	RadioDiv,
	SearchableSelectWrapper,
	SharedInputStyles,
	SharedRadioStyles,
} from './SharedStyles'
import { useFetch } from '../../../../api/apihook'
import { useAPIStateContext } from '../../../../context/APIStateProvider'

function initOtherActivites(state, levelIdx, propertyType) {
	const { Type, levels, SecondaryOccupancy } = state.location || {}

	const isPropertyBasement = Type === 'PROPERTYBASEMENT'

	let SecondaryOccupancyData = SecondaryOccupancy
	let levelsData = levels

	if (isPropertyBasement) {
		SecondaryOccupancyData = state.location?.[propertyType]?.SecondaryOccupancy || ''
		levelsData = state.location?.[propertyType]?.levels || []
	}
	const isMultiple = levelsData?.length
	if (isMultiple) {
		SecondaryOccupancyData = levelsData[levelIdx].SecondaryOccupancy || ''
	}
	return {
		otherUseData: SecondaryOccupancyData ? Boolean(SecondaryOccupancyData?.length) : '',
		otherActivitiesData: SecondaryOccupancyData?.length
			? SecondaryOccupancyData?.map(el => ({ ...el, id: nanoid() }))
			: [{ ...otherUsageDefaults }],
	}
}

const OtherUsage = ({
	onSave,
	isActiveTab,
	disableProceed,
	levelIdx,
	propertyType,
	toggleNoticeModal,
}) => {
	const { cachedData } = useAPIStateContext()
	const { getSubCategoryByRiskType, trackRfqEvent } = useFetch()
	const [state] = useContext(Context)
	const { otherUseData, otherActivitiesData } = initOtherActivites(state, levelIdx, propertyType)

	const { Type } = state?.location || {}

	const [otherUse, setOtherUse] = useState(otherUseData)
	const [otherActivites, setOtherActivities] = useState(otherActivitiesData)

	const [catLoading, setCatLoading] = useState(false)

	const initializeSubCategories = () => {
		if (cachedData?.SubCategories?.required) {
			return cachedData?.SubCategories?.required?.map(({ Description }) => {
				return {
					name: Description,
					value: Description,
				}
			})
		}
	}

	const [subCategoryData, setSubCategoryData] = useState(initializeSubCategories())

	const initializeFreeTextResponse = () => {
		if (cachedData?.SubCategories?.required && otherUse && otherActivites) {
			const otherUsagePresent = cachedData?.SubCategories?.required.find(
				item => item.Description === otherActivites[0].SubCategory
			)
			return !otherUsagePresent
		}
		return false
	}

	const [freeTextResponse, setFreeTextResponse] = useState(initializeFreeTextResponse())

	// update freetextresponse and subcategory when cache updates
	useEffect(() => {
		if (!freeTextResponse) {
			setFreeTextResponse(initializeFreeTextResponse())
		}

		if (!subCategoryData) {
			setSubCategoryData(initializeSubCategories())
		}
	}, [cachedData?.SubCategories?.required.length]) // eslint-disable-line

	const toggleFreeTextResponse = async val => {
		setOtherActivities([{ SubCategory: '' }]) // reset otheractivities row

		toggleNoticeModal()
		setFreeTextResponse(val)
		if (val) {
			await trackRfqEvent('Other occupancy switch from IIB to FREE TEXT')
		}
	}

	const checkOtherUsageCompletion = () => {
		if (!otherUse) {
			return true
		} else {
			let otherActivityItemsIncomplete
			otherActivityItemsIncomplete = otherActivites.some(el => !el.SubCategory)

			return !otherActivityItemsIncomplete
		}
	}
	const otherUsageComplete = checkOtherUsageCompletion()

	const getPrimarySubCategory = () => {
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'
		let primaryOccupancy

		if (isPropertyBasement) {
			const data = state?.location?.[propertyType] || {}
			if (levelIdx > -1) {
				primaryOccupancy = data?.levels?.[levelIdx]?.PrimaryOccupancy || {}
			} else {
				primaryOccupancy = data?.PrimaryOccupancy || {}
			}
		} else if (levelIdx > -1) {
			primaryOccupancy = state?.location?.levels?.[levelIdx]?.PrimaryOccupancy || {}
		} else {
			primaryOccupancy = state?.location?.PrimaryOccupancy || {}
		}

		return primaryOccupancy?.SubCategory || ''
	}

	const primarySubCategory = getPrimarySubCategory()

	const fetchSubCategories = async () => {
		if (!cachedData?.SubCategories?.required) {
			setCatLoading(true)
			await getSubCategoryByRiskType()
			setCatLoading(false)
		}
	}

	useEffect(() => {
		if (!freeTextResponse) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	useEffect(() => {
		if (isActiveTab) {
			disableProceed(!otherUsageComplete)
		}
	}, [isActiveTab, otherUsageComplete]) // eslint-disable-line

	const addNewOtherActivity = () => {
		setOtherActivities([...otherActivites, { ...otherUsageDefaults, id: nanoid() }])
	}

	const removeOtherActivity = id => {
		const list = otherActivites.filter(el => el.id !== id)
		setOtherActivities(list)
		// trigger save from here
		triggerSave(list)
	}

	const checkOtherInputs = list => {
		let isValid = true
		list.forEach(item => {
			if (isValid) {
				isValid = !!item.SubCategory
			}
		})
		return isValid
	}

	const triggerSave = list => {
		const isInputComplete = checkOtherInputs(list)

		if (isInputComplete) {
			onSave({ SecondaryOccupancy: filterArrObj(list, ['id']) })
		}
	}

	const setOtherUseItem = (id, name, value) => {
		const alteredItems = otherActivites.map(el => {
			return el.id === id ? { ...el, [name]: value } : el
		})
		setOtherActivities(alteredItems)
		triggerSave(alteredItems)
	}

	const otherUseSectionItems = otherActivites.map(el => {
		return (
			<GridContainer key={el.id} removeButtonCol={otherActivites.length > 1} className="mt-3">
				{!freeTextResponse ? (
					<SearchableSelectWrapper>
						<SearchableSelect
							style={SharedInputStyles}
							options={subCategoryData}
							value={el.SubCategory}
							key={subCategoryData?.length}
							emptyMessage={catLoading ? 'Loading...' : 'Not found'}
							onChange={val => setOtherUseItem(el.id, 'SubCategory', val)}
							search
							placeholder="Select primary use from IIB rating sub-category"
						/>
					</SearchableSelectWrapper>
				) : (
					<div className="d-flex w-100">
						<Input
							type="text"
							style={{ width: '720px' }}
							placeholder="Enter in detail"
							value={el.SubCategory}
							onChange={e => {
								setOtherUseItem(el.id, 'SubCategory', e.target.value)
							}}
						/>
					</div>
				)}

				<RenderRemoveButton
					onClick={() => removeOtherActivity(el.id)}
					renderWhen={otherActivites.length > 1}
				/>
			</GridContainer>
		)
	})

	const OtherUseSectionElement = (
		<>
			{otherUseSectionItems}

			<div className="d-flex mt-3">
				<FreeTextToggle onChange={toggleFreeTextResponse} isFreeTextResponse={freeTextResponse} />
			</div>
		</>
	)

	const otherUsageOptions = otherUse ? (
		<SpecifyMoreDetails
			title="Please provide further details of other activities performed by the client:"
			renderDetailItems={OtherUseSectionElement}
			addNewDetailItem={addNewOtherActivity}
			addItemButtonLabel="Add another nature of business"
		/>
	) : null

	const onSelect = val => {
		if (!val) {
			onSave({ SecondaryOccupancy: [] })
		}
		setOtherUse(val)
	}
	return (
		<>
			<Text type="body1" fontWeight={500}>
				Does the client use this {propertyType} for any other purpose apart from{' '}
				{primarySubCategory}?
			</Text>
			<RadioDiv className="d-flex mt-2">
				<Radio
					labelStyle={SharedRadioStyles}
					value={otherUse === true}
					onChange={() => onSelect(true)}
					text="Yes"
					name={`${propertyType}-${levelIdx}-otherUse-yes`}
				/>
				<Space x={1} />
				<Radio
					labelStyle={SharedRadioStyles}
					value={otherUse === false}
					onChange={() => onSelect(false)}
					text="No"
					name={`${propertyType}-${levelIdx}-otherUse-no`}
				/>
			</RadioDiv>
			{otherUsageOptions}
		</>
	)
}

export default OtherUsage
