import { calculateNumStock } from "./calculateNumStock"

export const determineEdgeCase1 = (numStock, RiskLocations) => {
	let actualNumStock = calculateNumStock(RiskLocations)
	
	if (numStock === 0 && actualNumStock === 1) {
		return '0to1'
	} else if (numStock === 1 && actualNumStock > 1) {
		return '1to2'
	}
}
