import { useHistory } from 'react-router'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'

import { AlignmentContainer } from '../../components/SharedContainers'
import { Icon, Space, Text, theme, Button, MainContainer } from 'verak-ui'
import { useFetch } from '../../api/apihook'

const RailwaySidingsCheck = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	let history = useHistory()

	const proceed = async value => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_RAIL_SIDING',
				data: {
					StockRailSiding: value,
				},
			})
			resolve()
		})

		history.push('/stock-disclosure-choice/stock-select')
	}
	return (
		<MainContainer
			header="Let’s understand which Stock disclosure methods are applicable for the client"
			top={5.5}
		>
			<AlignmentContainer>
				<Space y={3.125} />
				<Icon name="railwaySidings" />
				<Space y={1.5} />
				<Text type="primary" color={theme.colors.gray7}>
					Does the client have any stock stored at railway sidings?
				</Text>
				<Space y={1.5} />
				<div className="flex-container">
					<Button
						label="No"
						icon="no"
						iconStyles={iconStyle}
						style={buttonStyle}
						secondary
						onClick={() => proceed(false)}
					/>
					<Space x={1} />
					<Button
						label="Yes"
						icon="yes"
						iconStyles={iconStyle}
						style={buttonStyle}
						onClick={() => proceed(true)}
					/>
				</div>
			</AlignmentContainer>
		</MainContainer>
	)
}

export default RailwaySidingsCheck

const iconStyle = {
	marginRight: '0.5rem',
}
const buttonStyle = {
	width: '200px',
	height: '48px',
}
