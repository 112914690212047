import { useEffect } from 'react'
import { useContextApi } from '../context/Provider'
import { Container, Spinner } from 'react-bootstrap'
import { Button, Text } from 'verak-ui'
import { useAuth0 } from '@auth0/auth0-react'
import SQSHome from '../screens/SQS/SQSHome'

// home screen
const HomeScreen = () => {
	const { isLoading, isAuthenticated, user, loginWithPopup } = useAuth0()

	const [state, dispatch] = useContextApi()

	useEffect(() => {
		if (isAuthenticated && user?.email?.includes('fos_') && !state?.app?.isFosUser) {
			dispatch({ type: 'SET_FOS_USER' })
		}
		if (isAuthenticated && user.nickname) {
			window.clarity('set', 'user-name', user.nickname)
			window.clarity('identify', user.nickname)
		}
	}, [isAuthenticated]) // eslint-disable-line
	if (isLoading) {
		return (
			<div className="d-flex flex-column align-items-center">
				<Text className="my-4" type="body1">
					Loading...
				</Text>
				<Spinner animation="border" variant="secondary" />
			</div>
		)
	}

	if (!isAuthenticated) {
		return (
			<Container className="d-flex align-items-center justify-content-center">
				<Button label="Login to continue" onClick={loginWithPopup} />
			</Container>
		)
	} else {
		return <SQSHome />
	}
}

export default HomeScreen
