import { Icon, Text, Space, Button, theme } from 'verak-ui'

export const determineWhichNonSFSPQuote = sfspEdgeCase => {
	let policyName = null
	let thresholdVal = null
	if (sfspEdgeCase === 'SFSPtoBSUS') {
		policyName = 'BSUS'
		thresholdVal = 5
	} else if (sfspEdgeCase === 'SFSPtoBLUS') {
		policyName = 'BLUS'
		thresholdVal = 50
	}

	return { policyName, thresholdVal }
}

export const NoticeForSFSPEdgeCase = ({ sfspEdgeCase, proceed }) => {
	let content = null
	if (sfspEdgeCase === 'BSUStoSFSP' || sfspEdgeCase === 'BLUStoSFSP') {
		content = (
			<>
				<Icon name="floor" size="100px" style={{ margin: '0 auto' }} />
				<Space y={1.5} />
				<CustomText>The quote has crossed ₹50 Crore in Sum Insured.</CustomText>
				<Space y={1.5} />
				<CustomText>Hence, it will now be treated as a SFSP product.</CustomText>
				<Space y={1.5} />
				<CustomText>There are a few more questions which need to be filled out.</CustomText>
				<Space y={1.5} />
				<Button
					label="Continue"
					iconAlign="right"
					icon="rightArrow"
					iconStyles={{ marginLeft: '0.5rem' }}
					onClick={proceed}
					style={buttonStyle}
				/>
			</>
		)
	} else {
		// SFSP to BLUS or BSUS
		const { policyName, thresholdVal } = determineWhichNonSFSPQuote(sfspEdgeCase)

		content = (
			<>
				<Icon name="floor" size="100px" style={{ margin: '0 auto' }} />
				<Space y={1.5} />
				<CustomText>The Quote has fallen below the ₹{thresholdVal} Crore Sum Insured.</CustomText>
				<Space y={1.5} />
				<CustomText>Hence, it will now be treated as a {policyName} product.</CustomText>
				<Space y={1.5} />
				<Button
					label="Continue"
					iconAlign="right"
					icon="rightArrow"
					iconStyles={{ marginLeft: '0.5rem' }}
					onClick={proceed}
					style={buttonStyle}
				/>
			</>
		)
	}

	return content
}

export const InfoAboutNonSFSPQuote = ({ sfspEdgeCase, proceed }) => {
	const { policyName } = determineWhichNonSFSPQuote(sfspEdgeCase)

	return (
		<>
			<Icon name="floor" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1.5} />
			<CustomText>
				Since this is now a {policyName} quote, please note the following changes:
			</CustomText>
			<Space y={1.5} />
			<ol style={{ textAlign: 'left' }}>
				<li>
					Default claims valuation method of Reinstatement value will now apply to Sum Insureds.
				</li>
				<li>You can no longer opt for the Escalation clause.</li>
				<li>
					You can no longer opt for any add-ons or remove any in-built covers from your policy.
				</li>
			</ol>
			<Space y={1.5} />
			<Button
				label="Continue"
				iconAlign="right"
				icon="rightArrow"
				iconStyles={{ marginLeft: '0.5rem' }}
				onClick={proceed}
				style={buttonStyle}
			/>
		</>
	)
}

export const PlinthFoundationModal = ({ proceedForNo, proceedForYes }) => {
	return (
		<>
			<Icon name="floor" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1.5} />
			<CustomText>
				Plinth & Foundation can be insured separately across all risk locations.
			</CustomText>
			<Space y={1.5} />
			<CustomText>Add Plinth & Foundation</CustomText>
			<Space y={1.5} />
			<div className="flex-container">
				<Button
					label="No"
					icon="no"
					iconStyles={{ marginRight: '0.25rem' }}
					secondary
					onClick={proceedForNo}
					style={buttonStyle}
				/>
				<Space x={1} />
				<Button
					label="Yes"
					icon="yes"
					iconStyles={{ marginRight: '0.25rem' }}
					onClick={proceedForYes}
					style={buttonStyle}
				/>
			</div>
		</>
	)
}

export const SFSPFinalConfirmation = ({ proceed }) => {
	return (
		<>
			<Icon name="floor" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1.5} />
			<CustomText>Since this is now a SFSP quote, please note the following change:</CustomText>
			<Space y={1.5} />
			<ol style={{ textAlign: 'left' }}>
				<li>
					For this policy, add-ons can now be opted for and any in-built covers can be removed from
					the policy if required.
				</li>
			</ol>
			<Space y={1.5} />
			<Button
				label="Continue"
				iconAlign="right"
				icon="rightArrow"
				iconStyles={{ marginLeft: '0.5rem' }}
				onClick={proceed}
				style={buttonStyle}
			/>
		</>
	)
}

const CustomText = ({ children, color = theme.colors.primary }) => {
	return (
		<Text color={color} style={{ fontWeight: 700, fontSize: '18px' }}>
			{children}
		</Text>
	)
}

const buttonStyle = {
	width: '100%',
	height: '64px',
	fontSize: '18px',
}
