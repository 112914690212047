import { useState } from 'react'

import { Modal, theme, Text, Radio, Checkbox, Space, Button, Input, Switch } from 'verak-ui'
import { compulsoryStyle } from '../../../components/SharedComponents'
import { useFetch } from '../../../api/apihook'
import styled from 'styled-components'

const SpecificRequestsContainer = styled.div`
	position: relative;
	opacity: ${props => (props.blocked ? 0.5 : 1)};
`

const RequestForQuotationModal = ({ handleClose, rfqData }) => {
	const [typeOfRequest, setTypeOfRequest] = useState('any')

	const [wantsGovInsurer, setGovInsurer] = useState(false)
	const [wantsPrivInsurer, setPrivInsurer] = useState(false)
	const [govReason, setGovReason] = useState('')
	const [privReason, setPrivReason] = useState('')
	const [generalRequest, setGeneralRequest] = useState('')

	const [wantsAddOn, setWantsAddOn] = useState(false)
	const [addOn, setAddOn] = useState('')
	const { escalateRfq } = useFetch()
	const titleEle = (
		<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
			Specifications for requesting new quotes
		</Text>
	)

	const requestForQuotes = async () => {
		let insurerType = ''

		if (typeOfRequest === 'any') {
			insurerType = 'Any'
		} else {
			if (wantsGovInsurer && wantsPrivInsurer) {
				insurerType = 'Government_Private'
			} else if (wantsGovInsurer) {
				insurerType = 'Government'
			} else if (wantsPrivInsurer) {
				insurerType = 'Private'
			} else {
				insurerType = 'Any'
			}
		}
		let toSend = {
			InsurerType: insurerType,
			GovtReason: govReason,
			PrivateReason: privReason,
			Addon: addOn,
			GeneralRequest: '',
		}
		if (insurerType === 'Any') {
			toSend = {
				...toSend,
				GovtReason: '',
				PrivateReason: '',
				Addon: '',
				GeneralRequest: generalRequest,
			}
		}

		const result = await escalateRfq(rfqData.id, toSend)
		if (!result.error) {
			handleClose()
		}
	}

	const isAnySelected = typeOfRequest === 'any'

	let requestForQuotedDisabled = (wantsAddOn && !addOn) || (!wantsGovInsurer && !wantsPrivInsurer)
	if (isAnySelected) {
		requestForQuotedDisabled = false
	}

	let requestContainer
	if (isAnySelected) {
		requestContainer = (
			<div>
				<Input
					value={generalRequest}
					onChange={e => setGeneralRequest(e.target.value)}
					label="Details for the request (Optional)"
					placeholder="Enter here"
				/>
				<Space y={8} />
			</div>
		)
	} else {
		requestContainer = (
			<>
				<div className="full-flex-container">
					<Checkbox
						text="Government Insurance Co."
						value={wantsGovInsurer}
						onChange={() => setGovInsurer(prevState => !prevState)}
						textStyle={checkBoxTextStyle}
					/>
					<Space x={0.5} />
					<Input
						placeholder="Enter here"
						type="text"
						label="Reason for specific request (Optional)"
						value={govReason}
						onChange={e => setGovReason(e.target.value)}
						labelStyle={{ fontSize: '13.5px' }}
						style={{ width: '100%' }}
					/>
				</div>
				<Space y={1.5} />
				<div className="full-flex-container">
					<Checkbox
						text="Private Insurance Co."
						value={wantsPrivInsurer}
						onChange={() => setPrivInsurer(prevState => !prevState)}
						textStyle={checkBoxTextStyle}
					/>
					<Space x={0.5} />
					<Input
						placeholder="Enter here"
						type="text"
						label="Reason for specific request (Optional)"
						value={privReason}
						onChange={e => setPrivReason(e.target.value)}
						labelStyle={{ fontSize: '13.5px' }}
						style={{ width: '100%' }}
					/>
				</div>

				<Space y={1.5} />
				<div className="full-flex-container">
					<div>
						<Text>Add-on Required?</Text>
						<Space y={1} />
						<div className="d-flex align-items-center">
							<Text className="me-1" color={theme.colors.primary}>
								No
							</Text>
							<Switch
								value={wantsAddOn}
								onChange={() => setWantsAddOn(prevState => !prevState)}
								style={{ color: theme.colors.primary }}
							/>
							<Text className="ms-1" color={theme.colors.primary}>
								Yes
							</Text>
						</div>
					</div>
					<Input
						placeholder="Enter here"
						type="text"
						label={
							<div>
								Which add-on does the customer need <span style={compulsoryStyle}>*</span>
							</div>
						}
						disabled={!wantsAddOn}
						value={addOn}
						onChange={e => setAddOn(e.target.value)}
						labelStyle={{ fontSize: '13px' }}
						style={{ width: '100%' }}
					/>
				</div>
			</>
		)
	}

	const bodyEle = (
		<>
			<div className="full-flex-container">
				<Radio
					id="quote-any"
					text="Get me what you can"
					value={typeOfRequest === 'any'}
					onChange={() => setTypeOfRequest('any')}
					textStyle={{ fontSize: '14px' }}
					name="rfq"
					secondary
				/>
				<Radio
					id="quote-specific"
					text="Specific request from client"
					value={typeOfRequest === 'specific'}
					onChange={() => setTypeOfRequest('specific')}
					textStyle={{ fontSize: '14px' }}
					name="rfq"
					secondary
				/>
			</div>

			<Space y={2} />
			<SpecificRequestsContainer>{requestContainer}</SpecificRequestsContainer>

			<Space y={2} />
			<Button
				label="Request for quotes"
				disabled={requestForQuotedDisabled}
				onClick={requestForQuotes}
				style={{ width: '330px', height: '48px', margin: '0 auto' }}
			/>
		</>
	)

	return (
		<Modal show={true} handleClose={handleClose} title={titleEle} footer={<></>}>
			{bodyEle}
		</Modal>
	)
}

export default RequestForQuotationModal

const checkBoxTextStyle = {
	fontSize: '12.5px',
	letterSpacing: '0.03em',
	color: `${theme.colors.gray2}`,
	fontWeight: 700,
	maxWidth: '200px',
}
