export const findLocations = (RiskLocations, searchFn) => {
	let riskLocationIDS = []

	for (const location in RiskLocations) {
		let present = false
		let locationData = RiskLocations[location]
		if (locationData?.Type === 'PROPERTYBASEMENT') {
			// same info present for both property or basement
			present = searchFn(locationData?.property)
		} else {
			present = searchFn(locationData)
		}

		if (present) {
			riskLocationIDS.push(locationData?.ID)
		}
	}

	return riskLocationIDS
}

// check if Shop Risk exists
export const doesShopRiskExist = locationData => {
	if (locationData?.ShopRisk?.Goods?.length > 0) {
		return true
	}
}
// delete Shop Risk
export const deleteShopRisk = async (ID, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
		dispatch({
			type: 'SET_LOCATION_SHOP_RISK',
			data: {
				Goods: [],
				ExceedsLimit: '',
			},
		})
		dispatch({
			type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
		})

		resolve()
	})
}

// check if warranties exist
export const doesWarrantiesExist = locationData => {
	if (locationData?.HistoricInfo?.AddedWarranties?.Present !== '') {
		return true
	}
}
// delete Warranties
export const deleteWarranties = async (ID, prevData, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
		dispatch({
			type: 'SET_HISTORIC_INFO',
			data: {
				...prevData,
				AddedWarranties: {
					Present: '',
					Description: '',
				},
			},
		})
		dispatch({
			type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
		})

		resolve()
	})
}

// check if Building Height exists
export const doesBuildingHeightExist = locationData => {
	if (locationData?.BuildingHeight) {
		return true
	}
}
// delete Building Height
export const deleteBuildingHeight = async (ID, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
		dispatch({
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			propertyType: 'property',
			levelIndex: -1,
			data: {
				BuildingHeight: '',
			},
		})
		dispatch({
			type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
		})

		resolve()
	})
}

// check if Floors exists
export const doesFloorsExist = locationData => {
	if (locationData?.Floors) {
		return true
	}
}
// delete Floors
export const deleteFloors = async (ID, dispatch) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
		dispatch({
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			propertyType: 'property',
			levelIndex: -1,
			data: {
				Floors: '',
			},
		})
		dispatch({
			type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
		})

		resolve()
	})
}

// Plinth & Foundation
const checkForPlinthFoundation = (SumInsured = []) => {
	return SumInsured.find(item => item?.Name === 'PlinthFoundation') !== undefined
}
export const findPlinthFoundation = RiskLocations => {
	let riskLocationIDS = []
	// most complex - prop+basement both have levels more than one level returns true
	// riskLocationIDS = [{ ID: '', propertyType: '', levelIndex: '' }, { ID: '', propertyType: '', levelIndex: '' }]

	const obtainSumInsuredData = (locationData, propertyType, ID) => {
		let present = false

		if (locationData?.levels?.length > 0) {
			locationData?.levels.forEach((level, idx) => {
				present = checkForPlinthFoundation(level?.SumInsured)
				if (present) {
					const data = {
						ID: ID,
						propertyType: propertyType,
						levelIndex: idx,
					}
					riskLocationIDS.push(data)
				}
			})
		} else {
			present = checkForPlinthFoundation(locationData?.SumInsured)
			if (present) {
				const data = {
					ID: ID,
					propertyType: propertyType,
					levelIndex: -1,
				}
				riskLocationIDS.push(data)
			}
		}
	}

	for (const location in RiskLocations) {
		let locationData = RiskLocations[location]

		if (locationData?.Type === 'PROPERTYBASEMENT') {
			obtainSumInsuredData(locationData?.property, 'property', locationData?.ID)
			obtainSumInsuredData(locationData?.basement, 'basement', locationData?.ID)
		} else {
			obtainSumInsuredData(locationData, '', locationData?.ID)
		}
	}

	return riskLocationIDS
}

export const deletePlinthFoundation = async (
	ID,
	propertyType,
	levelIndex,
	SIwithoutPlinth,
	dispatch
) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: 'MOVE_TO_LOCATION',
			data: {
				locationId: ID,
			},
		})
		dispatch({
			type: 'UPDATE_RISK_LOCATION_DETAILS',
			propertyType: propertyType,
			levelIndex: levelIndex,
			data: {
				SumInsured: SIwithoutPlinth,
			},
		})
		dispatch({
			type: 'SAVE_LOCATION_CHANGES_AND_NAVIGATE',
		})

		resolve()
	})
}

// edge case utils
// shop risk
export const isShopRiskPresent = locationData => {
	let present = false
	if (locationData?.Type === 'PROPERTYBASEMENT') {
		// same info present for both property or basement
		present = doesShopRiskExist(locationData?.property)
	} else {
		present = doesShopRiskExist(locationData)
	}

	return present
}

// materials
export const materialsPresence = (dataObj, propertyType) => {
	const checkForMaterialsInput = data => {
		return data?.Type && data?.Description
	}

	if (dataObj?.Type === 'PROPERTYBASEMENT') {
		if (propertyType === 'property') {
			return (
				checkForMaterialsInput(dataObj?.Materials?.Roof) &&
				checkForMaterialsInput(dataObj?.Materials?.Walls) &&
				checkForMaterialsInput(dataObj?.Materials?.Floors)
			)
		} else {
			return (
				checkForMaterialsInput(dataObj?.Materials?.Ceiling) &&
				checkForMaterialsInput(dataObj?.Materials?.Walls) &&
				checkForMaterialsInput(dataObj?.Materials?.Floors)
			)
		}
	} else {
		if (dataObj?.Type === 'PROPERTY') {
			return (
				checkForMaterialsInput(dataObj?.Materials?.Roof) &&
				checkForMaterialsInput(dataObj?.Materials?.Walls) &&
				checkForMaterialsInput(dataObj?.Materials?.Floors)
			)
		} else if (dataObj?.Type === 'BASEMENT') {
			return (
				checkForMaterialsInput(dataObj?.Materials?.Ceiling) &&
				checkForMaterialsInput(dataObj?.Materials?.Walls) &&
				checkForMaterialsInput(dataObj?.Materials?.Floors)
			)
		}
	}
}

export const isMaterialsPresent = locationData => {
	let present = false
	if (locationData?.Type === 'PROPERTYBASEMENT') {
		present = materialsPresence(locationData?.property, 'property')
		if (present) {
			present = materialsPresence(locationData?.basement, 'basement')
		}
	} else {
		present = materialsPresence(locationData)
	}

	return present
}

// sum insured
const plinthFoundationPresence = locationData => {
	let present = false

	if (locationData?.levels?.length > 0) {
		locationData?.levels.forEach((level, idx) => {
			if (present === false) {
				present = checkForPlinthFoundation(level?.SumInsured)
			}
		})
	} else {
		present = checkForPlinthFoundation(locationData?.SumInsured)
	}

	return present
}

export const isSumInsuredPresent = locationData => {
	let present = false
	if (locationData?.Type === 'PROPERTYBASEMENT') {
		present = plinthFoundationPresence(locationData?.property)
		if (present === false) {
			present = plinthFoundationPresence(locationData?.basement)
		}
	} else {
		present = plinthFoundationPresence(locationData)
	}

	return present
}
