import { useState, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'

import { Checkbox, Input, Text, theme, MainContainer } from 'verak-ui'
import { Context } from '../../../context/Provider'
import { defaultSecurityOptions, prefillTheftRisk } from './Shared'
import { useFetch } from '../../../api/apihook'

const TitleText = styled(Text)`
	font-weight: 700;
	font-size: 24px;
	color: ${theme.colors.gray7};
`

export default function TheftRiskBasement() {
	const fetch = useFetch()
	const location = useLocation()

	const returnPath = location?.state?.returnPath || ''

	const cachedData = fetch?.cachedData?.SubCategories?.required

	const fetchSubCategories = async () => {
		if (!cachedData) {
			await fetch.getSubCategoryByRiskType()
		}
	}

	useEffect(() => {
		if (!cachedData) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	const [state, dispatch] = useContext(Context)
	const prefillData = prefillTheftRisk(state.location || {})

	const [SecurityArrangements, setSecurityArrangements] = useState(
		prefillData.SecurityArrangements?.filter(el => defaultSecurityOptions.includes(el)) || []
	)
	const [otherDisabled, setOtherDisabled] = useState(!Boolean(prefillData.otherOptionData))

	const [otherOption, setOtherOption] = useState(prefillData.otherOptionData || '')

	const history = useHistory()

	const isDwellingsCategory = OccupancyItem => {
		const DWELLINGS_CATEGORY = 'Dwellings, Office, Shop or Hotel'

		if (!!cachedData) {
			const isDwellings = cachedData?.find(item => item.Description === OccupancyItem.SubCategory)

			return isDwellings?.Type === DWELLINGS_CATEGORY
		}
		return false
	}

	const checkForPrimarySecondaryOcc = data => {
		let secondaryOccupancyCheck = false,
			primaryOccupancyCheck = false
		if (data?.levels) {
			secondaryOccupancyCheck = data?.levels?.some(el =>
				el?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			)
			primaryOccupancyCheck = data?.levels?.some(el =>
				isDwellingsCategory(el?.PrimaryOccupancy || {})
			)
		} else {
			secondaryOccupancyCheck = data?.SecondaryOccupancy?.some(item => isDwellingsCategory(item))
			primaryOccupancyCheck = isDwellingsCategory(data?.PrimaryOccupancy || {})
		}
		return secondaryOccupancyCheck || primaryOccupancyCheck
	}

	const checkDwellings = () => {
		const { Type, property, basement } = state?.location || {}
		const isPropertyBasement = Type === 'PROPERTYBASEMENT'

		let isDwellings = false

		if (isPropertyBasement) {
			isDwellings = checkForPrimarySecondaryOcc(property)
			isDwellings = isDwellings || checkForPrimarySecondaryOcc(basement)
		} else {
			isDwellings = checkForPrimarySecondaryOcc(state?.location)
		}
		return isDwellings
	}

	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else if (state.quote?.PolicyName === 'SFSP') {
			if (checkDwellings()) {
				history.replace('/property-risk/shop-risk')
			} else {
				history.replace('/property-risk/location-details')
			}
		} else {
			history.replace('/property-risk/location-details')
		}
	}

	const proceed = async () => {
		let values = [...SecurityArrangements]

		if (!otherDisabled && otherOption) {
			values = [...SecurityArrangements, otherOption]
		}

		await new Promise(resolve => {
			dispatch({
				type: 'SET_LOCATION_THEFT_RISK',
				data: {
					SecurityArrangements: values,
				},
			})

			dispatch({
				type: 'SAVE_LOCATION_CHANGES',
			})
			resolve()
		})

		await fetch.saveQuote()

		if (returnPath) {
			history.push(returnPath)
		} else {
			history.push(`/property-risk/materials`)
		}
	}

	const handleChange = (key, state) => {
		let arrCopy = [...SecurityArrangements]

		if (state && !arrCopy.includes(key) && key !== 'Other') {
			arrCopy = [...arrCopy, key]
		} else {
			if (arrCopy.includes(key)) {
				arrCopy = [...arrCopy.filter(el => el !== key)]
			}
		}
		setSecurityArrangements([...arrCopy])
	}

	const isChecked = value => SecurityArrangements.includes(value)
	const proceedDisabled = !otherDisabled && !otherOption

	return (
		<Container>
			<MainContainer
				header={
					<>
						Theft risk assessment <span className="ms-2">[OPTIONAL]</span>
					</>
				}
				rightLabel="Save & Continue"
				rightOnClick={proceed}
				leftOnClick={goBack}
				rightDisabled={proceedDisabled}
			>
				<>
					<TitleText className="mb-2">What security arrangements exist on the premises?</TitleText>
					<Text className="mb-3" color={theme.colors.gray3} type={'body1'}>
						Select all that apply
					</Text>
					<Row className="mb-4">
						<Col className="d-flex">
							<Checkbox
								value={isChecked('Anti-theft locks')}
								onChange={state => handleChange('Anti-theft locks', state)}
								text="Locks"
								icon="antiTheftLock"
							/>
						</Col>
						<Col className="d-flex">
							<Checkbox
								value={isChecked('CCTV')}
								onChange={state => handleChange('CCTV', state)}
								text="CCTV"
								icon="cctv"
							/>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col className="d-flex">
							<Checkbox
								value={isChecked('24 hour security')}
								onChange={state => handleChange('24 hour security', state)}
								text="24 hour security"
								icon="hrSecurity"
							/>
						</Col>
						<Col className="d-flex">
							<Checkbox
								value={isChecked('Alarm system')}
								onChange={state => handleChange('Alarm system', state)}
								text="Alarm system"
								icon="alarm"
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col className="d-flex">
							<Checkbox
								value={isChecked('Night watchman')}
								onChange={state => handleChange('Night watchman', state)}
								text="Night watchman"
								icon="watchman"
							/>
						</Col>
						<Col className="d-flex flex-column">
							<div className="d-flex">
								<Checkbox
									value={!otherDisabled}
									onChange={state => setOtherDisabled(!state)}
									text="Other (Please specify)"
									icon="otherSecurity"
								/>
							</div>
						</Col>
					</Row>
					<Row className="mb-5">
						<Col></Col>
						<Col>
							<Input
								disabled={otherDisabled}
								value={otherOption}
								onChange={e => setOtherOption(e.target.value)}
								placeholder="Enter here"
							/>
						</Col>
					</Row>
				</>
			</MainContainer>
		</Container>
	)
}
