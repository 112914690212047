import { Button, Text, theme, Divider } from 'verak-ui'

const SpecifyMoreDetails = ({
	title,
	renderDetailItems,
	addNewDetailItem,
	addItemButtonLabel = 'Add another property',
	divider = true,
}) => {
	const renderDivider = divider ? <Divider top={0.5} bottom={1.5} /> : null
	return (
		<>
			{renderDivider}
			<Text type="body1" fontWeight={500}>
				{title}
			</Text>
			{renderDetailItems}
			<Button
				className="mt-3"
				bgColor={theme.colors.white}
				icon="add"
				iconStyles={{
					marginRight: '0.5rem',
				}}
				label={addItemButtonLabel}
				onClick={addNewDetailItem}
				style={{
					padding: '0 0.5rem',
				}}
				textColor={theme.colors.primary}
			/>
		</>
	)
}

const RenderRemoveButton = ({ onClick, renderWhen }) => {
	return renderWhen ? (
		<Button
			bgColor={theme.colors.white}
			label=""
			icon="remove"
			onClick={onClick}
			style={{
				height: 'auto',
				margin: '0',
				padding: '0',
			}}
			textColor={theme.colors.primary}
		/>
	) : null
}

export { SpecifyMoreDetails, RenderRemoveButton }
