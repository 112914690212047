import { useHistory, useLocation } from 'react-router'
import PurchaseSuccessful from './PurchaseSuccessful'
import PolicyConfirmationPending from './PolicyConfirmationPending'
import PurchaseUnsuccessful from './PurchaseUnsuccessful'

const RFQPurchase = ({}) => {
	const { state } = useLocation()
	let toRender
	switch (state.rfqItem.Status) {
		case 'OPS:CLOSURE:PENDING':
		case 'OPS:POLICY:PENDING':
			toRender = <PurchaseSuccessful />
			break
		case 'AGENT:SETTLEMENT:FAILED':
			toRender = <PurchaseUnsuccessful />
			break
		case 'OPS:SETTLEMENT:PENDING':
			toRender = <PolicyConfirmationPending />
			break
		default:
	}
	return toRender
}
export default RFQPurchase
