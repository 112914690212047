import {
	HorizontalDivider,
	InfoBox,
	InfoText,
	VerticalDivider,
} from '../../components/contentUtils'

import CashCovered from '../../../../../assets/training/CashCovered.svg'
import CostToReplace from '../../../../../assets/training/CostToReplace.svg'
import PersonalBelongings from '../../../../../assets/training/PersonalBelongings.svg'
import LossOfCompany from '../../../../../assets/training/LossOfCompany.svg'

export default function CheatSheet_Benefits() {
	return (
		<div className="d-flex flex-column p-3">
			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={CashCovered}>
						<InfoText fontWeight="700">Cash upto ₹50,000 is covered</InfoText>
					</InfoBox>
					<ul>
						<li>Built in extra benefits ekdum Free hai</li>
						<li>
							Benefit is <b>ONLY for Fire & Allied Perils and NOT Burglary & Theft</b>
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={CostToReplace}>
						<InfoText fontWeight="700">Cost to replace documents upto ₹50,000</InfoText>
					</InfoBox>
					<InfoText className="my-1">Example</InfoText>
					<ol>
						<li>Company formation docs</li>
						<li>Business plans</li>
						<li>Accounting books (khata)</li>
					</ol>
				</div>
			</div>
			<HorizontalDivider />

			<div className="row w-100 d-flex justify-content-between">
				<div className="col d-flex flex-column">
					<InfoBox icon={PersonalBelongings}>
						<InfoText fontWeight="700">
							Personal belongings of employees, owners & visitors
						</InfoText>
					</InfoBox>
					<ul>
						<li>Covered upto ₹15,000 per person</li>
						<li>
							Max Coverage <br /> 20 x ₹15,000 = ₹3 Lacs
						</li>
					</ul>
				</div>

				<VerticalDivider />

				<div className="col d-flex flex-column">
					<InfoBox icon={LossOfCompany}>
						<InfoText fontWeight="700">Loss of company data & programs upto ₹5 Lakhs</InfoText>
					</InfoBox>
					<ol>
						<li>Example: Godown which has on premise supply chain software</li>
						<li>
							If due to fire / flood etc - the IT system fails and company data is lost - upto ₹5
							Lakhs can be paid.
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}
