import { Text, Icon, Modal, theme, MainContainer } from 'verak-ui'
import { Row, Col, Card, Container } from 'react-bootstrap'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { StockLocationsIs0 } from '../StockDisclosureChoice/Modals'

const PolicytTypeCard = styled(Card)`
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px #02475e40;
	cursor: pointer;
`
const VerticalDivider = styled.div`
	width: 1px;
	background: ${theme.colors.gray6};
	padding: 0;
`

const LocationInput = styled.div`
	height: 100px;
	width: 140px;
	border-radius: 6px;
	border: 1px solid ${theme.colors.primary};
	font-size: 60px;
	text-align: center;
`

const RiskLocation = () => {
	let history = useHistory()
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	const [locationCount, setCount] = useState(state.quote?.NumRiskLoc || 0)
	const [modalState, setModalState] = useState(false)

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_RISK_LOCATION_COUNT',
				data: {
					NumRiskLoc: locationCount,
				},
			})
			resolve()
		})

		await fetch.trackRfqEvent("Entered 'No. of Risk Locations'")

		if (state?.quote?.PolicyKind === 'BUILDING_ONLY') {
			setModalState(true) // trigger no stock warning
		} else {
			history.push(`/stock-disclosure-choice/stock-location`)
		}
	}

	const back = () => {
		history.goBack()
	}

	const incrementCount = () => {
		setCount(e => e + 1)
	}
	const decrementCount = () => {
		if (locationCount > 0) {
			setCount(e => e - 1)
		}
	}
	return (
		<Container>
			<MainContainer
				header="Let’s enter details of each risk location"
				rightLabel="Save & Continue"
				rightOnClick={proceed}
				rightDisabled={locationCount <= 0}
				leftOnClick={back}
			>
				<Row>
					<Col className="mb-3">
						<Icon name="locationPoint" />
					</Col>
				</Row>
				<Row>
					<Col className="mb-3">
						<Text type={'primary'}>How many risk locations does the client need insured?</Text>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex align-items-center mb-5">
						<Icon
							name="minus"
							onClick={decrementCount}
							disabled={true}
							style={{ cursor: 'pointer' }}
							color={locationCount == 0 ? theme.colors.gray3 : null}
						/>
						<LocationInput className="ms-3 me-3">{locationCount}</LocationInput>
						<Icon name="add" size={'60px'} onClick={incrementCount} style={{ cursor: 'pointer' }} />
					</Col>
				</Row>
				<Modal
					show={modalState}
					handleClose={() => setModalState(false)}
					closeButton={true}
					title={
						<Text type="primary" color={theme.colors.red}>
							Notice
						</Text>
					}
					footer={<></>}
					style={{ textAlign: 'center' }}
				>
					<StockLocationsIs0 />
				</Modal>
			</MainContainer>
		</Container>
	)
}

export default RiskLocation
