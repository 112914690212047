import { theme, Text, Button, Space } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'
import TimelineView from '../../../../components/Timeline'
import { useHistory, useLocation } from 'react-router'

import TimelineWarningIcon from '../../../../assets/icons/timelineWarningIcon.svg'
import { useState } from 'react'
import { useContextApi } from '../../../../context/Provider'
import { useFetch } from '../../../../api/apihook'

export default function ClientVerificationPending() {
	const fetch = useFetch()
	const [state, dispatch] = useContextApi()
	const [reminderSent, setReminderSent] = useState(false)

	const history = useHistory()
	const location = useLocation()

	const navText = `Overview, {UID} - {Client Name} - {Policy Type}`

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			{navText}
		</Text>
	)

	const sendReminder = () => {
		// API call to send a reminder
		setReminderSent(true)
	}

	const handleCheckRfqDetails = async () => {
		const { uid } = location?.state || {}
		if (uid) {
			let quote = await fetch.getQuote(uid)

			const toMerge = {
				...quote,
				quoteId: uid,
			}

			const data = fetch.mergeRiskLocations(toMerge)

			await new Promise((resolve, reject) => {
				dispatch({
					type: 'RESUME_QUOTE',
					data: data,
				})
				resolve()
			})
		} else {
			await new Promise((resolve, reject) => {
				dispatch({ type: 'LOAD_TEST_STATE' })
				resolve()
			})
		}

		// the sum insured breakdown should take the user to this route after the updates are done
		// instead of continuing the normal flow
		const flowCompletionRoutes = {
			finalRoute: '/sum-insured-breakdown',
			redirectRoute: '/quotes-in-progress',
		}
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_FLOW_COMPLETION_ROUTES',
				data: { flowCompletionRoutes },
			})
			resolve()
		})

		// set update flow as false initially
		await new Promise(resolve => {
			dispatch({
				type: 'SET_EDIT_RFQ',
				data: { editRfqMode: false },
			})
			resolve()
		})

		// navigate to the summary
		history.push('/summary')

		// history.push('/rfq-status/client-verification-pending/final-review')
	}

	let reminderBtn = (
		<Button
			secondary
			style={{ height: '56px' }}
			label="Remind customer again"
			icon="bellIconSm"
			iconAlign="left"
			onClick={sendReminder}
		/>
	)

	if (reminderSent) {
		const buttonLabel = (
			<div className="d-flex flex-column align-items-start">
				Reminder sent
				<p style={{ color: theme.colors.gray4, fontSize: '9px', margin: '0' }}>
					Send again in 24 hrs
				</p>
			</div>
		)
		reminderBtn = (
			<Button
				bgColor="#F6FFED"
				textColor={theme.colors.green4}
				style={{ height: '56px', border: `1px solid ${theme.colors.green4}` }}
				label={buttonLabel}
				icon="tickCircle"
				iconColor={theme.colors.green4}
				iconAlign="left"
				iconStyles={{ marginRight: '0.5rem' }}
			/>
		)
	}

	const timelineItems = [
		{
			icon: (
				<img
					style={{ marginLeft: '-3px', marginTop: '-2px' }}
					src={TimelineWarningIcon}
					alt="warning"
				/>
			),
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex flex-column">
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Client verification
						</Text>
						<div className="d-flex mt-2">
							<Text className="mr-2" color={theme.colors.primary} fontSize="12px" fontWeight="400">
								Sent: 14 July 2021, 5:11pm
							</Text>
							<Text className="mx-1" color="#C4C4C4">
								|
							</Text>
							<Text
								className="mx-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Awaiting update
							</Text>
						</div>
					</div>
					<div className="d-flex">
						{reminderBtn}
						<Space x={1} />
						<Button
							primary
							style={{ height: '56px' }}
							label="See RFQ details"
							onClick={handleCheckRfqDetails}
							icon="rightArrow"
							iconAlign="right"
						/>
					</div>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						RFQ submission
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Awaiting RFQ completion
						</Text>
					</div>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
							Quotes generation
						</Text>
						<div className="d-flex mt-2">
							<Text
								className="mr-2"
								color={theme.colors.secondary}
								fontSize="12px"
								fontWeight="400"
							>
								Awaiting update
							</Text>
						</div>
					</div>

					<Button
						style={{ height: '56px' }}
						label="See quotes"
						icon="rightArrow"
						iconAlign="right"
					/>
				</div>
			),
		},
		{
			disabled: true,
			content: (
				<div className="d-flex flex-column">
					<Text color={theme.colors.primary} fontSize="18px" fontWeight="700">
						Purchase
					</Text>
					<div className="d-flex mt-2">
						<Text className="mr-2" color={theme.colors.secondary} fontSize="12px" fontWeight="400">
							Pending
						</Text>
					</div>
				</div>
			),
		},
	]

	return (
		<PageContentAndNavbarWrapper
			className="d-flex flex-column align-items-center"
			// style={{ height: '100%' }}
		>
			<SecondaryNavbar
				headerBg={theme.colors.secondary2}
				navbarNumber={1}
				leftContent={navContent}
				rightContent=""
			/>

			<div
				style={{
					borderRadius: '8px',
					width: 'min(1200px, 100%)',
				}}
				className="container h-100 p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.primary}>
					Awaiting Client verification
				</Text>

				<TimelineView className="mt-3" timelineItems={timelineItems} />
			</div>
		</PageContentAndNavbarWrapper>
	)
}
