import { theme, Text, Accordion, Button, Checkbox, Icon } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import { Context } from '../../context/Provider'
import { isFireAllRiskPolicy } from '../../utilities/isFireAllRiskPolicy'
import { useFetch } from '../../api/apihook'
import { ComponentContext } from '../../context/ComponentProvider'

import cisImg from '../../assets/icons/cis.svg'
import citImg from '../../assets/icons/cit.svg'
import cicImg from '../../assets/icons/cic.svg'

export default function SelectPolicyType() {
	const prefillSelectedItems = () => {
		if (state?.quote?.PolicyAddons !== undefined) {
			// in the future this would be made dynamic to pick add-ons from other
			// standalone policies --> mapping to the "Tag" would be required (for fire consider "FIRE" and "FAR")
			if (Object.keys(state?.quote?.PolicyAddons)?.length > 0) {
				return { 'Fire and Allied Perils': state?.quote?.PolicyAddons }
			} else {
				return { 'Fire and Allied Perils': {} }
			}
		} else {
			return {}
		}
	}

	const [state, dispatch] = useContext(Context)
	const { setRenderSidePanel } = useContext(ComponentContext)

	const [selectedItems, setSelectedItems] = useState(prefillSelectedItems)
	let fetch = useFetch()

	const history = useHistory()
	const location = useLocation()
	const nextScreenPath = location?.state?.nextScreenPath // got from the add on CTA on the left

	const { state: locationState } = useLocation()

	useEffect(() => {
		setRenderSidePanel(true)
	}, [])

	const navigateToNextPage = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_ADD_ONS',
				data: {
					// in the future this would be made dynamic to pick add-ons from other
					// standalone policies --> mapping to the "Tag" would be required (for fire consider "FIRE" and "FAR")
					PolicyAddons: selectedItems['Fire and Allied Perils'],
				},
			})
			resolve()
		})

		// sends a new policyTag only if it's an 'update'
		if (state?.app?.clientCreated) {
			await fetch.saveQuote()
		}

		if (nextScreenPath) {
			history.push(nextScreenPath)
		} else {
			history.push('/new-quote/select-sum-insured', locationState)
		}
	}

	// main object structure:
	// data: {
	// 	'Fire and Allied Perils': {
	// 		'Burglary': {},
	// 		'Plate Glass': {},
	// 	},
	// 	'Marine': {
	// 		'ABC': {},
	// 	}
	// }

	// Imp**: the assumption for add-ons is that it will always have a standalone (eg: fire) policy attached to it

	const handleChange = (value, isChecked, policyType, isStandAlonePolicy = false) => {
		let objCopy = { ...selectedItems }

		if (isChecked) {
			if (isStandAlonePolicy) {
				// something like 'Fire & Allied Perils' or 'Marine' or 'Workman's Compensation'
				objCopy[value] = {}
			} else {
				objCopy[policyType][value] = { Present: true }
			}
		} else {
			// deletion
			if (isStandAlonePolicy) {
				delete objCopy[value]
			} else {
				delete objCopy[policyType][value]
			}
		}

		setSelectedItems(objCopy)
	}

	const isChecked = (value, policyType, isStandAlonePolicy) => {
		if (isStandAlonePolicy) {
			return selectedItems[value] !== undefined
		} else {
			// add-ons
			// 2 levels of 'if' statements are necessary cause otherwise it throws an undefined error
			if (selectedItems[policyType] !== undefined) {
				if (selectedItems[policyType][value] !== undefined) {
					return true
				}
			}
			return false
		}
	}

	const proceedEnabled = Object.keys(selectedItems)?.length > 0

	// checks if the standalone policy is present - used for disabling of the add ons
	const isStandAlonePolicyAbsent = value => selectedItems[value] === undefined

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Policy type
		</Text>
	)

	const fireAndAlliedPerilsAccordionList = [
		{
			id: 'fnap-item-1',
			header: 'Fire add-on policies',
			content: (
				<>
					<div className="d-flex">
						<PolicyItem
							value={isChecked('BURGLARY_THEFT', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('BURGLARY_THEFT', state, 'Fire and Allied Perils')
							}
							policyType="online"
							icon="burglaryAndTheft"
							label="Burglary & Theft"
							disabled={isStandAlonePolicyAbsent('Fire and Allied Perils')}
						/>

						<PolicyItem
							value={isChecked('CIC', 'Fire and Allied Perils')}
							onChangeFunc={state => handleChange('CIC', state, 'Fire and Allied Perils')}
							policyType="online"
							img={cicImg}
							label="Cash In Counter"
							disabled={isStandAlonePolicyAbsent('Fire and Allied Perils')}
						/>
					</div>

					<div className="d-flex mt-4">
						<PolicyItem
							value={isChecked('CIS', 'Fire and Allied Perils')}
							onChangeFunc={state => handleChange('CIS', state, 'Fire and Allied Perils')}
							policyType="online"
							img={cisImg}
							label="Cash In Safe"
							disabled={isStandAlonePolicyAbsent('Fire and Allied Perils')}
						/>

						<PolicyItem
							value={isChecked('CIT', 'Fire and Allied Perils')}
							onChangeFunc={state => handleChange('CIT', state, 'Fire and Allied Perils')}
							policyType="online"
							img={citImg}
							label="Cash In Transit"
							disabled={isStandAlonePolicyAbsent('Fire and Allied Perils')}
						/>
					</div>

					<div className="d-flex mt-4">
						<PolicyItem
							value={isChecked('Neon Glass / Sign Board', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Neon Glass / Sign Board', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="neonGlass"
							label="Neon Glass / Sign Board"
							disabled
						/>

						<PolicyItem
							value={isChecked('Machinery Breakdown & Damage', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Machinery Breakdown & Damage', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="machineryBreakdown"
							label="Machinery Breakdown & Damage"
							disabled
						/>
					</div>

					<div className="d-flex mt-4">
						<PolicyItem
							value={isChecked('Fire Loss Of Profit', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Fire Loss Of Profit', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="fireLossOfProfit"
							label="Fire Loss Of Profit"
							disabled
						/>

						<PolicyItem
							value={isChecked('Machinery Loss Of Profit', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Machinery Loss Of Profit', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="machineryLossOfProfit"
							label="Machinery Loss Of Profit"
							disabled
						/>
					</div>

					<div className="d-flex mt-4">
						<PolicyItem
							value={isChecked('Electronic Equipment Insurance', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Electronic Equipment Insurance', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="electronicEquipmentInsurance"
							label="Electronic Equipment Insurance"
							disabled
						/>

						<PolicyItem
							value={isChecked('Fidelity and Larceny', 'Fire and Allied Perils')}
							onChangeFunc={state =>
								handleChange('Fidelity and Larceny', state, 'Fire and Allied Perils')
							}
							policyType="offline"
							icon="fidelityAndLarceny"
							label="Fidelity and Larceny"
							disabled
						/>
					</div>

					<div className="d-flex mt-4">
						<PolicyItem
							value={isChecked('Plate Glass', 'Fire and Allied Perils')}
							onChangeFunc={state => handleChange('Plate Glass', state, 'Fire and Allied Perils')}
							policyType="offline"
							icon="plateGlass"
							label="Plate Glass"
							disabled
						/>
					</div>
				</>
			),
		},
	]

	const FireAndAlliedPerils_Content = (
		<>
			<PolicyItem
				value={isChecked('Fire and Allied Perils', '', true)}
				onChangeFunc={state => handleChange('Fire and Allied Perils', state, '', true)}
				policyType="online"
				icon="fireAndAlliedPerils"
				label="Fire and Allied Perils"
			/>

			<Accordion
				headerStyle={{
					fontSize: '14px',
					color: theme.colors.secondary,
				}}
				defaultActiveKey="fnap-item-1"
				variant="transparent"
				accordionList={fireAndAlliedPerilsAccordionList}
			/>
		</>
	)

	const mainAccordionList = [
		{
			id: 'main-item-1',
			header: 'FIRE AND ALLIED PERILS',
			content: FireAndAlliedPerils_Content,
		},
	]

	return (
		<PageContentAndNavbarWrapper>
			<SecondaryNavbar headerBg="white" navbarNumber={1} leftContent={navContent} rightContent="" />

			<div className="container p-3 mb-5">
				<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3} className="px-2">
					Please select all that apply
				</Text>

				<div className="px-2">
					<Accordion
						headerStyle={{
							color: theme.colors.primary,
							opacity: '0.4',
							fontSize: '12px',
						}}
						defaultActiveKey="main-item-1"
						variant="transparent"
						accordionList={mainAccordionList}
					/>
				</div>
			</div>

			<div
				className="d-flex justify-content-end align-items-center"
				style={{ background: 'white', padding: '12px 24px', width: '100%' }}
			>
				<Button
					disabled={!proceedEnabled}
					label="Save and Continue"
					icon="rightArrow"
					onClick={navigateToNextPage}
					iconAlign="right"
				/>
			</div>
		</PageContentAndNavbarWrapper>
	)
}

const PolicyItem = ({
	label = '',
	icon = '',
	img,
	policyType = 'offline',
	value,
	onChangeFunc,
	disabled,
}) => {
	let policyTypeText = policyType === 'offline' ? 'Offline' : 'Online & seamless process >>>'
	let subTextColor = policyType === 'offline' ? theme.colors.gray4 : theme.colors.secondary

	return (
		<PolicyItemWrapper
			disabled={disabled}
			className="d-flex justify-content-between align-items-center px-3"
		>
			<Checkbox
				disabled={disabled}
				value={disabled ? false : value}
				onChange={() => onChangeFunc(!value)}
			/>
			<div
				className="d-flex align-items-center flex-fill py-3"
				onClick={() => onChangeFunc(!value)}
			>
				<div className="d-flex flex-fill">
					{img ? (
						<img src={img} alt={img} style={{ width: '24px', height: '24px' }} />
					) : (
						<Icon name={icon} />
					)}
					<Text
						style={{ marginLeft: '10px' }}
						color={theme.colors.primary}
						fontSize="18px"
						fontWeight="700"
					>
						{label}
					</Text>
				</div>
				<Text
					style={{ userSelect: 'none' }}
					fontSize="10px"
					className="font-italic"
					fontWeight="700"
					color={subTextColor}
				>
					{policyTypeText}
				</Text>
			</div>
		</PolicyItemWrapper>
	)
}

const PolicyItemWrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	width: min(510px, 95%);
	cursor: pointer;
	margin-right: 2rem;
	opacity: ${({ disabled }) => (disabled ? '.5' : '1')};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
