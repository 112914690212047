import { useHistory, useLocation } from 'react-router'
import { useContext, useState } from 'react'
import { Text, theme, MainContainer, Alert, Space } from 'verak-ui'
import AnotherInsuranceCompany from './components/AnotherInsuranceCompany'
import CoverDenialHistory from './components/CoverDenialhistory'
import ClaimsHistory from './components/ClaimsHistory'
import Warranties from './components/Warranties'
import { Context } from '../../../context/Provider'
import { useFetch } from '../../../api/apihook'
import { getCorrectDateFormat } from '../../../utilities/getCorrectDateFormat'

const getPropertyType = type => {
	switch (type) {
		case 'BASEMENT':
			return 'Basement'
		case 'PROPERTY':
			return 'Building'
		case 'PROPERTYBASEMENT':
			return 'Building & Basement'
		default:
			return ''
	}
}

function prefillHistoricInfo(state) {
	const { HistoricInfo, Type, property, basement } = state?.location || {}
	if (Type === 'PROPERTYBASEMENT') {
		const historicInfoData = property?.HistoricInfo || basement?.HistoricInfo || {}
		return historicInfoData
	}
	return HistoricInfo
}

export default function HistoricInfo() {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)

	const HistoricInfo = prefillHistoricInfo(state)

	const propertyType = getPropertyType(state?.location?.Type)
	const isSFSP = state.quote?.PolicyName === 'SFSP'

	const history = useHistory()
	const historyLoc = useLocation()
	const { edit, returnPath } = historyLoc?.state || {}

	// another insurer
	// NA - if another insurer is not selected
	const [InsuredElsewhere, setInsuredElsewhere] = useState(HistoricInfo?.InsuredElsewhere ?? 'NA')
	const [otherInsurerData, setOtherInsurerData] = useState({
		OtherInsurer: HistoricInfo?.OtherInsurer || '',
		StartDate: HistoricInfo?.StartDate || new Date(),
	})
	// cover denial
	const [CoverDenied, setCoverDenied] = useState(HistoricInfo?.CoverDenied ?? 'NA')
	// eslint-disable-next-line no-unused-vars
	const [declinedBy, setDeclinedBy] = useState({
		DenialInsurer: HistoricInfo?.DenialInsurer || '',
		DenialReason: HistoricInfo?.DenialReason || '',
	})
	// claims history
	const [PastClaims, setPastClaims] = useState(() => {
		let hasPastClaims = HistoricInfo?.PastClaims ?? 'NA'
		if (hasPastClaims !== 'NA') {
			hasPastClaims = HistoricInfo?.Claims?.length ? 'YES' : 'NO'
		}
		return hasPastClaims
	}) // VALUES - YES, NO, NA

	const [HistoricInfoData, setHistoricInfoData] = useState(() => {
		const claimsData = HistoricInfo?.Claims || ''
		if (HistoricInfo?.PastClaims !== 'NA' && claimsData && claimsData.length >= 0) {
			// YES OR NO
			let updatedClaimsList = []
			claimsData?.forEach((claim, key) => {
				updatedClaimsList.push({
					claimId: `Claim #${key + 1}`,
					...claim,
				})
			})
			return updatedClaimsList
		}
		return []
	})
	// policy pdf
	const [policyPDF, setPolicyPDF] = useState(() => {
		return HistoricInfo?.PolicyPDF || { meta: '', url: '' }
	})

	// warranties
	const [hasWarranties, setHasWarranties] = useState(HistoricInfo?.AddedWarranties?.Present ?? 'NA')
	const [warrantiesDesc, setWarrantiesDesc] = useState(
		HistoricInfo?.AddedWarranties?.Description || ''
	)
	const handleWarrantiesPresence = value => {
		if (value === false) {
			// handles case of removing warranties if sales exec shifts to No from Yes
			setWarrantiesDesc('')
		}
		setHasWarranties(value)
	}
	const handleWarrantiesDesc = value => {
		setWarrantiesDesc(value)
	}

	const computeProceedDisabled = () => {
		let disableForCoverDenial = false // not disabled
		let disableForAnotherInsurer = false // not disabled
		let disableForWarranties = false
		let disableForClaimsHistory = false

		if (HistoricInfoData === 'NA') {
			disableForClaimsHistory = false
		} else {
			disableForClaimsHistory =
				HistoricInfoData?.length > 0 &&
				HistoricInfoData?.some((historicItem, key) => {
					const { Damaged, Month, Year, Status, Reasons, Amount } = historicItem

					const isValidAmount = Amount || Amount === 0
					const areValidReasons = Reasons?.length && Reasons?.every(el => el)

					if (!(isValidAmount && Status && areValidReasons && Damaged && Month && Year)) {
						return true
					}
					return false
				})
		}

		if (CoverDenied === true) {
			if (!declinedBy?.DenialInsurer || !declinedBy?.DenialReason) {
				disableForCoverDenial = true // disable
			}
		}

		if (InsuredElsewhere === true) {
			if (!otherInsurerData?.OtherInsurer || !otherInsurerData?.StartDate) {
				disableForAnotherInsurer = true // disable
			}
		}

		if (hasWarranties === true && warrantiesDesc === '') {
			disableForWarranties = true // disable
		}

		return (
			disableForClaimsHistory ||
			disableForCoverDenial ||
			disableForAnotherInsurer ||
			disableForWarranties
		)
	}

	const proceedDisabled = computeProceedDisabled()

	const proceed = async () => {
		const data = {
			InsuredElsewhere,
			OtherInsurer:
				!!InsuredElsewhere && InsuredElsewhere !== 'NA' ? otherInsurerData.OtherInsurer : '', // dispatch otherinsurer only when InsuredElsewhere is true
			StartDate:
				!!InsuredElsewhere && InsuredElsewhere !== 'NA'
					? getCorrectDateFormat(otherInsurerData.StartDate)
					: '', // same as above ^
			CoverDenied,
			DenialInsurer: declinedBy.DenialInsurer,
			DenialReason: declinedBy.DenialReason,
			Claims: HistoricInfoData,
			PastClaims: PastClaims,
			PolicyPDF: policyPDF,
			AddedWarranties: {
				Present: hasWarranties,
				Description: warrantiesDesc,
			},
		}
		await new Promise(resolve => {
			dispatch({ type: 'SET_HISTORIC_INFO', data })
			resolve()
		})
		await new Promise(resolve => {
			dispatch({ type: 'SAVE_LOCATION_CHANGES' })
			resolve()
		})
		await fetch.saveQuote()

		// let path = '/property-risk/goods-in-trust'
		let path = '/property-risk/sum-insured'
		if (edit && returnPath) {
			path = returnPath
		} else {
			// check if gooddata present, if yes then navigate to good in trust data

			const isPropertyBasement = state.location.Type === 'PROPERTYBASEMENT'

			if (!isPropertyBasement) {
				// check if levels

				if (state.location.levels) {
					// check if any level contains goods in trust
					const isPresent = state.location.levels.some(item => {
						return item?.GoodsInTrust?.Present
					})
					if (isPresent) {
						// path = '/property-risk/goods-in-trust-data'
						path = '/property-risk/sum-insured'
					}
				} else if (state.location?.GoodsInTrust?.Present) {
					// path = '/property-risk/goods-in-trust-data'
					path = '/property-risk/sum-insured'
				}
			} else {
				const propertyItem = state.location.property
				const basementItem = state.location.basement
				// check in property
				if (propertyItem) {
					if (propertyItem.levels) {
						const isPresent = propertyItem.levels.some(item => {
							return item?.GoodsInTrust?.Present
						})
						if (isPresent) {
							// path = '/property-risk/goods-in-trust-data'
							path = '/property-risk/sum-insured'
						}
					} else if (propertyItem?.GoodsInTrust?.Present) {
						// path = '/property-risk/goods-in-trust-data'
						path = '/property-risk/sum-insured'
					}
				}

				if (basementItem) {
					if (basementItem.levels) {
						const isPresent = basementItem.levels.some(item => {
							return item?.GoodsInTrust?.Present
						})
						if (isPresent) {
							// path = '/property-risk/goods-in-trust-data'
							path = '/property-risk/sum-insured'
						}
					} else if (basementItem?.GoodsInTrust?.Present) {
						// path = '/property-risk/goods-in-trust-data'
						path = '/property-risk/sum-insured'
					}
				}
			}
		}

		history.push(path)
	}
	const goBack = () => {
		if (returnPath) {
			history.replace(returnPath)
		} else {
			history.replace('/property-risk/fire-system')
		}
	}

	// state setters wrapped in methods

	// universal page setter function
	const updateHistoricInfoData = (claimId, field, value) => {
		const claimData = HistoricInfoData.find(el => el.claimId === claimId)

		let updatedValue = value
		let isAmountField = field === 'Amount'
		// check for amount field
		if (isAmountField) {
			if (value || value === 0) {
				updatedValue = parseFloat(value)
			} else {
				updatedValue = ''
			}
		}

		if (claimData) {
			claimData[field] = updatedValue
			setHistoricInfoData(prev => {
				let updatedHistoricInfo = []
				prev?.forEach(el => {
					if (el.claimId === claimId) {
						updatedHistoricInfo.push(claimData)
					} else {
						updatedHistoricInfo.push(el)
					}
				})
				return updatedHistoricInfo
			})
		}
	}

	const setPolicyPDFFunc = v => setPolicyPDF({ ...policyPDF, url: v })

	const setOtherInsurerDataFunc = v => setOtherInsurerData(v)

	const setInsuredElsewhereFunc = v => setInsuredElsewhere(v)

	const setCoverDeniedFunc = v => setCoverDenied(v)

	const setDeclinedByFunc = v => setDeclinedBy(v)

	const createNewClaimsItem = () => {
		const hasPastClaims = PastClaims === 'YES'
		const newClaimItem = {
			claimId: `Claim #${!hasPastClaims ? 1 : HistoricInfoData.length + 1}`,
			Damaged: '',
			Year: '',
			Month: '',
			Amount: '',
			Status: '',
			Reasons: [],
			RiskMitigation: '',
		}

		if (!hasPastClaims) {
			setPastClaims('YES')
			setHistoricInfoData([newClaimItem])
		} else {
			setHistoricInfoData([...HistoricInfoData, newClaimItem])
		}
	}

	const removeClaimsItem = claimId => {
		const HistoricInfoDataCopy = HistoricInfoData.filter(claimItem => {
			return claimItem.claimId !== claimId
		})

		setHistoricInfoData(HistoricInfoDataCopy)
	}

	const removeAllClaimsItem = () => {
		setPastClaims('NO')
		setHistoricInfoData([])
	}

	return (
		<MainContainer
			header="Historic information"
			rightLabel="Save & Continue"
			rightOnClick={proceed}
			rightDisabled={proceedDisabled}
			leftOnClick={goBack}
			top={1.5}
		>
			<Alert
				bgColor={theme.colors.secondaryLightRed}
				iconName="infoIconOctagon"
				infoIconColor={theme.colors.red}
				titleColor={theme.colors.red}
			>
				<Text
					color={theme.colors.red}
					style={{
						fontWeight: 'bold',
					}}
				>
					Disclaimer: Please enter the client details here accurately. Any misrepresentation of
					information will result in the policy being cancelled or claim being denied!
				</Text>
			</Alert>
			<Space y={1} />
			<Text type="h5" fontWeight={700} style={{ lineHeight: '30.24px' }}>
				Please provide the following information:
			</Text>
			<AnotherInsuranceCompany
				setOtherInsurerData={setOtherInsurerDataFunc}
				setPolicyPDF={setPolicyPDFFunc}
				setInsuredElsewhere={setInsuredElsewhereFunc}
				InsuredElsewhere={InsuredElsewhere}
				otherInsurerData={otherInsurerData}
				policyPDF={policyPDF?.url || ''}
				propertyType={propertyType}
			/>
			<CoverDenialHistory
				CoverDenied={CoverDenied}
				declinedBy={declinedBy}
				setCoverDenied={setCoverDeniedFunc}
				setDeclinedBy={setDeclinedByFunc}
			/>
			<ClaimsHistory
				updateHistoricInfoData={updateHistoricInfoData}
				HistoricInfoData={HistoricInfoData}
				hasPastClaims={PastClaims}
				propertyType={propertyType}
				onNewClaimsAddition={createNewClaimsItem}
				onClaimsRemoval={removeClaimsItem}
				onAllClaimsRemoval={removeAllClaimsItem}
			/>
			{isSFSP ? (
				<Warranties
					hasWarranties={hasWarranties}
					warrantiesDesc={warrantiesDesc}
					handleWarrantiesPresence={handleWarrantiesPresence}
					handleWarrantiesDesc={handleWarrantiesDesc}
				/>
			) : null}
		</MainContainer>
	)
}
