import { useHistory } from 'react-router'
import { useContext } from 'react'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { Space, Text, theme, Button, Icon } from 'verak-ui'

export const StockLocationsIs0 = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	let history = useHistory()

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 0,
					StockDisclosureMethod: '',
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		if (state.quote?.PolicyName === 'SFSP') {
			history.push('/sfsp/sum-insured-methods')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}
	return (
		<>
			<Text type="primary" color={theme.colors.primary}>
				Please note - if you still need to declare stock, you will be able to do so at the risk
				locations.
			</Text>
			<Space y={1.5} />
			<Text type="primary" color={theme.colors.secondary}>
				However, if you choose to do so - further details (such as choice of disclosure method) may
				be required.
			</Text>
			<Space y={1.5} />
			<ModalButton proceed={proceed} />
		</>
	)
}

export const StockLocationsIs1 = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	let history = useHistory()
	const isDraftRFQ = state?.rfq?.draftRFQ?._id

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_NUMSTOCK_AND_DISCLOSURE_METHOD',
				data: {
					NumStock: 1,
					StockDisclosureMethod: 'DECLARED_VALUE',
				},
			})
			resolve()
		})

		if (state?.quote?.PolicyKind === 'STOCK_ONLY' && !isDraftRFQ) {
			// Risk locations get initialized to the same number as stock since that ques is skipped
			dispatch({
				type: 'SET_RISK_LOCATION_COUNT',
				data: {
					NumRiskLoc: 1,
				},
			})
		}

		await fetch.saveQuote()
		if (state.quote?.PolicyName === 'SFSP') {
			history.push('/sfsp/sum-insured-methods')
		} else {
			history.push('/journey/risk-location-selection')
		}
	}
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<Text style={{ fontSize: '18px', fontWeight: '700' }} color={theme.colors.red}>
				Since only 1 risk location has stock - Declared Value method for disclosing stock value must
				be used.
			</Text>
			<Space y={1} />
			<ModalButton proceed={proceed} />
		</>
	)
}

export const StockLocationsIsMoreThan1 = ({ NumStock }) => {
	const [state, dispatch] = useContext(Context)
	let history = useHistory()
	const isDraftRFQ = state?.rfq?.draftRFQ?._id

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_LOCATION_WITH_STOCK',
				data: {
					NumStock: NumStock,
				},
			})
			resolve()
		})

		if (state?.quote?.PolicyKind === 'STOCK_ONLY' && !isDraftRFQ) {
			// Risk locations get initialized to the same number as stock since that ques is skipped
			dispatch({
				type: 'SET_RISK_LOCATION_COUNT',
				data: {
					NumRiskLoc: NumStock,
				},
			})
		}

		history.push('/stock-disclosure-choice/stock-in-process')
	}
	return (
		<>
			<Icon name="infoIconOctagon" size="100px" style={{ margin: '0 auto' }} />
			<Space y={1} />
			<Text style={{ fontSize: '18px', fontWeight: '700' }} color={theme.colors.red}>
				To provide the list of Stock disclosure methods which are applicable - some further
				information is required from the client.
			</Text>
			<Space y={1} />
			<ModalButton proceed={proceed} />
		</>
	)
}

export const FloaterDeclarationError = () => {
	const fetch = useFetch()
	const [state, dispatch] = useContext(Context)
	let history = useHistory()

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_STOCK_DISCLOSURE_METHOD',
				data: {
					StockDisclosureMethod: '',
				},
			})
			resolve()
		})

		await fetch.saveQuote()
		history.push('/stock-disclosure-choice/stock-select')
	}
	return (
		<>
			<Text type="primary" color={theme.colors.primary}>
				As per the Guidelines, a Floater Declaration policy for stock must: <br />
				1. Have a minimum Sum Insured of ₹2 crores <br />
				2. Have at least one location with a Sum Insured &gt; ₹25 Lakhs
			</Text>
			<Space y={1.5} />
			<Text type="primary" color={theme.colors.secondary}>
				Since the client’s stock does NOT meet this criteria - you need to pick a new Stock
				disclosure method
			</Text>
			<Space y={1.5} />
			<ModalButton proceed={proceed} />
		</>
	)
}

export const ModalButton = ({ proceed }) => {
	return (
		<Button
			label="Continue"
			icon="rightArrow"
			iconAlign="right"
			style={buttonStyle}
			onClick={proceed}
		/>
	)
}

const buttonStyle = {
	width: '100%',
	height: '64px',
	fontSize: '18px',
}
