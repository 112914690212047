import { useHistory } from 'react-router'
// import { useFetch } from '../../../../api/apihook'
// import { useContextApi } from '../../../../context/Provider'
import FinalReviewScreen from '../../../FinalReview/FinalReview'

const QuotesPendingFinalReview = () => {
	const history = useHistory()
	// const { quotesPendingResubmitRfq } = useFetch()
	// const [state, dispatch] = useContextApi()

	// const currentRfqId = state?.quote?.quoteId || ''

	const onBack = () => {
		history.push('/rfq-status/quotes-pending/summary')
	}
	const onProceed = async () => {
		// await quotesPendingResubmitRfq(currentRfqId)
		history.push('/rfq-status/update-rfq-success')
	}
	return <FinalReviewScreen onBack={onBack} onProceed={onProceed} />
}

export default QuotesPendingFinalReview
