import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useContext, useState, useEffect } from 'react'
import { useFetch } from '../../../api/apihook'

import { Context } from '../../../context/Provider'
import { LocationCard } from '../../JourneyStart/RiskLocationSelection'
import { LocationReview } from '../../../components/Tags'
import { checkForTypeOfOccupancy } from '../../FinalReview/finalReviewUtils'
import { SFSPFinalConfirmation } from './Modals'
import {
	isShopRiskPresent,
	isMaterialsPresent,
	isSumInsuredPresent,
} from '../../JourneyStart/riskLocationSelectionUtils'

import { Icon, Space, Text, theme, Modal, MainContainer } from 'verak-ui'

const RiskLocationSelection = () => {
	const fetch = useFetch()

	const cachedData = fetch?.cachedData?.SubCategories?.required

	const fetchSubCategories = async () => {
		if (!cachedData) {
			await fetch.getSubCategoryByRiskType()
		}
	}

	useEffect(() => {
		if (!cachedData) {
			fetchSubCategories()
		}
	}, []) // eslint-disable-line

	let history = useHistory()
	const [state, dispatch] = useContext(Context)
	const [finalModal, setFinalModal] = useState(false)

	const { RiskLocations } = state.quote
	const locationCount = RiskLocations?.length

	useEffect(() => {
		dispatch({
			type: 'SAVE_LOCATION_CHANGES',
		})
		dispatch({
			type: 'DONE_WITH_LOCATION_CHANGES',
		})
	}, [])

	const getLocationStatus = location => {
		const locationData = RiskLocations?.find(loc => loc.ID === location.ID)
		let questions = 2

		// is dwellings check -> shop risk

		const isDwellings = checkForTypeOfOccupancy(locationData, cachedData)

		if (isDwellings) {
			questions += 1
			if (isShopRiskPresent(locationData) || isMaterialsPresent(locationData)) {
				questions -= 1
			}
		}

		// check for materials
		if (isMaterialsPresent(locationData)) {
			questions -= 1
		}

		// check for sum insured
		// Working of marking this question done:
		// If the sales exec immediately clicks "No" its marked as done as well as "Yes"
		// but if the sales exec leaves and comes back he/she will be asked this ques again - provided the check for plinth doesn't satisy
		if (isSumInsuredPresent(locationData) || locationData?.Answered) {
			questions -= 1
		}

		if (questions === 0) {
			return 'Complete'
		} else if (questions === 1) {
			return `${questions} Value missing`
		} else {
			return `${questions} Values missing`
		}
	}

	const navigateToNextScreen = async location => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'MOVE_TO_LOCATION',
				data: {
					locationId: location?.ID,
				},
			})
			resolve()
		})

		const locationData = RiskLocations?.find(loc => loc?.ID === location?.ID)

		// is dwellings check -> shop risk
		const isDwellings = checkForTypeOfOccupancy(locationData, cachedData)

		if (isDwellings) {
			history.push({
				pathname: '/sfsp-edgecase/shop-risk',
				state: {
					isSFSPEdgeCase: true,
				},
			})
		} else {
			history.push({
				pathname: '/sfsp-edgecase/materials',
				state: {
					isSFSPEdgeCase: true,
				},
			})
		}
	}

	let locationList = []
	let locationStatuses = []
	RiskLocations?.forEach((location, index) => {
		let locStatus = getLocationStatus(location)
		locationStatuses.push(locStatus)

		let PIN = location?.PIN
		if (location.Type === 'PROPERTYBASEMENT') {
			PIN = location?.property?.PIN
		}
		locationList.push(
			<Row key={location.ID}>
				<LocationCard
					onClick={() => navigateToNextScreen(location)}
					className={` ${
						index == locationCount ? 'mb-5' : 'mb-3'
					} p-3 d-flex align-items-center justify-content-between`}
				>
					<LocationReview
						label={location.label}
						type={location.Type}
						status={locStatus}
						pinCode={PIN}
					/>
					<Icon name="rightArrow" color={theme.colors.gray2} />
				</LocationCard>
			</Row>
		)
	})

	let allLocationsFilled = true
	locationStatuses.forEach(status => {
		if (status !== 'Complete') {
			allLocationsFilled = false
		}
	})

	const handleBack = () => {
		// need this because otherwise it would take you to the last page which gets bricked
		// since it needs an object in state.location which doesn't exist anymore
		history.push({
			pathname: '/sfsp-edgecase/sum-insured-methods',
			state: {
				isSFSPEdgeCase: true,
			},
		})
	}

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_TYPE',
				data: {
					PolicyName: 'SFSP',
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		history.push('/sfsp/add-ons')
	}

	return (
		<MainContainer
			header="Please enter the missing values for the following locations"
			rightLabel="Continue"
			rightDisabled={!allLocationsFilled}
			rightOnClick={() => setFinalModal(true)}
			leftOnClick={handleBack}
			top={3}
		>
			<Text type="primary">Click on a risk location to continue:</Text>
			<Space y={2} />
			{locationList}
			<Modal
				show={finalModal}
				handleClose={() => setFinalModal(false)}
				closeButton={true}
				title={
					<Text type="primary" color={theme.colors.red}>
						Notice
					</Text>
				}
				footer={<></>}
				style={{ textAlign: 'center' }}
			>
				<SFSPFinalConfirmation proceed={proceed} />
			</Modal>
		</MainContainer>
	)
}

export default RiskLocationSelection
