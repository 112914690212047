import { useHistory } from 'react-router'
import { useState, useEffect, useContext } from 'react'
import { MainContainer, Space, Radio } from 'verak-ui'
import { Container } from 'react-bootstrap'
import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { Header, CCTVWarning, inputStyle, AddressField, Label } from './Common'
import { radioOptionTextStyle } from '../DraftRFQ/DraftRFQForm'
import FormInput from '../../components/FormInput'

import cicImg from '../../assets/icons/cic.svg'

const CIC = () => {
	const history = useHistory()
	const [state, dispatch] = useContext(Context)
	let fetch = useFetch()
	const PolicyAddons = state?.quote?.PolicyAddons
	const rlData = state?.quote?.RiskLocations[0]

	const [CICData, setCICData] = useState(state?.quote?.CIC || {})
	const [proceedDisabled, setProceedDisabled] = useState(true)
	const handleCICData = (val, key) => {
		let prevValues = { ...CICData }
		prevValues[key] = val
		setCICData(prevValues)
	}

	const proceed = async () => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_CIC_DATA',
				data: {
					CIC: CICData,
				},
			})
			resolve()
		})

		await fetch.saveQuote()

		if (PolicyAddons?.CIS?.Present) {
			history.push('/cis')
		} else if (PolicyAddons?.CIT?.Present) {
			history.push('/cit')
		} else {
			history.push('/summary')
		}
	}

	const back = () => {
		history.goBack()
	}

	useEffect(() => {
		if (
			CICData?.Amount &&
			typeof CICData?.IsCounterLocked === 'boolean' &&
			typeof CICData?.IsCCTVPresent === 'boolean' &&
			CICData?.Address
		) {
			setProceedDisabled(false)
		} else {
			setProceedDisabled(true)
		}
	}, [CICData])

	return (
		<Container>
			<MainContainer
				header="Enter Details"
				divider={true}
				rightOnClick={proceed}
				rightDisabled={proceedDisabled}
				leftOnClick={back}
				top={1}
			>
				<Header img={cicImg} header="Cash in Counter" />
				<Label compulsory>Amount to be insured</Label>
				<FormInput
					placeholder="Enter here"
					style={inputStyle}
					onChange={e => handleCICData(e.target.value, 'Amount')}
					value={CICData?.Amount}
					compulsory
					autoComma
					rupee
				/>
				<Space y={1.5} />
				<Label compulsory>Is the register fully locked/secured?</Label>
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CICData?.IsCounterLocked === true}
						onClick={() => handleCICData(true, 'IsCounterLocked')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CICData?.IsCounterLocked === false}
						onClick={() => handleCICData(false, 'IsCounterLocked')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				<Space y={1.5} />
				<Label compulsory>Is a CCTV present at the location?</Label>
				<div className="d-flex">
					<Radio
						className="flex-fill"
						text="Yes"
						secondary={true}
						value={CICData?.IsCCTVPresent === true}
						onClick={() => handleCICData(true, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
					<Radio
						className="flex-fill"
						text="No"
						secondary={true}
						value={CICData?.IsCCTVPresent === false}
						onClick={() => handleCICData(false, 'IsCCTVPresent')}
						textStyle={radioOptionTextStyle}
					/>
				</div>
				{CICData?.IsCCTVPresent === false ? CCTVWarning : null}
				{/* disabled is false since this is the first add on to show up */}
				<AddressField
					type="counter"
					value={CICData?.Address}
					handleUpdate={handleCICData}
					disabled={false}
					rlData={rlData}
				/>
			</MainContainer>
		</Container>
	)
}

export default CIC
