import styled from 'styled-components'
import { Icon } from 'verak-ui'
import { QuoteStatusTag } from '../../../components/Tags'
import { convertAmountToWords } from '../../RiskLocationReview/reviewScreenUtils'

const responseItemConfig = {
	ACCEPTED: { color: '#BDFFD0', textColor: '#275534', icon: '✓' },
	REJECTED: { color: '#FFDAD7', textColor: '#DC2F21', icon: '×' },
	FURTHERREQ: { color: '#FAF098', textColor: '#7B6F00', icon: '?' },
}

/* components */
const ResponseItem = ({ type, count }) => {
	return (
		<ResponseContainerBackground
			className="d-flex justify-content-center me-2"
			background={responseItemConfig[type].color}
			textColor={responseItemConfig[type].textColor}
		>
			{responseItemConfig[type].icon}
			<span className="ms-1 h-100" style={{ color: responseItemConfig[type].textColor }}>
				{count}
			</span>
		</ResponseContainerBackground>
	)
}

/* METHODS */
const getStatusTag = leadItem => {
	const status = leadItem?.Status
	const metaStatus = leadItem?.MetaStatus
	const isSelfServeRfq = leadItem?.SelfServed
	return (
		<QuoteStatusTag
			status={status}
			discrepancyFlag={metaStatus?.Discrepancy}
			isNew={getNewTag(status, metaStatus)}
			isSelfServeRfq={isSelfServeRfq}
			metaStatus={{ ...metaStatus, PaymentDropoff: leadItem?.PaymentDropoff }}
		/>
	)
}

const getNewTag = (status, metaStatus) => {
	if (metaStatus.Abandoned || metaStatus?.FurtherRequirements) {
		return false
	}

	return (
		(status === 'AGENT:QUOTES:PENDING' ||
			status === 'AGENT:ALL_QUOTES:PENDING' ||
			status === 'OPS:RFQ_RESUBMISSION:PENDING') &&
		metaStatus?.AgentQuotesAvailable &&
		!metaStatus.Discrepancy
	)
}

const getLastPathAndLocationId = LastPath => {
	if (LastPath === '') {
		return ['/journey/coverage-details', '']
	} else if (LastPath?.includes('?locationID')) {
		let pathArr = LastPath.split('?locationID=')

		if (pathArr?.length > 1) {
			return pathArr
		} else {
			return ['/journey/coverage-details', '']
		}
	} else {
		return [LastPath, '']
	}
}

const getLowestPremium = leadItem => {
	let lowestPremiumString = convertAmountToWords(`₹ ${leadItem?.lowestPremium || 0}`)
	if (lowestPremiumString.includes('Crores')) {
		lowestPremiumString = lowestPremiumString.replace('Crores', 'Cr')
	} else if (lowestPremiumString.includes('Lakhs')) {
		lowestPremiumString = lowestPremiumString.replace('Lakhs', 'Lac')
	}
	lowestPremiumString = lowestPremiumString.trim() === '0' ? '--' : lowestPremiumString
	return lowestPremiumString
}

const getResponsesContainer = quotes => {
	return (
		<div className="d-flex align-items-center">
			<ResponseItem type="ACCEPTED" count={quotes?.Accepted} />
			<ResponseItem type="REJECTED" count={quotes?.Rejected} />
			<ResponseItem type="FURTHERREQ" count={quotes?.FurtherReq} />
		</div>
	)
}

/* VARS */

const newQuoteMenu = [
	{ text: 'New Quote', icon: 'editPencilSquare', id: 'NEW_QUOTE' },
	// { text: 'Renewal', icon: 'renewPolicy', id: 'RENEWAL' },
]

const sortMenuItems = [
	{ text: 'Latest First', value: 'LatestFirst', icon: 'filterLatest' },
	{ text: 'Oldest First', value: 'OldestFirst', icon: 'filterOldest' },
]

const lostFilter = { text: 'Lost', value: 'Lost' }

const statusFilterMenuItems = [
	{ text: 'None', value: '' },
	{ text: 'Quotes Ready', value: 'Quotes ready' },
	{ text: 'Purchase successful', value: 'Purchase successful' },
	{ text: 'Policy Issued', value: 'Policy Issued' },
	{ text: 'Purchase confirmation pending ', value: 'Purchase confirmation pending' },
	{ text: 'In Progress', value: 'In Progress' },
	{ text: 'RFQ submitted', value: 'RFQ submitted' },
	{ text: 'Quotes pending', value: 'Quotes pending' },
	{ text: 'Quotes abandoned', value: 'Quotes abandoned' },
]

const HeaderContent = ({ text, icon, iconColor = '#8D8D8D' }) => {
	return (
		<span className="d-flex align-items-center">
			{icon && <Icon className="me-1" name={icon} color={iconColor} />}
			{text}
		</span>
	)
}

// table header items
const headerItems = [
	{ key: 'Name', content: <HeaderContent icon="buildingSm" text="Client Name" />, width: '200px' },
	{ key: 'Phone', content: <HeaderContent icon="phoneSm" text="Number" /> },
	{ key: 'createdAt', content: <HeaderContent icon="lastUpdatedSm" text="RFQ Creation Date" /> },
	{ key: 'lowestPremium', content: <HeaderContent icon="" text="₹ Lowest Quote Premium" /> },
	{ key: 'responses', content: <HeaderContent icon="bellIcon" text="Responses" /> },
	{ key: 'Status', content: <HeaderContent icon="statusSm" text="Status" /> },
]

/* STYLES */

const emptySearchStyle = {
	borderRadius: '5px',
	padding: '8px',
	paddingLeft: '42px',
	maxHeight: '48px',
	boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
}

const MenuItemContainer = styled.li`
	display: flex;
	align-items: center;
	padding: 0.75rem 0.375rem;
	border-bottom: ${props => (props?.isLast ? '0.5px solid #8d8d8d' : 'none')};
	list-style: none;
	background-color: ${({ disabled }) => (disabled ? '#e3e3e3' : '#fff')};
	opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0rem;
	color: #8d8d8d;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	&:hover {
		background: ${({ disabled }) => (disabled ? '' : ' #e9e9e9')};
	}
`

const MenuContainer = styled.ul`
	min-width: 200px;
	padding: 0;
`

const PseudoCheckbox = styled.div`
	background: ${props => (props.isSelected ? '#FF934F' : 'white')};
	border: 3px solid ${props => (props.isSelected ? '#FF934F' : '#B3B3B3')};
	height: 24px;
	width: 24px;
	border-radius: 3px;
	font-size: 16px;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
`

const ResponseContainerBackground = styled.div`
	background: ${props => props?.background};
	border-radius: 2px;
	height: 30px;
	width: 36px;
	color: ${props => props?.textColor};
	padding-top: 6px;
`

export {
	// STYLES
	emptySearchStyle,
	MenuItemContainer,
	MenuContainer,
	PseudoCheckbox,
	// METHODS
	getLastPathAndLocationId,
	getStatusTag,
	getLowestPremium,
	getResponsesContainer,
	// VARS
	newQuoteMenu,
	statusFilterMenuItems,
	sortMenuItems,
	headerItems,
	lostFilter,
	// COMPONENTS
	HeaderContent,
}
