import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useState, useContext } from 'react'

import { Context } from '../../context/Provider'
import { useFetch } from '../../api/apihook'

import { MainContainer, Text, theme, Button, Space } from 'verak-ui'
import EditNoticeModal from '../../components/EditNoticeModal'
import { isRfqEqual, editRfqAllowedStatuses } from '../../helpers/editRfqUtils'

const NoteForInsurer = () => {
	const [state, dispatch] = useContext(Context)
	const flowCompletionRoutes = state?.sqs?.flowCompletionRoutes || {}
	const { finalRoute, redirectRoute } = flowCompletionRoutes

	let history = useHistory()
	let fetch = useFetch()

	const [note, setNote] = useState(state?.quote?.Note || '')
	const [showEditNoticeModal, setShowEditNoticeModal] = useState(false)

	const rfqStatus = state?.rfq?.rfqStatus || state?.quote?.Status

	const handleSave = async () => {
		if (
			editRfqAllowedStatuses.includes(rfqStatus) &&
			state?.rfq?.editRfqMode &&
			!isRfqEqual(state?.quote, state?.oldRfq)
		) {
			setShowEditNoticeModal(true)
		} else {
			await saveRfqWithNote()
			redirectForward()
		}
	}

	const saveRfqWithNote = async () => {
		await new Promise(resolve => {
			dispatch({
				type: 'SET_NOTE_FOR_INSURER',
				data: {
					Note: note,
				},
			})
			resolve()
		})

		await fetch.saveQuote()
		return Promise.resolve()
	}

	const redirectForward = () => {
		let nextRoute = '/rfq-email'
		if (finalRoute === window.location.pathname) {
			nextRoute = redirectRoute
		}
		history.push(nextRoute)
	}

	const goBack = () => history.goBack()

	return (
		<MainContainer
			header="Add remarks or notes for the Insurer"
			icon="yes"
			headerBg="#FF934F"
			hasLeftBtn={false}
			divider={false}
		>
			<EditNoticeModal
				show={showEditNoticeModal}
				handleClose={() => setShowEditNoticeModal(false)}
				redirectForward={redirectForward}
				saveRfqWithNote={saveRfqWithNote}
			/>
			<Space y={6} />
			<Text style={{ fontSize: '14px', letterSpacing: '0.03em' }} color={theme.colors.gray2}>
				Describe the business conducted at the risk location in brief or add any points you would
				like to convey to the Insurers
			</Text>
			<Space y={1} />
			<StyledTextArea
				placeholder="Note for insurer"
				value={note}
				onChange={e => setNote(e.target.value)}
			/>
			<Space y={3} />
			<div className="w-100 d-flex justify-content-center align-items-center">
				<Button
					onClick={goBack}
					label="Back"
					secondary
					iconColor={theme.colors.primary}
					icon="leftArrow"
					iconAlign="left"
					style={{ height: '48px' }}
				/>
				<Button
					label="Save and continue"
					style={{ width: 'min(430px, 100%)', height: '48px', marginLeft: '10px' }}
					onClick={handleSave}
				/>
			</div>
			<Space y={6} />
		</MainContainer>
	)
}

export default NoteForInsurer

const StyledTextArea = styled.textarea`
	width: 100%;
	height: 110px;
	border: 0.5px solid #03045e;
	border-radius: 8px;
	padding: 0.625rem;
	font-size: 14px;
`
