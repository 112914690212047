import { useState } from 'react'
import { useHistory } from 'react-router'
import styled, { keyframes } from 'styled-components'
import { theme, Text, Button, Space, Accordion, Input, Radio, Checkbox, Select } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../../../components/SecondaryNavbar'

import confirmationLottie from '../../../../assets/confirmationLottie.svg'

const AccordionHeader = ({ children }) => (
	<Text type="body1" color={theme.colors.secondary} fontWeight="700">
		{children}
	</Text>
)

const GenerateInputField = ({ inputFieldData, handleChange }) => {
	const { id, fieldType, label, value, values, fields, ...otherProps } = inputFieldData

	let inputField = null

	switch (fieldType) {
		case 'text':
			inputField = (
				<Input
					{...otherProps}
					label=""
					placeholder="Enter value here"
					type="text"
					defaultValue={value}
					onBlur={e => handleChange(e.target.value)}
				/>
			)
			break
		case 'number':
			inputField = (
				<Input
					{...otherProps}
					label=""
					placeholder="Enter value here"
					type="number"
					defaultValue={value}
					onBlur={e => handleChange(e.target.value)}
				/>
			)
			break
		case 'amount':
			inputField = (
				<Input
					{...otherProps}
					label=""
					placeholder="Enter value here"
					type="number"
					rupee
					autoComma
					value={value}
					onBlur={e => handleChange(e.target.value)}
				/>
			)
			break
		case 'radio':
			if (fields?.length) {
				let radioFields = []
				fields.forEach(field => {
					radioFields.push(
						<>
							<Radio
								{...otherProps}
								text={field?.label}
								value={field?.value === value}
								name="choice"
								onChange={() => handleChange(field?.value)}
							/>
							<Space x={0.5} />
						</>
					)
				})

				inputField = <div className="d-flex">{radioFields}</div>
			}
			break

		case 'checkbox':
			if (fields?.length) {
				let checkboxFields = []
				fields.forEach(field => {
					checkboxFields.push(
						<>
							<Checkbox
								{...otherProps}
								text={field?.label}
								value={value.includes(field?.value)}
								name="choice"
								onChange={checked => {
									let updatedFieldItems = value || []
									if (checked) {
										updatedFieldItems.push(field?.value)
									} else {
										updatedFieldItems = updatedFieldItems?.filter(item => item !== field?.value)
									}
									handleChange(updatedFieldItems)
								}}
							/>
							<Space x={0.5} />
						</>
					)
				})

				inputField = <div className="d-flex">{checkboxFields}</div>
			}
			break

		case 'select':
			if (fields?.length) {
				const options = []
				fields.forEach(field => {
					options.push(<option id={field}>{field}</option>)
				})

				inputField = (
					<Select
						{...otherProps}
						placeholder="Select any value"
						value={value}
						onChange={handleChange}
					>
						{options}
					</Select>
				)
			}
			break

		default:
			break
	}

	return (
		<>
			<Text type="body1" fontWeight="700">
				{label}
			</Text>
			<Space y={0.5} />
			{inputField}
			<Space y={1.5} />
		</>
	)
}

const AccordionBody = ({ locationData, handleChange }) => {
	let renderLocationData = null

	if (locationData?.length) {
		renderLocationData = []

		locationData?.forEach(locDataItem => {
			renderLocationData.push(
				<GenerateInputField
					key={locDataItem?.id}
					inputFieldData={locDataItem}
					handleChange={value => handleChange(locDataItem?.id, value)}
				/>
			)
		})
	}

	return renderLocationData
}

// TODO: based on API response for missing fields, generate the appropriate field type
// predefined field types can be: amount, radio, checkbox, free-text, dropdown select, file-upload, etc
const dummyLocationMissingData = [
	{
		id: 'missing-data-1',
		header: 'Location 1, Floor 1, Pin - 400 021',
		missingDataItems: [
			{
				id: 'missing-data-1-1',
				fieldType: 'text',
				label: 'Enter text',
				value: 'abc',
			},
			{
				id: 'missing-data-1-2',
				fieldType: 'radio',
				label: 'Select value',
				value: 'no',
				fields: [
					{
						label: 'Yes',
						value: 'yes',
					},
					{
						label: 'No',
						value: 'no',
					},
				],
			},
			{
				id: 'missing-data-1-3',
				fieldType: 'select',
				label: 'Select an option from the list',
				value: 'I select the first item',
				fields: [
					'I select the first item',
					'I select the second item',
					'I select the third item',
					'I select the fourth item',
				],
			},
		],
	},
	{
		id: 'missing-data-2',
		header: 'Location 2, Floor 2, Pin - 400 021',
		missingDataItems: [
			{
				id: 'missing-data-2-1',
				fieldType: 'amount',
				label: 'Enter amount',
				value: 201991,
			},
			{
				id: 'missing-data-2-2',
				fieldType: 'checkbox',
				label: 'Select value',
				value: ['option-3'],
				fields: [
					{
						label: 'Option 1',
						value: 'option-1',
					},
					{
						label: 'Option 2',
						value: 'option-2',
					},
					{
						label: 'Option 3',
						value: 'option-3',
					},
					{
						label: 'Option 4',
						value: 'option-4',
					},
				],
			},
			{
				id: 'missing-data-2-3',
				fieldType: 'select',
				label: 'Select any option',
				value: 'I select the first item',
				fields: [
					'I select the first item',
					'I select the second item',
					'I select the third item',
					'I select the fourth item',
				],
			},
		],
	},
]

const FillMissingData = () => {
	const history = useHistory()
	const [submittedDetails, setSubmittedDetails] = useState(false)

	const [locationMissingData, setLocationMissingData] = useState(dummyLocationMissingData)

	const handleLocationDataItemChange = (locationId, dataItemId, value) => {
		console.log('receivedData', { locationId, dataItemId, value })
		setLocationMissingData(locationData => {
			let updatedLocationData = []

			locationData?.forEach(location => {
				if (location?.id === locationId) {
					let updatedDataItems = []
					location?.missingDataItems?.forEach(dataItem => {
						if (dataItem?.id === dataItemId) {
							updatedDataItems.push({
								...dataItem,
								value: value,
							})
						} else {
							updatedDataItems.push(dataItem)
						}
					})

					updatedLocationData.push({
						...location,
						missingDataItems: updatedDataItems,
					})
				} else {
					updatedLocationData.push(location)
				}
			})

			return updatedLocationData
		})
	}

	const submitDetails = () => {
		// API call: for submitting missing data

		setSubmittedDetails(true)
	}

	let leftNavText = `Overview, {UID} - {Client Name} - {Policy Type}`

	let navLeftContent = (
		<Text type="body1" color="rgba(229,229,229, 0.6)" fontWeight="700">
			{leftNavText}
			<span style={{ color: '#fff' }}>{` > Missing data`}</span>
		</Text>
	)

	const navRightContent = (
		<div className="d-flex align-items-center">
			<Text type="body1" color="rgba(229,229,229, 0.6)" fontWeight="700">
				Sum Insured:
				<span style={{ color: '#fff' }}>{`{₹150 Crore}`}</span>
			</Text>
			<Space x={0.5} />
			<Button
				icon="checkboxImg"
				bgColor="#fff"
				textColor={theme.colors.primary}
				iconColor={theme.colors.primary}
				label="Submit updated details"
				iconAlign="left"
				iconStyles={{ marginRight: '0.5rem' }}
				onClick={submitDetails}
			/>
		</div>
	)

	let locationList = []

	locationMissingData.forEach(locationData => {
		locationList.push({
			id: locationData?.id,
			header: <AccordionHeader>{locationData?.header}</AccordionHeader>,
			content: (
				<AccordionBody
					locationData={locationData?.missingDataItems}
					handleChange={(dataItemId, value) =>
						handleLocationDataItemChange(locationData?.id, dataItemId, value)
					}
				/>
			),
		})
	})

	let pageContent = (
		<>
			<SecondaryNavbar
				headerBg={theme.colors.red}
				navbarNumber={1}
				leftContent={navLeftContent}
				rightContent={navRightContent}
			/>

			<div
				style={{
					borderRadius: '8px',
					width: 'min(1200px, 100%)',
				}}
				className="container h-100 p-3 mt-4 mx-2 d-flex flex-column"
			>
				<Text fontWeight="700" fontSize="24px" color={theme.colors.primary}>
					Please complete the below requirements from the Underwriter to continue with the quote
					generation
				</Text>

				<Accordion accordionList={locationList} defaultActiveKey="missing-data-1" />
			</div>
		</>
	)

	const returnToQuotesInProgress = () => history.push('/quotes-in-progress')

	if (submittedDetails) {
		leftNavText = `Progress! , {UID} - {Client Name} - {Policy Type}`

		navLeftContent = (
			<Text type="body1" color={theme.colors.primary} fontWeight="700">
				{leftNavText}
			</Text>
		)

		pageContent = (
			<>
				<SecondaryNavbar
					headerBg={theme.colors.secondary2}
					navbarNumber={1}
					leftContent={navLeftContent}
					rightContent=""
				/>
				<div
					style={{
						boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)',
						borderRadius: '8px',
						width: '95%',
					}}
					className="container h-100 bg-white p-3 mt-4 mx-2 d-flex justify-content-between align-items-center flex-column"
				>
					<div className="d-flex justify-content-between align-items-center flex-column">
						<Text color={theme.colors.gray7} fontSize="24px" fontWeight="700">
							Submitted
						</Text>

						<CustomDiv>
							<ConfirmationSuccessImage className="mb-4" src={confirmationLottie} />
						</CustomDiv>

						<Text color={theme.colors.primary} fontSize="18px" fontWeight="bold" className="mb-4">
							We will notify you when the Underwriter has approved all changes
						</Text>
					</div>

					<div
						className="d-flex justify-content-end align-items-center mt-2"
						style={{
							background: 'white',
							padding: '12px 24px',
							width: '100%',
							borderTop: '1.5px solid #02475E',
						}}
					>
						<Button
							label="Continue"
							icon="rightArrow"
							onClick={returnToQuotesInProgress}
							iconAlign="right"
						/>
					</div>
				</div>
			</>
		)
	}

	return (
		<PageContentAndNavbarWrapper className="d-flex flex-column align-items-center">
			{pageContent}
		</PageContentAndNavbarWrapper>
	)
}

const lottieAnimation = keyframes`
	from{
		transform: scale(1);
	}
	to{
	transform: scale(.5);
	}
`

const fadeIn = keyframes`
	from{
		opacity: 0.3;
	}
	to{
		opacity: 1;
	}
`

const ConfirmationSuccessImage = styled.img`
	animation-name: ${lottieAnimation};
	animation-duration: 1s;
	animation-iteration-count: 1;
	transform: scale(0.5);
`

const CustomDiv = styled.div`
	animation-name: ${fadeIn};
	animation-duration: 2s;
	animation-iteration-count: 1;
`

export default FillMissingData
