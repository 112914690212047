import { theme, Text, Button, Icon, BottomPopup } from 'verak-ui'
import PageContentAndNavbarWrapper from '../../components/PageContentAndNavbarWrapper'
import SecondaryNavbar from '../../components/SecondaryNavbar'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router'
import { useState, useContext } from 'react'
import { Context } from '../../context/Provider'

const btnIconStyle = {
	marginRight: '.7rem',
}

export default function SelectSumInsuredRange() {
	const [, dispatch] = useContext(Context)

	const [selectedRange, setSelectedRange] = useState()

	const history = useHistory()

	const { state: locationState } = useLocation()

	const navContent = (
		<Text type="body1" color={theme.colors.gray7} fontWeight="700">
			Select the Sum Insured range
		</Text>
	)

	const startQuote = async quoteType => {
		await new Promise((resolve, reject) => {
			dispatch({
				type: 'SET_POLICY_TYPE',
				data: {
					PolicyName: quoteType,
				},
			})
			resolve()
		})

		history.push('/journey/client-details', locationState)
	}

	const navigateToAddonsScreen = () => {
		// history.push('/new-quote/select-addons') // TODO: enable this when other journeys are available
	}

	const selectSumInsured = v => setSelectedRange(v)

	let FireAllRiskPopup

	if (selectedRange === 'BLUS' || selectedRange === 'SFSP') {
		FireAllRiskPopup = (
			<BottomPopup
				style={{
					background: 'linear-gradient(180deg, #D55C28 0%, #FF934F 100%)',
					position: 'inherit',
				}}
			>
				<div className="d-flex flex-column" style={{ padding: '4px' }}>
					<div className="d-flex mb-4">
						<Icon name="sumInsuredShield" />
						<Text fontSize="24px" fontWeight="700" color="white">
							Does the client wish to opt for a Fire All Risk package? (Comprehensive fire policy)
						</Text>
					</div>

					<div className="d-flex justify-content-end">
						<Button
							onClick={() => startQuote('BLUS')}
							bgColor="#FFBE9C"
							label="Not now"
							color="white"
							style={{
								padding: '16px',
								height: '56px',
								marginRight: '2rem',
							}}
						/>

						<Button
							onClick={navigateToAddonsScreen}
							bgColor="white"
							label="Yes, let's continue"
							icon="rightArrow"
							iconAlign="right"
							iconColor={theme.colors.secondary}
							style={{
								color: theme.colors.secondary,
								padding: '16px',
								height: '56px',
							}}
						/>
					</div>
				</div>
			</BottomPopup>
		)
	}

	return (
		<PageContentAndNavbarWrapper className="h-100 d-flex flex-column justify-content-between">
			<SecondaryNavbar headerBg="white" navbarNumber={1} leftContent={navContent} rightContent="" />

			<div className="container p-3 h-100">
				<Text fontSize="14px" fontWeight="400" color={theme.colors.gray3} className="mb-3">
					Select an option to continue
				</Text>

				<div className="d-flex">
					<CustomButton
						disabled={selectedRange && selectedRange !== 'BSUS'}
						iconStyles={btnIconStyle}
						icon="bsus"
						label={
							<div className="w-100 d-flex justify-content-between align-items-center">
								less than ₹5 Crore
								<Icon name="rightArrow" color={theme.colors.gray2} />
							</div>
						}
						onClick={() => startQuote('BSUS')}
					/>

					<CustomButton
						iconStyles={btnIconStyle}
						icon="blus"
						label={
							<div className="w-100 d-flex justify-content-between align-items-center">
								₹5 Crore to ₹50 Crore
								<Icon name="rightArrow" color={theme.colors.gray2} />
							</div>
						}
						// onClick={() => selectSumInsured('BLUS')} // uncomment this line when FAR addons are available
						onClick={() => startQuote('BLUS')}
					/>

					<CustomButton
						// disabled={true} // TODO: add logic when SFSP workflow is ready
						iconStyles={btnIconStyle}
						icon="sfsp"
						label={
							<div className="w-100 d-flex justify-content-between align-items-center">
								above ₹50 Crore
								<Icon name="rightArrow" color={theme.colors.gray2} />
							</div>
						}
						onClick={() => startQuote('SFSP')} // SFSP not supported by RFQ journey
					/>
				</div>
			</div>

			{FireAllRiskPopup}
		</PageContentAndNavbarWrapper>
	)
}

// components

const CustomButton = styled(Button)`
	justify-content: flex-start;
	padding: 16px;
	height: 88px;
	background: white;
	color: ${theme.colors.primary};
	width: min(328px, 90%);
	margin-right: 24px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	font-weight: bold;
	font-size: 18px;
	opacity: ${({ disabled }) => disabled && '.5'};
	&:hover {
		color: white;
	}
`
